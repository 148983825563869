import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "semantic-ui-react";

import UserAvatar from "components/UserAvatar";

import * as styles from "./styles.module.scss";

export default class UserPill extends React.Component {
  render() {
    const { avatar, bgColor, fgColor, className, onClick, text } = this.props;
    const classNames = [styles.pill, "clickable", className];

    return (
      <div
        className={classnames(classNames)}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex="-1"
        title={text}
      >
        <UserAvatar
          avatar={avatar}
          bgColor={bgColor}
          className="avatar"
          displayName={text}
          fgColor={fgColor}
          isAnonymous={!avatar}
          size="20"
        />
        <span className="text">{text}</span>
        <Icon className="close" name="close" />
      </div>
    );
  }
}

UserPill.propTypes = {
  avatar: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  fgColor: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

UserPill.defaultProps = {
  onClick: () => {},
};
