import hoistStatics from "hoist-non-react-statics";
import React from "react";

/**
 * Allows two animation frames to complete to allow other components to update
 * and re-render before mounting and rendering an expensive `WrappedComponent`.
 *
 * https://medium.com/@paularmstrong/twitter-lite-and-high-performance-react-progressive-web-apps-at-scale-d28a00e780a3
 */
export default function deferComponentRender(WrappedComponent) {
  class DeferredRenderWrapper extends React.Component {
    _isMounted = false;

    constructor(props) {
      super(props);
      this.state = {
        shouldRender: false,
      };
    }

    componentDidMount() {
      this._isMounted = true;
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          if (this._isMounted) {
            this.setState({ shouldRender: true });
          }
        });
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const { shouldRender } = this.state;
      return shouldRender ? <WrappedComponent {...this.props} /> : null;
    }
  }

  return hoistStatics(DeferredRenderWrapper, WrappedComponent);
}
