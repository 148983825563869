import PropTypes from "prop-types";
import React from "react";
import { Button } from "semantic-ui-react";

import styles from "./styles.module.scss";

const ScreenWelcome = ({ active, currentUser, onStart }) => {
  if (!active) return null;

  return (
    <div className={styles.welcome}>
      <img src="/newWorkspace.svg" alt="New Workspace" />

      <h2>Welcome to your workspace, {currentUser.firstName}</h2>

      <p>
        It looks like you don&apos;t have anyone to collaborate with yet.
        <br />
        You can start by creating a new project or inviting team members.
      </p>

      <Button primary color="purple" content="Start project" onClick={onStart} />
    </div>
  );
};

ScreenWelcome.propTypes = {
  active: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape().isRequired,
  onStart: PropTypes.func.isRequired,
};

export default ScreenWelcome;
