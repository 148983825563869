import { attachment, attachmentDelete } from "actions/attachments";
import { editMessage, messageBoxCache, pusherSendMessage, slashCommand } from "actions/chat";
import classnames from "classnames";
import Dropdown from "components/Dropdown";
import FormattingTools from "components/FormattingTools";
import ResponsiveEmojiPicker from "components/ResponsiveEmojiPicker";
import ScrollableContent from "components/ScrollableContent";
import {
  autoCompletes,
  autoCompleteSelector,
  slashCommands,
} from "containers/Workspace/autoComplete";
import { findIndex, isEmpty, orderBy } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Shortcuts } from "react-shortcuts";
import TextAreaAutosize from "react-textarea-autosize";
import { getCurrentRoomId, getCurrentProjectMemberships } from "reducers/selectors";
import { Button } from "semantic-ui-react";

import { projectMembershipType } from "types/project";
import { computePickerPosition } from "utils/chat";
import { KeyCodes } from "utils/constants";
import { isMobile } from "utils/responsive";
import { Roles } from "utils/constants/project";

import MessageAttachmentList from "./MessageAttachmentList";
import styles from "./styles.module.scss";

class MessageBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cachedMessage: props.message ? props.message.content : props.defaultValue,
      isActiveFormatting: false,
      searchLists: [],
      searchOpen: false,
      searchSort: true,
      searchValue: "",
      showPicker: false,
      upward: true,
    };
    this._timeoutId = null;
    this.isMobile = isMobile();
    this.message = React.createRef();
    this.discardButton = React.createRef();
    this.formatMessage = this.handleFormatMessage.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
    if (this.props.autoFocus && !this.isMobile) {
      this.handleFocus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled && !this.props.disabled && this.props.autoFocus) {
      this.handleFocus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
    const { cacheId, room, message } = this.props;
    const { cachedMessage } = this.state;
    if (room && !message) {
      this.props.messageBoxCache(cacheId, cachedMessage);
    }
  }

  _getChannelDisplay = () => {
    const { currentUser } = this.props;
    switch (currentUser.channelDisplay) {
      case "fixed_width_center":
        return {
          chatBox: classnames(styles.chatboxContainer, styles.centered),
          chatDropdown: classnames(styles.chatDropdown, styles.centered),
        };
      case "fixed_width_left":
        return {
          chatBox: styles.chatboxContainer,
          chatDropdown: styles.chatDropdown,
        };
      case "full_width_left":
        return {
          chatBox: classnames(styles.chatboxContainer, styles.fullWidth),
          chatDropdown: classnames(styles.chatDropdown, styles.fullWidth),
        };
      default:
        return {
          chatBox: styles.chatboxContainer,
          chatDropdown: styles.chatDropdown,
        };
    }
  };

  handleFocus = () => {
    const messageElement = this.message.current;
    if (messageElement) {
      messageElement.focus();
      messageElement.setSelectionRange(messageElement.value.length, messageElement.value.length);
    }
  };

  handleMessageKeyPress = (e) => {
    const { nativeEvent, target } = e;
    const { cacheId, files, root } = this.props;

    const ctrlOrShiftPressed = nativeEvent.ctrlKey || nativeEvent.metaKey || nativeEvent.shiftKey;
    const enterPressed = nativeEvent.keyCode === 13 || nativeEvent.keyCode === 10;
    const enterNewLine = this.isMobile;
    const value = this.state.cachedMessage;

    if (!enterNewLine && enterPressed && !ctrlOrShiftPressed) {
      e.preventDefault();
      if (this.canSendMessage()) {
        this.handleSendMessage(target.value.trim(), files, root);
        this.props.attachmentClear(cacheId);
      } else if (this.props.onEmptyMessage && value.length === 0) {
        this.props.onEmptyMessage();
      }
    }

    return false;
  };

  handleMessageKeyDown = (e) => {
    const {
      keyCode,
      target: { value, selectionStart },
    } = e;
    const { searchOpen, searchValue } = this.state;

    if (!searchOpen) return;

    if (keyCode === KeyCodes.ENTER[1] || keyCode === KeyCodes.TAB[1]) {
      e.preventDefault();

      this.insertAutocomplete(value, selectionStart, searchValue);
    } else if (keyCode === KeyCodes.UP[1]) {
      e.preventDefault();

      this.setState((prevState) => {
        const searchList = [].concat(...Object.values(prevState.searchLists));
        const currentIndex = findIndex(searchList, (item) => item.value === prevState.searchValue);
        const nextIndex = currentIndex === 0 ? searchList.length - 1 : currentIndex - 1;

        return {
          searchValue: searchList[nextIndex]?.value || "",
        };
      });
    } else if (keyCode === KeyCodes.DOWN[1]) {
      e.preventDefault();

      this.setState((prevState) => {
        const searchList = [].concat(...Object.values(prevState.searchLists));
        const currentIndex = findIndex(searchList, (item) => item.value === prevState.searchValue);
        const nextIndex = currentIndex === searchList.length - 1 ? 0 : currentIndex + 1;

        return {
          searchValue: searchList[nextIndex]?.value || "",
        };
      });
    }
  };

  handleDropdownChange = (e, data) => {
    const { type, keyCode } = e;

    if (this.message.current) {
      const { value, selectionStart } = this.message.current;

      if (
        type === "click" ||
        (type === "keydown" && (keyCode === KeyCodes.ENTER[1] || keyCode === KeyCodes.TAB[1]))
      ) {
        e.preventDefault();
        this.insertAutocomplete(value, selectionStart, data.value);
      }
    }
  };

  _splice = (string, start, end, substring, newLineStart = false, newLineEnd = false) => {
    const startString = string.slice(0, start);
    const endString = string.slice(end);
    let splicedString = `${startString}`;

    if (newLineStart && startString) {
      splicedString = `${startString}
${substring}`;
    } else {
      splicedString = `${splicedString}${substring}`;
    }

    if (newLineEnd && endString) {
      splicedString = `${splicedString}
${endString}`;
    } else {
      splicedString = `${splicedString}${endString}`;
    }
    return splicedString;
  };

  handleFormatMessage = (text, insertIndex, newLineStart = false, newLineEnd = false) => {
    if (this.message.current) {
      const message = this.message.current;
      const { value, selectionStart: start, selectionEnd: end } = message;

      const substring = value.slice(start, end);
      let cursorPosition = end + insertIndex;
      if (!substring) {
        cursorPosition = start + insertIndex;
      }
      const insert = this._splice(text, insertIndex, insertIndex, substring);
      const newString = this._splice(value, start, end, insert, newLineStart, newLineEnd);
      this.setState({ cachedMessage: newString }, () => {
        message.selectionEnd = cursorPosition;
      });
      this.handleFocus();
    }
  };

  handleMessageChange = (e) => {
    const {
      target: { value, selectionStart },
    } = e;

    this.setSearchList(value, selectionStart);
    this.setState({ cachedMessage: value });
  };

  handleSendMessage = (content, files, root = null) => {
    const { message, onMessageSent } = this.props;
    if (message) {
      this.handleMessageEdit(content);
    } else {
      this.handleSendNewMessage(content, files, root);
    }
    if (onMessageSent) {
      onMessageSent();
    }
  };

  handleSendNewMessage = (content, files, root = null) => {
    const { room, currentUser, token } = this.props;
    const roomId = room.id;

    if (
      slashCommands.reduce(
        (capture, cmd) =>
          capture ||
          cmd({
            message: content,
            canInviteMember: this.canInviteMember(currentUser),
            callAPI: (command, data, onSuccess, onFailure) =>
              this.props.slashCommand({
                token,
                id: roomId,
                command,
                data,
                onSuccess: () => this.clearCachedMessage(),
                onFailure,
              }),
            sendMessage: (message, onSuccess) => {
              this.clearCachedMessage();
              this.props.pusherSendMessage({
                content: message,
                files: [],
                id: roomId,
                token,
                user: currentUser,
                root: root ? root.id : null,
                onSuccess,
              });
            },
          }),
        false
      )
    )
      return;

    this.props.pusherSendMessage({
      content,
      files,
      id: roomId,
      token,
      user: currentUser,
      root: root ? root.id : null,
    });
    this.clearCachedMessage();
  };

  handleMessageEdit = (content) => {
    const { message, room } = this.props;
    const roomId = room.id;

    this.props.editMessage({
      id: roomId,
      messageId: message.id,
      content,
    });
    this.clearCachedMessage();
  };

  handleAttachButtonClick = () => {
    this.props.dropzoneOpen();

    // keep the focus on the text area
    this.handleFocus();
  };

  handleAttachmentDelete = (fileId) => {
    const { cacheId } = this.props;
    this.props.attachmentDeleteRequest(cacheId, fileId);
  };

  handleAttachmentRetry = (fileId) => {
    const { cacheId, files } = this.props;
    this.props.attachmentInit(cacheId, files[fileId]);
  };

  handleSendButtonClick = (e) => {
    if (this.message.current) {
      const target = this.message.current;
      const { cacheId, files, root } = this.props;
      const value = this.state.cachedMessage;
      if (this.canSendMessage()) {
        this.handleSendMessage(target.value.trim(), files, root);
        this.props.attachmentClear(cacheId);
        if (!this.isMobile) {
          this.handleFocus();
        } else {
          this._onBlur();
        }
      } else if (this.props.onEmptyMessage && value.length === 0) {
        this.props.onEmptyMessage();
      }
    }
    e.preventDefault();
  };

  handleDiscardClick = () => {
    const { onDiscard, cacheId } = this.props;
    this.clearCachedMessage();
    this.props.attachmentClear(cacheId);
    onDiscard();
    if (this.discardButton && this.discardButton.ref) {
      this.discardButton.ref.blur();
    }
  };

  handleDocumentClick = (event) => {
    const { searchOpen, showPicker } = this.state;
    if (searchOpen) {
      this.setState({ searchOpen: false });
    } else if (showPicker) {
      if (event.target.closest(".emoji-mart") === null) {
        this.setState({ showPicker: false });
      }
    }
  };

  handleEmojiPickerClick = (event) => {
    const { target } = event;
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.setState((prevState) => ({
      showPicker: !prevState.showPicker,
      pickerStyle: computePickerPosition(target.closest("button")),
    }));
    return false;
  };

  handleAddEmoji = (emoji) => {
    this.setState(
      (prevState) => ({
        cachedMessage: `${prevState.cachedMessage}${
          prevState.cachedMessage.length > 0 &&
          prevState.cachedMessage.charAt(prevState.cachedMessage.length - 1) !== " "
            ? " "
            : ""
        }${emoji.colons} `,
        showPicker: false,
      }),
      () => {
        this.handleFocus();
      }
    );
  };

  handleShortcut = (action, event) => {
    const {
      target: { value },
    } = event;
    switch (action) {
      case "UPLOAD":
        if (!value) {
          event.preventDefault();
          event.stopPropagation();
          this.handleAttachButtonClick();
        }
        break;
      case "EDIT_LAST":
        if (!value && this.props.onEditLastMessage) {
          event.preventDefault();
          event.stopPropagation();
          this.props.onEditLastMessage();
        }
        break;
      case "REPLY_LAST":
        if (!value && this.props.onReplyLastMessage) {
          this.props.onReplyLastMessage();
        }
        break;
      default:
        break;
    }
  };

  handleToggleFormatting = (active) => {
    this.setState({
      isActiveFormatting: active,
    });
  };

  setSearchList = (message, cursor) => {
    const { currentUser, users, room } = this.props;

    if (message.length === 0) {
      this.setState({
        searchLists: [],
        searchOpen: false,
        searchSort: true,
      });
    } else if (message.startsWith("/")) {
      // Prevent slash commands from showing up when editing a message.
      if (this.props.message) {
        return;
      }
      autoCompletes.map((autoComplete, index) =>
        autoComplete({
          user: currentUser,
          users,
          room,
          canInviteMember: this.canInviteMember(currentUser),
          message: message.substring(0, cursor),
          callback: (nextSearchList) => {
            this.setState((prevState) => {
              const searchLists = {
                ...prevState.searchLists,
                [index]: nextSearchList,
              };
              const searchList = orderBy([].concat(...Object.values(searchLists)), "value", "asc");

              return {
                searchLists,
                searchOpen: Object.values(searchLists).reduce(
                  (open, sl) => open || sl.length > 0,
                  false
                ),
                searchSort: true,
                searchValue: searchList[0] ? searchList[0].value : "",
              };
            });
          },
        })
      );
    } else {
      autoCompleteSelector({
        user: currentUser,
        users,
        room,
        message: message.substring(0, cursor),
        atMentionCallback: (searchLists) => {
          this.setState({
            searchLists,
            searchOpen: searchLists.length > 0,
            searchSort: false,
            searchValue: searchLists.length > 0 ? searchLists[0][0].value : "",
          });
        },
        emojiCallback: (searchLists) => {
          this.setState({
            searchLists,
            searchOpen: searchLists.length > 0,
            searchSort: true,
            searchValue: searchLists.length > 0 ? searchLists[0][0].value : "",
          });
        },
        emptyCallback: () => {
          this.setState({
            searchLists: [],
            searchOpen: false,
            searchSort: true,
            searchValue: "",
          });
        },
      });
    }
  };

  canSendMessage = () => {
    const { room, files } = this.props;
    if (!room) {
      return false;
    }
    const value = this.state.cachedMessage;
    if (!Object.keys(files).every((fileId) => !files[fileId].uploading)) {
      return false;
    }
    if (Object.keys(files).some((fileId) => !!files[fileId].error)) {
      return false;
    }
    if ((!value || value.trim().length === 0) && isEmpty(files)) {
      return false;
    }
    return true;
  };

  clearCachedMessage = () => {
    const { cacheId, message, room } = this.props;
    this.setState({ cachedMessage: "" }, () => {
      if (room && !message) {
        this.props.messageBoxCache(cacheId, "");
      }
    });
  };

  insertAutocomplete = (message, cursor, value) => {
    const leftMessage = message.slice(0, cursor);
    const rightMessage = message.slice(cursor);
    const separators = leftMessage.match(/[\s,:]/gi);
    const newMessage = `${
      separators
        ? `${leftMessage.slice(0, leftMessage.lastIndexOf(separators[separators.length - 1]) + 1)}`
        : ""
    }${value} ${rightMessage}`;

    this.setState({ cachedMessage: newMessage, searchValue: "" }, () => {
      this.setSearchList(newMessage, newMessage.length);
    });
  };

  canInviteMember = (user) => {
    const { projectMemberships } = this.props;

    if (isEmpty(projectMemberships)) {
      return true;
    }

    const { slug: userSlug } = user;
    const { role: userRole } = projectMemberships[userSlug];
    return userRole !== Roles.clientMember;
  };

  render() {
    const {
      searchValue,
      isActiveFormatting,
      searchLists,
      searchOpen,
      searchSort,
      showPicker,
      upward,
      pickerStyle,
    } = this.state;
    const {
      disabled,
      height,
      id,
      hideSubmitButton,
      message,
      room,
      root,
      inChat,
      dropzoneOnPaste,
    } = this.props;

    const value = this.state.cachedMessage;
    const canSend = this.canSendMessage();
    const searchList = searchSort
      ? orderBy([].concat(...Object.values(searchLists)), "value", "asc")
      : [].concat(...Object.values(searchLists));

    let placeholder = "Type message, /command, @mention";
    let idPrefix = "chatbox";
    let textareaId = id;
    let attachmentIdPrefix = "attachmentsbox";
    if (root) {
      placeholder = "Write a reply...";
      idPrefix = `${idPrefix}-root`;
      if (id) {
        textareaId = `${idPrefix}-${id}`;
      }
      attachmentIdPrefix = `${attachmentIdPrefix}-root`;
    } else if (message) {
      placeholder = "Type a message...";
      idPrefix = `${idPrefix}-message`;
      attachmentIdPrefix = `${attachmentIdPrefix}-root`;
    }

    if (!id) {
      // set id for textarea
      textareaId = `${idPrefix}-chatMessageBox`;
    }

    const channelDisplay = this._getChannelDisplay();

    return (
      <div onBlur={this._onBlur} onFocus={this._onFocus}>
        <Shortcuts
          name="MESSAGE_BOX"
          alwaysFireHandler
          handler={this.handleShortcut}
          className={classnames("shortcuts", styles.textareaSegment, inChat && styles.chatTextArea)}
          stopPropagation={false}
          preventDefault={false}
        >
          <Dropdown
            className={channelDisplay.chatDropdown}
            fluid
            icon={null}
            noResultsMessage={null}
            onChange={this.handleDropdownChange}
            open={searchOpen}
            openOnFocus={false}
            options={searchList}
            scrolling
            style={upward ? {} : { bottom: "38px" }}
            upward={upward}
            value={searchValue}
            wrapSelection
          />
          <div className={channelDisplay.chatBox}>
            <ScrollableContent
              containerClass={styles.scrollableTextarea}
              scrollableElementId={textareaId}
              idPrefix={idPrefix}
            >
              <TextAreaAutosize
                className={styles.textarea}
                data-testid="chatBox"
                disabled={disabled}
                id={textareaId}
                onChange={this.handleMessageChange}
                onKeyDown={this.handleMessageKeyDown}
                onKeyPress={this.handleMessageKeyPress}
                onPaste={dropzoneOnPaste}
                placeholder={placeholder}
                ref={this.message}
                rows={height}
                value={value}
              />
            </ScrollableContent>
            {!isEmpty(this.props.files) && (
              <ScrollableContent idPrefix={attachmentIdPrefix} alwaysDisplayScrollbar>
                <MessageAttachmentList
                  files={this.props.files}
                  onRemove={this.handleAttachmentDelete}
                  onRetry={this.handleAttachmentRetry}
                />
              </ScrollableContent>
            )}
            {showPicker && (
              <ResponsiveEmojiPicker
                native
                onSelect={this.handleAddEmoji}
                perLine={7}
                showPreview={false}
                style={pickerStyle}
              />
            )}
            <div
              className={classnames(
                styles.messageButtons,
                isActiveFormatting && styles.activeFormatting
              )}
            >
              <div className={styles.formatting}>
                {!message && room && (
                  <Button
                    basic
                    disabled={disabled}
                    icon="attach"
                    type="button"
                    onClick={this.handleAttachButtonClick}
                    title="Attach file"
                  />
                )}
                <FormattingTools
                  disabled={disabled}
                  onSubmit={this.handleFormatMessage}
                  onToggle={this.handleToggleFormatting}
                  inChat={inChat}
                />
                <Button
                  basic
                  disabled={disabled}
                  icon="smile"
                  type="button"
                  onClick={this.handleEmojiPickerClick}
                  title="Insert emoji"
                />
              </div>

              {!hideSubmitButton && (
                <div className={styles.messageActions}>
                  <Button
                    className={classnames(styles.discard, styles.submitBtn)}
                    disabled={disabled}
                    onClick={this.handleDiscardClick}
                    title="Discard message"
                    ref={this.discardButton}
                  >
                    Discard
                  </Button>
                  <Button
                    primary
                    className={styles.submitBtn}
                    disabled={disabled || !canSend}
                    onClick={this.handleSendButtonClick}
                    title="Send message"
                  >
                    Post
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Shortcuts>
      </div>
    );
  }
}

MessageBox.propTypes = {
  attachmentClear: PropTypes.func.isRequired,
  attachmentInit: PropTypes.func.isRequired,
  attachmentDeleteRequest: PropTypes.func.isRequired,

  autoFocus: PropTypes.bool,
  cacheId: PropTypes.string,
  currentUser: PropTypes.shape().isRequired,
  id: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  dropzoneOpen: PropTypes.func,
  dropzoneOnPaste: PropTypes.func,
  editMessage: PropTypes.func.isRequired,
  files: PropTypes.objectOf(PropTypes.shape()).isRequired,
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
  }),
  height: PropTypes.number,
  room: PropTypes.shape(),
  root: PropTypes.shape(),
  hideSubmitButton: PropTypes.bool,
  users: PropTypes.shape(),
  onDiscard: PropTypes.func,
  onEditLastMessage: PropTypes.func,
  onReplyLastMessage: PropTypes.func,
  onMessageSent: PropTypes.func,
  onEmptyMessage: PropTypes.func,
  pusherSendMessage: PropTypes.func.isRequired,
  messageBoxCache: PropTypes.func.isRequired,
  slashCommand: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  inChat: PropTypes.bool,
  projectMemberships: PropTypes.objectOf(projectMembershipType).isRequired,
};

MessageBox.defaultProps = {
  autoFocus: true,
  dropzoneOpen: () => {},
  dropzoneOnPaste: () => {},
  height: 1,
  hideSubmitButton: false,
  inChat: false,
  onDiscard: () => {},
};

function mapStateToProps(state, { cacheId, room }) {
  const {
    attachments,
    auth: { user, token },
    entities: { users },
    rooms: { roomSending },
    cachedMessages,
  } = state;
  const projectMemberships = getCurrentProjectMemberships(state);
  const currentRoomId = getCurrentRoomId(state);

  const cachedMessage = cachedMessages[cacheId] || "";
  const files = attachments[cacheId] || {};
  const isSending = roomSending && !!roomSending[currentRoomId];
  const disabled =
    !room || !room.isActive || !room.userSlugs || !room.userSlugs.includes(user.slug) || isSending;

  return {
    currentUser: user,
    defaultValue: cachedMessage,
    disabled,
    files,
    token,
    users,
    projectMemberships,
  };
}

export default connect(mapStateToProps, {
  messageBoxCache,
  attachmentInit: attachment.init,
  attachmentClear: attachment.clear,
  attachmentDeleteRequest: attachmentDelete.request,
  editMessage: editMessage.request,
  pusherSendMessage: pusherSendMessage.request,
  slashCommand: slashCommand.request,
})(MessageBox);
