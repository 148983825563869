import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider, connect } from "react-redux";
import { Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { PersistGate } from "redux-persist/integration/react";

import ErrorBoundary from "components/ErrorBoundary";
import GlobalShortcuts from "containers/GlobalShortcuts";
import Routes from "containers/Routes";

export class Root extends Component {
  render() {
    const { store, history, persistor } = this.props;
    const language = "en";
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalShortcuts>
            <IntlProvider key={language} locale={language} messages={{}} textComponent="span">
              <ErrorBoundary>
                <Router history={history}>
                  <Routes />
                </Router>
              </ErrorBoundary>
            </IntlProvider>
          </GlobalShortcuts>
        </PersistGate>
      </Provider>
    );
  }
}

Root.propTypes = {
  persistor: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const { request, auth } = state;
  return {
    auth,
    request,
  };
}

export default connect(mapStateToProps)(Root);
