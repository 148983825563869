import { mapKeys, mapValues, sortBy } from "lodash";
import { createSelector } from "reselect";

import { getRoomName } from "utils";

import { getRooms } from ".";

const getCurrentUser = (state) => state.auth && state.auth.user;
const getProjectMemberships = (state) => state.entities.projectMemberships;
const getProjects = (state) => state.entities.projects;

export const getCurrentUserMessageRooms = createSelector(
  getRooms,
  getCurrentUser,
  (rooms, currentUser) => {
    const messageRooms = Object.values(rooms).filter((room) => room && !room.project && !room.team);
    return sortBy(messageRooms, (room) => getRoomName(room, room.users, currentUser).toLowerCase());
  }
);

export const getCurrentUserTeamRooms = createSelector(
  getRooms,
  getCurrentUser,
  (rooms, currentUser) => {
    const messageRooms = Object.values(rooms).filter((room) => room && !room.project && room.team);
    return sortBy(messageRooms, (room) => getRoomName(room, room.users, currentUser).toLowerCase());
  }
);

export const getCurrentUserProjectMemberships = createSelector(
  getProjectMemberships,
  getCurrentUser,
  (projectMemberships, currentUser) => {
    if (!currentUser) return {};

    return mapKeys(
      Object.values(projectMemberships).filter((m) => m.userSlug === currentUser.slug),
      "projectId"
    );
  }
);

export const getCurrentUserProjectIds = createSelector(
  getCurrentUserProjectMemberships,
  (projectMemberships) => Object.values(projectMemberships).map((m) => m.projectId)
);

export const getCurrentUserProjectsActive = createSelector(
  getCurrentUserProjectIds,
  getProjects,
  (projectIds, projects) =>
    sortBy(
      projectIds
        .map((id) => projects[id])
        .filter((project) => project && project.isActive && project.name),
      (project) => project.name.toLowerCase()
    )
);

export const getCurrentUserProjectRoomsActive = createSelector(
  getCurrentUserProjectsActive,
  getRooms,
  (projects, rooms) =>
    mapValues(mapKeys(projects, "id"), (project) => {
      const projectRooms = project.roomIds.map((id) => rooms[id]);
      const projectRoomsFiltered = projectRooms.filter((room) => room);
      return sortBy(projectRoomsFiltered, [
        (room) => !room.isPrivate,
        (room) => !room.isGeneral,
        (room) => room.name?.toLowerCase(),
      ]);
    })
);

export const getCurrentUserProjectsArchived = createSelector(
  getCurrentUserProjectIds,
  getProjects,
  (projectIds, projects) =>
    sortBy(
      projectIds
        .map((id) => projects[id])
        .filter((project) => project && !project.isActive && project.name),
      (project) => project.name.toLowerCase()
    )
);

export const getCurrentUserProjectRoomsArchived = createSelector(
  getCurrentUserProjectsArchived,
  getRooms,
  (projects, rooms) =>
    mapValues(mapKeys(projects, "id"), (project) => {
      const projectRooms = project.roomIds.map((id) => rooms[id]);
      const projectRoomsFiltered = projectRooms.filter((room) => room);
      return sortBy(projectRoomsFiltered, (room) => room.name.toLowerCase());
    })
);
