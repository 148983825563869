import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Loader from "react-loader";
import { Redirect, useLocation } from "react-router-dom";

import config from "config";
import { token as tokenService } from "services";

const AuthenticatedContainer = (props) => {
  const { pathname } = useLocation();
  const [token, setToken] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    tokenService.getAuthToken().then((nextToken) => {
      setToken(nextToken);
      setIsLoaded(true);
    });
  }, []);

  if (isLoaded && !token) {
    return <Redirect to={`${config.loginUrl}?next=${pathname}`} />;
  }

  if (props.isAuthenticated && token) {
    return <props.component {...props} />;
  }

  return <Loader />;
};

AuthenticatedContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  loginInit: PropTypes.func.isRequired,
  component: PropTypes.elementType.isRequired,
};

export default AuthenticatedContainer;
