import { createRequestTypes, action } from "./index";

export const CHANNEL_CREATE_OR_UPDATE = createRequestTypes("CHANNEL_CREATE_OR_UPDATE");
export const CHANNEL_DELETE = createRequestTypes("CHANNEL_DELETE");
export const CREATE_LINK = createRequestTypes("CREATE_LINK");
export const CREATE_LINK_RESET = "CREATE_LINK_RESET";
export const CREATE_PROJECT = createRequestTypes("CREATE_PROJECT");
export const GET_SUGGESTED_TIMES = createRequestTypes("GET_SUGGESTED_TIMES");
export const INVITATION_ADD = createRequestTypes("INVITATION_ADD");
export const LINKS_CREATE_OR_UPDATE = createRequestTypes("LINKS_CREATE_OR_UPDATE");
export const LINKS_DELETE = createRequestTypes("LINKS_DELETE");
export const PROJECT = createRequestTypes("PROJECT");
export const PROJECT_DELETE = createRequestTypes("PROJECT_DELETE");
export const PROJECT_DETAIL = createRequestTypes("PROJECT_DETAIL");
export const PROJECT_INVITATIONS_DETAIL = createRequestTypes("PROJECT_INVITATIONS_DETAIL");
export const PROJECT_INVITATIONS_UPDATE = createRequestTypes("PROJECT_INVITATIONS_UPDATE");
export const PROJECT_MEMBERSHIPS_DELETE = createRequestTypes("PROJECT_MEMBERSHIPS_DELETE");
export const PROJECT_MEMBERSHIPS_DETAIL = createRequestTypes("PROJECT_MEMBERSHIPS_DETAIL");
export const PROJECT_MEMBERSHIPS_UPDATE = createRequestTypes("PROJECT_MEMBERSHIPS_UPDATE");
export const PROJECT_BUDGET_UPDATE = createRequestTypes("PROJECT_BUDGET_UPDATE");
export const PROJECT_UPDATE = createRequestTypes("PROJECT_UPDATE");
export const TALENT_PROJECT = createRequestTypes("TALENT_PROJECT");
export const UPDATE_PROJECT = "UPDATE_PROJECT";

export const channelCreateOrUpdate = {
  request: (projectId, channels, resolve, reject) =>
    action(CHANNEL_CREATE_OR_UPDATE.REQUEST, { projectId, channels, resolve, reject }),
  success: (projectId, response) =>
    action(CHANNEL_CREATE_OR_UPDATE.SUCCESS, { projectId, response }),
  failure: (projectId, errorDetails) =>
    action(CHANNEL_CREATE_OR_UPDATE.FAILURE, { projectId, errorDetails }),
};

export const channelDelete = {
  request: (projectId, channelId, resolve, reject) =>
    action(CHANNEL_DELETE.REQUEST, { projectId, channelId, resolve, reject }),
  success: (projectId, channelId, response) =>
    action(CHANNEL_DELETE.SUCCESS, {
      projectId,
      channelId,
      response,
    }),
  failure: (projectId, channelId, errorDetails) =>
    action(CHANNEL_DELETE.FAILURE, {
      projectId,
      channelId,
      errorDetails,
    }),
};

export const createLink = {
  reset: () => action(CREATE_LINK_RESET),
  init: (id, name, url) => action(CREATE_LINK.INIT, { id, name, url }),
  request: () => action(CREATE_LINK.REQUEST),
  success: (response) => action(CREATE_LINK.SUCCESS, { response }),
  failure: (error) => action(CREATE_LINK.FAILURE, { error }),
};

export const createProject = {
  init: (id, name, clientSlug, roomId) =>
    action(CREATE_PROJECT.INIT, { id, name, clientSlug, roomId }),
  request: () => action(CREATE_PROJECT.REQUEST),
  success: (response) => action(CREATE_PROJECT.SUCCESS, { response }),
  failure: (error) => action(CREATE_PROJECT.FAILURE, { error }),
};

export const invitationAdd = {
  request: (projectId, resolve, reject) =>
    action(INVITATION_ADD.REQUEST, { projectId, resolve, reject }),
  success: (response) => action(INVITATION_ADD.SUCCESS, { response }),
  failure: (error) => action(INVITATION_ADD.FAILURE, { error }),
};

export const getSuggestedTimes = {
  request: (guests, defaultMeetings, resolve, reject) =>
    action(GET_SUGGESTED_TIMES.REQUEST, { guests, defaultMeetings, resolve, reject }),
  success: (response) => action(GET_SUGGESTED_TIMES.SUCCESS, { response }),
  failure: (error) => action(GET_SUGGESTED_TIMES.FAILURE, { error }),
};

export const linkCreateOrUpdate = {
  request: (projectId, links, resolve, reject) =>
    action(LINKS_CREATE_OR_UPDATE.REQUEST, { projectId, links, resolve, reject }),
  success: (projectId, response) => action(LINKS_CREATE_OR_UPDATE.SUCCESS, { projectId, response }),
  failure: (projectId, errorDetails) =>
    action(LINKS_CREATE_OR_UPDATE.FAILURE, { projectId, errorDetails }),
};

export const linkDelete = {
  request: (projectId, linkId, resolve, reject) =>
    action(LINKS_DELETE.REQUEST, { projectId, linkId, resolve, reject }),
  success: (projectId, linkId, response) =>
    action(LINKS_DELETE.SUCCESS, { projectId, linkId, response }),
  failure: (projectId, linkId, errorDetails) =>
    action(LINKS_DELETE.FAILURE, { projectId, linkId, errorDetails }),
};

export const loadProjects = (refresh = true) => action(PROJECT.INIT, { refresh });

export const project = {
  init: (id) => action(PROJECT.INIT, { id }),
  request: (id) => action(PROJECT.REQUEST, { id }),
  success: (response, id) => action(PROJECT.SUCCESS, { response, id }),
  failure: (error, id) => action(PROJECT.FAILURE, { error, id }),
};

export const projectDelete = {
  request: (id) => action(PROJECT_DELETE.REQUEST, { id }),
  success: (response) => action(PROJECT_DELETE.SUCCESS, { response }),
  failure: (errorDetails) => action(PROJECT_DELETE.FAILURE, { errorDetails }),
};

export const projectDetail = {
  success: (response) => action(PROJECT_DETAIL.SUCCESS, { response }),
};

export const projectInvitationsDetail = {
  success: (response) => action(PROJECT_INVITATIONS_DETAIL.SUCCESS, { response }),
};

export const projectInvitationsUpdate = {
  request: (projectId, invitations, resolve, reject) =>
    action(PROJECT_INVITATIONS_UPDATE.REQUEST, { projectId, invitations, resolve, reject }),
  success: (projectId, response) =>
    action(PROJECT_INVITATIONS_UPDATE.SUCCESS, { projectId, response }),
  failure: (projectId, errorDetails) =>
    action(PROJECT_INVITATIONS_UPDATE.FAILURE, { projectId, errorDetails }),
};

export const projectMembershipDelete = {
  request: (projectId, membershipId, userSlug, resolve, reject) =>
    action(PROJECT_MEMBERSHIPS_DELETE.REQUEST, {
      projectId,
      membershipId,
      userSlug,
      resolve,
      reject,
    }),
  success: (projectId, membershipId, userSlug, response) =>
    action(PROJECT_MEMBERSHIPS_DELETE.SUCCESS, { projectId, membershipId, userSlug, response }),
  failure: (projectId, membershipId, errorDetails) =>
    action(PROJECT_MEMBERSHIPS_DELETE.FAILURE, { projectId, membershipId, errorDetails }),
};

export const projectMembershipDetail = {
  success: (response) => action(PROJECT_MEMBERSHIPS_DETAIL.SUCCESS, { response }),
};

export const projectMembershipUpdate = {
  request: (projectId, memberships, resolve, reject) =>
    action(PROJECT_MEMBERSHIPS_UPDATE.REQUEST, { projectId, memberships, resolve, reject }),
  success: (projectId, response) =>
    action(PROJECT_MEMBERSHIPS_UPDATE.SUCCESS, {
      projectId,
      response,
    }),
  failure: (projectId, errorDetails) =>
    action(PROJECT_MEMBERSHIPS_UPDATE.FAILURE, {
      projectId,
      errorDetails,
    }),
};

export const projectBudgetUpdate = {
  request: (projectId, budget, resolve, reject) =>
    action(PROJECT_BUDGET_UPDATE.REQUEST, { projectId, budget, resolve, reject }),
  success: (projectId, response) =>
    action(PROJECT_BUDGET_UPDATE.SUCCESS, {
      projectId,
      response,
    }),
  failure: (projectId, errorDetails) =>
    action(PROJECT_BUDGET_UPDATE.FAILURE, {
      projectId,
      errorDetails,
    }),
};

export const projectUpdate = {
  request: (projectId, data, resolve, reject) =>
    action(PROJECT_UPDATE.REQUEST, { projectId, data, resolve, reject }),
  success: (projectId, response) =>
    action(PROJECT_UPDATE.SUCCESS, {
      projectId,
      response,
    }),
  failure: (projectId, errorDetails) =>
    action(PROJECT_UPDATE.FAILURE, {
      projectId,
      errorDetails,
    }),
};

export const updateProject = (id, data) => action(UPDATE_PROJECT, { id, data });

export const talentProject = {
  request: (slug, refresh = true) => action(TALENT_PROJECT.REQUEST, { slug, refresh }),
  success: (response) => action(TALENT_PROJECT.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(TALENT_PROJECT.FAILURE, { error, errorDetails }),
};
