import Humanize from "humanize-plus";
import PropTypes from "prop-types";
import React from "react";
import { Icon, Button, Modal } from "semantic-ui-react";

import ImageLoader from "components/ImageLoader";
import TextViewer from "components/TextViewer";
import { getAbsoluteUri } from "utils";

import * as styles from "./styles.module.scss";

function filterImagesOnly(fileList) {
  return fileList.filter(
    (file) =>
      file.type === "png" ||
      file.type === "jpeg" ||
      file.type === "gif" ||
      file.type === "svg" ||
      file.type === "webp"
  );
}

export default class AttachmentItem extends React.PureComponent {
  getFile = () => {
    const { fileId, files } = this.props;
    return files.find((file) => file.id === fileId || file.fileId === fileId);
  };

  _isImage = (fileType) => {
    switch (fileType) {
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
      case "webp":
        return true;
      default:
        return false;
    }
  };

  renderLink() {
    const file = this.getFile();
    const fileUrl = file.file ? getAbsoluteUri(file.file) : null;

    return (
      <div className={styles.attachment}>
        <div className={styles.attachmentDetail}>
          <p className={styles.heading}>
            {file.data ? (
              file.name
            ) : (
              <a href={`${fileUrl}?download=1`} title={file.name}>
                {file.name}
              </a>
            )}
          </p>
          <p className={styles.subheading}>{Humanize.fileSize(file.fileSize || file.data.size)}</p>
          {file.error && <p className={styles.subheading}>{`(${file.error.detail})`}</p>}
        </div>
        {this.renderAttachmentButton(file, fileUrl)}
      </div>
    );
  }

  renderAttachmentIcon = (fileType) => {
    let icon = "";
    switch (fileType) {
      case "text":
      case "docx":
        icon = "file alternate";
        break;
      case "mp3":
      case "x-m4a":
        icon = "file audio";
        break;
      case "webm":
      case "mp4":
      case "octet-stream":
        icon = "file video";
        break;
      case "xlsx":
        icon = "table";
        break;
      default:
        icon = "paperclip";
        break;
    }
    return (
      <div className={styles.attachmentIcon}>
        <Icon fitted size="large" name={icon} />
      </div>
    );
  };

  renderAttachmentButton = (file, fileUrl) => (
    <div className={styles.attachmentButton}>
      <Button
        as="a"
        basic
        icon="arrow down"
        type="button"
        href={file.data ? null : `${fileUrl}?download=1`}
        title="Download"
      />
    </div>
  );

  renderFileViewer() {
    const { files } = this.props;

    const file = this.getFile();
    const fileUrl = file.file ? getAbsoluteUri(file.file) : null;

    switch (file.type) {
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
      case "webp":
        return (
          <React.Fragment>
            <ImageLoader
              imageList={filterImagesOnly(files)}
              src={fileUrl}
              alt="Attachment"
              triggerClassName={styles.attachmentImageTrigger}
              name={file.name}
            />
            {this.renderAttachmentButton(file, fileUrl)}
          </React.Fragment>
        );
      case "text":
        return (
          <React.Fragment>
            <Modal
              basic
              closeIcon
              inverted
              className={styles.modal}
              trigger={this.renderAttachmentIcon(file.type)}
            >
              <Modal.Content>
                <TextViewer filePath={fileUrl} />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="a"
                  compact
                  content="Download"
                  href={file.data ? null : `${fileUrl}?download=1`}
                  icon="download"
                  rel="noopener noreferrer"
                  secondary
                  size="small"
                  target="_blank"
                />
              </Modal.Actions>
            </Modal>
            {this.renderLink(true)}
          </React.Fragment>
        );
      case "mp3":
      case "x-m4a":
        return (
          <React.Fragment>
            <Modal
              basic
              closeIcon
              inverted
              className={styles.modal}
              size="mini"
              trigger={this.renderAttachmentIcon(file.type)}
            >
              <Modal.Content>
                {/* eslint-disable jsx-a11y/media-has-caption */}
                <audio
                  autoPlay={false}
                  src={fileUrl}
                  controls
                  controlsList="nodownload"
                  style={{ maxWidth: "100%" }}
                />
                {/* eslint-enable jsx-a11y/media-has-caption */}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="a"
                  compact
                  content="Download"
                  href={file.data ? null : `${fileUrl}?download=1`}
                  icon="download"
                  rel="noopener noreferrer"
                  secondary
                  size="small"
                  target="_blank"
                />
              </Modal.Actions>
            </Modal>
            {this.renderLink()}
          </React.Fragment>
        );
      case "webm":
      case "mp4":
      case "octet-stream":
        return (
          <React.Fragment>
            <Modal
              basic
              closeIcon
              inverted
              className={styles.modal}
              size="small"
              trigger={this.renderAttachmentIcon(file.type)}
            >
              <Modal.Content>
                <video
                  muted
                  src={fileUrl}
                  controls
                  controlsList="nodownload"
                  disablePictureInPicture
                  style={{ maxWidth: "100%", height: 320 }}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="a"
                  compact
                  content="Download"
                  href={file.data ? null : `${fileUrl}?download=1`}
                  icon="download"
                  rel="noopener noreferrer"
                  secondary
                  size="small"
                  target="_blank"
                />
              </Modal.Actions>
            </Modal>
            {this.renderLink()}
          </React.Fragment>
        );
      case "docx":
      case "xlsx":
        return (
          <React.Fragment>
            {this.renderAttachmentIcon(file.type)}
            {this.renderLink()}
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            {this.renderAttachmentIcon(file.type)}
            {this.renderLink()}
          </React.Fragment>
        );
    }
  }

  render() {
    return <div className={styles.attachmentContainer}>{this.renderFileViewer()}</div>;
  }
}

AttachmentItem.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      fileSize: PropTypes.number,
      data: PropTypes.shape({
        preview: PropTypes.string,
        size: PropTypes.number.isRequired,
        type: PropTypes.string,
      }),

      // Extra attributes
      progress: PropTypes.number,
      error: PropTypes.shape({
        detail: PropTypes.string,
        retry: PropTypes.bool,
      }),
    })
  ).isRequired,
  fileId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
