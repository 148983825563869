import { sumBy } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Loader, Segment } from "semantic-ui-react";

import MessageListItemPollResultListItem from "./MessageListItemPollResultListItem";
import styles from "./styles.module.scss";

const MessageListItemPollResultList = ({ poll, users, votesByOptions = {} }) => {
  if (Object.keys(users).length === 0) {
    return (
      <Segment className={styles.messageListItemPollResultsLoading}>
        <Loader active />
      </Segment>
    );
  }

  const totalVotesKey =
    poll.optionType === "RESULTS_BY_WEIGHTED" ? "totalVotesWeighted" : "totalVotesSimple";
  const totalVoteCount = sumBy(poll.options, totalVotesKey);

  return (
    <div className={styles.messageListItemPollResults}>
      {poll.options.map((option) => (
        <MessageListItemPollResultListItem
          currentVoteCount={option[totalVotesKey]}
          key={option.id}
          name={option.name}
          optionType={poll.optionType}
          totalVoteCount={totalVoteCount}
          users={users}
          votes={votesByOptions[option.id]}
        />
      ))}
    </div>
  );
};

MessageListItemPollResultList.propTypes = {
  poll: PropTypes.shape().isRequired,
  users: PropTypes.shape().isRequired,
  votesByOptions: PropTypes.shape(),
};

export default MessageListItemPollResultList;
