import React from "react";
import PropTypes from "prop-types";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/cjs/styles/hljs";

const CodeRenderer = ({ language, value }) => (
  <SyntaxHighlighter
    customStyle={{ border: "1px solid #d0d0d0", borderRadius: 4, fontSize: "90%" }}
    language={language}
    showLineNumbers
    style={vs}
  >
    {value}
  </SyntaxHighlighter>
);

CodeRenderer.defaultProps = {
  language: '',
  value: '',
}

CodeRenderer.propTypes = {
  language: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CodeRenderer;
