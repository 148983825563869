import PropTypes from "prop-types";
import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import CookieBanner from "react-cookie-banner";
import config from "config";

import App from "containers/App";
import Loading from "containers/Loading";
import Pusher from "containers/Pusher";
import ShortcutsHelp from "containers/ShortcutsHelp";
import DownloadLink from "containers/DownloadLink";
import NewVersionPrompt from "containers/NewVersionPrompt";

const Call = lazy(() => import(/* webpackChunkName: "containers/Call" */ "containers/Call"));

export class RouteBase extends Component {
  render() {
    if (!this.props.initialLoad) {
      return <div />;
    }

    return (
      <>
        <Pusher />
        <ShortcutsHelp />
        <DownloadLink />
        <Suspense fallback={<Loading isLoading />}>
          <Switch>
            {config.flags.call && (
              <Route exact path="/call/:callCode">
                <Call />
              </Route>
            )}

            <Route path="/">
              <App />
            </Route>
          </Switch>
        </Suspense>
        <CookieBanner
          buttonMessage="Close"
          disableStyle
          dismissOnScroll={false}
          link={
            <span>
              {" "}
              <a href="/legal/privacy">More information</a>
            </span>
          }
          message={`${config.appName} uses cookies to offer users a better experience. By continuing to browse the site, you're agreeing to our use of cookies.`}
        />
        <NewVersionPrompt />
      </>
    );
  }
}

RouteBase.propTypes = {
  initialLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { initialLoad },
  } = state;

  return {
    initialLoad,
  };
}

export default connect(mapStateToProps)(RouteBase);
