import React from "react";
import { Button, Icon } from "semantic-ui-react";
import config from "config";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/facebook/login/`;

const FacebookButton = (props) => (
  <Button color="facebook" fluid onClick={() => (window.location.href = LOGIN_URL)} {...props}>
    <Icon name="facebook square" />
    Facebook
  </Button>
);

export default FacebookButton;
