import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { isBldAiDomain } from "utils";
import MemberItem from "./MemberItem";

import * as styles from "./styles.module.scss";

export default class Member extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      members: props.members,
    };
  }

  componentWillReceiveProps(props) {
    // Update state when props has changed.
    const cond = !isEqual(props.members, this.props.members);

    if (cond) {
      this.setState({
        members: props.members,
      });
    }
  }

  updateHourlyRate = (id, hourlyRate) => {
    this.setState(
      (prevState) => ({
        members: prevState.members.map((user) =>
          user.slug === id || user.email === id ? { ...user, hourlyRate } : user
        ),
      }),
      () => {
        const { onValuesChange } = this.props;
        if (onValuesChange) onValuesChange(this.state.members);
      }
    );
  };

  updateRole = (id, role) => {
    this.setState(
      (prevState) => ({
        members: prevState.members.map((user) =>
          user.slug === id || user.email === id ? { ...user, role } : user
        ),
      }),
      () => {
        const { onValuesChange } = this.props;
        if (onValuesChange) onValuesChange(this.state.members);
      }
    );
  };

  updateDisplayName = (email, displayName) => {
    this.setState(
      (prevState) => ({
        members: prevState.members.map((user) =>
          user.email === email ? { ...user, displayName } : user
        ),
      }),
      () => {
        const { onValuesChange } = this.props;
        if (onValuesChange) onValuesChange(this.state.members);
      }
    );
  };

  removeMember = (userToBeDeleted) => {
    this.setState(
      (prevState) => ({
        members: [...prevState.members.filter((user) => user !== userToBeDeleted)],
      }),
      () => {
        const { onValuesChange } = this.props;
        if (onValuesChange) onValuesChange(this.state.members);
      }
    );
  };

  render() {
    const { currentUser, currentUserEmail, members, sortMember, sortBy } = this.props;

    if (members.length === 0) {
      return null;
    }
    return (
      <>
        <div className={styles.sectionRowHeader}>
          <div className={styles.sectionColumnAction} />
          <div className={styles.sectionColumnAvatar} />
          <div className={styles.sectionNameColumn} />
          <div className={styles.sectionColumnAction} />
          <div className={styles.sectionColumnInput}>Rate</div>
          <div className={styles.sectionColumn}>Role</div>
        </div>
        <div className={styles.list}>
          <div className={styles.listContainer}>
            <div className={styles.memberList}>
              {sortMember(members, sortBy).map((user, index) => (
                <MemberItem
                  currentUser={currentUser}
                  isCurrentUserBldAiMember={isBldAiDomain(currentUserEmail)}
                  key={index}
                  removeMember={this.removeMember}
                  updateHourlyRate={this.updateHourlyRate}
                  updateRole={this.updateRole}
                  updateDisplayName={this.updateDisplayName}
                  user={user}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Member.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  currentUserEmail: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
  onValuesChange: PropTypes.func,
  sortMember: PropTypes.func,
  sortBy: PropTypes.string,
};

Member.defaultProps = {
  members: [],
  onValuesChange: null,
  sortMember: null,
  sortBy: "",
  currentUserEmail: "",
};
