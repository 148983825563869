import { decamelizeKeys } from 'humps';
import * as schema from 'services/schema';
import { callApi, callApiWithToken } from './base';

export const loadForm = () =>
  callApi('health/form/', schema.healthFormSchemaArray, 'GET', {
    'Content-Type': 'application/json',
  });

export const loadFormData = () =>
  callApi('health/data/', schema.healthFormDataSchemaArray, 'GET', {
    'Content-Type': 'application/json',
  });

export const updateFormData = (token, id, data) => {
  const url = id ? `health/data/${id}/` : 'health/data/';
  const method = id ? 'PATCH' : 'POST';
  return callApiWithToken(
    url,
    token,
    null,
    method,
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
};

export const loadSummaryAggregated = (token, url, qs) =>
  callApi(`health/summary/${qs}`, null, 'GET', {
    'Content-Type': 'application/json',
  });

export const loadPersonal = (token, url, qs) =>
  callApi(`health/personal/${qs}`, null, 'GET', {
    'Content-Type': 'application/json',
  });

export const loadGuide = () =>
  callApi('health/guide/', schema.healthGuideSchemaArray, 'GET', {
    'Content-Type': 'application/json',
  });
