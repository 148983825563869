import { createRequestTypes, action } from ".";

export const SKILL = createRequestTypes("SKILL");
export const TALENT_SKILL = createRequestTypes("TALENT_SKILL");
export const HIERARCHY = createRequestTypes("HIERARCHY");
export const CREATE_SKILL = "CREATE_SKILL";
export const CREATE_TALENT_SKILL = "CREATE_TALENT_SKILL";
export const UPDATE_TALENT_SKILL = "UPDATE_TALENT_SKILL";

export const skill = {
  init: (minimal = false) => action(SKILL.INIT, { minimal }),
  request: (refresh) => action(SKILL.REQUEST, { refresh }),
  success: (response) => action(SKILL.SUCCESS, { response }),
  failure: (error, errorDetails) => action(SKILL.FAILURE, { error, errorDetails }),
};

export const talentSkill = {
  request: (refresh) => action(TALENT_SKILL.REQUEST, { refresh }),
  success: (response) => action(TALENT_SKILL.SUCCESS, { response }),
  failure: (error, errorDetails) => action(TALENT_SKILL.FAILURE, { error, errorDetails }),
};

export const hierarchy = {
  init: () => action(HIERARCHY.INIT),
  request: (refresh) => action(HIERARCHY.REQUEST, { refresh }),
  success: (response) => action(HIERARCHY.SUCCESS, { response }),
  failure: (error, errorDetails) => action(HIERARCHY.FAILURE, { error, errorDetails }),
};

export const createSkill = (data) => action(CREATE_SKILL, { data });
export const createTalentSkill = (data) => action(CREATE_TALENT_SKILL, { data });
export const updateTalentSkill = (id, data) => action(UPDATE_TALENT_SKILL, { data });
