export const MESSAGES = {
  answer: {
    id: "Reminders.answer",
    defaultMessage: "Answer",
  },
  snooze: {
    id: "Reminders.snooze",
    defaultMessage: "Snooze",
  },
  disable: {
    id: "Reminders.disable",
    defaultMessage: "Disable reminder",
  },
};
