import { CHANNEL_TABS } from "actions/chat";

export const ABOUT = "about";
export const CALL = "call";
export const CHAT = "chat";
export const FEEDBACK = "feedback";
export const CLIENT_FEEDBACK = "client-feedback";
export const POLL = "poll";
export const HEALTH = "health";
export const MEETING = "meeting";
export const DISCUSSION = "discussion";

export function getActiveApp(pathname, hasCall = false) {
  const patternFeedback = /^\/chat\/r\/([0-9])+\/feedback(\/[a-z0-9]*)*$/;
  const patternClientFeedback = /^\/chat\/r\/([0-9])+\/client-feedback(\/[a-z0-9]*)*$/;
  const patternHealth = /^\/chat\/r\/([0-9])+\/health(\/[a-z0-9]*)?$/;
  const patternMeeting = /^\/chat\/r\/([0-9])+\/meetings/;
  const patternPoll = /^\/chat\/r\/([0-9])+\/polls/;
  const patternSettings = /^\/chat\/r\/([0-9])+\/settings$/;
  const patternDiscussion = /^\/chat\/r\/([0-9])+\/discussions/;

  if (patternSettings.exec(pathname)) {
    return ABOUT;
  }
  if (patternFeedback.exec(pathname)) {
    return FEEDBACK;
  }
  if (patternClientFeedback.exec(pathname)) {
    return CLIENT_FEEDBACK;
  }
  if (patternPoll.exec(pathname)) {
    return POLL;
  }
  if (patternHealth.exec(pathname)) {
    return HEALTH;
  }
  if (patternMeeting.exec(pathname)) {
    return MEETING;
  }
  if (patternDiscussion.exec(pathname)) {
    return ABOUT;
  }
  if (hasCall) {
    return CALL;
  }
  return CHAT;
}

export function getActiveAppRoomUrl(pathname, room) {
  const activeApp = getActiveApp(pathname);

  if (!room) {
    return "..";
  }

  switch (activeApp) {
    case ABOUT:
      if (room.project) {
        return `/chat/r/${room.id}/settings`;
      }
      return `/chat/r/${room.id}`;
    case FEEDBACK:
      return `/chat/r/${room.id}/feedback/dashboard/graph`;
    case CLIENT_FEEDBACK:
      return `/chat/r/${room.id}/client-feedback`;
    case HEALTH:
      return `/chat/r/${room.id}/health/team`;
    case POLL:
      return `/chat/r/${room.id}/polls`;
    case MEETING:
      return `/chat/r/${room.id}/meetings`;
    default:
      return `/chat/r/${room.id}`;
  }
}

export function getActiveTabRoomUrl(room, lastRoomTab) {
  if (!room) {
    return "..";
  }

  if (lastRoomTab) {
    switch (lastRoomTab) {
      case CHANNEL_TABS.FEED:
        return `/chat/r/${room.id}`;
      case CHANNEL_TABS.PLANNING:
        return `/chat/r/${room.id}/planning`;
      case CHANNEL_TABS.DISCUSSIONS:
        return `/chat/r/${room.id}/discussions`;
      case CHANNEL_TABS.POLLS:
        return `/chat/r/${room.id}/polls`;
      case CHANNEL_TABS.EVENTS:
        return `/chat/r/${room.id}/meetings`;
      case CHANNEL_TABS.CLIENT_FEEDBACK: {
        if (room.project) return `/chat/r/${room.id}/client-feedback`;
        break;
      }
      case CHANNEL_TABS.SETTINGS: {
        if (room.project) return `/chat/r/${room.id}/settings`;
        break;
      }
      default:
        return `/chat/r/${room.id}`;
    }
  }
  return `/chat/r/${room.id}`;
}
