import Raven from "raven-js";
import React from "react";
import { render } from "react-dom";
import config from "config";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (config.ravenApiKey && config.ravenAppId) {
  Raven.config(`https://${config.ravenApiKey}@sentry.io/${config.ravenAppId}`, {
    environment: config.ravenEnv,
    sanitizeKeys: [/^token$/, /password/i, /password1/i, /password2/i],
  }).install();
} else {
  console.log("Raven is disabled."); // eslint-disable-line no-console
}

window.location.replace("https://flow.bld.ai");
render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
