import classnames from "classnames";
import Humanize from "humanize-plus";
import { isString } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import config from "config";
import styles from "./styles.module.scss";

export default class Errors extends React.PureComponent {
  renderError(key) {
    const { errors } = this.props;
    const errorDetails = errors[key];
    if (!errorDetails) return null;

    let header = Humanize.titleCase(key.replace(/_/g, " "));
    if (key === "project" && !config.flags.profile.teams) {
      header = "Team";
    }
    let showHeader = true;
    if (key === "non_field_errors") {
      showHeader = false;
    }

    if (isString(errorDetails)) {
      return (
        <div className={classnames("content", styles.content)} key={key}>
          {showHeader && <div className="header">{header}</div>}
          <ul className="list">
            <li className="content">{errorDetails}</li>
          </ul>
        </div>
      );
    }
    if (isString(errorDetails[0])) {
      return (
        <div className={classnames("content", styles.content)} key={key}>
          {showHeader && <div className="header">{header}</div>}
          <ul className="list">
            {errorDetails.map((detail, detailIndex) => (
              <li className="content" key={detailIndex}>
                {detail}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return errorDetails.map((parentDetails, index) => {
      const parentDetailKeys = Object.keys(parentDetails).sort();
      if (!parentDetailKeys.length) return null;

      return parentDetailKeys.map((detailKey) => {
        const details = parentDetails[parentDetailKeys];
        const detailsKey = `${key} #${index + 1} ${detailKey}`;
        const detailsHeader = Humanize.titleCase(detailsKey);

        return (
          <div className={classnames("content", styles.content)} key={detailsKey}>
            <div className="header">{detailsHeader}</div>
            <ul className="list">
              {details.map((detail, detailIndex) => (
                <li className="content" key={detailIndex}>
                  {detail}
                </li>
              ))}
            </ul>
          </div>
        );
      });
    });
  }

  render() {
    const { errors } = this.props;
    const errorKeys = Object.keys(errors).sort();

    if (errorKeys.length === 0) return null;
    return <div className="ui error message">{errorKeys.map((key) => this.renderError(key))}</div>;
  }
}

Errors.propTypes = {
  errors: PropTypes.shape(),
};

Errors.defaultProps = {
  errors: {},
};
