import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "semantic-ui-react";

import { DAILY, MONTHLY, WEEKLY, YEARLY } from "./constants";
import RecurrenceCustomEnd from "./RecurrenceCustomEnd";
import RecurrenceCustomInterval from "./RecurrenceCustomInterval";
import RecurrenceRepeatsCheckbox from "./RecurrenceRepeatsCheckbox";
import RecurrenceCustomWeekday from "./RecurrenceCustomWeekday";
import styles from "./styles.module.scss";

export default class RecurrenceCustomModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      endRecurringPeriod: props.endRecurringPeriod,
      frequency: props.frequency || WEEKLY,
      repeats: true,
      ...props.params,
    };
  }

  onChange = (e, { name, value }) => this.setState({ [name]: value });

  onChangeBatch = (e, data) => this.setState({ ...data });

  onSave = () => {
    const { endRecurringPeriod, frequency, repeats, ...curparams } = this.state;
    if (frequency === WEEKLY) {
      delete curparams.bysetpos;
    }
    if (frequency === MONTHLY && !curparams.bysetpos) {
      delete curparams.bysetpos;
      delete curparams.byweekday;
    }
    if (frequency === DAILY || frequency === YEARLY) {
      delete curparams.bysetpos;
      delete curparams.byweekday;
    }
    if (Object.prototype.hasOwnProperty.call(curparams, "interval") && curparams.interval < 2) {
      delete curparams.interval;
    }
    if (Object.prototype.hasOwnProperty.call(curparams, "count") && !curparams.count) {
      delete curparams.count;
    }
    if (!repeats) {
      this.props.onSave({ endRecurringPeriod: null, frequency: "", curparams: {} });
    } else {
      this.props.onSave({ endRecurringPeriod, frequency, params: curparams });
    }
  };

  render() {
    const { open, onCancel, startDate } = this.props;
    const { endRecurringPeriod, frequency, repeats, ...params } = this.state;

    return (
      <Modal className={styles.customModal} closeIcon onClose={onCancel} open={open} size="tiny">
        <Modal.Header className={styles.customModalHeader}>
          <FormattedMessage
            defaultMessage="Custom recurrence"
            id="RecurrenceInput.modalId.header"
          />
        </Modal.Header>
        <Modal.Content className={styles.customModalContent}>
          <RecurrenceRepeatsCheckbox checked={repeats} onChange={this.onChange} />
          <RecurrenceCustomInterval
            interval={params.interval}
            frequency={frequency}
            onChange={this.onChange}
            disabled={!repeats}
          />
          <RecurrenceCustomWeekday
            bysetpos={params.bysetpos}
            byweekday={params.byweekday}
            frequency={frequency}
            startDate={startDate}
            onChange={this.onChangeBatch}
            disabled={!repeats}
          />
          <RecurrenceCustomEnd
            count={params.count}
            endRecurringPeriod={endRecurringPeriod}
            startDate={startDate}
            onChange={this.onChangeBatch}
            disabled={!repeats}
          />
          <Button fluid onClick={this.onSave} primary type="button">
            <FormattedMessage
              defaultMessage="Save custom recurrence"
              id="RecurrenceInput.modalId.save"
            />
          </Button>
        </Modal.Content>
      </Modal>
    );
  }
}

RecurrenceCustomModal.propTypes = {
  open: PropTypes.bool.isRequired,

  endRecurringPeriod: PropTypes.string,
  frequency: PropTypes.string.isRequired,
  startDate: PropTypes.shape().isRequired,
  params: PropTypes.shape(),

  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
