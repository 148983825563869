import { omitBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ATTACHMENT, ATTACHMENT_DELETE } from "actions/attachments";

export default function attachments(state = {}, action) {
  const { type } = action;
  switch (type) {
    case ATTACHMENT.REQUEST: {
      const { id, file } = action;
      const item = { ...state[id] };

      file.fileId = file.fileId || uuidv4();
      file.uploading = true;
      if (file.error) delete file.error;

      const updatedItem = {
        ...item,
        [file.fileId]: file,
      };

      return { ...state, [id]: updatedItem };
    }

    case ATTACHMENT.PROGRESS: {
      const { id, file, progress } = action;
      const item = { ...state[id] };

      file.progress = progress;

      const updatedItem = {
        ...item,
        [file.fileId]: file,
      };

      return { ...state, [id]: updatedItem };
    }

    case ATTACHMENT.SUCCESS: {
      const { id, file, response } = action;
      const item = { ...state[id] };

      delete file.uploading;
      if (file.error) delete file.error;

      const updatedItem = {
        ...item,
        [file.fileId]: {
          ...item[file.fileId],
          ...response,
        },
      };
      return { ...state, [id]: updatedItem };
    }

    case ATTACHMENT.FAILURE: {
      const { id, file, error } = action;
      const item = { ...state[id] };

      file.error = error;
      if (file.uploading) delete file.uploading;

      const updatedItem = {
        ...item,
        [file.fileId]: file,
      };

      return { ...state, [id]: updatedItem };
    }

    case ATTACHMENT_DELETE.REQUEST: {
      const { id, fileId } = action;
      const item = { ...state[id] };
      const updatedItem = omitBy(item, (file) => file.fileId === fileId || file.id === fileId);
      return { ...state, [id]: updatedItem };
    }

    case ATTACHMENT.CLEAR: {
      const { id } = action;
      return { ...state, [id]: {} };
    }
    default:
      return state;
  }
}
