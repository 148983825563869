import moment from "moment-timezone";
import { convertToLocalDate, getInitialDate } from "utils/calendar";
import { fromPairs } from "lodash";
import { DEFAULT_EVENT } from "./constants";

export const numberSearch = (options, query) => {
  const result = options.filter((opt) => opt === query);
  return result;
};

export const getParams = ({ event, projectChannels, projectMemberships, currentUser, errors }) => {
  const userTimezone = currentUser && currentUser.timezoneDisplay;
  let params = {
    initStart: event.start ? getInitialDate(event.start, userTimezone) : "",
    initEnd: event.end ? getInitialDate(event.end, userTimezone) : "",
  };

  const {
    id,
    activity,
    billable,
    callUrl,
    date,
    description,
    end,
    endRecurringPeriod,
    hours,
    invitedGuests,
    invitedOptionalGuests,
    isAllDay,
    isAvailable,
    isOutOfOffice,
    issueNumber,
    issueUrl,
    isWorkLog,
    occurrenceId,
    organizerSlug,
    originalEnd,
    originalStart,
    project,
    projectGeneralRoomId,
    roomId,
    rule,
    start,
    timezoneDisplay,
    title,
    rootEventId,
  } = event;

  const timezone = userTimezone || timezoneDisplay || moment.tz.guess();
  let activeIndex = 0;
  let outOfOfficeTitle = "Vacation Leave";
  let allGuests = true;

  if (isOutOfOffice) {
    activeIndex = 1;
    outOfOfficeTitle = title;
  } else {
    let projectMembers = [];
    let projectMembersSlugs = [];
    let selectedGuests = "everyone";
    if (projectChannels && (roomId || projectGeneralRoomId)) {
      const selectedRoomId = roomId || projectGeneralRoomId;
      const channelProject = projectChannels.find((r) => r.id === selectedRoomId);
      if (channelProject) {
        projectMembers = projectMemberships.filter((m) => m.projectId === channelProject.projectId);
        projectMembersSlugs = projectMembers.map((m) => m.slug);
        const checker = (arr, target) => target.every((a) => arr.includes(a));
        selectedGuests =
          invitedGuests &&
          invitedGuests.length >= projectMembersSlugs.length &&
          checker(invitedGuests, projectMembersSlugs)
            ? "everyone"
            : "custom";
        allGuests = selectedGuests === "everyone";
      }
    }
    params = {
      ...params,
      eventProjectMembers: projectMembers,
      eventSelectedGuests: selectedGuests,
    };
  }
  const ruleObject = {
    endRecurringPeriod: endRecurringPeriod ? moment.tz(endRecurringPeriod, timezone) : null,
    frequency: rule ? rule.frequency : "",
    params: rule && rule.params ? fromPairs(rule.params.split(";").map((s) => s.split(":"))) : {},
  };

  params = {
    ...params,
    activeIndex,
    id,
    detailsChanged: false,
    errors,
    eventActivity: activity,
    eventBillable: billable,
    eventCreator: organizerSlug,
    eventDate: date,
    eventDescription: description,
    eventEnd: end,
    eventFilterUserOrExternalEmail: "",
    eventFilteredUsersList: [],
    eventGuests: invitedGuests || [],
    eventHours: hours,
    eventIsAllDay: isAllDay,
    eventIsMeeting: !isWorkLog,
    eventIssueNumber: issueNumber,
    eventIssueUrl: issueUrl,
    eventOptionalGuests: invitedOptionalGuests || [],
    eventProject: project,
    eventProjectChannel: roomId || projectGeneralRoomId,
    eventStart: start,
    eventTimezone: timezone,
    eventTitle: title,
    eventVideo: callUrl,
    isAvailable,
    isOutOfOffice,
    occurrenceId,
    outOfOfficeBillable: billable,
    outOfOfficeEnd: end,
    outOfOfficeIsAllDay: isAllDay,
    outOfOfficeStart: start,
    outOfOfficeTimezone: timezone,
    outOfOfficeTitle,
    recurrenceChanged: false,
    ruleInput: ruleObject,
    allGuests: invitedGuests || [],
    selectAllGuests: allGuests,
    otherGuest: false,
  };

  if (id) {
    params = { ...params, originalStart, originalEnd };
  }

  if (rootEventId) {
    params = { ...params, rootEventId };
  }

  return params;
};

export const apiDataToEventObject = ({
  currentRoom,
  currentUser,
  eventOccurrenceId,
  eventOccurrences,
  events,
  fromProjectCalendar = false,
  selectedEnd,
  selectedStart,
}) => {
  let event = {};
  let eventOccurrenceRaw = {};
  let eventRaw = {};
  if (eventOccurrenceId) {
    // Existing event
    if (Number.isInteger(eventOccurrenceId)) {
      const tempEvent = events[eventOccurrenceId];
      // If event doesn't exist in store, let caller know it's not yet fully loaded
      if (!tempEvent) return { event: {}, fullyLoaded: false };
      const {
        guests: invitedGuests,
        optionalGuests: invitedOptionalGuests,
        start: originalStart,
        end: originalEnd,
      } = tempEvent;
      const { id: roomId, project } = currentRoom;
      eventOccurrenceRaw = {
        ...tempEvent,
        invitedGuests,
        invitedOptionalGuests,
        roomId,
        project,
        originalStart,
        originalEnd,
      };
    } else {
      eventOccurrenceRaw = eventOccurrences[eventOccurrenceId];
      // If eventOccurrence doesn't exist in store, let caller know it's not yet fully loaded
      if (!eventOccurrenceRaw) return { event: {}, fullyLoaded: false };
    }
    if (eventOccurrenceRaw) {
      // If eventOccurrence data is incomplete, let caller know it's not yet fully loaded
      if (!("originalStart" in eventOccurrenceRaw)) return { event: {}, fullyLoaded: false };
      if (eventOccurrenceRaw?.event) {
        eventRaw = events[eventOccurrenceRaw.event];
      }
      let { timezoneDisplay } = eventOccurrenceRaw;
      let startDate = moment(convertToLocalDate(eventOccurrenceRaw.start, timezoneDisplay));
      let endDate = moment(convertToLocalDate(eventOccurrenceRaw.end, timezoneDisplay));
      if (
        currentUser &&
        currentUser.timezoneDisplay &&
        currentUser.timezoneDisplay !== timezoneDisplay
      ) {
        timezoneDisplay = currentUser.timezoneDisplay;
        startDate = moment(convertToLocalDate(eventOccurrenceRaw.start, timezoneDisplay));
        endDate = moment(convertToLocalDate(eventOccurrenceRaw.end, timezoneDisplay));
      }
      event = {
        ...eventRaw,
        ...eventOccurrenceRaw,
        timezoneDisplay,
        date: startDate,
        start: startDate,
        end: endDate,
      };
    } else {
      event = { ...DEFAULT_EVENT, date: selectedStart, start: selectedStart, end: selectedEnd };
    }
  } else {
    // New event
    const hours = selectedEnd?.diff(selectedStart, "hours", true) || 0;
    event = {
      ...DEFAULT_EVENT,
      date: selectedStart,
      start: selectedStart,
      end: selectedEnd,
      hours,
    };
    if (fromProjectCalendar) {
      const { id: roomId, project, userSlugs: invitedGuests } = currentRoom;
      event = { ...event, roomId, project, invitedGuests };
    }
  }
  return { event, fullyLoaded: true };
};
