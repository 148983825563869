import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import stylesScreenChat from "../styles.module.scss";
import styles from "./styles.module.scss";

import MessageAttachmentListItem from "./MessageAttachmentListItem";

export default class MessageAttachments extends React.Component {
  render() {
    const { files, onRemove, onRetry } = this.props;
    const filesArray = Object.keys(files).map((fileId) => files[fileId]);

    if (isEmpty(files)) {
      return null;
    }

    return (
      <div className={styles.attachmentsWrapper}>
        <div className={stylesScreenChat.attachments}>
          {filesArray.map((file, index) => (
            <MessageAttachmentListItem
              key={`${index}-${file.id}`}
              files={filesArray}
              fileId={file.id || file.fileId}
              loading={file.uploading}
              upload
              onRemove={onRemove}
              onRetry={onRetry}
            />
          ))}
        </div>
      </div>
    );
  }
}

MessageAttachments.propTypes = {
  files: PropTypes.shape().isRequired,
  onRemove: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};
