import PropTypes from "prop-types";
import React from "react";
import { Dimmer, Loader, Responsive } from "semantic-ui-react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import { newProjectModal } from "actions/modals";
import { getCurrentRoom, getCurrentRoomId } from "reducers/selectors";
import { getRoomName, requireAuthenticationSubscription } from "utils";

import Hamburger from "containers/Sidebar/Hamburger";
import Channel from "./Channel";
import ChannelHeader from "./ChannelHeader";
import ScreenWelcome from "./ScreenWelcome";
import styles from "./styles.module.scss";
import SlugRoom from "./SlugRoom";

const TABLET_AND_BELOW = { maxWidth: 991 };

class Workspace extends React.PureComponent {
  getRoomName() {
    const { currentRoom, user, users } = this.props;
    if (!currentRoom) return "";

    const userSlugs = currentRoom.userSlugs || [];
    const roomUsers = Object.keys(users)
      .filter((u) => userSlugs.indexOf(u) >= 0)
      .map((u) => users[u]);
    return getRoomName(currentRoom, roomUsers, user);
  }

  renderMessageContainer() {
    const { currentRoomId, isRoomListLoaded, unreadMessagesLoaded, user } = this.props;

    if (isRoomListLoaded && !currentRoomId) {
      return [
        <Responsive {...TABLET_AND_BELOW} key="hamburger">
          <Hamburger />
        </Responsive>,
        <ScreenWelcome
          key="welcome"
          active
          currentUser={user}
          onStart={this.props.newProjectModalShow}
        />,
      ];
    }

    return (
      <div className={styles.messageContainerWrapper} id="messageContainerWrapper">
        <Dimmer inverted active={!isRoomListLoaded || !unreadMessagesLoaded}>
          <Loader inverted />
        </Dimmer>

        {unreadMessagesLoaded && (
          <>
            <ChannelHeader />
            <Switch>
              <Route path="/chat/r/:roomId?">
                <Channel />
              </Route>

              <Route exact path="/chat/u/:userSlug">
                <SlugRoom />
              </Route>

              <Route exact path="/chat/t/:teamSlug">
                <SlugRoom />
              </Route>

              <Route exact path="/chat">
                <Channel />
              </Route>

              <Route>
                <div />
              </Route>
            </Switch>
          </>
        )}
      </div>
    );
  }

  render() {
    const { project } = this.props;

    return (
      <>
        <Helmet key="title" defer={false}>
          <title>{`${this.getRoomName()}${
            project && project.name ? ` - ${project.name}` : ""
          }`}</title>
        </Helmet>
        {this.renderMessageContainer()}
      </>
    );
  }
}

Workspace.propTypes = {
  currentRoom: PropTypes.shape(),
  currentRoomId: PropTypes.number,
  isRoomListLoaded: PropTypes.bool,
  unreadMessagesLoaded: PropTypes.bool,
  newProjectModalShow: PropTypes.func.isRequired,
  project: PropTypes.shape(),
  user: PropTypes.shape(),
  users: PropTypes.shape(),
};

function mapStateToProps(state) {
  const {
    auth: { user },
    entities: { projects, users },
    rooms: { isRoomListLoaded },
    messages: { unreadMessagesLoaded },
  } = state;

  const currentRoom = getCurrentRoom(state);
  const currentRoomId = getCurrentRoomId(state);

  let project = {};
  if (currentRoom) {
    project = projects[currentRoom.project];
  }

  return {
    currentRoom,
    currentRoomId,
    isRoomListLoaded,
    project,
    user,
    users,
    unreadMessagesLoaded,
  };
}

export default requireAuthenticationSubscription(
  connect(mapStateToProps, {
    newProjectModalShow: newProjectModal.show,
  })(Workspace)
);
