import { createRequestTypes, action } from ".";

export const GET_WORK_LOGS = createRequestTypes("GET_WORK_LOGS");
export const CREATE_WORK_LOG = createRequestTypes("CREATE_WORK_LOG");
export const UPDATE_WORK_LOG = createRequestTypes("UPDATE_WORK_LOG");
export const DELETE_WORK_LOG = createRequestTypes("DELETE_WORK_LOG");
export const RESET_WORK_LOGS = createRequestTypes("RESET_WORK_LOGS");
export const EXPORT_WORK_LOGS = createRequestTypes("EXPORT_WORK_LOGS");

export const getWorkLogs = {
  request: (params = {}) => action(GET_WORK_LOGS.REQUEST, { params }),
  success: (response) => action(GET_WORK_LOGS.SUCCESS, { response }),
  failure: (error) => action(GET_WORK_LOGS.FAILURE, { error }),
};

export const createWorkLog = {
  request: () => action(CREATE_WORK_LOG.REQUEST),
  success: (response) => action(CREATE_WORK_LOG.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(CREATE_WORK_LOG.FAILURE, { error, errorDetails }),
};

export const updateWorkLog = {
  request: () => action(UPDATE_WORK_LOG.REQUEST),
  success: (response) => action(UPDATE_WORK_LOG.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(UPDATE_WORK_LOG.FAILURE, { error, errorDetails }),
};

export const deleteWorkLog = {
  request: () => action(DELETE_WORK_LOG.REQUEST),
  success: (response) => action(DELETE_WORK_LOG.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(DELETE_WORK_LOG.FAILURE, { error, errorDetails }),
};

export const resetWorkLogs = () => action(RESET_WORK_LOGS.INIT);

export const exportWorkLogs = {
  request: (resolve, reject, params = {}) =>
    action(EXPORT_WORK_LOGS.REQUEST, { resolve, reject, params }),
  success: (response) => action(EXPORT_WORK_LOGS.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(EXPORT_WORK_LOGS.FAILURE, { error, errorDetails }),
};
