import { combineReducers } from "redux";
import {
  SUBSCRIPTIONS,
  SUBSCRIBE,
  RESUBSCRIBE,
  UNSUBSCRIBE,
  CUSTOMER,
  PLANS,
} from "../actions/subscription";

function subscriptions(
  state = {
    initialized: false,
    error: false,
    loading: false,
    active: [],
    canceled: [],
  },
  action
) {
  const { type } = action;

  switch (type) {
    case SUBSCRIPTIONS.REQUEST:
    case SUBSCRIBE.REQUEST:
    case RESUBSCRIBE.REQUEST:
    case UNSUBSCRIBE.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SUBSCRIPTIONS.SUCCESS:
      return {
        ...state,
        initialized: true,
        loading: false,
        active: action.response.active,
        canceled: action.response.canceled,
      };

    case SUBSCRIBE.SUCCESS:
    case RESUBSCRIBE.SUCCESS:
    case UNSUBSCRIBE.SUCCESS:
      return {
        ...state,
        initialized: true,
        loading: false,
        active: [action.response, ...state.active.filter((sub) => sub.id !== action.response.id)],
      };

    case SUBSCRIPTIONS.FAILURE:
    case SUBSCRIBE.FAILURE:
    case RESUBSCRIBE.FAILURE:
    case UNSUBSCRIBE.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      if (action.error === "Subscription required") {
        return {
          ...state,
          initialized: false,
          error: false,
          loading: false,
          active: [],
          canceled: [],
        };
      }
      return state;
  }
}

function customer(
  state = {
    initialized: false,
    error: false,
    loading: false,
    data: {},
  },
  action
) {
  const { type } = action;

  switch (type) {
    case CUSTOMER.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case CUSTOMER.SUCCESS:
      return {
        ...state,
        initialized: true,
        data: action.response,
        loading: false,
      };

    case CUSTOMER.FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      if (action.error === "Subscription required") {
        return {
          ...state,
          initialized: false,
          error: false,
          loading: false,
          data: {},
        };
      }
      return state;
  }
}

function plans(
  state = {
    initialized: false,
    error: false,
    loading: false,
    data: [],
  },
  action
) {
  const { type } = action;

  switch (type) {
    case PLANS.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case PLANS.SUCCESS:
      return {
        ...state,
        initialized: true,
        data: action.response,
        loading: false,
      };

    case PLANS.FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
}

export default combineReducers({
  subscriptions,
  customer,
  plans,
});
