import classnames from "classnames";
import { isEmpty } from "lodash";
import isEqual from "lodash/isEqual";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Dropdown, Icon, Responsive } from "semantic-ui-react";

import { getSearch } from "actions/chat";
import { sidePanel, PANELS } from "actions/sidePanel";
import ScrollableContent from "components/ScrollableContent";
import MessageList from "containers/Chat/MessageList";
import { getCurrentRoom } from "reducers/selectors";

import ChannelHeaderSearch from "../../ChannelHeaderSearch";
import * as styles from "../../styles.module.scss";

class SidePanelSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: "desc",
    };
  }

  componentWillReceiveProps(props) {
    const cond = !isEqual(props.loadingSearch, this.props.loadingSearch) && !props.loadingSearch;

    if (cond) {
      const search = document.querySelector("#search").value;
      const searchSidePanel = document.querySelector("#search-sidepanel");

      const highlightClassName = `${styles.highlightedResult}`;
      const highlightedElem = document.querySelectorAll(highlightClassName);
      highlightedElem.forEach((el) => el.classList.remove(highlightClassName));

      const checkSearchResultsExist = setInterval(() => {
        if (searchSidePanel.querySelectorAll("p, h4").length > 0) {
          clearInterval(checkSearchResultsExist);

          const searchResults = searchSidePanel.querySelectorAll("p, h4");
          const substring = new RegExp(search, "gi");

          searchResults.forEach((el) => {
            const replacedText = el.innerHTML.replace(
              substring,
              (match) => `<span class="${styles.highlightedResult}">${match}</span>`
            );
            el.innerHTML = replacedText;
          });
        }
      }, 100);
    }
  }

  handleSort = (sort) => {
    const { currentRoom, loadingSearch, token } = this.props;
    const search = document.querySelector("#search").value;

    if (!search || loadingSearch) {
      return;
    }

    this.setState({ sort }, () =>
      this.props.getSearchRequest({ id: currentRoom.id, search, token, sort })
    );
  };

  handleSortAsc = () => this.handleSort("asc");

  handleSortDesc = () => this.handleSort("desc");

  handleSidePanelClose = () => {
    this.setState({ sort: "desc" }, () => {
      const { currentRoom } = this.props;
      this.props.sidePanelClose(PANELS.SEARCH, { roomId: currentRoom.id });
    });
  };

  render() {
    const {
      currentRoom,
      currentUser,
      initialSearch,
      loadingSearch,
      messageOrdering,
      messages,
      onOpenThread,
      panelId,
      roomUsers,
      users,
      visible,
    } = this.props;
    const { sort } = this.state;

    if (!visible || panelId !== PANELS.SEARCH) {
      return null;
    }

    return (
      <ScrollableContent idPrefix="search-sidepanel" containerClass={styles.sidePanelBoxShadow}>
        <div className={classnames(styles.sidePanel, "sidePanel")} id="search-sidepanel">
          <div className={styles.heading}>
            <h2>Search Results</h2>
            <div className={styles.searchSort}>
              <input type="hidden" id="search-sort" value={sort} />
              <Dropdown
                trigger={
                  <Button
                    basic
                    content="Sort By"
                    className={classnames(styles.actionButton, styles.searchButtons)}
                  />
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.handleSortDesc} active={sort === "desc"}>
                    Newest
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.handleSortAsc} active={sort === "asc"}>
                    Oldest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                basic
                content="Close"
                onClick={this.handleSidePanelClose}
                className={classnames(styles.actionButton, styles.searchButtons)}
              />
            </div>
          </div>

          <Responsive maxWidth={767}>
            <ChannelHeaderSearch className={styles.sidePanelSearch} />
          </Responsive>

          {loadingSearch && (
            <div style={{ textAlign: "center" }}>
              <Icon name="spinner" size="big" loading />
            </div>
          )}

          {initialSearch && !loadingSearch && isEmpty(messageOrdering) && (
            <div style={{ margin: 50 }}>
              <h2 style={{ textAlign: "center", color: "gray", fontSize: "16px" }}>
                No results found.
              </h2>
            </div>
          )}

          {!initialSearch && !loadingSearch && isEmpty(messageOrdering) && (
            <div style={{ margin: 50 }}>
              <p style={{ textAlign: "center", color: "gray" }}>Type to search.</p>
              <p style={{ textAlign: "center", color: "gray" }}>
                Use <code>from:</code> to search for messages posted by a user.
              </p>
            </div>
          )}

          {!loadingSearch && (
            <MessageList
              currentUser={currentUser}
              isPinned
              messages={messages}
              messageOrdering={messageOrdering}
              onOpenThread={onOpenThread}
              room={currentRoom}
              roomUsers={roomUsers}
              users={users}
              verticalAlign="top"
            />
          )}
        </div>
      </ScrollableContent>
    );
  }
}

SidePanelSearch.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  currentRoom: PropTypes.shape(),
  initialSearch: PropTypes.bool,
  loadingSearch: PropTypes.bool,
  messageOrdering: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  messages: PropTypes.objectOf(PropTypes.shape()).isRequired,
  onOpenThread: PropTypes.func.isRequired,
  panelId: PropTypes.string,
  roomUsers: PropTypes.arrayOf(PropTypes.shape()),
  token: PropTypes.string.isRequired,
  users: PropTypes.shape().isRequired,
  visible: PropTypes.bool,

  getSearchRequest: PropTypes.func.isRequired,
  sidePanelClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser, token },
    entities: { users },
    sidePanel: { panelId, visible },
  } = state;
  const currentRoom = getCurrentRoom(state);
  const messageOrdering = state.messages.searchOrdering[currentRoom.id] || [];
  const messages = state.messages.items[currentRoom.id] || {};
  const initialSearch = state.messages.initialSearch[currentRoom.id] || false;
  const loadingSearch = state.messages.loadingSearch[currentRoom.id] || false;

  let roomUsers = [];
  if (currentRoom && currentRoom.users) {
    roomUsers = currentRoom.users;
  } else if (currentRoom && !currentRoom.users) {
    roomUsers = currentRoom.userSlugs.map((slug) => users[slug]);
  }

  return {
    currentRoom,
    currentUser,
    initialSearch,
    loadingSearch,
    messageOrdering,
    messages,
    panelId,
    roomUsers,
    token,
    users,
    visible,
  };
}

export default connect(mapStateToProps, {
  getSearchRequest: getSearch.request,
  sidePanelClose: sidePanel.close,
})(SidePanelSearch);
