import { decamelizeKeys } from "humps";
import * as schema from 'services/schema';
import { callApiWithToken } from "./base";

export const getWeeklyAssignments = (token) =>
  callApiWithToken("assignments/", token, null, "GET", { "Content-Type": "application/json" });

export const createWeeklyAssignment = (token, weeklyAssignmentsData) =>
  callApiWithToken(
    "assignments/",
    token,
    null,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(weeklyAssignmentsData))
  );

export const updateWeeklyAssignment = (token, id, weeklyAssignmentsData) =>
  callApiWithToken(
    `assignments/${id}/`,
    token,
    null,
    "PATCH",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(weeklyAssignmentsData))
  );

export const getWeeklyAssignmentHistory = (token, weeklyAssignmentId, url = null) =>
  callApiWithToken(url || `assignments/${weeklyAssignmentId}/log/`, token, null, "GET");

export const setAssignmentAccess = (token, data) => callApiWithToken(
  "assignment_permissions/",
  token,
  schema.userSchemaArray,
  "POST",
  { "Content-Type": "application/json" },
  JSON.stringify(decamelizeKeys(data))
);
