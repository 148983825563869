import * as schema from 'services/schema';
import { decamelizeKeys } from 'humps';
import { callApiWithToken } from './base';

export const fetchNotifications = (token, _, qs) =>
  callApiWithToken(`notifications/${qs}`, token, schema.notificationArray);

export const postMarkReadNotification = (token) =>
  callApiWithToken('notifications/mark_all_as_read/', token, null, 'POST');

export const postNotification = (token, id, data) =>
  callApiWithToken(
    `notifications/${id}/`,
    token,
    schema.notification,
    'PUT',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
