import classnames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { NavLink as Link, matchPath, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Dropdown, Responsive, Button, Icon, Menu } from "semantic-ui-react";

import config from "config";
import { logoutInit } from "actions/account";
import { getPinnedMessages, renameRoom, setChannelTab, CHANNEL_TABS } from "actions/chat";
import { sidebar } from "actions/sidebar";
import { sidePanel, PANELS } from "actions/sidePanel";
import AvatarMenu from "components/AvatarMenu";
import ChannelOptionMenu from "components/ChannelOptionMenu";
import TopBarDropdownMenu from "components/TopBarDropdownMenu";
import NotificationBell from "containers/Notification/NotificationBell";
import {
  getCurrentProjectMemberships,
  getCurrentRoom,
  getCurrentRoomPinnedCount,
  getCurrentRoomId,
} from "reducers/selectors";
import { getRoomName, launchCall } from "utils";
import { projectMembershipType } from "types/project";
import { Roles } from "utils/constants/project";

import ChannelHeaderSearch from "./ChannelHeaderSearch";
import ModalRename from "./ModalRename";
import * as styles from "./styles.module.scss";

class ChannelHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalRename: false,
      topMenuVisible: false,
    };
  }

  componentDidMount() {
    const { room, token } = this.props;
    if (room && token) {
      this.props.getPinnedMessagesRequest({ id: room.id, token });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.room && this.props.room && prevProps.room.id !== this.props.room.id) {
      this.setState({ topMenuVisible: false });

      const { token } = this.props;
      if (token) this.props.getPinnedMessagesRequest({ id: this.props.room.id, token });
    }
  }

  getUserProjectRole() {
    const { user, projectMemberships } = this.props;
    if (user) {
      const userMembership = projectMemberships[user.slug];
      if (userMembership) {
        return userMembership.role;
      }
    }
    return "";
  }

  getRoomName() {
    const { room, user, users } = this.props;
    if (!room) return "";

    const userSlugs = room.userSlugs || [];
    const roomUsers = userSlugs.map((slug) => users[slug]).filter((u) => !!u);
    return getRoomName(room, roomUsers, user);
  }

  handleCallClick = () => {
    launchCall(this.props.room.callCode);
  };

  handleShowModalRename = () => {
    this.setState({ modalRename: true });
  };

  handleHideModalRename = () => {
    this.setState({ modalRename: false });
  };

  handleRedirect = (tabId) => {
    const { room, sidePanelId, sidePanelVisible } = this.props;
    this.props.setChannelTab(room.id, tabId);
    // on mobile view, close side panel when switching to menu tabs
    if (window.innerWidth < 991 && sidePanelVisible) {
      this.props.sidePanelClose(sidePanelId, { roomId: room.id });
    }
  };

  handleRename = (name) => {
    const { room } = this.props;
    this.props.renameRoomInit(room.id, name);
  };

  handleSidebarToggle = (e) => {
    e.stopPropagation();
    const { topMenuVisible } = this.state;
    if (!topMenuVisible) this.props.sidebarOpen();
    this.setState((prevState) => ({ topMenuVisible: !prevState.topMenuVisible }));
  };

  handleSidePanelMembersToggle = () => {
    const { room } = this.props;
    this.props.sidePanelToggle(PANELS.MEMBERS, { roomId: room.id });
  };

  handleSidePanelPinnedToggle = () => {
    const { room } = this.props;
    this.props.sidePanelToggle(PANELS.PINNED, { roomId: room.id });
  };

  handleSidePanelAttachmentsToggle = () => {
    const { room } = this.props;
    this.props.sidePanelToggle(PANELS.ATTACHMENTS, { roomId: room.id });
  };

  handleSidePanelChannelInformation = () => {
    const { room } = this.props;
    this.props.sidePanelToggle(PANELS.CHANNEL_INFO, { roomId: room.id });
  };

  handleSidePanelSearchToggle = () => {
    const { room } = this.props;
    this.props.sidePanelToggle(PANELS.SEARCH, { roomId: room.id });
  };

  renderIcon() {
    const { room } = this.props;

    if (room.hasCall) {
      return <Icon name="call" />;
    }
    if (room.isGeneral) {
      return <Icon name="globe" />;
    }
    if (room.isPrivate) {
      return <Icon name="lock" />;
    }
    return <Icon name="hashtag" />;
  }

  render() {
    const {
      hasUnreadMessages,
      pinnedCount,
      room,
      project,
      sidePanelId,
      user,
      location,
    } = this.props;

    if (!room) return <div />;

    const { modalRename, topMenuVisible } = this.state;
    const roomName = this.getRoomName();

    const isCalendar = matchPath(location.pathname, { path: "/talents/:username/calendar" });
    const isTimeTracker = matchPath(location.pathname, { path: "/talents/:username/timetracker" });
    const isHealth = matchPath(location.pathname, { path: "/talents/:username/health" });
    const isNotifications = matchPath(location.pathname, { path: "/notifications" });

    const userProjectRole = this.getUserProjectRole();
    const isProjectAdmin = Roles.adminRoles.includes(userProjectRole);

    return (
      <div className={styles.header}>
        <div
          className={classnames(
            styles.messageHeaderTextWrapper,
            room.isPrivate ? styles.private : undefined
          )}
        >
          <Responsive
            as={Button}
            icon
            onClick={this.handleSidebarToggle}
            maxWidth={991}
            className={classnames(styles.barIcon, topMenuVisible && styles.topMenuVisible)}
          >
            {topMenuVisible ? (
              <Icon name="close" />
            ) : (
              <Icon.Group>
                <Icon name="bars" />
                {hasUnreadMessages && <div className={styles.unreadMessagesNotif} />}
              </Icon.Group>
            )}
          </Responsive>
          {topMenuVisible ? (
            <>
              <TopBarDropdownMenu
                currentUser={user}
                hasUnreadMessages={hasUnreadMessages}
                isCalendar={isCalendar}
                isTimeTracker={isTimeTracker}
                isHealth={isHealth}
              />
              {config.flags.notifications && <NotificationBell active={isNotifications} />}
            </>
          ) : (
            <>
              <Dropdown
                className={styles.dropdown}
                disabled={!room.isActive}
                icon={null}
                floating
                trigger={
                  <div className={styles.messageHeaderText}>
                    <span className={styles.roomName}>
                      {project ? (
                        <span>
                          <div className={styles.projectName}>{project.name}</div>
                          <div className={styles.channelName}>
                            {this.renderIcon()} {roomName}
                          </div>
                        </span>
                      ) : (
                        <span>{roomName}</span>
                      )}
                    </span>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <ModalRename
                    open={modalRename}
                    onClose={this.handleHideModalRename}
                    trigger={
                      <Dropdown.Item onClick={this.handleShowModalRename}>
                        Rename Channel
                      </Dropdown.Item>
                    }
                    onRename={this.handleRename}
                    roomName={room ? room.name : ""}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <span className={styles.messageHeader}>
                <Responsive
                  maxWidth={767}
                  as={Button}
                  circular
                  icon="search"
                  title="Search"
                  className={styles.messageHeaderSearchToggle}
                  onClick={this.handleSidePanelSearchToggle}
                />
                <Responsive
                  minWidth={768}
                  as={ChannelHeaderSearch}
                  className={styles.messageHeaderSearch}
                />
                {config.flags.call && (
                  <Button
                    circular
                    icon
                    title="Call"
                    primary
                    className={styles.messageHeaderCall}
                    onClick={this.handleCallClick}
                  >
                    <Icon name="phone" flipped="horizontally" />
                  </Button>
                )}
                <ChannelOptionMenu
                  hasProjectUrls={Boolean(project && project.gitlabUrl)}
                  sidePanelId={sidePanelId || ""}
                  onSidePanelMembersToggle={this.handleSidePanelMembersToggle}
                  onSidePanelPinnedToggle={this.handleSidePanelPinnedToggle}
                  onSidePanelAttachmentsToggle={this.handleSidePanelAttachmentsToggle}
                  onSidePanelChannelInfoToggle={this.handleSidePanelChannelInformation}
                />
                <AvatarMenu toHideInBigScreens currentUser={user} logout={this.props.logoutInit} />
              </span>
            </>
          )}
        </div>
        <div className={styles.messageHeaderMenuWrapper}>
          <Menu pointing secondary className={styles.messageHeaderMenu}>
            {config.flags.chat && (
              <Menu.Item
                as={Link}
                name="feed"
                exact
                to={`/chat/r/${room.id}`}
                content="Feed"
                tabIndex="0"
                onClick={() => this.handleRedirect(CHANNEL_TABS.FEED)}
                id="appChat"
              />
            )}
            {room.project && room.isGeneral && isProjectAdmin && config.flags.chat && (
              <Menu.Item
                as={Link}
                name="planning"
                exact
                to={`/chat/r/${room.id}/planning`}
                content="Planning"
                tabIndex="0"
                onClick={() => this.handleRedirect(CHANNEL_TABS.PLANNING)}
                id="appPlanning"
              />
            )}
            {config.flags.discussion && (
              <Menu.Item
                as={Link}
                name="discussion"
                exact
                to={`/chat/r/${room.id}/discussions`}
                content="Discussions"
                tabIndex="0"
                onClick={() => this.handleRedirect(CHANNEL_TABS.DISCUSSIONS)}
                id="appDiscussion"
              />
            )}
            {config.flags.poll && (
              <Menu.Item
                as={Link}
                name="poll"
                to={`/chat/r/${room.id}/polls`}
                content="Polls"
                tabIndex="0"
                onClick={() => this.handleRedirect(CHANNEL_TABS.POLLS)}
                id="appPoll"
              />
            )}
            {config.flags.meeting && (
              <Menu.Item
                as={Link}
                name="event"
                to={`/chat/r/${room.id}/meetings`}
                content="Events"
                tabIndex="0"
                onClick={() => this.handleRedirect(CHANNEL_TABS.EVENTS)}
                id="appMeeting"
              />
            )}
            {room.project && config.flags.clientFeedback && (
              <Menu.Item
                as={Link}
                name="client-feedback"
                exact
                to={`/chat/r/${room.id}/client-feedback`}
                content="Client Feedback"
                tabIndex="0"
                onClick={() => this.handleRedirect(CHANNEL_TABS.CLIENT_FEEDBACK)}
                id="appFeedback"
              />
            )}
            {room.project && room.isGeneral && (
              <Menu.Item
                as={Link}
                name="settings"
                exact
                to={`/chat/r/${room.id}/settings`}
                content={`${config.flags.profile.teams ? "Project" : "Team"} Settings`}
                tabIndex="0"
                onClick={() => this.handleRedirect(CHANNEL_TABS.SETTINGS)}
                id="appSettings"
              />
            )}
            {project && project.gitlabUrl && (
              <Menu.Item
                as="a"
                content="Gitlab"
                href={project.gitlabUrl}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.teamLink}
              >
                <span>Gitlab</span>
                <Icon name="external square alternate" />
              </Menu.Item>
            )}
            {project && project.googleDriveFolderId && user.email.endsWith("@bld.ai") && (
              <Menu.Item
                as="a"
                content="GDrive folder"
                href={`https://drive.google.com/drive/folders/${project.googleDriveFolderId}`}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.teamLink}
              >
                <span>GDrive folder</span>
                <Icon name="external square alternate" />
              </Menu.Item>
            )}
          </Menu>
          <div
            className={classnames(
              styles.channelOptions,
              project && project.gitlabUrl && styles.hasProjectUrls
            )}
          >
            <Button
              icon="user"
              size="tiny"
              title="Members"
              content={room.userSlugs.length}
              onClick={this.handleSidePanelMembersToggle}
              onKeyPress={this.handleSidePanelMembersToggle}
              className={sidePanelId === PANELS.MEMBERS ? styles.activeBtn : ""}
            />
            <Button
              icon="pin"
              size="tiny"
              title="Pinned"
              onClick={this.handleSidePanelPinnedToggle}
              onKeyPress={this.handleSidePanelPinnedToggle}
              content={pinnedCount}
              className={sidePanelId === PANELS.PINNED ? styles.activeBtn : ""}
            />
            <Button
              icon="attach"
              size="tiny"
              title="Attachments"
              onClick={this.handleSidePanelAttachmentsToggle}
              onKeyPress={this.handleSidePanelAttachmentsToggle}
              className={sidePanelId === PANELS.ATTACHMENTS ? styles.activeBtn : ""}
            />
            {room.project && (
              <Button
                icon="info circle"
                size="tiny"
                title="Info"
                onClick={this.handleSidePanelChannelInformation}
                onKeyPress={this.handleSidePanelChannelInformation}
                className={sidePanelId === PANELS.CHANNEL_INFO ? styles.activeBtn : ""}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ChannelHeader.propTypes = {
  hasUnreadMessages: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  pinnedCount: PropTypes.number.isRequired,
  project: PropTypes.shape(),
  projectMemberships: PropTypes.objectOf(projectMembershipType).isRequired,
  renameRoomInit: PropTypes.func.isRequired,
  room: PropTypes.shape(),
  getPinnedMessagesRequest: PropTypes.func.isRequired,
  logoutInit: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.func.isRequired,
  sidePanelId: PropTypes.string,
  sidePanelVisible: PropTypes.bool.isRequired,
  sidePanelClose: PropTypes.func.isRequired,
  sidePanelToggle: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  user: PropTypes.shape(),
  users: PropTypes.objectOf(PropTypes.shape()),
  setChannelTab: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    auth: { user, token },
    entities: { healthFormData: healthFormDataList, projects, users },
    messages: { loading: messagesLoading, unreadMessagesCount },
    rooms: { callDisconnecting, callStarted },
    sidePanel: { panelId, visible },
  } = state;
  const currentRoom = getCurrentRoom(state);
  const currentRoomId = getCurrentRoomId(state);

  const userFormDataId = Object.keys(healthFormDataList).find(
    (f) => healthFormDataList[f].submittedBy === user.id
  );
  const userHealthSubmission = healthFormDataList[userFormDataId] || {};

  const isLoadingMessages = !!messagesLoading[currentRoomId];
  const hasCall = callStarted && !callDisconnecting;
  const hasUnreadMessages = unreadMessagesCount > 0;
  const pinnedCount = getCurrentRoomPinnedCount(state, currentRoomId);

  const project = currentRoom ? projects[currentRoom.project] : null;
  const projectMemberships = getCurrentProjectMemberships(state);

  return {
    hasCall,
    hasHealthSubmission: !isEmpty(userHealthSubmission),
    hasUnreadMessages,
    isLoadingMessages,
    pinnedCount,
    room: currentRoom,
    project,
    projectMemberships,
    sidePanelId: panelId,
    sidePanelVisible: visible,
    token,
    user,
    users,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    logoutInit,
    getPinnedMessagesRequest: getPinnedMessages.request,
    renameRoomInit: renameRoom.init,
    sidebarOpen: sidebar.open,
    sidePanelClose: sidePanel.close,
    sidePanelToggle: sidePanel.toggle,
    setChannelTab,
  })(ChannelHeader)
);
