import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { NavLink as Link } from "react-router-dom";
import { Grid, Icon, Popup, Item, Transition } from "semantic-ui-react";

import { MESSAGES } from "../i18n";
import styles from "../styles.module.scss";

export default class HealthCard extends React.PureComponent {
  onDisable = () => {
    const {
      data: { id },
    } = this.props;
    this.props.update(id, { disable: true });
  };

  onSnooze = (e, snoozeBy) => {
    const {
      data: { id },
    } = this.props;
    this.props.update(id, { snooze: snoozeBy });
  };

  render() {
    const { data, intl, onFocus, visible } = this.props;
    const { formatMessage: _f } = intl;
    const timestamp = moment().format("h:mm a");
    const link = (data.context || {}).link || "";

    return (
      <Transition visible={visible} animation="fade" duration={500}>
        <div className={styles.reminder}>
          <Grid divided>
            <Grid.Row>
              <Grid.Column width={11}>
                <div className={styles.content}>
                  <span className={styles.icon}>
                    <Icon name="heartbeat" />
                  </span>
                  <p>
                    <span className={styles.title}>{data.title}</span>
                    <span className={styles.time}>{timestamp}</span>
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column width={5} className={styles.buttons}>
                <div className={styles.action}>
                  {link ? <Link to={link}>{_f(MESSAGES.answer)}</Link> : <span>{_f(MESSAGES.answer)}</span>}
                </div>
                <Popup
                  basic
                  content={
                    <Item.Group className={styles.options}>
                      {data.snoozeOptions.map((o) => (
                        <Item key={o[0]} onClick={(e) => this.onSnooze(e, o[0])}>
                          {o[1]}
                        </Item>
                      ))}
                      <Item onClick={this.onDisable}>{_f(MESSAGES.disable)}</Item>
                    </Item.Group>
                  }
                  hideOnScroll
                  hoverable
                  on="click"
                  position="bottom right"
                  trigger={
                    <div className={styles.snooze}>
                      <span
                        styles={{ outline: "none" }}
                        onClick={onFocus}
                        onKeyDown={onFocus}
                        role="button"
                        tabIndex="0"
                      >
                        {_f(MESSAGES.snooze)}
                      </span>
                    </div>
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Transition>
    );
  }
}

HealthCard.propTypes = {
  data: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  onFocus: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
