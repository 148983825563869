import classnames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Header, Icon } from "semantic-ui-react";
import { convertToLocalDate } from "utils/calendar";
import { injectIntl } from "react-intl";
import CalendarEventAddModal from "components/CalendarEventAddModal";

import MessageListItemEventGuestList from "./MessageListItemEventGuestList";
import styles from "./styles.module.scss";

class MessageListItemEvent extends React.Component {
  constructor(props) {
    super(props);
    this.eventAddModalRef = React.createRef();

    this.state = {
      addModal: false,
      occurrenceId: null,
    };
  }

  handleMeetingJoin = (join, event) => {
    this.props.onMeetingJoin(join, event);
  };

  handleWorkLogModalOpen = ({ id: occurrenceId }) => {
    const { isActive } = this.props;
    if (isActive) {
      this.setState({
        addModal: true,
        occurrenceId,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      addModal: false,
      occurrenceId: null,
    });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  renderActions = () => {
    const { currentUser, event, isActive, maximized, room } = this.props;
    const { formatMessage: _f } = this.props.intl;
    const { addModal, occurrenceId } = this.state;

    let currentEvent = { ...event };
    if (event) {
      let { timezoneDisplay } = event;
      // compute the start and end date of the event based on the event's timezone
      let startDate = convertToLocalDate(event.start, timezoneDisplay);
      let endDate = convertToLocalDate(event.end, timezoneDisplay);

      // if the user has a different timezone, localize the start and end date of the event
      if (
        currentUser &&
        currentUser.timezoneDisplay &&
        currentUser.timezoneDisplay !== timezoneDisplay
      ) {
        timezoneDisplay = currentUser.timezoneDisplay;
        startDate = convertToLocalDate(event.start, timezoneDisplay);
        endDate = convertToLocalDate(event.end, timezoneDisplay);
      }
      currentEvent = { ...event, start: startDate, end: endDate };
    }

    return (
      <div className={styles.messageListItemEventActions}>
        <CalendarEventAddModal
          key={`addModal-${occurrenceId}`}
          ref={this.eventAddModalRef}
          open={addModal}
          formatMessage={_f}
          onClose={this.handleCancel}
          eventOccurrenceId={occurrenceId}
          onChange={(newState) => this.setState(newState)}
        />
        <Button.Group basic size="mini">
          <Button
            disabled={!isActive}
            icon={{ name: "pencil", style: { fontWeight: 900 } }}
            onClick={() => this.handleWorkLogModalOpen({ ...currentEvent })}
          />

          {!maximized && (
            <Button
              as={Link}
              icon={{ name: "expand arrows alternate", style: { fontWeight: 400 } }}
              to={`/chat/r/${room.id}/meetings/${event.id}`}
            />
          )}
        </Button.Group>
      </div>
    );
  };

  renderGuests = () => {
    const { event, roomUsers, showAllGuests } = this.props;
    const totalCount = event.invitations ? event.invitations.length : 0;
    return (
      <div className={styles.messageListItemEventGuests}>
        <div className={styles.messageListItemEventGuestsHeader}>{`${totalCount} guests`}</div>
        <MessageListItemEventGuestList
          invitations={event.invitations}
          roomUsers={roomUsers}
          showAllGuests={showAllGuests}
          totalCount={totalCount}
        />
      </div>
    );
  };

  renderFooter = () => {
    const { currentUser, event, room } = this.props;
    const index = (event.invitations || []).findIndex((i) => i.user === currentUser.slug);
    let accepted = false;
    let declined = false;
    if (index >= 0) {
      const { status } = event.invitations[index];
      if (status === "accepted") {
        accepted = true;
      } else if (status === "declined") {
        declined = true;
      }
    }

    return (
      <div className={styles.messageListItemEventFooter}>
        <p>Going?</p>
        <div className={styles.messageListItemEventFooterBtns}>
          <Button
            basic
            className={styles.messageListItemMeetingFooterBtn}
            compact
            content="Yes"
            disabled={accepted || event.loading || !room.isActive}
            loading={!accepted && event.loading}
            onClick={() => this.handleMeetingJoin(true, event)}
          />
          <Button
            basic
            className={styles.messageListItemMeetingFooterBtn}
            compact
            content="No"
            disabled={declined || event.loading || !room.isActive}
            loading={!declined && event.loading}
            onClick={() => this.handleMeetingJoin(false, event)}
          />
        </div>
      </div>
    );
  };

  render() {
    const { event, maximized, modalView, currentUser } = this.props;
    if (!event) return null;
    // TODO: Remove when viewer modal ticket is merged
    // temporarily hide unrelated items to worklog until viewer modal ticket is merged
    const isMeeting = !event.savedAsWorkLog && !event.isOutOfOffice;
    let dateLabel = "";
    const timezone =
      currentUser && currentUser.timezoneDisplay
        ? currentUser.timezoneDisplay
        : event.timezoneDisplay;

    const start = moment.tz(event.start, timezone);
    const end = moment.tz(event.end, timezone);

    const multiDay = start.date() !== end.date();
    const { rule } = event;
    if (rule && rule.frequency) {
      const frequencyLabel = event.ruleDisplay;
      switch (rule.frequency) {
        case "WEEKLY":
          dateLabel = `${start.format("ddd, h:mm A")} - ${end.format("h:mm A")} (${timezone})`;
          if (multiDay) {
            dateLabel = `${start.format("MMM D, h:mm A")} - ${end.format(
              "MMM D, h:mm A"
            )} (${timezone})`;
          }
          break;
        case "MONTHLY":
          dateLabel = `${start.format("ddd, MMMM D, h:mm A")} - ${end.format(
            "h:mm A"
          )} (${timezone})`;
          if (multiDay) {
            dateLabel = `${start.format("MMM D, h:mm A")} - ${end.format(
              "MMM D, h:mm A"
            )} (${timezone})`;
          }
          break;
        case "YEARLY":
          dateLabel = `${start.format("MMMM D, YYYY h:mm A")} - ${end.format(
            "h:mm A"
          )} (${timezone})`;
          if (multiDay) {
            dateLabel = `${start.format("MMM D, YYYY h:mm A")} - ${end.format(
              "MMM D, YYYY h:mm A"
            )} (${timezone})`;
          }
          break;
        default:
          dateLabel = `${start.format("h:mm A")} - ${end.format("h:mm A")} (${timezone})`;
          if (multiDay) {
            dateLabel = `${start.format("MMM D, h:mm A")} - ${end.format(
              "MMM D, h:mm A"
            )} (${timezone})`;
          }
          break;
      }
      dateLabel = `${dateLabel}\n${frequencyLabel}`;
    } else {
      dateLabel = `${start.format("ddd, MMMM D, YYYY h:mm A")} - ${end.format(
        "h:mm A"
      )} (${timezone})`;
      if (multiDay) {
        dateLabel = `${start.format("MMM D, h:mm A")} - ${end.format(
          "MMM D, h:mm A"
        )} (${timezone})`;
      }
    }

    return (
      <div
        id={`event-${event.id}`}
        className={classnames(
          styles.messageListItemEvent,
          maximized ? styles.maximized : null,
          modalView ? styles.modalView : null
        )}
      >
        {isMeeting && this.renderActions()}
        <div className={styles.messageListItemEventHeader}>
          <Icon name="calendar outline" />
          <Header as="h4">{event.title}</Header>
        </div>
        <div className={styles.messageListItemEventDate}>{dateLabel}</div>
        {event.callUrl ? (
          <div className={styles.messageListItemEventCallUrl}>
            <a href={event.callUrl} target="_blank" rel="noopener noreferrer">
              <Icon name="video camera" />
              {event.callUrl}
            </a>
          </div>
        ) : null}
        {isMeeting && (
          <>
            <Divider />
            {this.renderGuests()}
            <Divider />
            {this.renderFooter()}
          </>
        )}
      </div>
    );
  }
}

MessageListItemEvent.propTypes = {
  currentUser: PropTypes.shape(),
  event: PropTypes.shape(),
  isActive: PropTypes.bool.isRequired,
  maximized: PropTypes.bool,
  modalView: PropTypes.bool,
  onMeetingJoin: PropTypes.func,
  room: PropTypes.shape(),
  roomUsers: PropTypes.arrayOf(PropTypes.shape()),
  showAllGuests: PropTypes.bool,
  intl: PropTypes.shape().isRequired,
};

MessageListItemEvent.defaultProps = {
  event: null,
  maximized: false,
  showAllGuests: false,
};

export default injectIntl(MessageListItemEvent);
