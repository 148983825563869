import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

export default class SemanticTableRenderer extends React.PureComponent {
  render() {
    const { children } = this.props;
    return (
      <Table celled striped collapsing>
        {children}
      </Table>
    );
  }
}

SemanticTableRenderer.propTypes = {
  children: PropTypes.node.isRequired,
};
