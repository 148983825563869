/* eslint-disable react/no-did-update-set-state */

import classnames from "classnames";
import { join } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { trimString, generateColor } from "utils";

import imgAnon from "./anon.png";
import styles from "./styles.module.scss";

const AVATAR_DEFAULT_FONTS = ["Helvetica", "Arial", "sans-serif"];

class UserAvatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bgColorDefault: generateColor(true, props.displayName),
      loadError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.displayName !== this.props.displayName) {
      this.setState({
        bgColorDefault: generateColor(true, this.props.displayName),
        loadError: false,
      });
    }
  }

  getInitials = (displayName) => {
    const names = trimString(displayName).split(" ");
    let initials = "";
    names.forEach((item, idx) => {
      const trimmedItem = trimString(item);
      if ((idx === 0 || idx === names.length - 1) && trimmedItem) {
        initials += trimmedItem[0];
      }
    });
    return initials;
  };

  renderImage = (src, className) => {
    const { displayName, size, style, round, bgColor } = this.props;
    const sizeInPixels = `${size}px`;

    const imgStyle = {
      backgroundColor: "rgb(80, 80, 80)",
      border: `2px solid ${bgColor}`,
      borderRadius: "unset",
      height: sizeInPixels,
      maxWidth: "100%",
      width: sizeInPixels,
      ...style,
    };

    if (round) {
      imgStyle.borderRadius = "100%";
    }

    return (
      <img
        src={src}
        height={sizeInPixels}
        width={sizeInPixels}
        style={imgStyle}
        alt={displayName}
        className={className}
        onError={() => {
          this.setState({
            loadError: true,
          });
        }}
      />
    );
  };

  renderText = (className) => {
    const { fgColor, bgColor, displayName, round, size, style } = this.props;
    const { bgColorDefault } = this.state;
    const initials = this.getInitials(displayName);
    const fontSize = parseInt(size, 10) / 2.7;
    const textStyle = {
      backgroundColor: bgColor || bgColorDefault,
      borderRadius: "unset",
      color: fgColor || "#FFFFFF",
      border: `2px solid ${bgColor}`,
      cursor: "default",
      fontSize: `${fontSize}px`,
      fontStyle: "normal",
      fontWeight: "lighter",
      lineHeight: `${size}px`,
      height: `${size}px`,
      textAlign: "center",
      width: `${size}px`,
      fontFamily: join(AVATAR_DEFAULT_FONTS, ","),
      ...style,
    };
    if (round) {
      textStyle.borderRadius = "100%";
    }
    return (
      <div style={textStyle} className={className}>
        <span>{initials}</span>
      </div>
    );
  };

  render() {
    const {
      avatar,
      check,
      className,
      close,
      float,
      inline,
      isAnonymous,
      online,
      round,
      size,
      star,
    } = this.props;
    const { loadError } = this.state;
    let imageGroupStyle;
    let avatarClass = styles.avatar;
    let avatarSrc = avatar;
    const containerStyle = {
      borderRadius: "unset",
      display: "inline-block",
      verticalAlign: "middle",
    };

    if (size) {
      const sizeInPixels = `${size}px`;
      imageGroupStyle = { minHeight: sizeInPixels, minWidth: sizeInPixels };
      containerStyle.height = sizeInPixels;
      containerStyle.width = sizeInPixels;
    }
    if (inline) {
      imageGroupStyle.display = "inline-block";
      imageGroupStyle.marginRight = "5px";
    }
    if (round) {
      containerStyle.borderRadius = "100%";
    }
    if (className) {
      avatarClass = className;
    }
    if (isAnonymous) {
      avatarSrc = imgAnon;
    }

    return (
      <div className={classnames(styles.avatarWrapper, className, float)} style={imageGroupStyle}>
        <Icon.Group size="large">
          <div style={containerStyle} className={avatarClass}>
            {avatarSrc && !loadError
              ? this.renderImage(avatarSrc, avatarClass)
              : this.renderText(avatarClass)}
          </div>
          {online !== null && !star && (
            <Icon
              className={styles.circle}
              corner
              name="circle"
              color={online ? "green" : "grey"}
            />
          )}
        </Icon.Group>
        {star && <Icon className={classnames(styles.icon, styles.star)} name="star" />}
        {check && <Icon className={classnames(styles.icon, styles.check)} name="check" />}
        {close && <Icon className={classnames(styles.icon, styles.close)} name="close" />}
      </div>
    );
  }
}

UserAvatar.propTypes = {
  avatar: PropTypes.string,
  bgColor: PropTypes.string,
  check: PropTypes.bool,
  className: PropTypes.string,
  close: PropTypes.bool,
  displayName: PropTypes.string,
  fgColor: PropTypes.string,
  float: PropTypes.oneOf(["left", "right"]),
  inline: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  online: PropTypes.bool,
  round: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  star: PropTypes.bool,
  style: PropTypes.shape(),
};

UserAvatar.defaultProps = {
  avatar: null,
  bgColor: null,
  check: false,
  className: null,
  close: false,
  displayName: "",
  fgColor: null,
  float: null,
  inline: false,
  isAnonymous: false,
  online: null,
  round: true,
  size: 36,
  star: false,
};

export default UserAvatar;
