import moment from "moment-timezone";

export const convertToDate = (datetime) =>
  new Date(
    datetime.year(),
    datetime.month(),
    datetime.date(),
    datetime.hour(),
    datetime.minute(),
    0
  );

export const convertToLocalDate = (datetime, timezone) => {
  const m = timezone ? moment.tz(datetime, timezone) : moment(datetime);
  return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0);
};

export const convertToLocalMoment = (datetime, timezone) => {
  if (timezone) {
    return moment.tz(datetime, timezone);
  }
  return moment(datetime);
};

export const convertToNaiveMoment = (datetime) => moment(convertToDate(datetime));

export const getCurrentDate = (timezoneDisplay) => {
  const currentDate = new Date();
  if (timezoneDisplay) {
    return convertToLocalDate(currentDate, timezoneDisplay);
  }
  return currentDate;
};

export const getCurrentMoment = (timezoneDisplay) => moment(getCurrentDate(timezoneDisplay));

export const getInitialDate = (datetime, timezone) => {
  /**
   * Events in the calendar are converted to their local date and time (based on the user's timezone)
   * BUT since `react-big-calendar` uses the system's timezone, those converted events are represented
   * using the system's timezone. So to get the correct initial value, the event must be reverted
   * back it it's original state before calling `toISOString()`
   */

  if (timezone !== moment.tz.guess()) {
    // make the datetime timezone-unaware
    const date = convertToDate(moment(datetime));
    // convert it to it's local time, then make it timezone-unaware as well
    const originalDate = convertToDate(convertToLocalMoment(date, timezone));
    return moment(originalDate).toISOString;
  }
  return moment(datetime).toISOString();
};
