import classnames from "classnames";
import Fuse from "fuse.js";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "semantic-ui-react";

import { projectType } from "types/project";

import config from "config";
import Project from "./Project";
import styles from "./styles.module.scss";

export default class ProjectList extends React.PureComponent {
  componentDidMount() {
    this.initFuse();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projects !== this.props.projects) {
      this.initFuse();
    }
  }

  initFuse = () => {
    this.projectSearcher = new Fuse(this.props.projects, {
      distance: 300,
      keys: ["name"],
      location: 0,
      minMatchCharLength: 1,
      shouldSort: true,
      threshold: 0.2,
    });
  };

  render() {
    const {
      collapsed,
      currentRoom,
      onNewClick,
      onToggleCollapse,
      projects,
      projectHomeRoomMapping,
      projectRooms,
      searchValue,
      title,
      unreadMessagesCountByRoom,
      isArchived,
      lastRoomTab,
    } = this.props;
    let projectsFiltered = projects;
    let isUnread = false;
    if (isArchived) {
      const hasUnread = (room) => unreadMessagesCountByRoom[room.id] > 0;
      for (const rooms of Object.values(projectRooms)) {
        isUnread = rooms.some(hasUnread);
        if (isUnread) break;
      }
    }

    const projectSearchValue = searchValue.split("#")[0] || searchValue;
    if (projectSearchValue) {
      projectsFiltered = this.projectSearcher.search(projectSearchValue).map(({ item }) => item);
    }

    return (
      <div className={classnames(styles.sidebarSection, collapsed && isUnread && styles.unread)}>
        <header className={styles.sidebarSectionHeader}>
          {onToggleCollapse === undefined && (
            <h2 className={styles.sidebarSectionHeaderText}>
              <Icon name={config.flags.profile.teams ? "briefcase" : "users"} />
              {title}
            </h2>
          )}
          {onToggleCollapse !== undefined && (
            <span className={styles.sidebarSectionHeaderContent}>
              <h2
                className={classnames(styles.sidebarSectionHeaderText, styles.collapsible)}
                onClick={onToggleCollapse}
                onKeyPress={onToggleCollapse}
                role="presentation"
                tabIndex="-1"
              >
                <Icon name={config.flags.profile.teams ? "briefcase" : "users"} />
                {title}
              </h2>
              <div className={styles.unreadProjectListNotif} />
            </span>
          )}

          {onNewClick && (
            <button
              className={styles.sidebarSectionHeaderButton}
              onClick={onNewClick}
              type="button"
            >
              <Icon name="plus" />
            </button>
          )}
          {onToggleCollapse !== undefined && (
            <button
              className={styles.sidebarSectionHeaderButton}
              onClick={onToggleCollapse}
              type="button"
            >
              <Icon name={collapsed ? "chevron right" : "chevron down"} />
            </button>
          )}
        </header>

        {(onToggleCollapse === undefined || !collapsed) &&
          projectsFiltered.map((project) => (
            <Project
              currentRoom={currentRoom}
              isCollapsed={!!currentRoom && currentRoom.project === project.id}
              key={project.id}
              searchValue={searchValue}
              project={project}
              projectHomeRoomId={projectHomeRoomMapping[project.id]}
              projectRooms={projectRooms[project.id]}
              unreadMessagesCountByRoom={unreadMessagesCountByRoom}
              setChannelTab={this.props.setChannelTab}
              lastRoomTab={lastRoomTab}
            />
          ))}
      </div>
    );
  }
}

ProjectList.propTypes = {
  currentRoom: PropTypes.shape(),
  collapsed: PropTypes.bool,
  onNewClick: PropTypes.func,
  onToggleCollapse: PropTypes.func,
  projectHomeRoomMapping: PropTypes.objectOf(PropTypes.number),
  projectRooms: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape())),
  projects: PropTypes.arrayOf(projectType),
  searchValue: PropTypes.string,
  title: PropTypes.string.isRequired,
  unreadMessagesCountByRoom: PropTypes.objectOf(PropTypes.number).isRequired,
  setChannelTab: PropTypes.func.isRequired,
  isArchived: PropTypes.bool,
  lastRoomTab: PropTypes.string,
};

ProjectList.defaultProps = {
  currentRoom: null,
  projectRooms: {},
  projects: [],
  searchValue: "",
};
