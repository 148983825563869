import Humanize from "humanize-plus";
import { filter, sortBy } from "lodash";

export function createNotification(message) {
  createNotification.id = createNotification.id || 0;
  createNotification.id += 1;
  return { message, id: createNotification.id };
}

export function createOwnMessage(
  id,
  content,
  lastTimeStampCurrent,
  user,
  actions,
  privateTo,
  files,
  root
) {
  return {
    content,
    id,
    actions: actions || [],
    privateTo: privateTo || null,
    files: files || [],
    author: user ? user.slug : null,
    authorName: user ? user.displayName : null,
    created: lastTimeStampCurrent + 1,
    hasRead: true,
    loading: true,
    error: null,
    root,
  };
}

export function getLabelRoomName(room, users, currentUser) {
  if (!room) {
    return "(Unnamed)";
  }

  if (Object.keys(room).length === 0) {
    return "(Unnamed)";
  }

  if (room.name) {
    return room.name;
  }

  if (!currentUser) {
    return "Loading...";
  }

  const emails = sortBy(
    filter(users, (user) => user && user.slug !== currentUser.slug).map((user) => user.email)
  );
  if (emails.length === 0) {
    return "(Unnamed)";
  }

  return Humanize.oxford(emails, 3);
}

export function getRoomName(room, users, currentUser) {
  if (!room) return "(Unnamed)";
  if (!Object.keys(room).length) return "(Unnamed)";
  if (room.name) return room.name;
  if (room.displayName) return room.displayName;

  const displayNames = sortBy(
    filter(users, (user) => user.slug !== currentUser.slug).map((user) => user.displayName)
  );
  if (displayNames.length === 0) return "(Unnamed)";
  return Humanize.oxford(displayNames, 3);
}

const TALENTED_CALL_PREFIX = "__$talented$__";

export const callWindows = {};

export function launchCall(callCode) {
  const windowId = `${TALENTED_CALL_PREFIX}${callCode}`;

  if (callWindows[windowId] && !callWindows[windowId].closed) {
    return;
  }

  callWindows[windowId] = window.open(
    `/call/${callCode}`,
    windowId,
    `width=${window.screen.width / 2},height=${(3 * window.screen.width) / 8}`
  );
}

export function closeAllCalls() {
  Object.keys(callWindows).map((windowId) => callWindows[windowId].close());
}

export function computePickerPosition(target) {
  const pickerHeight = 427;
  const pickerWidth = 276;
  const style = {};
  if (target) {
    const boundingBox = target.getBoundingClientRect();
    const parentBoundingBox = target.closest(".modal")
      ? target.closest(".modal").getBoundingClientRect()
      : {
          top: 0,
          left: 0,
          right: window.innerWidth,
          bottom: window.innerHeight,
        };
    const upward = boundingBox.top > pickerHeight;
    const leftward = boundingBox.left + pickerWidth > parentBoundingBox.right;

    if (upward) {
      style.top = boundingBox.top - parentBoundingBox.top - pickerHeight;
    } else {
      style.top = boundingBox.bottom - parentBoundingBox.top;
    }

    if (leftward) {
      style.right = parentBoundingBox.right - boundingBox.right;
    } else {
      style.left = boundingBox.left - parentBoundingBox.left;
    }
  }
  return style;
}
