import { decamelizeKeys } from 'humps';
import { callApiWithToken } from './base';

export const updateReminder = (token, id, data) => {
  const url = `reminders/${id}/`;
  const method = 'PATCH';
  return callApiWithToken(
    url,
    token,
    null,
    method,
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
};
