/* eslint-disable jsx-a11y/label-has-for */

import classnames from "classnames";
import hoistNonReactStatic from "hoist-non-react-statics";
import PropTypes from "prop-types";
import React from "react";
import { Input, TextArea, Dropdown } from "semantic-ui-react";

import { getComponentName } from "utils";

import DateTimeDropdown from "./DateTimeDropdown";
import styles from "./styles.module.scss";

export const withLabel = (Component) => {
  class WithLabel extends React.PureComponent {
    render() {
      const {
        containerClassName,
        forwardedRef,
        id,
        label,
        labelClassName,
        componentClassName,
        ...passThroughProps
      } = this.props;

      if (!label) return <Component id={id} ref={forwardedRef} {...passThroughProps} />;

      return (
        <div className={containerClassName}>
          <label className={classnames(labelClassName, styles.labelText)} htmlFor={id}>
            {label}
          </label>
          <Component
            id={id}
            className={componentClassName}
            ref={forwardedRef}
            {...passThroughProps}
          />
        </div>
      );
    }
  }

  WithLabel.propTypes = {
    ...Component.propTypes,
    containerClassName: PropTypes.string,
    componentClassName: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
  };

  WithLabel.defaultProps = {
    ...Component.defaultProps,
    containerClassName: null,
    componentClassName: null,
    label: null,
    labelClassName: null,
  };

  WithLabel.displayName = `WithLabel(${getComponentName(Component)})`;
  hoistNonReactStatic(WithLabel, Component);

  return React.forwardRef((props, ref) => <WithLabel {...props} forwardedRef={ref} />);
};

export const LabeledDateTimeDropdown = withLabel(DateTimeDropdown);
export const LabeledDropdown = withLabel(Dropdown);
export const LabeledInput = withLabel(Input);
export const LabeledTextArea = withLabel(TextArea);
