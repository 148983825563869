import classnames from "classnames";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import debounceRender from "react-debounce-render";
import Diff from "react-diffy";
import ReactMarkdown from "react-markdown";
import { NavLink as Link } from "react-router-dom";
import { Button, Card, Icon, Label } from "semantic-ui-react";

import { plugins, renderers } from "components/Markdown";
import { mentionParser } from "components/Markdown/plugins/MentionPlugin";
import UserAvatar from "components/UserAvatar";
import ContactModal from "components/ContactModal";
import { sentinelSlug } from "utils/constants";
import { PollRecurringType } from "utils/constants/poll";

import MessageAttachmentListItem from "../MessageAttachmentList/MessageAttachmentListItem";
import styles from "./styles.module.scss";
import MessageListItemEvent from "./MessageListItemEvent";
import MessageListItemHeader from "./MessageListItemHeader";
import MessageListItemOptions from "./MessageListItemOptions";
import MessageListItemPoll from "./MessageListItemPoll";
import Reaction from "./Reaction";

const STEP_VOTE = "STEP_VOTE";
const STEP_RESULTS = "STEP_RESULTS";

function getDiffOld(diff) {
  if (diff.what === "recurring") {
    return PollRecurringType.labels[diff.old];
  }

  return diff.old;
}

function getDiffNew(diff) {
  if (diff.what === "recurring") {
    return PollRecurringType.labels[diff.new];
  }

  return diff.new;
}

class MessageListItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      overflown: false,
      bodyClasses: [styles.body],
      pollStep: "",
    };

    this.plugins = [
      ...plugins,
      [
        mentionParser,
        {
          validate: (slug) =>
            props.room.userSlugs.indexOf(slug) >= 0 || slug === "here" || slug === "all",
          getDisplay: (slug) => (props.users[slug] ? props.users[slug].displayName : `@${slug}`),
          getURL: (slug) => (props.room.userSlugs.indexOf(slug) >= 0 ? `/talents/${slug}` : "#"),
        },
      ],
    ];

    this.body = React.createRef();
  }

  componentDidMount() {
    if (this.body.current) {
      const overflown = this.isOverflown(this.body.current);
      if (overflown) {
        this.setState({ overflown, bodyClasses: [styles.body, styles.collapsed] });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message &&
      this.props.message.content &&
      prevProps.message &&
      prevProps.message.content !== this.props.message.content &&
      this.body.current
    ) {
      const overflown = this.isOverflown(this.body.current);
      if (overflown) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ overflown, bodyClasses: [styles.body, styles.collapsed] });
      }
    }
  }

  isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) =>
    scrollHeight > clientHeight || scrollWidth > clientWidth;

  _isImage = (fileType) => {
    switch (fileType) {
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
      case "webp":
        return true;
      default:
        return false;
    }
  };

  _collapseFiles = () => {
    const { message } = this.props;
    const { files } = message;
    // if all files are images, expand attachment files
    if (!isEmpty(files)) {
      const allImages = files.every((file) => this._isImage(file.type));
      return !allImages;
    }
    // otherwise, image attachments should be collapsed
    return true;
  };

  _getChannelDisplay = () => {
    const { currentUser } = this.props;
    switch (currentUser.channelDisplay) {
      case "fixed_width_center":
        return {
          messageListItem: classnames(styles.messageListItemContent, styles.centered),
          systemMessage: classnames(styles.systemMessage, styles.centered),
        };
      case "fixed_width_left":
        return {
          messageListItem: styles.messageListItemContent,
          systemMessage: styles.systemMessage,
        };
      case "full_width_left":
        return {
          messageListItem: classnames(styles.messageListItemContent, styles.fullWidth),
          systemMessage: styles.systemMessage,
        };
      default:
        return {
          messageListItem: styles.messageListItemContent,
          systemMessage: styles.systemMessage,
        };
    }
  };

  getActionHandler = (action, data) => () => {
    const { performActionInit } = this.props;

    performActionInit(action, data);
  };

  getCleanedContent = () => {
    const {
      message: { content },
    } = this.props;

    // Replace two or consecutive newlines with just one newline.
    // See: https://stackoverflow.com/a/10965543
    // Also remove trailing whitespace.
    return content.replace(/\n\s*\n\s*\n/g, "\n\n").replace(/\s+$/, "");
  };

  handleShowMore = (e) => {
    e.preventDefault();
    this.setState({ collapsed: false, bodyClasses: [styles.body, styles.uncollapsed] });
  };

  handleShowLess = (e) => {
    e.preventDefault();
    this.setState({ collapsed: true, bodyClasses: [styles.body, styles.collapsed] });
  };

  handleOpenThread = (e) => {
    const { isThread, message, onOpenThread, room } = this.props;
    e.preventDefault();
    if (!isThread && onOpenThread) {
      onOpenThread(room.id, message.root || message.id);
    }
  };

  handleChangeStep = ({ next }) => {
    const { pollStep } = this.state;
    this.setState({
      pollStep: next || (pollStep === STEP_VOTE ? STEP_RESULTS : STEP_VOTE),
    });
  };

  renderMessage() {
    const { message, onResend, room } = this.props;
    const { files, error } = message;
    const { bodyClasses, collapsed, overflown } = this.state;
    if (message.isEdited) {
      bodyClasses.push(styles.inlineEdited);
    }

    const content = this.getCleanedContent();

    return (
      <>
        <div className={classnames(...bodyClasses)} ref={this.body}>
          <ReactMarkdown source={content} plugins={this.plugins} renderers={renderers} />
        </div>

        {message.isEdited && <div className={styles.edited}>(edited)</div>}

        {overflown && collapsed && (
          <div className={styles.showMore}>
            <a href="#more" onClick={this.handleShowMore}>
              Show more&hellip;
            </a>
          </div>
        )}
        {overflown && !collapsed && (
          <div className={styles.showMore}>
            <a href="#less" onClick={this.handleShowLess}>
              Show less&hellip;
            </a>
          </div>
        )}

        {!isEmpty(files) && (
          <div className={styles.attachments}>
            {files.map((file) => (
              <MessageAttachmentListItem
                key={file.file || file.data.preview}
                files={files}
                fileId={file.id || file.fileId}
                isCollapsed={this._collapseFiles()}
              />
            ))}
          </div>
        )}

        {error && (
          <div>
            <small style={{ color: "red" }}>
              Message not sent.{" "}
              <Button
                size="small"
                negative
                basic
                onClick={() => onResend(message, room)}
                content="Retry"
              />
            </small>
          </div>
        )}
      </>
    );
  }

  renderActionMessage() {
    const { message } = this.props;
    const { content, actions } = message;

    return (
      <Card color="green">
        <Card.Content>
          <Icon floated="right" name="envelope" />
          <Card.Description>
            <ReactMarkdown source={content} plugins={this.plugins} renderers={renderers} />
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button.Group widths={actions.length}>
            {actions.map((b) => (
              <Button
                basic
                color="blue"
                key={b.label}
                onClick={this.getActionHandler(b.action, b.data.post)}
              >
                {b.label}
              </Button>
            ))}
          </Button.Group>
        </Card.Content>
      </Card>
    );
  }

  renderDeletedMessage = () => (
    <div>
      <div disabled>
        <em>This message has been deleted.</em>
      </div>
    </div>
  );

  renderUserMessage(showHeader, messageClassName) {
    const {
      currentUser,
      deleteMessage,
      isThread,
      isPinned,
      message,
      onMeetingJoin,
      onPin,
      onPollArchive,
      onPollPin,
      onPollDelegate,
      onPollVote,
      onUnpin,
      optionsDirection,
      room,
      roomUsers,
      rootMessage,
      showRoot,
      users,
    } = this.props;
    const { actions, author, authorName, created, isEmailMessage, error, isDeleted } = message;
    const hasActions = actions && actions.length > 0;
    const online = users && users[message.author] && !!users[message.author].online;

    const pinnedBy = message.pinnedBy ? users[message.pinnedBy] : null;
    const pinnedOn = message.pinnedBy ? message.pinnedOn : null;

    let avatar = null;
    const avatarColors = {};
    if (users && users[message.author]) {
      avatar = users[message.author].avatarOrDefault;
      avatarColors.bgColor = users[message.author].avatarBgcolor;
      avatarColors.fgColor = users[message.author].avatarColor;
    }

    const renderedMessage = (
      <>
        {!hasActions && !isDeleted && this.renderMessage()}
        {hasActions && !isDeleted && this.renderActionMessage()}
        {isDeleted && this.renderDeletedMessage()}

        <MessageListItemPoll
          currentUser={currentUser}
          onDelegate={onPollDelegate}
          onVote={onPollVote}
          poll={message.poll}
          room={room}
          roomUsers={roomUsers}
          users={users}
          leftAligned
          showPollActions={false}
          onChangeStep={this.handleChangeStep}
          step={this.state.pollStep}
        />

        <MessageListItemEvent
          currentUser={currentUser}
          event={message.event}
          isActive={room.isActive}
          onMeetingJoin={onMeetingJoin}
          room={room}
          roomUsers={roomUsers}
        />

        <Reaction message={message} room={room} />
      </>
    );

    let rootedRenderedMessage;
    if (!isThread && rootMessage && rootMessage !== message && users[rootMessage.author]) {
      rootedRenderedMessage = (
        <>
          {showRoot && (
            <div className={styles.rootMessage}>
              Replying to <ContactModal user={rootMessage.author} propStyle={styles.contactModal} />
              &rsquo;s message :{" "}
              <Button onClick={this.handleOpenThread}>
                <ReactMarkdown
                  source={rootMessage.content.split("\n")[0]}
                  allowedTypes={["text"]}
                  unwrapDisallowed
                />
              </Button>
            </div>
          )}
          <blockquote>{renderedMessage}</blockquote>
        </>
      );
    } else {
      rootedRenderedMessage = renderedMessage;
    }

    let avatarElement = null;
    if (showHeader) {
      avatarElement = (
        <UserAvatar
          avatar={avatar}
          online={online}
          float="left"
          bgColor={avatarColors.bgColor}
          fgColor={avatarColors.fgColor}
          displayName={authorName}
        />
      );

      if (sentinelSlug !== author) {
        avatarElement = (
          <Link to={`/talents/${author}`} target="_blank" title="View profile">
            {avatarElement}
          </Link>
        );
      }
    }

    return (
      <div className={messageClassName}>
        {!message.isDeleted && room.isActive && (
          <MessageListItemOptions
            deleteMessage={deleteMessage}
            direction={optionsDirection}
            isThread={isThread}
            isPinned={isPinned}
            room={room}
            message={message}
            user={currentUser}
            onOpenThread={this.handleOpenThread}
            onPin={onPin}
            onUnpin={onUnpin}
            onPollPin={onPollPin}
            onPollArchive={onPollArchive}
            onChangeStep={this.handleChangeStep}
            pollStep={this.state.pollStep}
          />
        )}

        {pinnedBy && (
          <div>
            <Label
              className={styles.pinned}
              content={`Pinned by ${pinnedBy.displayName}`}
              icon="pin"
              size="small"
              title={moment(pinnedOn).format("LLL")}
            />
          </div>
        )}

        {avatarElement}

        <MessageListItemHeader
          author={author}
          authorName={authorName}
          created={created}
          error={error}
          id={message.id}
          isEmailMessage={isEmailMessage}
          isThread={isThread}
          isPinned={isPinned}
          pinnedBy={message.pinnedBy ? users[message.pinnedBy] : null}
          pinnedOn={message.pinnedBy ? message.pinnedOn : null}
          showHeader={showHeader}
          showLinkToProfile={author !== sentinelSlug}
        >
          {rootedRenderedMessage}
        </MessageListItemHeader>
      </div>
    );
  }

  renderSystemMessage = (messageClassName) => {
    const { isThread, message, room, rootMessage, currentUser } = this.props;

    let content = "unchanged";
    if (message.data && message.data.type === "addition") {
      content = (
        <span>
          {message.data.data.who} added {message.data.data.what}{" "}
          <Diff type="words" inputA="" inputB={getDiffNew(message.data.data)} />.
        </span>
      );
    } else if (message.data && message.data.type === "diff") {
      content = (
        <span>
          {message.data.data.who} changed {message.data.data.what} to{" "}
          <Diff
            type="words"
            inputA={getDiffOld(message.data.data)}
            inputB={getDiffNew(message.data.data)}
          />
          .
        </span>
      );
    } else if (message.data && message.data.type === "removal") {
      content = (
        <span>
          {message.data.data.who} removed {message.data.data.what}.
        </span>
      );
    } else if (message.data && message.data.type === "standard") {
      content = (
        <span>
          {message.data.data.who} changed the poll&rsquo;s {message.data.data.what} from{" "}
          {getDiffOld(message.data.data)} to {getDiffNew(message.data.data)}.
        </span>
      );
    } else if (message.data && message.data.type === "expiration") {
      // Legacy system message. Future expiration system messages have datetime as type
      const oldExpireAt = message.data.data.old
        ? moment(message.data.data.old).format("LLL")
        : "Never";
      const newExpireAt = message.data.data.new
        ? moment(message.data.data.new).format("LLL")
        : "Never";

      content = (
        <span>
          {message.data.data.who} changed the poll&rsquo;s expiration from{" "}
          <time dateTime={message.data.data.old}>{oldExpireAt}</time> to{" "}
          <time dateTime={message.data.data.new}>{newExpireAt}</time>.
        </span>
      );
    } else if (message.data && message.data.type === "datetime") {
      const oldDateTime = message.data.data.old
        ? moment(message.data.data.old).format("LLL")
        : "Never";
      const newDateTime = message.data.data.new
        ? moment(message.data.data.new).format("LLL")
        : "Never";

      content = (
        <span>
          {message.data.data.who} changed the poll&rsquo;s {message.data.data.what} from{" "}
          <time dateTime={message.data.data.old}>{oldDateTime}</time> to{" "}
          <time dateTime={message.data.data.new}>{newDateTime}</time>.
        </span>
      );
    } else if (message.data && message.data.type === "event_date") {
      const oldDate = message.data.data.old
        ? moment.tz(message.data.data.old, currentUser.timezoneDisplay).format("LLL")
        : "never";
      const newDate = message.data.data.new
        ? moment.tz(message.data.data.new, currentUser.timezoneDisplay).format("LLL")
        : "never";
      content = (
        <span>
          {message.data.data.who} changed the event&rsquo;s {message.data.data.what} date from{" "}
          {oldDate} to {newDate}.
        </span>
      );
    } else {
      ({ content } = message);
    }

    return (
      <div className={messageClassName} title={moment(message.created).format("LLL")}>
        {content}{" "}
        {!isThread && rootMessage && rootMessage.id !== message.id && rootMessage.poll && (
          <Link to={`/chat/r/${room.id}/polls/${rootMessage.poll.id}`} role="button" tabIndex="-1">
            <Icon name="external" />
          </Link>
        )}
        {!isThread && rootMessage && rootMessage.id !== message.id && rootMessage.event && (
          <Link
            to={`/chat/r/${room.id}/meetings/${rootMessage.event.id}`}
            role="button"
            tabIndex="-1"
          >
            <Icon name="external" />
          </Link>
        )}
      </div>
    );
  };

  renderAnonymousMessage = (messageClassName) => {
    const {
      currentUser,
      deleteMessage,
      isThread,
      isPinned,
      message,
      onPin,
      onPollArchive,
      onPollPin,
      onPollDelegate,
      onPollVote,
      onUnpin,
      optionsDirection,
      room,
      roomUsers,
      users,
    } = this.props;
    const { author, created, isEmailMessage, error, isDeleted } = message;

    return (
      <div className={messageClassName}>
        {!message.isDeleted && (
          <MessageListItemOptions
            deleteMessage={deleteMessage}
            direction={optionsDirection}
            isThread={isThread}
            isPinned={isPinned}
            room={room}
            message={message}
            user={currentUser}
            onOpenThread={this.handleOpenThread}
            onPin={onPin}
            onUnpin={onUnpin}
            onPollPin={onPollPin}
            onPollArchive={onPollArchive}
            onChangeStep={this.handleChangeStep}
            pollStep={this.state.pollStep}
          />
        )}

        <UserAvatar isAnonymous float="left" />

        <MessageListItemHeader
          showHeader
          author={author}
          authorName="Anonymous"
          created={created}
          error={error}
          id={message.id}
          isEmailMessage={isEmailMessage}
          isThread={isThread}
          isPinned={isPinned}
          pinnedBy={message.pinnedBy ? users[message.pinnedBy] : null}
          pinnedOn={message.pinnedBy ? message.pinnedOn : null}
        >
          {!isDeleted && this.renderMessage()}
          {isDeleted && this.renderDeletedMessage()}

          <MessageListItemPoll
            currentUser={currentUser}
            onDelegate={onPollDelegate}
            onVote={onPollVote}
            poll={message.poll}
            room={room}
            roomUsers={roomUsers}
            users={users}
            leftAligned
            showPollActions={false}
            onChangeStep={this.handleChangeStep}
            step={this.state.pollStep}
          />
          <Reaction message={message} room={room} />
        </MessageListItemHeader>
      </div>
    );
  };

  render() {
    const { message, room, showHeader } = this.props;
    const { author, poll } = message;
    const channelDisplay = this._getChannelDisplay();

    return (
      <div
        className={classnames(
          `message-${message.id}`,
          styles.messageListItem,
          room.jump === message.id ? styles.active : "",
          !message.author || !showHeader ? styles.headerless : ""
        )}
      >
        {author &&
          this.renderUserMessage(
            message.isEmailMessage || showHeader,
            channelDisplay.messageListItem
          )}
        {!author && poll && this.renderAnonymousMessage(channelDisplay.messageListItem)}
        {!author && !poll && this.renderSystemMessage(channelDisplay.systemMessage)}
      </div>
    );
  }
}

MessageListItem.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  deleteMessage: PropTypes.func.isRequired,
  isThread: PropTypes.bool,
  isPinned: PropTypes.bool,
  message: PropTypes.shape().isRequired,
  optionsDirection: PropTypes.oneOf(["up", "down"]),
  room: PropTypes.shape().isRequired,
  roomUsers: PropTypes.arrayOf(PropTypes.shape()),
  rootMessage: PropTypes.shape(),
  showHeader: PropTypes.bool.isRequired,
  showRoot: PropTypes.bool,
  users: PropTypes.shape().isRequired,

  onMeetingJoin: PropTypes.func.isRequired,
  onPin: PropTypes.func.isRequired,
  onPollPin: PropTypes.func.isRequired,
  onPollArchive: PropTypes.func.isRequired,
  onPollDelegate: PropTypes.func.isRequired,
  onPollVote: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  onOpenThread: PropTypes.func,
  onUnpin: PropTypes.func.isRequired,
  performActionInit: PropTypes.func.isRequired,
};

MessageListItem.defaultProps = {
  isThread: false,
  optionsDirection: "down",
  roomUsers: [],
  showRoot: false,
};

export default debounceRender(MessageListItem, 100, { leading: true, trailing: false });
