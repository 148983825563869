import classnames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Icon, Responsive } from "semantic-ui-react";

import UserAvatar from "components/UserAvatar";
import styles from "./styles.module.scss";

export default class TimezoneViewerMember extends React.PureComponent {
  render() {
    const {
      currentDate,
      isCurrentUser,
      member,
      onTimezoneDelete,
      selectedStart,
      selectedEnd,
    } = this.props;
    const convertedDate = moment(currentDate).tz(member.timezoneDisplay);
    const convertedStart = selectedStart ? moment(selectedStart).tz(member.timezoneDisplay) : null;
    const convertedEnd = selectedEnd ? moment(selectedEnd).tz(member.timezoneDisplay) : null;
    let timeLabel = convertedDate.format("h:mm A");
    let dateLabel = convertedDate.format("ddd, MMM D");
    if (convertedStart && convertedEnd) {
      timeLabel = `${convertedStart.format("h:mm a")} - ${convertedEnd.format("h:mm a")}`;
      if (convertedStart.date() !== convertedEnd.date()) {
        dateLabel = `${convertedStart.format("ddd, MMM D")} - ${convertedEnd.format("ddd, MMM D")}`;
      } else {
        dateLabel = convertedStart.format("ddd, MMM D");
      }
    }

    if (member.isDummy) {
      return (
        <div className={styles.timezoneViewerGroupDetail}>
          <Button
            basic
            className={styles.deleteBtn}
            icon
            onClick={() => onTimezoneDelete(member.slug)}
            size="mini"
          >
            <Icon name="delete" />
          </Button>
          <div className={styles.timezoneIcon}>
            <Icon name="clock" size="big" />
          </div>
          <Responsive
            as="div"
            className={styles.timezoneViewerGroupDetailDescription}
            minWidth={768}
          >
            <div className={styles.timezoneViewerGroupDetailName}>{member.timezoneDisplay}</div>
          </Responsive>
          <Responsive
            as="div"
            className={classnames(styles.timezoneViewerGroupDetailDescription, styles.right)}
            minWidth={768}
          >
            <div className={styles.timezoneViewerGroupDetailName}>{timeLabel}</div>
            <span className={styles.timezoneViewerGroupDetailTimezone}>{dateLabel}</span>
          </Responsive>
        </div>
      );
    }

    return (
      <div className={styles.timezoneViewerGroupDetail}>
        <UserAvatar
          avatar={member.avatarOrDefault}
          size="40"
          bgColor={member.avatarBgcolor}
          fgColor={member.avatarColor}
          displayName={member.displayName}
        />
        <Responsive as="div" className={styles.timezoneViewerGroupDetailDescription} minWidth={768}>
          <div className={styles.timezoneViewerGroupDetailName}>
            <NavLink to={`/talents/${member.slug}`} target="_blank" title="View profile">
              {member.displayName} {isCurrentUser && "(You)"}
            </NavLink>
          </div>
          <span className={styles.timezoneViewerGroupDetailTimezone}>{member.timezoneDisplay}</span>
        </Responsive>
        <Responsive
          as="div"
          className={classnames(styles.timezoneViewerGroupDetailDescription, styles.right)}
          minWidth={768}
        >
          <div className={styles.timezoneViewerGroupDetailName}>{timeLabel}</div>
          <span className={styles.timezoneViewerGroupDetailTimezone}>{dateLabel}</span>
        </Responsive>
      </div>
    );
  }
}

TimezoneViewerMember.propTypes = {
  currentDate: PropTypes.shape().isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  member: PropTypes.shape().isRequired,
  onTimezoneDelete: PropTypes.func.isRequired,
  selectedStart: PropTypes.shape(),
  selectedEnd: PropTypes.shape(),
};
