import { call, put, takeLatest } from "redux-saga/effects";
import { api, token as tokenService } from "services";

import * as reminders from "actions/reminders";

export function* updateReminder({ id, data }) {
  const entity = reminders.updateReminder;
  const apiFn = api.updateReminder;
  const refresh = true;
  const token = yield tokenService.getAuthToken();

  yield put(entity.request(refresh));
  const { response, error, errorDetails } = yield call(apiFn, token, id || null, data);
  if (response) {
    yield put(entity.success(response));
  } else {
    yield put(entity.failure({ error, errorDetails }));
  }
  return response;
}

export function* watchReminders() {
  yield takeLatest(reminders.UPDATE_REMINDER.INIT, updateReminder);
}
