import { SOCKET } from "actions/socket";

export default function online(state = true, action) {
  const { type } = action;
  switch (type) {
    case SOCKET.ONLINE:
      return true;
    case SOCKET.OFFLINE:
      return false;
    default:
      return state;
  }
}
