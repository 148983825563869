import { arrayOf, bool, number, objectOf, oneOf, shape, string } from "prop-types";

import { PollOptionType, PollRecurringType } from "utils/constants/poll";

import { dateTimeString, dateTimeStringRequired } from ".";

export const pollOptionType = shape({
  id: number.isRequired,
  name: string.isRequired,
  orderId: number.isRequired,
  votes: objectOf(number),
  votesWeighted: objectOf(number),
});

export const pollSkillType = shape({
  id: number.isRequired,
  name: string.isRequired,
});

export const pollType = shape({
  actualMaxVotes: number,
  allowAnonymousVotes: bool.isRequired,
  allowEditingVotes: bool.isRequired,
  archivedAt: dateTimeString,
  areChoicesRandomized: bool.isRequired,
  author: string.isRequired,
  authorName: string.isRequired,
  created: dateTimeStringRequired,
  description: string.isRequired,
  expireAt: dateTimeString,
  hasVoted: bool.isRequired,
  isArchived: bool.isRequired,
  isEditable: bool.isRequired,
  isExpired: bool.isRequired,
  isPinned: bool.isRequired,
  isResultsViewablePresubmission: bool.isRequired,
  isVoteable: bool.isRequired,
  maxVotes: number,
  optionType: oneOf(PollOptionType.all).isRequired,
  options: arrayOf(pollOptionType).isRequired,
  question: string.isRequired,
  recurring: oneOf(PollRecurringType.all),
  room: number.isRequired,
  scheduledAt: dateTimeString,
  skills: arrayOf(pollSkillType).isRequired,
  votesLeft: number,
  voteDelegate: shape(),
  voteDelegators: arrayOf(shape()).isRequired,
});
