import { call, delay, put, race, take, takeLatest } from "redux-saga/effects";
import { stringify as queryStringify } from "querystring-es3";
import { api, token as tokenService } from "services";
import * as account from "actions/account";
import * as user from "actions/user";
import * as calendar from "actions/calendar";
import * as timetracker from "actions/timetracker";
import * as version from "actions/version";

import { fetchEntity, pushEntity } from "./base";

export const fetchUser = fetchEntity.bind(null, user.user, api.fetchUser);
export const fetchUserCalendar = fetchEntity.bind(null, calendar.calendar, api.fetchUserCalendar);
export const fetchEvents = fetchEntity.bind(null, calendar.event, api.fetchEvents);
export const pushNewEvent = pushEntity.bind(null, calendar.event, api.createEvent);
export const pushEvent = pushEntity.bind(null, calendar.event, api.updateEvent);
export const fetchTeam = fetchEntity.bind(null, user.team, api.fetchTeam);
export const fetchTalentTeam = fetchEntity.bind(null, user.team, api.fetchTalentTeam);
export const fetchTeamTalents = fetchEntity.bind(null, user.teamTalents, api.fetchTeamTalents);
export const fetchTeamTalentInvites = fetchEntity.bind(
  null,
  user.teamTalents,
  api.fetchTeamTalentInvites
);
export const pushMembers = pushEntity.bind(null, user.user, api.addMembers);
export const fetchTeamCalendar = fetchEntity.bind(null, user.teamCalendar, api.fetchTeamCalendar);

export function* loadUser({ slug, refresh }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchUser, refresh, token, slug);
}

export function* updateUser({ slug, data }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.updateUser, token, slug, data);
  if (response) {
    yield put(user.updateUser.success(response));
  } else {
    yield put(user.updateUser.failure({ error, errorDetails }));
  }
}

export function* deleteUser({ slug }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.deleteUser, token, slug);
  if (response) {
    yield put({ type: account.AUTH_LOGOUT_INIT });
    yield put(user.deleteUser.success(response));
  } else {
    yield put(user.deleteUser.failure({ error, errorDetails }));
  }
}

export function* loadUserCalendar({ slug, params, refresh }) {
  const baseUrl = `calendars/users/${slug}/`;
  const query = queryStringify(params);
  const url = query ? `${baseUrl}?${query}` : baseUrl;
  const token = yield tokenService.getAuthToken();
  yield call(fetchUserCalendar, refresh, token, url);
}

export function* createEvent({ slug, data, resolve, reject, params, isWorkLog }) {
  yield put(calendar.event.request(true));
  if (isWorkLog) yield put(timetracker.createWorkLog.request());
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.createEvent, token, null, data);
  if (response) {
    if (isWorkLog) yield put(timetracker.createWorkLog.success(response));
    yield put(calendar.event.success(response));
    yield put(calendar.loadUserCalendar(slug, true, params));
    if (resolve) resolve(response);
  } else {
    yield put(calendar.event.failure(error, errorDetails));
    if (isWorkLog) yield put(timetracker.createWorkLog.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
  return response;
}

export function* updateEvent({ id, data, slug, resolve, reject, params, isWorkLog }) {
  if (isWorkLog) yield put(timetracker.updateWorkLog.request());
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.updateEvent, token, id, data);
  if (response) {
    if (isWorkLog) yield put(timetracker.updateWorkLog.success(response));
    yield put(calendar.cleanEventOccurrences(slug, [response.result]));
    yield put(calendar.event.success(response));
    yield put(calendar.loadUserCalendar(slug, true, params));
    if (resolve) resolve(response);
  } else {
    yield put(calendar.event.failure(error, errorDetails));
    if (isWorkLog) yield put(timetracker.updateWorkLog.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
  return response;
}

export function* deleteEvent({ id, slug, params, resolve, reject, isWorkLog }) {
  yield put(calendar.cleanEventOccurrences(slug, [id], true));
  if (isWorkLog) yield put(timetracker.deleteWorkLog.request());
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.deleteEvent, token, id);
  if (response) {
    yield put(calendar.eventDelete.success(response));
    if (isWorkLog) yield put(timetracker.deleteWorkLog.success(response));
    yield put(calendar.loadUserCalendar(slug, true, params));
    if (resolve) resolve(response);
  } else {
    yield put(calendar.eventDelete.failure({ error, errorDetails }));
    if (isWorkLog) yield put(timetracker.deleteWorkLog.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
  return response;
}

export function* eventDetail({ id }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.eventDetail, token, id);

  if (response) {
    yield put(calendar.eventDetail.success(response));
  } else {
    yield put(calendar.eventDetail.failure({ error, errorDetails }));
  }
}

export function* eventOccurrenceDetail({ occurrenceId, params, resolve, reject }) {
  const token = yield tokenService.getAuthToken();
  let id;
  if (Number.isInteger(occurrenceId)) {
    id = occurrenceId;
  } else {
    const [firstValue] = occurrenceId.split("_");
    id = firstValue;
  }
  const { response, error, errorDetails } = yield call(
    api.eventOccurrenceDetail,
    token,
    id,
    params
  );

  if (response) {
    yield put(calendar.eventOccurrenceDetail.success(response, occurrenceId));
    if (resolve) resolve();
  } else {
    yield put(calendar.eventOccurrenceDetail.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
}

export function* addGoogleCalendar({ ids }) {
  const token = yield tokenService.getAuthToken();
  const { response } = yield call(api.addGoogleCalendar, token, ids);
  return response;
}

export function* addOutlookCalendar({ ids }) {
  const token = yield tokenService.getAuthToken();
  const { response } = yield call(api.addOutlookCalendar, token, ids);
  return response;
}

export function* loadTeam() {
  yield call(fetchTeam);
}

export function* loadTalentTeam({ slug, refresh }) {
  const token = yield tokenService.getAuthToken();
  const response = yield call(fetchTalentTeam, refresh, token, slug);
  if (response) {
    yield put(user.team.success(response));
  }
}

export function* loadTeamTalents({ slug }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchTeamTalents, true, token, slug);
}

export function* loadTeamTalentInvites({ slug }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchTeamTalentInvites, true, token, slug);
}

export function* updateTeam({ slug, data }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.updateTeam, token, slug, data);
  if (response) {
    yield put(user.updateTeam.success(response));
  } else {
    yield put(user.updateTeam.failure({ error, errorDetails }));
  }
}

export function* deleteTeam({ slug, resolve, reject }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.deleteTeam, token, slug);
  if (response) {
    yield put(user.deleteTeam.success(response));
    resolve();
  } else {
    yield put(user.deleteTeam.failure({ error, errorDetails }));
    reject(errorDetails);
  }
}

export function* addMembers({ slug, data }) {
  const token = yield tokenService.getAuthToken();
  const response = yield call(pushMembers, true, token, slug, null, data);
  if (response) {
    yield put(user.addMembers.success(response));
  }
}

export function* updateMemberships({ slug, data, resolve, reject }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.updateMemberships, token, slug, data);
  if (response) {
    yield put(user.updateMemberships.success(response));
    resolve();
  } else {
    yield put(user.updateMemberships.failure({ error, errorDetails }));
    reject(errorDetails);
  }
}

export function* resendTeamInvite({ id }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.resendTeamInvite, token, id);
  if (response) {
    yield put(user.resendTeamInvite.success(response));
  } else {
    yield put(user.resendTeamInvite.failure({ error, errorDetails }));
  }
}

export function* removeTeamInvite({ id }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.removeTeamInvite, token, id);
  if (response) {
    yield put(user.removeTeamInvite.success(response));
  } else {
    yield put(user.removeTeamInvite.failure({ error, errorDetails }));
  }
}

export function* loadTeamCalendar({ slug, params }) {
  const baseUrl = `talent_teams/${slug}/calendar/`;
  const query = queryStringify(params);
  const url = query ? `${baseUrl}?${query}` : baseUrl;
  const token = yield tokenService.getAuthToken();
  yield call(fetchTeamCalendar, true, token, url);
}

export function* bulkSkillsUpdate({ slug, data }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.bulkSkillsUpdate, token, slug, data);
  if (response) {
    yield put(user.bulkSkillsUpdate.success(response));
  } else {
    yield put(user.bulkSkillsUpdate.failure({ error, errorDetails }));
  }
}

export function* bulkTeamSkillsUpdate({ slug, data }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.bulkTeamSkillsUpdate, token, slug, data);
  if (response) {
    yield put(user.bulkTeamSkillsUpdate.success(response));
  } else {
    yield put(user.bulkTeamSkillsUpdate.failure({ error, errorDetails }));
  }
}

export function* deleteTeamMembership({ slug, id, resolve, reject }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.deleteTeamMembership, token, slug, id);
  if (response) {
    yield put(user.deleteTeamMembership.success(response));
    resolve();
  } else {
    yield put(user.deleteTeamMembership.failure({ error, errorDetails }));
    reject(errorDetails);
  }
}

export function* bulkPhoneNumbersUpdate({ slug, data }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(
    api.bulkPhoneNumbersUpdate,
    token,
    slug,
    data
  );
  if (response) {
    yield put(user.bulkPhoneNumbersUpdate.success(slug, response));
  } else {
    yield put(user.bulkPhoneNumbersUpdate.failure({ error, errorDetails }));
  }
}

export function* addToRocketChat({ slug }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.addToRocketChat, token, slug);
  if (response) {
    yield put(user.addToRocketChat.success(response));
  } else {
    yield put(user.addToRocketChat.failure({ error, errorDetails }));
  }
}

export function* watchUserPresence() {
  while (true) {
    yield race({
      init: take(user.USER_PRESENCE.INIT),
      // Update user presence every 10 or so minutes
      timeout: delay(10 * 60 * 1000 + Math.random() * 1000),
    });

    const token = yield call(tokenService.getAuthToken);

    if (token) {
      yield put(user.userPresence.request());
      const { response, error } = yield call(api.userPresence, token);

      if (response) {
        yield put(user.userPresence.success(response));
      } else {
        yield put(user.userPresence.failure(error));
      }
    }
  }
}

let _currentVersion = null;

export function* watchPing() {
  while (true) {
    // Ping the api server every minute to update last online timestamp
    const token = yield tokenService.getAuthToken();
    if (token) {
      const { response } = yield call(api.ping, token);
      if (response) {
        const { version: newVersion } = response;
        if (_currentVersion === null) {
          _currentVersion = newVersion;
        } else if (newVersion !== _currentVersion) {
          yield put(version.hasNewVersion());
          _currentVersion = newVersion;
        }
      }
    }
    yield delay(10 * 60 * 1000 + Math.random() * 1000);
  }
}

export function* watchUpdateProfile() {
  yield takeLatest(user.UPDATE_USER.INIT, updateUser);
  yield takeLatest(user.UPDATE_TEAM.INIT, updateTeam);
  yield takeLatest(user.ADD_TEAM_MEMBERS.INIT, addMembers);
  yield takeLatest(user.UPDATE_MEMBERSHIPS.INIT, updateMemberships);
  yield takeLatest(user.RESEND_INVITE.INIT, resendTeamInvite);
  yield takeLatest(user.REMOVE_INVITE.INIT, removeTeamInvite);
  yield takeLatest(user.BULK_SKILLS_UPDATE.INIT, bulkSkillsUpdate);
  yield takeLatest(user.BULK_TEAM_SKILLS_UPDATE.INIT, bulkTeamSkillsUpdate);
  yield takeLatest(user.BULK_PHONE_NUMBERS_UPDATE.INIT, bulkPhoneNumbersUpdate);
  yield takeLatest(user.ADD_TO_ROCKET_CHAT.INIT, addToRocketChat);
}
