import { createRequestTypes, action } from "./index";

export const WORKMATES = createRequestTypes("WORKMATES");
export const FEEDBACK = createRequestTypes("FEEDBACK");
export const WORKMATE_FEEDBACK = createRequestTypes("WORKMATE_FEEDCBACK");

export const TALENT_FEEDBACK = createRequestTypes("TALENT_FEEDBACK");
export const SKILL_FEEDBACK = createRequestTypes("SKILL_FEEDBACK");
export const FEEDBACK_REQUEST = createRequestTypes("FEEDBACK_REQUEST");
export const FEEDBACK_WIZARD = createRequestTypes("FEEDBACK_WIZARD");
export const GROUP_FEEDBACK = createRequestTypes("GROUP_FEEDBACK");

export const CONJOINT_PAIR = createRequestTypes("CONJOINT_PAIR");
export const CONJOINT_FEEDBACK = createRequestTypes("CONJOINT_FEEDBACK");
export const UNDO_CONJOINT_FEEDBACK = createRequestTypes("UNDO_CONJOINT_FEEDBACK");

export const RATINGS_HISTORY = createRequestTypes("RATINGS_HISTORY");
export const TEAM_RATINGS_HISTORY = createRequestTypes("TEAM_RATINGS_HISTORY");

export const RELATIONS = createRequestTypes("RELATIONS");
export const COMMON_SKILL = createRequestTypes("COMMON_SKILL");

export const workmates = {
  init: (username) => action(WORKMATES.INIT, { username }),
  request: () => action(WORKMATES.REQUEST),
  success: (response) => action(WORKMATES.SUCCESS, { response }),
  failure: (error) => action(WORKMATES.FAILURE, { error }),
};

export const feedback = {
  init: (username) => action(FEEDBACK.INIT, { username }),
  request: () => action(FEEDBACK.REQUEST),
  success: (response) => action(FEEDBACK.SUCCESS, { response }),
  failure: (error) => action(FEEDBACK.FAILURE, { error }),
};

export const workmateFeedback = {
  init: (username) => action(WORKMATE_FEEDBACK.INIT, { username }),
  request: () => action(WORKMATE_FEEDBACK.REQUEST),
  success: (response) => action(WORKMATE_FEEDBACK.SUCCESS, { response }),
  failure: (error) => action(WORKMATE_FEEDBACK.FAILURE, { error }),
};

export const talentFeedback = {
  init: (data) => action(TALENT_FEEDBACK.INIT, { data }),
  request: () => action(TALENT_FEEDBACK.REQUEST),
  success: (response) => action(TALENT_FEEDBACK.SUCCESS, { response }),
  failure: (error) => action(TALENT_FEEDBACK.FAILURE, { error }),
};

export const skillFeedback = {
  init: (data) => action(SKILL_FEEDBACK.INIT, { data }),
  request: () => action(SKILL_FEEDBACK.REQUEST),
  success: (response) => action(SKILL_FEEDBACK.SUCCESS, { response }),
  failure: (error) => action(SKILL_FEEDBACK.FAILURE, { error }),
};

export const feedbackRequest = {
  init: (data) => action(FEEDBACK_REQUEST.INIT, { data }),
  request: () => action(FEEDBACK_REQUEST.REQUEST),
  success: (response) => action(FEEDBACK_REQUEST.SUCCESS, { response }),
  failure: (error) => action(FEEDBACK_REQUEST.FAILURE, { error }),
};

export const feedbackWizard = {
  init: (data) => action(FEEDBACK_WIZARD.INIT, { data }),
  request: () => action(FEEDBACK_WIZARD.REQUEST),
  success: (response) => action(FEEDBACK_WIZARD.SUCCESS, { response }),
  failure: (error) => action(FEEDBACK_WIZARD.FAILURE, { error }),
};

export const groupFeedback = {
  init: (data) => action(GROUP_FEEDBACK.INIT, { data }),
  request: () => action(GROUP_FEEDBACK.REQUEST),
  success: (response) => action(GROUP_FEEDBACK.SUCCESS, { response }),
  failure: (error) => action(GROUP_FEEDBACK.FAILURE, { error }),
};

export const conjointPair = {
  init: () => action(CONJOINT_PAIR.INIT),
  delayedRequest: () => action(CONJOINT_PAIR.REQUEST),
  request: () => action(CONJOINT_PAIR.REQUEST),
  success: (response) => action(CONJOINT_PAIR.SUCCESS, { response }),
  failure: (error) => action(CONJOINT_PAIR.FAILURE, { error }),
};

export const conjointFeedback = {
  init: (data) => action(CONJOINT_FEEDBACK.INIT, { data }),
  request: () => action(CONJOINT_FEEDBACK.REQUEST),
  success: (response) => action(CONJOINT_FEEDBACK.SUCCESS, { response }),
  failure: (error) => action(CONJOINT_FEEDBACK.FAILURE, { error }),
};

export const undoConjointFeedback = {
  init: (id) => action(UNDO_CONJOINT_FEEDBACK.INIT, { id }),
  request: () => action(UNDO_CONJOINT_FEEDBACK.REQUEST),
  success: (response) => action(UNDO_CONJOINT_FEEDBACK.SUCCESS, { response }),
  failure: (error) => action(UNDO_CONJOINT_FEEDBACK.FAILURE, { error }),
};

export const ratingsHistory = {
  init: (username, query = "") => action(RATINGS_HISTORY.INIT, { username, query }),
  request: () => action(RATINGS_HISTORY.REQUEST),
  success: (response) => action(RATINGS_HISTORY.SUCCESS, { response }),
  failure: (error) => action(RATINGS_HISTORY.FAILURE, { error }),
};

export const teamRatingsHistory = {
  init: (username, query = "") => action(TEAM_RATINGS_HISTORY.INIT, { username, query }),
  request: () => action(TEAM_RATINGS_HISTORY.REQUEST),
  success: (response) => action(TEAM_RATINGS_HISTORY.SUCCESS, { response }),
  failure: (error) => action(TEAM_RATINGS_HISTORY.FAILURE, { error }),
};

export const relations = {
  init: (username, query = "") => action(RELATIONS.INIT, { username, query }),
  request: () => action(RELATIONS.REQUEST),
  success: (response) => action(RELATIONS.SUCCESS, { response }),
  failure: (error) => action(RELATIONS.FAILURE, { error }),
};

export const commonSkill = {
  init: (username, skillType, workmate) =>
    action(COMMON_SKILL.INIT, { username, skillType, workmate }),
  request: () => action(COMMON_SKILL.REQUEST),
  success: (response) => action(COMMON_SKILL.SUCCESS, { response }),
  failure: (error) => action(COMMON_SKILL.FAILURE, { error }),
};

export const submitFeedbackComment = (reviewer, talent, comment) =>
  talentFeedback.init({
    reviewer,
    talent,
    comment,
  });

export const submitFeedbackThanks = (reviewer, talent) =>
  talentFeedback.init({
    reviewer,
    talent,
    is_thank_you: true,
  });

export const submitSkillFeedback = (reviewer, skill, rating) =>
  skillFeedback.init({
    reviewer,
    rating,
    talent_skill: skill,
  });

export const submitFeedbackRequest = (createdBy, recipient) =>
  feedbackRequest.init({
    recipient,
    created_by: createdBy,
  });

export const submitConjointFeedback = (reviewer, winner, loser, skill) =>
  conjointFeedback.init({
    reviewer,
    winner_talent: winner,
    loser_talent: loser,
    skill,
  });

export const submitGroupThanks = (data) => {
  const tydata = Object.keys(data).map((key) => ({ ...data[key], isThankYou: true }));
  return groupFeedback.init(tydata);
};
