import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import { isEqual } from "lodash";
import { Button, Icon, Modal, Form } from "semantic-ui-react";
import { Views as BigCalendarViews } from "react-big-calendar";

import Errors from "components/Errors";
import TimezoneViewer from "containers/Meeting/ScreenMeetingForm/TimezoneViewer";
import { getCurrentMoment } from "utils/calendar";
import styles from "containers/Profile/styles.module.scss";

class SchedulerView extends React.Component {
  constructor(props) {
    super(props);
    this.rangePickerRef = React.createRef();
    const { currentUser, event } = props;
    const { start, timezoneDisplay } = event;
    const timezone = timezoneDisplay || currentUser.timezoneDisplay || moment.tz.guess();
    const currentDate = getCurrentMoment(timezone);
    const curMinutes = Math.floor(currentDate.minutes() / 30) * 30;
    const currentTime = currentDate.clone().minutes(curMinutes);

    this.state = { timezoneExpanded: false, timezoneDate: start || currentTime };
  }

  componentDidMount() {
    const { event, loadRoomCalendar, loadRoomMemberCalendar, currentUser } = this.props;
    if (event && event.roomId) {
      const query = { date: moment().format("YYYY-MM-DD") };
      if (currentUser.lastCalendarView === BigCalendarViews.MONTH) {
        query.bymonth = true;
      }
      loadRoomCalendar(event.roomId, query);
      loadRoomMemberCalendar(event.roomId, query);
    }
  }

  componentDidUpdate(prevProps) {
    const { event, currentUser, loadRoomMemberCalendar, loadRoomCalendar } = this.props;

    if (!isEqual(prevProps.event, this.props.event)) {
      const { start, timezoneDisplay } = event || {};

      const timezone = timezoneDisplay || currentUser.timezoneDisplay || moment.tz.guess();
      const currentDate = getCurrentMoment(timezone);
      // Ensure default meeting start is in multiples of 30 minutes
      const curMinutes = Math.floor(currentDate.minutes() / 30) * 30;
      const currentTime = currentDate.clone().minutes(curMinutes);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        timezoneDate: start || currentTime,
      });
    }
    if (!isEqual(prevProps.currentRoomId, this.props.currentRoomId) && this.props.currentRoomId) {
      const query = moment().format("YYYY-MM-DD");
      loadRoomCalendar(this.props.currentRoomId, { date: query });
      loadRoomMemberCalendar(this.currentRoomId, { date: query });
    }
  }

  handleIconClick = (name) => {
    this.setState({ timezoneExpanded: false });
    if (name === "eventStart") {
      this.rangePickerRef.current.startDateRef.current.handleFocus();
    } else {
      const element = document.getElementById(`scheduler-${name}`);
      if (element) element.focus();
    }
  };

  toggleTimezoneExpanded = () =>
    this.setState((prevState) => ({ timezoneExpanded: !prevState.timezoneExpanded }));

  handleTimezoneDateChange = (start, end) => {
    if (!!start && !!end) {
      this.props.onChange({
        eventStart: start,
        eventEnd: end,
        detailsChanged: true,
      });
    } else if (start) {
      this.props.onChange({
        eventStart: start,
        detailsChanged: true,
      });
    } else {
      this.props.onChange({
        eventEnd: end,
        detailsChanged: true,
      });
    }
  };

  handleTimezoneNavigate = (date) => this.setState({ timezoneDate: date });

  setTimezoneDate = (value) => this.setState({ timezoneDate: value });

  renderForm() {
    const { sharedFunctions, sharedState } = this.props;
    const { timezoneExpanded } = this.state;
    const { errors } = sharedState;
    const { handleUpdate, renderMeetingForm } = sharedFunctions;
    const iconClick = () => {};

    return (
      <Form
        className={classnames(styles.meetingForm, timezoneExpanded && styles.collapsed)}
        error={errors && Object.keys(errors).length > 0}
        onSubmit={() => handleUpdate()}
      >
        <Errors errors={errors} />
        <div className={styles.meetingFormFields}>
          <div className={styles.section}>
            <Form.Group inline style={{ margin: "0px" }}>
              <p className={styles.label}>Work Type</p>
              <Form.Radio
                disabled
                className={styles.radioBtn}
                label="Task"
                value="false"
                name="eventIsMeeting"
              />
              <Form.Radio
                disabled
                className={styles.radioBtn}
                label="Meeting"
                checked
                value="true"
                name="eventIsMeeting"
              />
              <Icon
                className={classnames(styles.icon, styles.unclickable)}
                name="suitcase"
                onClick={() => iconClick()}
              />
            </Form.Group>
          </div>
          {renderMeetingForm(true, {
            timezoneExpanded,
            toggleTimezoneExpanded: this.toggleTimezoneExpanded,
            handleIconClick: this.handleIconClick,
            rangePickerRef: this.rangePickerRef,
            setTimezoneDate: this.timezoneDate,
          })}
        </div>
      </Form>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderTimezones() {
    const {
      calendars,
      currentRoomId,
      currentUser,
      eventOccurrences,
      loadRoomMemberCalendar,
      members,
      sharedState,
    } = this.props;
    const { timezoneDate } = this.state;
    const { eventEnd, eventStart, eventTimezone } = sharedState;
    return (
      <div className={styles.timeline}>
        <div className={styles.meetingFormTimezones}>
          <div className={styles.timezonesContainer}>
            <TimezoneViewer
              calendars={calendars}
              currentDate={timezoneDate}
              currentRoomId={currentRoomId}
              currentUser={currentUser}
              eventOccurrences={eventOccurrences}
              members={members}
              selectedEnd={eventEnd}
              selectedStart={eventStart}
              selectedUserTimezone={eventTimezone}
              timezonesAdded={[]}
              loadRoomMemberCalendar={loadRoomMemberCalendar}
              onDateChange={this.handleTimezoneDateChange}
              onTimezoneNavigate={this.handleTimezoneNavigate}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <Modal
        className={styles.schedulerViewModal}
        open={open}
        closeOnDimmerClick={false}
        dimmer="inverted"
        onClose={onClose}
        size="fullscreen"
      >
        <Modal.Content>
          <div className={styles.schedulerHeader}>
            <div className={styles.schedulerHeaderTitle}>Scheduler</div>
            <Button basic className={styles.schedulerHeaderClose} onClick={onClose}>
              Close
            </Button>
          </div>
          <div className={styles.schedulerContainer}>
            {this.renderForm()}
            {this.renderTimezones()}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

SchedulerView.propTypes = {
  calendars: PropTypes.objectOf(PropTypes.shape()).isRequired,
  currentRoomId: PropTypes.number,
  currentUser: PropTypes.shape().isRequired,
  event: PropTypes.shape().isRequired,
  eventOccurrences: PropTypes.objectOf(PropTypes.shape()).isRequired,
  loadRoomCalendar: PropTypes.func.isRequired,
  loadRoomMemberCalendar: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
  sharedFunctions: PropTypes.shape().isRequired,
  sharedState: PropTypes.shape().isRequired,
};

SchedulerView.defaultProp = {
  open: false,
};

export default SchedulerView;
