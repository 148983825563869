import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";

import { notificationClear } from "actions/chat";
import { getCurrentRoomId, getCurrentNotifications } from "reducers/selectors";

import styles from "./styles.module.scss";

const autoClearDelay = 5000;

class Notification extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
    this.timer = null;
  }

  componentDidUpdate() {
    this.startAutoClearNotification();
  }

  onClose = () => {
    const { currentRoomId } = this.props;

    this.props.notificationClear(currentRoomId);
  };

  autoClearNotification = () => {
    const { currentRoomId } = this.props;

    this.props.notificationClear(currentRoomId);
    this.timer = null;
    this.startAutoClearNotification();
  };

  startAutoClearNotification() {
    const { notifications } = this.props;
    if (notifications.length === 0 || this.timer) return;

    this.timer = setTimeout(this.autoClearNotification, autoClearDelay);
  }

  render() {
    const { notifications, stylesClassName, dismissible } = this.props;
    if (notifications.length === 0) return null;

    return (
      <div className={classnames(styles.notification, stylesClassName)}>
        <span>{notifications[0].message}</span>
        {dismissible && (
          <Button className={styles.notificationButton} icon="close" onClick={this.onClose} />
        )}
      </div>
    );
  }
}

Notification.propTypes = {
  currentRoomId: PropTypes.number,
  notificationClear: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape()),
  stylesClassName: PropTypes.string,
  dismissible: PropTypes.bool,
};

Notification.defaultProps = {
  currentRoomId: null,
  notifications: [],
  stylesClassName: "",
  dismissible: true,
};

function mapStateToProps(state) {
  const currentRoomId = getCurrentRoomId(state);
  const notifications = getCurrentNotifications(state);

  return { currentRoomId, notifications };
}

export default connect(mapStateToProps, { notificationClear })(Notification);
