import PropTypes from "prop-types";
import React from "react";
import { Button, Header, Icon, Modal, Segment } from "semantic-ui-react";
import Draggable from "react-draggable";

import UserAvatar from "components/UserAvatar";
import NotificationSound from "utils/notificationSound";
import * as styles from "./styles.module.scss";

class ModelCallStarted extends React.PureComponent {
  handleJoin = () => {
    const { onJoinClick, callCode } = this.props;

    if (onJoinClick) onJoinClick(callCode);
    NotificationSound.pauseCall();
  };

  handleIgnore = () => {
    const { onIgnoreClick, callCode } = this.props;
    if (onIgnoreClick) onIgnoreClick(callCode);
    NotificationSound.pauseCall();
  };

  render() {
    const { caller, callerName } = this.props;

    return (
      <Draggable bounds="body">
        <Segment className={styles.ringer} inverted size="small">
          <Icon name="close" color="grey" onClick={this.handleIgnore} />

          <div style={{ textAlign: "center" }}>
            {caller && (
              <UserAvatar
                avatar={caller.avatarOrDefault}
                className={styles.callModalAvatar}
                size={84}
                displayName={caller.displayName}
                bgColor={caller.avatarBgcolor}
                fgColor={caller.avatarColor}
              />
            )}
            {!caller && (
              <UserAvatar className={styles.callModalAvatar} displayName={callerName} size={84} />
            )}
          </div>

          <Header
            as="h1"
            content={callerName || (caller && caller.displayName)}
            textAlign="center"
          />
          <Header as="h2" textAlign="center">
            Incoming call&hellip;
          </Header>

          <Modal.Actions style={{ textAlign: "center" }}>
            <Button
              circular
              color="red"
              icon="call"
              title="Ignore"
              onClick={this.handleIgnore}
              style={{ transform: "rotate(-135deg)", marginLeft: 5 }}
            />
            <Button
              circular
              color="green"
              icon={{ name: "call", flipped: "horizontally" }}
              title="Accept"
              onClick={this.handleJoin}
            />
          </Modal.Actions>
        </Segment>
      </Draggable>
    );
  }
}

ModelCallStarted.propTypes = {
  caller: PropTypes.shape(),
  callerName: PropTypes.string,
  onJoinClick: PropTypes.func,
  onIgnoreClick: PropTypes.func,
  callCode: PropTypes.string.isRequired,
};

export default ModelCallStarted;
