import { orderBy } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Shortcuts } from "react-shortcuts";

import { Dropdown, Input, Form } from "semantic-ui-react";

import { getSearch } from "actions/chat";
import { sidePanel, PANELS } from "actions/sidePanel";
import { getCurrentRoom } from "reducers/selectors";

import { atMentionAutoComplete } from "./autoComplete";

class ChannelHeaderSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoCompleteOpen: false,
      search: "",
      userLists: [],
    };
  }

  insertAutocomplete = (message, cursor, value) => {
    const leftMessage = message.slice(0, cursor);
    const rightMessage = message.slice(cursor);
    const separators = leftMessage.match(/[\s]/gi);
    const newMessage = `${
      separators
        ? `${leftMessage.slice(0, leftMessage.lastIndexOf(separators[separators.length - 1]) + 1)}`
        : ""
    }${value} ${rightMessage}`;
    this.setState({ search: newMessage }, () => {
      this.search.setValue(null);
      this.setUserList(newMessage, newMessage.length);
    });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleInput = (e) => {
    const {
      target: { value, selectionStart },
    } = e;
    this.setUserList(value, selectionStart);
  };

  handleKeyDown = (e) => {
    // handles tab key. must use keyDown to preempt switching focus
    const {
      keyCode,
      target: { value, selectionStart },
    } = e;
    const { searchOpen } = this.state;
    if (keyCode === 9 && searchOpen) {
      e.preventDefault();
      this.insertAutocomplete(value, selectionStart, this.search.getSelectedItem().value);
    }
  };

  handleSubmit = () => {
    const { currentRoom, loadingSearch, token } = this.props;
    const { search } = this.state;

    if (!search || loadingSearch) {
      return;
    }

    this.props.sidePanelOpen(PANELS.SEARCH, { roomId: currentRoom.id });
    const sortElem = document.querySelector("#search-sort");
    const sort = sortElem ? sortElem.value : "desc";
    this.props.getSearchRequest({ id: currentRoom.id, search, token, sort });
  };

  handleDropdownChange = (e, data) => {
    // handles arrow keys and enter keys when dropdown is open
    const { type, keyCode } = e;
    const { value, selectionStart } = this.query.inputRef;
    if (
      type === "click" ||
      (type === "keydown" && (keyCode === 13 || keyCode === 10 || keyCode === 9))
    ) {
      e.preventDefault();
      this.insertAutocomplete(value, selectionStart, data.value);
    }
  };

  handleShortcut = (action, event) => {
    const { currentRoom } = this.props;
    switch (action) {
      case "CLOSE":
        event.preventDefault();
        event.stopPropagation();
        this.props.sidePanelClose(PANELS.SEARCH, { roomId: currentRoom.id });
        break;
      default:
        break;
    }
  };

  setUserList = (message, cursor) => {
    const { currentUser, users, currentRoom } = this.props;

    if (message.length === 0) {
      this.setState({
        userLists: [],
        autoCompleteOpen: false,
      });
    } else {
      atMentionAutoComplete({
        addSpecial: false,
        includeSelf: true,
        user: currentUser,
        users,
        prefix: "from:",
        room: currentRoom,
        message: message.substring(0, cursor),
        callback: (userLists) => {
          this.setState({
            userLists,
            autoCompleteOpen: userLists.length > 0,
          });
        },
      });
    }
  };

  render() {
    const { loadingSearch, className } = this.props;
    const { autoCompleteOpen, search, userLists } = this.state;

    const userList = orderBy([].concat(...Object.values(userLists)), "value", "asc");

    return (
      <Shortcuts
        className="shortcuts"
        name="MESSAGE_BOX_THREAD"
        alwaysFireHandler
        handler={this.handleShortcut}
        stopPropagation={false}
        preventDefault={false}
      >
        <Form onSubmit={this.handleSubmit} className={className}>
          <Input
            id="search"
            fluid
            icon={loadingSearch ? { name: "spinner", loading: true } : { name: "search" }}
            iconPosition="left"
            name="search"
            onChange={this.handleChange}
            onInput={this.handleInput}
            onKeyDown={this.handleKeyDown}
            placeholder="Search"
            type="search"
            value={search}
            ref={(ref) => {
              this.query = ref;
            }}
          />
          <Dropdown
            fluid
            scrolling
            wrapSelection
            open={autoCompleteOpen}
            options={userList}
            onChange={this.handleDropdownChange}
            openOnFocus={false}
            selectOnBlur={false}
            selectOnNavigation={false}
            noResultsMessage={null}
            icon={null}
            ref={(ref) => {
              this.search = ref;
            }}
          />
          <button type="submit" className="sr-only">
            Search
          </button>
        </Form>
      </Shortcuts>
    );
  }
}

ChannelHeaderSearch.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  currentRoom: PropTypes.shape(),
  loadingSearch: PropTypes.bool,
  token: PropTypes.string.isRequired,
  users: PropTypes.shape().isRequired,
  className: PropTypes.string,

  getSearchRequest: PropTypes.func.isRequired,
  sidePanelClose: PropTypes.func.isRequired,
  sidePanelOpen: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser, token },
    entities: { users },
  } = state;
  const currentRoom = getCurrentRoom(state);
  const loadingSearch = state.messages.loadingSearch[currentRoom.id] || false;

  return {
    currentUser,
    currentRoom,
    loadingSearch,
    token,
    users,
  };
}

export default connect(mapStateToProps, {
  getSearchRequest: getSearch.request,
  sidePanelClose: sidePanel.close,
  sidePanelOpen: sidePanel.open,
})(ChannelHeaderSearch);
