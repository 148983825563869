import { call, put, takeLatest } from "redux-saga/effects";
import { api, token as tokenService } from "services";

import * as health from "actions/health";
import { fetchEntity, fetchEntityQS } from "./base";

const loadForm = fetchEntity.bind(null, health.healthForm, api.loadForm);
const loadFormData = fetchEntity.bind(null, health.healthFormData, api.loadFormData);
const loadGuide = fetchEntity.bind(null, health.healthGuide, api.loadGuide);

const fetchSummaryAggregated = fetchEntityQS.bind(
  null,
  health.healthSummaryAggregated,
  api.loadSummaryAggregated
);
const fetchPersonal = fetchEntityQS.bind(null, health.healthPersonal, api.loadPersonal);

export function* updateFormData({ id, data }) {
  const entity = health.healthFormDataUpdate;
  const apiFn = api.updateFormData;
  const refresh = true;
  const token = yield tokenService.getAuthToken();

  yield put(entity.request(refresh));
  const { response, error, errorDetails } = yield call(apiFn, token, id || null, data);
  if (response) {
    yield put(entity.success(response));
  } else if ((errorDetails || {}).duplicate_submission) {
    yield call(loadFormData);
    yield put(entity.failure({ error, errorDetails }));
  } else {
    yield put(entity.failure({ error, errorDetails }));
  }
  return response;
}

export function* loadSummaryAggregated({ query }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchSummaryAggregated, true, token, null, null, query);
}

export function* loadPersonal({ query }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchPersonal, true, token, null, null, query);
}

export function* watchHealth() {
  yield takeLatest(health.HEALTH_FORM.INIT, loadForm);
  yield takeLatest(health.HEALTH_FORM_DATA.INIT, loadFormData);
  yield takeLatest(health.HEALTH_FORM_DATA_UPDATE.INIT, updateFormData);
  yield takeLatest(health.HEALTH_SUMMARY_AGGREGATED.INIT, loadSummaryAggregated);
  yield takeLatest(health.HEALTH_PERSONAL.INIT, loadPersonal);
  yield takeLatest(health.HEALTH_GUIDE.INIT, loadGuide);
}
