import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";

import { getRooms } from "reducers/selectors";
import styles from "./styles.module.scss";

class UnreadBelow extends React.PureComponent {
  _lastUnreadFocusable = null;

  constructor(props) {
    super(props);

    this.state = {
      showUnreadBelow: false,
    };
  }

  componentDidMount() {
    document
      .querySelector(`.${styles.sidebarContent}`)
      .addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  componentWillUnmount() {
    document
      .querySelector(`.${styles.sidebarContent}`)
      .removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleScroll);
  }

  handleScroll = () => {
    const { showUnreadBelow } = this.state;
    const target = document.querySelector(`.${styles.sidebarContent}`);
    const unreadFocusables = document.querySelectorAll(`[data-focusable].${styles.unread}`);
    if (unreadFocusables.length > 0) {
      const lastUnreadFocusable = unreadFocusables[unreadFocusables.length - 1];
      const bottomVisible =
        target.scrollTop + target.clientHeight - lastUnreadFocusable.clientHeight;

      if (!showUnreadBelow && lastUnreadFocusable.offsetTop >= bottomVisible) {
        this.setState({
          showUnreadBelow: true,
        });
        this._lastUnreadFocusable = lastUnreadFocusable;
      } else if (showUnreadBelow && lastUnreadFocusable.offsetTop < bottomVisible) {
        this.setState({
          showUnreadBelow: false,
        });
        this._lastUnreadFocusable = null;
      }
    }
  };

  handleGoToLastUnreadFocusable = () => {
    if (this._lastUnreadFocusable) {
      this._lastUnreadFocusable.scrollIntoView();
    }
  };

  render() {
    const { hasUnreadRoom } = this.props;
    const { showUnreadBelow } = this.state;

    if (!hasUnreadRoom || !showUnreadBelow) {
      return null;
    }

    return (
      <button
        onClick={this.handleGoToLastUnreadFocusable}
        type="button"
        className={classnames(styles.sidebarUnread, styles.unreadBelow)}
      >
        Unread messages <Icon name="arrow down" style={{ fontWeight: 900, paddingLeft: 16 }} />
      </button>
    );
  }
}

UnreadBelow.propTypes = {
  hasUnreadRoom: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const rooms = getRooms(state) || {};
  const { unreadMessagesCountByRoom } = state.messages;
  const hasUnreadRoom = !!Object.keys(rooms).find(
    (roomId) => unreadMessagesCountByRoom[roomId] > 0
  );
  return {
    hasUnreadRoom,
  };
}

export default connect(mapStateToProps)(UnreadBelow);
