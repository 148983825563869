import { camelizeKeys } from "humps";
import { stringify as queryStringify } from "querystring-es3";
import { all, call, delay, fork, put, race, take, takeEvery, takeLatest } from "redux-saga/effects";

import { navigate } from "actions";
import { cleanEventOccurrences, loadUserCalendar } from "../actions/calendar";
import * as chat from "../actions/chat";
import { api, token as tokenService } from "../services";

function* addMembers(request) {
  const { emails, roomId, userSlugs } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, errorDetails } = yield call(
    api.addRoomMembers,
    token,
    roomId,
    emails,
    userSlugs
  );

  if (response) {
    yield put(chat.addMembers.success(roomId, response));
    if (roomId !== response.id) {
      yield put(navigate(`/chat/r/${response.id}`, true));
    }
  } else {
    yield put(chat.addMembers.failure(roomId, errorDetails));
  }
}

function* countUnreadMessages(token) {
  const { response, error } = yield call(api.countUnreadMessages, token);

  if (response) {
    yield put(chat.countUnreadMessages.success(response));
  } else {
    yield put(chat.countUnreadMessages.failure(error));
  }
}

function* createRoom(request) {
  const { teamSlug, token, userSlugs, name } = request;
  const { response, error } = yield call(api.createRoom, token, { teamSlug, userSlugs, name });

  if (response) {
    yield put(chat.createRoom.success(response));
    yield put(navigate(`/chat/r/${response.id}`, true));
  } else {
    yield put(chat.createRoom.failure(error));
  }
}

function* hideRoom(request) {
  const { id } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.hideRoom, token, id);

  if (response) {
    yield put(chat.roomHide.success());
  } else {
    yield put(chat.roomHide.failure(error));
  }
}

function* disconnectCall(request) {
  const { callerId, callCode, token } = request;
  const { response, error } = yield call(api.disconnectCall, token, callCode, callerId);

  if (response) {
    yield put(chat.disconnectCall.success(callCode, response));
  } else {
    yield put(chat.disconnectCall.failure(callCode, error));
  }
}

function* fetchReactions(request) {
  const { token, id, messageId } = request;
  const { response, error } = yield call(api.fetchReactions, token, id, messageId);

  if (response) {
    yield put(chat.reactions.success(response, id, messageId));
  } else {
    yield put(chat.reactions.failure(error));
  }
}

function* reactionsAdd(request) {
  const { token, id, messageId, emoji } = request;
  const { error } = yield call(api.reactionsAdd, token, id, messageId, emoji);

  // success is handled by Pusher container
  if (error) {
    yield put(chat.reactionsAdd.failure(error));
  }
}

function* reactionsDelete(request) {
  const { token, id, messageId, emoji } = request;
  const { error } = yield call(api.reactionsDelete, token, id, messageId, emoji);

  // success is handled by Pusher container
  if (error) {
    yield put(chat.reactionsDelete.failure(error));
  }
}

function* fetchAttachments(request) {
  const { id, token, url } = request;
  const { response, error } = yield call(api.fetchAttachments, token, id, url);

  if (response) {
    yield put(chat.getAttachments.success(id, response));
  } else {
    yield put(chat.getAttachments.failure(id, error));
  }
}

function* fetchMessages(request) {
  const { id, jump, token, url } = request;
  const { response, error } = yield call(api.fetchMessages, token, id, jump, url);

  if (response) {
    yield put(chat.getMessages.success(id, jump, response));
  } else {
    yield put(chat.getMessages.failure(id, error));
  }
}

function* fetchMessage(request) {
  const { id, mid, token } = request;
  const { response } = yield call(api.fetchMessage, token, id, mid);

  if (response) {
    yield put(chat.pusherReceiveMessage.success(id, response));
  }
}

function* fetchPinnedMessages(request, url = null) {
  const { id, token } = request;
  const { response, error } = yield call(api.fetchPinnedMessages, token, id, url);

  if (response) {
    yield put(chat.getPinnedMessages.success(id, response));
    if (response.next) {
      yield call(fetchPinnedMessages, request, response.next);
    }
  } else {
    yield put(chat.getPinnedMessages.failure(id, error));
  }
}

function* fetchThread(request, url = null) {
  const { id, messageId, token } = request;
  const { response, error } = yield call(api.fetchThread, token, id, messageId, url);

  if (response) {
    yield put(chat.getThread.success(id, messageId, response));
    if (response.next) {
      yield call(fetchThread, request, response.next);
    }
  } else {
    yield put(chat.getThread.failure(id, messageId, error));
  }
}

function* fetchSearch(request) {
  const { id, token, search, sort } = request;
  const { response, error } = yield call(api.fetchSearch, token, id, search, sort);

  if (response) {
    yield put(chat.getSearch.success(id, response, sort));
  } else {
    yield put(chat.getSearch.failure(id, error));
  }
}

function* fetchCompleteMessage(request) {
  const { id, mid, token } = request;
  const { response } = yield call(api.fetchMessage, token, id, mid);

  if (response) {
    return response;
  }
  return null;
}

function* fetchPusherMessage(request) {
  const {
    data,
    user: { slug },
  } = request;

  let response = camelizeKeys(data);
  const { roomId } = response;

  // Retrieve the complete message if Pusher sent only sent part of the message.
  if (!response.isDeleted && response.message.length !== response.messageLength) {
    const token = yield call(tokenService.getAuthToken);
    const expandedResponse = yield call(fetchCompleteMessage, {
      id: roomId,
      mid: response.messageId,
      token,
    });

    if (expandedResponse) {
      response = { ...response, ...expandedResponse };
    } else {
      response.error = { message: "Unable to retrieve the entire message." };
    }
  }

  response.content = response.message;
  delete response.message;

  response.created = response.sendAt;
  delete response.sendAt;

  response.id = response.messageId;
  delete response.messageId;

  if (typeof response.hasRead === "undefined" && (response.sendId || response.author === slug)) {
    response.hasRead = response.author === slug;
  }
  delete response.roomId;

  yield put(chat.pusherReceiveMessage.success(roomId, response));
}

function* fetchRoom(request) {
  const { id, token } = request;
  const { response, error } = yield call(api.fetchRoom, token, id);

  if (response) {
    yield put(chat.getRoom.success(response));
  } else {
    yield put(chat.getRooms.failure(error));
  }
}

export function* fetchRooms(token) {
  const { response, error } = yield call(api.fetchRooms, token);

  if (response) {
    yield put(chat.getRooms.success({ results: response }));
  } else {
    yield put(chat.getRooms.failure(error));
  }
}

function* readMessages(request) {
  const { id, token } = request;
  const { response, error } = yield call(api.readMessages, token, id);

  if (response) {
    yield put(chat.readMessages.success(id, response.count));
  } else {
    yield put(chat.readMessages.failure(id, error));
  }
}

function* editMessage(request) {
  const { id, messageId, content } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.editMessage, token, id, messageId, content);

  if (response) {
    yield put(chat.editMessage.success(id, messageId));
  } else {
    yield put(chat.editMessage.failure(id, messageId, error));
  }
}

function* deleteMessage(request) {
  const { id, messageId } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.deleteMessage, token, id, messageId);

  if (response) {
    yield put(chat.deleteMessage.success(id, messageId));
  } else {
    yield put(chat.deleteMessage.failure(id, messageId, error));
  }
}

function* pinMessage(request) {
  const { id, messageId } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.pinMessage, token, id, messageId);

  if (response) {
    yield put(chat.pinMessage.success(id, messageId));
  } else {
    yield put(chat.pinMessage.failure(id, messageId, error));
  }
}

function* unpinMessage(request) {
  const { id, messageId } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.unpinMessage, token, id, messageId);

  if (response) {
    yield put(chat.unpinMessage.success(id, messageId));
  } else {
    yield put(chat.unpinMessage.failure(id, messageId, error));
  }
}

function* invitationDelete(request) {
  const { invitation, reject, resolve } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, errorDetails } = yield call(api.invitationDelete, token, invitation);

  if (response) {
    yield put(chat.invitationDelete.success(invitation, response));
    if (resolve) resolve();
  } else {
    yield put(chat.invitationDelete.failure(invitation, errorDetails));
    if (reject) reject(errorDetails);
  }
}

function* invitationResend(request) {
  const { invitation, roomId } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, errorDetails } = yield call(api.invitationResend, token, invitation);

  if (response) {
    yield put(chat.invitationResend.success(roomId, invitation, response));
  } else {
    yield put(chat.invitationResend.failure(roomId, invitation, errorDetails));
  }
}

function* pusherUserTypingInit(id) {
  const token = yield tokenService.getAuthToken();
  yield call(api.startTyping, token, id);
}

function* pusherUserTyping(id, author) {
  yield delay(10000);
  yield put(chat.pusherUserTyping.end({ id, author }));
}

function* sendMessage(request) {
  const { id, content, files, sendId, token, root } = request;

  const { response, error } = yield call(api.sendMessage, token, id, content, sendId, files, root);

  if (response) {
    yield put(chat.pusherSendMessage.success(id, response));
  } else if (error) {
    yield put(chat.pusherSendMessage.failure(id, sendId, error));
  }
}

function* signalCall(token, callCode, response) {
  const { callId, callerId } = response;

  while (true) {
    yield call(api.signalCall, token, callCode, callId, callerId);

    const { poll } = yield race({
      disconnect: take(chat.DISCONNECT_CALL.SUCCESS),
      poll: call(delay, 6000),
    });

    if (!poll) break;
  }
}

function* performAction(action, data) {
  yield put(chat.performAction.request());
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.performAction, token, action, data);
  if (response) {
    yield put(chat.performAction.success(response));
  } else if (error) {
    yield put(chat.performAction.failure(error));
  }
}

function* pollCreate(request) {
  const { poll, reject, resolve, roomId } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, errorDetails } = yield call(api.pollCreate, token, roomId, poll);

  if (response) {
    yield put(chat.pollCreate.success(roomId, response));
    resolve(response);
  } else {
    yield put(chat.pollCreate.failure(roomId, errorDetails));
    reject(errorDetails);
  }
}

function* pollDelete(request) {
  const { poll } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, errorDetails } = yield call(api.pollDelete, token, poll.id);

  if (response) {
    yield put(chat.pollDelete.success(poll, response));
  } else {
    yield put(chat.pollDelete.failure(poll, errorDetails));
  }
}

function* pollDetail(request) {
  const { fromCurrentUser, pollId } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, errorDetails } = yield call(api.pollDetail, token, pollId);

  if (response) {
    yield put(chat.pollDetail.success(fromCurrentUser, response));
  } else {
    yield put(chat.pollDetail.failure(fromCurrentUser, errorDetails));
  }
}

function* pollList(request) {
  const { isArchived, roomId, token } = request;
  const { response, error } = yield call(api.pollList, token, roomId, isArchived);

  if (response) {
    yield put(chat.pollList.success(roomId, response));
  } else {
    yield put(chat.pollList.failure(roomId, error));
  }
}

function* pollDelegate(request) {
  const { poll, delegateSlug } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.pollDelegate, token, poll.id, delegateSlug);

  if (response) {
    yield put(chat.pollDelegate.success(poll, response));
  } else {
    yield put(chat.pollDelegate.failure(poll, error));
  }
}

function* pollMultipleVote(request) {
  const { poll, votes } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.pollMultipleVote, token, poll.id, votes);

  if (response) {
    yield put(chat.pollMultipleVote.success(poll, response));
  } else {
    yield put(chat.pollMultipleVote.failure(poll, error));
  }
}

function* pollSearchSkills(request) {
  const { question } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.pollSearchSkills, token, question);

  if (response) {
    yield put(chat.pollSearchSkills.success(response));
  } else {
    yield put(chat.pollSearchSkills.failure(error));
  }
}

function* pollUpdate(request) {
  const { poll, reject, resolve } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, errorDetails } = yield call(api.pollUpdate, token, poll);

  if (response) {
    yield put(chat.pollUpdate.success(response));
    if (resolve) resolve(response);
  } else {
    yield put(chat.pollUpdate.failure(errorDetails));
    if (reject) reject(errorDetails);
  }
}

function* renameRoom(id, name) {
  yield put(chat.renameRoom.request(id, name));
  const token = yield call(tokenService.getAuthToken);
  const { response, error } = yield call(api.renameRoom, token, id, name);
  if (response) {
    yield put(chat.renameRoom.success(id, response));
  } else if (error) {
    yield put(chat.renameRoom.failure(id, error));
  }
}

function* startCall(request) {
  const { callCode, displayName, token } = request;

  const { response, error, errorDetails } = yield call(api.startCall, token, callCode, displayName);

  if (response) {
    yield all([
      put(chat.startCall.success(callCode, response)),
      fork(signalCall, token, callCode, response),
    ]);
  } else if (error) {
    yield put(chat.startCall.failure(callCode, errorDetails));
  }
}

function* slashCommand(request) {
  const { token, id, command, data, onSuccess, onFailure } = request;
  const { response, error, errorDetails } = yield call(api.slashCommand, token, id, command, data);

  if (response) {
    yield put(chat.slashCommand.success(id, response));
    if (onSuccess) onSuccess();
  } else if (error) {
    yield put(chat.slashCommand.failure(id, error, errorDetails));
    if (onFailure) onFailure();
  }
}

function* roomMembershipUpdate(request) {
  const { id, roomId, token, data } = request;
  const { response, error, errorDetails } = yield call(api.roomMembershipUpdate, id, token, data);

  if (response) {
    yield put(chat.roomMembershipUpdate.success(id, roomId, response));
  } else if (error) {
    yield put(chat.roomMembershipUpdate.failure(id, error, errorDetails));
  }
}

function* roomUpdate(request) {
  const { fields, id, reject, resolve } = request;
  const token = yield call(tokenService.getAuthToken);
  const { response, errorDetails } = yield call(api.roomUpdate, token, id, fields);

  if (response) {
    yield put(chat.roomUpdate.success(id, response));
    if (resolve) resolve(response);
  } else {
    yield put(chat.roomUpdate.failure(id, errorDetails));
    if (reject) reject(errorDetails);
  }
}

function* loadRoomCalendar(request) {
  const { id, resolve, reject, params } = request;
  const baseUrl = `chat/${id}/calendar/`;
  const query = queryStringify(params);
  const url = query ? `${baseUrl}?${query}` : baseUrl;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.fetchRoomCalendar, token, url);

  if (response) {
    yield put(chat.roomCalendar.success(response));
    if (resolve) resolve(response);
  } else {
    yield put(chat.roomCalendar.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
}

function* loadRoomMemberCalendar(request) {
  const { id, params } = request;
  const baseUrl = `chat/${id}/calendar/users/`;
  const query = queryStringify(params);
  const url = query ? `${baseUrl}?${query}` : baseUrl;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.fetchRoomMemberCalendar, token, url);

  if (response) {
    yield put(chat.roomMemberCalendar.success(response));
  } else {
    yield put(chat.roomMemberCalendar.failure({ error, errorDetails }));
  }
}

function* loadRoomMeetings(request) {
  const { roomId } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.fetchRoomMeetings, token, roomId);

  if (response) {
    yield put(chat.roomMeetingList.success(response));
  } else {
    yield put(chat.roomMeetingList.failure({ error, errorDetails }));
  }
}

function* createMeeting({ id, data, resolve, reject, params, userSlug }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.roomMeetingCreate, token, id, data);
  if (response) {
    yield put(chat.roomMeetingCreate.success(response));
    yield put(chat.roomCalendar.request(id, params));
    if (userSlug) yield put(loadUserCalendar(userSlug, true, params));
    if (resolve) resolve(response);
  } else {
    yield put(chat.roomMeetingCreate.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
}

function* updateMeeting({ rid, oid, data, resolve, reject, params }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(
    api.roomMeetingUpdate,
    token,
    rid,
    oid,
    data
  );
  if (response) {
    yield put(cleanEventOccurrences(rid, [response.result]));
    yield put(chat.roomMeetingUpdate.success(response));
    yield put(chat.roomCalendar.request(rid, params));
    if (resolve) resolve(response);
  } else {
    yield put(chat.roomMeetingUpdate.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
}

function* deleteMeeting({ rid, oid, resolve, reject, emailNotif }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(
    api.roomMeetingDelete,
    token,
    rid,
    oid,
    emailNotif ? 1 : 0
  );
  if (response) {
    yield put(cleanEventOccurrences(rid, [oid], true));
    yield put(chat.roomMeetingDelete.success(response));
    if (resolve) resolve();
  } else {
    yield put(chat.roomMeetingDelete.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
}

function* meetingDetail(request) {
  const { fromCurrentUser, rid, oid } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.roomMeetingDetail, token, rid, oid);

  if (response) {
    yield put(chat.roomMeetingDetail.success(fromCurrentUser, response, oid));
  } else {
    yield put(chat.roomMeetingDetail.failure({ error, errorDetails, fromCurrentUser }));
  }
}

function* meetingJoin(request) {
  const { join, rid, event } = request;
  const token = yield tokenService.getAuthToken();
  let apiFn = api.roomMeetingDecline;
  if (join) {
    apiFn = api.roomMeetingJoin;
  }
  const { response, error, errorDetails } = yield call(apiFn, token, rid, event.id);

  if (response) {
    yield put(chat.roomMeetingJoin.success(response, event));
  } else {
    yield put(chat.roomMeetingJoin.failure({ error, errorDetails, event }));
  }
}

function* watchAddMembers() {
  while (true) {
    const request = yield take(chat.ADD_MEMBERS.REQUEST);
    yield fork(addMembers, request);
  }
}

function* watchCountUnreadMessages() {
  while (true) {
    yield take(chat.COUNT_UNREAD_MESSAGES.REQUEST);
    const token = yield tokenService.getAuthToken();
    yield fork(countUnreadMessages, token);
  }
}

function* watchCreateRoom() {
  while (true) {
    const request = yield take(chat.CREATE_ROOM.REQUEST);
    yield fork(createRoom, request);
  }
}

function* watchHideRoom() {
  while (true) {
    const request = yield take(chat.ROOM_HIDE.REQUEST);
    yield fork(hideRoom, request);
  }
}

function* watchDisconnectCall() {
  while (true) {
    const request = yield take(chat.DISCONNECT_CALL.REQUEST);
    yield fork(disconnectCall, request);
  }
}

function* watchFetchAttachments() {
  while (true) {
    const request = yield take(chat.GET_ATTACHMENTS.REQUEST);
    yield fork(fetchAttachments, request);
  }
}

function* watchFetchMessages() {
  while (true) {
    const request = yield take(chat.GET_MESSAGES.REQUEST);
    yield fork(fetchMessages, request);
  }
}

function* watchFetchMessage() {
  while (true) {
    const request = yield take(chat.GET_MESSAGE.REQUEST);
    yield fork(fetchMessage, request);
  }
}

function* watchFetchReations() {
  while (true) {
    const request = yield take(chat.REACTIONS.REQUEST);
    yield fork(fetchReactions, request);
  }
}

function* watchFetchPinnedMessages() {
  while (true) {
    const request = yield take(chat.GET_PINNED_MESSAGES.REQUEST);
    yield fork(fetchPinnedMessages, request);
  }
}

function* watchFetchThread() {
  while (true) {
    const request = yield take(chat.GET_THREAD.REQUEST);
    yield fork(fetchThread, request);
  }
}

function* watchFetchSearch() {
  while (true) {
    const request = yield take(chat.GET_SEARCH.REQUEST);
    yield fork(fetchSearch, request);
  }
}

function* watchFetchPusherMessage() {
  while (true) {
    const request = yield take(chat.PUSHER_RECEIVE_MESSAGE.REQUEST);
    yield fork(fetchPusherMessage, request);
  }
}

function* watchPusherUserTyping() {
  while (true) {
    const { id, author } = yield take(chat.PUSHER_USER_TYPING.START);
    yield fork(pusherUserTyping, id, author);
  }
}

function* watchPusherUserTypingInit() {
  while (true) {
    const { id } = yield take(chat.PUSHER_USER_TYPING.INIT);
    yield fork(pusherUserTypingInit, id);
  }
}

function* watchFetchRoom() {
  while (true) {
    const request = yield take(chat.GET_ROOM.REQUEST);
    yield fork(fetchRoom, request);
  }
}

function* watchFetchRooms() {
  while (true) {
    const { token } = yield take(chat.GET_ROOMS.REQUEST);
    yield fork(fetchRooms, token);
  }
}

function* watchPerformAction() {
  while (true) {
    const { action, data } = yield take(chat.PERFORM_ACTION.INIT);
    yield fork(performAction, action, data);
  }
}

function* watchReadMessages() {
  while (true) {
    const request = yield take(chat.READ_MESSAGES.REQUEST);
    yield fork(readMessages, request);
  }
}

function* watchEditMessage() {
  while (true) {
    const request = yield take(chat.EDIT_MESSAGE.REQUEST);
    yield fork(editMessage, request);
  }
}

function* watchDeleteMessage() {
  while (true) {
    const request = yield take(chat.DELETE_MESSAGE.REQUEST);
    yield fork(deleteMessage, request);
  }
}

function* watchPinMessage() {
  while (true) {
    const request = yield take(chat.PIN_MESSAGE.REQUEST);
    yield fork(pinMessage, request);
  }
}

function* watchUnpinMessage() {
  while (true) {
    const request = yield take(chat.UNPIN_MESSAGE.REQUEST);
    yield fork(unpinMessage, request);
  }
}

function* watchInvitationDelete() {
  while (true) {
    const request = yield take(chat.INVITATION_DELETE.REQUEST);
    yield fork(invitationDelete, request);
  }
}

function* watchInvitationResend() {
  while (true) {
    const request = yield take(chat.INVITATION_RESEND.REQUEST);
    yield fork(invitationResend, request);
  }
}

function* watchPollCreate() {
  while (true) {
    const request = yield take(chat.POLL_CREATE.REQUEST);
    yield fork(pollCreate, request);
  }
}

function* watchPollList() {
  while (true) {
    const request = yield take(chat.POLL_LIST.REQUEST);
    yield fork(pollList, request);
  }
}

function* watchPollDelegate() {
  while (true) {
    const request = yield take(chat.POLL_DELEGATE.REQUEST);
    yield fork(pollDelegate, request);
  }
}

function* watchPollMultipleVote() {
  while (true) {
    const request = yield take(chat.POLL_MULTIPLE_VOTE.REQUEST);
    yield fork(pollMultipleVote, request);
  }
}

function* watchPollSearchSkills() {
  while (true) {
    const request = yield take(chat.POLL_SEARCH_SKILLS.REQUEST);
    yield fork(pollSearchSkills, request);
  }
}

function* watchPollUpdate() {
  while (true) {
    const request = yield take(chat.POLL_UPDATE.REQUEST);
    yield fork(pollUpdate, request);
  }
}

function* watchRenameRoom() {
  while (true) {
    const { id, name } = yield take(chat.RENAME_ROOM.INIT);
    yield fork(renameRoom, id, name);
  }
}

function* watchSendMessage() {
  while (true) {
    const request = yield take(chat.PUSHER_SEND_MESSAGE.REQUEST);
    yield fork(sendMessage, request);
  }
}

function* watchStartCall() {
  while (true) {
    const request = yield take(chat.START_CALL.REQUEST);
    yield fork(startCall, request);
  }
}

function* watchSlashCommand() {
  while (true) {
    const request = yield take(chat.SLASH_COMMAND.REQUEST);
    yield fork(slashCommand, request);
  }
}

function* watchRoomMembershipUpdate() {
  while (true) {
    const request = yield take(chat.ROOM_MEMBERSHIP_UPDATE.REQUEST);
    yield fork(roomMembershipUpdate, request);
  }
}

function* watchLoadRoomCalendar() {
  while (true) {
    const request = yield take(chat.ROOM_CALENDAR.REQUEST);
    yield fork(loadRoomCalendar, request);
  }
}

function* watchLoadRoomMemberCalendar() {
  while (true) {
    const request = yield take(chat.ROOM_MEMBER_CALENDAR.REQUEST);
    yield fork(loadRoomMemberCalendar, request);
  }
}

function* watchLoadRoomMeetings() {
  while (true) {
    const request = yield take(chat.ROOM_MEETING_LIST.REQUEST);
    yield fork(loadRoomMeetings, request);
  }
}

function* watchCreateMeeting() {
  while (true) {
    const request = yield take(chat.ROOM_MEETING_CREATE.REQUEST);
    yield fork(createMeeting, request);
  }
}

function* watchUpdateMeeting() {
  while (true) {
    const request = yield take(chat.ROOM_MEETING_UPDATE.REQUEST);
    yield fork(updateMeeting, request);
  }
}

function* watchDeleteMeeting() {
  while (true) {
    const request = yield take(chat.ROOM_MEETING_DELETE.REQUEST);
    yield fork(deleteMeeting, request);
  }
}

function* watchMeetingDetail() {
  while (true) {
    const request = yield take(chat.ROOM_MEETING_DETAIL.REQUEST);
    yield fork(meetingDetail, request);
  }
}

function* watchMeetingJoin() {
  while (true) {
    const request = yield take(chat.ROOM_MEETING_JOIN.REQUEST);
    yield fork(meetingJoin, request);
  }
}

export function* rootChat() {
  yield all([
    fork(watchAddMembers),
    fork(watchCountUnreadMessages),
    fork(watchCreateRoom),
    fork(watchDeleteMessage),
    fork(watchHideRoom),
    fork(watchPinMessage),
    fork(watchUnpinMessage),
    fork(watchDisconnectCall),
    fork(watchEditMessage),
    fork(watchFetchAttachments),
    fork(watchFetchMessages),
    fork(watchFetchMessage),
    fork(watchFetchReations),
    fork(watchFetchPinnedMessages),
    fork(watchFetchThread),
    fork(watchFetchSearch),
    fork(watchFetchPusherMessage),
    fork(watchFetchRoom),
    fork(watchFetchRooms),
    fork(watchInvitationDelete),
    fork(watchInvitationResend),
    fork(watchPerformAction),
    fork(watchPollCreate),
    fork(watchPollList),
    fork(watchPollDelegate),
    fork(watchPollMultipleVote),
    fork(watchPollSearchSkills),
    fork(watchPollUpdate),
    fork(watchPusherUserTyping),
    fork(watchPusherUserTypingInit),
    fork(watchReadMessages),
    fork(watchRenameRoom),
    fork(watchSendMessage),
    fork(watchStartCall),
    fork(watchSlashCommand),
    fork(watchRoomMembershipUpdate),
    fork(watchLoadRoomCalendar),
    fork(watchLoadRoomMemberCalendar),
    fork(watchLoadRoomMeetings),
    fork(watchCreateMeeting),
    fork(watchUpdateMeeting),
    fork(watchDeleteMeeting),
    fork(watchMeetingDetail),
    fork(watchMeetingJoin),
  ]);
  yield takeEvery(chat.POLL_DELETE.REQUEST, pollDelete);
  yield takeEvery(chat.POLL_DETAIL.REQUEST, pollDetail);
  yield takeLatest(chat.REACTIONS_ADD.REQUEST, reactionsAdd);
  yield takeLatest(chat.REACTIONS_DELETE.REQUEST, reactionsDelete);
  yield takeLatest(chat.ROOM_UPDATE.REQUEST, roomUpdate);
}
