import { decamelizeKeys } from 'humps';
import { callApi, callApiWithToken } from './base';

const getLoginUrl = (provider) => 'auth/login/';

export const login = (credentials, extraData) => {
  const auth = decamelizeKeys(credentials);
  return callApi(
    getLoginUrl(),
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify({ ...auth, ...extraData })
  );
};

export const retrieveToken = (token, sessionToken) =>
  callApiWithToken(`auth/token/?session_token=${sessionToken || ""}`, token, null, 'GET', {}, null);

export const register = (data) =>
  callApi(
    'auth/registration/',
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(data)
  );

export const logout = (token, csrftoken) =>
  callApiWithToken('auth/logout/', token, null, 'POST', {}, '');

export const fetchMe = (token) => callApiWithToken('auth/user/', token);

export const fetchConfirmEmail = (key) =>
  callApi(`auth/registration/account-confirm-email/${key}/`);

export const verifyEmail = (key) =>
  callApi(
    'auth/registration/verify-email/',
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ key })
  );

export const passwordReset = (email) =>
  callApi(
    'auth/password/reset/',
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ email })
  );

export const passwordResetCheck = (uid, token) =>
  callApi(`auth/password/reset/confirm/${uid}-${token}/`, null, 'GET');

export const passwordResetConfirm = (uid, token, password, password2) =>
  callApi(
    'auth/password/reset/confirm/',
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      decamelizeKeys({
        uid,
        token,
        newPassword1: password,
        newPassword2: password2,
      })
    )
  );

export const passwordChange = (token, data) =>
  callApiWithToken(
    'auth/password/change/',
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const teamInvitation = (authToken, invitationToken, decision) =>
  callApi(
    'team_invitation/',
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        authToken,
        invitationToken,
        decision,
      })
    )
  );

export const passwordlessGetToken = (email) =>
  callApi(
    'auth/link/',
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ email })
  );

export const passwordlessValidateToken = (invitationToken) =>
  callApi(
    'auth/link/validate/',
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys({ invitationToken }))
  );

export const passwordlessLogin = (authToken, invitationToken, expiration, doSignout) =>
  callApi(
    'auth/link/login/',
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        authToken,
        invitationToken,
        expiration,
        doSignout,
      })
    )
  );

export const setLoginExpiration = (token, expiration, doSignout) =>
  callApiWithToken(
    'auth/login/set-expiration/',
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      decamelizeKeys({
        expiration,
        doSignout,
      })
    )
  );
