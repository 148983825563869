import visit from "unist-util-visit";
import { emojiIndex } from "emoji-mart";
import { isUrlValid } from "utils";

const regex = /:\+1:|:-1:|:[\w-]+:/g;

export function emojiParser() {
  function getEmoji(match) {
    const code = match.substring(1, match.length - 1);
    return emojiIndex.emojis[code]?.native || emojiIndex.emojis[code]?.[1]?.native || match;
  }

  function transformer(tree) {
    visit(tree, "text", (node) => {
      if (!isUrlValid(node.value)) {
        node.value = node.value?.replace(regex, getEmoji); // eslint-disable-line no-param-reassign
      }
    });
  }

  return transformer;
}
