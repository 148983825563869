import React from "react";
import PropTypes from "prop-types";
import { Header, Button, Form, TextArea } from "semantic-ui-react";
import ProjectBudget from "components/ProjectBudget";
import config from "config";
import localforage from "localforage";

import { WELCOME_MESSAGE_KEY } from "./constants";
import * as styles from "./styles.module.scss";

/* eslint-disable */
const invitationMessage = (project) => `\
Hi team! I've started a team called "${project}".

Login with your ${config.appName} account to start using the tools for high performance.

Let's get started!
`;
/* eslint-enable */

export default class Review extends React.Component {
  componentDidMount() {
    const { project } = this.props;
    const defaultMessage = invitationMessage(project);

    localforage.getItem(WELCOME_MESSAGE_KEY).then((message) => {
      if (!message) {
        localforage.setItem(WELCOME_MESSAGE_KEY, defaultMessage);
        this.props.onChange(null, {
          name: "message",
          value: defaultMessage,
        });
      } else {
        this.props.onChange(null, {
          name: "message",
          value: message,
        });
      }
    });
  }

  handleChange = (e, { name, value }) => {
    this.props.onChange(null, { name, value });
    localforage.setItem(WELCOME_MESSAGE_KEY, value);
  };

  render() {
    const { message, onBudgetChange, onPrevious, onFinish, timezone } = this.props;

    return (
      <>
        <Header as="h4" content="You are one step away from building your team" />
        <Form>
          <small className={styles.helper}>
            We&rsquo;ve already prepared a templated welcome message, but feel free to edit!
          </small>
          <TextArea
            name="message"
            value={message}
            onChange={this.handleChange}
            style={{ minHeight: 192 }}
          />
        </Form>

        <Header as="h4">
          Add an estimated budget for the project <span className={styles.lighter}>(Optional)</span>
        </Header>
        <ProjectBudget isAdmin hasTotal onUpdate={onBudgetChange} timezone={timezone} />

        <div className={styles.prevNext}>
          <Button type="button" basic floated="left" content="Back" onClick={onPrevious} />
          <Button
            primary
            type="button"
            floated="right"
            content="Continue"
            onClick={onFinish}
            disabled={message.length === 0}
          />
        </div>
      </>
    );
  }
}

Review.propTypes = {
  project: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onBudgetChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onFinish: PropTypes.func,
  timezone: PropTypes.string.isRequired,
};

Review.defaultProps = {
  onPrevious: () => {},
  onFinish: () => {},
};
