import { schema } from "normalizr";

export const skillSchema = new schema.Entity("skills", {}, { idAttribute: "name" });
export const skillSchemaArray = new schema.Array(skillSchema);
skillSchema.define({ descendants: skillSchemaArray });
export const hierarchySchema = new schema.Entity("hierarchy");
export const hierarchySchemaArray = new schema.Array(hierarchySchema);
export const accountSchema = new schema.Entity("accounts");
export const accountCategorySchema = new schema.Entity("accountCategories", {
  accounts: [accountSchema],
});
export const fileSchema = new schema.Entity("files");
export const talentSkillSchema = new schema.Entity("talentSkills");
export const educRecordSchema = new schema.Entity("educRecords");
export const employmentSchema = new schema.Entity("employments");
export const externalProjectSchema = new schema.Entity("externalProjects", {
  skillsUsed: [skillSchema],
});
export const publicationSchema = new schema.Entity("publications");
export const workDetailsSchema = new schema.Entity("workDetails", {}, { idAttribute: "id" });
export const talentTeamsSchema = new schema.Entity("teams", {}, { idAttribute: "id" });
export const teamMembershipSchema = new schema.Entity(
  "teamMemberships",
  {},
  { idAttribute: "membershipId" }
);

export const userSchema = new schema.Entity(
  "users",
  {
    userFiles: [fileSchema],
    talentSkills: [talentSkillSchema],
    educationalAccomplishments: [educRecordSchema],
    employmentHistory: [employmentSchema],
    externalProjects: [externalProjectSchema],
    workDetails: [workDetailsSchema],
    teams: [talentTeamsSchema],
    teamMemberships: [teamMembershipSchema],
    publications: [publicationSchema],
  },
  { idAttribute: "slug" }
);
export const userSchemaArray = new schema.Array(userSchema);
export const talentTeamsSchemaArray = new schema.Array(talentTeamsSchema);

export const eventSchema = new schema.Entity("events", {}, { idAttribute: "id" });
export const eventSchemaArray = new schema.Array(eventSchema);
export const googleCalendarSchema = new schema.Entity("googleCalendars", {}, { idAttribute: "id" });
export const outlookCalendarSchema = new schema.Entity(
  "outlookCalendars",
  {},
  { idAttribute: "id" }
);
export const eventOccurrenceSchema = new schema.Entity(
  "eventOccurrences",
  {
    event: eventSchema,
    rootEvent: eventSchema,
  },
  { idAttribute: "occurrenceId" }
);
export const calendarSchema = new schema.Entity(
  "calendars",
  {
    calendar: {
      google: {
        calendars: [googleCalendarSchema],
      },
      outlook: {
        calendars: [outlookCalendarSchema],
      },
    },
    occurrences: [eventOccurrenceSchema],
  },
  { idAttribute: "slug" }
);
export const calendarSchemaArray = new schema.Array(calendarSchema);

export const linkSchema = new schema.Entity("links", {}, { idAttribute: "id" });
export const projectMembershipSchema = new schema.Entity(
  "projectMemberships",
  {},
  { idAttribute: "id" }
);
export const projectMembershipSchemaArray = [projectMembershipSchema];
export const projectBudgetSchema = new schema.Entity("projectBudget", {}, { idAttribute: "id" });
export const projectBudgetSchemaArray = [projectBudgetSchema];

export const projectSchema = new schema.Entity(
  "projects",
  {
    links: [linkSchema],
    memberships: [projectMembershipSchema],
    scheduledFeedbackUserRecipients: userSchemaArray,
  },
  { idAttribute: "id" }
);
export const projectSchemaArray = [projectSchema];

export const projectSkillSchema = new schema.Entity("projectSkills");
export const workmateSchema = new schema.Entity(
  "workmates",
  {
    softSkills: [projectSkillSchema],
    hardSkills: [projectSkillSchema],
  },
  { idAttribute: "slug" }
);
export const workmateSchemaArray = [workmateSchema];

export const talentFeedback = new schema.Entity("talentFeedback");
export const talentFeedbackArray = [talentFeedback];
export const talentSkillFeedback = new schema.Entity("talentSkillFeedback");
export const talentSkillFeedbackArray = [talentSkillFeedback];
export const talentSkill = new schema.Entity("talentSkill", {
  feedback: talentSkillFeedbackArray,
});
export const talentSkillArray = [talentSkill];
export const commentSchema = new schema.Entity("comments");

export const feedback = new schema.Entity(
  "feedback",
  {
    talentFeedback: talentFeedbackArray,
    talentSkillFeedback: talentSkillArray,
    comments: [commentSchema],
  },
  { idAttribute: "talent" }
);

export const clientFeedback = new schema.Entity("clientFeedback");
export const clientFeedbackArray = [clientFeedback];

export const workmateFeedback = new schema.Entity(
  "workmateFeedback",
  {
    feedback,
  },
  { idAttribute: "slug" }
);
export const workmateFeedbackArray = [workmateFeedback];

export const feedbackRequest = new schema.Entity("feedbackRequest");

export const conjointPair = new schema.Entity("conjointPair", undefined, {
  idAttribute: () => "current",
});
export const conjointFeedback = new schema.Entity("conjointFeedback");

export const ratingsHistory = new schema.Entity("ratingsHistory", undefined, {
  idAttribute: () => "ratings",
});

export const teamRatingsHistory = new schema.Entity("teamRatingsHistory", undefined, {
  idAttribute: () => "ratings",
});

export const budgetSchema = new schema.Entity("budget");
export const budgetSchemaArray = [budgetSchema];
export const relations = new schema.Entity("relations", undefined, {
  idAttribute: () => "relations",
});
export const commonSkill = new schema.Entity("commonSkill", undefined, {
  idAttribute: () => "commonSkill",
});

export const notification = new schema.Entity("notification");
export const notificationArray = [notification];

export const roomMembership = new schema.Entity("roomMembership");

export const reactionSchema = new schema.Entity("reactions");
export const reactionSchemaArray = [reactionSchema];

export const healthFormSchema = new schema.Entity("healthForm");
export const healthFormSchemaArray = new schema.Array(healthFormSchema);
export const healthFormDataSchema = new schema.Entity("healthFormData");
export const healthFormDataSchemaArray = new schema.Array(healthFormDataSchema);
export const healthSummaryAggregatedSchema = new schema.Entity("healthSummaryAggregated");
export const healthPersonalSchema = new schema.Entity("healthPersonal");
export const healthGuideSchema = new schema.Entity("healthGuide");
export const healthGuideSchemaArray = new schema.Array(healthGuideSchema);

export const workLogSchema = new schema.Entity("workLogs", {}, { idAttribute: "workLogId" });
export const workLogsSchema = [workLogSchema];
