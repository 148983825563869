import React from "react";
import { Button } from "semantic-ui-react";
import config from "config";
import styles from "./styles.module.scss";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/facebook/login/`;

const FacebookButton = (props) => (
  <Button
    className={styles.button}
    onClick={() => {
      window.location.href = LOGIN_URL;
    }}
    {...props}
    title="Facebook"
  >
    <img src="/facebook-icon.svg" alt="facebook" />
  </Button>
);

export default FacebookButton;
