import PropTypes from "prop-types";
import React from "react";
import { batch, connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  deleteMessage,
  getMessages,
  getPinnedMessages,
  readMessages,
  resetJump,
} from "actions/chat";
import { getCurrentRoom, getCurrentRoomId } from "reducers/selectors";

import Attachment from "containers/Attachment";
import MessageContainer from "./MessageContainer";

import * as workspaceStyles from "../Workspace/styles.module.scss";

class Chat extends React.Component {
  componentDidMount() {
    this.loadMessages();
    this._scrollToMessage();
  }

  componentDidUpdate(prevProps) {
    const { jump } = this.props.match.params;
    const { jump: prevJump } = prevProps.match.params;

    if (jump !== prevJump) {
      this.loadMessages();
    }
    this._scrollToMessage();
  }

  _scrollToMessage = () => {
    const { jump } = this.props.match.params;
    if (jump) {
      // scroll the loaded message into view
      const element = document.getElementsByClassName(`message-${jump}`)[0];
      if (element) {
        element.scrollIntoView();
      } else {
        document.body.scrollTop = document.body.scrollHeight;
        document.documentElement.scrollTop = document.body.scrollHeight;
      }
    }
  };

  loadMessages = () => {
    const { currentRoom, hasLoadedMessages, isLoadingMessages, token } = this.props;

    const { jump } = this.props.match.params;
    const processedJump = jump ? parseInt(jump, 10) : null;

    batch(() => {
      if (processedJump) {
        this.props.getMessages({ token, id: currentRoom.id, jump: processedJump });
      } else {
        this.props.resetJump({ id: currentRoom.id });

        if (hasLoadedMessages) return;
        if (isLoadingMessages) return;

        this.props.getMessages({ token, id: currentRoom.id });
        this.props.getPinnedMessagesRequest({ id: currentRoom.id, token });
      }
    });
  };

  onLoadPreviousMessages = () => {
    const { currentRoomId, nextItemsUrl, isLoadingMessages, token } = this.props;
    if (!nextItemsUrl) return;
    if (isLoadingMessages) return;

    this.props.getMessages({ token, id: currentRoomId, url: nextItemsUrl });
  };

  onMessageRead = (roomId) => {
    const { token } = this.props;

    this.props.readMessages({ token, id: roomId });
  };

  render() {
    const {
      currentRoom,
      currentUser,
      deleteMessageRequest,
      isLoadingMessages,
      lastMessage,
      messages,
      messageOrdering,
      onMessageOpenThread,
      unreadMessagesCount,
      users,
    } = this.props;

    return (
      <Attachment className={workspaceStyles.messageContainer} cacheId={`room-${currentRoom.id}`}>
        {({ cacheId, dropzoneOnPaste, dropzoneOpen }) => (
          <MessageContainer
            cacheId={cacheId}
            currentUser={currentUser}
            deleteMessageRequest={deleteMessageRequest}
            dropzoneOpen={dropzoneOpen}
            dropzoneOnPaste={dropzoneOnPaste}
            isLoadingMessages={isLoadingMessages}
            lastMessage={lastMessage}
            messages={messages}
            messageOrdering={messageOrdering}
            onLoadPreviousMessages={this.onLoadPreviousMessages}
            onMessageOpenThread={onMessageOpenThread}
            onMessageRead={this.onMessageRead}
            room={currentRoom}
            roomUsers={currentRoom.users}
            unreadMessagesCount={unreadMessagesCount}
            users={users}
          />
        )}
      </Attachment>
    );
  }
}

Chat.propTypes = {
  currentRoom: PropTypes.shape(),
  currentRoomId: PropTypes.number,
  currentUser: PropTypes.shape(),
  deleteMessageRequest: PropTypes.func.isRequired,
  getMessages: PropTypes.func.isRequired,
  getPinnedMessagesRequest: PropTypes.func.isRequired,
  nextItemsUrl: PropTypes.string,
  hasLoadedMessages: PropTypes.bool.isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
  lastMessage: PropTypes.shape(),
  match: PropTypes.shape({
    params: PropTypes.shape({
      jump: PropTypes.string,
    }).isRequired,
  }).isRequired,
  messages: PropTypes.shape(),
  messageOrdering: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  readMessages: PropTypes.func.isRequired,
  resetJump: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  unreadMessagesCount: PropTypes.number.isRequired,
  users: PropTypes.shape().isRequired,
  onMessageOpenThread: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { token, user: currentUser },
    entities: { users },
    messages: {
      nextItemsUrls,
      initialLoad: messagesInitialLoad,
      items: messagesByRooms,
      loading: messagesLoading,
      ordering: messageOrderingByRooms,
      unreadMessagesCountByRoom,
    },
  } = state;

  const currentRoom = getCurrentRoom(state);
  const currentRoomId = getCurrentRoomId(state);

  const unreadMessagesCount = currentRoomId ? unreadMessagesCountByRoom[currentRoomId] || 0 : 0;
  const nextItemsUrl = nextItemsUrls[currentRoomId] || null;
  const hasLoadedMessages = !!messagesInitialLoad[currentRoomId];
  const isLoadingMessages = !!messagesLoading[currentRoomId];
  const messageOrdering = messageOrderingByRooms[currentRoomId] || [];
  const messages = messagesByRooms[currentRoomId] || {};
  const lastMessageId = messageOrdering
    .slice()
    .reverse()
    .find(
      (messageId) =>
        !messages[messageId].isDeleted &&
        !messages[messageId].poll &&
        messages[messageId].author === currentUser.slug
    );
  const lastMessage = lastMessageId ? messages[lastMessageId] : null;

  return {
    currentRoom,
    currentRoomId,
    currentUser,
    nextItemsUrl,
    hasLoadedMessages,
    isLoadingMessages,
    lastMessage,
    messages,
    messageOrdering,
    token,
    unreadMessagesCount,
    users,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    deleteMessageRequest: deleteMessage.request,
    getMessages: getMessages.request,
    getPinnedMessagesRequest: getPinnedMessages.request,
    resetJump,
    readMessages: readMessages.request,
  })(Chat)
);
