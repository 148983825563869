import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "semantic-ui-react";

import { DAILY, WEEKLY, MONTHLY, YEARLY } from "./constants";
import styles from "./styles.module.scss";

const repeatOptions = {
  daily: {
    modalPluralId: "RecurrenceInput.modalId.dailyPlural",
    modalPluralMessage: "days",
    modalSingularId: "RecurrenceInput.modalId.dailySingular",
    modalSingularMessage: "day",
    value: DAILY,
  },
  weekly: {
    modalPluralId: "RecurrenceInput.modalId.weeklyPlural",
    modalPluralMessage: "weeks",
    modalSingularId: "RecurrenceInput.modalId.weeklySingular",
    modalSingularMessage: "week",
    value: WEEKLY,
  },
  monthly: {
    modalPluralId: "RecurrenceInput.modalId.monthlyPlural",
    modalPluralMessage: "months",
    modalSingularId: "RecurrenceInput.modalId.monthlySingular",
    modalSingularMessage: "month",
    value: MONTHLY,
  },
  yearly: {
    modalPluralId: "RecurrenceInput.modalId.yearlyPlural",
    modalPluralMessage: "years",
    modalSingularId: "RecurrenceInput.modalId.yearlySingular",
    modalSingularMessage: "year",
    value: YEARLY,
  },
};

export default class RecurrenceCustomInterval extends React.PureComponent {
  getRepeatOptions = (interval) =>
    Object.keys(repeatOptions).map((key) => ({
      key,
      value: repeatOptions[key].value,
      text: (
        <FormattedMessage
          defaultMessage={
            interval > 1
              ? repeatOptions[key].modalPluralMessage
              : repeatOptions[key].modalSingularMessage
          }
          id={interval > 1 ? repeatOptions[key].modalPluralId : repeatOptions[key].modalSingularId}
        />
      ),
    }));

  onChange = (e, { name, value }) => {
    const { onChange } = this.props;
    if (name === "interval" && value < 1) {
      onChange(e, { name, value: 1 });
    } else {
      onChange(e, { name, value });
    }
  };

  render() {
    const { interval, frequency, disabled } = this.props;
    const intInterval = interval ? parseInt(interval, 10) : 1;
    const options = this.getRepeatOptions(intInterval);

    return (
      <div className={classnames(styles.customModalSection, styles.customModalInterval)}>
        <div className={styles.customModalIntervalLabel}>
          <FormattedMessage defaultMessage="Repeat every" id="RecurrenceInput.modalId.interval" />
        </div>
        <div>
          <Form.Input
            min="1"
            name="interval"
            onChange={this.onChange}
            type="number"
            value={intInterval}
            disabled={disabled}
          />
        </div>
        <div>
          <Form.Select
            name="frequency"
            onChange={this.onChange}
            options={options}
            value={frequency}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

RecurrenceCustomInterval.propTypes = {
  interval: PropTypes.string,
  frequency: PropTypes.string.isRequired,
  disabled: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
};
