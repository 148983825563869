import classnames from "classnames";
import React, { Component } from "react";
import { Emoji } from "emoji-mart";
import PropTypes from "prop-types";
import { NavLink as Link } from "react-router-dom";
import { Button, Dimmer, Feed, Loader, Responsive, Icon } from "semantic-ui-react";

import config from "config";
import UserAvatar from "components/UserAvatar";

import * as styles from "./styles.module.scss";

const DISPLAY_USER_SETTINGS = {
  "requested feedback from": {
    displayActor: true,
    avatar: "actor",
  },
  "created a poll": {
    displayActor: false,
    avatar: "actor",
  },
  "invited you in a project": {
    displayActor: true,
    avatar: "actor",
  },
  "level up congratulations": {
    displayActor: false,
    avatar: "target",
  },
  "invited you to form a team": {
    displayActor: true,
    avatar: "actor",
  },
  "gave skill feedback to": {
    displayActor: false,
    avatar: "target",
  },
  "said thank you to": {
    displayActor: false,
    avatar: "target",
  },
  "invited you to a meeting": {
    displayActor: true,
    avatar: "actor",
  },
};

const getAvatar = (obj) => {
  const displaySettings = DISPLAY_USER_SETTINGS[obj.verb];
  let avatarSource = "actor";
  if (displaySettings) {
    avatarSource = displaySettings.avatar;
  }

  if (obj.verb === "created a poll") {
    return (
      <div className={styles.pollAvatar}>
        <Icon circular inverted color="violet" name="chart bar" />
      </div>
    );
  }

  const item = obj[avatarSource] || {};
  return (
    <Feed.Label>
      <UserAvatar
        avatar={item.avatarOrDefault}
        displayName={item.displayName}
        round
        size="35"
        bgColor={item.avatarBgcolor}
        fgColor={item.avatarColor}
      />
    </Feed.Label>
  );
};

class NotificationList extends Component {
  generateNotificationContent = (obj) => {
    let displayName = "";
    const target = obj.target || { displayName: `A ${config.appName} User` };
    const actionObject = obj.actionObject || {};
    const actor = obj.actor || { displayName: `A ${config.appName} User` };
    switch (obj.verb) {
      case "requested feedback from":
        displayName =
          this.props.userDisplayName === target.displayName ? "you" : target.displayName;
        return `${obj.verb} ${displayName}.`;
      case "created a poll":
        return (
          <React.Fragment>
            <Button
              as="span"
              onClick={() => this.props.handleItemClick(obj)}
              className={styles.linkButton}
            >
              {actionObject.question}
            </Button>{" "}
            poll is running.
          </React.Fragment>
        );
      case "invited you in a project":
        return (
          <React.Fragment>
            {config.flags.profile.teams ? obj.verb : "invited you to form a team"}{" "}
            <Button
              as="span"
              onClick={() => this.props.handleItemClick(obj)}
              className={styles.linkButton}
            >
              {target.name}
            </Button>
          </React.Fragment>
        );
      case "level up congratulations":
        return (
          <React.Fragment>
            Congrats! You&apos;re now {actionObject.peerSkillRatingName} in{" "}
            <Button
              as="span"
              onClick={() => this.props.handleItemClick(obj)}
              className={styles.linkButton}
            >
              {actionObject.skillName}
            </Button>
          </React.Fragment>
        );
      case "invited you to form a team":
        return `${obj.verb}.`;
      case "gave skill feedback to":
        return (
          <React.Fragment>
            {this.props.userDisplayName === actor.displayName ? (
              "you"
            ) : (
              <Link to={`/talents/${actor.slug}`} target="_blank" title="View profile">
                <strong>{actor.displayName}</strong>
              </Link>
            )}{" "}
            {obj.verb}{" "}
            <Link to={`/talents/${target.slug}`} target="_blank" title="View profile">
              <strong>{target.displayName}</strong>
            </Link>
            .
          </React.Fragment>
        );
      case `said thank you to`:
        return (
          <React.Fragment>
            {this.props.userDisplayName === actor.displayName ? (
              "you"
            ) : (
              <Link to={`/talents/${actor.slug}`} target="_blank" title="View profile">
                <strong>{actor.displayName}</strong>
              </Link>
            )}{" "}
            {obj.verb}{" "}
            <Link to={`/talents/${target.slug}`} target="_blank" title="View profile">
              <strong>{target.displayName}</strong>
            </Link>
            .
          </React.Fragment>
        );
      case "invited you to a meeting":
        return (
          <React.Fragment>
            {obj.verb}{" "}
            <Button
              as="span"
              onClick={() => this.props.handleItemClick(obj)}
              className={styles.linkButton}
            >
              {target.title}
            </Button>
          </React.Fragment>
        );
      default:
        return `${obj.verb}.`;
    }
  };

  createItem = (obj) => {
    const actor = obj.actor || { displayName: `A ${config.appName} User` };
    return (
      <Feed.Event
        key={obj.id.toString()}
        className={classnames(styles.notifEvent, !!obj.unread && styles.unreadEvent)}
      >
        {obj.emoji && (
          <Feed.Extra className={styles.feedEmoji}>
            {obj.emoji && <Emoji native emoji={obj.emoji} size={16} />}
          </Feed.Extra>
        )}
        {getAvatar(obj)}
        <Feed.Content className={styles.notifContent}>
          <Feed.Summary>
            <Responsive
              as={Feed.Date}
              minWidth={813}
              className={styles.feedDate}
              title={obj.timestampFullDisplay}
            >
              {obj.timestampDisplay}
            </Responsive>
            {DISPLAY_USER_SETTINGS[obj.verb] && DISPLAY_USER_SETTINGS[obj.verb].displayActor && (
              <React.Fragment>
                <Feed.User>
                  <Link to={`/talents/${actor.slug}`} target="_blank" title="View profile">
                    <strong>{actor.displayName}</strong>
                  </Link>
                </Feed.User>{" "}
              </React.Fragment>
            )}
            {this.generateNotificationContent(obj)}
            {obj.itemLink ? (
              <Feed.Extra
                text
                onClick={() => this.props.handleItemClick(obj)}
                className={styles.eventLink}
              >
                {obj.displayLink}
              </Feed.Extra>
            ) : (
              <Feed.Extra className={styles.emptyLink} />
            )}
            <Responsive as={Feed.Date} maxWidth={812} className={styles.feedDate}>
              {obj.timestampDisplay}
            </Responsive>
          </Feed.Summary>
        </Feed.Content>
      </Feed.Event>
    );
  };

  render() {
    const { notifications, loading } = this.props;

    if (Object.keys(notifications).length <= 0 && !loading) {
      return <div className={styles.noNotifs}>There are no available notifications.</div>;
    }

    return (
      <div>
        <Dimmer.Dimmable as={Feed}>
          <Dimmer active={loading} inverted>
            <Loader inverted />
          </Dimmer>
          {notifications.map(this.createItem)}
        </Dimmer.Dimmable>
      </div>
    );
  }
}

NotificationList.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape()),
  userDisplayName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default NotificationList;
