import { createRequestTypes, action } from ".";

export const AUTH_LOAD_TOKEN = "AUTH_LOAD_TOKEN";
export const AUTH_LOGIN_INIT = "AUTH_LOGIN_INIT";
export const AUTH_LOGIN = createRequestTypes("AUTH_LOGIN");
export const AUTH_LOGOUT_INIT = "AUTH_LOGOUT_INIT";
export const AUTH_LOGOUT = createRequestTypes("AUTH_LOGOUT");
export const AUTH_REGISTER_INIT = "AUTH_REGISTER_INIT";
export const AUTH_REGISTER = createRequestTypes("AUTH_REGISTER");
export const AUTH_PASSWORD_RESET = createRequestTypes("AUTH_PASSWORD_RESET");
export const AUTH_PASSWORD_RESET_CHECK = createRequestTypes("AUTH_PASSWORD_RESET_CHECK");
export const AUTH_PASSWORD_RESET_CONFIRM = createRequestTypes("AUTH_PASSWORD_RESET_CONFIRM");
export const AUTH_PASSWORD_CHANGE = createRequestTypes("AUTH_PASSWORD_CHANGE");
export const TEAM_INVITATION = createRequestTypes("TEAM_INVITATION");
export const PASSWORDLESS_GET_TOKEN = createRequestTypes("PASSWORDLESS_GET_TOKEN");
export const PASSWORDLESS_VALIDATE_TOKEN = createRequestTypes("PASSWORDLESS_VALIDATE_TOKEN");
export const PASSWORDLESS_LOGIN = createRequestTypes("PASSWORDLESS_LOGIN");
export const LOGIN_MODAL = createRequestTypes("LOGIN_MODAL");
export const LOGIN_MODAL_OPEN = "LOGIN_MODAL_OPEN";
export const LOGIN_MODAL_CLOSE = "LOGIN_MODAL_CLOSE";
export const LOGIN_MODAL_SET_EXPIRATION = createRequestTypes("LOGIN_MODAL_SET_EXPIRATION");

export const loadToken = () => action(AUTH_LOAD_TOKEN);

export const login = {
  request: () => action(AUTH_LOGIN.REQUEST),
  success: (response) => action(AUTH_LOGIN.SUCCESS, { response }),
  failure: (error, errorDetails) => action(AUTH_LOGIN.FAILURE, { error, errorDetails }),
};

export const loginModal = {
  success: (response) => action(AUTH_LOGIN.SUCCESS, { response }),
  failure: (error) => action(AUTH_LOGIN.FAILURE, { error }),
  open: () => action(LOGIN_MODAL_OPEN),
  close: () => action(LOGIN_MODAL_CLOSE),
  setExpiration: ({ expiration, doSignout }) =>
    action(LOGIN_MODAL_SET_EXPIRATION.REQUEST, { expiration, doSignout }),
  setExpirationSuccess: (response) => action(LOGIN_MODAL_SET_EXPIRATION.SUCCESS, { response }),
  setExpirationFailure: ({ error, errorDetails }) =>
    action(LOGIN_MODAL_SET_EXPIRATION.FAILURE, { error, errorDetails }),
};

export const logout = {
  request: () => action(AUTH_LOGOUT.REQUEST),
  success: (token, userObj) => action(AUTH_LOGOUT.SUCCESS, { token, user: userObj }),
  failure: (error) => action(AUTH_LOGOUT.FAILURE, { error }),
};

export const register = {
  request: () => action(AUTH_REGISTER.REQUEST),
  success: (response) => action(AUTH_REGISTER.SUCCESS, { response }),
  failure: (error, errorDetails) => action(AUTH_REGISTER.FAILURE, { error, errorDetails }),
};

export const loginInit = (credentials, token, next, extraData) =>
  action(AUTH_LOGIN_INIT, { credentials, next, token, extraData });
export const logoutInit = () => action(AUTH_LOGOUT_INIT);
export const registerInit = (data) => action(AUTH_REGISTER_INIT, { data });

export const passwordReset = {
  init: (email) => action(AUTH_PASSWORD_RESET.INIT, { email }),
  request: () => action(AUTH_PASSWORD_RESET.REQUEST),
  success: (response) => action(AUTH_PASSWORD_RESET.SUCCESS, { response }),
  failure: (error, errorDetails) => action(AUTH_PASSWORD_RESET.FAILURE, { error, errorDetails }),
};

export const passwordResetCheck = {
  init: (uid, token) => action(AUTH_PASSWORD_RESET_CHECK.INIT, { uid, token }),
  request: () => action(AUTH_PASSWORD_RESET_CHECK.REQUEST),
  success: (response) => action(AUTH_PASSWORD_RESET_CHECK.SUCCESS, { response }),
  failure: (error, errorDetails) =>
    action(AUTH_PASSWORD_RESET_CHECK.FAILURE, { error, errorDetails }),
};

export const passwordResetConfirm = {
  init: (uid, token, password, password2) =>
    action(AUTH_PASSWORD_RESET_CONFIRM.INIT, { uid, token, password, password2 }),
  request: () => action(AUTH_PASSWORD_RESET_CONFIRM.REQUEST),
  success: (response) => action(AUTH_PASSWORD_RESET_CONFIRM.SUCCESS, { response }),
  failure: (error, errorDetails) =>
    action(AUTH_PASSWORD_RESET_CONFIRM.FAILURE, { error, errorDetails }),
};

export const passwordChange = {
  init: (oldPassword, newPassword1, newPassword2) =>
    action(AUTH_PASSWORD_CHANGE.INIT, { oldPassword, newPassword1, newPassword2 }),
  request: () => action(AUTH_PASSWORD_CHANGE.REQUEST),
  success: (response) => action(AUTH_PASSWORD_CHANGE.SUCCESS, { response }),
  failure: (errorDetails) => action(AUTH_PASSWORD_CHANGE.FAILURE, { errorDetails }),
};

export const teamInvitation = {
  init: (authToken, invitationToken, decision) =>
    action(TEAM_INVITATION.INIT, { authToken, invitationToken, decision }),
  request: () => action(TEAM_INVITATION.REQUEST),
  success: (response) => action(TEAM_INVITATION.SUCCESS, { response }),
  failure: (error) => action(TEAM_INVITATION.FAILURE, { error }),
};

export const passwordlessGetToken = {
  request: (email, resolve, reject) =>
    action(PASSWORDLESS_GET_TOKEN.REQUEST, { email, resolve, reject }),
  success: (response) => action(PASSWORDLESS_GET_TOKEN.SUCCESS, { response }),
  failure: (error) => action(PASSWORDLESS_GET_TOKEN.FAILURE, { error }),
};

export const passwordlessValidateToken = {
  request: (invitationToken, resolve, reject) =>
    action(PASSWORDLESS_VALIDATE_TOKEN.REQUEST, { invitationToken, resolve, reject }),
  success: (response) => action(PASSWORDLESS_VALIDATE_TOKEN.SUCCESS, { response }),
  failure: (error) => action(PASSWORDLESS_VALIDATE_TOKEN.FAILURE, { error }),
};

export const passwordlessLogin = {
  request: (authToken, invitationToken, expiration, doSignout) =>
    action(PASSWORDLESS_LOGIN.REQUEST, { authToken, invitationToken, expiration, doSignout }),
  success: (response) => action(PASSWORDLESS_LOGIN.SUCCESS, { response }),
  failure: (error) => action(PASSWORDLESS_LOGIN.FAILURE, { error }),
};
