import { call, put, select, take } from "redux-saga/effects";
import * as chat from "actions/chat";
import * as socket from "actions/socket";
import { getCurrentRoomId } from "reducers/selectors";
import { token as tokenService } from "services";

export function* watchSocket() {
  while (true) {
    yield take(socket.SOCKET.OFFLINE);
    /* eslint-disable-next-line no-console */
    console.log("Websocket offline!");

    yield take(socket.SOCKET.ONLINE);
    /* eslint-disable-next-line no-console */
    console.log(`Websocket online`);

    // Update the current room.
    // Other rooms will update when they are reopened.
    const token = yield call(tokenService.getAuthToken);
    const currentRoomId = yield select(getCurrentRoomId);
    if (token && currentRoomId) {
      yield put(chat.getRoom.request({ id: currentRoomId, token }));
      yield put(chat.getMessages.request({ id: currentRoomId, token }));
      yield put(chat.pollList.request({ token, isArchived: false, roomId: currentRoomId }));
      yield put(chat.roomMeetingList.request({ roomId: currentRoomId }));
    }
  }
}
