import { call, takeLatest, put } from "redux-saga/effects";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { api, token as tokenService } from "services";
import * as feedback from "actions/clientFeedback";

import { fetchEntityQS } from "./base";

const fetchClientFeedbacks = fetchEntityQS.bind(
  null,
  feedback.clientFeedback,
  api.fetchClientFeedbacks
);

export function* loadClientFeedbacks({ project }) {
  const token = yield tokenService.getAuthToken();
  yield put(showLoading());
  yield call(fetchClientFeedbacks, true, token, null, `?project=${project}`);
  yield put(hideLoading());
}

export function* watchClientFeedback() {
  yield takeLatest(feedback.CLIENT_FEEDBACK.INIT, loadClientFeedbacks);
}
