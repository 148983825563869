import { connect } from "react-redux";
import { navigate } from "actions";
import { loginInit } from "actions/account";
import AuthenticatedContainer from "containers/AuthenticatedContainer";
import UnauthenticatedContainer from "containers/UnauthenticatedContainer";

export function requireAuthentication(Component) {
  const mapStateToProps = (state) => ({
    component: Component,
    isAuthenticated: state.isAuthenticated,
  });

  const dispatchProps = {
    navigate,
    loginInit,
  };

  return connect(mapStateToProps, dispatchProps)(AuthenticatedContainer);
}

export function requireUnauthenticated(Component) {
  const mapStateToProps = (state) => ({
    component: Component,
    isAuthenticated: state.isAuthenticated,
  });

  const dispatchProps = {
    navigate,
  };

  return connect(mapStateToProps, dispatchProps)(UnauthenticatedContainer);
}
