import localForage from "localforage";

export const AUTH_TOKEN_KEY = "authToken";

// Side effects Services

export function getSessionAuthToken() {
  return sessionStorage.getItem(AUTH_TOKEN_KEY);
}

export function setSessionAuthToken(token) {
  return sessionStorage.setItem(AUTH_TOKEN_KEY, token);
}

export function removeSessionAuthToken() {
  return sessionStorage.removeItem(AUTH_TOKEN_KEY);
}

export function getAuthToken() {
  return localForage.getItem(AUTH_TOKEN_KEY);
}

export function setAuthToken(token) {
  setSessionAuthToken(token);
  return localForage.setItem(AUTH_TOKEN_KEY, token);
}

export function removeAuthToken() {
  removeSessionAuthToken();
  return localForage.removeItem(AUTH_TOKEN_KEY);
}
