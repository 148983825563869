import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";

import { dateString, dateTimeString } from "types";

import DateTime from "./DateTime";

const defaultTick = 1000;

export default class Countdown extends React.PureComponent {
  componentDidMount() {
    this.ongoing = true;
    this.interval = setInterval(this.onTick, this.props.tick);
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  onTick = () => {
    const { dateTime, onExpire } = this.props;

    if (!this.hasExpired()) {
      this.ongoing = true;
    } else if (this.ongoing) {
      this.ongoing = false;
      if (dateTime) onExpire();
    }

    this.forceUpdate();
  };

  clearInterval() {
    clearInterval(this.interval);
    delete this.interval;
  }

  hasExpired() {
    const { dateTime } = this.props;
    if (!dateTime) return true;

    return moment(dateTime).isSameOrBefore(moment());
  }

  render() {
    const {
      className,
      dateTime,
      noDateTimeMessage,
      prefixExpired,
      prefixOngoing,
      suffixExpired,
      suffixOngoing,
    } = this.props;
    if (!dateTime) {
      return noDateTimeMessage ? <span className={className}>{noDateTimeMessage}</span> : null;
    }

    return (
      <DateTime
        className={className}
        dateTime={dateTime}
        prefixExpired={prefixExpired}
        prefixOngoing={prefixOngoing}
        suffixExpired={suffixExpired}
        suffixOngoing={suffixOngoing}
      />
    );
  }
}

Countdown.propTypes = {
  className: PropTypes.string,
  dateTime: PropTypes.oneOfType([dateString, dateTimeString]),
  noDateTimeMessage: PropTypes.string,
  prefixExpired: PropTypes.string,
  prefixOngoing: PropTypes.string,
  suffixExpired: PropTypes.string,
  suffixOngoing: PropTypes.string,
  tick: PropTypes.number,

  onExpire: PropTypes.func,
};

Countdown.defaultProps = {
  className: null,
  dateTime: null,
  prefixExpired: "ended",
  prefixOngoing: "ends in",
  suffixExpired: "ago",
  suffixOngoing: null,
  noDateTimeMessage: null,
  tick: defaultTick,

  onExpire: () => {},
};
