import PropTypes from "prop-types";
import React from "react";

import MessageListItemEventGuestItem from "./MessageListItemEventGuestItem";
import styles from "./styles.module.scss";

export default class MessageListItemEventGuestList extends React.PureComponent {
  render() {
    const { invitations: invitationsProp, roomUsers, showAllGuests, totalCount } = this.props;
    const invitations = invitationsProp.filter((i) => i);
    const activeList = [];
    const declinedList = [];
    const pendingList = [];

    const invitedMembers = (invitations || []).filter((i) => !i.email && !!i.user);
    invitedMembers.forEach((invitation) => {
      const { status, user: userSlug, isOptional: optional } = invitation;
      const user = roomUsers.find((u) => u.slug === userSlug);
      if (status === "accepted") {
        activeList.push({ user, optional });
      } else if (status === "declined") {
        declinedList.push({ user, optional });
      } else {
        pendingList.push({ user, optional });
      }
    });

    const guestList = (invitations || [])
      .filter((i) => i.status === "pending" && !!i.email && !i.user)
      .map((invitation) => ({
        user: { email: invitation.email },
        optional: invitation.isOptional,
      }));

    const labels = [];
    const pendingCount = totalCount - activeList.length - declinedList.length;
    if (activeList.length > 0) labels.push(`${activeList.length} yes`);
    if (declinedList.length > 0) labels.push(`${declinedList.length} no`);
    if (pendingCount > 0) labels.push(`${pendingCount} awaiting`);

    if (totalCount <= 5 || showAllGuests) {
      return (
        <>
          <div className={styles.messageListItemEventGuestListShort}>{labels.join(", ")}</div>
          <div className={styles.messageListItemEventGuestList}>
            {activeList.map((invitation, ind) => (
              <MessageListItemEventGuestItem
                key={`a${ind}`}
                user={invitation.user}
                optional={invitation.optional}
                accepted
              />
            ))}
            {declinedList.map((invitation, ind) => (
              <MessageListItemEventGuestItem
                key={`d${ind}`}
                user={invitation.user}
                optional={invitation.optional}
                declined
              />
            ))}
            {pendingList.map((invitation, ind) => (
              <MessageListItemEventGuestItem
                key={`p${ind}`}
                user={invitation.user}
                optional={invitation.optional}
              />
            ))}
            {guestList.length > 0 && (
              <div className={styles.messageListItemEventExternalGuestsHeader}>External guests</div>
            )}
            {guestList.map((invitation, ind) => (
              <MessageListItemEventGuestItem
                key={`e${ind}`}
                user={invitation.user}
                optional={invitation.optional}
              />
            ))}
          </div>
        </>
      );
    }
    return <div className={styles.messageListItemEventGuestListShort}>{labels.join(", ")}</div>;
  }
}

MessageListItemEventGuestList.propTypes = {
  invitations: PropTypes.arrayOf(PropTypes.shape()),
  roomUsers: PropTypes.arrayOf(PropTypes.shape()),
  showAllGuests: PropTypes.bool,
  totalCount: PropTypes.number,
};
