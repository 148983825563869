import { action } from ".";

export const SOCKET = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
};

export const socket = {
  online: () => action(SOCKET.ONLINE),
  offline: () => action(SOCKET.OFFLINE),
};
