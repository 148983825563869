import PropTypes from "prop-types";
import React, { Component } from "react";
import Loader from "react-loader";
import { Redirect } from "react-router-dom";
import { token as tokenService } from "services";

export default class UnauthenticatedContainer extends Component {
  constructor(props) {
    super(props);

    this.checkAuth = this.checkAuth.bind(this);

    this.state = {
      token: null,
      loaded: false,
    };
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth() {
    tokenService.getAuthToken().then((token) => {
      this.setState({
        token,
        loaded: true,
      });
    });
  }

  render() {
    const { loaded, token } = this.state;

    if (loaded && token) {
      return <Redirect to="/" />;
    }

    if (!this.props.isAuthenticated || !this.state.token) {
      return <this.props.component {...this.props} />;
    }

    return <Loader />;
  }
}

UnauthenticatedContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
};
