import {
  NEW_MESSAGE_MODAL,
  NEW_PROJECT_MODAL,
  NEW_TEAM_MODAL,
  SHORTCUTS_HELP_MODAL,
  DOWNLOAD_LINK_MODAL,
} from "actions/modals";

export default function modals(
  state = {
    showNewMessageModal: false,
    showNewProjectModal: false,
    showShortcutsHelpModal: false,
    showNewTeamModal: false,
    showDownloadLinkModal: false,
  },
  action
) {
  const { type } = action;
  switch (type) {
    case NEW_MESSAGE_MODAL.SHOW:
      return { ...state, showNewMessageModal: true };
    case NEW_MESSAGE_MODAL.HIDE:
      return { ...state, showNewMessageModal: false };
    case NEW_MESSAGE_MODAL.TOGGLE:
      return { ...state, showNewMessageModal: !state.showNewMessageModal };
    case NEW_PROJECT_MODAL.SHOW:
      return { ...state, showNewProjectModal: true };
    case NEW_PROJECT_MODAL.HIDE:
      return { ...state, showNewProjectModal: false };
    case NEW_PROJECT_MODAL.TOGGLE:
      return { ...state, showNewProjectModal: !state.showNewProjectModal };
    case SHORTCUTS_HELP_MODAL.SHOW:
      return { ...state, showShortcutsHelpModal: true };
    case SHORTCUTS_HELP_MODAL.HIDE:
      return { ...state, showShortcutsHelpModal: false };
    case SHORTCUTS_HELP_MODAL.TOGGLE:
      return { ...state, showShortcutsHelpModal: !state.showShortcutsHelpModal };
    case NEW_TEAM_MODAL.SHOW:
      return { ...state, showNewTeamModal: true };
    case NEW_TEAM_MODAL.HIDE:
      return { ...state, showNewTeamModal: false };
    case NEW_TEAM_MODAL.TOGGLE:
      return { ...state, showNewTeamModal: !state.showNewTeamModal };
    case DOWNLOAD_LINK_MODAL.SHOW:
      return { ...state, showDownloadLinkModal: true };
    case DOWNLOAD_LINK_MODAL.HIDE:
      return { ...state, showDownloadLinkModal: false };
    case DOWNLOAD_LINK_MODAL.TOGGLE:
      return { ...state, showDownloadLinkModal: !state.showDownloadLinkModal };
    default:
      return state;
  }
}
