import localforage from "localforage";
import { createTransform, persistReducer } from "redux-persist";
import { v4 as uuidv4 } from "uuid";
import {
  difference,
  flatten,
  isArray,
  isEmpty,
  isEqual,
  keyBy,
  mapKeys,
  mergeWith,
  omit,
  union,
  uniqWith,
} from "lodash";
import {
  CALENDAR,
  CLEAN_EVENT_OCCURRENCES,
  CLEAN_GOOGLE_EVENT_OCCURRENCES,
  CLEAN_OUTLOOK_EVENT_OCCURRENCES,
  EVENT_OCCURRENCE_DETAIL,
  RESET_EVENT_OCCURRENCES,
} from "actions/calendar";
import {
  ADD_MEMBERS,
  GET_ROOM,
  GET_ROOMS,
  INVITATION_DELETE,
  ROOM_MEETING_DETAIL,
  ROOM_MEETING_JOIN,
  REACTIONS_ADD,
  REACTIONS_DELETE,
} from "actions/chat";
import { NOTIFICATION, PAGINATED_NOTIFICATION } from "actions/notification";
import {
  CHANNEL_CREATE_OR_UPDATE,
  CHANNEL_DELETE,
  PROJECT_DELETE,
  INVITATION_ADD,
  LINKS_DELETE,
  PROJECT,
  PROJECT_INVITATIONS_DETAIL,
  PROJECT_MEMBERSHIPS_DELETE,
  PROJECT_MEMBERSHIPS_DETAIL,
  PROJECT_INVITATIONS_UPDATE,
  PROJECT_DETAIL,
  PROJECT_UPDATE,
  PROJECT_BUDGET_UPDATE,
} from "actions/project";
import { ADD_REMINDERS, REMOVE_REMINDER } from "actions/reminders";
import {
  CREATE_WEEKLY_ASSIGNMENT,
  GET_WEEKLY_ASSIGNMENTS,
  UPDATE_WEEKLY_ASSIGNMENT,
} from "actions/schedule";
import { ADD_CLIENT_FEEDBACK } from "actions/clientFeedback";
import {
  BULK_PHONE_NUMBERS_UPDATE,
  BULK_SKILLS_UPDATE,
  BULK_TEAM_SKILLS_UPDATE,
  DELETE_TEAM,
  DELETE_USER,
  SET_USER_PRESENCE,
  TEAM_INVITATION_DETAIL,
  TEAM_MEMBERSHIPS_DETAIL,
  UPDATE_USER,
  UPDATE_TEAM,
  USER_DETAIL,
  TEAM_DETAIL,
  USER,
} from "actions/user";
import { HEALTH_FORM_DATA, HEALTH_FORM_DATA_UPDATE } from "actions/health";
import { RESET_WORK_LOGS } from "actions/timetracker";
import { GET_BUDGET_TRACKER } from "actions/budget";
import { normalize } from "normalizr";
import * as schema from "services/schema";
import { status } from "utils/constants";

const projectInitialState = {
  invitations: [],
  links: [],
  memberships: [],
  roomIds: [],
  budget: [],
};

export function customizer(objValue, srcValue, key) {
  if (isArray(objValue)) {
    return uniqWith(flatten([objValue, srcValue]), isEqual);
  }
  return undefined;
}

export function mergeUser(response, currentState) {
  return Object.keys(response.entities).reduce(
    (acc, keyId) => {
      const item = Object.keys(response.entities[keyId]).reduce(
        (subAcc, subKeyId) => ({
          ...subAcc,
          [subKeyId]: response.entities[keyId][subKeyId],
        }),
        { ...currentState[keyId] }
      );
      return { ...acc, [keyId]: item };
    },
    { ...currentState }
  );
}

const initialState = {
  accountCategories: {},
  accounts: {},
  budgetHistory: {},
  budgetTracker: {},
  calendars: {},
  clientFeedback: {},
  comments: {},
  conjointFeedback: {},
  conjointPair: {},
  educRecords: {},
  employments: {},
  eventOccurrences: {},
  events: {},
  externalProjects: {},
  feedback: {},
  feedbackRequest: {},
  files: {},
  googleCalendars: {},
  hierarchy: {},
  links: {},
  notification: {},
  outlookCalendars: {},
  projectMemberships: {},
  projects: {},
  projectSkills: {},
  reactions: {},
  reminders: {},
  skills: {},
  talentFeedback: {},
  talentSkill: {},
  talentSkillFeedback: {},
  talentSkills: {},
  teams: {},
  teamMemberships: {},
  users: {},
  weeklyAssignments: {},
  workDetails: {},
  workLogs: {},
  workmateFeedback: {},
  workmates: {},
  healthForm: {},
  healthFormData: {},
  publications: {},
};

// Updates an entity cache in response to any action with response.entities.
function entities(state = initialState, action) {
  // Detect user presence changes.
  if (action.type === SET_USER_PRESENCE) {
    const { slug, online } = action;
    return mergeWith({}, state, {
      users: {
        [slug]: {
          online,
        },
      },
    });
  }

  if (action.type === EVENT_OCCURRENCE_DETAIL.SUCCESS) {
    const { response, occurrenceId: oldOccurrenceId } = action;
    const { event, occurrenceId: newOccurrenceId, rootEvent, rootEventId } = response;
    const { id: eventId } = event;
    let occurrenceId = oldOccurrenceId;
    let oldEventOccurrence = {};
    if (!Number.isInteger(oldOccurrenceId)) {
      const tempOccurrence = state.eventOccurrences[occurrenceId];
      if (tempOccurrence) {
        const { start: originalStart, end: originalEnd } = tempOccurrence;
        oldEventOccurrence = { ...tempOccurrence, originalStart, originalEnd };
      } else {
        const { start: originalStart, end: originalEnd } = event;
        oldEventOccurrence = { ...event, originalStart, originalEnd };
      }
    } else {
      occurrenceId = newOccurrenceId;
    }
    const newEventOccurrence = { ...response, ...oldEventOccurrence };

    if (rootEventId !== eventId) {
      return mergeWith({}, state, {
        events: {
          [eventId]: event,
          [rootEventId]: rootEvent,
        },
        eventOccurrences: {
          [occurrenceId]: newEventOccurrence,
        },
      });
    }
    return mergeWith({}, state, {
      events: {
        [rootEventId]: rootEvent,
      },
      eventOccurrences: {
        [occurrenceId]: newEventOccurrence,
      },
    });
  }

  if (action.type === ADD_MEMBERS.SUCCESS) {
    const { response } = action;
    const responseUsers = mapKeys(response.users, "slug");
    const responseInvitationUsers = mapKeys(response.invitationUsers, "slug");
    const users = mergeWith({}, state.users, responseUsers, responseInvitationUsers, customizer);

    return { ...state, users };
  }

  if (action.type === GET_BUDGET_TRACKER.SUCCESS) {
    const { response: budgetTracker } = action;
    return { ...state, budgetTracker };
  }

  if (action.type === GET_ROOM.SUCCESS) {
    const { response } = action;
    const responseUsers = mapKeys(response.users, "slug");
    const responseInvitationUsers = mapKeys(response.invitationUsers, "slug");
    const users = mergeWith({}, state.users, responseUsers, responseInvitationUsers, customizer);

    const projects = { ...state.projects };
    if (response.project) {
      const project = { ...projectInitialState, ...projects[response.project] };
      project.roomIds = union(project.roomIds, [response.id]);
      projects[response.project] = project;
    }

    return { ...state, projects, users };
  }

  if (action.type === CHANNEL_CREATE_OR_UPDATE.SUCCESS) {
    const { projectId, response } = action;

    const channelIds = response.map((channel) => channel.id);
    const project = { ...projectInitialState, ...state.projects[projectId] };
    project.roomIds = union(project.roomIds, channelIds);
    const projects = { ...state.projects, [projectId]: project };

    return { ...state, projects };
  }

  if (action.type === CHANNEL_DELETE.SUCCESS) {
    const { channelId, projectId } = action;

    const project = { ...projectInitialState, ...state.projects[projectId] };
    project.roomIds = project.roomIds.filter((id) => id !== channelId);
    const projects = { ...state.projects, [projectId]: project };

    return { ...state, projects };
  }

  if (action.type === GET_ROOMS.SUCCESS) {
    const {
      response: { results },
    } = action;

    let users = { ...state.users };
    results.forEach((room) => {
      const roomUsers = mapKeys(room.users, "slug");
      const roomInvitationUsers = mapKeys(room.invitationUsers, "slug");
      users = mergeWith({}, users, roomUsers, roomInvitationUsers, customizer);
    });

    return { ...state, users };
  }

  if (action.type === INVITATION_ADD.SUCCESS) {
    const { response } = action;

    const { projectId } = response;
    const project = { ...projectInitialState, ...state.projects[projectId] };
    const newInvitations = [...project.invitations, response];
    const newProject = { ...project, invitations: newInvitations };
    const projects = { ...state.projects, [projectId]: newProject };

    return { ...state, projects };
  }

  if (action.type === INVITATION_DELETE.SUCCESS) {
    const { invitation } = action;

    const { projectId } = invitation;
    const project = { ...projectInitialState, ...state.projects[projectId] };
    project.invitations = project.invitations.filter((item) => item.id !== invitation.id);
    const projects = { ...state.projects, [projectId]: project };
    return { ...state, projects };
  }

  if (action.type === LINKS_DELETE.SUCCESS) {
    const { linkId, projectId } = action;

    const project = { ...projectInitialState, ...state.projects[projectId] };
    project.links = project.links.filter((id) => id !== linkId);
    const projects = { ...state.projects, [projectId]: project };

    const links = { ...state.links };
    delete links[linkId];

    return { ...state, links, projects };
  }

  if (action.type === PROJECT_MEMBERSHIPS_DETAIL.SUCCESS) {
    const { response } = action;
    const { projectId, id: membershipId } = response;

    if (response.isDeleted) {
      const project = { ...projectInitialState, ...state.projects[projectId] };
      project.memberships = project.memberships.filter((id) => id !== membershipId);
      const projects = { ...state.projects, [projectId]: project };

      const projectMemberships = { ...state.projectMemberships };
      delete projectMemberships[membershipId];

      return { ...state, projects, projectMemberships };
    }

    const membershipCurrent = { ...state.projectMemberships[membershipId] };
    const membershipUpdated = { ...membershipCurrent, ...response };
    const membershipsCurrent = { ...state.projectMemberships };
    const membershipsUpdated = { ...membershipsCurrent, [membershipId]: membershipUpdated };

    const project = { ...projectInitialState, ...state.projects[projectId] };
    if (!project.memberships.includes(membershipId)) {
      project.memberships.push(membershipId);
    }
    const projects = { ...state.projects, [projectId]: project };

    return { ...state, projectMemberships: membershipsUpdated, projects };
  }

  if (action.type === PROJECT_INVITATIONS_DETAIL.SUCCESS) {
    const { response } = action;

    const { projectId } = response;
    const projectCurrent = { ...projectInitialState, ...state.projects[projectId] };

    if (response.isDeleted) {
      const invitations = projectCurrent.invitations.filter((i) => i.id !== response.id);
      const projectUpdated = { ...projectCurrent, invitations };

      const projects = { ...state.projects, [projectId]: projectUpdated };
      return { ...state, projects };
    }

    const invitations = [...projectCurrent.invitations];
    const index = projectCurrent.invitations.findIndex((i) => i.id === response.id);

    if (index >= 0) {
      invitations[index] = { ...invitations[index], ...response };
    } else {
      invitations.push(response);
    }

    const projectUpdated = { ...projectCurrent, invitations };
    const projects = { ...state.projects, [projectId]: projectUpdated };

    return { ...state, projects };
  }

  if (action.type === PROJECT_INVITATIONS_UPDATE.SUCCESS) {
    const { response } = action;
    const { entities, result: projectId } = response;
    const newInvitations = entities.projects[projectId].invitations;
    const newProject = { ...state.projects[projectId], invitations: newInvitations };
    const newProjects = { ...state.projects, [projectId]: newProject };
    return { ...state, projects: newProjects };
  }

  if (action.type === PROJECT_MEMBERSHIPS_DELETE.SUCCESS) {
    const { membershipId, projectId, userSlug } = action;

    const project = { ...projectInitialState, ...state.projects[projectId] };
    const user = { ...state.users[userSlug] };
    if (user.workDetails) {
      user.workDetails = user.workDetails.filter((item) => item !== project.id);
    }
    const users = { ...state.users, [userSlug]: user };

    project.memberships = project.memberships.filter((id) => id !== membershipId);
    const projects = { ...state.projects, [projectId]: project };

    const projectMemberships = { ...state.projectMemberships };
    delete projectMemberships[membershipId];

    const workDetails = { ...state.workDetails };
    delete workDetails[project.id];

    return { ...state, projects, projectMemberships, workDetails, users };
  }

  if (action.type === PROJECT_DETAIL.SUCCESS) {
    const { response } = action;
    const projectId = response.id;

    if (response.isDeleted) {
      const projects = { ...state.projects };
      const project = { ...projectInitialState, ...projects[projectId] };
      delete projects[projectId];

      const projectMembershipsCurrent = { ...state.projectMemberships };
      const projectMemberships = omit(projectMembershipsCurrent, project.memberships);

      return { ...state, projects, projectMemberships };
    }

    const project = { ...projectInitialState, ...state.projects[projectId], ...response };

    const linksNew = keyBy(project.links, "id");
    const links = { ...state.links, ...linksNew };

    const projectMembershipsNew = keyBy(project.memberships, "id");
    const projectMemberships = { ...state.projectMemberships, ...projectMembershipsNew };

    if (response.links) {
      project.links = project.links.map((link) => link.id);
    }

    if (response.memberships) {
      project.memberships = project.memberships.map((membership) => membership.id);
    }

    const projects = { ...state.projects, [projectId]: project };

    return { ...state, links, projects, projectMemberships };
  }

  if (action.type === PROJECT_UPDATE.SUCCESS) {
    const { response } = action;
    const projectId = response.result;
    const project = {
      ...projectInitialState,
      ...state.projects[projectId],
      ...response.entities.projects[projectId],
    };
    project.scheduledFeedbackUserRecipients =
      response.entities.projects[projectId].scheduledFeedbackUserRecipients;
    project.scheduledFeedbackExternalRecipients =
      response.entities.projects[projectId].scheduledFeedbackExternalRecipients;
    const projects = { ...state.projects, [projectId]: project };
    return { ...state, projects };
  }

  if (action.type === PROJECT_BUDGET_UPDATE.SUCCESS) {
    const { response, projectId } = action;
    if (response.entities.projectBudget) {
      const project = {
        ...state.projects[projectId],
      };
      project.budget = Object.values(response.entities.projectBudget).filter(
        (item) => item.project === projectId
      );

      const projects = { ...state.projects, [projectId]: project };
      return { ...state, projects };
    }
  }

  if (action.type === UPDATE_USER.INIT) {
    const { slug, data } = action;

    // Handle user profile deletions!
    if (!isEmpty(data.accounts)) {
      const nextState = {
        ...state,
        users: {
          ...state.users,
          [data.slug]: {
            ...state.users[data.slug],
            linkedAccountCategories: state.users[data.slug].linkedAccountCategories.filter(
              (id) => !data.accounts[id] || !data.accounts[id]._toDelete
            ),
          },
        },
        accountCategories: Object.keys(state.accountCategories).reduce((obj, id) => {
          if (data.accounts[id]._toDelete) {
            return obj;
          }
          const accounts = Object.assign(
            {},
            ...data.accounts[id].accounts.map((acc) => ({ [acc.id]: acc }))
          );
          return {
            ...obj,
            [id]: {
              ...state.accountCategories[id],
              accounts: state.accountCategories[id].accounts.filter(
                (aid) => !accounts[aid] || !accounts[aid]._toDelete
              ),
            },
          };
        }, {}),
      };
      return nextState;
    }
    const user = {
      educationalAccomplishments: [],
      employmentHistory: [],
      publications: [],
      linkedAccounts: [],
      ...state.users[slug],
    };
    if (!isEmpty(data.educationalAccomplishments)) {
      const education = data.educationalAccomplishments
        .filter((obj) => obj._toDelete)
        .map((obj) => obj.id);
      user.educationalAccomplishments = user.educationalAccomplishments.filter(
        (id) => !education.includes(id)
      );
    }
    if (!isEmpty(data.employmentHistory)) {
      const employment = data.employmentHistory.filter((obj) => obj._toDelete).map((obj) => obj.id);
      user.employmentHistory = user.employmentHistory.filter((id) => !employment.includes(id));
    }
    if (!isEmpty(data.publications)) {
      const publication = data.publications.filter((obj) => obj._toDelete).map((obj) => obj.id);
      user.publications = user.publications.filter((id) => !publication.includes(id));
    }

    if (Object.keys(data).includes("featuredSkills")) {
      user.featuredSkills = data.featuredSkills || [];
    }
    const users = { ...state.users, [slug]: user };
    return { ...state, users };
  }

  if (action.type === UPDATE_USER.SUCCESS || action.type === USER.SUCCESS) {
    if (action.response.entities) {
      return mergeUser(action.response, state);
    }
  }

  if (action.type === USER_DETAIL.SUCCESS) {
    const { user, response } = action;

    if (response && response.isDeleted) {
      const { slug, sentinel } = response;
      const userItem = state.users[slug];

      // remove team memberships associated to user
      const teamMemberships = Object.keys(state.teamMemberships).reduce((acc, membershipId) => {
        const item = { ...state.teamMemberships[membershipId] };
        if (item.talent !== slug) {
          return { ...acc, [item.membershipId]: item };
        }
        return { ...acc };
      }, {});

      const teams = Object.keys(state.teams).reduce((acc, teamId) => {
        const team = {
          talents: [],
          talentSlugs: [],
          talentNamesList: [],
          invitations: [],
          ...state.teams[teamId],
        };
        team.talents = team.talents.filter(
          (talent) => talent !== userItem.username && talent !== undefined
        );
        team.talentSlugs = team.talentSlugs.filter(
          (slug_) => slug_ !== userItem.slug && slug_ !== undefined
        );
        team.talentNamesList = team.talentNamesList.filter(
          (name) => name !== userItem.displayName && name !== undefined
        );
        team.invitations = team.invitations.filter(
          (obj) => obj.user !== slug && obj.user !== undefined
        );
        return { ...acc, [team.id]: team };
      }, {});

      const employments = Object.keys(state.employments).reduce((acc, employmentId) => {
        const item = { ...state.employments[employmentId] };
        if (item.talent !== userItem.username) {
          return { ...acc, [item.id]: item };
        }
        return { ...acc };
      }, {});

      const educRecords = Object.keys(state.educRecords).reduce((acc, educId) => {
        const item = { ...state.educRecords[educId] };
        if (item.talent !== userItem.username) {
          return { ...acc, [item.id]: item };
        }
        return { ...acc };
      }, {});

      const publications = Object.keys(state.publications).reduce((acc, pubId) => {
        const item = { ...state.publications[pubId] };
        if (item.talent !== userItem.username) {
          return { ...acc, [item.id]: item };
        }
        return { ...acc };
      }, {});

      const talentSkills = Object.keys(state.talentSkills).reduce((acc, skillId) => {
        const item = { ...state.talentSkills[skillId] };
        if (item.talent !== userItem.username) {
          return { ...acc, [item.id]: item };
        }
        return { ...acc };
      }, {});

      const membershipIds = [];
      const projectMemberships = Object.keys(state.projectMemberships).reduce(
        (acc, membershipId) => {
          const item = { ...state.projectMemberships[membershipId] };
          if (item.userSlug !== slug) {
            return { ...acc, [item.id]: item };
          }
          membershipIds.push(item.id);
          return { ...acc };
        },
        {}
      );

      const projects = Object.keys(state.projects).reduce((acc, projectId) => {
        const project = {
          ...projectInitialState,
          ...state.projects[projectId],
        };

        project.memberships = project.memberships.filter((id) => !membershipIds.includes(id));
        project.invitations = project.invitations.filter((obj) => obj.user !== slug);
        return { ...acc, [project.id]: project };
      }, {});

      const users = { ...state.users };
      const userSlugs = Object.keys(state.users);
      if (!userSlugs.includes(sentinel.slug)) {
        users[sentinel.slug] = sentinel;
      }

      const reactions = Object.keys(state.reactions).reduce((acc, reactionId) => {
        const reaction = { ...state.reactions[reactionId] };
        if (reaction.user === slug) {
          reaction.user = sentinel.slug;
        }
        return { ...acc, [reaction.id]: reaction };
      }, {});

      delete users[slug];

      return {
        ...state,
        teams,
        users,
        teamMemberships,
        employments,
        educRecords,
        talentSkills,
        projectMemberships,
        projects,
        publications,
        reactions,
      };
    }

    if (user) {
      const userUpdated = { ...state.users[user.slug], ...user };
      const users = { ...state.users, [user.slug]: userUpdated };

      return { ...state, users };
    }
    if (response) {
      const userItem = {
        user: response,
      };
      const normalizedResponse = normalize(userItem, {
        user: schema.userSchema,
      });
      return mergeUser(normalizedResponse, state);
    }

    return state;
  }

  if (action.type === TEAM_INVITATION_DETAIL.SUCCESS) {
    const { response } = action;

    if (response.isDeleted) {
      const { id, teamId } = response;
      const team = {
        invitations: [],
        ...state.teams[teamId],
      };
      team.invitations = team.invitations.filter((obj) => obj.id !== id);
      const teams = { ...state.teams, [teamId]: team };

      return { ...state, teams };
    }

    const { team: teamId, id, user: userSlug } = response;
    const team = {
      invitations: [],
      talents: [],
      talentSlugs: [],
      talentNamesList: [],
      ...state.teams[teamId],
    };

    const user = {
      teams: [],
      ...state.users[userSlug],
    };

    if (response.status === status.accepted) {
      if (user) {
        // update the talent list
        if (!team.talents.includes(user.username)) {
          team.talents.push(user.username);
        }
        if (!team.talentSlugs.includes(user.slug)) {
          team.talentSlugs.push(user.slug);
        }
        if (team.talentNamesList.includes(user.displayName)) {
          team.talentNamesList.push(user.displayName);
        }
        if (!user.teams.includes(teamId)) {
          user.teams.push(teamId);
        }
      }
    }
    // remove the old invitation then push the new one
    team.invitations = team.invitations.filter((obj) => obj.id !== id);
    team.invitations.push(response);
    const teams = { ...state.teams, [teamId]: team };
    const users = { ...state.users, [userSlug]: user };

    return { ...state, teams, users };
  }

  if (action.type === CLEAN_EVENT_OCCURRENCES) {
    const { slug, ids, deleted } = action;

    let nextEvents = state.events;
    if (deleted) {
      nextEvents = Object.keys(state.events).reduce((acc, id) => {
        const event = state.events[id];
        if (ids.includes(event.id)) {
          return acc;
        }
        return {
          ...acc,
          [id]: event,
        };
      }, {});
    }
    const occurrenceIds = [];
    const nextOccurrences = Object.keys(state.eventOccurrences).reduce((acc, id) => {
      const occurrence = state.eventOccurrences[id];
      if (ids.includes(occurrence.event)) {
        occurrenceIds.push(id);
        return acc;
      }
      return {
        ...acc,
        [id]: occurrence,
      };
    }, {});
    if (occurrenceIds.length > 0) {
      const nextState = {
        ...state,
        calendars: {
          ...state.calendars,
          [slug]: {
            ...state.calendars[slug],
            occurrences: state.calendars[slug].occurrences.filter(
              (id) => !occurrenceIds.includes(id)
            ),
          },
        },
        events: nextEvents,
        eventOccurrences: nextOccurrences,
      };
      return nextState;
    }
    return state;
  }

  if (action.type === CLEAN_GOOGLE_EVENT_OCCURRENCES) {
    const { slug, ids, deleted } = action;

    const nextGoogleCalendars = Object.keys(state.googleCalendars).reduce((acc, id) => {
      if (ids.includes(id) && deleted) {
        return acc;
      }
      return {
        ...acc,
        [id]: state.googleCalendars[id],
      };
    }, {});
    const occurrenceIds = [];
    const nextOccurrences = Object.keys(state.eventOccurrences).reduce((acc, id) => {
      const occurrence = state.eventOccurrences[id];
      if (ids.includes(occurrence.googleCalId)) {
        occurrenceIds.push(id);
        return acc;
      }
      return {
        ...acc,
        [id]: occurrence,
      };
    }, {});
    if (occurrenceIds.length > 0) {
      const nextState = {
        ...state,
        calendars: {
          ...state.calendars,
          [slug]: {
            ...state.calendars[slug],
            calendar: {
              ...state.calendars[slug].calendar,
              google: {
                ...state.calendars[slug].calendar.google,
                calendars: state.calendars[slug].calendar.google.calendars.filter(
                  (id) => !(ids.includes(id) && deleted)
                ),
              },
            },
            occurrences: state.calendars[slug].occurrences.filter(
              (id) => !occurrenceIds.includes(id)
            ),
          },
        },
        eventOccurrences: nextOccurrences,
        googleCalendars: nextGoogleCalendars,
      };
      return nextState;
    }
    return state;
  }

  if (action.type === CLEAN_OUTLOOK_EVENT_OCCURRENCES) {
    const { slug, ids, deleted } = action;

    const nextOutlookCalendars = Object.keys(state.outlookCalendars).reduce((acc, id) => {
      if (ids.includes(id) && deleted) {
        return acc;
      }
      return {
        ...acc,
        [id]: state.outlookCalendars[id],
      };
    }, {});
    const occurrenceIds = [];
    const nextOccurrences = Object.keys(state.eventOccurrences).reduce((acc, id) => {
      const occurrence = state.eventOccurrences[id];
      if (ids.includes(occurrence.outlookCalId)) {
        occurrenceIds.push(id);
        return acc;
      }
      return {
        ...acc,
        [id]: occurrence,
      };
    }, {});
    if (occurrenceIds.length > 0) {
      const nextState = {
        ...state,
        calendars: {
          ...state.calendars,
          [slug]: {
            ...state.calendars[slug],
            calendar: {
              ...state.calendars[slug].calendar,
              outlook: {
                ...state.calendars[slug].calendar.outlook,
                calendars: state.calendars[slug].calendar.outlook.calendars.filter(
                  (id) => !(ids.includes(id) && deleted)
                ),
              },
            },
            occurrences: state.calendars[slug].occurrences.filter(
              (id) => !occurrenceIds.includes(id)
            ),
          },
        },
        eventOccurrences: nextOccurrences,
        outlookCalendars: nextOutlookCalendars,
      };
      return nextState;
    }
    return state;
  }

  if (action.type === RESET_EVENT_OCCURRENCES) {
    const { slug } = action;
    if (Object.prototype.hasOwnProperty.call(state.calendars, slug)) {
      const { occurrences } = state.calendars[slug];

      const nextOccurrences = Object.keys(state.eventOccurrences).reduce((acc, id) => {
        const occurrence = state.eventOccurrences[id];
        if (occurrences.includes(id)) {
          return acc;
        }
        return {
          ...acc,
          [id]: occurrence,
        };
      }, {});

      const nextState = {
        ...state,
        calendars: {
          ...state.calendars,
          [slug]: {
            ...state.calendars[slug],
            occurrences: [],
          },
        },
        eventOccurrences: nextOccurrences,
      };
      return nextState;
    }
    return state;
  }

  if (action.type === REACTIONS_ADD.REQUEST) {
    const { id, messageId, user, emoji } = action;
    const reactions = {
      ...state.reactions,
      [`__temp__${uuidv4()}`]: {
        room: id,
        message: messageId,
        user,
        emoji,
      },
    };
    return { ...state, reactions };
  }

  if (action.type === REACTIONS_ADD.SUCCESS) {
    const reactions = { ...state.reactions };
    const toDelete = Object.keys(reactions).find(
      (reactionId) => reactionId.length && reactionId.startsWith("__temp__")
    );
    delete reactions[toDelete];
    const nextState = { ...state, reactions };
    return mergeWith({}, nextState, action.response.entities, customizer);
  }

  if (action.type === REACTIONS_DELETE.REQUEST) {
    const { messageId, user, emoji } = action;
    const reactions = { ...state.reactions };
    const toDelete = Object.keys(reactions).find(
      (reactionId) =>
        reactions[reactionId].message === messageId &&
        reactions[reactionId].user === user &&
        reactions[reactionId].emoji === emoji
    );
    delete reactions[toDelete];
    return { ...state, reactions };
  }

  if (action.type === REACTIONS_DELETE.SUCCESS) {
    const {
      response: { id },
    } = action;
    const reactions = { ...state.reactions };
    if (reactions[id]) {
      delete reactions[id];
      return { ...state, reactions };
    }
    return state;
  }

  if (action.type === UPDATE_TEAM.INIT) {
    const { id, data } = action;

    // remove teamSkills from data
    // since it will be added in TEAM_DETAIL.SUCCESS
    const updatedData = { ...data };
    delete updatedData.teamSkills;

    const team = {
      ...state.teams[id],
      ...updatedData,
    };

    if (Object.keys(updatedData).includes("featuredSkills")) {
      team.featuredSkills = updatedData.featuredSkills || [];
    }

    const teams = { ...state.teams, [id]: team };
    return { ...state, teams };
  }

  if (action.type === TEAM_DETAIL.SUCCESS || action.type === DELETE_TEAM.SUCCESS) {
    const { response } = action;
    const { id } = response;

    if (response.isDeleted) {
      const membershipIds = [];

      // remove team memberships associated to team
      const teamMemberships = Object.keys(state.teamMemberships).reduce((acc, membershipId) => {
        const item = { ...state.teamMemberships[membershipId] };
        if (item.team !== id) {
          return { ...acc, [item.membershipId]: item };
        }
        membershipIds.push(item.membershipId);
        return { ...acc };
      }, {});

      const users = Object.keys(state.users).reduce((acc, userSlug) => {
        const user = {
          teams: [],
          teamMemberships: [],
          ...state.users[userSlug],
        };

        user.teams = user.teams.filter((teamId) => teamId !== id);
        user.teamMemberships = user.teamMemberships.filter(
          (membershipId) => !membershipIds.includes(membershipId)
        );

        return {
          ...acc,
          [user.slug]: user,
        };
      }, {});

      const teams = { ...state.teams };
      delete teams[id];

      return { ...state, teams, users, teamMemberships };
    }

    const teams = {
      ...state.teams,
      [id]: {
        ...state.teams[id],
        ...response,
      },
    };

    return {
      ...state,
      teams,
    };
  }

  if (action.type === TEAM_MEMBERSHIPS_DETAIL.SUCCESS) {
    const { response } = action;
    const { team: teamId, membershipId, talent: talentSlug } = response;

    if (response.isDeleted) {
      const user = {
        teams: [],
        teamMemberships: [],
        ...state.users[talentSlug],
      };
      user.teams = user.teams.filter((item) => item !== teamId);
      user.teamMemberships = user.teamMemberships.filter(
        (membership) => membership !== membershipId
      );
      const users = { ...state.users, [talentSlug]: user };

      const team = {
        talents: [],
        talentSlugs: [],
        talentNamesList: [],
        ...state.teams[teamId],
      };
      team.talents = team.talents.filter((talent) => talent !== user.username);
      team.talentSlugs = team.talentSlugs.filter((slug) => slug !== user.slug);
      team.talentNamesList = team.talentNamesList.filter((name) => name !== user.displayName);
      const teams = { ...state.teams, [teamId]: team };

      const teamMemberships = { ...state.teamMemberships };
      delete teamMemberships[membershipId];

      return { ...state, teams, teamMemberships, users };
    }

    const user = {
      teamMemberships: [],
      ...state.users[talentSlug],
    };
    if (!user.teamMemberships.includes(membershipId)) {
      user.teamMemberships.push(membershipId);
    }

    const users = { ...state.users, [talentSlug]: user };
    const membershipsUpdated = {
      ...state.teamMemberships,
      [membershipId]: {
        ...state.teamMemberships[membershipId],
        ...response,
      },
    };

    return {
      ...state,
      teamMemberships: membershipsUpdated,
      users,
    };
  }

  if (action.type === DELETE_USER.SUCCESS) {
    const {
      response: { slug },
    } = action;
    const users = { ...state.users };
    delete users[slug];

    return { ...state, users };
  }

  if (action.type === PROJECT_DELETE.SUCCESS) {
    const {
      response: { id },
    } = action;
    const projects = { ...state.projects };
    delete projects[id];

    return { ...state, projects };
  }

  if (action.type === HEALTH_FORM_DATA.SUCCESS) {
    const { response } = action;
    return {
      ...state,
      healthFormData: response.entities.healthFormData || {},
    };
  }

  if (action.type === HEALTH_FORM_DATA_UPDATE.SUCCESS) {
    const { response } = action;
    return {
      ...state,
      healthFormData: {
        ...Object.keys(state.healthFormData).reduce((arr, key) => {
          if (state.healthFormData[key].id !== response.id) {
            arr[key] = state.healthFormData[key];
          }
          return arr;
        }, {}),
        [response.id]: response,
      },
    };
  }

  if (action.type === ADD_REMINDERS) {
    const { data } = action;
    return {
      ...state,
      reminders: {
        ...state.reminders,
        ...data,
      },
    };
  }

  if (action.type === REMOVE_REMINDER) {
    const { id } = action;
    return {
      ...state,
      reminders: {
        ...Object.keys(state.reminders).reduce((arr, key) => {
          if (key !== id) {
            arr[key] = state.reminders[key]; // eslint-disable-line no-param-reassign
          }
          return arr;
        }, {}),
      },
    };
  }

  if (action.type === ADD_CLIENT_FEEDBACK) {
    const { data } = action;
    return {
      ...state,
      clientFeedback: {
        ...state.clientFeedback,
        ...data,
      },
    };
  }

  if (action.type === BULK_SKILLS_UPDATE.INIT) {
    const { slug, data } = action;

    // handle skills deletion
    if (!isEmpty(data.toDelete)) {
      const talentSkills = { ...state.talentSkills };
      data.toDelete.forEach((id) => {
        delete talentSkills[id];
      });

      // handle talent skills update with skills deletion
      data.skillsMap.forEach((object) => {
        object.ids.forEach((id) => {
          talentSkills[id].userRating = object.rating;
          talentSkills[id].skillRatingName = object.ratingName;
        });
      });

      let users = { ...state.users };
      if (users[slug]) {
        const newTalentSkillsList = difference(users[slug].talentSkills, data.toDelete);
        console.log(newTalentSkillsList);
        users = {
          ...users,
          [slug]: {
            ...users[slug],
            talentSkills: newTalentSkillsList,
          },
        };
      }

      return { ...state, users, talentSkills };
    }
  }

  if (action.type === BULK_TEAM_SKILLS_UPDATE.INIT) {
    const { slug, data } = action;
    const { teams } = state;
    const team = Object.keys(teams)
      .map((key) => teams[key])
      .find((obj) => obj.slug === slug);

    if (team) {
      // handle skills deletion
      if (!isEmpty(data.toDelete)) {
        const reducedSkills = team.teamSkills.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: item,
          }),
          {}
        );
        data.toDelete.forEach((id) => {
          delete reducedSkills[id];
        });

        // handle talent skills update with skills deletion
        data.skillsMap.forEach((object) => {
          object.ids.forEach((id) => {
            reducedSkills[id].userRating = object.rating;
            reducedSkills[id].skillRatingName = object.ratingName;
          });
        });

        const updatedSkills = Object.keys(reducedSkills).map((key) => reducedSkills[key]);

        const updatedTeams = {
          ...teams,
          [team.id]: {
            ...team,
            teamSkills: updatedSkills,
          },
        };

        return { ...state, updatedTeams };
      }
    }
  }

  if (action.type === BULK_TEAM_SKILLS_UPDATE.SUCCESS && action.response.entities) {
    const responseTeams = action.response.entities.teams;

    if (responseTeams) {
      const teams = { ...state.teams };
      // iterate through teams and update the `skillTopRatingsList` and `topThreeSkills`
      Object.keys(responseTeams).forEach((id) => {
        const item = responseTeams[id];

        if (!isEqual(item.topThreeSkills, teams[id].topThreeSkills)) {
          teams[id].topThreeSkills = item.topThreeSkills;
        }
        if (!isEqual(item.teamSkills, teams[id].teamSkills)) {
          teams[id].teamSkills = item.teamSkills;
        }
      });

      return { ...state, teams };
    }
  }

  if (action.type === ROOM_MEETING_DETAIL.SUCCESS) {
    const responseEvents = action.response.entities.events;

    if (responseEvents) {
      const events = { ...state.events };
      Object.keys(events).forEach((id) => {
        const item = responseEvents[id];
        events[id] = { ...events[id], ...item };
      });
      return { ...state, events };
    }
  }

  if (action.type === ROOM_MEETING_JOIN.REQUEST) {
    const { event } = action;
    const nextEvents = {
      ...state.events,
      [event.id]: {
        ...state.events[event.id],
        loading: true,
      },
    };
    return { ...state, events: nextEvents };
  }

  if (action.type === ROOM_MEETING_JOIN.SUCCESS) {
    const { event, response } = action;
    const eventResponse = response.entities.events[event.id];
    const nextEvents = {
      ...state.events,
      [event.id]: {
        ...state.events[event.id],
        ...eventResponse,
        loading: false,
      },
    };
    return { ...state, events: nextEvents };
  }

  if (action.type === ROOM_MEETING_JOIN.FAILURE) {
    const { event } = action;
    const nextEvents = {
      ...state.events,
      [event.id]: {
        ...state.events[event.id],
        ...event,
        loading: true,
      },
    };
    return { ...state, events: nextEvents };
  }

  if (action.type === BULK_PHONE_NUMBERS_UPDATE.SUCCESS) {
    const { slug, response } = action;
    const user = {
      phoneNumbers: [],
      ...state.users[slug],
    };
    user.phoneNumbers = response;
    const users = { ...state.users, [slug]: user };
    return { ...state, users };
  }

  if (action.type === RESET_WORK_LOGS.INIT) {
    return { ...state, workLogs: {} };
  }

  if (action.response && action.response.entities) {
    /* There are two action types for notification: NOTIFICATION and PAGINATED_NOTIFICATION
       Assumption: the first page is always through the NOTIFICATION, the rest of the pages are
       through the PAGINATED_NOTIFICATION. This is needed to identify whether to
       replace the content of state.notification or merge it with the notification
       from the response.
       * NOTIFICATION - replaces the content of the state.notification
       * PAGINATED_NOTIFICATION - merges the content of state.notification with the response
    */
    if (action.type === NOTIFICATION.SUCCESS) {
      const copy = { ...action.response.entities };
      if (Object.keys(copy).length > 0) {
        Object.keys(copy.notification).forEach((key) => {
          copy.notification[key] = {
            ...copy.notification[key],
            page: 1,
          };
        });
        return {
          ...state,
          notification: copy.notification,
        };
      }
      return {
        ...state,
        notification: {},
      };
    }

    if (action.type === PAGINATED_NOTIFICATION.SUCCESS) {
      // get the page number from state.notification
      let current = 1;
      const keys = Object.keys(state.notification);
      if (keys.length > 0) {
        let pageNumbers = keys.map((key) => state.notification[key].page);
        pageNumbers = pageNumbers.filter((x) => x !== undefined);
        const previous = Math.max(...pageNumbers);
        current = isNaN(previous) || previous === undefined ? 1 : previous + 1;
      }
      const copy = { ...action.response.entities };
      if (Object.keys(copy).length > 0) {
        Object.keys(copy.notification).forEach((key) => {
          copy.notification[key] = {
            ...copy.notification[key],
            page: current,
          };
        });
      }
      return mergeWith({}, state, copy, customizer);
    }

    if (action.type === PROJECT.SUCCESS && !action.id) {
      const nextState = { ...state, projects: {}, projectMemberships: {}, links: {} };
      return mergeWith({}, nextState, action.response.entities, customizer);
    }

    if (action.type === CALENDAR.SUCCESS) {
      const nextState = {
        ...state,
        calendars: {
          ...state.calendars,
          ...action.response.entities.calendars,
        },
        googleCalendars: {
          ...state.googleCalendars,
          ...action.response.entities.googleCalendars,
        },
        outlookCalendars: {
          ...state.outlookCalendars,
          ...action.response.entities.outlookCalendars,
        },
        eventOccurrences: {
          ...state.eventOccurrences,
          ...action.response.entities.eventOccurrences,
        },
        events: {
          ...state.events,
          ...action.response.entities.events,
        },
      };
      return nextState;
    }

    return mergeWith({}, state, action.response.entities, customizer);
  }

  if (action.type === GET_WEEKLY_ASSIGNMENTS.SUCCESS) {
    const { response } = action;
    const weeklyAssignments = response.results
      .map((weeklyAssignment) => ({ [weeklyAssignment.id]: weeklyAssignment }))
      .reduce(
        (weeklyAssignments, weeklyAssignment) => ({
          ...weeklyAssignments,
          ...weeklyAssignment,
        }),
        {}
      );
    return { ...state, weeklyAssignments };
  }

  if (
    action.type === CREATE_WEEKLY_ASSIGNMENT.SUCCESS ||
    action.type === UPDATE_WEEKLY_ASSIGNMENT.SUCCESS
  ) {
    const { response: weeklyAssignment } = action;
    const weeklyAssignments = {
      ...state.weeklyAssignments,
      [weeklyAssignment.id]: weeklyAssignment,
    };
    return { ...state, weeklyAssignments };
  }

  return state;
}

const ProjectIncompleteTransform = createTransform(
  (inboundState, key) => {
    const result = { ...inboundState };
    if (key === "projects") {
      Object.keys(result).forEach((id) => {
        const project = { ...result[id] };
        delete project.isComplete;
        result[id] = project;
      });
    } else if (key === "users") {
      const blacklist = [
        "educationalAccomplishments",
        "employmentHistory",
        "externalProjects",
        "featuredSkills",
        "hourlyRateRange",
        "linkedAccountCategories",
        "linkedAccounts",
        "phoneNumbers",
        "publications",
        "talentSkills",
        "teamMemberships",
        "teams",
        "userFiles",
        "workDetails",
      ];
      Object.keys(result).forEach((id) => {
        const user = { ...result[id] };
        blacklist.forEach((item) => {
          delete user[item];
        });
        result[id] = user;
      });
    }
    return result;
  },
  (outboundState) => outboundState,
  {
    whitelist: ["projects", "users"],
  }
);

const entitiesConfig = {
  key: "entities",
  storage: localforage,
  transforms: [ProjectIncompleteTransform],
  whitelist: ["projects", "projectMemberships", "users"],
};

export default persistReducer(entitiesConfig, entities);
