import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const SCROLLABLE_ID = "contentContainer";

class ScrollableContent extends React.Component {
  constructor(props) {
    super(props);
    this._isScrolling = null;

    if (props.scrollableElementId) {
      this._id = props.scrollableElementId;
    } else {
      this._id = `${props.idPrefix}-${SCROLLABLE_ID}`;
    }
  }

  componentDidMount() {
    const { alwaysDisplayScrollbar } = this.props;
    document.getElementById(this._id).addEventListener("scroll", this._displayScrollBars);
    if (alwaysDisplayScrollbar) {
      this._displayScrollBars();
    }
  }

  componentWillUnmount() {
    document.getElementById(this._id).removeEventListener("scroll", this._displayScrollBars);
  }

  _displayScrollBars = () => {
    const { idPrefix, alwaysDisplayScrollbar } = this.props;
    window.clearTimeout(this._isScrolling);
    const container = document.getElementById(this._id);
    const scrollBar = document.getElementById(`${idPrefix}-scrollBar`);
    const scrollThumb = document.getElementById(`${idPrefix}-scrollThumb`);
    const OFFSET = 10;

    // compute for the height of the scroll thumb
    const height = container.clientHeight * (container.clientHeight / container.scrollHeight);
    if (scrollThumb) scrollThumb.style.height = `${height}px`;

    // compute for the scrollTop of the scrollThumb
    let top = (container.scrollTop / container.scrollHeight) * container.clientHeight;
    // subtract offset so the scrollbar is not cut off when it reaches the bottom
    if (top > OFFSET) {
      top -= OFFSET;
    }
    if (scrollThumb) scrollThumb.style.transform = `translateY(${top}px)`;
    if (scrollBar) scrollBar.style.opacity = 1;

    if (!alwaysDisplayScrollbar) {
      this._isScrolling = setTimeout(this._hideScrollbars, 1000);
    }
  };

  _hideScrollbars = () => {
    const { idPrefix } = this.props;
    const scrollBar = document.getElementById(`${idPrefix}-scrollBar`);
    if (scrollBar) scrollBar.style.opacity = 0;
  };

  render() {
    const { children, contentClass, idPrefix, containerClass, alwaysDisplayScrollbar } = this.props;
    return (
      <div className={classnames(styles.scrollableContent, containerClass)}>
        <div id={`${idPrefix}-${SCROLLABLE_ID}`} className={contentClass}>
          {children}
        </div>
        <div
          id={`${idPrefix}-scrollBar`}
          className={classnames(styles.scrollbarTrack, alwaysDisplayScrollbar && styles.showAlways)}
        >
          <div id={`${idPrefix}-scrollThumb`} className={styles.scrollbarThumb} />
        </div>
      </div>
    );
  }
}

ScrollableContent.propTypes = {
  children: PropTypes.node,
  containerClass: PropTypes.string,
  contentClass: PropTypes.string,
  scrollableElementId: PropTypes.string,
  idPrefix: PropTypes.string.isRequired,
  alwaysDisplayScrollbar: PropTypes.bool,
};

ScrollableContent.defaultProps = {
  containerClass: "",
  contentClass: styles.content,
  scrollableElementId: "",
  alwaysDisplayScrollbar: false,
};

export default ScrollableContent;
