export const ALPHABETICAL = "alphabetical";
export const POSITION = "position";
export const ROLE = "role";

export const SORT_OPTIONS = [
  { text: "Alphabetical", value: ALPHABETICAL },
  // { text: "Position", value: POSITION }, TODO: uncomment when position field is available
  { text: "Role", value: ROLE },
];

export const WELCOME_MESSAGE_KEY = "welcomeMessage";

export const MEETING_TEMPLATE = {
  end: null,
  endRecurringPeriod: null,
  id: null,
  isAllDay: false,
  start: null,
  timezoneDisplay: "",
  title: "",
  callUrl: "",
  description: "",
  billable: true,
  guests: [],
  ruleFrequency: "WEEKLY",
  emailNotif: true,
};

export const MEETINGS = {
  planning: {
    name: "Sprint planning",
    description: "Plan the features that will be developed",
    ruleParams: "interval:2",
    icon: "calendar outline",
    suggestedStart: 8,
  },
  estimation: {
    name: "Sprint estimation",
    description: "Estimate how much work can be done",
    ruleParams: "interval:2",
    icon: "check circle outline",
    suggestedStart: 10,
  },
  review: {
    name: "Sprint review",
    description: "Demo on the product and accomplishments",
    ruleParams: "interval:2",
    icon: "comments",
    suggestedStart: 9,
  },
  retrospective: {
    name: "Retrospective",
    description: "Discuss how the next sprint could be improved further",
    ruleParams: "interval:2",
    icon: "eye",
    suggestedStart: 11,
  },
  midsprint: {
    name: "Mid-sprint prioritization",
    description: "Meet with your team mid-sprint",
    ruleParams: "interval:2",
    icon: "chart bar",
    suggestedStart: 7,
  },
  checkIns: {
    name: "Daily check-ins",
    description: "Check-in with the team daily",
    ruleParams: "byweekday:MO,TU,WE,TH,FR",
    icon: "user",
    suggestedStart: 7,
  },
};

export const MEETING_TYPES = Object.keys(MEETINGS);
