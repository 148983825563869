import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { NavLink as Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { notification as notificationAction } from "actions/notification";

import styles from "./styles.module.scss";

class NotificationBell extends React.PureComponent {
  componentDidMount() {
    if (this.props.isAuthenticated && this.props.unreadMessagesLoaded) {
      this.props.loadNotification();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isAuthenticated &&
      this.props.unreadMessagesLoaded &&
      !(prevProps.isAuthenticated && prevProps.unreadMessagesLoaded)
    ) {
      this.props.loadNotification();
    }
  }

  render() {
    const { active, className, hasNotifications, id } = this.props;

    return (
      <Link className={className} id={id} to="/notifications">
        <Button
          basic
          circular
          className={classnames(styles.notifBell, active && styles.active)}
          icon
          size="medium"
          title="Notifications"
        >
          <Icon name="bell" />
          {hasNotifications && <div id="notifBellUnread" className={styles.notifBellUnread} />}
        </Button>
      </Link>
    );
  }
}

NotificationBell.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,

  hasNotifications: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool,
  unreadMessagesLoaded: PropTypes.bool,
  loadNotification: PropTypes.func.isRequired,
};

NotificationBell.defaultProps = {
  active: false,
  className: null,
  id: null,
};

function mapStateToProps(state) {
  const { unreadCount: unreadNotificationsCount } = state.notification;
  const hasNotifications = unreadNotificationsCount > 0;
  const { isAuthenticated } = state;
  const { unreadMessagesLoaded } = state.messages;

  return {
    hasNotifications,
    isAuthenticated,
    unreadMessagesLoaded,
  };
}

export default connect(mapStateToProps, {
  loadNotification: notificationAction.init,
})(NotificationBell);
