import classnames from "classnames";
import moment from "moment-timezone";
import Moment from "moment";
import { extendMoment } from "moment-range";
import PropTypes from "prop-types";
import React from "react";

import {
  COUNT_ARRAY,
  DAY_ARRAY_UNAVAILABLE,
  DEFAULT_TIME_HEIGHT,
  DEFAULT_TIME_WIDTH,
  TIMELINE_COLORS,
  UNAVAILABLE_HOUR,
} from "./constants";
import { getNewStyle } from "./utils";
import styles from "./styles.module.scss";

const momentRange = extendMoment(Moment).range;

const TimezoneHour = ({ date, firstHalf, inRange, secondHalf }) => {
  const displayDate = moment(date);
  const displayDateHour = displayDate.hours();
  const displayDateMinutes = displayDate.minutes();
  const displayDateMeridiem = displayDate.format("a");
  const displayDateFormat = displayDate.format("M/D");
  const itemContainerClasses = [styles.timelineHourItem];
  const itemClasses = [];
  if (displayDateHour === 23) {
    itemContainerClasses.push(styles.endDay);
    itemClasses.push(styles.timelineHourDisplay);
  } else if (displayDateHour > 0) {
    itemClasses.push(styles.timelineHourDisplay);
  } else {
    itemContainerClasses.push(styles.newDay);
    itemClasses.push(styles.timelineDate);
  }
  if (inRange) {
    itemClasses.push(styles.inRange);
  }
  let displayDateHourFormat = displayDate.format("h");
  if (displayDateMinutes > 0) {
    displayDateHourFormat = displayDate.format("h:mm");
  }

  return (
    <div className={classnames(...itemContainerClasses)}>
      {displayDateHour > 0 ? (
        <div
          style={{
            background: `linear-gradient(to right, ${TIMELINE_COLORS[firstHalf]} 50%, ${TIMELINE_COLORS[secondHalf]} 0%)`,
          }}
          className={classnames(...itemClasses)}
        >
          <div className={styles.timelineHour}>{displayDateHourFormat}</div>
          <span className={styles.timelineMeridiem}>{displayDateMeridiem}</span>
        </div>
      ) : (
        <div
          style={{
            background: `linear-gradient(to right, ${TIMELINE_COLORS[firstHalf]} 50%, ${TIMELINE_COLORS[secondHalf]} 0%)`,
          }}
          className={classnames(...itemClasses)}
        >
          {displayDateFormat}
        </div>
      )}
    </div>
  );
};

TimezoneHour.propTypes = {
  date: PropTypes.shape().isRequired,
  firstHalf: PropTypes.string,
  inRange: PropTypes.bool.isRequired,
  secondHalf: PropTypes.string,
};

TimezoneHour.defaultProps = {
  firstHalf: "hoursAvailable",
  secondHalf: "hoursAvailable",
};

const TimezoneHourOverlay = React.forwardRef(
  ({ inRange, onClick, onFocus, onMouseOver, onMouseUp, selected, style }, ref) => {
    const overlayClasses = [styles.hourOverlay];
    if (selected) {
      overlayClasses.push(styles.rangeSelected);
    }
    if (inRange || !selected) {
      overlayClasses.push(styles.inRange);
    }
    return (
      <div
        className={classnames(...overlayClasses)}
        onClick={onClick}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        onMouseUp={onMouseUp}
        onSelect={(e) => e.preventDefault()}
        ref={ref}
        role="presentation"
        style={style}
      />
    );
  }
);

TimezoneHourOverlay.propTypes = {
  inRange: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  style: PropTypes.shape().isRequired,
};

const TimezoneHourRangeControl = ({
  notInDate,
  onMouseDown,
  onMouseUp,
  style,
  startsBeforeDay,
  endsAfterDay,
}) => {
  const handlerStyle = { height: style.height - 4 };
  return (
    !notInDate && (
      <div className={styles.timelineOverlayControl} style={style}>
        {!startsBeforeDay && (
          <div
            className={styles.timelineOverlayControlLeft}
            onMouseDown={() => onMouseDown("left")}
            onMouseUp={onMouseUp}
            onSelect={(e) => e.preventDefault()}
            role="presentation"
            style={handlerStyle}
          />
        )}
        {!endsAfterDay && (
          <div
            className={styles.timelineOverlayControlRight}
            onMouseDown={() => onMouseDown("right")}
            onMouseUp={onMouseUp}
            onSelect={(e) => e.preventDefault()}
            role="presentation"
            style={handlerStyle}
          />
        )}
      </div>
    )
  );
};

TimezoneHourRangeControl.propTypes = {
  notInDate: PropTypes.bool.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,
  style: PropTypes.shape().isRequired,
  startsBeforeDay: PropTypes.bool.isRequired,
  endsAfterDay: PropTypes.bool.isRequired,
};

export default class TimezoneViewerTimeline extends React.PureComponent {
  constructor(props) {
    super(props);

    let selected = false;
    let selectedEndKey = 0;
    let selectedStartKey = 0;
    if (props.defaultEnd && props.defaultStart) {
      const { defaultEnd, defaultStart } = props;
      selected = true;
      selectedEndKey = defaultEnd.hours() * 2 - 1;
      selectedStartKey = defaultStart.hours() * 2;
      selectedStartKey += Math.floor(defaultStart.minutes() / 30);
      selectedEndKey += Math.floor(defaultEnd.minutes() / 30);
    }

    this.currentHour = moment().hours();
    this.hoursOverlayRef = {};
    this.state = {
      dragging: "",
      selected,
      selectedEndKey,
      selectedStartKey,
      startsBeforeDay: false,
      endsAfterDay: false,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(props) {
    if (
      (props.defaultStart && props.defaultStart !== this.props.defaultStart) ||
      (props.defaultEnd && props.defaultEnd !== this.props.defaultEnd) ||
      props.currentDate !== this.props.currentDate
    ) {
      const { currentDate, defaultEnd, defaultStart } = props;
      const currentDay = moment(currentDate);
      const nextDay = currentDay.clone().add(1, "days");
      let selectedStartKey = null;
      let selectedEndKey = null;
      let startsBeforeDay = false;
      let endsAfterDay = false;
      if (defaultStart < nextDay && defaultEnd > currentDay) {
        if (currentDay <= defaultStart && defaultStart < nextDay) {
          selectedStartKey = defaultStart.hours() * 2 + Math.floor(defaultStart.minutes() / 30);
        } else {
          startsBeforeDay = true;
          selectedStartKey = 0;
        }
        if (currentDay < defaultEnd && defaultEnd < nextDay) {
          selectedEndKey = defaultEnd.hours() * 2 - 1 + Math.floor(defaultEnd.minutes() / 30);
        } else {
          endsAfterDay = !defaultEnd.isSame(nextDay);
          selectedEndKey = 47;
        }
      }
      this.setState({
        selectedStartKey,
        selectedEndKey,
        startsBeforeDay,
        endsAfterDay,
      });
    }
  }

  getHours(member) {
    const { calendars, currentDate, currentUser, eventOccurrences } = this.props;
    const { selected, selectedStartKey, selectedEndKey } = this.state;
    let hasIndicatedAvailability = false;
    let memberAvailability = [];
    if (member?.defaultWorkDays && member?.defaultWorkHoursStart && member?.defaultWorkHoursEnd) {
      hasIndicatedAvailability = true;
      const defaultWorkDays = member.defaultWorkDays.split(",");
      const workStartHour = moment(member?.defaultWorkHoursStart)
        .tz(member?.timezoneDisplay)
        ?.hours();
      const workStartMinute = moment(member?.defaultWorkHoursStart)
        .tz(member?.timezoneDisplay)
        ?.minutes();
      const workEndHour = moment(member?.defaultWorkHoursEnd).tz(member?.timezoneDisplay)?.hours();
      const workEndMinute = moment(member?.defaultWorkHoursStart)
        .tz(member?.timezoneDisplay)
        ?.minutes();
      const noStartFirstHalf = workStartMinute > 15;
      const noStartSecondHalf = workStartMinute > 45;
      const noEndFirstHalf = workEndMinute < 15;
      const noEndSecondHalf = workEndMinute < 45;
      const dailyAvailability = COUNT_ARRAY.map((hr) => {
        const isStartHour = hr === workStartHour;
        const isEndHour = hr === workEndHour;
        const inBetween = workStartHour < hr && hr < workEndHour;
        if (isStartHour && isEndHour) {
          if (workStartMinute === workEndMinute) return {};
          return {
            firstHalf: noStartFirstHalf ? "hoursOutsideWork" : undefined,
            secondHalf: noEndSecondHalf ? "hoursOutsideWork" : undefined,
          };
        }
        if (isStartHour) {
          return {
            firstHalf: noStartFirstHalf ? "hoursOutsideWork" : undefined,
            secondHalf: noStartSecondHalf ? "hoursOutsideWork" : undefined,
          };
        }
        if (isEndHour) {
          return {
            firstHalf: noEndFirstHalf ? "hoursOutsideWork" : undefined,
            secondHalf: noEndSecondHalf ? "hoursOutsideWork" : undefined,
          };
        }
        if (inBetween) {
          return {};
        }
        return UNAVAILABLE_HOUR;
      });
      memberAvailability = defaultWorkDays.map((workday) =>
        workday === "1" ? dailyAvailability : DAY_ARRAY_UNAVAILABLE
      );
    }
    const localTimezone = currentUser?.timezoneDisplay || moment.tz.guess();
    const localCurrentDate = moment(currentDate).tz(localTimezone, true).startOf("day");
    const convertedDate = moment(localCurrentDate).tz(member.timezoneDisplay);
    const nextDay = moment(localCurrentDate).tz(member.timezoneDisplay).add(1, "days");
    const calendar = calendars[member.slug] || {};
    const dayRange = momentRange(convertedDate, nextDay);
    let dayEvents = [];
    if (calendar.occurrences && calendar.occurrences.length > 0) {
      dayEvents = calendar.occurrences.reduce((acc, oId) => {
        const occurrence = eventOccurrences[oId];
        const convertedStart = moment(occurrence.start).tz(member.timezoneDisplay);
        const convertedEnd = moment(occurrence.end).tz(member.timezoneDisplay);
        const occurrenceRange = momentRange(convertedStart, convertedEnd);
        if (dayRange.overlaps(occurrenceRange)) {
          return [
            ...acc,
            {
              isAvailable: occurrence.isAvailable,
              convertedStart,
              convertedEnd,
              isLeave: occurrence.isOutOfOffice,
              startHour: convertedStart.hours(),
              startMinute: convertedStart.minutes(),
              endHour: convertedEnd.hours(),
              endMinute: convertedEnd.minutes(),
              range: occurrenceRange,
            },
          ];
        }
        return acc;
      }, []);
    }
    const hourList = [];
    for (let h = 0; h < 24; h += 1) {
      const nextDate = moment(convertedDate).hours(convertedDate.hours() + h);
      const inRange = selected
        ? Math.floor(selectedStartKey / 2) <= h && h <= Math.floor(selectedEndKey / 2)
        : true;

      const nextDateHour = moment(convertedDate).hours(convertedDate.hours() + h + 1);
      const hourRange = momentRange(nextDate, nextDateHour);
      const curHour = nextDate.hours();
      let firstHalf;
      let secondHalf;

      if (hasIndicatedAvailability && !member.isDummy) {
        const curDay = nextDate.day();
        const hourStyle = memberAvailability[curDay][curHour];
        firstHalf = hourStyle.firstHalf;
        secondHalf = hourStyle.secondHalf;
      }
      // Only do this if there are dayEvents and both firstHalf and secondHalf are not yet set
      if (dayEvents.length > 0 && !(firstHalf && secondHalf)) {
        for (let i = 0; i < dayEvents.length; i += 1) {
          const occurrence = dayEvents[i];

          if (occurrence.range.overlaps(hourRange)) {
            const { firstHalf: newFirstHalf, secondHalf: newSecondHalf } = getNewStyle(
              { firstHalf, secondHalf },
              occurrence.isLeave ? "hoursUnavailable" : "hoursBusy",
              curHour,
              occurrence
            );
            firstHalf = newFirstHalf;
            secondHalf = newSecondHalf;
          }
          // Break out of loop if both firstHalf and secondHalf are set
          if (firstHalf && secondHalf) break;
        }
      }
      hourList.push(
        <TimezoneHour
          key={h}
          date={nextDate}
          firstHalf={firstHalf}
          inRange={inRange}
          secondHalf={secondHalf}
        />
      );
    }
    return hourList;
  }

  getOverlayHours(length) {
    const { selected, selectedEndKey, selectedStartKey } = this.state;
    const overlayHeight = length * DEFAULT_TIME_HEIGHT;

    const hourList = [];
    for (let h = 0; h < 48; h += 1) {
      const newStartKey = selectedStartKey === null ? 0 : selectedStartKey;
      const newEndKey = selectedEndKey === null ? 47 : selectedEndKey;
      const notInDate = selectedStartKey === null && selectedEndKey === null;
      let inRange = selected ? newStartKey <= h && h <= newEndKey : true;
      if (notInDate) inRange = false;
      hourList.push(
        <TimezoneHourOverlay
          key={h}
          inRange={inRange}
          onClick={() => this.handleOverlayClick(h)}
          onFocus={() => null}
          onMouseOver={() => this.handleOverlayHover(h)}
          onMouseUp={this.handleControlUp}
          ref={(el) => {
            this.hoursOverlayRef[h] = el;
          }}
          selected={selected}
          style={{ height: overlayHeight, width: DEFAULT_TIME_WIDTH / 2 }}
        />
      );
    }
    return hourList;
  }

  getOverlayControl(length) {
    const {
      selected,
      selectedStartKey,
      selectedEndKey,
      startsBeforeDay,
      endsAfterDay,
    } = this.state;
    if (!selected) return null;

    const newStartKey = selectedStartKey === null ? 0 : selectedStartKey;
    const newEndKey = selectedEndKey === null ? 47 : selectedEndKey;
    const notInDate = selectedStartKey === null && selectedEndKey === null;
    const leftRadius = startsBeforeDay ? 0 : 5;
    const rightRadius = endsAfterDay ? 0 : 5;

    const overlayHeight = length * DEFAULT_TIME_HEIGHT;
    const overlayWidth = selected ? ((newEndKey + 1 - newStartKey) * DEFAULT_TIME_WIDTH) / 2 : 0;
    const overlayLeftOffset = selected ? (newStartKey / 2) * DEFAULT_TIME_WIDTH : 0;

    return (
      <TimezoneHourRangeControl
        notInDate={notInDate}
        onMouseDown={this.handleControlDown}
        onMouseUp={this.handleControlUp}
        style={{
          height: overlayHeight,
          left: overlayLeftOffset,
          width: overlayWidth,
          borderRadius: `${leftRadius}px ${rightRadius}px ${rightRadius}px ${leftRadius}px`,
        }}
        startsBeforeDay={startsBeforeDay}
        endsAfterDay={endsAfterDay}
      />
    );
  }

  handleClearSelection = () => {
    const selection = window.getSelection ? window.getSelection() : document.selection;
    if (selection) {
      if (selection.removeAllRanges) {
        selection.removeAllRanges();
      } else if (selection.empty) {
        selection.empty();
      }
    }
  };

  handleControlDown = (direction) => {
    this.handleClearSelection();
    this.setState({ dragging: direction, selected: true });
  };

  handleControlUp = () => {
    if (this.state.dragging) {
      this.setState({ dragging: "", selected: true });
    }
  };

  handleOverlayClick = (key) => {
    const { onDateRangeChange } = this.props;
    const { selected } = this.state;
    this.handleClearSelection();
    if (selected) {
      this.setState({
        dragging: "",
        selected: false,
        selectedStartKey: 0,
        selectedEndKey: 0,
      });
      onDateRangeChange(null, null);
    } else {
      this.setState({
        dragging: "",
        selected: true,
        selectedStartKey: key,
        selectedEndKey: key,
      });
      onDateRangeChange(key, key + 1);
    }
  };

  handleOverlayHover = (key) => {
    const { onDateRangeChange } = this.props;
    const {
      dragging,
      startsBeforeDay,
      selectedStartKey,
      selectedEndKey,
      endsAfterDay,
    } = this.state;
    if (dragging === "right" && key >= selectedStartKey) {
      this.setState({ selectedEndKey: key });
      onDateRangeChange(startsBeforeDay ? null : selectedStartKey, key + 1);
    } else if (dragging === "left" && key <= selectedEndKey) {
      this.setState({ selectedStartKey: key });
      onDateRangeChange(key, endsAfterDay ? null : selectedEndKey + 1);
    }
  };

  render() {
    const { members } = this.props;

    return (
      <div className={styles.timezoneViewerTimeline} onMouseLeave={this.handleControlUp}>
        <div className={styles.timelineContainer}>
          {members.map((member) => (
            <div key={member.slug} className={styles.timeline}>
              {this.getHours(member)}
            </div>
          ))}
          <div className={styles.timelineOverlay}>{this.getOverlayHours(members.length)}</div>
          {this.getOverlayControl(members.length)}
        </div>
      </div>
    );
  }
}

TimezoneViewerTimeline.propTypes = {
  calendars: PropTypes.objectOf(PropTypes.shape()).isRequired,
  currentDate: PropTypes.string.isRequired,
  currentUser: PropTypes.shape().isRequired,
  defaultEnd: PropTypes.shape(),
  defaultStart: PropTypes.shape(),
  eventOccurrences: PropTypes.objectOf(PropTypes.shape()).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
};
