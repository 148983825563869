import classnames from "classnames";
import { omitBy, sortBy } from "lodash";
import Humanize from "humanize-plus";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

import { sidePanel, PANELS } from "actions/sidePanel";
import ScrollableContent from "components/ScrollableContent";
import MessageList from "containers/Chat/MessageList";
import {
  getCurrentRoom,
  getCurrentRoomPinnedCount,
  getCurrentRoomId,
  getCurrentProject,
} from "reducers/selectors";

import * as styles from "../../styles.module.scss";

class SidePanelPinned extends React.Component {
  render() {
    const {
      currentRoom,
      currentUser,
      hasProjectUrls,
      messages,
      messageOrdering,
      onOpenThread,
      panelId,
      pinnedCount,
      sidePanelClose,
      users,
      visible,
    } = this.props;

    const roomUsers = currentRoom.users
      ? currentRoom.users
      : currentRoom.userSlugs.map((slug) => users[slug]);

    if (!visible || panelId !== PANELS.PINNED) {
      return null;
    }

    return (
      <ScrollableContent idPrefix="pinned-sidepanel" containerClass={styles.sidePanelBoxShadow}>
        <div className={classnames(styles.sidePanel, "sidePanel")}>
          <div className={styles.heading}>
            <p>
              {pinnedCount} Pinned {Humanize.pluralize(pinnedCount, "Item")}
            </p>
            <Button
              basic
              content="Close"
              onClick={() => sidePanelClose(PANELS.PINNED, { roomId: currentRoom.id })}
              className={classnames(
                styles.actionButton,
                styles.close,
                hasProjectUrls && styles.hasProjectUrlsClose
              )}
            />
          </div>

          <MessageList
            currentUser={currentUser}
            isPinned
            messages={messages}
            messageOrdering={messageOrdering}
            onOpenThread={onOpenThread}
            room={currentRoom}
            roomUsers={roomUsers}
            users={users}
            verticalAlign="top"
          />
        </div>
      </ScrollableContent>
    );
  }
}

SidePanelPinned.propTypes = {
  currentRoom: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  hasProjectUrls: PropTypes.bool.isRequired,
  messages: PropTypes.objectOf(PropTypes.shape()).isRequired,
  messageOrdering: PropTypes.arrayOf(PropTypes.number).isRequired,
  onOpenThread: PropTypes.func.isRequired,
  panelId: PropTypes.string,
  pinnedCount: PropTypes.number.isRequired,
  sidePanelClose: PropTypes.func.isRequired,
  users: PropTypes.shape().isRequired,
  visible: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser },
    entities: { users },
    messages: { items: messagesByRooms },
    sidePanel: { panelId, visible },
  } = state;
  const currentRoom = getCurrentRoom(state);
  const currentRoomId = getCurrentRoomId(state);
  const project = getCurrentProject(state);
  const hasProjectUrls = Boolean(project && project.gitlabUrl);

  let messages = {};
  let messageOrdering = [];

  if (currentRoom && messagesByRooms && messagesByRooms[currentRoom.id]) {
    messages = omitBy(messagesByRooms[currentRoom.id], (message) => !message.pinnedBy);
    messageOrdering = sortBy(messages, "created").map((message) => message.id);
  }

  const pinnedCount = getCurrentRoomPinnedCount(state, currentRoomId);

  return {
    currentRoom,
    currentUser,
    hasProjectUrls,
    messages,
    messageOrdering,
    panelId,
    pinnedCount,
    users,
    visible,
  };
}

export default connect(mapStateToProps, {
  sidePanelClose: sidePanel.close,
})(SidePanelPinned);
