import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";

import { getAbsoluteUri } from "utils";

import * as styles from "./styles.module.scss";

export default class ImageLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      index:
        props.imageList.length > 0
          ? props.imageList.findIndex(
              (file) => getAbsoluteUri(file.file) === getAbsoluteUri(props.src)
            )
          : 0,
    };

    this.image = React.createRef();
  }

  componentDidMount() {
    this.image.current.onload = this.handleLoad;
  }

  componentWillUnmount() {
    if (this.image && this.image.current) {
      this.image.current.onload = null;
    }
  }

  getImageSrc = () => {
    const { imageList, src } = this.props;
    const { index } = this.state;
    if (imageList.length > 0) {
      const absoluteUriSrc = getAbsoluteUri(src);
      if (imageList[index]) return getAbsoluteUri(imageList[index].file) || absoluteUriSrc;
      return absoluteUriSrc;
    }
    return src;
  };

  handleLoad = () => {
    this.setState({
      loaded: true,
    });
  };

  handlePrevious = () => {
    this.setState((prevState) => ({
      index: Math.max(0, prevState.index - 1),
    }));
  };

  handleNext = () => {
    this.setState((prevState) => ({
      index: Math.min(this.props.imageList.length - 1, prevState.index + 1),
    }));
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 37 && !event.shiftKey && !event.ctrlKey) {
      event.stopPropagation();
      event.preventDefault();
      this.handlePrevious();
    } else if (event.keyCode === 39 && !event.shiftKey && !event.ctrlKey) {
      event.stopPropagation();
      event.preventDefault();
      this.handleNext();
    }
  };

  handleOpen = () => {
    document.addEventListener("keydown", this.handleKeyDown);
  };

  handleClose = () => {
    const { imageList, src } = this.props;
    document.removeEventListener("keydown", this.handleKeyDown);
    this.setState({
      index:
        imageList.length > 0
          ? imageList.findIndex((file) => getAbsoluteUri(file.file) === getAbsoluteUri(src))
          : 0,
    });
  };

  renderButtons() {
    const { imageList } = this.props;
    const { index } = this.state;

    if (imageList.length <= 1) {
      return null;
    }

    return (
      <React.Fragment>
        {index > 0 && (
          <Button
            className={styles.previous}
            icon="chevron left"
            circular
            inverted
            onClick={this.handlePrevious}
          />
        )}
        {index < imageList.length - 1 && (
          <Button
            className={styles.next}
            icon="chevron right"
            circular
            inverted
            onClick={this.handleNext}
          />
        )}
      </React.Fragment>
    );
  }

  renderImage(original) {
    const {
      alt,
      className,
      imageList,
      name,
      triggerClassName,
      inline,
      style,
      src: originalSrc,
      ...imageProps
    } = this.props;
    const { loaded } = this.state;
    const src = this.getImageSrc();

    const defaultStyle = Object.assign({}, style);
    if (!loaded) {
      defaultStyle.height = "350px";
      defaultStyle.visibility = "hidden";
    }

    // if `original` is True, render image as a div with background-image
    if (original) {
      return (
        <div className={classnames(styles.imageTrigger, styles.borders, triggerClassName)}>
          <img
            title={name}
            ref={this.image}
            src={originalSrc}
            alt={alt}
            className={!loaded ? styles.hidden : ""}
          />
          {!loaded && <Icon fitted size="large" name="image" style={{ position: "absolute" }} />}
        </div>
      );
    }

    // otherwise, render as img for the modal
    return (
      <img
        key={src}
        ref={this.image}
        alt={alt}
        className={className}
        src={original ? originalSrc : src}
        style={defaultStyle}
        {...imageProps}
      />
    );
  }

  render() {
    const { imageList, inline } = this.props;
    const { index } = this.state;
    const src = this.getImageSrc();

    return (
      <Modal
        basic
        className={styles.modal}
        closeIcon
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        style={{ display: inline ? "inline-block" : "block" }}
        trigger={this.renderImage(true)}
      >
        {this.renderButtons()}
        <Modal.Description>{this.renderImage()}</Modal.Description>
        <Modal.Actions>
          {imageList.length > 0 && (
            <div className={styles.caption}>
              {index + 1} of {imageList.length}
            </div>
          )}
          <Button
            as="a"
            compact
            content="Download"
            href={`${src}?download=1`}
            icon="download"
            rel="noopener noreferrer"
            secondary
            size="small"
            target="_blank"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ImageLoader.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  imageList: PropTypes.arrayOf(PropTypes.shape()),
  src: PropTypes.string.isRequired,
  name: PropTypes.string,
  style: PropTypes.shape(),
  triggerClassName: PropTypes.string,
};

ImageLoader.defaultProps = {
  inline: false,
  imageList: [],
  name: "",
  triggerClassName: "",
};
