import { v4 as uuidv4 } from "uuid";

import { createRequestTypes, action } from ".";

export const ADD_MEMBERS = createRequestTypes("ADD_MEMBERS");
export const COUNT_UNREAD_MESSAGES = createRequestTypes("COUNT_UNREAD_MESSAGES");
export const CREATE_ROOM = createRequestTypes("CREATE_ROOM");
export const DELETE_MESSAGE = createRequestTypes("DELETE_MESSAGE");
export const PIN_MESSAGE = createRequestTypes("PIN_MESSAGE");
export const UNPIN_MESSAGE = createRequestTypes("UNPIN_MESSAGE");
export const DISCONNECT_CALL = createRequestTypes("DISCONNECT_CALL");
export const EDIT_MESSAGE = createRequestTypes("EDIT_MESSAGE");
export const GET_ATTACHMENTS = createRequestTypes("GET_ATTACHMENTS");
export const GET_MESSAGES = createRequestTypes("GET_MESSAGES");
export const GET_MESSAGE = createRequestTypes("GET_MESSAGE");
export const GET_PINNED_MESSAGES = createRequestTypes("GET_PINNED_MESSAGES");
export const GET_SEARCH = createRequestTypes("GET_SEARCH");
export const GET_THREAD = createRequestTypes("GET_THREAD");
export const GET_ROOM = createRequestTypes("GET_ROOM");
export const GET_ROOMS = createRequestTypes("GET_ROOMS");
export const INVITATION_DELETE = createRequestTypes("INVITATION_DELETE");
export const INVITATION_RESEND = createRequestTypes("INVITATION_RESEND");
export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const NOTIFICATION_CLEAR = "NOTIFICATION_CLEAR";
export const PERFORM_ACTION = createRequestTypes("PERFORM_ACTION");
export const POLL_CREATE = createRequestTypes("POLL_CREATE");
export const POLL_DELETE = createRequestTypes("POLL_DELETE");
export const POLL_DETAIL = createRequestTypes("POLL_DETAIL");
export const POLL_LIST = createRequestTypes("POLL_LIST");
export const POLL_DELEGATE = createRequestTypes("POLL_DELEGATE");
export const POLL_MULTIPLE_VOTE = createRequestTypes("POLL_MULTIPLE_VOTE");
export const POLL_SEARCH_SKILLS = createRequestTypes("POLL_SEARCH_SKILLS");
export const POLL_UPDATE = createRequestTypes("POLL_UPDATE");
export const PUSHER_CALL_STARTED = createRequestTypes("PUSHER_CALL_STARTED");
export const PUSHER_RECEIVE_MESSAGE = createRequestTypes("PUSHER_RECEIVE_MESSAGE");
export const PUSHER_SEND_MESSAGE = createRequestTypes("PUSHER_SEND_MESSAGE");
export const PUSHER_USER_KICKED = "PUSHER_USER_KICKED";
export const PUSHER_USER_TYPING = {
  INIT: "PUSHER_USER_TYPING_INIT",
  START: "PUSHER_USER_TYPING_START",
  END: "PUSHER_USER_TYPING_END",
};
export const READ_MESSAGES = createRequestTypes("READ_MESSAGES");
export const UPDATE_READ_MESSAGES = createRequestTypes("UPDATE_READ_MESSAGES");
export const RENAME_ROOM = createRequestTypes("RENAME_ROOM");
export const ROOM_CHANGE = "ROOM_CHANGE";
export const ROOM_HIDE = createRequestTypes("ROOM_HIDE");
export const ROOM_MEMBERSHIP_DETAIL = createRequestTypes("ROOM_MEMBERSHIP_DETAIL");
export const ROOM_SET_LAST = "ROOM_SET_LAST";
export const ROOM_UPDATE = createRequestTypes("ROOM_UPDATE");
export const PROJECT_HOME_ROOM_SET_MAPPING = "PROJECT_HOME_ROOM_SET_MAPPING";
export const ROOM_VISIBLE = "ROOM_VISIBLE";
export const START_CALL = createRequestTypes("START_CALL");
export const SLASH_COMMAND = createRequestTypes("SLASH_COMMAND");
export const ROOM_MEMBERSHIP_UPDATE = createRequestTypes("ROOM_MEMBERSHIP_UPDATE");
export const MESSAGE_BOX_CACHE = "MESSAGE_BOX_CACHE";
export const REACTIONS = createRequestTypes("REACTIONS");
export const REACTIONS_ADD = createRequestTypes("REACTIONS_ADD");
export const REACTIONS_DELETE = createRequestTypes("REACTIONS_DELETE");
export const RESET_JUMP = "RESET_JUMP";
export const TIMEZONE_TOGGLE = "TIMEZONE_TOGGLE";
export const ROOM_CALENDAR = createRequestTypes("ROOM_CALENDAR");
export const ROOM_MEMBER_CALENDAR = createRequestTypes("ROOM_MEMBER_CALENDAR");
export const ROOM_MEETING_LIST = createRequestTypes("ROOM_MEETING_LIST");
export const ROOM_MEETING_CREATE = createRequestTypes("ROOM_MEETING_CREATE");
export const ROOM_MEETING_UPDATE = createRequestTypes("ROOM_MEETING_UPDATE");
export const ROOM_MEETING_DELETE = createRequestTypes("ROOM_MEETING_DELETE");
export const ROOM_MEETING_DETAIL = createRequestTypes("ROOM_MEETING_DETAIL");
export const ROOM_MEETING_JOIN = createRequestTypes("ROOM_MEETING_JOIN");

export const SELECTED_CHANNEL_TAB = "SELECTED_CHANNEL_TAB";
export const CHANNEL_TABS = {
  FEED: "FEED",
  DISCUSSIONS: "DISCUSSIONS",
  POLLS: "POLLS",
  EVENTS: "EVENTS",
  CLIENT_FEEDBACK: "CLIENT_FEEDBACK",
  SETTINGS: "SETTINGS",
  PLANNING: "PLANNING",
};

export const makeRoomVisible = (id) => action(ROOM_VISIBLE, { id });
export const notificationAdd = (roomId, message) => action(NOTIFICATION_ADD, { message, roomId });
export const notificationClear = (roomId) => action(NOTIFICATION_CLEAR, { roomId });
export const roomChange = (projectId, roomId) => action(ROOM_CHANGE, { projectId, roomId });
export const setLastRoom = (roomId) => action(ROOM_SET_LAST, { roomId });
export const setProjectHomeRoomMapping = (mapping) =>
  action(PROJECT_HOME_ROOM_SET_MAPPING, { mapping });

export const setChannelTab = (roomId, tabId) => action(SELECTED_CHANNEL_TAB, { roomId, tabId });

export const roomHide = {
  request: (id) => action(ROOM_HIDE.REQUEST, { id }),
  success: () => action(ROOM_HIDE.SUCCESS),
  failure: (error) => action(ROOM_HIDE.FAILURE, { error }),
};

export const addMembers = {
  request: ({ emails, roomId, userSlugs }) =>
    action(ADD_MEMBERS.REQUEST, { emails, roomId, userSlugs }),
  success: (roomId, response) => action(ADD_MEMBERS.SUCCESS, { response, roomId }),
  failure: (roomId, errorDetails) => action(ADD_MEMBERS.FAILURE, { errorDetails, roomId }),
};

export const countUnreadMessages = {
  request: () => action(COUNT_UNREAD_MESSAGES.REQUEST),
  success: (response) => action(COUNT_UNREAD_MESSAGES.SUCCESS, { response }),
  failure: (error) => action(COUNT_UNREAD_MESSAGES.FAILURE, { error }),
};

export const createRoom = {
  request: ({ teamSlug, token, userSlugs, name }) =>
    action(CREATE_ROOM.REQUEST, { teamSlug, token, userSlugs, name }),
  success: (response) => action(CREATE_ROOM.SUCCESS, { response }),
  failure: (error) => action(CREATE_ROOM.FAILURE, { error }),
};

export const disconnectCall = {
  request: ({ callCode, token, callerId }) =>
    action(DISCONNECT_CALL.REQUEST, { callCode, token, callerId }),
  success: (callCode, response) => action(DISCONNECT_CALL.SUCCESS, { callCode, response }),
  failure: (callCode, error) => action(DISCONNECT_CALL.FAILURE, { callCode, error }),
};

export const getAttachments = {
  request: ({ id, token, url }) => action(GET_ATTACHMENTS.REQUEST, { id, token, url }),
  success: (id, response) => action(GET_ATTACHMENTS.SUCCESS, { id, response }),
  failure: (id, error) => action(GET_ATTACHMENTS.FAILURE, { id, error }),
};

export const getMessages = {
  request: ({ id, jump, token, url }) => action(GET_MESSAGES.REQUEST, { id, jump, token, url }),
  success: (id, jump, response) => action(GET_MESSAGES.SUCCESS, { id, jump, response }),
  failure: (id, error) => action(GET_MESSAGES.FAILURE, { id, error }),
};

export const getMessage = {
  request: ({ id, mid, token }) => action(GET_MESSAGE.REQUEST, { id, mid, token }),
};

export const getPinnedMessages = {
  request: ({ id, token }) => action(GET_PINNED_MESSAGES.REQUEST, { id, token }),
  success: (id, response) => action(GET_PINNED_MESSAGES.SUCCESS, { id, response }),
  failure: (id, error) => action(GET_PINNED_MESSAGES.FAILURE, { id, error }),
};

export const getThread = {
  request: ({ id, messageId }) => action(GET_THREAD.REQUEST, { id, messageId }),
  success: (id, messageId, response) => action(GET_THREAD.SUCCESS, { id, messageId, response }),
  failure: (id, messageId, error) => action(GET_THREAD.FAILURE, { id, messageId, error }),
};

export const getSearch = {
  request: ({ id, search, token, sort }) => action(GET_SEARCH.REQUEST, { id, search, token, sort }),
  success: (id, response, sort) => action(GET_SEARCH.SUCCESS, { id, response, sort }),
  failure: (id, error) => action(GET_SEARCH.FAILURE, { id, error }),
};

export const getRoom = {
  request: ({ id, token, room }) => action(GET_ROOM.REQUEST, { id, token, room }),
  success: (response) => action(GET_ROOM.SUCCESS, { response }),
  failure: (error) => action(GET_ROOM.FAILURE, { error }),
};

export const getRooms = {
  request: ({ token, url }) => action(GET_ROOMS.REQUEST, { token, url }),
  success: (response) => action(GET_ROOMS.SUCCESS, { response }),
  failure: (error) => action(GET_ROOMS.FAILURE, { error }),
};

export const invitationDelete = {
  request: (invitation, resolve, reject) =>
    action(INVITATION_DELETE.REQUEST, { invitation, resolve, reject }),
  success: (invitation, response) => action(INVITATION_DELETE.SUCCESS, { invitation, response }),
  failure: (invitation, errorDetails) =>
    action(INVITATION_DELETE.FAILURE, { invitation, errorDetails }),
};

export const invitationResend = {
  request: ({ invitation, roomId, token }) =>
    action(INVITATION_RESEND.REQUEST, { invitation, roomId, token }),
  success: (roomId, invitation, response) =>
    action(INVITATION_RESEND.SUCCESS, { invitation, response, roomId }),
  failure: (roomId, invitation, errorDetails) =>
    action(INVITATION_RESEND.FAILURE, { errorDetails, invitation, roomId }),
};

export const pusherCallStarted = {
  request: ({ callCode }) => action(PUSHER_CALL_STARTED.REQUEST, { callCode }),
};

export const pusherReceiveMessage = {
  request: ({ data, user }) => action(PUSHER_RECEIVE_MESSAGE.REQUEST, { data, user }),
  success: (id, response) => action(PUSHER_RECEIVE_MESSAGE.SUCCESS, { id, response }),
  failure: (id, error) => action(PUSHER_RECEIVE_MESSAGE.FAILURE, { id, error }),
};

export const pusherSendMessage = {
  request: ({ content, files, id, token, user, sendId, messageId, root }) =>
    action(PUSHER_SEND_MESSAGE.REQUEST, {
      content,
      id,
      token,
      user,
      sendId: sendId || uuidv4(),
      files,
      messageId,
      root,
    }),
  success: (id, response) => action(PUSHER_SEND_MESSAGE.SUCCESS, { id, response }),
  failure: (id, sendId, error) => action(PUSHER_SEND_MESSAGE.FAILURE, { id, sendId, error }),
};

export const pusherUserKicked = ({ currentUser, roomId, userSlugs }) =>
  action(PUSHER_USER_KICKED, { currentUser, roomId, userSlugs });

export const pusherUserTyping = {
  init: ({ id }) => action(PUSHER_USER_TYPING.INIT, { id }),
  start: ({ id, author }) => action(PUSHER_USER_TYPING.START, { id, author }),
  end: ({ id, author }) => action(PUSHER_USER_TYPING.END, { id, author }),
};

export const updateReadMessages = {
   request: ({ id, token }) => action(UPDATE_READ_MESSAGES.REQUEST, { id, token }),
};

export const readMessages = {
  request: ({ id, token }) => action(READ_MESSAGES.REQUEST, { id, token }),
  success: (id, unread) => action(READ_MESSAGES.SUCCESS, { id, unread }),
  failure: (id, error) => action(READ_MESSAGES.FAILURE, { id, error }),
};

export const editMessage = {
  request: ({ id, messageId, content }) => action(EDIT_MESSAGE.REQUEST, { id, messageId, content }),
  success: (id, messageId) => action(EDIT_MESSAGE.SUCCESS, { id, messageId }),
  failure: (id, messageId, error) => action(EDIT_MESSAGE.FAILURE, { id, error, messageId }),
};

export const deleteMessage = {
  request: ({ id, messageId }) => action(DELETE_MESSAGE.REQUEST, { id, messageId }),
  success: (id, messageId) => action(DELETE_MESSAGE.SUCCESS, { id, messageId }),
  failure: (id, messageId, error) => action(DELETE_MESSAGE.FAILURE, { id, error, messageId }),
};

export const pinMessage = {
  request: ({ id, messageId }) => action(PIN_MESSAGE.REQUEST, { id, messageId }),
  success: (id, messageId) => action(PIN_MESSAGE.SUCCESS, { id, messageId }),
  failure: (id, messageId, error) => action(PIN_MESSAGE.FAILURE, { id, error, messageId }),
};

export const unpinMessage = {
  request: ({ id, messageId }) => action(UNPIN_MESSAGE.REQUEST, { id, messageId }),
  success: (id, messageId) => action(UNPIN_MESSAGE.SUCCESS, { id, messageId }),
  failure: (id, messageId, error) => action(UNPIN_MESSAGE.FAILURE, { id, error, messageId }),
};

export const startCall = {
  request: ({ callCode, displayName, token }) =>
    action(START_CALL.REQUEST, { callCode, displayName, token }),
  success: (callCode, response) => action(START_CALL.SUCCESS, { callCode, response }),
  failure: (callCode, error) => action(START_CALL.FAILURE, { callCode, error }),
};

export const renameRoom = {
  init: (id, name) => action(RENAME_ROOM.INIT, { id, name }),
  request: (id, name) => action(RENAME_ROOM.REQUEST, { id, name }),
  success: (id, response) => action(RENAME_ROOM.SUCCESS, { id, response }),
  failure: (id, error) => action(RENAME_ROOM.FAILURE, { id, error }),
};

export const roomMembershipDetail = {
  success: (membershipId, response) =>
    action(ROOM_MEMBERSHIP_DETAIL.SUCCESS, { membershipId, response }),
};

export const roomUpdate = {
  request: (id, fields, resolve, reject) =>
    action(ROOM_UPDATE.REQUEST, { id, fields, resolve, reject }),
  success: (id, response) => action(ROOM_UPDATE.SUCCESS, { id, response }),
  failure: (id, error) => action(ROOM_UPDATE.FAILURE, { id, error }),
};

export const performAction = {
  init: (action_, data) => action(PERFORM_ACTION.INIT, { action: action_, data }),
  request: () => action(PERFORM_ACTION.REQUEST),
  success: (response) => action(PERFORM_ACTION.SUCCESS, { response }),
  failure: (error) => action(PERFORM_ACTION.FAILURE, { error }),
};

export const pollCreate = {
  request: (roomId, poll, resolve, reject) =>
    action(POLL_CREATE.REQUEST, { roomId, poll, resolve, reject }),
  success: (roomId, response) => action(POLL_CREATE.SUCCESS, { response, roomId }),
  failure: (roomId, errorDetails) => action(POLL_CREATE.FAILURE, { errorDetails, roomId }),
};

export const pollDelete = {
  request: (poll) => action(POLL_DELETE.REQUEST, { poll }),
  success: (poll, response) => action(POLL_DELETE.SUCCESS, { poll, response }),
  failure: (poll, errorDetails) => action(POLL_DELETE.FAILURE, { poll, errorDetails }),
};

export const pollDetail = {
  request: (pollId, fromCurrentUser) => action(POLL_DETAIL.REQUEST, { pollId, fromCurrentUser }),
  success: (fromCurrentUser, response) =>
    action(POLL_DETAIL.SUCCESS, { fromCurrentUser, response }),
  failure: (fromCurrentUser, error) => action(POLL_DETAIL.FAILURE, { fromCurrentUser, error }),
};

export const pollList = {
  request: ({ isArchived, roomId, token }) =>
    action(POLL_LIST.REQUEST, { isArchived, roomId, token }),
  success: (roomId, response) => action(POLL_LIST.SUCCESS, { response, roomId }),
  failure: (roomId, error) => action(POLL_LIST.FAILURE, { error, roomId }),
};

export const pollDelegate = {
  request: (poll, delegateSlug) => action(POLL_DELEGATE.REQUEST, { poll, delegateSlug }),
  success: (poll, response) => action(POLL_DELEGATE.SUCCESS, { poll, response }),
  failure: (poll, error) => action(POLL_DELEGATE.ERROR, { poll, error }),
};

export const pollMultipleVote = {
  request: (poll, votes) => action(POLL_MULTIPLE_VOTE.REQUEST, { poll, votes }),
  success: (poll, response) => action(POLL_MULTIPLE_VOTE.SUCCESS, { poll, response }),
  failure: (poll, error) => action(POLL_MULTIPLE_VOTE.FAILURE, { poll, error }),
};

export const pollSearchSkills = {
  request: (question) => action(POLL_SEARCH_SKILLS.REQUEST, { question }),
  success: (response) => action(POLL_SEARCH_SKILLS.SUCCESS, { response }),
  failure: (error) => action(POLL_SEARCH_SKILLS.FAILURE, { error }),
};

export const pollUpdate = {
  request: (poll, resolve, reject) => action(POLL_UPDATE.REQUEST, { poll, resolve, reject }),
  success: (response) => action(POLL_UPDATE.SUCCESS, { response }),
  failure: (errorDetails) => action(POLL_UPDATE.FAILURE, { errorDetails }),
};

export const reactions = {
  request: ({ token, id, messageId }) => action(REACTIONS.REQUEST, { token, id, messageId }),
  success: (response, id, messageId) => action(REACTIONS.SUCCESS, { response, id, messageId }),
  failure: (error) => action(REACTIONS.FAILURE, { error }),
};

export const reactionsAdd = {
  request: ({ token, id, messageId, emoji, user }) =>
    action(REACTIONS_ADD.REQUEST, { token, id, messageId, emoji, user }),
  success: (response) => action(REACTIONS_ADD.SUCCESS, { response }),
  failure: (error) => action(REACTIONS_ADD.FAILURE, { error }),
};

export const reactionsDelete = {
  request: ({ token, id, messageId, emoji, user }) =>
    action(REACTIONS_DELETE.REQUEST, { token, id, messageId, emoji, user }),
  success: (response) => action(REACTIONS_DELETE.SUCCESS, { response }),
  failure: (error) => action(REACTIONS_DELETE.FAILURE, { error }),
};

export const resetJump = ({ id }) => action(RESET_JUMP, { id });

export const slashCommand = {
  request: ({ token, id, command, data, onSuccess, onFailure }) =>
    action(SLASH_COMMAND.REQUEST, {
      token,
      id,
      command,
      data,
      onSuccess,
      onFailure,
    }),
  success: (id, response) => action(SLASH_COMMAND.SUCCESS, { id, response }),
  failure: (id, error, errorDetails) => action(SLASH_COMMAND.FAILURE, { id, error, errorDetails }),
};

export const LAST_ROOM_KEY = "lastProjectRoom";
export const PROJECT_HOME_ROOM_MAPPING_KEY = "projectHomeRooms";

export const roomMembershipUpdate = {
  request: ({ id, roomId, token, data }) =>
    action(ROOM_MEMBERSHIP_UPDATE.REQUEST, {
      id,
      roomId,
      token,
      data,
    }),
  success: (id, roomId, response) =>
    action(ROOM_MEMBERSHIP_UPDATE.SUCCESS, { id, roomId, response }),
  failure: (id, error, errorDetails) =>
    action(ROOM_MEMBERSHIP_UPDATE.FAILURE, { id, error, errorDetails }),
};

export const messageBoxCache = (cacheId, message) =>
  action(MESSAGE_BOX_CACHE, { cacheId, message });

export const roomCalendar = {
  request: (id, params = {}, resolve = null, reject = null) =>
    action(ROOM_CALENDAR.REQUEST, { id, resolve, reject, params }),
  success: (response) => action(ROOM_CALENDAR.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(ROOM_CALENDAR.FAILURE, { error, errorDetails }),
};

export const roomMemberCalendar = {
  request: (id, params = {}) => action(ROOM_MEMBER_CALENDAR.REQUEST, { id, params }),
  success: (response) => action(ROOM_MEMBER_CALENDAR.SUCCESS, { response }),
  failure: ({ error, errorDetails }) =>
    action(ROOM_MEMBER_CALENDAR.FAILURE, { error, errorDetails }),
};

export const roomMeetingList = {
  request: ({ roomId }) => action(ROOM_MEETING_LIST.REQUEST, { roomId }),
  success: (response) => action(ROOM_MEETING_LIST.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(ROOM_MEETING_LIST.FAILURE, { error, errorDetails }),
};

export const roomMeetingCreate = {
  request: (id, data, resolve, reject, params = {}, userSlug = null) =>
    action(ROOM_MEETING_CREATE.REQUEST, { id, data, resolve, reject, params, userSlug }),
  success: (response) => action(ROOM_MEETING_CREATE.SUCCESS, { response }),
  failure: ({ error, errorDetails }) =>
    action(ROOM_MEETING_CREATE.FAILURE, { error, errorDetails }),
};

export const roomMeetingUpdate = {
  request: (rid, oid, data, resolve, reject, params = {}) =>
    action(ROOM_MEETING_UPDATE.REQUEST, { rid, oid, data, resolve, reject, params }),
  success: (response) => action(ROOM_MEETING_UPDATE.SUCCESS, { response }),
  failure: ({ error, errorDetails }) =>
    action(ROOM_MEETING_UPDATE.FAILURE, { error, errorDetails }),
};

export const roomMeetingDelete = {
  request: (rid, oid, resolve, reject, emailNotif) =>
    action(ROOM_MEETING_DELETE.REQUEST, { rid, oid, resolve, reject, emailNotif }),
  success: (response) => action(ROOM_MEETING_DELETE.SUCCESS, { response }),
  failure: ({ error, errorDetails }) =>
    action(ROOM_MEETING_DELETE.FAILURE, { error, errorDetails }),
};

export const roomMeetingDetail = {
  request: ({ fromCurrentUser, rid, oid }) =>
    action(ROOM_MEETING_DETAIL.REQUEST, { fromCurrentUser, rid, oid }),
  success: (fromCurrentUser, response, oid) =>
    action(ROOM_MEETING_DETAIL.SUCCESS, { fromCurrentUser, response, oid }),
  failure: ({ error, errorDetails, fromCurrentUser }) =>
    action(ROOM_MEETING_DETAIL.FAILURE, { error, errorDetails, fromCurrentUser }),
};

export const roomMeetingJoin = {
  request: ({ join, rid, event }) => action(ROOM_MEETING_JOIN.REQUEST, { join, rid, event }),
  success: (response, event) => action(ROOM_MEETING_JOIN.SUCCESS, { response, event }),
  failure: ({ error, errorDetails, event }) =>
    action(ROOM_MEETING_JOIN.FAILURE, { error, errorDetails, event }),
};
