import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import UserAvatar from "components/UserAvatar";

import { KeyCodes } from "utils/constants";

import * as styles from "./styles.module.scss";

export default class MemberList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: -1,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    const { matches } = this.props;

    let selected;
    switch (e.key) {
      case KeyCodes.ENTER[0]: {
        const { item } = Object.assign({}, matches[this.state.selected]);
        const result = this.onSelect(item);
        if (result) e.preventDefault();
        return;
      }

      case KeyCodes.DOWN[0]:
        selected = this.state.selected + 1;
        e.preventDefault();
        break;

      case KeyCodes.UP[0]:
        selected = this.state.selected - 1;
        e.preventDefault();
        break;

      default:
        return;
    }

    if (selected >= matches.length) selected = -1;
    if (selected < -1) selected = matches.length - 1;
    this.setState({ selected });
  };

  onSelect = (item) => {
    const { onClick } = this.props;

    if (item) {
      onClick(item);
      this.setState({ selected: -1 });
      return item;
    }

    return null;
  };

  render() {
    const { matches } = this.props;
    const { selected } = this.state;

    return (
      <ul className={classnames(styles.options)}>
        {matches.map(({ item }, index) => (
          <li
            key={item.slug}
            className={classnames(
              styles.manageMemberListItem,
              styles.clickable,
              index === selected && styles.selected
            )}
            onKeyPress={() => this.onSelect(item)}
            tabIndex="-1"
            role="menuitem"
            onClick={() => this.onSelect(item)}
          >
            <UserAvatar
              avatar={item.avatarOrDefault}
              float="left"
              online={item.online}
              size="30"
              bgColor={item.avatarBgcolor}
              fgColor={item.avatarColor}
              displayName={item.displayName}
            />
            <div
              className={styles.manageMemberListItemName}
              title={`${item.displayName}${item.email ? ` (${item.email})` : ""}`}
            >
              {item.displayName}
              {item.email ? ` (${item.email})` : ""}
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

MemberList.propTypes = {
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
      }),
    })
  ),
  onClick: PropTypes.func.isRequired,
};
