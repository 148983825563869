import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Form, Modal } from "semantic-ui-react";

import MessageBox from "./MessageBox";
import styles from "./styles.module.scss";

export default class MessageBoxEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      deleteModal: false,
    };
  }

  handleShowEditModal = () => {
    this.setState({
      editModal: true,
    });
  };

  handleHideEditModal = () => {
    this.setState({ editModal: false });
  };

  handleShowDeleteModal = () => {
    this.setState({ deleteModal: true });
  };

  handleHideDeleteModal = () => {
    this.setState({ deleteModal: false });
  };

  handleDelete = () => {
    const { room } = this.props;
    (this.props.onDeleteModalClose || this.handleHideDeleteModal)();
    (this.props.onEditModalClose || this.handleHideEditModal)();
    this.props.deleteMessage({ id: room.id, messageId: this.props.message.id });
  };

  handleClose = () => {
    (this.props.onEditModalClose || this.handleHideEditModal)();
  };

  render() {
    const editOpen = this.props.editOpen !== undefined ? this.props.editOpen : this.state.editModal;
    const deleteOpen =
      this.props.deleteOpen !== undefined ? this.props.deleteOpen : this.state.deleteModal;

    return (
      <>
        <Modal
          as={Form}
          open={editOpen}
          onOpen={this.handleShowEditModal}
          onClose={this.handleClose}
          trigger={this.props.editTrigger}
          closeOnDimmerClick={false}
        >
          <Modal.Header className={classnames(styles.messageBoxEditHeader)}>
            Edit Message
          </Modal.Header>
          <Modal.Content className={classnames(styles.messageBoxEditContent)}>
            <MessageBox
              key={`message-${this.props.message.id}`}
              message={this.props.message}
              room={this.props.room}
              onEmptyMessage={this.props.onEmptyMessage || this.handleShowDeleteModal}
              onMessageSent={this.handleClose}
              onDiscard={this.handleClose}
              height={3}
            />
          </Modal.Content>
        </Modal>
        <Modal
          open={deleteOpen}
          onOpen={this.handleShowDeleteModal}
          onClose={this.props.onDeleteModalClose || this.handleHideDeleteModal}
          trigger={this.props.deleteTrigger}
          className={styles.deleteModal}
          closeIcon
        >
          <Modal.Content className={styles.modalContent}>
            <p className={styles.heading}>Delete message</p>
            <p className={styles.content}>You are about to permanently delete this message.</p>
            <div className={styles.actionsContainer}>
              <Button
                className={classnames(styles.action, styles.delete)}
                onClick={this.handleDelete}
              >
                Yes, delete message
              </Button>
              <Button
                className={classnames(styles.action, styles.cancel)}
                onClick={this.props.onDeleteModalClose || this.handleHideDeleteModal}
              >
                Cancel, do not delete
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

MessageBoxEdit.propTypes = {
  deleteOpen: PropTypes.bool,
  deleteMessage: PropTypes.func.isRequired,
  editOpen: PropTypes.bool,
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  onDeleteModalClose: PropTypes.func,
  onEditModalClose: PropTypes.func,
  onEmptyMessage: PropTypes.func,
  room: PropTypes.shape().isRequired,
  deleteTrigger: PropTypes.node,
  editTrigger: PropTypes.node,
};
