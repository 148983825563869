import {
  HEALTH_FORM,
  HEALTH_FORM_DATA,
  HEALTH_FORM_DATA_UPDATE,
  HEALTH_SUMMARY_AGGREGATED,
  HEALTH_PERSONAL,
  HEALTH_GUIDE,
  RESET_HEALTH_FORM_DATA_UPDATE,
} from "actions/health";
import { createReducerType } from "./base";

const INITIAL_STATE = {
  loading: false,
  success: false,
  failure: false,
  errorDetails: null,
};

export function createFormDataUpdateReducer() {
  return (state = INITIAL_STATE, action) => {
    const { type } = action;

    switch (type) {
      case HEALTH_FORM.REQUEST:
      case HEALTH_FORM_DATA.REQUEST:
        return {
          ...state,
          success: false,
        };
      case HEALTH_FORM_DATA_UPDATE.REQUEST:
        return {
          ...state,
          loading: true,
          success: false,
          failure: false,
          errorDetails: null,
        };
      case HEALTH_FORM_DATA_UPDATE.SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          failure: false,
          errorDetails: null,
        };
      case HEALTH_FORM_DATA_UPDATE.FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          failure: true,
          errorDetails: { ...action.errorDetails },
        };
      case RESET_HEALTH_FORM_DATA_UPDATE:
        return INITIAL_STATE;
      default:
        return state;
    }
  };
}

export function createAggregatedReducer(entity) {
  return (
    state = {
      loading: false,
      success: true,
      failure: false,
      errorDetails: null,
      items: {},
    },
    action
  ) => {
    const { type } = action;

    switch (type) {
      case entity.REQUEST:
        return { ...state, loading: true, success: false, failure: false, errorDetails: null };
      case entity.SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          failure: false,
          errorDetails: null,
          items: { ...state.items, ...action.response },
        };
      case entity.FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          failure: true,
          errorDetails: { ...action.errorDetails },
        };
      default:
        return state;
    }
  };
}

export const healthForm = createReducerType(HEALTH_FORM);
export const healthFormData = createReducerType(HEALTH_FORM_DATA, { success: false });
export const healthFormDataUpdate = createFormDataUpdateReducer();
export const healthGuide = createReducerType(HEALTH_GUIDE);
export const healthSummaryAggregated = createAggregatedReducer(HEALTH_SUMMARY_AGGREGATED);
export const healthPersonal = createAggregatedReducer(HEALTH_PERSONAL);
