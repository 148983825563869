import React from "react";
import { Button } from "semantic-ui-react";
import config from "config";

import styles from "./styles.module.scss";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/google/login/`;

const GoogleButton = (props) => (
  <Button
    className={styles.google}
    fluid
    onClick={() => (window.location.href = LOGIN_URL)}
    {...props}
  >
    <img src="/google_signin_button.svg" alt="google" />
    Google
  </Button>
);

export default GoogleButton;
