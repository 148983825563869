import { call, put, takeLatest } from "redux-saga/effects";
import { api, token as tokenService } from "services";
import * as schedule from "actions/schedule";
import * as log from "actions/log";

function* getWeeklyAssignments() {
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.getWeeklyAssignments, token);
  if (response) {
    yield put(schedule.getWeeklyAssignments.success(response));
  } else if (error) {
    yield put(schedule.getWeeklyAssignments.failure(error));
  }
}

function* createWeeklyAssignment({ data }) {
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.createWeeklyAssignment, token, data);
  if (response) {
    yield put(schedule.createWeeklyAssignment.success(response));
  } else if (error) {
    yield put(schedule.createWeeklyAssignment.failure(error));
  }
}

function* updateWeeklyAssignment({ id, data }) {
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.updateWeeklyAssignment, token, id, data);
  if (response) {
    yield put(schedule.updateWeeklyAssignment.success(response));
  } else if (error) {
    yield put(schedule.updateWeeklyAssignment.failure(error));
  }
}

function* getWeeklyAssignmentHistory(request) {
  const { weeklyAssignmentId, url } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(
    api.getWeeklyAssignmentHistory,
    token,
    weeklyAssignmentId,
    url
  );
  if (response) {
    yield put(log.getWeeklyAssignmentHistory.success(weeklyAssignmentId, response));
  } else if (error) {
    yield put(log.getWeeklyAssignmentHistory.failure(weeklyAssignmentId, errorDetails));
  }
}

function* setAssignmentAccess({ data, resolve, reject }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.setAssignmentAccess, token, data);
  if (response) {
    yield put(schedule.setAssignmentAccess.success(response));
    if (resolve) resolve();
  } else if (error) {
    yield put(schedule.setAssignmentAccess.failure(error));
    if (reject) reject(errorDetails);
  }
}

export function* watchWeeklyAssignments() {
  yield takeLatest(schedule.GET_WEEKLY_ASSIGNMENTS.REQUEST, getWeeklyAssignments);
  yield takeLatest(schedule.CREATE_WEEKLY_ASSIGNMENT.REQUEST, createWeeklyAssignment);
  yield takeLatest(schedule.UPDATE_WEEKLY_ASSIGNMENT.REQUEST, updateWeeklyAssignment);
  yield takeLatest(log.GET_WEEKLY_ASSIGNMENT_HISTORY.REQUEST, getWeeklyAssignmentHistory);
  yield takeLatest(schedule.SET_ASSIGNMENT_ACCESS.REQUEST, setAssignmentAccess);
}
