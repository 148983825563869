import { call, takeLatest } from "redux-saga/effects";
import { api, token as tokenService } from "services";
import * as notification from "actions/notification";

import { fetchEntityQS, pushEntity } from "./base";

const fetchNotification = fetchEntityQS.bind(
  null,
  notification.notification,
  api.fetchNotifications
);

const fetchPaginatedNotification = fetchEntityQS.bind(
  null,
  notification.paginatedNotification,
  api.fetchNotifications
);

const pushMarkReadNotification = pushEntity.bind(
  null,
  notification.markReadNotification,
  api.postMarkReadNotification
);
const pushNotification = pushEntity.bind(
  null,
  notification.updateNotification,
  api.postNotification
);

export function* loadNotification({ query }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchNotification, true, token, null, null, query);
}

export function* loadPaginatedNotification({ query }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchPaginatedNotification, true, token, null, null, query);
}

export function* markReadNotification() {
  const token = yield tokenService.getAuthToken();
  yield call(pushMarkReadNotification, true, token);
}

export function* postNotification({ id, data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushNotification, true, token, id, null, data);
}

export function* watchNotification() {
  yield takeLatest(notification.NOTIFICATION.INIT, loadNotification);
  yield takeLatest(notification.PAGINATED_NOTIFICATION.INIT, loadPaginatedNotification);
  yield takeLatest(notification.MARK_READ_NOTIFICATION.INIT, markReadNotification);
  yield takeLatest(notification.UPDATE_NOTIFICATION.INIT, postNotification);
}
