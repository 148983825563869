import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import UserAvatar from "components/UserAvatar";
import ContactModal from "components/ContactModal";
import { createRoom } from "actions/chat";

import styles from "./styles.module.scss";

class MessageListItemEventGuestItem extends React.PureComponent {
  render() {
    const { accepted, declined, optional, user } = this.props;
    return (
      <div className={styles.messageListItemEventGuestItem}>
        {user.displayName && (
          <UserAvatar
            avatar={user.avatarOrDefault}
            size="26"
            bgColor={user.avatarBgcolor}
            fgColor={user.avatarColor}
            displayName={user.displayName}
            check={accepted}
            close={declined}
          />
        )}
        <ContactModal user={user} propStyle={styles.messageListItemEventGuestItemName} />
        {optional && (
          <span className={styles.messageListItemEventGuestOptionalText}>(Optional)</span>
        )}
      </div>
    );
  }
}

MessageListItemEventGuestItem.propTypes = {
  accepted: PropTypes.bool,
  declined: PropTypes.bool,
  optional: PropTypes.bool,
  user: PropTypes.shape(),
};

MessageListItemEventGuestItem.defaultProps = {
  accepted: false,
  declined: false,
  optional: false,
  user: {},
};

function mapStateToProps(state) {
  const {
    auth: { token, user: currentUser },
  } = state;

  return {
    currentUser,
    token,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createRoomRequest: createRoom.request,
  })(MessageListItemEventGuestItem)
);
