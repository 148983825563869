import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Form, Header, Message, Modal } from "semantic-ui-react";
import { isString } from "lodash";
import { connect } from "react-redux";
import { resetErrorMessage as resetErrorMessageAction } from "actions";
import { loginModal } from "actions/account";
import { getErrorMessages } from "../../../utils";

import styles from "../styles.module.scss";

const EXPIRATION_OPTIONS = [
  {
    label: "Only  this session",
    value: 0,
  },
  {
    label: "1 day",
    value: 60 * 60 * 24,
  },
  {
    label: "1 week",
    value: 60 * 60 * 24 * 7,
  },
  {
    label: "1 month",
    value: 60 * 60 * 24 * 30,
  },
];

const LoginModal = ({
  errorMessage,
  loginModalClose,
  loginModalSetExpiration,
  isLoggingIn,
  onCancel,
  onLogin,
  open,
  resetErrorMessage,
}) => {
  const [expiration, setExpiration] = useState(null);
  const [doSignout, setDoSignout] = useState(false);

  const handleChangeExpiration = (e, { value }) => {
    setExpiration(value);
  };

  const handleChangeSignout = () => {
    setDoSignout((prevState) => !prevState);
  };

  const handleLogin = useCallback(() => {
    if (onLogin) {
      onLogin({ expiration, doSignout });
    } else {
      loginModalSetExpiration({ expiration, doSignout });
    }
    if (!errorMessage) resetErrorMessage();
  }, [expiration, doSignout, onLogin, resetErrorMessage, errorMessage, loginModalSetExpiration]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      loginModalClose();
    }
    resetErrorMessage();
  }, [onCancel, loginModalClose, resetErrorMessage]);

  return (
    <Modal
      className={styles.modal}
      onClose={handleCancel}
      open={open}
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header className={styles.modalHeader}>
        <Header>Log-in</Header>
      </Modal.Header>
      <Modal.Content className={styles.modalContent}>
        {errorMessage && (
          <Message
            error
            header="Error"
            content={isString(errorMessage) ? errorMessage : getErrorMessages(errorMessage)}
          />
        )}
        <p className={styles.modalDescription}>Keep this browser logged in for:</p>
        <Form className={styles.modalForm}>
          <Form.Group className={styles.formGroup} grouped>
            {EXPIRATION_OPTIONS.map((option) => (
              <Form.Radio
                className={styles.radioBtn}
                label={option.label}
                checked={expiration === option.value}
                value={option.value}
                name="expiration"
                key={option.label}
                onChange={handleChangeExpiration}
              />
            ))}
          </Form.Group>
          <Form.Checkbox
            checked={doSignout}
            inline
            label="Sign me out of other devices"
            name="doSignout"
            onChange={handleChangeSignout}
          />
          <Divider fitted className={styles.actionDivider} />
          <div className={styles.formActions}>
            <Button basic content="Cancel" disabled={isLoggingIn} onClick={handleCancel} />
            <Button primary content="Log-in" loading={isLoggingIn} onClick={handleLogin} />
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

LoginModal.propTypes = {
  errorMessage: PropTypes.string,
  loginModalClose: PropTypes.func.isRequired,
  loginModalSetExpiration: PropTypes.func.isRequired,
  isLoggingIn: PropTypes.bool,
  onCancel: PropTypes.func,
  onLogin: PropTypes.func,
  open: PropTypes.bool,
  resetErrorMessage: PropTypes.func.isRequired,
};

LoginModal.defaultProps = {
  errorMessage: "",
  open: true,
  isLoggingIn: false,
};

function mapStateToProps(state) {
  const { errorMessage } = state;
  return { errorMessage };
}

export default connect(mapStateToProps, {
  loginModalClose: loginModal.close,
  loginModalSetExpiration: loginModal.setExpiration,
  resetErrorMessage: resetErrorMessageAction,
})(LoginModal);
