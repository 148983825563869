import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Icon } from "semantic-ui-react";

import { PANELS } from "actions/sidePanel";
import styles from "./styles.module.scss";

class ChannelOptionMenu extends React.Component {
  getActiveChannelOption = () => {
    const { sidePanelId } = this.props;
    switch (sidePanelId) {
      case PANELS.MEMBERS:
        return <Icon name="user" title="Members" className={styles.selectedPanel} />;
      case PANELS.PINNED:
        return <Icon name="pin" title="Pinned" className={styles.selectedPanel} />;
      case PANELS.ATTACHMENTS:
        return <Icon name="attach" title="Attachments" className={styles.selectedPanel} />;
      default:
        return <Icon name="info circle" title="Information" className={styles.selectedPanel} />;
    }
  };

  render() {
    const {
      hasProjectUrls,
      sidePanelId,
      onSidePanelMembersToggle,
      onSidePanelPinnedToggle,
      onSidePanelAttachmentsToggle,
      onSidePanelChannelInfoToggle,
    } = this.props;

    return (
      <Dropdown
        className={classnames(
          styles.channelOptionsDropdown,
          hasProjectUrls && styles.hasProjectUrls
        )}
        trigger={this.getActiveChannelOption()}
      >
        <Dropdown.Menu className={styles.channelOptionsDropdownMenu}>
          <Dropdown.Item
            name="members"
            className={styles.menuItem}
            active={sidePanelId === PANELS.MEMBERS}
            onClick={onSidePanelMembersToggle}
            onKeyPress={onSidePanelMembersToggle}
          >
            <Icon name="user" />
            <span className={styles.menuLabel}>Members</span>
          </Dropdown.Item>
          <Dropdown.Item
            name="pinned"
            className={styles.menuItem}
            active={sidePanelId === PANELS.PINNED}
            onClick={onSidePanelPinnedToggle}
            onKeyPress={onSidePanelPinnedToggle}
          >
            <Icon name="pin" />
            <span className={styles.menuLabel}>Pinned</span>
          </Dropdown.Item>
          <Dropdown.Item
            name="attachments"
            className={styles.menuItem}
            active={sidePanelId === PANELS.ATTACHMENTS}
            onClick={onSidePanelAttachmentsToggle}
            onKeyPress={onSidePanelAttachmentsToggle}
          >
            <Icon name="attach" />
            <span className={styles.menuLabel}>Attachments</span>
          </Dropdown.Item>
          <Dropdown.Item
            name="info"
            className={styles.menuItem}
            active={sidePanelId === PANELS.CHANNEL_INFO}
            onClick={onSidePanelChannelInfoToggle}
            onKeyPress={onSidePanelChannelInfoToggle}
          >
            <Icon name="info circle" />
            <span className={styles.menuLabel}>Information</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

ChannelOptionMenu.propTypes = {
  hasProjectUrls: PropTypes.bool.isRequired,
  sidePanelId: PropTypes.string.isRequired,
  onSidePanelMembersToggle: PropTypes.func.isRequired,
  onSidePanelPinnedToggle: PropTypes.func.isRequired,
  onSidePanelAttachmentsToggle: PropTypes.func.isRequired,
  onSidePanelChannelInfoToggle: PropTypes.func.isRequired,
};

export default ChannelOptionMenu;
