import { uniq, isEqual } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "semantic-ui-react";
import { NavLink as Link } from "react-router-dom";
import classnames from "classnames";

import { isBldAiDomain } from "utils";
import { bldAiDomain } from "utils/constants";
import UserAvatar from "components/UserAvatar";

import * as styles from "./styles.module.scss";

export default class Workmate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      members: props.members,
    };
  }

  componentWillReceiveProps(props) {
    // Update state when props has changed.
    const cond = !isEqual(props.members, this.props.members);

    if (cond) {
      this.setState({
        members: props.members,
      });
    }
  }

  onAddUser = (user) => {
    this.setState(
      (prevState) => ({
        members: uniq([...prevState.members, user]),
      }),
      () => {
        const { onValuesChange } = this.props;
        if (onValuesChange) onValuesChange(this.state.members);
      }
    );
  };

  isUserSelected = (members, user) => members.some((u) => u.id === user.id);

  render() {
    const { members, users, sortContact, sortBy } = this.props;
    if (users.length === 0) {
      return null;
    }

    return (
      <div className={styles.list}>
        <div className={styles.listContainer}>
          <div className={styles.peopleList}>
            {sortContact(users, sortBy).map((user) => (
              <div key={user.id} className={styles.peopleContainer}>
                <div className={styles.user}>
                  <UserAvatar
                    avatar={user.avatar}
                    bgColor={user.avatarBgcolor}
                    className={styles.sectionMemberMatchAvatar}
                    displayName={user.displayName}
                    fgColor={user.avatarColor}
                    size="32"
                  />
                  <div
                    className={
                      isBldAiDomain(user.email)
                        ? classnames(styles.name, styles.isbldaiParentNode)
                        : styles.name
                    }
                  >
                    <Link to={`/talents/${user.slug}`} target="_blank" title="View profile">
                      {user.displayName}
                    </Link>
                    <br />
                    <span className={styles.isbldai}>
                      {isBldAiDomain(user.email) ? bldAiDomain : ""}
                    </span>
                  </div>
                </div>
                <div className={styles.action}>
                  {this.isUserSelected(members, user) ? (
                    <span className={styles.added}>
                      <Icon name="check" />
                      <span>Added</span>
                    </span>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <span
                      role="button"
                      tabIndex={0}
                      className={styles.add}
                      onClick={() => this.onAddUser(user)}
                    >
                      <Icon name="plus" />
                      <span>Add member</span>
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Workmate.propTypes = {
  users: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
  members: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
  onValuesChange: PropTypes.func,
  sortContact: PropTypes.func,
  sortBy: PropTypes.string,
};

Workmate.defaultProps = {
  users: [],
  members: [],
  onValuesChange: null,
  sortContact: null,
  sortBy: "",
};
