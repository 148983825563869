import classnames from "classnames";
import Fuse from "fuse.js";
import { isString, isEqual, omit, uniq } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { LabeledInput } from "components/input";
import { KeyCodes } from "utils/constants";

import Autocomplete from "./Autocomplete";
import styles from "./styles.module.scss";

const FuzzySearchMatch = (match, onClick, selected) => (
  <div
    className={classnames(styles.searchMatch, selected && styles.selected)}
    key={match.item}
    onClick={() => onClick(match.item)}
    onKeyPress={() => onClick(match.item)}
    role="menuitem"
    tabIndex="-1"
    title={match.item}
  >
    <span>{match.item}</span>
  </div>
);

export default class FuzzySearch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      matches: [],
      valueCurrent: "",
      values: props.values,
    };

    this.input = React.createRef();
  }

  componentWillReceiveProps(props) {
    // Update state when props has changed.
    const cond = !isEqual(props.values, this.props.values);

    if (cond) {
      this.setState({
        values: props.values,
      });
    }
  }

  handleAutocompleteSelect = (item) => {
    const { multiple, onValuesChange, optionToString } = this.props;

    if (multiple) {
      this.setState(
        (prevState) => ({
          matches: [],
          valueCurrent: "",
          values: uniq([...prevState.values, item]),
        }),
        () => {
          if (onValuesChange) onValuesChange(this.state.values);
        }
      );
    } else {
      this.setState({
        matches: [],
        valueCurrent: isString ? item : optionToString(item),
      });
    }

    this.getInputRef().current.focus();
  };

  handleTextChange = (e, { value }) => {
    const { onTextChange } = this.props;
    const { text } = this.sanitize(value);
    if (onTextChange) onTextChange(text);
  };

  handleInput = (e) => {
    const valueCurrent = e.target.value;
    const { onValuesChange } = this.props;
    const { values, text } = this.sanitize(valueCurrent);

    this.setState(
      (prevState) => ({
        matches: this.search(text),
        valueCurrent: text,
        values: uniq([...prevState.values, ...values]),
      }),
      () => {
        if (values.length > 0 && onValuesChange) onValuesChange(this.state.values);
      }
    );
  };

  handleBlur = () => {
    const { onValuesChange } = this.props;
    const { valueCurrent } = this.state;
    const { values, text } = this.sanitize(valueCurrent, true);

    this.setState(
      (prevState) => ({
        matches: this.search(text),
        valueCurrent: text,
        values: uniq([...prevState.values, ...values]),
      }),
      () => {
        if (values.length > 0 && onValuesChange) onValuesChange(this.state.values);
      }
    );
  };

  handleKeyPress = (event) => {
    const {
      key,
      target: { value },
    } = event;

    // Allow input of values without submitting the form
    if (key === KeyCodes.ENTER[0] && value.length > 0) {
      event.preventDefault();
      event.stopPropagation();
      this.handleBlur();
    }
  };

  handleValueClick = (index) => {
    const { onValuesChange } = this.props;

    this.setState(
      (prevState) => {
        const values = [...prevState.values];
        values.splice(index, 1);
        return {
          values,
        };
      },
      () => {
        if (onValuesChange) onValuesChange(this.state.values);
      }
    );
  };

  handlePaste = (event) => {
    if (event.clipboardData) {
      const { onValuesChange } = this.props;

      event.preventDefault();

      const data = event.clipboardData.getData("Text");
      const splitData = data
        .split(/\r?\n/)
        .map((v) => v.trim())
        .filter((v) => v.length > 0);

      const { values, text } = this.sanitize(splitData.join(";"), true);
      this.setState(
        (prevState) => ({
          matches: this.search(text),
          valueCurrent: text,
          values: uniq([...prevState.values, ...values]),
        }),
        () => {
          if (values.length > 0 && onValuesChange) onValuesChange(this.state.values);
        }
      );
    }
  };

  getInputRef = () => {
    const { inputRef } = this.props;
    return inputRef || this.input;
  };

  getValuesValidator = () => {
    const { allowAdditions, valuesValidator } = this.props;
    if (allowAdditions) return valuesValidator;
    return () => false;
  };

  sanitize(text, allowSingle = false) {
    const { allowAdditions, multiple, separatorCharacters } = this.props;

    if (!allowAdditions) {
      return { text, values: [] };
    }

    const separatorCharactersList = separatorCharacters.split("");
    const separatorCharactersRegex = new RegExp(separatorCharactersList.join("|"), "g");
    const separatorCharactersCount = (text.match(separatorCharactersRegex) || []).length;

    if (!allowSingle && (separatorCharactersCount === 0 || !multiple)) {
      return { text, values: [] };
    }

    const values = text.split(separatorCharactersRegex).map((value) => value.trim());
    let textSanitized = !allowSingle ? values.splice(separatorCharactersCount).join("") : "";
    const valuesValidator = this.getValuesValidator();
    const valuesExcluded = values.filter((value) => valuesValidator && !valuesValidator(value));
    const valuesFiltered = values.filter((value) => !valuesValidator || valuesValidator(value));

    if (valuesExcluded.length > 0) {
      textSanitized = [...valuesExcluded, textSanitized]
        .map((t) => t)
        .filter((t) => t.length > 0)
        .join(separatorCharactersList[0]);
    }

    return { text: textSanitized, values: valuesFiltered };
  }

  search(text) {
    if (!text) return [];

    const {
      caseSensitive,
      distance,
      findAllMatches,
      keys,
      location,
      maxOptionsShown,
      minMatchCharLength,
      options,
      threshold,
      tokenize,
    } = this.props;
    const fuse = new Fuse(options, {
      caseSensitive,
      distance,
      findAllMatches,
      keys,
      location,
      minMatchCharLength,
      threshold,
      tokenize,
      includeMatches: true,
      shouldSort: true,
    });

    return fuse.search(text).slice(0, maxOptionsShown);
  }

  render() {
    const {
      children,
      className,
      disabled,
      label,
      matchRenderer,
      multiple,
      valuesClassName,
      valuesRenderer,
      ...inputPropsUnfiltered
    } = this.props;
    const inputProps = omit(inputPropsUnfiltered, [
      "allowAdditions",
      "caseSensitive",
      "distance",
      "findAllMatches",
      "keys",
      "inputRef",
      "location",
      "maxOptionsShown",
      "minMatchCharLength",
      "onTextChange",
      "onValuesChange",
      "options",
      "optionToString",
      "separatorCharacters",
      "threshold",
      "tokenize",
      "valuesValidator",
    ]);

    const { matches, valueCurrent, values } = this.state;
    const inputRef = this.getInputRef();

    return (
      <div className={className}>
        <div className={styles.fuzzySearchContainer}>
          <LabeledInput
            className={styles.fuzzySearch}
            disabled={disabled}
            icon="search"
            iconPosition="left"
            label={label}
            onBlur={this.handleBlur}
            onChange={this.handleTextChange}
            onInput={this.handleInput}
            onKeyPress={this.handleKeyPress}
            onPaste={this.handlePaste}
            ref={inputRef}
            type="search"
            value={valueCurrent}
            {...inputProps}
          />

          {!children && (
            <Autocomplete
              disabled={disabled}
              matches={matches}
              matchRenderer={matchRenderer}
              onSelect={this.handleAutocompleteSelect}
              text={valueCurrent}
              validator={this.getValuesValidator()}
            />
          )}
        </div>

        {!disabled && multiple && (
          <div className={valuesClassName}>
            {valuesRenderer && valuesRenderer(values, this.handleValueClick)}
          </div>
        )}

        {!!children &&
          children({
            matches,
            value: valueCurrent,
            values,
            onClick: this.handleAutocompleteSelect,
          })}
      </div>
    );
  }
}

FuzzySearch.propTypes = {
  allowAdditions: PropTypes.bool,

  /**
   * Indicates whether comparisons should be case sensitive.
   */
  caseSensitive: PropTypes.bool,

  children: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,

  /**
   * Determines how close the match must be to the fuzzy location (specified by location).
   * An exact letter match which is distance characters away from the fuzzy location would score
   * as a complete mismatch. A distance of 0 requires the match be at the exact location specified,
   * a distance of 1000 would require a perfect match to be within 800 characters of the location to
   * be found using a threshold of 0.8.
   */
  distance: PropTypes.number,

  /**
   * When true, the matching function will continue to the end of a search pattern
   * even if a perfect match has already been located in the string.
   */
  findAllMatches: PropTypes.bool,

  /**
   * Expose the DOM reference to the input outside the component.
   */
  inputRef: PropTypes.shape(),

  /**
   * List of properties that will be searched. This supports nested properties, weighted search,
   * searching in arrays of strings and objects
   */
  keys: PropTypes.arrayOf(PropTypes.string),

  label: PropTypes.string,

  /**
   * Determines approximately where in the text is the pattern expected to be found.
   */
  location: PropTypes.number,

  matchRenderer: PropTypes.func,
  maxOptionsShown: PropTypes.number,

  /**
   * When set to include matches, only the matches whose length exceeds this value will be returned.
   * (For instance, if you want to ignore single character index returns, set to 2)
   */
  minMatchCharLength: PropTypes.number,

  multiple: PropTypes.bool,
  onTextChange: PropTypes.func,
  onValuesChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),

  /**
   * When multiple is set to False, this is required to convert an object into a text that can
   * be inserted into the text field when clicking an entry from the autocomplete component.
   */
  optionToString: PropTypes.func,

  separatorCharacters: PropTypes.string,

  /**
   * At what point does the match algorithm give up.
   * A threshold of 0.0 requires a perfect match (of both letters and location),
   * a threshold of 1.0 would match anything.
   */
  threshold: PropTypes.number,

  /**
   * When true, the algorithm will search individual words and the full string,
   * computing the final score as a function of both. In this case, the threshold, distance,
   * and location are inconsequential for individual tokens, and are thus ignored.
   */
  tokenize: PropTypes.bool,

  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
  valuesClassName: PropTypes.string,
  valuesRenderer: PropTypes.func,

  /**
   * This will be used to check if entries typed are valid.
   * Note that this will not be used for autocomplete entries.
   */
  valuesValidator: PropTypes.func,
};

FuzzySearch.defaultProps = {
  allowAdditions: false,
  caseSensitive: false,
  className: null,
  disabled: false,
  distance: 300,
  findAllMatches: false,
  keys: [],
  location: 0,
  matchRenderer: (match, onClick, selected) => (
    <FuzzySearchMatch match={match} onClick={onClick} selected={selected} />
  ),
  maxOptionsShown: 10,
  minMatchCharLength: 1,
  multiple: false,
  threshold: 0.2,
  tokenize: false,
  onTextChange: null,
  onValuesChange: null,
  options: [],
  optionToString: null,
  separatorCharacters: ",;",
  values: [],
  valuesClassName: null,
  valuesRenderer: null,
  valuesValidator: null,
};
