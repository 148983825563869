import React from "react";
import PropTypes from "prop-types";
import Checkbox from "components/Checkbox";
import { Header, Icon } from "semantic-ui-react";
import moment from "moment-timezone";

import * as styles from "./styles.module.scss";
import { MEETING_TYPES, MEETINGS } from "./constants";

export const renderDescription = (startHour, timezone, meetingType) => {
  const start = moment()
    .tz(timezone)
    .day(8)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .add(startHour, "hours");
  const end = moment()
    .tz(timezone)
    .day(8)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .add(startHour + 1, "hours");
  return (
    <>
      <div>{`${start.format("ddd")}, ${start.format("h:mm A")} - ${end.format(
        "h:mm A"
      )} (${timezone})`}</div>
      <div>
        {meetingType === "checkIns"
          ? "Repeats every day"
          : `Repeats every 2 weeks on ${start.format("ddd")}`}
      </div>
    </>
  );
};

const Meetings = (props) => {
  const { meetings, onCheckboxChange, timezone } = props;

  const renderCheckbox = (meetingType, meetingState) => (
    <Checkbox
      body={
        <>
          <div className={styles.checkboxBody}>{MEETINGS[meetingType].description}</div>
          <div className={styles.schedule}>
            <div className={styles.scheduleTitle}>Suggested Schedule:</div>
            <div className={styles.scheduleBody}>
              {renderDescription(meetingState.suggestedStart, timezone, meetingType)}
            </div>
          </div>
        </>
      }
      checked={meetingState.checked}
      identifier={meetingType}
      key={meetingType}
      onChange={(e) =>
        onCheckboxChange(e, {
          name: meetingType,
          value: e.target.checked,
        })
      }
    >
      <>
        <Icon name={MEETINGS[meetingType].icon} className={styles[MEETINGS[meetingType].icon]} />
        {MEETINGS[meetingType].name}
      </>
    </Checkbox>
  );

  return (
    <>
      <Header as="h4" content="Hold meetings" />
      <div className={styles.meetingsSubtitle}>
        You can always customize your meetings after forming your team
      </div>
      <div className={styles.selectAllMeetingsCheckbox}>
        <Checkbox
          label="Select all meetings"
          name="selectAll"
          onChange={(e) =>
            onCheckboxChange(e, {
              name: "selectAll",
              value: e.target.checked,
            })
          }
        >
          Select all meetings
        </Checkbox>
      </div>
      <div className={styles.meetingsContainer}>
        <div className={styles.meetingsColumn}>
          {MEETING_TYPES.slice(0, 3).map((meetingType) =>
            renderCheckbox(meetingType, meetings[meetingType])
          )}
        </div>
        <div className={styles.meetingsColumn}>
          {MEETING_TYPES.slice(3).map((meetingType) =>
            renderCheckbox(meetingType, meetings[meetingType])
          )}
        </div>
      </div>
    </>
  );
};

Meetings.propTypes = {
  meetings: PropTypes.shape().isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default Meetings;
