import PropTypes from "prop-types";
import React from "react";
import { escapeRegExp } from "lodash";
import moment from "moment-timezone";
import { Dropdown } from "semantic-ui-react";
import { isMobile } from "utils/responsive";

import styles from "./styles.module.scss";

const CONTAINER_STYLE = {
  position: "relative",
  display: "inline-block",
  verticalAlign: "middle",
};

const OPTIONS = [
  { key: "12:00am", value: "00:00", text: "12:00am" },
  { key: "12:15am", value: "00:15", text: "12:15am" },
  { key: "12:30am", value: "00:30", text: "12:30am" },
  { key: "12:45am", value: "00:45", text: "12:45am" },
  { key: "1:00am", value: "01:00", text: "1:00am" },
  { key: "1:15am", value: "00:15", text: "1:15am" },
  { key: "1:30am", value: "01:30", text: "1:30am" },
  { key: "1:45am", value: "01:45", text: "1:45am" },
  { key: "2:00am", value: "02:00", text: "2:00am" },
  { key: "2:15am", value: "02:15", text: "2:15am" },
  { key: "2:30am", value: "02:30", text: "2:30am" },
  { key: "2:45am", value: "02:45", text: "2:45am" },
  { key: "3:00am", value: "03:00", text: "3:00am" },
  { key: "3:15am", value: "03:15", text: "3:15am" },
  { key: "3:30am", value: "03:30", text: "3:30am" },
  { key: "3:45am", value: "03:45", text: "3:45am" },
  { key: "4:00am", value: "04:00", text: "4:00am" },
  { key: "4:15am", value: "04:15", text: "4:15am" },
  { key: "4:30am", value: "04:30", text: "4:30am" },
  { key: "4:45am", value: "04:45", text: "4:45am" },
  { key: "5:00am", value: "05:00", text: "5:00am" },
  { key: "5:15am", value: "05:15", text: "5:15am" },
  { key: "5:30am", value: "05:30", text: "5:30am" },
  { key: "5:45am", value: "05:45", text: "5:45am" },
  { key: "6:00am", value: "06:00", text: "6:00am" },
  { key: "6:15am", value: "06:15", text: "6:15am" },
  { key: "6:30am", value: "06:30", text: "6:30am" },
  { key: "6:45am", value: "06:45", text: "6:45am" },
  { key: "7:00am", value: "07:00", text: "7:00am" },
  { key: "7:15am", value: "07:15", text: "7:15am" },
  { key: "7:30am", value: "07:30", text: "7:30am" },
  { key: "7:45am", value: "07:45", text: "7:45am" },
  { key: "8:00am", value: "08:00", text: "8:00am" },
  { key: "8:15am", value: "08:15", text: "8:15am" },
  { key: "8:30am", value: "08:30", text: "8:30am" },
  { key: "8:45am", value: "08:45", text: "8:45am" },
  { key: "9:00am", value: "09:00", text: "9:00am" },
  { key: "9:15am", value: "09:15", text: "9:15am" },
  { key: "9:30am", value: "09:30", text: "9:30am" },
  { key: "9:45am", value: "09:45", text: "9:45am" },
  { key: "10:00am", value: "10:00", text: "10:00am" },
  { key: "10:15am", value: "10:15", text: "10:15am" },
  { key: "10:30am", value: "10:30", text: "10:30am" },
  { key: "10:45am", value: "10:45", text: "10:45am" },
  { key: "11:00am", value: "11:00", text: "11:00am" },
  { key: "11:15am", value: "11:15", text: "11:15am" },
  { key: "11:30am", value: "11:30", text: "11:30am" },
  { key: "11:45am", value: "11:45", text: "11:45am" },
  { key: "12:00pm", value: "12:00", text: "12:00pm" },
  { key: "12:15pm", value: "12:15", text: "12:15pm" },
  { key: "12:30pm", value: "12:30", text: "12:30pm" },
  { key: "12:45pm", value: "12:45", text: "12:45pm" },
  { key: "1:00pm", value: "13:00", text: "1:00pm" },
  { key: "1:15pm", value: "13:15", text: "1:15pm" },
  { key: "1:30pm", value: "13:30", text: "1:30pm" },
  { key: "1:45pm", value: "13:45", text: "1:45pm" },
  { key: "2:00pm", value: "14:00", text: "2:00pm" },
  { key: "2:15pm", value: "14:15", text: "2:15pm" },
  { key: "2:30pm", value: "14:30", text: "2:30pm" },
  { key: "2:45pm", value: "14:45", text: "2:45pm" },
  { key: "3:00pm", value: "15:00", text: "3:00pm" },
  { key: "3:15pm", value: "15:15", text: "3:15pm" },
  { key: "3:30pm", value: "15:30", text: "3:30pm" },
  { key: "3:45pm", value: "15:45", text: "3:45pm" },
  { key: "4:00pm", value: "16:00", text: "4:00pm" },
  { key: "4:15pm", value: "16:15", text: "4:15pm" },
  { key: "4:30pm", value: "16:30", text: "4:30pm" },
  { key: "4:45pm", value: "16:45", text: "4:45pm" },
  { key: "5:00pm", value: "17:00", text: "5:00pm" },
  { key: "5:15pm", value: "17:15", text: "5:15pm" },
  { key: "5:30pm", value: "17:30", text: "5:30pm" },
  { key: "5:45pm", value: "17:45", text: "5:45pm" },
  { key: "6:00pm", value: "18:00", text: "6:00pm" },
  { key: "6:15pm", value: "18:15", text: "6:15pm" },
  { key: "6:30pm", value: "18:30", text: "6:30pm" },
  { key: "6:45pm", value: "18:45", text: "6:45pm" },
  { key: "7:00pm", value: "19:00", text: "7:00pm" },
  { key: "7:15pm", value: "19:15", text: "7:15pm" },
  { key: "7:30pm", value: "19:30", text: "7:30pm" },
  { key: "7:45pm", value: "19:45", text: "7:45pm" },
  { key: "8:00pm", value: "20:00", text: "8:00pm" },
  { key: "8:15pm", value: "20:15", text: "8:15pm" },
  { key: "8:30pm", value: "20:30", text: "8:30pm" },
  { key: "8:45pm", value: "20:45", text: "8:45pm" },
  { key: "9:00pm", value: "21:00", text: "9:00pm" },
  { key: "9:15pm", value: "21:15", text: "9:15pm" },
  { key: "9:30pm", value: "21:30", text: "9:30pm" },
  { key: "9:45pm", value: "21:45", text: "9:45pm" },
  { key: "10:00pm", value: "22:00", text: "10:00pm" },
  { key: "10:15pm", value: "22:15", text: "10:15pm" },
  { key: "10:30pm", value: "22:30", text: "10:30pm" },
  { key: "10:45pm", value: "22:45", text: "10:45pm" },
  { key: "11:00pm", value: "23:00", text: "11:00pm" },
  { key: "11:15pm", value: "23:15", text: "11:15pm" },
  { key: "11:30pm", value: "23:30", text: "11:30pm" },
  { key: "11:45pm", value: "23:45", text: "11:45pm" },
];

const FORMATS = [
  "YYYY-MM-DD ha",
  "YYYY-MM-DD hha",
  "YYYY-MM-DD h",
  "YYYY-MM-DD hh",
  "YYYY-MM-DD h:",
  "YYYY-MM-DD hh:",
  "YYYY-MM-DD h:m",
  "YYYY-MM-DD hh:m",
  "YYYY-MM-DD hh:mm",
  "YYYY-MM-DD h:mm",
  "YYYY-MM-DD hh:mm a",
  "YYYY-MM-DD hh:mma",
  "YYYY-MM-DD h:mm a",
  "YYYY-MM-DD h:mma",
];

const MTIME_FORMATS = [
  "YYYY-MM-DD H",
  "YYYY-MM-DD HH",
  "YYYY-MM-DD H:",
  "YYYY-MM-DD HH:",
  "YYYY-MM-DD H:m",
  "YYYY-MM-DD HH:m",
  "YYYY-MM-DD H:mm",
  "YYYY-MM-DD HH:mm",
];

const ALL_FORMATS = [...FORMATS, ...MTIME_FORMATS];

export function convertToMilitaryTime(value) {
  return moment(`2000-01-01 ${value}`, ALL_FORMATS, true).format("HH:mm");
}

export function convertToRegularTime(value) {
  const dt = moment(`2000-01-01 ${value}`, ALL_FORMATS, true);
  if (dt.isValid()) {
    return dt.format("h:mma");
  }
  return "";
}

export function checkIfValidTime(value) {
  return moment(`2000-01-01 ${value}`, ALL_FORMATS, true).isValid();
}

const regularAndMtimeSearch = (options, query) => {
  const re = new RegExp(`^${escapeRegExp(query)}`, "i");
  const result = options.filter((opt) => re.test(opt.text) || re.test(opt.value));
  return result;
};

export default class TimeDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    const { value } = props;
    const options = [...OPTIONS];
    this.state = {
      searchQuery: "",
      displayText: convertToRegularTime(value),
      options,
      hasError: false,
    };
    this.isMobile = isMobile();
  }

  componentWillReceiveProps(props) {
    if (props.value !== this.props.value) {
      this.setState({ displayText: convertToRegularTime(props.value) });
    }
  }

  handleChange = (e, data) => {
    const { value: newValue } = data;
    const newValueM = convertToMilitaryTime(newValue);
    const { name, onChange } = this.props;

    onChange(e, { name, value: newValueM });
    this.setState({
      displayText: convertToRegularTime(newValueM),
      hasError: false,
    });
  };

  handleClose = (e) => {
    const { name, onChange } = this.props;
    const { displayText, searchQuery, hasError } = this.state;
    const val = convertToRegularTime(displayText);
    if (!hasError && val && searchQuery) {
      const newValueM = convertToMilitaryTime(val);
      onChange(e, { name, value: newValueM });
      this.setState({ searchQuery: "", displayText: val });
    }
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.setState({ searchQuery, displayText: searchQuery, hasError: false });
    if (searchQuery && !checkIfValidTime(searchQuery)) {
      this.setState({ hasError: true });
    } else {
      this.setState({ hasError: false });
    }
  };

  handleAddition = (e, { value }) => {
    const regularValue = convertToRegularTime(value);
    if (!checkIfValidTime(regularValue)) {
      this.setState({ hasError: true });
    }
  };

  handleFocus = (e) => {
    e.target.select();
  };

  render() {
    const {
      className,
      containerStyle,
      disabled,
      dropdownStyle,
      fluid,
      name,
      onChange,
      placeholder,
      required,
      value,
    } = this.props;
    const { searchQuery, options, hasError, displayText } = this.state;
    if (this.isMobile) {
      // On mobile, fallback to native time input
      return (
        <input
          name={name}
          value={value}
          onChange={(event) => onChange(event, { name, value: event.target.value })}
          required="required"
          type="time"
          style={{ width: "100%", ...containerStyle, ...dropdownStyle }}
        />
      );
    }

    const fluidStyle = {};
    if (fluid) {
      fluidStyle.width = "100%";
    }

    return (
      <div className={className} style={{ ...CONTAINER_STYLE, ...fluidStyle, ...containerStyle }}>
        <Dropdown
          additionLabel=""
          additionPosition="bottom"
          allowAdditions
          className={styles.timeDropdown}
          disabled={disabled}
          error={hasError}
          floating
          fluid={fluid}
          icon={null}
          onAddItem={this.handleAddition}
          onBlur={this.handleClose}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onLabelClick={this.handleLabelClick}
          onSearchChange={this.handleSearchChange}
          openOnFocus
          options={options}
          placeholder={placeholder}
          scrolling
          search={regularAndMtimeSearch}
          searchInput={{ required, value: searchQuery || displayText, onFocus: this.handleFocus }}
          searchQuery={searchQuery}
          selection
          selectOnBlur={false}
          style={{ fontStyle: "normal", ...dropdownStyle }}
          text={displayText}
          value={value}
        />
      </div>
    );
  }
}

TimeDropdown.propTypes = {
  className: PropTypes.string,
  containerStyle: PropTypes.shape(),
  disabled: PropTypes.bool,
  dropdownStyle: PropTypes.shape(),
  fluid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

TimeDropdown.defaultProps = {
  containerStyle: {},
  disabled: false,
  dropdownStyle: {},
  fluid: false,
  required: false,
};
