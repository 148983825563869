import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Responsive } from "semantic-ui-react";

import DateTimePicker from "components/DateTimePicker";
import styles from "./styles.module.scss";

export default class DateTimeRangePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.startDateRef = React.createRef();
  }

  render() {
    const {
      className,
      endDate,
      endDateName,
      disabled,
      hideTime,
      maxDate,
      minDate,
      required,
      small,
      startDate,
      startDateName,
      onChange,
    } = this.props;
    const datepickerClasses = [styles.datetimePicker];
    if (small) {
      datepickerClasses.push(styles.small);
    }

    return (
      <>
        <Responsive
          as="div"
          className={classnames(className, styles.datetimePickers)}
          minWidth={420}
        >
          <div className={classnames(...datepickerClasses)}>
            <DateTimePicker
              ref={this.startDateRef}
              date={startDate}
              disabled={disabled}
              hideTime={hideTime}
              maxDate={maxDate}
              minDate={minDate}
              name={startDateName}
              required={required}
              small={small}
              onChange={onChange}
            />
          </div>
          <div className={classnames(...datepickerClasses)}>
            <DateTimePicker
              date={endDate}
              disabled={disabled}
              hideTime={hideTime}
              maxDate={maxDate}
              minDate={minDate}
              name={endDateName}
              required={required}
              reversed
              small={small}
              onChange={onChange}
            />
          </div>
        </Responsive>
        <Responsive
          as="div"
          className={classnames(className, styles.datetimePickers)}
          maxWidth={419}
        >
          <p className={styles.datetimePickerLabel}>
            <FormattedMessage defaultMessage="From" id="DateTimeRangePicker.label.start" />
          </p>
          <div className={styles.datetimePickerBlock}>
            <DateTimePicker
              date={startDate}
              disabled={disabled}
              hideTime={hideTime}
              maxDate={maxDate}
              minDate={minDate}
              name={startDateName}
              required={required}
              onChange={onChange}
            />
          </div>
          <p className={styles.datetimePickerLabel}>
            <FormattedMessage defaultMessage="To" id="DateTimeRangePicker.label.end" />
          </p>
          <div className={styles.datetimePickerBlock}>
            <DateTimePicker
              date={endDate}
              disabled={disabled}
              hideTime={hideTime}
              maxDate={maxDate}
              minDate={minDate}
              name={endDateName}
              required={required}
              onChange={onChange}
            />
          </div>
        </Responsive>
      </>
    );
  }
}

DateTimeRangePicker.propTypes = {
  className: PropTypes.string,
  endDate: PropTypes.string,
  endDateName: PropTypes.string,
  disabled: PropTypes.bool,
  hideTime: PropTypes.bool,
  maxDate: PropTypes.shape(),
  minDate: PropTypes.shape(),
  required: PropTypes.bool,
  small: PropTypes.bool,
  startDate: PropTypes.string,
  startDateName: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

DateTimeRangePicker.defaultProps = {
  endDate: "",
  endDateName: "end",
  disabled: false,
  hideTime: false,
  maxDate: null,
  minDate: null,
  required: true,
  small: false,
  startDate: "",
  startDateName: "start",
};
