export default {
  GLOBAL: {
    APP_CHAT: {
      windows: "ctrl+shift+1",
      linux: "ctrl+shift+1",
      osx: "command+shift+1",
      other: "ctrl+shift+1",
    },
    APP_CALL: {
      windows: "ctrl+shift+2",
      linux: "ctrl+shift+2",
      osx: "command+shift+2",
      other: "ctrl+shift+2",
    },
    APP_POLL: {
      windows: "ctrl+shift+3",
      linux: "ctrl+shift+3",
      osx: "command+shift+3",
      other: "ctrl+shift+3",
    },
    APP_MEETING: {
      windows: "ctrl+shift+4",
      linux: "ctrl+shift+4",
      osx: "command+shift+4",
      other: "ctrl+shift+4",
    },
    APP_FEEDBACK: {
      windows: "ctrl+shift+5",
      linux: "ctrl+shift+5",
      osx: "command+shift+5",
      other: "ctrl+shift+5",
    },
    APP_HEALTH: {
      windows: "ctrl+shift+6",
      linux: "ctrl+shift+6",
      osx: "command+shift+6",
      other: "ctrl+shift+6",
    },
    APP_ABOUT: {
      windows: "ctrl+shift+7",
      linux: "ctrl+shift+7",
      osx: "command+shift+7",
      other: "ctrl+shift+7",
    },
    APP_SETTINGS: {
      windows: "ctrl+shift+8",
      linux: "ctrl+shift+8",
      osx: "command+shift+8",
      other: "ctrl+shift+8",
    },
    APP_PLANNING: {
      windows: "ctrl+shift+9",
      linux: "ctrl+shift+9",
      osx: "command+shift+9",
      other: "ctrl+shift+9",
    },
    APP_PREVIOUS: {
      windows: "ctrl+shift+,",
      linux: "ctrl+shift+,",
      osx: "command+shift+,",
      other: "ctrl+shift+,",
    },
    APP_NEXT: {
      windows: "ctrl+shift+.",
      linux: "ctrl+shift+.",
      osx: "command+shift+.",
      other: "ctrl+shift+.",
    },
    SEARCH_FOCUS: {
      windows: "ctrl+k",
      linux: "ctrl+k",
      osx: "command+k",
      other: "ctrl+k",
    },
    CHAT_FOCUS: {
      windows: "ctrl+shift+l",
      linux: "ctrl+shift+l",
      osx: "command+shift+l",
      other: "ctrl+shift+l",
    },
    PREVIOUS_ROOM: {
      windows: "ctrl+shift+up",
      linux: "ctrl+shift+up",
      osx: "command+shift+up",
      other: "ctrl+shift+up",
    },
    NEXT_ROOM: {
      windows: "ctrl+shift+down",
      linux: "ctrl+shift+down",
      osx: "command+shift+down",
      other: "ctrl+shift+down",
    },
    PREVIOUS_UNREAD_ROOM: {
      windows: "ctrl+shift+alt+up",
      linux: "ctrl+shift+alt+up",
      osx: "command+shift+option+up",
      other: "ctrl+shift+alt+up",
    },
    NEXT_UNREAD_ROOM: {
      windows: "ctrl+shift+alt+down",
      linux: "ctrl+shift+alt+down",
      osx: "command+shift+option+down",
      other: "ctrl+shift+alt+down",
    },
    NEW_PROJECT: "ctrl+alt+p",
    NEW_MESSAGE: "ctrl+alt+k",
    NEW_TEAM: "ctrl+alt+e",
    SHORTCUTS_HELP: {
      windows: "ctrl+/",
      linux: "ctrl+/",
      osx: "command+/",
      other: "ctrl+/",
    },
  },
  SIDEBAR_SECTION: {
    PREVIOUS_ROOM: "up",
    NEXT_ROOM: "down",
    CANCEL: "escape",
    SELECT_ROOM: "enter",
  },
  MESSAGE_BOX: {
    UPLOAD: {
      windows: "ctrl+u",
      linux: "ctrl+u",
      osx: "command+u",
      other: "ctrl+u",
    },
    EDIT_LAST: "up",
    REPLY_LAST: "shift+up",
  },
  MESSAGE_BOX_THREAD: {
    CLOSE: "escape",
  },
};
