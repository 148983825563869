import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import NotificationSound from "utils/notificationSound";
import styles from "../styles.module.scss";

class ReminderWorkLogModal extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      NotificationSound.playBeep();
    }
  }

  render() {
    const { onClose, open } = this.props;

    return (
      <Modal onClose={onClose} open={open} className={styles.hoursNotificationsModal} closeIcon>
        <Modal.Content className={styles.modalContent}>
          <p className={styles.heading}>Please remember to log your hours</p>
          <p className={styles.content}>
            Just a friendly reminder to log or edit your task hours as needed.
          </p>
          <hr />
          <div className={styles.actionsContainer}>
            <Button className={classnames(styles.action, styles.cancel)} onClick={onClose}>
              Close
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

ReminderWorkLogModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

ReminderWorkLogModal.defaultProps = {
  open: false,
};

export default ReminderWorkLogModal;
