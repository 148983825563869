import React from "react";
import PropTypes from "prop-types";
import { NavLink as Link } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";

import config from "config";
import styles from "./styles.module.scss";

class TopBarDropdownMenu extends React.Component {
  getActiveMenu = () => {
    const { hasUnreadMessages, isCalendar, isHealth, isSchedule, isTimeTracker } = this.props;

    if (isCalendar) {
      return (
        <div className={styles.selectedMenu}>
          <Icon name="calendar alternate" />
          <span className={styles.menuLabel}>Calendar</span>
        </div>
      );
    }

    if (isTimeTracker) {
      return (
        <div className={styles.selectedMenu}>
          <Icon name="clock" />
          <span className={styles.menuLabel}>Time Tracker</span>
        </div>
      );
    }

    if (config.flags.health && isHealth) {
      return (
        <div className={styles.selectedMenu}>
          <Icon name="heartbeat" />
          <span className={styles.menuLabel}>Health</span>
        </div>
      );
    }

    if (config.flags.schedule && isSchedule) {
      return (
        <div className={styles.selectedMenu}>
          <img src="/schedule.svg" alt="schedule" />
          <span className={styles.menuLabel}>Schedule</span>
        </div>
      );
    }

    return (
      <div className={styles.selectedMenu}>
        <img src="/workspace.png" alt="workspace" />
        <span className={styles.menuLabel}>Workspace</span>
        {hasUnreadMessages && <div className={styles.unreadMessages} />}
      </div>
    );
  };

  render() {
    const {
      currentUser,
      hasUnreadMessages,
      isCalendar,
      isHealth,
      isSchedule,
      isTimeTracker,
    } = this.props;

    return (
      <Dropdown className={styles.dropdownMenu} trigger={this.getActiveMenu()}>
        <Dropdown.Menu className={styles.topbarDropdownMenu}>
          {config.flags.chat && (
            <Dropdown.Item
              name="workspace"
              className={styles.menuItem}
              as={Link}
              to="/chat"
              active={!isHealth && !isCalendar && !isTimeTracker && !isSchedule}
              activeClassName="active"
            >
              <img src="/workspace_black.png" alt="workspace" />
              <span className={styles.menuLabel}>Workspace</span>
              {hasUnreadMessages && <div className={styles.unreadMessages} />}
            </Dropdown.Item>
          )}

          <Dropdown.Item
            name="calendar"
            className={styles.menuItem}
            as={Link}
            to={`/talents/${currentUser.slug}/calendar`}
            active={!!isCalendar}
            activeClassName="active"
          >
            <Icon name="calendar alternate" />
            <span className={styles.menuLabel}>Calendar</span>
          </Dropdown.Item>

          {config.flags.timeTracker && (
            <Dropdown.Item
              name="time-tracker"
              className={styles.menuItem}
              as={Link}
              to="/timetracker"
              active={!!isTimeTracker}
              activeClassName="active"
            >
              <Icon name="clock" />
              <span className={styles.menuLabel}>Time Tracker</span>
            </Dropdown.Item>
          )}

          {config.flags.schedule && currentUser.weeklyAssignmentsAccessType && (
            <Dropdown.Item
              name="schedule"
              className={styles.menuItem}
              as={Link}
              to="/schedule"
              active={isSchedule}
              activeClassName="active"
            >
              <img src="/schedule_black.svg" alt="schedule" />
              <span className={styles.menuLabel}>Schedule</span>
            </Dropdown.Item>
          )}

          {config.flags.health && (
            <Dropdown.Item
              name="health"
              className={styles.menuItem}
              as={Link}
              to={`/talents/${currentUser.slug}/health`}
              active={!!isHealth}
              activeClassName="active"
            >
              <Icon name="heartbeat" />
              <span className={styles.menuLabel}>Health</span>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

TopBarDropdownMenu.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  hasUnreadMessages: PropTypes.bool.isRequired,
  isCalendar: PropTypes.bool,
  isHealth: PropTypes.bool,
  isSchedule: PropTypes.bool,
  isTimeTracker: PropTypes.bool,
};

TopBarDropdownMenu.defaultProps = {
  isCalendar: false,
  isHealth: false,
  isSchedule: false,
  isTimeTracker: false,
};

export default TopBarDropdownMenu;
