import { createRequestTypes, action } from "./index";

export const BUDGET = createRequestTypes("BUDGET");
export const VISUALIZATION = createRequestTypes("VISUALIZATION");
export const UPDATE_BUDGET = createRequestTypes("UPDATE_BUDGET");
export const GET_BUDGET_TRACKER = createRequestTypes("GET_BUDGET_TRACKER");
export const SEND_BUDGET_REPORT = "SEND_BUDGET_REPORT";

export const budget = {
  init: () => action(BUDGET.INIT),
  request: () => action(BUDGET.REQUEST),
  success: (response) => action(BUDGET.SUCCESS, { response }),
  failure: (error) => action(BUDGET.FAILURE, { error }),
};

export const budgetVisualization = {
  init: (params, refresh) => action(VISUALIZATION.INIT, { params, refresh }),
  request: (refresh) => action(VISUALIZATION.REQUEST, { refresh }),
  success: (response) => action(VISUALIZATION.SUCCESS, { response }),
  failure: (error) => action(VISUALIZATION.FAILURE, { error }),
};

export const updateBudget = {
  init: (slug, pid, rSlug, amount, description, params) =>
    action(UPDATE_BUDGET.INIT, { slug, pid, rSlug, amount, description, params }),
  request: () => action(UPDATE_BUDGET.REQUEST),
  success: (response) => action(UPDATE_BUDGET.SUCCESS, { response }),
  failure: (error) => action(UPDATE_BUDGET.FAILURE, { error }),
};

export const getBudgetTracker = {
  init: () => action(GET_BUDGET_TRACKER.INIT),
  request: (params = {}) => action(GET_BUDGET_TRACKER.REQUEST, { params }),
  success: (response) => action(GET_BUDGET_TRACKER.SUCCESS, { response }),
  failure: (error) => action(GET_BUDGET_TRACKER.FAILURE, { error }),
};

export const sendBudgetReport = (projectId, params, graph) =>
  action(SEND_BUDGET_REPORT, {
    projectId,
    params,
    graph,
  });
