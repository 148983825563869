import PropTypes from "prop-types";
import React from "react";

import RecurrenceCustomModal from "./RecurrenceCustomModal";
import RecurrenceDropdown from "./RecurrenceDropdown";

export default class RecurrenceInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  onCustomModalClose = () => {
    this.setState({ modalOpen: false });
  };

  onCustomModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  onChange = (data) => {
    this.setState({
      modalOpen: false,
    });
    this.props.onChange(null, {
      name: "ruleInput",
      value: {
        endRecurringPeriod: data.endRecurringPeriod,
        frequency: data.frequency,
        params: data.params,
      },
    });
  };

  render() {
    const { className, disabled, endRecurringPeriod, frequency, params, startDate } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <RecurrenceDropdown
          className={className}
          disabled={disabled}
          endRecurringPeriod={endRecurringPeriod}
          frequency={frequency}
          inline
          params={params}
          startDate={startDate}
          onChange={this.onChange}
          onCustom={this.onCustomModalOpen}
        />
        <RecurrenceCustomModal
          open={modalOpen}
          endRecurringPeriod={endRecurringPeriod}
          frequency={frequency}
          params={params}
          startDate={startDate}
          onCancel={this.onCustomModalClose}
          onSave={this.onChange}
        />
      </>
    );
  }
}

RecurrenceInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endRecurringPeriod: PropTypes.string,
  frequency: PropTypes.string,
  startDate: PropTypes.shape().isRequired,
  params: PropTypes.shape(),

  onChange: PropTypes.func.isRequired,
};
