export function createReducerType(entity, initial = {}) {
  return (
    state = {
      loading: "loading" in initial ? initial.loading : false,
      success: "success" in initial ? initial.success : true,
      failure: "failure" in initial ? initial.failure : false,
      errorDetails: "errorDetails" in initial ? initial.errorDetails : null,
    },
    action
  ) => {
    const { type } = action;

    switch (type) {
      case entity.REQUEST:
        return { ...state, loading: true, success: false, failure: false, errorDetails: null };
      case entity.SUCCESS:
        return { ...state, loading: false, success: true, failure: false, errorDetails: null };
      case entity.FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          failure: true,
          errorDetails: { ...action.errorDetails },
        };
      default:
        return state;
    }
  };
}

export function createReducerTypeTrackLast(entity) {
  return (
    state = {
      loading: false,
      success: true,
      failure: false,
      errorDetails: null,
      lastId: null,
    },
    action
  ) => {
    const { type, response = { result: null } } = action;

    switch (type) {
      case entity.REQUEST:
        return { ...state, loading: true, success: false, failure: false, errorDetails: null };
      case entity.SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          failure: false,
          errorDetails: null,
          lastId: response.result,
        };
      case entity.FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          failure: true,
          errorDetails: { ...action.errorDetails },
        };
      default:
        return state;
    }
  };
}
