import classnames from "classnames";
import moment from "moment-timezone";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Dropdown } from "semantic-ui-react";

import { computePickerPosition } from "utils/chat";
import { isMobile } from "utils/responsive";

import MessageBoxEdit from "../MessageBoxEdit";
import ModalPollDelete from "./ModalPollDelete";
import ReactionPicker from "./ReactionPicker";
import styles from "./styles.module.scss";

const STEP_VOTE = "STEP_VOTE";

export default class MessageListItemOptions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editModal: false,
      deleteModal: false,
      deletePollModal: false,
      picker: false,
      pickerStyle: {},
    };
  }

  handleShowEditModal = () => {
    this.setState({ editModal: true });
  };

  handleHideEditModal = () => {
    this.setState({ editModal: false });
  };

  handleShowDeleteModal = () => {
    this.setState({ deleteModal: true });
  };

  handleHideDeleteModal = () => {
    this.setState({ deleteModal: false });
  };

  handleShowDeletePollModal = () => {
    this.setState({ deletePollModal: true });
  };

  handleHideDeletePollModal = () => {
    this.setState({ deletePollModal: false });
  };

  handlePin = () => {
    const { room } = this.props;
    this.props.onPin(this.props.message, room);
  };

  handleUnpin = () => {
    const { room } = this.props;
    this.props.onUnpin(this.props.message, room);
  };

  handlePollArchive = () => {
    const {
      message: { poll },
      onPollArchive,
    } = this.props;
    if (poll) {
      onPollArchive(poll);
    }
  };

  handlePollPin = async () => {
    const {
      message: { poll },
      onPollPin,
    } = this.props;
    if (poll) {
      onPollPin(poll);
    }
  };

  handlePicker = (event) => {
    const { target } = event;
    const targetBtn = target.closest("button") || target.closest(".button");
    this.setState(({ picker }) => ({
      picker: !picker,
      pickerStyle: computePickerPosition(targetBtn),
    }));
  };

  handleClosePicker = () => {
    this.setState(({ picker }) => ({
      picker: !picker,
    }));
  };

  _isVoteable = () => {
    const {
      message: { poll },
      room,
    } = this.props;

    if (poll) {
      const dateTime = [poll.archivedAt, poll.expireAt].sort()[0];
      return (
        poll.isVoteable &&
        room.isActive &&
        (!dateTime || moment(dateTime).isAfter(moment())) &&
        poll.hasVoted
      );
    }
    return false;
  };

  renderMessageDropdown = () => {
    const { direction, message, user, isPinned } = this.props;
    const own = user.slug === message.author;

    return (
      <Dropdown
        icon={{
          name: "ellipsis horizontal",
          size: "small",
        }}
        button
        basic
        upward={direction === "up"}
        direction="left"
        className="icon"
      >
        <Dropdown.Menu className={styles.actionsMenu}>
          {!message.pinnedBy && (
            <Dropdown.Item onClick={this.handlePin}>Pin to channel</Dropdown.Item>
          )}
          {message.pinnedBy && (
            <Dropdown.Item onClick={this.handleUnpin}>Unpin from channel</Dropdown.Item>
          )}
          {isPinned && (
            <Dropdown.Item className={`add-reaction-${message.id}`} onClick={this.handlePicker}>
              Add reaction
            </Dropdown.Item>
          )}
          {own && !message.event && (
            <Dropdown.Item className={styles.editOption} onClick={this.handleShowEditModal}>
              Edit
            </Dropdown.Item>
          )}
          {own && !message.event && (
            <Dropdown.Item className={styles.deleteOption} onClick={this.handleShowDeleteModal}>
              Delete
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  renderPollDropdown = () => {
    const { onChangeStep, room, isPinned, message, pollStep, direction } = this.props;
    const { deletePollModal } = this.state;
    const { poll } = message;

    return (
      <React.Fragment>
        <Dropdown
          icon={{
            name: "ellipsis horizontal",
            size: "small",
          }}
          button
          basic
          upward={direction === "up"}
          direction="left"
          className="icon"
        >
          <Dropdown.Menu className={styles.actionsMenu}>
            {!poll.isArchived && poll.isEditable && (
              <Dropdown.Item onClick={this.handlePollPin}>
                {poll.isPinned ? "Unpin from Channel" : "Pin to Channel"}
              </Dropdown.Item>
            )}
            <Dropdown.Item as={Link} to={`/chat/r/${room.id}/polls/${poll.id}`}>
              View poll
            </Dropdown.Item>
            {this._isVoteable() && (
              <Dropdown.Item onClick={onChangeStep}>
                {pollStep === STEP_VOTE ? "View results" : "Change vote"}
              </Dropdown.Item>
            )}
            {isPinned && (
              <Dropdown.Item className={`add-reaction-${message.id}`} onClick={this.handlePicker}>
                Add reaction
              </Dropdown.Item>
            )}
            {!poll.isArchived && poll.isEditable && (
              <Dropdown.Item
                className={styles.editOption}
                as={Link}
                to={`/chat/r/${room.id}/polls/${poll.id}/edit`}
              >
                Edit
              </Dropdown.Item>
            )}
            {poll.isEditable && (
              <Dropdown.Item onClick={this.handlePollArchive}>
                {poll.isArchived ? "Unarchive" : "Archive"}
              </Dropdown.Item>
            )}
            {poll.isEditable && (
              <Dropdown.Item
                className={styles.deleteOption}
                onClick={this.handleShowDeletePollModal}
              >
                Delete
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {poll.isEditable && (
          <ModalPollDelete
            poll={poll}
            open={deletePollModal}
            onClose={this.handleHideDeletePollModal}
            onOpen={this.handleShowDeletePollModal}
          />
        )}
      </React.Fragment>
    );
  };

  render() {
    const { deleteMessage, isThread, isPinned, message, onOpenThread, room, user } = this.props;
    const { deleteModal, editModal, picker, pickerStyle } = this.state;
    const own = user.slug === message.author;

    return (
      <div className={classnames(styles.options, isMobile() ? styles.mobile : undefined)}>
        <Button.Group>
          {isPinned && (
            <Button
              as={Link}
              basic
              size="small"
              to={`/chat/r/${room.id}/${message.id}`}
              content="Jump"
            />
          )}
          {isPinned && picker && (
            <ReactionPicker
              room={room}
              message={message}
              showPicker={picker}
              pickerStyle={pickerStyle}
              onClose={this.handleClosePicker}
              trigger={false}
            />
          )}
          {!isPinned && <ReactionPicker room={room} message={message} />}
          {!isThread && (
            <Button
              basic
              type="button"
              size="small"
              icon="reply"
              onClick={onOpenThread}
              title="Reply"
            />
          )}
          {!message.poll ? this.renderMessageDropdown() : this.renderPollDropdown()}

          {!message.poll && own && (
            <MessageBoxEdit
              deleteOpen={deleteModal}
              editOpen={editModal}
              message={message}
              deleteMessage={deleteMessage}
              onDeleteModalClose={this.handleHideDeleteModal}
              onEditModalClose={this.handleHideEditModal}
              onEmptyMessage={this.handleShowDeleteModal}
              room={room}
            />
          )}
        </Button.Group>
      </div>
    );
  }
}

MessageListItemOptions.propTypes = {
  deleteMessage: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(["up", "down"]),
  isThread: PropTypes.bool,
  isPinned: PropTypes.bool,
  room: PropTypes.shape().isRequired,
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    event: PropTypes.shape(),
    poll: PropTypes.shape(),
    author: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    pinnedBy: PropTypes.string,
  }).isRequired,
  pollStep: PropTypes.string,
  user: PropTypes.shape().isRequired,
  onOpenThread: PropTypes.func,
  onPin: PropTypes.func.isRequired,
  onUnpin: PropTypes.func.isRequired,
  onPollArchive: PropTypes.func.isRequired,
  onPollPin: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func,
};

MessageListItemOptions.defaultProps = {
  direction: "down",
  isThread: false,
  isPinned: false,
  pollStep: STEP_VOTE,
  onChangeStep: () => {},
};
