import { createRequestTypes, action } from ".";

export const ATTACHMENT = createRequestTypes("ATTACHMENT");
ATTACHMENT.PROGRESS = "ATTACHMENT_PROGRESS";
ATTACHMENT.CLEAR = "ATTACHMENT_CLEAR";
export const ATTACHMENT_DELETE = createRequestTypes("ATTACHMENT_DELETE");

export const attachment = {
  init: (id, file, generic) => action(ATTACHMENT.INIT, { id, file, generic }),
  request: (id, file) => action(ATTACHMENT.REQUEST, { id, file }),
  success: (id, file, response) => action(ATTACHMENT.SUCCESS, { id, file, response }),
  failure: (id, file, error) => action(ATTACHMENT.FAILURE, { id, file, error }),
  progress: (id, file, progress) => action(ATTACHMENT.PROGRESS, { id, file, progress }),
  clear: (id) => action(ATTACHMENT.CLEAR, { id }),
};

export const attachmentDelete = {
  request: (id, fileId) => action(ATTACHMENT_DELETE.REQUEST, { id, fileId }),
};
