import EmailValidator from "email-validator";
import { isEmpty, isString } from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button, Checkbox, Form, Input, Header } from "semantic-ui-react";

import config from "config";
import FuzzySearch from "components/FuzzySearch";
import UserAvatar from "components/UserAvatar";
import UserPill from "components/UserPill";

import * as styles from "./styles.module.scss";

export default class People extends React.Component {
  constructor(props) {
    super(props);
    this.projectInput = React.createRef();
  }

  componentDidMount() {
    this.projectInput.current.focus();
  }

  matchRenderer = (match, onClick, selected) => (
    <div
      className={classnames(styles.sectionMemberMatch, selected && styles.selected)}
      key={match.item.slug}
      onClick={() => onClick(match.item)}
      onKeyPress={() => onClick(match.item)}
      role="menuitem"
      tabIndex="-1"
      title={`${match.item.displayName}${match.item.email ? ` (${match.item.email})` : ""}`}
    >
      <UserAvatar
        avatar={match.item.avatar}
        bgColor={match.item.avatarBgcolor}
        className={styles.sectionMemberMatchAvatar}
        displayName={match.item.displayName}
        fgColor={match.item.avatarColor}
        size="16"
      />
      <span>
        {match.item.displayName}
        {match.item.email ? ` (${match.item.email})` : ""}
      </span>
    </div>
  );

  valuesRenderer = (values, onClick) =>
    values.map((value, index) => {
      const avatar = isString(value) ? null : value.avatarOrDefault;
      const text = isString(value) ? value : value.displayName;

      return (
        <UserPill
          key={index}
          avatar={avatar}
          bgColor={value.avatarBgcolor}
          fgColor={value.avatarColor}
          onClick={() => onClick(index)}
          text={text}
        />
      );
    });

  valuesValidator = (value) => EmailValidator.validate(value);

  render() {
    const {
      appliedForMarketplace,
      members,
      team,
      users,
      onMemberChange,
      onTeamNameChange,
      onGenerateName,
      onNext,
    } = this.props;

    const memberSlugs = members.filter((member) => !isString(member)).map((member) => member.slug);
    const usersFiltered = users.filter((user) => !memberSlugs.includes(user.slug));

    return (
      <React.Fragment>
        <Form onSubmit={onNext}>
          <Header as="h4" content="Name team" />
          <Form.Field>
            <Input
              ref={this.projectInput}
              fluid
              placeholder="Enter team name"
              name="team"
              value={team}
              onChange={onTeamNameChange}
              maxLength="200"
              action={{
                basic: true,
                onClick: onGenerateName,
                content: "Generate name",
                icon: "random",
                type: "button",
              }}
            />
          </Form.Field>
          <small className={styles.helper}>{team.length} / 200</small>

          <Header as="h4" content="Add members" />

          <FuzzySearch
            allowAdditions
            keys={["displayName", "email"]}
            matchRenderer={this.matchRenderer}
            multiple
            onValuesChange={onMemberChange}
            options={usersFiltered}
            placeholder="Search a name or type an email address"
            values={members}
            valuesRenderer={this.valuesRenderer}
            valuesValidator={this.valuesValidator}
          />

          {config.flags.marketplace && (
            <Form.Field>
              <Checkbox
                checked={appliedForMarketplace}
                className={styles.helper}
                label="Make this team available on the Marketplace"
                name="appliedForMarketplace"
                onChange={(e, { checked }) =>
                  onTeamNameChange(e, { name: "appliedForMarketplace", value: checked })
                }
                toggle
              />
            </Form.Field>
          )}
          <div className={styles.prevNext}>
            <Button
              primary
              type="submit"
              floated="right"
              content="Continue"
              disabled={isEmpty(members) || team.length === 0}
            />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

People.propTypes = {
  appliedForMarketplace: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])).isRequired,
  team: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape()),
  onMemberChange: PropTypes.func.isRequired,
  onTeamNameChange: PropTypes.func.isRequired,
  onGenerateName: PropTypes.func.isRequired,
  onNext: PropTypes.func,
};

People.defaultProps = {
  onNext: () => {},
};
