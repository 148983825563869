import React from "react";
import PropTypes from "prop-types";
import { Picker } from "emoji-mart";
import { Modal, Responsive } from "semantic-ui-react";

export default class ResponsiveEmojiPicker extends React.Component {
  render() {
    const { style, ...passThroughProps } = this.props;
    const mobileStyle = {
      ...style,
      position: "static",
      width: "unset",
    };

    return (
      <React.Fragment>
        <Responsive as={Modal} open size="small" {...Responsive.onlyMobile}>
          <Modal.Content as={Picker} style={mobileStyle} {...passThroughProps} />
        </Responsive>
        <Responsive
          as={Picker}
          minWidth={Responsive.onlyMobile.maxWidth + 1}
          style={style}
          {...passThroughProps}
        />
      </React.Fragment>
    );
  }
}

ResponsiveEmojiPicker.propTypes = {
  style: PropTypes.shape(),
};
