import localforage from "localforage";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware, { END } from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "../reducers";

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();

  const persistConfig = {
    key: "state",
    storage: localforage,
    blacklist: ["entities"],
    whitelist: ["auth", "rooms"],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(sagaMiddleware))
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  const persistor = persistStore(store);

  return { store, persistor };
}
