import querystring from 'querystring';
import { decamelizeKeys } from 'humps';
import * as schema from 'services/schema';

import { callApi, callApiWithToken, getFullUrl } from './base';

export const addRoomMembers = (token, id, emails = [], userSlugs = []) =>
  callApiWithToken(
    `chat/${id}/users/`,
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        emails,
        userSlugs,
      })
    )
  );

export const removeRoomMember = (token, id, userSlug) =>
  callApiWithToken(`chat/${id}/users/${userSlug}/`, token, null, 'DELETE');

export const createRoom = (token, data = {}) =>
  callApiWithToken(
    'chat/',
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const countUnreadMessages = (token) =>
  callApiWithToken('chat/messages/count/?has_read=0', token);

export const fetchAttachments = (token, id, url = null) =>
  callApiWithToken(url || `chat/${id}/files/`, token);

export const fetchMessages = (token, id, jump, url = null) =>
  jump
    ? callApiWithToken(url || `chat/${id}/messages/?jump=${jump}`, token)
    : callApiWithToken(url || `chat/${id}/messages/`, token);

export const fetchPinnedMessages = (token, id, url = null) =>
  callApiWithToken(url || `chat/${id}/messages/?pinned=1`, token);

export const fetchThread = (token, id, messageId, url = null) =>
  callApiWithToken(url || `chat/${id}/messages/?root=${messageId}`, token);

export const fetchSearch = (token, id, search, sort) =>
  callApiWithToken(`chat/${id}/messages/?${querystring.stringify({ search, sort })}`, token);

export const fetchMessage = (token, id, mid) =>
  callApiWithToken(`chat/${id}/messages/${mid}/`, token);

export const fetchRoom = (token, id) => callApiWithToken(`chat/${id}/`, token);
export const fetchRooms = (token) => callApiWithToken('chat/', token);

export const readMessages = (token, id) =>
  callApiWithToken(`chat/${id}/messages/read/`, token, null, 'PATCH', {
    'Content-Type': 'application/json',
  });

export const editMessage = (token, id, messageId, content) =>
  callApiWithToken(
    `chat/${id}/messages/${messageId}/`,
    token,
    null,
    'PATCH',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        content,
      })
    )
  );

export const deleteMessage = (token, id, messageId) =>
  callApiWithToken(`chat/${id}/messages/${messageId}/`, token, null, 'DELETE', {
    'Content-Type': 'application/json',
  });

export const pinMessage = (token, id, messageId) =>
  callApiWithToken(`chat/${id}/messages/${messageId}/pin/`, token, null, 'POST', {
    'Content-Type': 'application/json',
  });

export const unpinMessage = (token, id, messageId) =>
  callApiWithToken(`chat/${id}/messages/${messageId}/unpin/`, token, null, 'POST', {
    'Content-Type': 'application/json',
  });

export const sendMessage = (token, id, content, sendId, files, root) =>
  callApiWithToken(
    `chat/${id}/messages/`,
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        content,
        sendId,
        fileIds: Object.keys(files)
          .filter((fileId) => files[fileId].id)
          .map((fileId) => files[fileId].id),
        root,
      })
    )
  );

// eslint-disable-next-line no-unused-vars
export const uploadAttachment = (token, generic) => {
  const xhr = new XMLHttpRequest();
  const fullUrl = getFullUrl(`chat/files/`);

  xhr.open('POST', fullUrl, true);
  xhr.responseType = 'json';
  xhr.setRequestHeader('Authorization', `Token ${token}`);
  return xhr;
};

export const disconnectCall = (token, callCode, callerId) =>
  callApiWithToken(
    `call/${callCode}/disconnect/`,
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        callerId,
      })
    )
  );

export const signalCall = (token, callCode, callId, callerId) =>
  callApiWithToken(
    `call/${callCode}/signal/`,
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        callId,
        callerId,
      })
    )
  );

export const startCall = (token, callCode, displayName) =>
  callApiWithToken(
    `call/${callCode}/call/`,
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(decamelizeKeys({ displayName }))
  );

export const chatAuthUrl = getFullUrl('chat/auth/');

export const invite = (token, id, name, roomId) =>
  callApiWithToken(
    'invite/',
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        projectName: name,
        projectId: id,
        roomId,
      })
    )
  );

export const renameRoom = (token, id, name) =>
  callApiWithToken(
    `chat/${id}/`,
    token,
    null,
    'PATCH',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify({
      name,
    })
  );

export const hideRoom = (token, id) => callApiWithToken(`chat/${id}/hide/`, token, null, 'POST');

export const performAction = (token, action, data) =>
  callApiWithToken(
    action,
    token,
    null,
    'PUT',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify({
      _post: data,
    })
  );

export const startTyping = (token, id) =>
  callApiWithToken(
    `chat/${id}/typing/`,
    token,
    null,
    'PUT',
    {
      'Content-Type': 'application/json',
    },
    ''
  );

export const previewAttachment = (filePath) => callApi(filePath);

export const invitationDelete = (token, invitation) =>
  callApiWithToken(`project_invitations/${invitation.id}/`, token, null, 'DELETE');

export const invitationResend = (token, invitation) =>
  callApiWithToken(`project_invitations/${invitation.id}/resend/`, token, null, 'POST');

export const pollCreate = (token, roomId, poll = {}) =>
  callApiWithToken(
    `chat/${roomId}/polls/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(poll))
  );

export const pollDelete = (token, pollId) =>
  callApiWithToken(`chat/polls/${pollId}/`, token, null, 'DELETE');

export const pollDetail = (token, pollId) => callApiWithToken(`chat/polls/${pollId}/`, token);

export const pollList = (token, roomId, archived = false, page = 1) => {
  const isArchived = archived ? '1' : '0';
  const endpoint = `chat/${roomId}/polls/?is_archived=${isArchived}&page=${page}`;

  return callApiWithToken(endpoint, token);
};

export const pollDelegate = (token, pollId, delegateSlug) =>
  callApiWithToken(
    `chat/polls/${pollId}/delegate/`,
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(decamelizeKeys({ delegateSlug }))
  );

export const pollMultipleVote = (token, pollId, votes) =>
  callApiWithToken(
    `chat/polls/${pollId}/vote/`,
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(decamelizeKeys({ votes }))
  );

export const pollSearchSkills = (token, question) =>
  callApiWithToken(
    'chat/polls/skills/',
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys({ question }))
  );

export const pollUpdate = (token, poll = {}) =>
  callApiWithToken(
    `chat/polls/${poll.id}/`,
    token,
    null,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(poll))
  );

export const slashCommand = (token, id, command, data) =>
  callApiWithToken(
    `chat/${id}/slash/${command}/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const roomMembershipUpdate = (id, token, data = {}) =>
  callApiWithToken(
    `memberships/${id}/`,
    token,
    schema.roomMembership,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const roomUpdate = (token, id, fields = {}) =>
  callApiWithToken(
    `chat/${id}/`,
    token,
    null,
    'PATCH',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(decamelizeKeys(fields))
  );

export const fetchReactions = (token, id, messageId) =>
  callApiWithToken(
    `chat/${id}/messages/${messageId}/reactions/`,
    token,
    schema.reactionSchemaArray,
    'GET',
    { 'Content-Type': 'application/json' }
  );

export const reactionsAdd = (token, id, messageId, emoji) =>
  callApiWithToken(
    `chat/${id}/messages/${messageId}/reactions/`,
    token,
    schema.reactionSchema,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys({ emoji }))
  );

export const reactionsDelete = (token, id, messageId, emoji) =>
  callApiWithToken(
    `chat/${id}/messages/${messageId}/reactions/?${querystring.stringify({ emoji })}`,
    token,
    null,
    'DELETE',
    { 'Content-Type': 'application/json' }
  );

export const fetchRoomCalendar = (token, url) =>
  callApiWithToken(url, token, schema.calendarSchema);

export const fetchRoomMemberCalendar = (token, url) =>
  callApiWithToken(url, token, schema.calendarSchemaArray);

export const fetchRoomMeetings = (token, id) =>
  callApiWithToken(`chat/${id}/meetings/`, token, schema.eventSchemaArray);

export const roomMeetingCreate = (token, id, data) =>
  callApiWithToken(
    `chat/${id}/meetings/`,
    token,
    schema.eventSchema,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const roomMeetingUpdate = (token, rid, oid, data) =>
  callApiWithToken(
    `chat/${rid}/meetings/${oid}/`,
    token,
    schema.eventSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const roomMeetingDelete = (token, rid, oid, email) =>
  callApiWithToken(`chat/${rid}/meetings/${oid}/?email=${email}`, token, null, 'DELETE', {
    'Content-Type': 'application/json',
  });

export const roomMeetingDetail = (token, rid, oid) =>
  callApiWithToken(`chat/${rid}/meetings/${oid}/`, token, schema.eventSchema);

export const roomMeetingJoin = (token, rid, oid) =>
  callApiWithToken(`chat/${rid}/meetings/${oid}/join/`, token, schema.eventSchema, 'POST');

export const roomMeetingDecline = (token, rid, oid) =>
  callApiWithToken(`chat/${rid}/meetings/${oid}/decline/`, token, schema.eventSchema, 'POST');

export const defaultMeetingsCreate = (token, id, data) =>
  callApiWithToken(
    `chat/${id}/default_meetings/`,
    token,
    schema.eventSchemaArray,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
