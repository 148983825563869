import { decamelizeKeys } from 'humps';
import querystring from 'querystring';
import { workLogSchema, workLogsSchema } from 'services/schema';
import { callApiWithToken } from './base';

export const fetchWorkLogs = (token, params = {}) =>
  callApiWithToken(`work_logs/?${querystring.stringify(params)}`, token, workLogsSchema);

export const createWorkLog = (token, data) =>
  callApiWithToken(
    'work_logs/',
    token,
    workLogSchema,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const updateWorkLog = (token, id, data) =>
  callApiWithToken(
    `work_logs/${id}/`,
    token,
    workLogSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const deleteWorkLog = (token, id) =>
  callApiWithToken(`work_logs/${id}/`, token, null, 'DELETE', {
    'Content-Type': 'application/json',
  });

export const exportWorkLogs = (token, params = {}) =>
  callApiWithToken(`work_logs_export/?${querystring.stringify(params)}`, token, null);
