import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";

import * as styles from "./styles.module.scss";

const Done = () => (
  <Container>
    <Header as="h2" content="Reset your password" className={styles.header} />

    <p>Your password has been reset. You may login now.</p>

    <p>
      <Link to="/login">Login</Link>
    </p>
  </Container>
);

export default Done;
