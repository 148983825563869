import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Input } from "semantic-ui-react";

import styles from "containers/Profile/styles.module.scss";

class EditWorkLogModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      project: "",
    };
  }

  focusInput = (input) => {
    if (input) input.focus();
  };

  handleClose = () => {
    this.setState({ project: "" });
    this.props.onClose();
  };

  handleConfirm = () => {
    this.setState({ project: "" });
    this.props.onConfirm();
  };

  handleChange = (e, { name, value }) =>
    this.setState(() => ({
      [name]: value,
    }));

  render() {
    const { open, event, projects } = this.props;
    const { project } = this.state;

    const eventProject = projects.find((proj) => proj.id === event.project);
    const projectName = eventProject?.text;

    return (
      <Modal
        onClose={this.handleClose}
        open={open}
        className={styles.hoursNotificationsModal}
        closeIcon
      >
        <Modal.Content className={styles.modalContent}>
          <p className={styles.heading}>Confirm Edit</p>
          <p className={styles.content}>
            You are attempting to edit a log that was created more than 24 hours ago.
            <br />
            Please enter the project name below to confirm your edit.
            <br />
            <br />
            Project Name: <b>{projectName}</b>
          </p>
          <Input
            name="project"
            fluid
            value={project}
            onChange={this.handleChange}
            placeholder="Enter the project name"
            ref={this.focusInput}
            classname={styles.input}
          />
          <hr />
          <div className={styles.actionsContainer}>
            <Button
              disabled={project !== projectName}
              className={classnames(styles.action, styles.cancel)}
              onClick={this.handleConfirm}
            >
              Confirm Edit
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

EditWorkLogModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  projects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  event: PropTypes.shape().isRequired,
};

EditWorkLogModal.defaultProps = {
  open: false,
};

export default EditWorkLogModal;
