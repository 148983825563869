import { call, fork, put, take } from "redux-saga/effects";
import { api, token as tokenService } from "services";
import { stringify as queryStringify } from "querystring-es3";

import * as budget from "actions/budget";
import * as project from "actions/project";
import * as user from "actions/user";

import { fetchEntity } from "./base";

const fetchBudgetVisualization = fetchEntity.bind(
  null,
  budget.budgetVisualization,
  api.fetchBudgetVisualization
);

export function* loadBudgetVisualization({ params, refresh }) {
  const query = queryStringify(params);
  const baseUrl = "budget/waterfall/";
  const url = query ? `${baseUrl}?${query}` : baseUrl;
  const token = yield tokenService.getAuthToken();
  yield call(fetchBudgetVisualization, refresh, token, url);
}

function* updateBudget(slug, pid, rSlug, amount, description, params) {
  yield put(budget.updateBudget.request());
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.updateBudget, token, pid, rSlug, amount, description);
  if (response) {
    yield put(budget.updateBudget.success(response));
    yield put(budget.budgetVisualization.init(params, true));
    yield put(project.loadProjects());
    yield put(user.loadUser(slug, true));
  } else if (error) {
    yield put(budget.updateBudget.failure(error));
  }
}

function* getBudgetTracker(request) {
  const token = yield tokenService.getAuthToken();
  const { params } = request;
  const { response, error } = yield call(api.getBudgetTracker, token, params);
  if (response) {
    yield put(budget.getBudgetTracker.success(response));
  } else {
    yield put(budget.getBudgetTracker.failure(error));
  }
}

function* sendBudgetReport({ projectId, params, graph }) {
  const token = yield tokenService.getAuthToken();
  yield call(api.sendBudgetReport, token, projectId, params, graph);
}

export function* watchUpdateBudget() {
  while (true) {
    const { slug, pid, rSlug, amount, description, params } = yield take(budget.UPDATE_BUDGET.INIT);
    yield updateBudget(slug, pid, rSlug, amount, description, params);
  }
}

export function* watchGetBudgetTracker() {
  while (true) {
    const request = yield take(budget.GET_BUDGET_TRACKER.REQUEST);
    yield fork(getBudgetTracker, request);
  }
}

export function* watchSendBudgetReport() {
  while (true) {
    const request = yield take(budget.SEND_BUDGET_REPORT);
    yield fork(sendBudgetReport, request);
  }
}
