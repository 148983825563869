import classnames from "classnames";
import Humanize from "humanize-plus";
import { sortBy } from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

import { getAttachments } from "actions/chat";
import { sidePanel, PANELS } from "actions/sidePanel";
import { getCurrentRoom, getCurrentRoomId, getCurrentProject } from "reducers/selectors";

import ScrollableContent from "components/ScrollableContent";
import AttachmentItem from "./AttachmentItem";
import * as sidePanelStyles from "../../styles.module.scss";
import * as styles from "./styles.module.scss";

class SidePanelAttachments extends React.Component {
  componentDidMount() {
    this.loadAttachments();
  }

  componentDidUpdate() {
    const { nextItemsUrl } = this.props;
    if (nextItemsUrl) {
      this.loadAttachments(nextItemsUrl);
    }
  }

  loadAttachments = (nextItemsUrl) => {
    const { currentRoom, getRoomAttachments, token, isLoadingAttachments } = this.props;
    if (isLoadingAttachments) return;

    if (nextItemsUrl) {
      getRoomAttachments({ id: currentRoom.id, token, url: nextItemsUrl });
    } else {
      getRoomAttachments({ id: currentRoom.id, token });
    }
  };

  render() {
    const {
      attachments,
      currentRoom,
      hasProjectUrls,
      visible,
      panelId,
      sidePanelClose,
    } = this.props;

    if (!visible || panelId !== PANELS.ATTACHMENTS) {
      return null;
    }

    return (
      <ScrollableContent
        idPrefix="attachments-sidepanel"
        containerClass={sidePanelStyles.sidePanelBoxShadow}
      >
        <div className={classnames(sidePanelStyles.sidePanel, styles.attachments)}>
          <div className={sidePanelStyles.heading}>
            <p>
              {attachments.length} {Humanize.pluralize(attachments.length, "Attachment")}
            </p>
            <Button
              basic
              content="Close"
              onClick={() => sidePanelClose(PANELS.ATTACHMENTS, { roomId: currentRoom.id })}
              className={classnames(
                sidePanelStyles.actionButton,
                sidePanelStyles.close,
                hasProjectUrls && sidePanelStyles.hasProjectUrlsClose
              )}
            />
          </div>
          <div className={styles.content}>
            {attachments.map((attachment) => (
              <AttachmentItem
                key={attachment.file || attachment.data.preview}
                files={attachments}
                fileId={attachment.id || attachment.fileId}
              />
            ))}
          </div>
        </div>
      </ScrollableContent>
    );
  }
}

SidePanelAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  currentRoom: PropTypes.shape().isRequired,
  hasProjectUrls: PropTypes.bool.isRequired,
  isLoadingAttachments: PropTypes.bool,
  nextItemsUrl: PropTypes.string,
  panelId: PropTypes.string,
  sidePanelClose: PropTypes.func,
  token: PropTypes.string.isRequired,
  visible: PropTypes.bool,

  getRoomAttachments: PropTypes.func.isRequired,
};

SidePanelAttachments.defaultProps = {
  attachments: [],
  isLoadingAttachments: false,
};

function mapStateToProps(state) {
  const {
    auth: { token },
    sidePanel: { panelId, visible },
    messages: { attachments: attachmentsByRooms },
    messageAttachments: { nextItemsUrls, loading: attachmentsLoading },
  } = state;
  const currentRoom = getCurrentRoom(state);
  const currentRoomId = getCurrentRoomId(state);
  const roomAttachments = attachmentsByRooms[currentRoomId] || {};
  const nextItemsUrl = nextItemsUrls[currentRoomId] || null;
  const isLoadingAttachments = !!attachmentsLoading[currentRoomId];
  const attachments = Object.keys(roomAttachments).map((id) => roomAttachments[id]);
  const sortedAttachments = sortBy(attachments, ["created"]).reverse();
  const project = getCurrentProject(state);
  const hasProjectUrls = Boolean(project && project.gitlabUrl);

  return {
    attachments: sortedAttachments,
    currentRoom,
    hasProjectUrls,
    isLoadingAttachments,
    nextItemsUrl,
    panelId,
    token,
    visible,
  };
}

export default connect(mapStateToProps, {
  getRoomAttachments: getAttachments.request,
  sidePanelClose: sidePanel.close,
})(SidePanelAttachments);
