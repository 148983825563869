import { take, call, fork, all, takeLatest, takeEvery, put } from "redux-saga/effects";

import config from "config";
import { api, history } from "services";
import { NAVIGATE } from "actions";

import { AUTH_PASSWORD_CHANGE } from "actions/account";
import * as user from "actions/user";
import * as calendar from "actions/calendar";
import * as project from "actions/project";
import * as budget from "actions/budget";
import * as skill from "actions/skill";

import { fetchEntity } from "./base";
import {
  authFlowSaga,
  initialLogin,
  passwordChange,
  watchPasswordlessGetToken,
  watchPasswordlessValidateToken,
  watchPasswordlessLogin,
  watchPasswordReset,
  watchPasswordResetConfirm,
  watchRegister,
  watchTeamInvitation,
  watchSetLoginExpiration,
} from "./account";
import {
  watchUpdateBudget,
  loadBudgetVisualization,
  watchGetBudgetTracker,
  watchSendBudgetReport,
} from "./budget";
import { rootChat } from "./chat";
import {
  watchVersionCommentAdd,
  watchVersionCommentUpdate,
  watchVersionCommentDelete,
} from "./log";
import { watchSocket } from "./socket";
import {
  loadProjects,
  loadTalentProjects,
  projectDelete,
  updateProject,
  watchChannelDelete,
  watchChannelsCreateOrUpdate,
  watchCreateLink,
  watchCreateProject,
  watchGetBudgetHistory,
  watchGetSuggestedTimes,
  watchReadBudgetHistory,
  watchInvitationAdd,
  watchInvitationsUpdate,
  watchLinkDelete,
  watchLinksCreateOrUpdate,
  watchMembershipDelete,
  watchMembershipUpdate,
  watchProjectBudgetUpdate,
  watchProjectUpdate,
} from "./project";
import { watchFeedback } from "./feedback";
import { watchClientFeedback } from "./clientFeedback";
import { watchSkills } from "./skill";
import { watchHealth } from "./health";
import { watchReminders } from "./reminders";
import {
  loadUser,
  deleteUser,
  loadTeam,
  deleteTeam,
  deleteTeamMembership,
  loadUserCalendar,
  createEvent,
  updateEvent,
  deleteEvent,
  eventDetail,
  eventOccurrenceDetail,
  loadTalentTeam,
  loadTeamCalendar,
  loadTeamTalents,
  loadTeamTalentInvites,
  addGoogleCalendar,
  addOutlookCalendar,
  watchUserPresence,
  watchPing,
  watchUpdateProfile,
} from "./user";
import { watchNotification } from "./notification";
import { rootAttachments } from "./attachments";
import { rootSubscription } from "./subscription";
import { watchFetchWorkLogs, watchExportWorkLogs } from "./timetracker";
import { watchWeeklyAssignments } from "./schedule";

export const fetchProjects = fetchEntity.bind(null, project.project, api.fetchProjects);

// trigger router navigation via history
export function* watchNavigate() {
  while (true) {
    const { pathname, replace } = yield take(NAVIGATE);
    if (replace) {
      yield history.replace(pathname);
    } else {
      yield history.push(pathname);
    }
  }
}

const forkWatch = [
  fork(authFlowSaga),
  fork(rootAttachments),
  fork(rootChat),
  fork(rootSubscription),
  fork(watchVersionCommentAdd),
  fork(watchVersionCommentUpdate),
  fork(watchVersionCommentDelete),
  fork(watchClientFeedback),
  fork(watchChannelDelete),
  fork(watchChannelsCreateOrUpdate),
  fork(watchCreateLink),
  fork(watchCreateProject),
  fork(watchExportWorkLogs),
  fork(watchFetchWorkLogs),
  fork(watchGetBudgetHistory),
  fork(watchGetSuggestedTimes),
  fork(watchReadBudgetHistory),
  fork(watchInvitationAdd),
  fork(watchInvitationsUpdate),
  fork(watchLinkDelete),
  fork(watchLinksCreateOrUpdate),
  fork(watchMembershipDelete),
  fork(watchMembershipUpdate),
  fork(watchProjectBudgetUpdate),
  fork(watchNavigate),
  fork(watchNotification),
  fork(watchPasswordlessGetToken),
  fork(watchPasswordlessValidateToken),
  fork(watchPasswordlessLogin),
  fork(watchPasswordReset),
  fork(watchPasswordResetConfirm),
  fork(watchPing),
  fork(watchProjectUpdate),
  fork(watchRegister),
  fork(watchSetLoginExpiration),
  fork(watchSkills),
  fork(watchSocket),
  fork(watchTeamInvitation),
  fork(watchUpdateBudget),
  fork(watchUpdateProfile),
  fork(watchUserPresence),
  fork(watchReminders),
  fork(watchGetBudgetTracker),
  fork(watchSendBudgetReport),
  fork(watchWeeklyAssignments),
];

if (config.flags.feedback) forkWatch.push(fork(watchFeedback));
if (config.flags.health) forkWatch.push(fork(watchHealth));

export default function* rootSaga() {
  yield all(forkWatch);
  yield takeLatest(AUTH_PASSWORD_CHANGE.INIT, passwordChange);
  yield takeLatest(budget.VISUALIZATION.INIT, loadBudgetVisualization);
  yield takeLatest(calendar.ADD_GOOGLE_CALENDAR, addGoogleCalendar);
  yield takeLatest(calendar.ADD_OUTLOOK_CALENDAR, addOutlookCalendar);
  yield takeLatest(calendar.CALENDAR.INIT, loadUserCalendar);
  yield takeLatest(calendar.CREATE_EVENT, createEvent);
  yield takeLatest(calendar.EVENT_DELETE.REQUEST, deleteEvent);
  yield takeLatest(calendar.EVENT_DETAIL.REQUEST, eventDetail);
  yield takeLatest(calendar.EVENT_OCCURRENCE_DETAIL.REQUEST, eventOccurrenceDetail);
  yield takeLatest(calendar.UPDATE_EVENT, updateEvent);
  yield takeLatest(project.PROJECT_DELETE.REQUEST, projectDelete);
  yield takeLatest(project.PROJECT.INIT, loadProjects);
  yield takeLatest(project.TALENT_PROJECT.REQUEST, loadTalentProjects);
  yield takeLatest(project.UPDATE_PROJECT, updateProject);
  yield takeLatest(user.DELETE_TEAM.INIT, deleteTeam);
  yield takeLatest(user.DELETE_TEAM_MEMBERSHIP.INIT, deleteTeamMembership);
  yield takeLatest(user.DELETE_USER.INIT, deleteUser);
  yield takeLatest(user.TEAM.INIT, loadTalentTeam);
  yield takeLatest(user.TEAM.INIT, loadTeam);
  yield takeLatest(user.TEAM_CALENDAR.INIT, loadTeamCalendar);
  yield takeLatest(user.TEAM_TALENT_INVITES.INIT, loadTeamTalentInvites);
  yield takeLatest(user.TEAM_TALENTS.INIT, loadTeamTalents);
  yield takeEvery(user.USER.INIT, loadUser);

  yield call(initialLogin);

  if (!config.flags.feedback) {
    yield put(skill.hierarchy.init());
  }
}
