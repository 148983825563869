import { isEqual } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";

import { reactionsAdd } from "actions/chat";
import ResponsiveEmojiPicker from "components/ResponsiveEmojiPicker";
import { computePickerPosition } from "utils/chat";

class ReactionPicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      picker: props.showPicker,
      style: props.pickerStyle,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
  }

  componentDidUpdate(prevProps) {
    const { showPicker, pickerStyle } = this.props;
    if (!isEqual(prevProps.pickerStyle, pickerStyle) || prevProps.showPicker !== showPicker) {
      this.setData({
        picker: showPicker,
        pickerStyle,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  setData = (data) => {
    this.setState(data);
  };

  handleDocumentClick = (event) => {
    const { message, onClose } = this.props;
    const { picker } = this.state;
    if (
      picker &&
      event.target.closest(`.add-reaction-${message.id}`) === null &&
      event.target.closest(".emoji-mart") === null
    ) {
      this.setState({ picker: false });
      onClose();
    }
  };

  handlePicker = (event) => {
    const { target } = event;
    const targetBtn = target.closest("button") || target.closest(".button");
    this.setState(({ picker }) => ({
      picker: !picker,
      style: computePickerPosition(targetBtn),
    }));
  };

  handleSelect = (emoji) => {
    const { currentUser, message, onClose, reactionsAddRequest, room, token } = this.props;
    const { id } = room;
    const messageId = message.id;
    reactionsAddRequest({ token, id, messageId, emoji: emoji.id, user: currentUser.slug });
    this.setState({ picker: false });
    onClose();
  };

  render() {
    const { message, trigger } = this.props;
    const { picker, style } = this.state;

    return (
      <React.Fragment>
        {picker && (
          <ResponsiveEmojiPicker
            native
            onSelect={this.handleSelect}
            perLine={7}
            showPreview={false}
            style={style}
          />
        )}
        {trigger && (
          <Button
            basic
            className={`add-reaction-${message.id}`}
            icon="smile"
            type="button"
            size="small"
            title="Add Reaction"
            onClick={this.handlePicker}
          />
        )}
      </React.Fragment>
    );
  }
}

ReactionPicker.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  message: PropTypes.shape().isRequired,
  showPicker: PropTypes.bool,
  pickerStyle: PropTypes.shape(),
  reactionsAddRequest: PropTypes.func.isRequired,
  room: PropTypes.shape().isRequired,
  token: PropTypes.string.isRequired,
  trigger: PropTypes.bool,
  onClose: PropTypes.func,
};

ReactionPicker.defaultProps = {
  showPicker: false,
  pickerStyle: {},
  trigger: true,
  onClose: () => {},
};

function mapStateToProps(state) {
  const {
    auth: { user, token },
  } = state;

  return {
    currentUser: user,
    token,
  };
}

export default connect(mapStateToProps, {
  reactionsAddRequest: reactionsAdd.request,
})(ReactionPicker);
