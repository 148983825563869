import PropTypes from "prop-types";
import React from "react";
import { Icon } from "semantic-ui-react";

import { getRoomName } from "utils";

import MessageRoom from "./MessageRoom";
import styles from "./styles.module.scss";

export default class MessageRoomList extends React.Component {
  render() {
    const {
      currentRoom,
      currentUser,
      header,
      messageRooms,
      onNewClick,
      searchValue,
      unreadMessagesCountByRoom,
      users,
      lastRoomTab,
    } = this.props;
    const messageRoomsFiltered = messageRooms.filter(
      (messageRoom) =>
        getRoomName(messageRoom, messageRoom.users, currentUser)
          .toLowerCase()
          .indexOf(searchValue) >= 0
    );

    return (
      <div className={styles.sidebarSection}>
        <header className={styles.sidebarSectionHeader}>
          <h2 className={styles.sidebarSectionHeaderText}>
            {header === "Teams" ? <Icon name="users" /> : <Icon name="user" />}
            {header}
          </h2>

          <button className={styles.sidebarSectionHeaderButton} onClick={onNewClick} type="button">
            <Icon name="plus" />
          </button>
        </header>

        <div>
          {messageRoomsFiltered.map((room) => (
            <MessageRoom
              currentUser={currentUser}
              isCurrent={room === currentRoom}
              key={room.id}
              room={room}
              roomHideRequest={this.props.roomHideRequest}
              unreadMessagesCount={unreadMessagesCountByRoom[room.id] || 0}
              users={users}
              lastRoomTab={lastRoomTab}
            />
          ))}
        </div>
      </div>
    );
  }
}

MessageRoomList.propTypes = {
  currentRoom: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
  header: PropTypes.string,
  messageRooms: PropTypes.arrayOf(PropTypes.shape()),
  onNewClick: PropTypes.func.isRequired,
  roomHideRequest: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  unreadMessagesCountByRoom: PropTypes.objectOf(PropTypes.number),
  users: PropTypes.shape().isRequired,
  lastRoomTab: PropTypes.string,
};

MessageRoomList.defaultProps = {
  currentRoom: null,
  header: "Direct",
  messageRooms: [],
  searchValue: "",
};
