import breaks from "remark-breaks";
import gfm from "remark-gfm";

import CodeRenderer from "./renderers/CodeRenderer";
import ImageRenderer from "./renderers/ImageRenderer";
import LinkRenderer from "./renderers/LinkRenderer";
import SemanticTableRenderer from "./renderers/SemanticTableRenderer";
import { emojiParser } from "./plugins/EmojiPlugin";

export const renderers = {
  code: CodeRenderer,
  image: ImageRenderer,
  link: LinkRenderer,
  table: SemanticTableRenderer,
};

export const plugins = [gfm, breaks, emojiParser];
