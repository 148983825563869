import PropTypes from "prop-types";
import React from "react";
import { Button, Icon } from "semantic-ui-react";

import styles from "./styles.module.scss";

const NewMessageButton = ({ show, onClick }) =>
  show && (
    <Button size="tiny" color="orange" className={styles.newMessagesButton} onClick={onClick}>
      <Icon name="chevron down" />
      New Messages
    </Button>
  );

NewMessageButton.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NewMessageButton;
