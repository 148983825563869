export const CALL_DISCONNECTED = "talentmarket:call_disconnected";
export const CALL_JOINED = "talentmarket:call_joined";
export const CALL_STARTED = "talentmarket:call_started";
export const MESSAGE_SENT = "talentmarket:message_sent";
export const MESSAGE_READ = "talentmarket:message_read";
export const POLL = "talentmarket:poll";
export const PROJECT = "talentmarket:project";
export const PROJECT_INVITATION = "talentmarket:project_invitation";
export const PROJECT_MEMBERSHIP = "talentmarket:project_membership";
export const ROOM_CREATED = "talentmarket:room_created";
export const ROOM_MEMBERSHIP = "talentmarket:room_membership";
export const ROOM_SWITCH = "talentmarket:room_switch";
export const USER_JOINED = "talentmarket:user_joined";
export const USER_KICKED = "talentmarket:user_kicked";
export const USER_PRESENCE = "talentmarket:user_presence";
export const USER_TYPING = "talentmarket:user_typing";
export const NEW_NOTIFICATION = "talentmarket:new_notification";
export const NEW_USER = "talentmarket:new_user";
export const REACTION_ADDED = "talentmarket:reaction_added";
export const REACTION_DELETED = "talentmarket:reaction_deleted";
export const USER_GOOGLE_AVAILABILITY = "talentmarket:user_google_availability";
export const USER_OUTLOOK_AVAILABILITY = "talentmarket:user_outlook_availability";
export const USER_AVAILABILITY = "talentmarket:user_availability";
export const ROOM_MEETING = "talentmarket:room_meeting";
export const TEAM_MEMBERSHIP = "talentmarket:team_membership";
export const TEAM_INVITATION = "talentmarket:team_invitation";
export const TEAM = "talentmarket:team";
export const USER = "talentmarket:user";
export const EVENT_NEW_REMINDER = "talentmarket:new_reminder";
export const EVENT_NEW_CLIENT_FEEDBACK = "talentmarket:new_client_feedback";
