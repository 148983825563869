import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./styles.module.scss";

class RecurrenceConfirmModal extends React.PureComponent {
  render() {
    const { onClose, onConfirm, open, event } = this.props;
    const { isOutOfOffice } = event;

    const content = `You are about to change the recurrence for this ${
      isOutOfOffice ? "log" : "meeting"
    } and all future ${
      isOutOfOffice ? "unavailable for work log" : "meeting"
    }s. Would you like to proceed?`;

    return (
      <Modal onClose={onClose} open={open} className={styles.recurrenceConfirmModal} closeIcon>
        <Modal.Content className={styles.modalContent}>
          <p className={styles.heading}>Save edit changes</p>
          <p className={styles.content}>{content}</p>
          <div className={styles.actionsContainer}>
            <Button className={styles.action} floated="right" onClick={onConfirm} primary>
              Change recurrence
            </Button>
            <Button
              className={classnames(styles.action, styles.cancel)}
              floated="right"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

RecurrenceConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  event: PropTypes.shape().isRequired,
};

RecurrenceConfirmModal.defaultProps = {
  open: false,
};

export default RecurrenceConfirmModal;
