import classnames from "classnames";

import Notification from "components/Notification";
import { debounce, isEmpty, isEqual, omitBy, sortedUniq } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import MessageBox from "./MessageBox";
import MessageBoxEdit from "./MessageBoxEdit";
import MessageList from "./MessageList";
import NewMessageButton from "./MessageList/NewMessageButton";
import styles from "./styles.module.scss";

const SCROLL_TOP_BEFORE_LOADING = 20;
const INITIAL_MESSAGES_SHOWN = 40;
const MARGIN_TOP = 15;

export default class MessageContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lastMessageModal: false,
      isScrolledToBottom: true,
      showCount: INITIAL_MESSAGES_SHOWN,
      unreadMessages: [],
      isScrolledTop: false,
    };

    this.messages = React.createRef();
    this.lastScrollHeight = 0;
    this.lastScrollTop = 0;
    this.lastFocus = null;
  }

  componentDidMount() {
    this.messages.current.addEventListener("scroll", this.onScroll);
    this.updateNewMessagesDivider(true);
    this.scrollToBottom();
    document.addEventListener("focus", this.handleReadMessage);
  }

  componentDidUpdate(prevProps) {
    // Check whether to show the "new message" button as new messages come in.
    if (prevProps.messageOrdering.length !== this.props.messageOrdering.length) {
      this.handleNewMessagesAppearance();
    }

    // Adjust the "new messages" divider as new messages come in.
    // The divider should not disappear as long as the room is being viewed.
    if (prevProps.room && this.props.room && prevProps.room.id !== this.props.room.id) {
      this.updateNewMessagesDivider(true);
    } else if (prevProps.messageOrdering.length !== this.props.messageOrdering.length) {
      this.updateNewMessagesDivider(false);
    }

    const lastMessageId = this.props.messageOrdering[this.props.messageOrdering.length - 1];
    const prevLastMessageId = prevProps.messageOrdering[prevProps.messageOrdering.length - 1];
    const empty = isEmpty(this.props.messageOrdering);
    const prevEmpty = isEmpty(prevProps.messageOrdering);
    if (!empty && !this.props.isLoadingMessages) {
      if (
        (this.props.room && !prevProps.room) ||
        (this.props.room.jump && prevProps.room.jump !== this.props.room.jump)
      ) {
        // Messages scrolled to where a jump is, if given.
        this.scrollToJump();
      } else if (prevProps.room && prevProps.room.jump && !this.props.room.jump) {
        // We lost a jump.
        this.scrollToBottom();
      } else if (prevEmpty) {
        // Messages scrolled to bottom on load.
        this.scrollToBottom();
      } else if (this.props.room && prevProps.room && this.props.room.id !== prevProps.room.id) {
        // Messages scrolled to bottom when room has changed.
        this.scrollToBottom();
      } else if (
        lastMessageId !== prevLastMessageId &&
        this.props.messages[lastMessageId].author === this.props.currentUser.slug
      ) {
        // Messages scrolled to bottom when last sender is the current user.
        this.scrollToBottom();
      } else if (lastMessageId !== prevLastMessageId && this.wasScrolledToBottom()) {
        // If scrolled to bottom, mark new message as read immediately.
        this.scrollToBottom();
      } else if (
        lastMessageId === prevLastMessageId &&
        this.props.messages !== prevProps.messages &&
        this.wasScrolledToBottom() &&
        !this.state.isScrolledTop
      ) {
        // If scrolled to bottom and a message was edited, scroll to bottom again.
        this.scrollToBottom();
      }

      // Preserve message scroll when first message has changed and tab/window is currently focused.
      if (this.messages.current && document.hasFocus() && !this.state.isScrolledTop) {
        this.lastScrollTop = this.messages.current.scrollTop;
        this.lastScrollHeight = this.messages.current.scrollHeight;
      }
    }
  }

  componentWillUnmount() {
    this.messages.current.removeEventListener("scroll", this.onScroll);
    this.onScroll_.cancel();

    document.removeEventListener("focus", this.handleReadMessage);
  }

  onScroll_ = debounce((scrollTop) => {
    if (!this.props.room.jump && scrollTop <= SCROLL_TOP_BEFORE_LOADING) {
      this.setState(
        (prevState) => ({
          showCount: Math.min(
            prevState.showCount + INITIAL_MESSAGES_SHOWN,
            this.props.messageOrdering.length + INITIAL_MESSAGES_SHOWN
          ),
          isScrolledTop: true,
        }),
        () => {
          if (this.state.showCount >= this.props.messageOrdering.length) {
            this.props.onLoadPreviousMessages();
            if (this.messages.current.scrollHeight === this.lastScrollHeight)
              this.lastScrollHeight -= MARGIN_TOP;
            this.updateScrollTop();
          }
        }
      );
    }
    this.handleNewMessagesAppearance();
  }, 250);

  onScroll = (e) => {
    this.onScroll_(e.target.scrollTop);
  };

  updateScrollTop = () => {
    if (this.messages.current) {
      const delta = this.messages.current.scrollHeight - this.lastScrollHeight;
      this.messages.current.scrollTop = delta;
      this.lastScrollTop = this.messages.current.scrollTop;
      this.lastScrollHeight = this.messages.current.scrollHeight;
    }
  };

  getUnreadMessages = () =>
    Object.keys(this.props.messages).filter((mid) => !this.props.messages[mid].hasRead).length;

  setScrollTop = (scrollTop) => {
    if (this.messages.current) {
      this.messages.current.scrollTop = scrollTop;
      this.lastScrollTop = this.messages.current.scrollTop;
      this.lastScrollHeight = this.messages.current.scrollHeight;
    }
  };

  handleShowLastMessageModal = () => {
    const { lastMessage } = this.props;
    this.lastFocus = document.activeElement;
    if (lastMessage) {
      this.setState({ lastMessageModal: true });
    } else {
      this.setState({ lastMessageModal: false });
    }
  };

  handleHideLastMessageModal = () => {
    this.setState({ lastMessageModal: false }, () => {
      this.lastFocus.focus();
      this.lastFocus = null;
    });
  };

  handleOpenThread = () => {
    const { messages, messageOrdering, onMessageOpenThread, room } = this.props;
    if (room && messageOrdering.length > 0) {
      const replyTo = omitBy(messages, (m) => !m.author)[
        messageOrdering[messageOrdering.length - 1]
      ];
      if (replyTo) {
        const root = replyTo.root ? messages[replyTo.root] : replyTo;
        onMessageOpenThread(room.id, root.id);
      }
    }
  };

  isScrolledToBottom = () =>
    this.messages.current.scrollHeight - this.messages.current.scrollTop <=
    this.messages.current.clientHeight;

  wasScrolledToBottom = () =>
    document.hasFocus() &&
    this.lastScrollHeight - this.lastScrollTop <= this.messages.current.clientHeight;

  scrollToBottom = () => {
    if (this.messages.current) {
      this.setScrollTop(this.messages.current.scrollHeight);
      this.handleReadMessage();
    }
  };

  scrollToJump = () => {
    const messageDom = this.messages.current.querySelector(`.message-${this.props.room.jump}`);
    if (messageDom) {
      messageDom.scrollIntoView({ inline: "center" });
    }
  };

  updateNewMessagesDivider = (reset) => {
    const unread = this.props.messageOrdering.filter(
      (messageId) => !this.props.messages[messageId].hasRead
    );

    if (reset) {
      if (!isEqual(unread, this.state.unreadMessages)) {
        this.setState({
          unreadMessages: unread,
        });
      }
    } else {
      this.setState((prevState) => {
        const unread_ = sortedUniq([...prevState.unreadMessages, ...unread]);
        if (!isEqual(unread_, prevState.unreadMessages)) {
          return {
            unreadMessages: unread_,
          };
        }
        return {};
      });
    }
  };

  handleNewMessagesAppearance = () => {
    const isScrolledToBottom = this.isScrolledToBottom();
    if (this.state.isScrolledToBottom && !isScrolledToBottom) {
      this.setState({
        isScrolledToBottom: false,
      });
    }
    if (!this.state.isScrolledToBottom && isScrolledToBottom) {
      this.setState({
        isScrolledToBottom: true,
      });
      this.handleReadMessage();
    }
  };

  handleNewMessagesClick = () => {
    this.scrollToBottom();
  };

  handleReadMessage = () => {
    // Allow reading of messages when scrolling to the bottom of unfocused tabs/windows.
    if (!document.hidden) {
      const { room, unreadMessagesCount } = this.props;
      if (room && !room.jump && unreadMessagesCount > 0) {
        this.props.onMessageRead(room.id);
      }
    }
  };

  render() {
    const {
      cacheId,
      currentUser,
      deleteMessageRequest,
      dropzoneOpen,
      dropzoneOnPaste,
      isLoadingMessages,
      lastMessage,
      messages,
      messageOrdering,
      onMessageOpenThread,
      room,
      roomUsers,
      unreadMessagesCount,
      users,
    } = this.props;
    const { isScrolledToBottom, lastMessageModal, showCount, unreadMessages } = this.state;
    const show = !isScrolledToBottom && unreadMessagesCount > 0;

    const messageOrderingTruncated = messageOrdering.slice(
      Math.max(messageOrdering.length - showCount, 0)
    );

    return (
      <>
        <Notification />

        {room ? (
          <div className={styles.messageListWrapper} onScroll={this.onScroll}>
            <NewMessageButton show={show} onClick={this.handleNewMessagesClick} />
            <div className={classnames("message-list", styles.messageList)} ref={this.messages}>
              <MessageList
                currentUser={currentUser}
                loading={isLoadingMessages}
                messages={messages}
                messageOrdering={messageOrderingTruncated}
                onOpenThread={onMessageOpenThread}
                room={room}
                roomUsers={roomUsers}
                scrollToBottom={this.scrollToBottom}
                unreadMessages={unreadMessages}
                users={users}
              />
            </div>
          </div>
        ) : (
          <div className={styles.messageListWrapper} ref={this.messages} />
        )}

        {lastMessage && (
          <MessageBoxEdit
            deleteMessage={deleteMessageRequest}
            editOpen={lastMessageModal}
            message={lastMessage}
            room={room}
            onEditModalClose={this.handleHideLastMessageModal}
          />
        )}

        {room && room.jump && (
          <Link to={`/chat/r/${room.id}`} className={styles.resetButton}>
            <Icon name="arrow down" /> Jump to latest messages <Icon name="arrow down" />
          </Link>
        )}

        {(!room || !room.jump) && (
          <MessageBox
            autoCompletes={this.props.autoCompletes}
            cacheId={cacheId}
            dropzoneOpen={dropzoneOpen}
            dropzoneOnPaste={dropzoneOnPaste}
            id="chatMessageBox"
            room={room}
            onEditLastMessage={this.handleShowLastMessageModal}
            onReplyLastMessage={this.handleOpenThread}
            inChat
          />
        )}
      </>
    );
  }
}

MessageContainer.propTypes = {
  autoCompletes: PropTypes.arrayOf(PropTypes.func),
  cacheId: PropTypes.string.isRequired,
  currentUser: PropTypes.shape(),
  deleteMessageRequest: PropTypes.func.isRequired,
  dropzoneOpen: PropTypes.func,
  dropzoneOnPaste: PropTypes.func,
  isLoadingMessages: PropTypes.bool,
  lastMessage: PropTypes.shape(),
  messages: PropTypes.objectOf(PropTypes.shape()),
  messageOrdering: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onLoadPreviousMessages: PropTypes.func.isRequired,
  onMessageRead: PropTypes.func.isRequired,
  onMessageOpenThread: PropTypes.func.isRequired,
  room: PropTypes.shape(),
  roomUsers: PropTypes.arrayOf(PropTypes.shape()),
  unreadMessagesCount: PropTypes.number.isRequired,
  users: PropTypes.shape(),
};
