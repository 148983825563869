import { createRequestTypes, action } from ".";

export const HEALTH_FORM = createRequestTypes("HEALTH_FORM");
export const HEALTH_FORM_DATA = createRequestTypes("HEALTH_FORM_DATA");
export const HEALTH_FORM_DATA_UPDATE = createRequestTypes("HEALTH_FORM_DATA_UPDATE");
export const HEALTH_SUMMARY_AGGREGATED = createRequestTypes("HEALTH_SUMMARY_AGGREGATED");
export const HEALTH_PERSONAL = createRequestTypes("HEALTH_PERSONAL");
export const HEALTH_GUIDE = createRequestTypes("HEALTH_GUIDE");

export const RESET_HEALTH_FORM_DATA_UPDATE = "RESET_HEALTH_FORM_DATA_UPDATE";

export const healthForm = {
  init: () => action(HEALTH_FORM.INIT),
  request: () => action(HEALTH_FORM.REQUEST),
  success: (response) => action(HEALTH_FORM.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(HEALTH_FORM.FAILURE, { error, errorDetails }),
};

export const healthFormData = {
  init: () => action(HEALTH_FORM_DATA.INIT),
  request: () => action(HEALTH_FORM_DATA.REQUEST),
  success: (response) => action(HEALTH_FORM_DATA.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(HEALTH_FORM_DATA.FAILURE, { error, errorDetails }),
};

export const healthFormDataUpdate = {
  init: (id, data) => action(HEALTH_FORM_DATA_UPDATE.INIT, { id, data }),
  request: () => action(HEALTH_FORM_DATA_UPDATE.REQUEST),
  success: (response) => action(HEALTH_FORM_DATA_UPDATE.SUCCESS, { response }),
  failure: ({ error, errorDetails }) =>
    action(HEALTH_FORM_DATA_UPDATE.FAILURE, { error, errorDetails }),
};

export const healthSummaryAggregated = {
  init: ({ query }) => action(HEALTH_SUMMARY_AGGREGATED.INIT, { query }),
  request: () => action(HEALTH_SUMMARY_AGGREGATED.REQUEST),
  success: (response) => action(HEALTH_SUMMARY_AGGREGATED.SUCCESS, { response }),
  failure: ({ error, errorDetails }) =>
    action(HEALTH_SUMMARY_AGGREGATED.FAILURE, { error, errorDetails }),
};

export const healthPersonal = {
  init: ({ query }) => action(HEALTH_PERSONAL.INIT, { query }),
  request: () => action(HEALTH_PERSONAL.REQUEST),
  success: (response) => action(HEALTH_PERSONAL.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(HEALTH_PERSONAL.FAILURE, { error, errorDetails }),
};

export const healthGuide = {
  init: () => action(HEALTH_GUIDE.INIT),
  request: () => action(HEALTH_GUIDE.REQUEST),
  success: (response) => action(HEALTH_GUIDE.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(HEALTH_GUIDE.FAILURE, { error, errorDetails }),
};

export const resetHealthFormDataUpdate = {
  init: () => action(RESET_HEALTH_FORM_DATA_UPDATE),
};
