import localforage from "localforage";
import { call, put, race, select, take } from "redux-saga/effects";
import { api, token as tokenService } from "services";

import config from "config";
import { navigate } from "actions";
import * as account from "actions/account";
import * as chat from "actions/chat";
import * as user from "actions/user";

import { pushEntity } from "./base";
import { fetchRooms } from "./chat";
import { loadProjects } from "./project";

export const pushPasswordReset = pushEntity.bind(null, account.passwordReset, api.passwordReset);

function* validate(credentials) {
  if (!credentials || !credentials.username || !credentials.password) {
    yield put(account.login.failure("Please sign in."));
    return false;
  }

  return true;
}

function* authorize(credentialsOrToken, extraData) {
  yield put(account.login.request());

  if (!validate(credentialsOrToken)) {
    return null;
  }

  const { apiLogin } = yield race({
    apiLogin: call(api.login, credentialsOrToken, extraData),
    signedOut: take(account.AUTH_LOGOUT_INIT),
  });

  const { response, error, errorDetails } = apiLogin;
  if (response) {
    response.token = response.token || response.key;
    yield call(tokenService.setAuthToken, response.token);
    yield put(account.login.success(response));

    return response.token;
  }

  let errorMessage = error || "Cannot reach API server.";
  if (errorDetails && errorDetails.non_field_errors && errorDetails.non_field_errors.length > 0) {
    [errorMessage] = errorDetails.non_field_errors;
  } else if (errorDetails && errorDetails.details) {
    errorMessage = errorDetails.details;
  }
  yield put(account.login.failure(errorMessage));
  return null;
}

function* checkToken(token, sessionToken) {
  const { apiLogin } = yield race({
    apiLogin: call(api.retrieveToken, token, sessionToken),
    signedOut: take(account.AUTH_LOGOUT_INIT),
  });

  if (apiLogin) {
    const { response, error } = apiLogin;

    if (response) {
      response.token = response.token || response.key;
      if (response.isNew) {
        yield put(account.loginModal.open());
      }
      yield call(tokenService.setAuthToken, response.token);
      yield put(
        account.login.success({
          token: response.token,
          user: response.user,
        })
      );
      return response.token;
    }
    if (error) {
      // Disable removing token so api will keep returning 401
      // yield call(tokenService.removeAuthToken);
      console.log("Has error", error, "removing token");
      return null;
    }
  }

  return null;
}

function* signout(token, csrftoken) {
  yield put(account.logout.request());
  if (token) {
    yield call(api.logout, token, csrftoken);
  }

  yield call(tokenService.removeAuthToken);
  yield put(account.logout.success());
  return null;
}

export function* register(data) {
  yield put(account.register.request());

  const { response, error, errorDetails } = yield call(api.register, data);

  if (response) {
    const token = response.token || response.key;
    if (token) {
      yield call(tokenService.setAuthToken, token);
      yield put(account.register.success(response));
      const { teamSlug } = response;
      if (teamSlug) return { token, teamSlug };
      return { token };
    }
  }

  yield put(account.register.failure(error, errorDetails));
  return null;
}

export function* authFlowSaga() {
  while (true) {
    let token;

    const winner = yield race({
      login: take(account.AUTH_LOGIN_INIT),
      logout: take(account.AUTH_LOGOUT_INIT),
    });

    if (winner.login) {
      let didLoginUsingCredentials = false;
      const action = winner.login;
      const { credentials, next, extraData } = action;
      ({ token } = action);

      // Use a valid token or credentials
      // to login.
      if (credentials) {
        token = yield call(authorize, credentials, extraData);
        didLoginUsingCredentials = true;
      } else if (token) {
        didLoginUsingCredentials = false;
      }

      // If we lose the token, try again.
      // We might lose the token, if:
      //  - the stored token has become invalid; or
      //  - the wrong username or password was provided.
      // Otherwise, load stuff because we are now logged in!
      if (token) {
        const lastProjectRoom = yield localforage.getItem(chat.LAST_ROOM_KEY);
        const projectHomeRoomMapping = yield localforage.getItem(
          chat.PROJECT_HOME_ROOM_MAPPING_KEY
        );

        const isSubscribed = yield select(
          (store) => store.auth.user && store.auth.user.isSubscribed
        );
        if (lastProjectRoom) {
          yield put(chat.setLastRoom(lastProjectRoom));
        }
        if (projectHomeRoomMapping) {
          yield put(chat.setProjectHomeRoomMapping(projectHomeRoomMapping));
        }
        if (!config.stripeApiKey || isSubscribed) {
          yield call(fetchRooms, token);
          yield put(user.userPresence.init());
          yield call(loadProjects, {});
        }

        if (didLoginUsingCredentials) {
          yield put(navigate(next || "/"));
        }
      } else if (!didLoginUsingCredentials) {
        yield call(signout, token);
        token = null;
      }
    } else if (winner.logout) {
      token = yield call(tokenService.getAuthToken);
      token = yield call(signout, token);
      if (!token) {
        yield put(navigate("/login"));
      }
    }
  }
}

export function* initialLogin() {
  const token = yield call(tokenService.getAuthToken);
  const sessionToken = yield call(tokenService.getSessionAuthToken);
  const validToken = yield call(checkToken, token, sessionToken);
  yield put(account.loadToken());
  if (validToken) {
    yield put(account.loginInit(null, validToken, null, {}));
  } else if (token) {
    yield call(tokenService.removeAuthToken);
    yield put(account.login.failure("Your session has expired. Please login again."));
    yield put(navigate("/"));
  }
}

export function* setLoginExpiration(expiration, doSignout) {
  const token = yield call(tokenService.getAuthToken);
  const { response, error, errorDetails } = yield call(
    api.setLoginExpiration,
    token,
    expiration,
    doSignout
  );

  if (response) {
    yield put(account.loginModal.setExpirationSuccess(response));
    yield put(account.loginModal.close());
  } else {
    yield put(account.loginModal.setExpirationfailure(error, errorDetails));
  }
}

export function* passwordReset(email) {
  yield put(account.passwordReset.request());
  const { response, error, errorDetails } = yield call(api.passwordReset, email);

  if (response) {
    yield put(account.passwordReset.success(response));
    yield put(navigate("/password/reset/done"));
  } else if (error) {
    yield put(account.passwordReset.failure(errorDetails.email));
  }
}

export function* passwordResetCheck(uid, token) {
  yield put(account.passwordResetCheck.request());
  const { response, error, errorDetails } = yield call(api.passwordResetCheck, uid, token);

  if (response) {
    yield put(account.passwordResetCheck.success(response));
    return true;
  }
  if (error) {
    yield put(account.passwordResetCheck.failure(error, errorDetails));
    return false;
  }
  return false;
}

export function* passwordResetConfirm(uid, token, password, password2) {
  yield put(account.passwordResetConfirm.request());
  const { response, error, errorDetails } = yield call(
    api.passwordResetConfirm,
    uid,
    token,
    password,
    password2
  );

  if (response) {
    yield put(account.passwordResetConfirm.success(response));
    yield put(navigate("/password/reset/complete"));
  } else if (error) {
    yield put(
      account.passwordResetConfirm.failure(
        `${errorDetails.new_password1} ${errorDetails.new_password2}`
      )
    );
  }
}

export function* passwordChange({ oldPassword, newPassword1, newPassword2 }) {
  yield put(account.passwordChange.request());

  const token = yield tokenService.getAuthToken();
  const { response, errorDetails } = yield call(api.passwordChange, token, {
    oldPassword,
    newPassword1,
    newPassword2,
  });

  if (response) {
    yield put(account.passwordChange.success(response));
  } else if (errorDetails) {
    yield put(account.passwordChange.failure(errorDetails));
  }
}

export function* teamInvitation(authToken, invitationToken, decision) {
  yield put(account.teamInvitation.request());

  const { response, error } = yield call(api.teamInvitation, authToken, invitationToken, decision);

  if (response) {
    yield put(account.teamInvitation.success(response));

    const token = response.token || response.key;
    const { teamSlug, roomId } = response;
    if (token) {
      yield call(tokenService.setAuthToken, token);
      return { token, teamSlug, roomId };
    }
    if (teamSlug) {
      return { teamSlug, roomId };
    }
  } else if (error) {
    yield put(account.teamInvitation.failure(error));
  }

  return null;
}

export function* passwordlessGetToken({ email, resolve, reject }) {
  const { response, error, errorDetails } = yield call(api.passwordlessGetToken, email);

  if (response) {
    yield put(account.passwordlessGetToken.success(response));
    if (resolve) resolve();
  } else if (error) {
    yield put(account.passwordlessGetToken.failure(errorDetails));
    if (reject) reject(errorDetails);
  }
}

export function* passwordlessValidateToken({ invitationToken, resolve, reject }) {
  const { response, error, errorDetails } = yield call(
    api.passwordlessValidateToken,
    invitationToken
  );

  if (response) {
    yield put(account.passwordlessValidateToken.success(response));
    if (resolve) resolve();
  } else if (error) {
    yield put(account.passwordlessValidateToken.failure(errorDetails));
    if (reject) reject(errorDetails);
  }
}

export function* passwordlessLogin({ authToken, invitationToken, expiration, doSignout }) {
  const { response, error } = yield call(
    api.passwordlessLogin,
    authToken,
    invitationToken,
    expiration,
    doSignout
  );

  if (response) {
    yield put(account.passwordlessLogin.success(response));

    const token = response.token || response.key;
    const { roomId, doSignout: doSignoutResponse } = response;
    if (doSignoutResponse) {
      yield put(account.logout.request());
      yield put(navigate("/"));
    }
    if (token) {
      yield call(tokenService.setAuthToken, token);
      return { token, roomId };
    }
    if (roomId) {
      return { roomId };
    }
  } else if (error) {
    yield put(account.passwordlessLogin.failure(error));
  }

  return null;
}

export function* watchRegister() {
  while (true) {
    const { data } = yield take(account.AUTH_REGISTER_INIT);
    const result = yield call(register, data);

    if (result && result.token) {
      const sessionToken = yield call(tokenService.getAuthToken);
      yield call(checkToken, result.token, sessionToken);
      yield put(account.loginInit(null, result.token, null, {}));

      if (result.teamSlug) {
        yield put(navigate(`/teams/${result.teamSlug}`));
      } else {
        const inviteToken = data.invite_token;
        if (inviteToken) {
          const { response } = yield call(
            api.buildTeamProjectInviteAccept,
            result.token,
            inviteToken
          );
          if (response) {
            yield put(navigate(`/chat/r/${response.roomId}`));
          } else {
            yield put(navigate("/"));
          }
        } else {
          yield put(navigate("/"));
        }
      }
    }
  }
}

export function* watchPasswordReset() {
  while (true) {
    const { email } = yield take(account.AUTH_PASSWORD_RESET.INIT);
    yield call(passwordReset, email);
  }
}

export function* watchPasswordResetConfirm() {
  while (true) {
    const { uid: uid1, token: token1 } = yield take(account.AUTH_PASSWORD_RESET_CHECK.INIT);
    const success = yield call(passwordResetCheck, uid1, token1);

    if (success) {
      const { uid: uid2, token: token2, password, password2 } = yield take(
        account.AUTH_PASSWORD_RESET_CONFIRM.INIT
      );
      yield call(passwordResetConfirm, uid2, token2, password, password2);
    }
  }
}

export function* watchTeamInvitation() {
  while (true) {
    const { authToken, invitationToken, decision } = yield take(account.TEAM_INVITATION.INIT);
    const result = yield call(teamInvitation, authToken, invitationToken, decision);

    if (result && result.teamSlug) {
      let token = authToken;
      if (result.token) {
        ({ token } = result);
        const sessionToken = yield call(tokenService.getSessionAuthToken);
        yield call(checkToken, result.token, sessionToken);
        yield put(account.loginInit(null, token, null, {}));
      }
      yield put(navigate(`/chat/r/${result.roomId}`));
    }
  }
}

export function* watchPasswordlessGetToken() {
  while (true) {
    const { email } = yield take(account.PASSWORDLESS_GET_TOKEN.REQUEST);
    yield call(passwordlessGetToken, email);
  }
}

export function* watchPasswordlessValidateToken() {
  while (true) {
    const { invitationToken } = yield take(account.PASSWORDLESS_VALIDATE_TOKEN.REQUEST);
    yield call(passwordlessValidateToken, invitationToken);
  }
}

export function* watchPasswordlessLogin() {
  while (true) {
    const { authToken, invitationToken, expiration, doSignout } = yield take(
      account.PASSWORDLESS_LOGIN.REQUEST
    );
    const result = yield call(passwordlessLogin, authToken, invitationToken, expiration, doSignout);

    if (result) {
      let token = authToken;
      if (result.token) {
        ({ token } = result);
        const sessionToken = yield call(tokenService.getSessionAuthToken);
        yield call(checkToken, result.token, sessionToken);
        yield put(account.loginInit(null, token, null, {}));
      }
      if (result.roomId) {
        yield put(navigate(`/chat/r/${result.roomId}`));
      }
    }
  }
}

export function* watchSetLoginExpiration() {
  while (true) {
    const { expiration, doSignout } = yield take(account.LOGIN_MODAL_SET_EXPIRATION.REQUEST);
    yield call(setLoginExpiration, expiration, doSignout);
  }
}
