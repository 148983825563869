import classnames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";

import TimezoneViewerHeader from "../ScreenMeetingList/TimezoneViewerHeader";
import TimezoneViewerLegend from "../ScreenMeetingList/TimezoneViewerLegend";
import TimezoneViewerMember from "../ScreenMeetingList/TimezoneViewerMember";
import TimezoneViewerTimeline from "../ScreenMeetingList/TimezoneViewerTimeline";
import styles from "../ScreenMeetingList/styles.module.scss";

export default class TimezoneViewer extends React.PureComponent {
  constructor(props) {
    super(props);

    const now = moment();
    this.state = {
      currentDate: props.currentDate ? moment(props.currentDate) : now,
      selectedStart: props.selectedStart ? moment(props.selectedStart) : null,
      selectedEnd: props.selectedEnd ? moment(props.selectedEnd) : null,
      timezonesAdded: props.timezonesAdded ? props.timezonesAdded : [],
      defaultTimezone: props.currentUser?.timezoneDisplay
        ? [
            {
              isDummy: true,
              slug: "currentMemberTimezone",
              timezoneDisplay: props.currentUser.timezoneDisplay,
            },
          ]
        : [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const derivedState = {};
    if (
      nextProps.currentDate &&
      nextProps.currentDate.format() !== prevState.currentDate.format()
    ) {
      derivedState.currentDate = moment(nextProps.currentDate);
    }
    if (
      nextProps.selectedEnd &&
      nextProps.selectedEnd.format() !== prevState.selectedEnd.format()
    ) {
      derivedState.selectedEnd = moment(nextProps.selectedEnd);
    }
    if (
      nextProps.selectedStart &&
      nextProps.selectedStart.format() !== prevState.selectedStart.format()
    ) {
      derivedState.selectedStart = moment(nextProps.selectedStart);
    }
    return derivedState;
  }

  getMembers() {
    const { currentUser, members, selectedUserTimezone } = this.props;
    const { defaultTimezone, timezonesAdded } = this.state;
    const timezoneMembers = timezonesAdded.map(({ key, timezone }) => ({
      isDummy: true,
      slug: key,
      timezoneDisplay: timezone,
    }));

    const defaultTimezoneCopy = [...defaultTimezone];

    const updatedMembers = members.map((member) => {
      if (currentUser.slug === member.slug && selectedUserTimezone) {
        return {
          ...member,
          timezoneDisplay: selectedUserTimezone,
        };
      }
      return member;
    });
    return defaultTimezoneCopy.concat(updatedMembers.concat(timezoneMembers));
  }

  handleDateSelect = (date) => {
    const { currentRoomId, loadRoomMemberCalendar, onTimezoneNavigate } = this.props;
    this.setState({
      currentDate: date,
    });
    onTimezoneNavigate(date);
    const query = date.format("YYYY-MM-DD");
    loadRoomMemberCalendar(currentRoomId, { date: query });
  };

  handleDateRangeSelect = (startKey, endKey) => {
    const { currentDate } = this.state;
    const { selectedUserTimezone } = this.props;
    if (!!startKey || !!endKey) {
      // currentDateStart uses timezone specified in dropdown
      const currentDateStart = moment(currentDate)
        .tz(selectedUserTimezone || moment.tz.guess())
        .startOf("day");
      // Both start and end use user's browser timezone (not the one specified in profile) to work properly with DateTimePicker
      const start = moment(currentDateStart)
        .add(startKey * 30, "minutes")
        .tz(moment.tz.guess(), true);
      const end = moment(currentDateStart)
        .add(endKey * 30, "minutes")
        .tz(moment.tz.guess(), true);
      if (startKey !== null && endKey !== null) {
        this.setState({
          selectedStart: start,
          selectedEnd: end,
        });
        this.props.onDateChange(start, end);
      } else if (startKey) {
        this.setState({
          selectedStart: start,
        });
        this.props.onDateChange(start, null);
      } else {
        this.setState({
          selectedEnd: end,
        });
        this.props.onDateChange(null, end);
      }
    }
  };

  handleTimezoneAdd = (e, { value }) => {
    const key = moment().format("x");
    this.setState((prevState) => ({
      timezonesAdded: [...prevState.timezonesAdded, { key, timezone: value }],
    }));
  };

  handleTimezoneDelete = (slug) => {
    const { defaultTimezone, timezonesAdded } = this.state;
    const filtered = timezonesAdded.filter((tzinfo) => tzinfo.key !== slug);
    const newDefaultTimezone = defaultTimezone.filter((tzinfo) => tzinfo.slug !== slug);
    this.setState({ defaultTimezone: newDefaultTimezone, timezonesAdded: filtered });
  };

  handleScheduleMeeting = () => {
    const { selectedUserTimezone } = this.props;
    const { selectedStart, selectedEnd, timezonesAdded } = this.state;
    const convertedEnd = selectedEnd ? moment(selectedEnd).tz(selectedUserTimezone, true) : null;
    const convertedStart = selectedStart
      ? moment(selectedStart).tz(selectedUserTimezone, true)
      : null;

    const eventData = {
      title: "",
      start: convertedStart,
      end: convertedEnd,
      timezoneDisplay: selectedUserTimezone,
      timezonesAdded,
      billable: true,
    };
    this.props.onScheduleMeeting(eventData);
  };

  render() {
    const { calendars, currentUser, eventOccurrences, selectedUserTimezone } = this.props;
    const { currentDate, selectedStart, selectedEnd } = this.state;
    const convertedCurrent = moment(currentDate).tz(selectedUserTimezone, true);
    const convertedCurrentStart = moment(convertedCurrent).startOf("day").format();
    const convertedEnd = selectedEnd ? moment(selectedEnd).tz(selectedUserTimezone, true) : null;
    const convertedStart = selectedStart
      ? moment(selectedStart).tz(selectedUserTimezone, true)
      : null;
    const members = this.getMembers();

    return [
      <TimezoneViewerHeader
        key="header"
        currentDate={convertedCurrent}
        onNavigate={this.handleDateSelect}
        onTimezoneAdd={this.handleTimezoneAdd}
        onScheduleMeeting={this.handleScheduleMeeting}
      />,
      <TimezoneViewerLegend key="legend" />,
      <div key="viewer" className={styles.timezoneViewerContainer}>
        <div className={classnames(styles.timezoneViewerGroup, styles.sticky)}>
          {members.map((member) => (
            <TimezoneViewerMember
              key={member.slug}
              currentDate={convertedCurrent}
              isCurrentUser={currentUser.slug === member.slug}
              member={member}
              onTimezoneDelete={this.handleTimezoneDelete}
              selectedEnd={convertedEnd}
              selectedStart={convertedStart}
            />
          ))}
        </div>
        <TimezoneViewerTimeline
          calendars={calendars}
          currentDate={convertedCurrentStart}
          eventOccurrences={eventOccurrences}
          defaultEnd={convertedEnd}
          defaultStart={convertedStart}
          members={members}
          onDateRangeChange={this.handleDateRangeSelect}
          currentUser={currentUser}
        />
      </div>,
    ];
  }
}

TimezoneViewer.propTypes = {
  calendars: PropTypes.objectOf(PropTypes.shape()).isRequired,
  currentDate: PropTypes.shape(),
  currentRoomId: PropTypes.number,
  currentUser: PropTypes.shape(),
  eventOccurrences: PropTypes.objectOf(PropTypes.shape()).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedEnd: PropTypes.shape(),
  selectedStart: PropTypes.shape(),
  selectedUserTimezone: PropTypes.string,
  timezonesAdded: PropTypes.arrayOf(PropTypes.shape()),

  loadRoomMemberCalendar: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onTimezoneNavigate: PropTypes.func.isRequired,
  onScheduleMeeting: PropTypes.func.isRequired,
};
