import Humanize from "humanize-plus";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "semantic-ui-react";

import { DAILY, WEEKLY, MONTHLY, YEARLY, NOREPEAT, CUSTOM, BLANK_CUSTOM } from "./constants";

const repeatOptions = {
  noRepeat: {
    dropdownId: "RecurrenceInput.dropdownId.noRepeat",
    dropdownMessage: "Does not repeat",
    value: NOREPEAT,
  },
  daily: {
    dropdownId: "RecurrenceInput.dropdownId.daily",
    dropdownMessage: "Daily",
    value: DAILY,
  },
  weekly: {
    dropdownId: "RecurrenceInput.dropdownId.weekly",
    dropdownMessage: "Weekly on {day}",
    value: WEEKLY,
  },
  monthly: {
    dropdownId: "RecurrenceInput.dropdownId.monthly",
    dropdownMessage: "Monthly on day {date}",
    value: MONTHLY,
  },
  yearly: {
    dropdownId: "RecurrenceInput.dropdownId.yearly",
    dropdownMessage: "Annual on {monthDate}",
    value: YEARLY,
  },
  custom: {
    dropdownId: "RecurrenceInput.dropdownId.custom",
    dropdownMessage: "{customLabel}",
    value: CUSTOM,
  },
};

const LABELS = {
  [DAILY]: {
    customLabelPluralMessage: "days",
    customLabelSingularMessage: "day",
  },
  [WEEKLY]: {
    customLabelPluralMessage: "weeks",
    customLabelSingularMessage: "week",
  },
  [MONTHLY]: {
    customLabelPluralMessage: "months",
    customLabelSingularMessage: "month",
  },
  [YEARLY]: {
    customLabelPluralMessage: "years",
    customLabelSingularMessage: "year",
  },
};

const WEEKDAYS = {
  SU: "Sun",
  MO: "Mon",
  TU: "Tue",
  WE: "Wed",
  TH: "Thu",
  FR: "Fri",
  SA: "Sat",
};

export default class RecurrenceDropdown extends React.PureComponent {
  getRepeatOptions = () => {
    const { endRecurringPeriod, frequency, params, startDate } = this.props;
    const date = startDate.date();
    const day = startDate.format("dddd");
    const monthDate = startDate.format("MMMM D");
    let hasCustomRecurrence = false;
    let customRecurrenceLabel = "";
    let customLabel = "Custom";
    if ((!isEmpty(params) || endRecurringPeriod) && frequency) {
      hasCustomRecurrence = true;
      customRecurrenceLabel = `Every ${LABELS[frequency].customLabelSingularMessage}`;
      if (params.interval) {
        customRecurrenceLabel = `Every ${params.interval} ${LABELS[frequency].customLabelPluralMessage}`;
      }
      if (params.bysetpos) {
        customRecurrenceLabel = `${customRecurrenceLabel} on the ${Humanize.ordinal(
          params.bysetpos
        )} ${day}`;
      } else if (params.byweekday) {
        const weekdayList = params.byweekday.split(",").map((wkday) => WEEKDAYS[wkday]);
        if (
          weekdayList.length === 5 &&
          weekdayList.indexOf("Sat") < 0 &&
          weekdayList.indexOf("Sun") < 0
        ) {
          customRecurrenceLabel = `${customRecurrenceLabel} on weekdays`;
        } else {
          customRecurrenceLabel = `${customRecurrenceLabel} on ${weekdayList.join(",")}`;
        }
      }
      if (endRecurringPeriod) {
        customRecurrenceLabel = `${customRecurrenceLabel}, until ${moment(
          endRecurringPeriod
        ).format("MMM D, YYYY")}`;
      } else if (params.count) {
        customRecurrenceLabel = `${customRecurrenceLabel}, ${params.count} times`;
      }
      customLabel = customRecurrenceLabel;
    }
    const oldOptions = Object.keys(repeatOptions).map((key) => ({
      key,
      value: repeatOptions[key].value,
      text: (
        <FormattedMessage
          defaultMessage={repeatOptions[key].dropdownMessage}
          id={repeatOptions[key].dropdownId}
          values={{ day, date, monthDate, customLabel }}
        />
      ),
    }));
    if (hasCustomRecurrence) {
      oldOptions.push({
        key: "blankCustom",
        value: BLANK_CUSTOM,
        text: (
          <FormattedMessage
            defaultMessage="Custom"
            id="RecurrenceInput.dropdownId.blankCustom"
            values={{ day, date, monthDate }}
          />
        ),
      });
    }
    return oldOptions;
  };

  onChange = (e, { value }) => {
    if (value === CUSTOM || value === BLANK_CUSTOM) {
      this.props.onCustom();
    } else if (value === NOREPEAT) {
      this.props.onChange({ endRecurringPeriod: null, frequency: "", params: {} });
    } else {
      this.props.onChange({ endRecurringPeriod: null, frequency: value, params: {} });
    }
  };

  render() {
    const { disabled, endRecurringPeriod, frequency, params } = this.props;
    let currentValue = frequency || NOREPEAT;
    if (!isEmpty(params) || endRecurringPeriod) {
      currentValue = CUSTOM;
    }
    const options = this.getRepeatOptions();

    return (
      <Form.Select
        className={this.props.className}
        disabled={disabled}
        inline={this.props.inline}
        name="ruleFrequency"
        onChange={this.onChange}
        options={options}
        selectOnBlur={false}
        value={currentValue}
      />
    );
  }
}

RecurrenceDropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endRecurringPeriod: PropTypes.string,
  frequency: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  params: PropTypes.shape(),
  startDate: PropTypes.shape().isRequired,

  onChange: PropTypes.func.isRequired,
  onCustom: PropTypes.func.isRequired,
};

RecurrenceDropdown.defaultProps = {
  className: "",
  disabled: false,
  inline: false,
  params: {},
};
