import React from "react";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const EmailButton = (props) => (
  <Button className={styles.button} onClick={props.onClick} title="Username/Password">
    <img src="/email-password.svg" alt="email and password" />
  </Button>
);

EmailButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EmailButton;
