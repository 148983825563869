import classnames from "classnames";
import React from "react";
import { Button } from "semantic-ui-react";
import config from "config";
import styles from "./styles.module.scss";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/windowslive/login/`;

const MicrosoftButton = (props) => (
  <Button
    className={classnames(styles.button, styles.microsoft)}
    circular
    fluid
    onClick={() => {
      window.location.href = LOGIN_URL;
    }}
    title="Microsoft Azure AD"
    icon="windows"
    {...props}
  />
);

export default MicrosoftButton;
