import {
  TEAM,
  UPDATE_TEAM,
  ADD_TEAM_MEMBERS,
  UPDATE_MEMBERSHIPS,
  RESEND_INVITE,
  REMOVE_INVITE,
  DELETE_TEAM,
  BULK_TEAM_SKILLS_UPDATE,
  TEAM_DETAIL,
  CLOSE_NOTIFICATION,
} from "../actions/user";

const initialState = {
  loaded: false,
  error: false,
  errorDetails: {},
  updated: false,
  updateError: false,

  addMembersLoading: false,
  addMembersSuccess: false,
  saveMembersLoading: false,
  saveMembersSuccess: false,

  resendInviteSuccess: false,
  removeInviteSuccess: false,
  inviteId: null,
  inviteErrorDetails: {},

  deleteLoading: false,
  deleteSuccess: false,
  deleteName: "",
  deleteError: false,

  skillsUpdateLoading: false,
  skillsUpdateSuccess: false,
};

export default function team(state = initialState, action) {
  if (action.type === UPDATE_TEAM.INIT) {
    return {
      ...state,
      loaded: false,
      updated: false,
      error: false,
      errorDetails: {},
      saveMembersSuccess: false,
      updateError: false,
    };
  }

  if (action.type === UPDATE_TEAM.SUCCESS) {
    return {
      ...state,
      loaded: true,
      updated: true,
      error: false,
      errorDetails: {},
      updateError: false,
    };
  }

  if (action.type === UPDATE_TEAM.FAILURE) {
    return {
      ...state,
      loaded: true,
      updated: false,
      error: true,
      errorDetails: action.errorDetails,
      updateError: true,
    };
  }

  if (action.type === TEAM.REQUEST) {
    return {
      ...state,
      loaded: false,
      error: false,
      errorDetails: {},
      updated: false,
      updateError: false,
      addMembersSuccess: false,
      addMembersLoading: false,
      saveMembersLoading: false,
      saveMembersSuccess: false,
      resendInviteSuccess: false,
      removeInviteSuccess: false,
    };
  }

  if (action.type === TEAM.SUCCESS) {
    return {
      ...state,
      updated: false,
      loaded: true,
      error: false,
      updateError: false,
      errorDetails: {},
    };
  }

  if (action.type === TEAM.FAILURE) {
    return {
      ...state,
      loaded: true,
      error: true,
      errorDetails: action.errorDetails,
      updateError: false,
    };
  }

  if (action.type === ADD_TEAM_MEMBERS.INIT) {
    return {
      ...state,
      updated: false,
      removeInviteSuccess: false,
      resendInviteSuccess: false,
      addMembersSuccess: false,
      addMembersLoading: true,
      saveMembersLoading: false,
      saveMembersSuccess: false,
    };
  }

  if (action.type === ADD_TEAM_MEMBERS.SUCCESS) {
    return {
      ...state,
      removeInviteSuccess: false,
      resendInviteSuccess: false,
      addMembersSuccess: true,
      addMembersLoading: false,
      saveMembersLoading: false,
      saveMembersSuccess: false,
    };
  }

  if (action.type === UPDATE_MEMBERSHIPS.INIT) {
    return {
      ...state,
      updated: false,
      removeInviteSuccess: false,
      resendInviteSuccess: false,
      addMembersLoading: false,
      addMembersSuccess: false,
      saveMembersSuccess: false,
      saveMembersLoading: true,
    };
  }

  if (action.type === UPDATE_MEMBERSHIPS.SUCCESS) {
    return {
      ...state,
      removeInviteSuccess: false,
      resendInviteSuccess: false,
      addMembersLoading: false,
      addMembersSuccess: false,
      saveMembersSuccess: true,
      saveMembersLoading: false,
    };
  }

  if (action.type === UPDATE_MEMBERSHIPS.FAILURE) {
    return {
      ...state,
      removeInviteSuccess: false,
      resendInviteSuccess: false,
      addMembersLoading: false,
      addMembersSuccess: false,
      saveMembersSuccess: false,
      saveMembersLoading: false,
    };
  }

  if (action.type === RESEND_INVITE.INIT || action.type === REMOVE_INVITE.INIT) {
    const { id } = action;

    return {
      ...state,
      updated: false,
      inviteId: id,
      removeInviteSuccess: false,
      resendInviteSuccess: false,
      inviteErrorDetails: {},
      addMembersSuccess: false,
      addMembersLoading: false,
      saveMembersLoading: false,
      saveMembersSuccess: false,
    };
  }

  if (action.type === RESEND_INVITE.SUCCESS) {
    return {
      ...state,
      inviteId: null,
      removeInviteSuccess: false,
      resendInviteSuccess: true,
      inviteErrorDetails: {},
      addMembersSuccess: false,
      addMembersLoading: false,
      saveMembersLoading: false,
      saveMembersSuccess: false,
    };
  }

  if (action.type === REMOVE_INVITE.SUCCESS) {
    return {
      ...state,
      inviteId: null,
      removeInviteSuccess: true,
      resendInviteSuccess: false,
      inviteErrorDetails: {},
      addMembersSuccess: false,
      addMembersLoading: false,
      saveMembersLoading: false,
      saveMembersSuccess: false,
    };
  }

  if (action.type === RESEND_INVITE.FAILURE || action.type === REMOVE_INVITE.FAILURE) {
    return {
      ...state,
      removeInviteSuccess: false,
      resendInviteSuccess: false,
      inviteErrorDetails: action.errorDetails,
      addMembersSuccess: false,
      addMembersLoading: false,
      saveMembersLoading: false,
      saveMembersSuccess: false,
    };
  }

  if (action.type === DELETE_TEAM.INIT) {
    const { name } = action;

    return {
      ...state,
      updated: false,
      deleteLoading: true,
      deleteSuccess: false,
      deleteName: name,
      saveMembersSuccess: false,
      deleteError: false,
    };
  }

  if (action.type === DELETE_TEAM.SUCCESS) {
    return {
      ...state,
      deleteLoading: false,
      deleteSuccess: true,
      deleteError: false,
      deleteName: "",
    };
  }

  if (action.type === DELETE_TEAM.FAILURE) {
    return {
      ...state,
      deleteLoading: false,
      deleteSuccess: false,
      deleteError: true,
    };
  }

  if (action.type === TEAM_DETAIL.SUCCESS) {
    const { response } = action;
    if (response.isDeleted) {
      // update error and errorDetails to trigger the Not Found page
      return {
        ...state,
        error: true,
        errorDetails: {
          detail: "Not found.",
        },
      };
    }
    return {
      ...state,
      error: false,
      errorDetails: {},
    };
  }

  if (action.type === BULK_TEAM_SKILLS_UPDATE.INIT) {
    return {
      ...state,
      updated: false,
      skillsUpdateLoading: true,
      skillsUpdateSuccess: false,
      saveMembersSuccess: false,
    };
  }

  if (action.type === BULK_TEAM_SKILLS_UPDATE.SUCCESS) {
    return {
      ...state,
      skillsUpdateLoading: false,
      skillsUpdateSuccess: true,
    };
  }

  if (action.type === CLOSE_NOTIFICATION) {
    return {
      ...initialState,
      loaded: true,
    };
  }

  return state;
}
