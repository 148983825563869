import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Header, Form, Button, Message } from "semantic-ui-react";

import { resetErrorMessage } from "actions";
import { passwordReset } from "actions/account";

import * as styles from "./styles.module.scss";

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const email = params.get("email") || "";

    this.state = {
      email,
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    const { email } = this.state;
    this.props.resetErrorMessage();
    this.props.passwordResetInit(email);
  };

  render() {
    const { email } = this.state;
    const { errorMessage, loading } = this.props;

    return (
      <Grid container centered>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={14} textAlign="center">
            <Header as="h2" content="Forgot Password" className={styles.header} />
            <Header
              as="h4"
              color="grey"
              content="We'll send you a password reset link"
              className={styles.subheader}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={6} tablet={12} mobile={16}>
            {errorMessage && (
              <Message error header="Error" content={this.props.errorMessage.email} />
            )}
            <Form onSubmit={this.handleSubmit} loading={loading}>
              <Form.Input
                label="Email"
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                required
              />
              <Button type="submit" fluid primary>
                Reset password
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={6} tablet={12} mobile={16} textAlign="center">
            <p>
              No account yet?{" "}
              <a href="/signup">
                <strong>Create account</strong>
              </a>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

PasswordReset.propTypes = {
  passwordResetInit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    errorMessage,
    passwordReset: { loading },
  } = state;
  return {
    errorMessage,
    loading,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    passwordResetInit: passwordReset.init,
    resetErrorMessage,
  })(PasswordReset)
);
