import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { getActiveTabRoomUrl } from "utils/apps";

import styles from "./styles.module.scss";

class ProjectRoom extends React.PureComponent {
  renderIcon() {
    const { room } = this.props;

    if (room.hasCall) {
      return <Icon name="call" />;
    }
    if (room.isGeneral) {
      return <Icon name="globe" />;
    }
    if (room.isPrivate) {
      return <Icon name="lock" />;
    }
    return <Icon name="hashtag" />;
  }

  render() {
    const { isCurrent, room, unreadMessagesCount, lastRoomTab } = this.props;
    if (!room) return null;

    const url = getActiveTabRoomUrl(room, lastRoomTab);

    return (
      <Button
        as={Link}
        className={classnames(
          "focusable",
          styles.sidebarProjectRoom,
          isCurrent && styles.active,
          unreadMessagesCount > 0 && styles.unread
        )}
        data-focusable
        id={`room${room.id}`}
        tabIndex="0"
        title={room.name}
        to={url}
      >
        <span className={styles.sidebarProjectRoomText}>
          {this.renderIcon()}
          <span>{room.name}</span>
          <div className={styles.unreadProjectRoomNotif} />
        </span>
      </Button>
    );
  }
}

ProjectRoom.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  room: PropTypes.shape().isRequired,
  unreadMessagesCount: PropTypes.number.isRequired,
  lastRoomTab: PropTypes.string,
};

export default ProjectRoom;
