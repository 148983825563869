import { decamelizeKeys } from 'humps';
import * as schema from 'services/schema';
import { callApi, callApiWithToken } from './base';

export * from './account';
export * from './budget';
export * from './chat';
export * from './feedback';
export * from './clientFeedback';
export * from './log';
export * from './project';
export * from './user';
export * from './notification';
export * from './skill';
export * from './health';
export * from './reminders';
export * from './schedule';
export * from './subscription';
export * from './timetracker';

export const fetchProjects = (token, url) =>
  callApiWithToken(url, token, schema.projectSchemaArray, 'GET');

export const fetchProject = (token, id) =>
  callApiWithToken(`projects/${id}/`, token, schema.projectSchema, 'GET');

export const projectUpdate = (token, id, data) =>
  callApiWithToken(
    `projects/${id}/`,
    token,
    schema.projectSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const fetchBudgetVisualization = (token, url) => callApiWithToken(url, token, null, 'GET');
export const updateBudget = (token, pid, rSlug, amount, description) => {
  const body = {
    project: pid,
    amount,
    description,
  };
  if (rSlug) {
    body.receivedBy = rSlug;
  }
  return callApiWithToken(
    'budget/',
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(decamelizeKeys(body))
  );
};

export const createLink = (token, id, name, url) =>
  callApiWithToken(
    `projects/${id}/links/`,
    token,
    schema.projectSchema,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      decamelizeKeys({
        name,
        url,
      })
    )
  );

export const ping = (token) => callApiWithToken('ping/', token, null, 'GET');

export const requirementsSearch = (data) => callApi('requirements_search/', null, 'POST', {}, data);
