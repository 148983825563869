import React from "react";
import classnames from "classnames";
import EmailValidator from "email-validator";
import { flatten, isEmpty, isEqual, orderBy } from "lodash";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { connect } from "react-redux";
import { defineMessages } from "react-intl";
import { Button, Dimmer, Form, Loader, Modal, Tab, Icon, Menu } from "semantic-ui-react";
import { SingleDatePicker } from "react-dates";

import {
  getCurrentProjectMemberships,
  getCurrentProject,
  getCurrentRoom,
} from "reducers/selectors";
import {
  getCurrentUserProjectsActive,
  getCurrentUserProjectRoomsActive,
  getCurrentUserProjectMemberships,
} from "reducers/selectors/project";

import { projectMembershipType } from "types/project";
import DateTimeRangePicker from "components/DateTimeRangePicker";
import Errors from "components/Errors";
import TimezoneDropdown from "components/TimezoneDropdown";
import RecurrenceInput from "components/RecurrenceInput";
import UserAvatar from "components/UserAvatar";
import { MESSAGES } from "containers/Profile/l10n";
import ProjectDropdown from "containers/TimeTracker/ProjectDropdown";
import ProjectChannelDropdown from "containers/Profile/components/ProjectChannelDropdown";
import { isUrlValid } from "utils";
import { convertToLocalDate, convertToLocalMoment } from "utils/calendar";
import { KeyCodes } from "utils/constants";
import { HOURS, OUT_OF_OFFICE_ACTIVITIES } from "containers/TimeTracker/constants";
import {
  roomCalendar as roomCalendarAction,
  roomMemberCalendar,
  roomMeetingCreate,
  roomMeetingDelete,
  roomMeetingJoin,
} from "actions/chat";
import {
  addGoogleCalendar,
  addOutlookCalendar,
  createEvent,
  eventDelete,
  eventOccurrenceDetail,
  loadUserCalendar,
  setSelectedEvent,
  updateEvent,
} from "actions/calendar";
import styles from "containers/Profile/styles.module.scss";
import { apiDataToEventObject, getParams, numberSearch } from "./utils";

import RecurrenceConfirmModal from "./components/RecurrenceConfirmModal";
import DeleteEventModal from "./components/DeleteEventModal";
import RecurrenceModal from "./components/RecurrenceModal";
import EditWorkLogModal from "./components/EditWorkLogModal";
import SchedulerView from "./components/SchedulerView";
import WarningOnCloseModal from "./components/WarningOnCloseModal";

class CalendarEventAddModal extends React.Component {
  constructor(props) {
    super(props);

    const { event } = props;
    const { invitedGuests } = event;

    this.dateTimeIcon = React.createRef();
    this.recurrenceIcon = React.createRef();
    this.guestIcon = React.createRef();
    this.schedulerViewRef = React.createRef();

    const { fullyLoaded } = props;
    let params = {};
    if (fullyLoaded) params = getParams(props);

    this.state = {
      ...params,
      addAnother: false,
      confirmEditModal: false,
      dateFocused: false,
      deleteModal: false,
      detailsChanged: false,
      errors: {},
      loading: false,
      recurrenceChanged: false,
      recurrenceChoice: "thisOnly",
      recurrenceConfirmModal: false,
      recurrenceModal: false,
      recurrenceSource: "edit",
      schedulerView: false,
      tempRecurrence: {
        endRecurringPeriod: null,
        frequency: "",
        params: {},
      },
      titleFlag: true,
      allGuests: invitedGuests || [],
      otherGuest: false,
      warningOnCloseModal: false,
    };
  }

  componentDidMount() {
    const {
      currentProjectId,
      eventOccurrenceId,
      fromTimeTracker,
      fullyLoaded,
      worklog,
    } = this.props;
    if (worklog) {
      this.setState({ eventBillable: worklog.billable });
    } else if (!fromTimeTracker) {
      this.setState({ eventIsMeeting: !fromTimeTracker });
    }
    if (!fullyLoaded)
      this.handleLoadEventOccurrence(eventOccurrenceId, { projectId: currentProjectId });
  }

  componentDidUpdate(prevProps) {
    const cond = !prevProps.fullyLoaded && this.props.fullyLoaded;
    if (cond) {
      const params = getParams(this.props);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...params });
    }
  }

  handleCreateModalClose = () => {
    const { detailsChanged } = this.state;
    if (detailsChanged) {
      this.setState({ warningOnCloseModal: true });
    } else {
      this.handleCancel();
    }
  };

  handleCancel = () => {
    this.setState({
      confirmEditModal: false,
      detailsChanged: false,
      id: null,
      originalEnd: undefined,
      originalStart: undefined,
      recurrenceChanged: false,
      recurrenceChoice: "thisOnly",
      recurrenceConfirmModal: false,
      recurrenceModal: false,
      schedulerView: false,
      titleFlag: true,
      warningOnCloseModal: false,
    });
    this.props.onClose();
  };

  handleChange = (e, { name, value }) => {
    if (name === "eventIsAllDay" && value) {
      this.setState((prevState) => ({
        [name]: value,
        eventEnd: moment(prevState.eventStart).startOf("day").add(1, "days"),
        eventStart: moment(prevState.eventStart).startOf("day"),
        detailsChanged: true,
      }));
    } else if (name === "outOfOfficeIsAllDay" && value) {
      this.setState((prevState) => ({
        [name]: value,
        outOfOfficeEnd: moment(prevState.outOfOfficeStart).startOf("day").add(1, "days"),
        outOfOfficeStart: moment(prevState.outOfOfficeStart).startOf("day"),
        detailsChanged: true,
      }));
    } else if (name === "eventProjectChannel") {
      let projectMembers = [];
      let title = "";
      if (value) {
        const { projectChannels, projectMemberships } = this.props;
        const project = projectChannels.find((r) => r.id === value).projectId;
        projectMembers = projectMemberships.filter((m) => m.projectId === project);
        title = `${projectChannels.find((r) => r.id === value).text} - Meeting`;
      }
      this.setState({
        [name]: value,
        eventTitle: title,
        eventProjectMembers: projectMembers,
        eventGuests: projectMembers.map((m) => m.slug),
        eventSelectedGuests: "everyone",
        selectAllGuests: true,
        detailsChanged: true,
        allGuests: projectMembers.map((m) => m.slug),
      });
    } else if (name === "eventSelectedGuests" && value) {
      const { projectChannels, projectMemberships, currentUser } = this.props;
      const {
        eventProjectChannel,
        eventGuests,
        eventSelectedGuests,
        allGuests,
        otherGuest,
      } = this.state;
      if (eventProjectChannel) {
        const project = projectChannels.find((r) => r.id === eventProjectChannel).projectId;
        const projectMembers = projectMemberships.filter((m) => m.projectId === project);
        const projectMemberSlugs = projectMembers.map((m) => m.slug);
        let guests = Array.from(new Set([...projectMemberSlugs, ...allGuests]));
        const invGuests = guests;
        if (value === "custom") {
          guests = eventGuests;
          if (eventSelectedGuests === "everyone" && isEqual(eventGuests.sort(), invGuests.sort())) {
            if (!otherGuest) {
              guests = [currentUser.slug];
            } else {
              // eslint-disable-next-line no-param-reassign
              value = "everyone";
            }
          } else if (isEqual(eventGuests.sort(), invGuests.sort())) {
            // eslint-disable-next-line no-param-reassign
            value = "everyone";
          }
        }
        this.setState({
          [name]: value,
          eventProjectMembers: projectMembers,
          eventGuests: guests,
          detailsChanged: true,
          otherGuest: false,
          selectAllGuests: value === "everyone",
        });
      }
    } else if (name === "eventStart" && value) {
      this.setState((prevState) => {
        const diff = prevState.eventEnd.diff(prevState.eventStart);
        this.schedulerViewRef.current.setTimezoneDate(moment(value));
        return {
          ...prevState,
          eventStart: moment(value),
          eventEnd: moment(value).add(diff),
          detailsChanged: true,
        };
      });
    } else if (name === "outOfOfficeStart" && value) {
      this.setState((prevState) => {
        const diff = prevState.outOfOfficeEnd.diff(prevState.outOfOfficeStart);
        return {
          ...prevState,
          outOfOfficeStart: moment(value),
          outOfOfficeEnd: moment(value).add(diff),
          detailsChanged: true,
        };
      });
    } else if (name === "eventIsMeeting" && value) {
      this.setState((prevState) => ({
        ...prevState,
        eventIsMeeting: value === "true",
      }));
    } else if (name === "eventFilterUserOrExternalEmail" && value) {
      const { eventProjectMembers } = this.state;

      let eventFilteredUsersList = [];
      let filterByEmail = [];
      filterByEmail = eventProjectMembers.filter((obj) =>
        obj.email.toLowerCase().includes(value.toLowerCase())
      );
      const filterByName = eventProjectMembers.filter((obj) =>
        obj.displayName.toLowerCase().includes(value.toLowerCase())
      );

      eventFilteredUsersList = [...new Set([...filterByEmail, ...filterByName])];

      this.setState({
        eventFilterUserOrExternalEmail: value,
        eventFilteredUsersList,
        detailsChanged: true,
      });
    } else {
      this.setState({ [name]: value, detailsChanged: true });
    }
  };

  handleFromViewModalOpen = () => {
    const { currentUser, event, fromTimeTracker, onChange } = this.props;
    const { project, start } = event;
    const createdDate = convertToLocalMoment(start, currentUser && currentUser.timezoneDisplay);
    const today = new Date();
    const now = convertToLocalMoment(today, currentUser && currentUser.timezoneDisplay);
    const hoursPast = now.diff(createdDate, "hours", true);
    if (project && hoursPast > 24) {
      this.setState({ confirmEditModal: true });
    } else {
      const key = fromTimeTracker ? "logModalVisible" : "addModal";
      onChange({ [key]: true });
    }
  };

  handleDeleteEventModalOpen = () => this.setState({ deleteModal: true });

  handleConfirmEdit = () => {
    const { fromTimeTracker, onChange } = this.props;
    this.handleModalClose("confirmEditModal");
    if (fromTimeTracker) {
      onChange({ logModalVisible: true });
    } else {
      onChange({ viewModal: false, fromViewModal: true, addModal: true });
    }
  };

  handleRecurrentDelete = () => {
    const { event } = this.props;
    const { recurrenceChoice } = this.state;
    const data = {
      title: event.title,
      start: moment(event.start).tz(event.timezoneDisplay).toISOString(),
      end: moment(event.end).tz(event.timezoneDisplay).toISOString(),
      initStart: moment(event.start).tz(event.timezoneDisplay).toISOString(),
      isRecurring: true,
      isDelete: true,
      issueNumber: 0,
      isSingleRecurrence: recurrenceChoice === "thisOnly",
      occurrenceId: null,
      guests: event.invitedGuests,
      id: event.id,
      originalStart: event.originalStart,
      originalEnd: event.originalEnd,
    };
    this.handleSendEventData(data, false);
  };

  handleRecurrenceModalConfirm = () => {
    const { recurrenceSource } = this.state;
    if (recurrenceSource === "delete") {
      this.handleRecurrentDelete();
    } else {
      this.handleSave();
    }
  };

  createEvent = (slug, data, params, isWorkLog = false) =>
    new Promise((resolve, reject) =>
      this.props.createEvent(slug, data, resolve, reject, params, isWorkLog)
    );

  createMeeting = (id, data, params, userSlug) =>
    new Promise((resolve, reject) =>
      this.props.createMeeting(id, data, resolve, reject, params, userSlug)
    );

  deleteMeeting = (rid, oid) =>
    new Promise((resolve, reject) => this.props.deleteMeeting(rid, oid, resolve, reject, true));

  updateEvent = (id, data, slug, params, isWorkLog = false) =>
    new Promise((resolve, reject) =>
      this.props.updateEvent(id, data, slug, resolve, reject, params, isWorkLog)
    );

  deleteEvent = (id, slug, params) =>
    new Promise((resolve, reject) =>
      this.props.deleteEvent(id, slug, params, resolve, reject, true)
    );

  handleLoadEventOccurrence = (occurrenceId, params) =>
    new Promise((resolve, reject) =>
      this.props.loadEventOccurrence(occurrenceId, params, resolve, reject)
    );

  loadRoomCalendar = (roomId) => {
    const query = moment().format("YYYY-MM-DD");
    return new Promise((resolve, reject) =>
      this.props.loadRoomCalendar(roomId, { date: query }, resolve, reject)
    );
  };

  handleEventCancel = async () => {
    const { applyFilter, fromTimeTracker, event, onClose } = this.props;
    const { roomId, rootEventId } = event;
    await this.deleteMeeting(roomId, rootEventId);
    if (fromTimeTracker) applyFilter();
    this.setState({ deleteModal: false });
    onClose(false);
  };

  handleEventRemove = async () => {
    const {
      applyFilter,
      byMonth,
      currentDate,
      currentUser,
      fromCalendar,
      fromTimeTracker,
      onClose,
    } = this.props;
    const { id } = this.state;
    let params = {};
    if (fromCalendar) {
      params = { date: currentDate.format("YYYY-MM-DD"), initial: true };
      if (byMonth) {
        params.bymonth = true;
      }
    }
    await this.deleteEvent(id, currentUser.slug, params);
    if (fromTimeTracker) applyFilter();
    this.setState({ deleteModal: false });
    onClose(false);
  };

  handleModalClose = (name) => this.setState({ [name]: false });

  handleRecurrenceUpdate = () =>
    this.setState((prevState) => ({
      ...prevState,
      ruleInput: prevState.tempRecurrence,
      recurrenceChanged: true,
      recurrenceConfirmModal: false,
    }));

  handleRecurrenceChange = (e, { name, value }) => {
    const { id } = this.state;
    if (id) {
      this.setState({ tempRecurrence: value, recurrenceConfirmModal: true, detailsChanged: true });
    } else {
      this.handleChange(e, { name, value });
    }
  };

  handleCheckboxChange = (e, { name, checked }) => this.handleChange(e, { name, value: checked });

  handleAddGuest = (event) => {
    const {
      key,
      target: { value },
    } = event;
    if (key === KeyCodes.ENTER[0] && value.length > 0) {
      event.preventDefault();
      event.stopPropagation();

      const { eventFilterUserOrExternalEmail, eventGuests, eventProjectMembers } = this.state;
      const isPartOfProject = eventProjectMembers.find(
        (obj) => obj.email === eventFilterUserOrExternalEmail
      );

      if (isPartOfProject) return;

      if (
        EmailValidator.validate(eventFilterUserOrExternalEmail) &&
        !eventGuests.includes(eventFilterUserOrExternalEmail)
      ) {
        this.setState(
          (prevState) => ({
            eventGuests: [...prevState.eventGuests, prevState.eventFilterUserOrExternalEmail],
            allGuests: [...prevState.allGuests, prevState.eventFilterUserOrExternalEmail],
            eventFilterUserOrExternalEmail: "",
            otherGuest: true,
          }),
          () => {
            document
              .getElementById(`guest-${eventFilterUserOrExternalEmail.replace("@", "")}`)
              .scrollIntoView();
            this.handleChange(null, {
              name: "eventSelectedGuests",
              value: "custom",
            });
          }
        );
      }
    }
  };

  handleEventGuestsCheckboxChange = (guest) => {
    const { eventGuests } = this.state;
    if (eventGuests.includes(guest)) {
      this.setState(
        (prevState) => ({
          eventGuests: prevState.eventGuests.filter((g) => g !== guest),
          eventOptionalGuests: prevState.eventOptionalGuests.filter((g) => g !== guest),
        }),
        () => {
          this.handleChange(null, { name: "eventSelectedGuests", value: "custom" });
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          eventGuests: [...prevState.eventGuests, guest],
        }),
        () => {
          this.handleChange(null, { name: "eventSelectedGuests", value: "custom" });
        }
      );
    }
  };

  handleRemoveEvent = () => {
    const { event } = this.props;
    if (event.rootEventId) {
      if (event.rule?.frequency) {
        this.setState({ recurrenceModal: true, recurrenceSource: "delete" });
      } else {
        this.setState({ deleteModal: true });
      }
    }
  };

  handleUpdate = (addAnother = false) => {
    const { event } = this.props;
    const { detailsChanged } = this.state;
    const editRecurring = event.id && event.rule?.frequency;
    if (editRecurring && detailsChanged) {
      this.setState({ recurrenceModal: true, recurrenceSource: "edit" });
    } else {
      this.handleSave(addAnother);
    }
  };

  handleSave = (addAnother = false) => {
    const { currentUser, event, projects, fromTimeTracker } = this.props;
    const {
      id,
      eventTitle,
      eventStart,
      eventEnd,
      eventTimezone,
      eventIsAllDay,
      eventProjectChannel,
      eventDescription,
      eventBillable,
      eventIsMeeting,
      eventGuests,
      eventOptionalGuests,
      initStart,
      initEnd,
      isAvailable,
      isOutOfOffice,
      outOfOfficeTitle,
      outOfOfficeStart,
      outOfOfficeEnd,
      outOfOfficeTimezone,
      outOfOfficeIsAllDay,
      outOfOfficeBillable,
      eventProject,
      eventHours,
      eventIssueUrl,
      eventVideo,
      ruleInput,
      eventDate,
      originalStart,
      originalEnd,
      occurrenceId,
      detailsChanged,
      recurrenceChanged,
      recurrenceChoice,
    } = this.state;
    const isPersistentOccurrence = Number.isInteger(occurrenceId);

    let eventData = {};
    if (isOutOfOffice) {
      let eventEndRecurringPeriod;
      let eventRuleFrequency;
      let eventRuleParams;

      if (ruleInput) {
        const { endRecurringPeriod, frequency, params } = ruleInput;
        if (endRecurringPeriod) {
          eventEndRecurringPeriod = moment(endRecurringPeriod)
            .tz(eventTimezone, true)
            .toISOString();
        }
        if (frequency) {
          const paramList = Object.keys(params).map((key) => `${key}:${params[key]}`);
          eventRuleFrequency = frequency;
          eventRuleParams = paramList.join(";");
        }
      }

      eventData = {
        id,
        isOutOfOffice,
        isWorkLog: false,
        isAvailable: false,
        title: outOfOfficeTitle,
        isAllDay: outOfOfficeIsAllDay,
        timezone: outOfOfficeTimezone,
        start: moment(outOfOfficeStart).tz(outOfOfficeTimezone, true).toISOString(),
        end: moment(outOfOfficeEnd).tz(outOfOfficeTimezone, true).toISOString(),
        roomId: null,
        billable: outOfOfficeBillable,
        endRecurringPeriod: eventEndRecurringPeriod,
        ruleFrequency: eventRuleFrequency,
        ruleParams: eventRuleParams,
        originalStart,
        originalEnd,
        occurrenceId: isPersistentOccurrence ? occurrenceId : null,
      };
    } else if (!eventIsMeeting) {
      // Task
      const project = projects.find((proj) => proj.id === eventProject);
      const projectName = project?.text;
      const titleData = `Work Log - ${projectName}`;
      let issueUrl = eventIssueUrl || null;
      let issueNumber = eventIssueUrl || null;

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(eventHours) || eventHours === "") {
        this.setState({
          errors: {
            hours: ["Hours required. Please input a valid number of hours"],
          },
        });
        return;
      }

      let start = moment(eventStart);
      if (fromTimeTracker) {
        start = moment(eventDate).hour(8).minute(0).second(0).millisecond(0);
        if (currentUser.defaultWorkHoursStart) {
          const defaultStartDate = convertToLocalDate(
            currentUser.defaultWorkHoursStart,
            currentUser.timezoneDisplay
          );
          const startDate = moment(defaultStartDate);
          start = start.clone().hour(startDate.hour()).minute(startDate.minute());
        }
      }
      const end = start.clone().add(eventHours, "hours");

      if (eventIssueUrl) {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(eventIssueUrl)) {
          if (project && project.project && project.project.gitlabUrl) {
            issueUrl = `${project.project.gitlabUrl}/issues/${eventIssueUrl}`;
          } else {
            this.setState({
              errors: {
                issue_url: [
                  "No gitlab url associated with the project. Please input a valid issueonClose() url instead",
                ],
              },
            });
            return;
          }
        } else if (isUrlValid(eventIssueUrl)) {
          // eslint-disable-next-line prefer-destructuring
          issueNumber = eventIssueUrl.split("issues/")[1];
        } else {
          this.setState({
            errors: {
              issue_url: ["Please input a valid issue number or url"],
            },
          });
          return;
        }
      }
      eventData = {
        id,
        isWorkLog: true,
        project: eventProject,
        description: eventDescription,
        hours: eventHours,
        billable: eventBillable,
        issueNumber,
        issueUrl,
        timezone: eventTimezone,
        start: moment(start).tz(eventTimezone, true).toISOString(),
        end: moment(end).tz(eventTimezone, true).toISOString(),
        title: titleData,
        isAllDay: false,
        isAvailable: false,
        isOutOfOffice: false,
        fromCalendar: !fromTimeTracker,
        roomId: null,
      };
      // Add handling when editing an existing event whose timezone is different from user's current timezone
      if (
        event.id &&
        fromTimeTracker &&
        event.originalTimezone &&
        eventTimezone !== event.originalTimezone
      ) {
        const originalDate = event.date.format("MM/DD/YYYY");
        const originalHours = event.hours;
        const newDate = eventDate.format("MM/DD/YYYY");
        const newHours = parseFloat(eventHours);
        const timeChanged = originalDate !== newDate || originalHours !== newHours;
        // Ensure event doesn't shift when neither hours nor date are changed
        if (!timeChanged) {
          const newOriginalStart = moment(event.start).tz(eventTimezone, true);
          let workStartHour = 8;
          let workStartMinute = 0;
          if (currentUser.defaultWorkHoursStart) {
            const workStart = convertToLocalDate(
              currentUser.defaultWorkHoursStart,
              currentUser.timezoneDisplay
            );
            const workStartMoment = moment(workStart);
            workStartHour = workStartMoment.hour();
            workStartMinute = workStartMoment.minute();
          }
          const startInOriginalTimezone = newOriginalStart.clone().tz(event.originalTimezone);
          const oldStart = startInOriginalTimezone
            .hour(workStartHour)
            .minute(workStartMinute)
            .second(0)
            .millisecond(0);
          const oldEnd = oldStart.clone().add(eventHours, "hours");
          eventData = {
            ...eventData,
            timezone: event.originalTimezone,
            start: oldStart.toISOString(),
            end: oldEnd.toISOString(),
          };
        }
      }
    } else {
      // meeting
      let eventEndRecurringPeriod;
      let eventRuleFrequency;
      let eventRuleParams;
      if (ruleInput) {
        const { endRecurringPeriod, frequency, params } = ruleInput;
        if (endRecurringPeriod) {
          eventEndRecurringPeriod = moment(endRecurringPeriod)
            .tz(eventTimezone, true)
            .toISOString();
        }
        if (frequency) {
          const paramList = Object.keys(params).map((key) => `${key}:${params[key]}`);
          eventRuleFrequency = frequency;
          eventRuleParams = paramList.join(";");
        }
      }
      eventData = {
        id,
        title: eventTitle,
        roomId: eventProjectChannel,
        start: moment(eventStart).tz(eventTimezone, true).toISOString(),
        end: moment(eventEnd).tz(eventTimezone, true).toISOString(),
        timezone: eventTimezone,
        billable: eventBillable,
        guests: eventGuests,
        optionalGuests: eventOptionalGuests,
        isAllDay: eventIsAllDay,
        callUrl: eventVideo,
        description: eventDescription,
        isOutOfOffice: false,
        isWorkLog: false,
        isAvailable,
        endRecurringPeriod: eventEndRecurringPeriod,
        ruleFrequency: eventRuleFrequency,
        ruleParams: eventRuleParams,
        originalStart,
        originalEnd,
        occurrenceId: isPersistentOccurrence ? occurrenceId : null,
      };
    }
    if (event.id) {
      eventData = {
        ...eventData,
        initStart,
        initEnd,
        quickUpdate: false,
      };
      if (event.rule?.frequency) {
        let isSingleRecurrence = recurrenceChoice === "thisOnly";
        if (recurrenceChanged && !detailsChanged) isSingleRecurrence = false;
        if (isPersistentOccurrence) isSingleRecurrence = true;
        eventData = {
          ...eventData,
          isRecurring: true,
          isSingleRecurrence,
          initStart: moment(event.start).tz(event.timezoneDisplay).toISOString(),
        };
      }
    }
    this.handleSendEventData(eventData, addAnother);
  };

  handleSendEventData = async (eventData, addAnother = false) => {
    const {
      applyFilter,
      byMonth,
      calendars,
      currentDate,
      currentUser,
      fromCalendar,
      fromTimeTracker,
      onClose,
      userCalendar,
    } = this.props;
    let params = {};
    if (fromCalendar) {
      params = { date: currentDate.format("YYYY-MM-DD"), initial: true };
      if (byMonth) {
        params.bymonth = true;
      }
    }
    this.setState({ errors: {}, loading: true });
    if (eventData.id) {
      try {
        await this.updateEvent(
          eventData.id,
          eventData,
          currentUser.slug,
          params,
          eventData.isWorkLog
        );
        this.setState({ errors: {}, loading: false });
        this.handleCancel();
        onClose(false);
      } catch (errors) {
        this.setState({ errors, loading: false });
      }
    } else {
      try {
        if (!eventData.roomId) {
          const objData = {
            ...eventData,
            calendar: userCalendar?.calendar.id,
          };
          await this.createEvent(currentUser.slug, objData, params, eventData.isWorkLog);
          this.setState({ loading: false });
        } else {
          const calendarObj = calendars[eventData.roomId] || {};
          let roomCalendar;
          if (!("calendar" in calendarObj)) {
            const response = await this.loadRoomCalendar(eventData.roomId);
            roomCalendar = response.entities.calendars[eventData.roomId].calendar;
          } else {
            roomCalendar = calendarObj.calendar;
          }

          const objData = {
            ...eventData,
            calendar: roomCalendar.id,
          };
          await this.createMeeting(eventData.roomId, objData, params, currentUser.slug);
          this.setState({ loading: false });
        }
        if (!addAnother) this.handleCancel();
      } catch (errors) {
        this.setState({ errors, loading: false });
      }
    }
    if (fromTimeTracker) {
      this.setState({
        eventProject: "",
        eventDescription: "",
        eventBillable: true,
        eventHours: "",
        eventIssueUrl: "",
      });
      applyFilter();
    }
  };

  handleTabChange = (e, { activeIndex }) => {
    switch (activeIndex) {
      case 1:
        this.setState({
          activeIndex,
          isOutOfOffice: true,
        });
        break;

      default:
        this.setState({
          activeIndex,
          isOutOfOffice: false,
        });
    }
  };

  handleChangeAttendanceRequirement = (guest) => {
    const { eventGuests, eventOptionalGuests } = this.state;
    if (eventGuests.includes(guest)) {
      if (eventOptionalGuests.includes(guest)) {
        this.setState((prevState) => ({
          eventOptionalGuests: prevState.eventOptionalGuests.filter(
            (optionalGuest) => optionalGuest !== guest
          ),
          detailsChanged: true,
        }));
      } else {
        this.setState((prevState) => ({
          eventOptionalGuests: [...prevState.eventOptionalGuests, guest],
          detailsChanged: true,
        }));
      }
    }
  };

  handleDateChange = (date) => {
    this.setState({ eventDate: date, detailsChanged: true });
  };

  handleSelectAllChange = () => {
    const { selectAllGuests } = this.state;
    this.setState({ selectAllGuests: !selectAllGuests }, () => this.handleSelectAllGuests());
  };

  handleSelectAllGuests = () => {
    const { selectAllGuests } = this.state;
    if (selectAllGuests) {
      this.handleChange(null, { name: "eventSelectedGuests", value: "everyone" });
    } else {
      this.handleChange(null, { name: "eventSelectedGuests", value: "custom" });
    }
  };

  checkEventTitle = () => {
    const { projectChannels } = this.props;
    const { loading } = this.state;
    const { id, eventProjectChannel, titleFlag } = this.state;
    if (!id && titleFlag && !loading) {
      if (projectChannels.find((r) => r.id === eventProjectChannel)) {
        this.setState({
          eventTitle: `${projectChannels.find((r) => r.id === eventProjectChannel).text} - Meeting`,
          titleFlag: false,
        });
      }
    }
  };

  renderMeetingForm = (fromViewScheduler = false, schedulerViewState = {}) => {
    const {
      formatMessage: _f,
      currentUser,
      msgs,
      event,
      fromProjectCalendar,
      showViewScheduler,
      memberRoles,
    } = this.props;
    const { invitedGuests } = event;
    const {
      id,
      eventEnd,
      eventIsAllDay,
      eventTimezone,
      eventStart,
      eventBillable,
      eventProjectMembers = [],
      eventGuests = [],
      eventOptionalGuests = [],
      eventFilteredUsersList,
      eventFilterUserOrExternalEmail,
      eventProjectChannel,
      eventDescription,
      eventTitle,
      eventSelectedGuests,
      eventVideo,
      ruleInput = {},
      detailsChanged,
      recurrenceChanged,
      allGuests,
      loading,
    } = this.state;
    // If this is a project event and user is not invited, prevent editing/deleting
    const notInMeeting =
      id &&
      event.project &&
      !invitedGuests?.includes(currentUser.slug) &&
      currentUser.slug !== event.organizerSlug;
    const currentUserRole = memberRoles[currentUser.slug]?.role;
    this.checkEventTitle();
    const {
      timezoneExpanded,
      toggleTimezoneExpanded,
      handleIconClick,
      rangePickerRef,
    } = schedulerViewState;
    const iconClick = timezoneExpanded ? handleIconClick : () => {};

    let membersList = [];
    if (!eventFilterUserOrExternalEmail) {
      membersList = eventProjectMembers;
    } else {
      membersList = eventFilteredUsersList;
    }

    return (
      <>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.projectChannel)}</p>
          <ProjectChannelDropdown
            disabled={!!id || fromProjectCalendar}
            fluid
            name="eventProjectChannel"
            onChange={this.handleChange}
            placeholder="Select Calendar to add the event to"
            value={eventProjectChannel}
            hideMyCalendar={fromViewScheduler}
          />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.activity)}</p>
          <Form.Input
            name="eventTitle"
            placeholder="Enter activity description"
            onChange={this.handleChange}
            required
            value={eventTitle}
            className={styles.input}
            disabled={notInMeeting}
            id={`${fromViewScheduler ? "scheduler" : "addmodal"}-eventTitle`}
          />
          <Icon
            className={styles.icon}
            name="file alternate"
            onClick={() => iconClick("eventTitle")}
          />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.setTime)}</p>
          <div
            onFocus={() => {
              this.dateTimeIcon.current.firstChild.style.color = "#424242";
            }}
            onBlur={() => {
              this.dateTimeIcon.current.firstChild.style.color = "#bdbdbd";
            }}
          >
            <DateTimeRangePicker
              ref={fromViewScheduler ? rangePickerRef : null}
              disabled={notInMeeting}
              endDate={eventEnd?.format() || moment().format()}
              endDateName="eventEnd"
              hideTime={eventIsAllDay}
              required
              startDate={eventStart?.format() || moment().format()}
              startDateName="eventStart"
              small
              onChange={this.handleChange}
            />
            <TimezoneDropdown
              displayOffset
              fluid
              name="eventTimezone"
              onChange={this.handleChange}
              placeholder="Select time zone"
              value={eventTimezone}
              className={styles.timezoneDropdown}
              disabled={notInMeeting}
            />
          </div>
          <span ref={this.dateTimeIcon}>
            <Icon
              id="calendarAlternate"
              className={styles.icon}
              name="calendar alternate"
              onClick={() => iconClick("eventStart")}
            />
          </span>
        </div>
        {!fromViewScheduler && showViewScheduler && eventProjectChannel && !notInMeeting ? (
          <span
            className={styles.findTime}
            onKeyDown={() => {}}
            onClick={() => this.setState({ schedulerView: true })}
            role="button"
            tabIndex={0}
          >
            Find time
          </span>
        ) : null}
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.recurrence)}</p>
          <div
            className={styles.recurrenceFields}
            onFocus={() => {
              this.recurrenceIcon.current.firstChild.style.color = "#424242";
            }}
            onBlur={() => {
              this.recurrenceIcon.current.firstChild.style.color = "#bdbdbd";
            }}
          >
            <Form.Checkbox
              className={classnames(styles.allDay, styles.checkbox)}
              checked={eventIsAllDay}
              name="eventIsAllDay"
              label={_f(msgs.availability.allDay)}
              onChange={this.handleCheckboxChange}
              disabled={notInMeeting}
              id={`${fromViewScheduler ? "scheduler" : "addmodal"}-eventIsAllDay`}
            />
            <RecurrenceInput
              key={id || 0}
              className={styles.recurrenceInput}
              disabled={notInMeeting}
              endRecurringPeriod={ruleInput.endRecurringPeriod}
              frequency={ruleInput.frequency}
              startDate={eventStart}
              params={ruleInput.params}
              onChange={this.handleRecurrenceChange}
            />
          </div>
          <span ref={this.recurrenceIcon}>
            <Icon className={styles.icon} name="sync" onClick={() => iconClick("eventIsAllDay")} />
          </span>
        </div>
        <div className={styles.section}>
          <p className={classnames(styles.label, styles.addGuests, id && styles.hasGuests)}>
            <span ref={this.guestIcon}>
              <Icon
                className={styles.icon}
                name="user plus"
                style={{ paddingTop: "7px" }}
                onClick={() =>
                  iconClick(eventSelectedGuests === "everyone" ? "eventEveryone" : "eventCustom")
                }
              />
            </span>
            Guests
          </p>
          <div
            className={styles.guestsContainer}
            onFocus={() => {
              this.guestIcon.current.firstChild.style.color = "#424242";
            }}
            onBlur={() => {
              this.guestIcon.current.firstChild.style.color = "#bdbdbd";
            }}
          >
            <Form.Input
              disabled={
                (!id && !eventProjectChannel) ||
                (id && !event.project) ||
                (notInMeeting &&
                  (currentUserRole !== "client_admin" || currentUserRole !== "client_member"))
              }
              fluid
              name="eventFilterUserOrExternalEmail"
              value={eventFilterUserOrExternalEmail}
              placeholder="Search and invite guests to this meeting"
              onChange={this.handleChange}
              onKeyPress={this.handleAddGuest}
              style={{ paddingTop: "5px" }}
              className={classnames(styles.input, styles.eventFilterUser)}
            />
            <Form.Checkbox
              disabled={(!id && !eventProjectChannel) || (id && !event.project) || notInMeeting}
              checked={eventSelectedGuests === "everyone"}
              name="eventSelectedGuests"
              onChange={this.handleSelectAllChange}
              value="everyone"
              label="Select All"
              className={styles.selectAllCheckbox}
            />
            <div className={styles.eventGuests}>
              {membersList
                .map((member) => ({
                  ...member,
                  checked: eventGuests.includes(member.slug),
                  optional: eventOptionalGuests.includes(member.slug),
                }))
                .map((member) => (
                  <div className={styles.section} key={member.slug}>
                    <Form.Checkbox
                      checked={member.checked}
                      name="eventGuests"
                      onChange={() => this.handleEventGuestsCheckboxChange(member.slug)}
                      className={styles.guestCheckbox}
                      disabled={
                        (!id && !eventProjectChannel) ||
                        (!id && member.slug === currentUser.slug) ||
                        (id && member.slug === event.organizerSlug) ||
                        (notInMeeting &&
                          (currentUserRole !== "client_admin" ||
                            currentUserRole !== "client_member"))
                      }
                    />
                    <Icon
                      link={member.checked}
                      name="user"
                      className={classnames(
                        styles.guestAttendance,
                        member.optional && styles.optional,
                        !member.checked && styles.disabled
                      )}
                      title={
                        // eslint-disable-next-line no-nested-ternary
                        !member.checked
                          ? ""
                          : member.optional
                          ? "Set as Required"
                          : "Set as Optional"
                      }
                      onClick={() => this.handleChangeAttendanceRequirement(member.slug)}
                    />
                    <UserAvatar
                      avatar={member.avatarOrDefault}
                      size="24"
                      bgColor={member.avatarBgcolor}
                      fgColor={member.avatarColor}
                      displayName={member.displayName}
                    />
                    <span className={styles.guestName}>{member.displayName}</span>
                    {member.optional && (
                      <span className={styles.guestOptionalText}>(Optional)</span>
                    )}
                  </div>
                ))}
              {allGuests &&
                allGuests
                  .filter((g) => g.includes("@"))
                  .map((email) => ({
                    email,
                    optional: eventOptionalGuests.includes(email),
                    checked: eventGuests.includes(email),
                  }))
                  .map((externalGuest) => (
                    <div
                      className={styles.section}
                      key={externalGuest.email}
                      id={`guest-${externalGuest.email.replace("@", "")}`}
                    >
                      <Form.Checkbox
                        checked={externalGuest.checked}
                        name="eventGuests"
                        onChange={() => this.handleEventGuestsCheckboxChange(externalGuest.email)}
                        className={styles.guestCheckbox}
                        disabled={
                          notInMeeting &&
                          (currentUserRole !== "client_admin" ||
                            currentUserRole !== "client_member")
                        }
                      />
                      <Icon
                        link
                        name="user"
                        className={classnames(
                          styles.guestAttendance,
                          externalGuest.optional && styles.optional,
                          !externalGuest.checked && styles.disabled
                        )}
                        title={externalGuest.optional ? "Set as Required" : "Set as Optional"}
                        onClick={() => this.handleChangeAttendanceRequirement(externalGuest.email)}
                      />
                      <span>{externalGuest.email}</span>
                      {externalGuest.optional && (
                        <span className={styles.guestOptionalText}>(Optional)</span>
                      )}
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.billable)}</p>
          <Form.Checkbox
            checked={eventBillable}
            name="eventBillable"
            onChange={this.handleCheckboxChange}
            className={styles.checkbox}
            disabled={notInMeeting}
            id={`${fromViewScheduler ? "scheduler" : "addmodal"}-eventBillable`}
          />
          <Icon
            className={styles.icon}
            name="dollar sign"
            onClick={() => iconClick("eventBillable")}
          />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.videoCall)}</p>
          <Form.Input
            name="eventVideo"
            type="url"
            placeholder="Enter video conference link"
            onChange={this.handleChange}
            value={eventVideo}
            className={styles.input}
            disabled={notInMeeting}
            id={`${fromViewScheduler ? "scheduler" : "addmodal"}-eventVideo`}
          />
          <Icon className={styles.icon} name="video" onClick={() => iconClick("eventVideo")} />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.description)}</p>
          <Form.Input
            name="eventDescription"
            placeholder="Enter meeting description"
            onChange={this.handleChange}
            value={eventDescription}
            className={styles.input}
            disabled={notInMeeting}
            id={`${fromViewScheduler ? "scheduler" : "addmodal"}-eventDescription`}
          />
          <Icon
            className={styles.icon}
            name="pencil"
            onClick={() => iconClick("eventDescription")}
          />
        </div>
        {fromViewScheduler ? (
          <div className={styles.btnContainer}>
            {!timezoneExpanded && (
              <>
                <Button
                  className={styles.addToCalendarButton}
                  content={event.id ? "Save changes" : "Add to calendar"}
                  disabled={loading || !(detailsChanged || recurrenceChanged)}
                  loading={loading}
                  primary
                  type="submit"
                />
                {event.id && (
                  <Button
                    disabled={loading}
                    loading={loading}
                    content="Cancel meeting"
                    negative
                    onClick={this.handleCancel}
                    type="button"
                  />
                )}
                <Button
                  basic
                  content="Back"
                  disabled={loading}
                  type="button"
                  onClick={() => this.handleModalClose("schedulerView")}
                />
              </>
            )}
            <Button
              basic
              className={styles.toggleButton}
              disabled={loading}
              loading={loading}
              type="button"
              onClick={toggleTimezoneExpanded}
            >
              <Icon
                className={styles.toggleIcon}
                name={timezoneExpanded ? "angle right" : "angle left"}
                size="big"
              />
              {timezoneExpanded ? "" : "Collapse"}
            </Button>
          </div>
        ) : (
          <div className={styles.formActions}>
            <Button
              key="cancel"
              basic
              content={_f(msgs.availability.cancel)}
              onClick={this.handleCancel}
              disabled={loading}
              loading={loading}
              type="button"
            />
            {event.id ? (
              <>
                <Button
                  key="remove"
                  disabled={loading || notInMeeting}
                  loading={loading}
                  content="Cancel meeting"
                  negative
                  onClick={this.handleRemoveEvent}
                  type="button"
                />
                <Button
                  key="save"
                  content={_f(msgs.saveChanges)}
                  disabled={
                    loading ||
                    !(detailsChanged || recurrenceChanged) ||
                    (notInMeeting &&
                      (currentUserRole !== "client_admin" || currentUserRole !== "client_member"))
                  }
                  loading={loading}
                  primary
                  type="submit"
                />
              </>
            ) : (
              <>
                <Button
                  key="addToCalendar"
                  type="submit"
                  content={_f(msgs.addToCalendar)}
                  disabled={loading}
                  loading={loading}
                  primary
                  onClick={() => {
                    this.setState({ addAnother: false });
                  }}
                />
              </>
            )}
          </div>
        )}
      </>
    );
  };

  renderTaskForm = () => {
    const {
      formatMessage: _f,
      msgs,
      event,
      fromTimeTracker,
      fromCalendar,
      adminProjects,
      currentUser,
    } = this.props;
    const {
      id,
      eventIssueUrl,
      eventBillable,
      eventHours,
      eventProject,
      eventIsMeeting,
      eventTitle,
      eventDescription,
      eventDate,
      dateFocused,
      eventCreator,
      detailsChanged,
      loading,
    } = this.state;

    const isAdmin =
      !!id && currentUser.slug !== eventCreator && adminProjects.includes(eventProject);

    return (
      <>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.projectChannel)}</p>
          <ProjectDropdown
            disabled={!!id || (!fromCalendar && !fromTimeTracker) || isAdmin}
            fluid
            required
            name="eventProject"
            onChange={this.handleChange}
            placeholder="Select Team"
            value={eventProject}
          />
        </div>
        <div className={classnames(styles.section, styles.singleDatePicker)}>
          <p className={styles.label}>{_f(msgs.availability.setDate)}</p>
          <SingleDatePicker
            disabled={isAdmin}
            required
            date={eventDate}
            onDateChange={this.handleDateChange}
            focused={dateFocused}
            onFocusChange={({ focused }) => this.setState({ dateFocused: focused })}
            isOutsideRange={() => false}
            daySize={33}
            displayFormat="MMM D, YYYY"
            hideKeyboardShortcutsPanel
            noBorder
            numberOfMonths={1}
            small
            transitionDuration={0}
          />
          <Icon className={styles.icon} name="calendar alternate" />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.activity)}</p>
          <Form.Input
            disabled={isAdmin}
            name="eventDescription"
            placeholder="Enter activity description"
            onChange={this.handleChange}
            required
            value={eventIsMeeting ? eventTitle : eventDescription}
            className={styles.input}
          />
          <Icon className={styles.icon} name="file alternate" />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.hours)}</p>
          <Form.Dropdown
            disabled={isAdmin}
            required
            fluid
            name="eventHours"
            onChange={this.handleChange}
            value={eventHours}
            options={HOURS}
            selection
            search={numberSearch}
            searchInput={{ type: "number" }}
            className={styles.input}
            selectOnBlur
            icon={null}
            openOnFocus
            scrolling
            additionLabel=""
            additionPosition="bottom"
            allowAdditions
            text={`${eventHours}`}
          />
          <Icon className={styles.icon} name="clock outline" />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.billable)}</p>
          <Form.Checkbox
            checked={eventBillable}
            name="eventBillable"
            onChange={this.handleCheckboxChange}
            className={styles.checkbox}
          />
          <Icon className={styles.icon} name="dollar sign" />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>{_f(msgs.availability.issueUrl)}</p>
          <Form.Input
            disabled={isAdmin}
            fluid
            type="text"
            name="eventIssueUrl"
            value={eventIssueUrl}
            placeholder="Enter issue number or URL"
            onChange={this.handleChange}
            className={styles.input}
          />
          <Icon className={styles.icon} name="linkify" />
        </div>
        <div className={styles.formActions}>
          <Button
            key="cancel"
            basic
            content={_f(msgs.availability.cancel)}
            onClick={this.handleCancel}
            disabled={loading}
            loading={loading}
            type="button"
          />
          {event.id ? (
            <>
              <Button
                key="remove"
                disabled={loading || isAdmin}
                loading={loading}
                content={_f(msgs.availability.remove)}
                negative
                onClick={this.handleRemoveEvent}
                type="button"
              />
              <Button
                key="save"
                content={_f(msgs.saveChanges)}
                disabled={loading || !detailsChanged}
                loading={loading}
                primary
                type="submit"
              />
            </>
          ) : (
            <>
              <Button
                key="addToCalendar"
                type="submit"
                content={_f(msgs.addToCalendar)}
                disabled={loading}
                loading={loading}
                primary
                onClick={() => {
                  this.setState({ addAnother: false });
                }}
              />
              <Button
                key="addAndCreateAnother"
                type="submit"
                content={_f(msgs.addAndCreateAnother)}
                disabled={loading}
                loading={loading}
                style={{ backgroundColor: "#2D9CDB", color: "#FFFFFF" }}
                onClick={() => {
                  this.setState({ addAnother: true });
                }}
              />
            </>
          )}
        </div>
      </>
    );
  };

  renderWorkLogTab = () => {
    const { formatMessage: _f, msgs, fromTimeTracker } = this.props;
    const { id, eventIsMeeting, addAnother, errors } = this.state;

    return (
      <Tab.Pane className={styles.modalTab} attached={false}>
        <Form error={!isEmpty(errors)} onSubmit={() => this.handleUpdate(addAnother)}>
          <Errors errors={errors} />
          <div className={styles.section}>
            <Form.Group inline style={{ margin: "0px" }}>
              <p className={styles.label}>{_f(msgs.availability.workType)}</p>
              <Form.Radio
                disabled={!!id || fromTimeTracker}
                className={styles.radioBtn}
                label="Task"
                checked={eventIsMeeting === false}
                value="false"
                name="eventIsMeeting"
                onChange={this.handleChange}
              />
              <Form.Radio
                disabled={!!id || fromTimeTracker}
                className={styles.radioBtn}
                label="Meeting"
                checked={eventIsMeeting === true}
                value="true"
                name="eventIsMeeting"
                onChange={this.handleChange}
              />
              <Icon className={styles.icon} name="suitcase" />
            </Form.Group>
          </div>
          {!fromTimeTracker && eventIsMeeting ? this.renderMeetingForm() : this.renderTaskForm()}
        </Form>
      </Tab.Pane>
    );
  };

  renderUnavailableForWorkTab = () => {
    const { formatMessage: _f, msgs, event } = this.props;
    const {
      outOfOfficeEnd,
      outOfOfficeIsAllDay,
      outOfOfficeTimezone,
      outOfOfficeTitle,
      outOfOfficeStart,
      outOfOfficeBillable,
      errors,
      ruleInput,
      occurrenceId,
      detailsChanged,
      recurrenceChanged,
      loading,
    } = this.state;
    return (
      <Tab.Pane className={styles.modalTab} attached={false}>
        <Form error={!isEmpty(errors)} onSubmit={() => this.handleUpdate(false)}>
          <Errors errors={errors} />
          <div className={styles.section}>
            <p className={styles.label}>{_f(msgs.availability.activity)}</p>
            <Form.Dropdown
              required
              fluid
              name="outOfOfficeTitle"
              onChange={this.handleChange}
              value={outOfOfficeTitle}
              options={OUT_OF_OFFICE_ACTIVITIES}
              selection
              search
              className={styles.input}
              selectOnBlur={false}
              icon={null}
              openOnFocus
              scrolling
            />
            <Icon className={styles.icon} name="file alternate" />
          </div>
          <div className={styles.section}>
            <p className={styles.label}>{_f(msgs.availability.setTime)}</p>
            <div
              onFocus={() => {
                this.dateTimeIcon.current.firstChild.style.color = "#424242";
              }}
              onBlur={() => {
                this.dateTimeIcon.current.firstChild.style.color = "#bdbdbd";
              }}
            >
              <DateTimeRangePicker
                endDate={outOfOfficeEnd.format()}
                endDateName="outOfOfficeEnd"
                hideTime={outOfOfficeIsAllDay}
                required
                startDate={outOfOfficeStart.format()}
                startDateName="outOfOfficeStart"
                small
                onChange={this.handleChange}
              />
              <TimezoneDropdown
                displayOffset
                fluid
                name="outOfOfficeTimezone"
                onChange={this.handleChange}
                placeholder="Select time zone"
                value={outOfOfficeTimezone}
                className={styles.timezoneDropdown}
              />
            </div>
            <span ref={this.dateTimeIcon}>
              <Icon id="calendarAlternate" className={styles.icon} name="calendar alternate" />
            </span>
          </div>
          <div className={styles.section}>
            <p className={styles.label}>{_f(msgs.availability.recurrence)}</p>
            <div
              className={styles.recurrenceFields}
              onFocus={() => {
                this.recurrenceIcon.current.firstChild.style.color = "#424242";
              }}
              onBlur={() => {
                this.recurrenceIcon.current.firstChild.style.color = "#bdbdbd";
              }}
            >
              <Form.Checkbox
                className={classnames(styles.allDay, styles.checkbox)}
                checked={outOfOfficeIsAllDay}
                name="outOfOfficeIsAllDay"
                label={_f(msgs.availability.allDay)}
                onChange={this.handleCheckboxChange}
              />
              <RecurrenceInput
                className={styles.recurrenceInput}
                disabled={Number.isInteger(occurrenceId)}
                endRecurringPeriod={ruleInput.endRecurringPeriod}
                frequency={ruleInput.frequency}
                startDate={outOfOfficeStart}
                params={ruleInput.params}
                onChange={this.handleRecurrenceChange}
              />
            </div>
            <span ref={this.recurrenceIcon}>
              <Icon className={styles.icon} name="sync" />
            </span>
          </div>
          <div className={styles.section}>
            <p className={styles.label}>{_f(msgs.availability.billable)}</p>
            <Form.Checkbox
              checked={outOfOfficeBillable}
              name="outOfOfficeBillable"
              onChange={this.handleCheckboxChange}
              className={styles.checkbox}
            />
            <Icon className={styles.icon} name="dollar sign" />
          </div>
          <div className={styles.formActions}>
            <Button
              key="cancel"
              type="button"
              basic
              content={_f(msgs.availability.cancel)}
              onClick={this.handleCancel}
              disabled={loading}
              loading={loading}
            />
            {event.id ? (
              [
                <Button
                  key="remove"
                  disabled={loading}
                  loading={loading}
                  content={_f(msgs.availability.remove)}
                  negative
                  onClick={this.handleRemoveEvent}
                  type="button"
                />,
                <Button
                  key="save"
                  content={_f(msgs.saveChanges)}
                  disabled={loading || !(detailsChanged || recurrenceChanged)}
                  loading={loading}
                  primary
                  type="submit"
                />,
              ]
            ) : (
              <Button
                key="addToCalendar"
                type="submit"
                content={_f(msgs.addToCalendar)}
                disabled={loading}
                loading={loading}
                primary
              />
            )}
          </div>
        </Form>
      </Tab.Pane>
    );
  };

  renderLoader = () => (
    <Dimmer active style={{ background: "transparent" }}>
      <Loader />
    </Dimmer>
  );

  render() {
    const {
      calendars,
      currentRoomId,
      currentUser,
      event,
      eventOccurrences,
      formatMessage: _f,
      fromCalendar,
      fullyLoaded,
      loadRoomCalendar,
      loadRoomMemberCalendar,
      members,
      msgs,
      open,
      projects,
      fromTimeTracker,
    } = this.props;
    const {
      activeIndex,
      confirmEditModal,
      deleteModal,
      eventIsMeeting,
      eventProjectChannel,
      id,
      isOutOfOffice,
      recurrenceChoice,
      recurrenceConfirmModal,
      recurrenceModal,
      recurrenceSource,
      loading,
      schedulerView,
      warningOnCloseModal,
    } = this.state;
    const isProjectMeeting = !!eventProjectChannel && eventIsMeeting;
    const panes = [
      {
        menuItem: (
          <Menu.Item key="workLog" disabled={!!id && isOutOfOffice}>
            {_f(msgs.availability.workLog)}
          </Menu.Item>
        ),
        render: this.renderWorkLogTab,
      },
      {
        menuItem: (
          <Menu.Item key="unavailableForWork" disabled={(!!id && !isOutOfOffice) || !fromCalendar}>
            {_f(msgs.availability.unavailableForWork)}
          </Menu.Item>
        ),
        render: this.renderUnavailableForWorkTab,
      },
    ];

    return (
      <>
        <Modal
          className={styles.availabilityAddModal}
          closeIcon
          onClose={this.handleCreateModalClose}
          open={open}
          size="mini"
          eventOccurrenceRaw
          closeOnDimmerClick
        >
          {fullyLoaded ? (
            <Tab
              activeIndex={activeIndex}
              className={styles.modalContent}
              onTabChange={this.handleTabChange}
              menu={{ secondary: true, pointing: true }}
              panes={panes}
            />
          ) : (
            this.renderLoader()
          )}
        </Modal>
        <RecurrenceConfirmModal
          key="recurrenceConfirm"
          onConfirm={this.handleRecurrenceUpdate}
          onClose={() => this.handleModalClose("recurrenceConfirmModal")}
          open={recurrenceConfirmModal}
          event={event}
          recurrenceChoice={recurrenceChoice}
        />
        <DeleteEventModal
          key="deleteModal"
          isCancelEvent={isProjectMeeting && !fromTimeTracker}
          onClose={() => this.handleModalClose("deleteModal")}
          onDelete={
            isProjectMeeting && !fromTimeTracker ? this.handleEventCancel : this.handleEventRemove
          }
          open={deleteModal}
          event={event}
          onRecurrentDelete={this.handleRecurrentDelete}
        />
        <RecurrenceModal
          key="recurrenceModal"
          onCancel={() => this.handleModalClose("recurrenceModal")}
          onConfirm={this.handleRecurrenceModalConfirm}
          open={recurrenceModal}
          event={event}
          choice={recurrenceChoice}
          onChange={this.handleChange}
          source={recurrenceSource}
          loading={loading}
        />
        <EditWorkLogModal
          key="confirmEditModal"
          onClose={() => this.handleModalClose("confirmEditModal")}
          open={confirmEditModal}
          onConfirm={this.handleConfirmEdit}
          event={event}
          projects={projects}
        />
        <SchedulerView
          key="schedulerView"
          ref={this.schedulerViewRef}
          calendars={calendars}
          currentRoomId={currentRoomId}
          currentUser={currentUser}
          event={event}
          eventOccurrences={eventOccurrences}
          loadRoomCalendar={loadRoomCalendar}
          loadRoomMemberCalendar={loadRoomMemberCalendar}
          members={members}
          open={schedulerView}
          onChange={(newState) => this.setState(newState)}
          onClose={() => this.handleModalClose("schedulerView")}
          sharedFunctions={{
            renderMeetingForm: this.renderMeetingForm,
            handleUpdate: this.handleUpdate,
          }}
          sharedState={{ ...this.state }}
        />
        <WarningOnCloseModal
          onClose={() => this.handleModalClose("warningOnCloseModal")}
          open={warningOnCloseModal}
          onConfirm={this.handleCancel}
        />
      </>
    );
  }
}

CalendarEventAddModal.propTypes = {
  adminProjects: PropTypes.arrayOf(PropTypes.number),
  applyFilter: PropTypes.func,
  byMonth: PropTypes.bool,
  calendars: PropTypes.objectOf(PropTypes.shape()).isRequired,
  createEvent: PropTypes.func.isRequired,
  createMeeting: PropTypes.func.isRequired,
  currentDate: PropTypes.shape(),
  currentRoomId: PropTypes.number,
  currentProjectId: PropTypes.number,
  currentUser: PropTypes.shape().isRequired,
  deleteEvent: PropTypes.func.isRequired,
  deleteMeeting: PropTypes.func.isRequired,
  event: PropTypes.shape(),
  eventOccurrenceId: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
  eventOccurrences: PropTypes.objectOf(PropTypes.shape()).isRequired,
  fullyLoaded: PropTypes.bool.isRequired,
  formatMessage: PropTypes.shape().isRequired,
  fromCalendar: PropTypes.bool,
  fromProjectCalendar: PropTypes.bool,
  fromTimeTracker: PropTypes.bool,
  loadEventOccurrence: PropTypes.func.isRequired,
  loadRoomCalendar: PropTypes.func.isRequired,
  loadRoomMemberCalendar: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  memberRoles: PropTypes.objectOf(projectMembershipType).isRequired,
  msgs: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  projectChannels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  projectMemberships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showViewScheduler: PropTypes.bool,
  start: PropTypes.shape().isRequired,
  updateEvent: PropTypes.func.isRequired,
  userCalendar: PropTypes.shape().isRequired,
  worklog: PropTypes.shape(),
};

CalendarEventAddModal.defaultProps = {
  applyFilter: () => {},
  byMonth: false,
  fromCalendar: false,
  fromProjectCalendar: false,
  fromTimeTracker: false,
  open: false,
  showViewScheduler: false,
};

function mapStateToProps(state, ownProps) {
  const {
    eventOccurrenceId,
    fromProjectCalendar,
    selectedEnd = moment(),
    selectedStart = moment(),
  } = ownProps;
  const {
    auth: { user: currentUser },
    entities: { calendars, eventOccurrences, events, projectMemberships, users },
  } = state;

  const userCalendar =
    currentUser && calendars[currentUser.slug] ? calendars[currentUser.slug] : {};

  const currentRoom = getCurrentRoom(state) || { invitations: [], userSlugs: [], project: null };
  const memberRoles = getCurrentProjectMemberships(state);

  const { event, fullyLoaded } = apiDataToEventObject({
    currentRoom,
    currentUser,
    eventOccurrenceId,
    eventOccurrences,
    events,
    fromProjectCalendar,
    selectedEnd,
    selectedStart,
  });

  const memberships = flatten(Object.values(projectMemberships)).reduce((acc, m) => {
    const member = users[m.userSlug];
    if (member) {
      acc.push({
        avatarOrDefault: member.avatarOrDefault,
        avatarBgcolor: member.avatarBgcolor,
        avatarColor: member.avatarColor,
        displayName: member.displayName,
        projectId: m.projectId,
        slug: member.slug,
        email: member.email,
      });
    }
    return acc;
  }, []);

  const currentProject = getCurrentProject(state);
  let members = [];
  if (currentProject) {
    members = flatten(Object.values(projectMemberships)).reduce((acc, m) => {
      const member = users[m.userSlug];
      if (member && m.projectId === currentProject.id) {
        const memberData = { ...member, projectId: m.projectId };
        if (member.slug !== currentUser.slug) {
          acc.push(memberData);
        } else {
          acc.unshift(memberData);
        }
      }
      return acc;
    }, []);
  } else {
    members = currentRoom?.users?.map((member) => member);
  }

  const currentUserProjectRoomsActive = getCurrentUserProjectRoomsActive(state);
  const projectChannels = flatten(Object.values(currentUserProjectRoomsActive)).map((r) => ({
    id: r.id,
    text: `${r.projectName} #${r.name}`,
    projectId: r.project,
    value: r.id,
    room: r,
  }));

  const currentUserProjectsActive = getCurrentUserProjectsActive(state);
  const flattenedProjects = flatten(Object.values(currentUserProjectsActive)).map((p) => ({
    id: p.id,
    text: `${p.name}`,
    project: p,
    value: p.id,
  }));
  const projects = orderBy(flattenedProjects, "text", "asc");

  const currentUserProjectMemberships = getCurrentUserProjectMemberships(state);
  const adminProjects = flatten(Object.values(currentUserProjectMemberships))
    .filter((m) => m.role === "admin")
    .map((p) => p.projectId);

  return {
    adminProjects,
    calendars,
    currentProjectId: currentRoom.project,
    currentRoomId: currentRoom?.id,
    currentUser,
    event,
    eventOccurrences,
    fullyLoaded,
    members,
    memberRoles,
    msgs: defineMessages(MESSAGES),
    projectChannels,
    projectMemberships: memberships,
    projects,
    userCalendar,
  };
}

export default connect(
  mapStateToProps,
  {
    loadUserCalendar,
    createEvent,
    deleteEvent: eventDelete.request,
    updateEvent,
    addGoogleCalendar,
    addOutlookCalendar,
    setSelectedEvent,
    createMeeting: roomMeetingCreate.request,
    deleteMeeting: roomMeetingDelete.request,
    loadRoomCalendar: roomCalendarAction.request,
    joinMeeting: roomMeetingJoin.request,
    loadEventOccurrence: eventOccurrenceDetail.request,
    loadRoomMemberCalendar: roomMemberCalendar.request,
  },
  null,
  { forwardRef: true }
)(CalendarEventAddModal);
