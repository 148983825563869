import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Form } from "semantic-ui-react";

import styles from "./styles.module.scss";

export default class RecurrenceRepeatsCheckbox extends React.PureComponent {
  render() {
    const { checked, onChange } = this.props;
    return (
      <div className={classnames(styles.customModalSection, styles.customModalRepeats)}>
        <div className={styles.customModalRepeatsLabel}>
          <FormattedMessage defaultMessage="Repeats" id="RecurrenceInput.modalId.repeats" />
        </div>
        <div>
          <Form.Checkbox
            name="repeats"
            className={styles.repeatsCheckbox}
            checked={checked}
            onChange={(event, data) => {
              onChange(event, { name: "repeats", value: data.checked });
            }}
          />
        </div>
      </div>
    );
  }
}

RecurrenceRepeatsCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
