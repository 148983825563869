import React from "react";
import ReactContentLoader from "react-content-loader";

const ContentLoader = (props) => (
  <ReactContentLoader
    speed={2}
    backgroundColor="#e0e0e0"
    foregroundColor="#acabab"
    viewBox="0 0 480 130"
    {...props}
  >
    <rect x="60" y="15" rx="4" ry="4" width="117" height="12" />
    <rect x="60" y="45" rx="3" ry="3" width="350" height="16" />
    <rect x="60" y="65" rx="3" ry="3" width="480" height="16" />
    <rect x="60" y="85" rx="3" ry="3" width="301" height="16" />
    <circle cx="25" cy="30" r="18" />
  </ReactContentLoader>
);

export default () => (
  <div style={{ width: 480, marginLeft: 20 }}>
    <ContentLoader />
  </div>
);
