import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink as Link, matchPath, withRouter } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";

import config from "config";
import { logoutInit } from "actions/account";
import AvatarMenu from "components/AvatarMenu";
import NotificationBell from "containers/Notification/NotificationBell";
import { getRooms } from "reducers/selectors";
import styles from "./styles.module.scss";

class Topbar extends React.Component {
  handleLogout = () => {
    this.props.logoutInit();
  };

  render() {
    const { currentUser, location, hasUnreadMessages } = this.props;

    const isChat = matchPath(location.pathname, { path: "/chat" });
    const isCalendar = matchPath(location.pathname, { path: "/talents/:username/calendar" });
    const isTimeTracker = matchPath(location.pathname, { path: "/timetracker" });
    const isSchedule = matchPath(location.pathname, { path: "/schedule" });
    const isHealth = matchPath(location.pathname, { path: "/talents/:username/health" });
    const isNotifications = matchPath(location.pathname, { path: "/notifications" });

    return (
      <div className={styles.topbar}>
        <Link to="/" className={styles.logo} title="Home">
          {config.flags.brand.sidebarLogo ? (
            <img src={config.brandSidebarLogo} width="auto" alt={config.appName} />
          ) : (
            <img src="/bld-logo.png" alt={config.appName} />
          )}
        </Link>
        <Menu text icon className={styles.menu}>
          {config.flags.chat && (
            <Menu.Item
              name="workspace"
              className={styles.menuItem}
              as={Link}
              to="/chat"
              active={!!isChat}
              activeClassName={styles.menuItemActive}
            >
              <img src="/workspace.png" alt="workspace" />
              <span className={styles.menuLabel}>Workspace</span>
              {hasUnreadMessages && <div className={styles.unreadMessages} />}
            </Menu.Item>
          )}

          <Menu.Item
            name="calendar"
            className={styles.menuItem}
            as={Link}
            to={`/talents/${currentUser.slug}/calendar`}
            active={!!isCalendar}
            activeClassName={styles.menuItemActive}
          >
            <Icon name="calendar alternate" />
            <span className={styles.menuLabel}>Calendar</span>
          </Menu.Item>

          {config.flags.timeTracker && (
            <Menu.Item
              name="time-tracker"
              className={styles.menuItem}
              as={Link}
              to="/timetracker"
              active={!!isTimeTracker}
              activeClassName={styles.menuItemActive}
            >
              <Icon name="clock" />
              <span className={styles.menuLabel}>Time Tracker</span>
            </Menu.Item>
          )}

          {config.flags.schedule && currentUser.weeklyAssignmentsAccessType && (
            <Menu.Item
              name="schedule"
              className={styles.menuItem}
              as={Link}
              to="/schedule"
              active={!!isSchedule}
              activeClassName={styles.menuItemActive}
            >
              <img src="/schedule.svg" alt="schedule" className={styles.scaled} />
              <span className={styles.menuLabel}>Schedule</span>
            </Menu.Item>
          )}

          {config.flags.health && (
            <Menu.Item
              name="health"
              className={styles.menuItem}
              as={Link}
              to={`/talents/${currentUser.slug}/health`}
              active={!!isHealth}
              activeClassName={styles.menuItemActive}
            >
              <Icon name="heartbeat" />
              <span className={styles.menuLabel}>Health</span>
            </Menu.Item>
          )}
        </Menu>
        {config.flags.notifications && <NotificationBell active={isNotifications} />}
        <AvatarMenu currentUser={currentUser} logout={this.handleLogout} />
      </div>
    );
  }
}

Topbar.propTypes = {
  currentUser: PropTypes.shape(),
  location: PropTypes.shape(),
  logoutInit: PropTypes.func.isRequired,
  hasUnreadMessages: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser },
    messages: { unreadMessagesCountByRoom },
  } = state;

  const rooms = getRooms(state) || {};
  const unreadMessagesCount = Object.keys(rooms).reduce(
    (count, roomId) => count + unreadMessagesCountByRoom[roomId],
    0
  );
  const hasUnreadMessages = !Number.isNaN(unreadMessagesCount) && unreadMessagesCount > 0;

  return { currentUser, hasUnreadMessages };
}

export default withRouter(connect(mapStateToProps, { logoutInit })(Topbar));
