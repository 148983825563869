import PropTypes from "prop-types";
import React from "react";
import { Icon } from "semantic-ui-react";

import { getRoomName } from "utils";

import MessageRoom from "./MessageRoom";
import styles from "./styles.module.scss";

const UnreadList = ({
  currentRoom,
  currentUser,
  header,
  projects,
  projectRooms,
  currentUserMessageRooms,
  currentUserTeamRooms,
  searchValue,
  unreadMessagesCountByRoom,
  unreadMessagesLastTimestampByRoom,
  users,
  roomHideRequest,
  lastRoomTab,
}) => {
  const messageRoomsFiltered = currentUserMessageRooms.filter(
    (messageRoom) =>
      getRoomName(messageRoom, messageRoom.users, currentUser).toLowerCase().indexOf(searchValue) >=
      0
  );

  const teamRoomsFiltered = currentUserTeamRooms.filter(
    (messageRoom) =>
      getRoomName(messageRoom, messageRoom.users, currentUser).toLowerCase().indexOf(searchValue) >=
      0
  );

  const getIcon = (room) => {
    if (room.isGeneral) return "globe";
    if (room.isPrivate) return "lock";
    if (room.hasCall) return "call";
    return "hashtag";
  };

  let currentUserProjectRooms = [];
  projects.forEach((p) => {
    currentUserProjectRooms = [...currentUserProjectRooms, ...projectRooms[p.id]];
  });

  currentUserProjectRooms = currentUserProjectRooms.map((room) => ({
    ...room,
    name: `${room.name} (${room.projectName})`,
    icon: getIcon(room),
  }));

  const projectsRoomsFiltered = currentUserProjectRooms.filter(
    (messageRoom) =>
      getRoomName(messageRoom, messageRoom.users, currentUser).toLowerCase().indexOf(searchValue) >=
      0
  );

  const allRoomsFiltered = [
    ...messageRoomsFiltered,
    ...teamRoomsFiltered,
    ...projectsRoomsFiltered,
  ].map((room) => ({ ...room, isInUnreadList: true }));

  if (unreadMessagesLastTimestampByRoom) {
    allRoomsFiltered.sort(
      (roomA, roomB) =>
        new Date(unreadMessagesLastTimestampByRoom[roomA.id]) <
        new Date(unreadMessagesLastTimestampByRoom[roomB.id])
    );
  }
  const hasUnread = Object.values(unreadMessagesCountByRoom).some((unread) => unread);

  return (
    <>
      {hasUnread && (
        <div className={styles.sidebarSection}>
          <header className={styles.sidebarSectionHeader}>
            <h2 className={styles.sidebarSectionHeaderText}>
              <Icon name="envelope" />
              {header}
            </h2>
          </header>

          <div>
            {allRoomsFiltered
              .filter((room) => unreadMessagesCountByRoom[room.id] || room.id === currentRoom.id)
              .map((room) => (
                <MessageRoom
                  currentUser={currentUser}
                  isCurrent={room.id === currentRoom.id}
                  key={`unread-${room.id}`}
                  room={room}
                  roomHideRequest={roomHideRequest}
                  unreadMessagesCount={unreadMessagesCountByRoom[room.id] || 0}
                  users={users}
                  lastRoomTab={lastRoomTab}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

UnreadList.propTypes = {
  currentRoom: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
  header: PropTypes.string,
  currentUserMessageRooms: PropTypes.arrayOf(PropTypes.shape()),
  currentUserTeamRooms: PropTypes.arrayOf(PropTypes.shape()),
  roomHideRequest: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  unreadMessagesCountByRoom: PropTypes.objectOf(PropTypes.number),
  unreadMessagesLastTimestampByRoom: PropTypes.shape(),
  users: PropTypes.shape().isRequired,
  projectRooms: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape())),
  projects: PropTypes.arrayOf(PropTypes.shape()),
  lastRoomTab: PropTypes.string,
};

UnreadList.defaultProps = {
  currentRoom: null,
  searchValue: "",
};

export default UnreadList;
