import localforage from "localforage";
import { persistReducer } from "redux-persist";
import { ROOM_CHANGE } from "actions/chat";
import { SIDEBAR } from "actions/sidebar";
import { isTabletWidth } from "utils/responsive";

const initialState = {
  open: isTabletWidth(),
  archivedCollapsed: true,
};

const sidebar = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SIDEBAR.OPEN:
      return { ...state, open: true };
    case SIDEBAR.CLOSE:
    case ROOM_CHANGE:
      return { ...state, open: false };
    case SIDEBAR.ARCHIVED_COLLAPSE:
      return { ...state, archivedCollapsed: true };
    case SIDEBAR.ARCHIVED_EXPAND:
      return { ...state, archivedCollapsed: false };
    default:
      return state;
  }
};

const sidebarPersistConfig = {
  key: "sidebar",
  storage: localforage,
  blacklist: ["open"],
};

export default persistReducer(sidebarPersistConfig, sidebar);
