import { sortBy, flatten } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { getCurrentUserProjectRoomsActive } from "reducers/selectors/project";

import styles from "../styles.module.scss";

class ProjectChannelDropdown extends React.PureComponent {
  render() {
    const {
      disabled,
      fluid,
      name,
      onChange,
      placeholder,
      projectChannels,
      value,
      upward,
    } = this.props;

    return (
      <Dropdown
        clearable
        disabled={disabled}
        fluid={fluid}
        name={name}
        onChange={onChange}
        options={projectChannels}
        placeholder={placeholder}
        search
        selection
        value={value || 0}
        upward={upward}
      />
    );
  }
}

ProjectChannelDropdown.propTypes = {
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  projectChannels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  upward: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ProjectChannelDropdown.defaultProps = {
  disabled: false,
  fluid: false,
  name: "projectChannel",
  onChange: () => {},
  placeholder: "Select project channel",
  upward: false,
  value: null,
};

function mapStateToProps(state, ownProps) {
  const currentUserProjectRoomsActive = getCurrentUserProjectRoomsActive(state);
  const flattenedProjectChannels = flatten(Object.values(currentUserProjectRoomsActive)).map(
    (r) => ({
      id: r.id,
      text: `${r.projectName} #${r.name}`,
      content: (
        <span>
          {r.projectName} <span className={styles.channelName}>#{r.name}</span>
        </span>
      ),
      value: r.id,
    })
  );
  const projectChannels = sortBy(flattenedProjectChannels, [(channel) => channel.text]);
  if (!ownProps.hideMyCalendar) {
    projectChannels.unshift({
      id: 0,
      text: "My Calendar",
      value: 0,
    });
  }

  return { projectChannels };
}

export default connect(mapStateToProps, {})(ProjectChannelDropdown);
