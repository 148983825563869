import Humanize from "humanize-plus";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Progress } from "semantic-ui-react";

import AvatarList from "components/AvatarList";
import { renderers, plugins } from "components/Markdown";
import { sentinelSlug } from "utils/constants";
import { PollOptionType } from "utils/constants/poll";

import styles from "./styles.module.scss";

const MessageListItemPollResultListItem = ({
  currentVoteCount = 0,
  name,
  optionType,
  totalVoteCount = 0,
  users,
  votes = {},
}) => {
  let anonymousCount = 0;
  const voters = Object.keys(votes);
  const usersFiltered = voters
    .map((voter) => {
      if (!voter) {
        anonymousCount += votes[voter];
        return null;
      }

      const user = users[voter];

      if (!user) {
        return users[sentinelSlug];
      }
      return user;
    })
    .filter((user) => user);

  const widthValue = (100.0 * currentVoteCount) / totalVoteCount;

  let currentVoteCountSuffix;
  if (optionType === PollOptionType.pointAllocation) {
    currentVoteCountSuffix = "%";
  } else {
    currentVoteCountSuffix = Humanize.pluralize(currentVoteCount, "pt");
  }

  return (
    <div className={styles.messageListItemPollResultsItem}>
      <div className={styles.messageListItemPollResultsItemHeader}>
        <div className={styles.messageListItemPollResultsItemVoteCount}>
          {currentVoteCount} {currentVoteCountSuffix}
        </div>

        <AvatarList
          anonymousCount={anonymousCount}
          className={styles.messageListItemPollResultsItemAvatarList}
          users={usersFiltered}
        />
      </div>

      <Progress className={styles.messageListItemPollResultsItemBar} percent={widthValue} />

      <ReactMarkdown
        className={styles.messageListItemPollResultsItemOption}
        plugins={plugins}
        renderers={renderers}
        source={name}
        unwrapDisallowed
      />
    </div>
  );
};

MessageListItemPollResultListItem.propTypes = {
  anonymous: PropTypes.bool,
  currentVoteCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  optionType: PropTypes.oneOf(PollOptionType.all).isRequired,
  totalVoteCount: PropTypes.number,
  users: PropTypes.shape().isRequired,
  votes: PropTypes.shape().isRequired,
};

export default MessageListItemPollResultListItem;
