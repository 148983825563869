import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink as Link } from "react-router-dom";
import { Icon, Popup, Divider } from "semantic-ui-react";
import UserAvatar from "components/UserAvatar";
import { createRoom } from "actions/chat";
import styles from "./styles.module.scss";

class ContactModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { createRoomRequest, token, user, users } = this.props;
    const selectedUser = user.email
      ? [user]
      : [Object.values(users).filter((u) => u.slug === user)[0]];
    createRoomRequest({
      name: "",
      userSlugs: selectedUser.map((u) => u.slug),
      token,
    });
  };

  render() {
    const { user, currentUser, displayYou, users, propStyle } = this.props;

    if (!user) {
      return null;
    }

    const selectedUser = user.email ? user : Object.values(users).filter((u) => u.slug === user)[0];
    if (!selectedUser) return null;

    return (
      <Popup
        className={styles.contactModal}
        flowing
        hoverable
        trigger={
          <span
            className={classnames(
              propStyle,
              styles.pointer,
              !selectedUser.displayName && styles.messageListItemEventExternalGuest
            )}
          >
            {selectedUser.displayName || selectedUser.email}{" "}
            {displayYou && selectedUser.slug === currentUser.slug && " (You)"}
          </span>
        }
        popperModifiers={{
          preventOverflow: {
            boundariesElement: "offsetParent",
          },
        }}
      >
        <div className={styles.contactModalMenu}>
          <Popup.Header className={styles.contactModalMenuHeader}>
            <Link to={`/talents/${selectedUser.slug}`}>
              <UserAvatar
                avatar={selectedUser.avatarOrDefault}
                bgColor={selectedUser.avatarBgcolor}
                className="avatar"
                displayName={selectedUser.displayName}
                fgColor={selectedUser.avatarColor}
                isAnonymous={!selectedUser.avatarOrDefault}
                size="40"
              />
            </Link>

            <div className={styles.contactModalMenuHeaderText}>
              <h2
                className={styles.contactModalMenuHeaderTextTitle}
                title={selectedUser.displayName}
              >
                {selectedUser.displayName}
              </h2>

              <h3 className={styles.contactModalMenuHeaderTextSubtitle} title={selectedUser.email}>
                {selectedUser.email}
              </h3>
            </div>
          </Popup.Header>

          <Divider fitted className={styles.contactModalDivider} />
          <div className={styles.contactModalLinks}>
            <Link
              to={`/talents/${selectedUser.slug}/bio`}
              target="_blank"
              title="View profile"
              className={styles.selectedUser}
            >
              <Icon name="user" />
            </Link>
            <Link
              to={`/talents/${selectedUser.slug}/calendar`}
              target="_blank"
              title="View Calendar"
              className={styles.calendar}
            >
              <Icon name="calendar outline" />
            </Link>

            {selectedUser.slug !== currentUser.slug ? (
              <span
                role="button"
                tabIndex={0}
                title="Chat"
                className={styles.chat}
                onClick={this.handleSubmit}
                onKeyDown={this.handleSubmit}
              >
                <Icon name="envelope outline" />
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </Popup>
    );
  }
}

ContactModal.propTypes = {
  user: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape(),
  createRoomRequest: PropTypes.func.isRequired,
  token: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  displayYou: PropTypes.bool,
  propStyle: PropTypes.shape(),
};

ContactModal.defaultProps = {
  propStyle: styles.messageListItemEventGuestItemName,
};

function mapStateToProps(state) {
  const {
    auth: { token, user: currentUser },
    entities: { users },
  } = state;

  return {
    currentUser,
    token,
    users,
  };
}

export default connect(mapStateToProps, {
  createRoomRequest: createRoom.request,
})(ContactModal);
