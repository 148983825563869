import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "containers/Profile/styles.module.scss";

class DeleteEventModal extends React.PureComponent {
  render() {
    const { isCancelEvent, onClose, onDelete, open, event, onRecurrentDelete } = this.props;
    const { isOutOfOffice, isWorkLog } = event;

    let heading = "Remove meeting from calendar";
    let content = "You are about to remove this meeting from your calendar.";
    let removeText = "Remove meeting";

    if (isWorkLog) {
      heading = "Remove task from calendar";
      content = "You are about to permanently delete this task work log.";
      removeText = "Remove task";
    } else if (isOutOfOffice) {
      heading = "Remove unavailable for work log";
      content = "You are about to permanently delete this unavailable for work log.";
      removeText = "Remove log";
    }

    return (
      <Modal onClose={onClose} open={open} className={styles.deleteEventModal} closeIcon>
        <Modal.Content className={styles.modalContent}>
          <p className={styles.heading}>{isCancelEvent ? "Cancel event" : heading}</p>
          <p className={styles.content}>
            {isCancelEvent ? "You are about to cancel this event." : content}
          </p>
          <p className={styles.content}>Would you like to proceed?</p>
          <div className={styles.actionsContainer}>
            <Button
              className={classnames(styles.action, styles.delete)}
              floated="right"
              onClick={event.rule?.frequency ? onRecurrentDelete : onDelete}
            >
              {isCancelEvent ? "Yes, cancel event" : removeText}
            </Button>
            <Button
              className={classnames(styles.action, styles.cancel)}
              floated="right"
              onClick={onClose}
            >
              {isCancelEvent ? "No, do not cancel event" : "Cancel"}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

DeleteEventModal.propTypes = {
  isCancelEvent: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool,
  event: PropTypes.shape().isRequired,
  onRecurrentDelete: PropTypes.func.isRequired,
};

DeleteEventModal.defaultProps = {
  isCancelEvent: false,
  open: false,
};

export default DeleteEventModal;
