import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import styles from "./styles.module.scss";

class FormattingTools extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  toggleState = () => {
    this.setState(
      (prevState) => ({
        active: !prevState.active,
      }),
      () => {
        const { onToggle } = this.props;
        onToggle(this.state.active);
      }
    );
  };

  handleBoldText = () => {
    const { onSubmit } = this.props;
    onSubmit(`****`, 2);
  };

  handleItalicText = () => {
    const { onSubmit } = this.props;
    onSubmit(`__`, 1);
  };

  handleStrikethroughText = () => {
    const { onSubmit } = this.props;
    onSubmit(`~~~~`, 2);
  };

  handleAddCode = () => {
    const { onSubmit } = this.props;
    onSubmit(`\`\``, 1);
  };

  handleAddQuote = () => {
    const { onSubmit } = this.props;
    onSubmit(`> `, 3, true, false);
  };

  handleAddLink = () => {
    const { onSubmit } = this.props;
    onSubmit(`[]()`, 1);
  };

  handleAddBulletedList = () => {
    const { onSubmit } = this.props;
    onSubmit(`* `, 3, true, false);
  };

  handleAddNumberedList = () => {
    const { onSubmit } = this.props;
    onSubmit(`1. `, 4, true, false);
  };

  handleAddCodeBlock = () => {
    const { onSubmit } = this.props;
    const codeBlock = `\`\`\`

\`\`\``;
    onSubmit(codeBlock, 4, true, true);
  };

  render() {
    const { inChat, disabled } = this.props;
    const { active } = this.state;
    return (
      <div className={classnames(styles.formattingTools, active && styles.activeContainer)}>
        <Button
          basic
          icon="font"
          disabled={disabled}
          type="button"
          title={active ? "Hide Formatting" : "Show Formatting"}
          onClick={this.toggleState}
          className={classnames(styles.trigger, active && styles.activeTool)}
        />
        <div
          className={classnames(
            styles.toolsContainer,
            active && styles.active,
            !inChat && styles.roundedBottomCorners
          )}
        >
          <Button
            basic
            icon="bold"
            type="button"
            title="Add bold text"
            onClick={this.handleBoldText}
          />
          <Button
            basic
            icon="italic"
            type="button"
            title="Add italic text"
            onClick={this.handleItalicText}
          />
          <Button
            basic
            icon="strikethrough"
            type="button"
            title="Add strikethrough text"
            onClick={this.handleStrikethroughText}
          />
          <Button
            basic
            icon="code"
            type="button"
            title="Insert code"
            onClick={this.handleAddCode}
          />
          <Button
            basic
            icon="linkify"
            type="button"
            title="Add a link"
            onClick={this.handleAddLink}
          />
          <Button
            basic
            icon="list ul"
            type="button"
            title="Add a bullet list"
            onClick={this.handleAddBulletedList}
          />
          <Button
            basic
            icon="list ol"
            type="button"
            title="Add a numbered list"
            onClick={this.handleAddNumberedList}
          />
          <Button
            basic
            icon="quote right"
            type="button"
            title="Insert a quote"
            onClick={this.handleAddQuote}
          />
          <Button
            basic
            icon="terminal"
            type="button"
            title="Insert code block"
            onClick={this.handleAddCodeBlock}
          />
        </div>
      </div>
    );
  }
}

FormattingTools.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onToggle: PropTypes.func,
  inChat: PropTypes.bool,
};

FormattingTools.defaultProps = {
  disabled: false,
  onSubmit: () => {},
  onToggle: () => {},
  inChat: false,
};

/*
`onSubmit` parameters are the following:
  :formattingText (str) - markdown format
  :insertIndex (int) - index where to insert text (if there"s a selected text in the text area)
  :newLineStart (bool) - True if new line is needed at the start of the formatting text
  :newLineEnd (bool) - True if new line is needed at the end of the formatting text
*/

/*
`onToggle` is for when you want to execute other methods when formatting tools gets toggled
*/

export default FormattingTools;
