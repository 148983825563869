import { NOTIFICATION, PAGINATED_NOTIFICATION, MARK_READ_NOTIFICATION } from "actions/notification";

export function createNotificationReducer(entity, paginatedEntity, markRead) {
  return (
    state = {
      loading: false,
      success: true,
      failure: false,
      errorDetails: null,
      unreadCount: 0,
      nextPage: undefined,
      nextPageNumber: 0,
      count: undefined,
    },
    action
  ) => {
    const { type, response } = action;
    let nextPageNumberVal = 0;
    let nextPageNumber = 0;
    if (type === entity.SUCCESS || type === paginatedEntity.SUCCESS) {
      nextPageNumberVal = response.nextPage && new URL(response.nextPage).searchParams.get("page");
      nextPageNumber = parseInt(nextPageNumberVal, 10);
    }

    switch (type) {
      case entity.REQUEST:
        return { ...state, loading: true, success: false, failure: false, errorDetails: null };
      case paginatedEntity.REQUEST:
        return { ...state, loading: true, success: false, failure: false, errorDetails: null };
      case entity.SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          failure: false,
          errorDetails: null,
          unreadCount: Object.keys(response.entities.notification || {})
            .map((key) => response.entities.notification[key])
            .filter((obj) => obj.unread === true).length,
          nextPage: response.nextPage,
          nextPageNumber,
          count: response.count,
        };
      case paginatedEntity.SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          failure: false,
          errorDetails: null,
          unreadCount: Object.keys(response.entities.notification || {})
            .map((key) => response.entities.notification[key])
            .filter((obj) => obj.unread === true).length,
          nextPage: response.nextPage,
          nextPageNumber,
          count: response.count,
        };
      case entity.FAILURE || paginatedEntity.FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          failure: true,
          errorDetails: { ...action.errorDetails },
        };
      case markRead.SUCCESS:
        return {
          ...state,
          unreadCount: 0,
        };
      default:
        return state;
    }
  };
}

export default createNotificationReducer(
  NOTIFICATION,
  PAGINATED_NOTIFICATION,
  MARK_READ_NOTIFICATION
);
