import localforage from "localforage";
import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";

import { AUTH_LOGOUT } from "actions/account";
import { closeAllCalls } from "utils/chat";

import { persistReducer } from "redux-persist";
import {
  auth,
  passwordReset,
  passwordResetConfirm,
  isAuthenticating,
  isAuthenticated,
  passwordChange,
  teamInvitation,
} from "./account";
import attachments from "./attachments";
import budget from "./budget";
import calendar from "./calendar";
import { cachedMessages, messages, messageAttachments, polls, rooms } from "./chat";
import entities from "./entities";
import errorMessage from "./errorMessage";
import {
  workmates,
  feedback,
  workmateFeedback,
  talentFeedback,
  skillFeedback,
  feedbackRequest,
  feedbackWizard,
  groupFeedback,
  conjointPair,
  conjointFeedback,
  ratingsHistory,
  teamRatingsHistory,
  relations,
  commonSkill,
} from "./feedback";
import modals from "./modals";
import notification from "./notification";
import project from "./project";
import projectBudget from "./projectBudget";
import { weeklyAssignment, weeklyAssignmentHistory } from "./schedule";
import sidebar from "./sidebar";
import socket from "./socket";
import sidePanel from "./sidePanel";
import skill, { hierarchy } from "./skill";
import team from "./team";
import user from "./user";
import { hasNewVersion } from "./version";
import {
  healthForm,
  healthFormData,
  healthFormDataUpdate,
  healthSummaryAggregated,
  healthPersonal,
  healthGuide,
} from "./health";
import subscription from "./subscription";
import workLog from "./timetracker";

const entitiesPersistConfig = {
  key: "entities",
  storage: localforage,
  whitelist: [
    "calendars",
    "eventOccurrences",
    "googleCalendars",
    "events",
    "outlookCalendars",
    "projects",
    "users",
  ],
};

const appReducer = combineReducers({
  auth,
  attachments,
  budget,
  cachedMessages,
  calendar,
  commonSkill,
  conjointFeedback,
  conjointPair,
  entities: persistReducer(entitiesPersistConfig, entities),
  errorMessage,
  feedback,
  feedbackRequest,
  feedbackWizard,
  groupFeedback,
  hasNewVersion,
  healthForm,
  healthFormData,
  healthFormDataUpdate,
  healthSummaryAggregated,
  healthPersonal,
  healthGuide,
  hierarchy,
  isAuthenticated,
  isAuthenticating,
  loadingBar: loadingBarReducer,
  messages,
  messageAttachments,
  modals,
  notification,
  socket,
  passwordChange,
  passwordReset,
  passwordResetConfirm,
  polls,
  project,
  projectBudget,
  ratingsHistory,
  relations,
  rooms,
  sidebar,
  sidePanel,
  skill,
  skillFeedback,
  subscription,
  talentFeedback,
  team,
  teamInvitation,
  teamRatingsHistory,
  user,
  weeklyAssignment,
  weeklyAssignmentHistory,
  workLog,
  workmateFeedback,
  workmates,
});

const initialState = appReducer({}, {});
initialState.auth.initialLoad = true;
delete initialState.errorMessage;

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT.SUCCESS) {
    closeAllCalls();
    localforage.clear();
    return appReducer(initialState, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
