import { call, fork, put, take } from "redux-saga/effects";

import { api, token as tokenService } from "services";
import { navigate } from "actions";
import * as project from "actions/project";
import * as log from "actions/log";
import * as chat from "actions/chat";

import { pushEntity } from "./base";

const pushProject = pushEntity.bind(null, project.project, api.updateProject);

export function* loadProjects({ id, refresh, url }) {
  yield put(project.project.request(id));

  const token = yield tokenService.getAuthToken();
  if (id) {
    const { response, error } = yield call(api.fetchProject, token, id);
    if (response) {
      yield put(project.project.success(response, id));
    } else {
      yield put(project.project.failure(error, id));
    }
  } else {
    const apiUrl = url || "projects/";
    const { response, error } = yield call(api.fetchProjects, token, apiUrl);
    if (response) {
      const { nextPage } = response;
      yield put(project.project.success(response));
      if (nextPage) {
        yield call(loadProjects, { refresh, url: nextPage });
      }
    } else {
      yield put(project.project.failure(error));
    }
  }
}

export function* loadTalentProjects({ slug }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.loadTalentProjects, token, slug);
  if (response) {
    yield put(project.talentProject.success(response));
  } else {
    yield put(project.talentProject.failure({ error, errorDetails }));
  }
}

export function* updateProject({ id, data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushProject, true, token, id, null, data);
}

function* createProject(id, name, clientSlug, roomId) {
  yield put(project.createProject.request());
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.invite, token, id, name, roomId);
  if (response) {
    yield put(project.createProject.success(response));
    yield put(chat.getRooms.request({ token }));
    yield put(project.project.init());
  } else if (error) {
    yield put(project.createProject.failure(error));
  }
}

function* createLink(id, name, url) {
  yield put(project.createLink.request());
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.createLink, token, id, name, url);
  if (response) {
    yield put(project.createLink.success(response));
  } else if (error) {
    yield put(project.createLink.failure(errorDetails));
  }
}

function* channelsCreateOrUpdate(request) {
  const { channels, projectId } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(
    api.channelCreateOrUpdate,
    token,
    projectId,
    channels
  );

  if (response) {
    yield put(project.channelCreateOrUpdate.success(projectId, response));
  } else {
    yield put(project.channelCreateOrUpdate.failure(projectId, errorDetails));
  }
}

function* channelDelete(request) {
  const { channelId, projectId, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(api.channelDelete, token, projectId, channelId);

  if (response) {
    yield put(project.channelDelete.success(projectId, channelId, response));
    resolve();
  } else {
    yield put(project.channelDelete.failure(projectId, channelId, errorDetails));
    reject(errorDetails);
  }
}

function* getBudgetHistory(request) {
  const { projectId, url } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.getBudgetHistory, token, projectId, url);
  if (response) {
    yield put(log.getBudgetHistory.success(projectId, response));
  } else if (error) {
    yield put(log.getBudgetHistory.failure(projectId, errorDetails));
  }
}

function* getSuggestedTimes(request) {
  const { guests, defaultMeetings, resolve, reject } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.getSuggestedTimes, token, {
    guests,
    defaultMeetings,
  });
  if (response) {
    yield put(project.getSuggestedTimes.success(response));
    resolve(response);
  } else if (error) {
    yield put(project.getSuggestedTimes.failure(errorDetails));
    reject(errorDetails);
  }
}

function* readBudgetHistory(request) {
  const { id, token } = request;
  const { response, error } = yield call(api.readBudgetHistory, token, id);

  if (response) {
    yield put(log.readBudgetHistory.success(id, response.count));
  } else {
    yield put(log.readBudgetHistory.failure(id, error));
  }
}

function* invitationAdd(request) {
  const { projectId, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.invitationAdd, token, projectId);
  if (response) {
    yield put(project.invitationAdd.success(response));
    resolve();
  } else if (error) {
    yield put(project.invitationAdd.failure(errorDetails));
    reject(errorDetails);
  }
}

function* invitationsUpdate(request) {
  const { invitations, projectId, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(
    api.invitationUpdate,
    token,
    projectId,
    invitations
  );

  if (response) {
    yield put(project.projectInvitationsUpdate.success(projectId, response));
    resolve();
  } else {
    yield put(project.projectInvitationsUpdate.failure(projectId, errorDetails));
    reject(errorDetails);
  }
}

function* linksCreateOrUpdate(request) {
  const { links, projectId, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(api.linkCreateOrUpdate, token, projectId, links);

  if (response) {
    yield put(project.linkCreateOrUpdate.success(projectId, response));
    resolve();
  } else {
    yield put(project.linkCreateOrUpdate.failure(projectId, errorDetails));
    reject(errorDetails);
  }
}

function* linkDelete(request) {
  const { linkId, projectId, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(api.linkDelete, token, projectId, linkId);

  if (response) {
    yield put(project.linkDelete.success(projectId, linkId, response));
    resolve();
  } else {
    yield put(project.linkDelete.failure(projectId, linkId, errorDetails));
    reject(errorDetails);
  }
}

function* membershipDelete(request) {
  const { membershipId, projectId, userSlug, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(
    api.membershipDelete,
    token,
    projectId,
    membershipId
  );

  if (response) {
    yield put(project.projectMembershipDelete.success(projectId, membershipId, userSlug, response));
    resolve();
  } else {
    yield put(project.projectMembershipDelete.failure(projectId, membershipId, errorDetails));
    reject(errorDetails);
  }
}

function* membershipUpdate(request) {
  const { memberships, projectId, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(
    api.membershipUpdate,
    token,
    projectId,
    memberships
  );

  if (response) {
    yield put(project.projectMembershipUpdate.success(projectId, response));
    resolve();
  } else {
    yield put(project.projectMembershipUpdate.failure(projectId, errorDetails));
    reject(errorDetails);
  }
}

function* projectBudgetUpdate(request) {
  const { budget, projectId, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(api.projectBudgetUpdate, token, projectId, budget);

  if (response) {
    yield put(project.projectBudgetUpdate.success(projectId, response));
    resolve();
  } else {
    yield put(project.projectBudgetUpdate.failure(projectId, errorDetails));
    reject(errorDetails);
  }
}

export function* projectDelete(request) {
  const { id } = request;
  const token = yield tokenService.getAuthToken();
  const { response, errorDetails } = yield call(api.projectDelete, token, id);

  if (response) {
    yield put(project.projectDelete.success(response));
    yield put(navigate("/chat"));
  } else {
    yield put(project.projectDelete.failure(errorDetails));
  }
}

function* projectUpdate(request) {
  const { data, projectId, reject, resolve } = request;
  const token = yield tokenService.getAuthToken();
  const { errorDetails, response } = yield call(api.projectUpdate, token, projectId, data);
  if (response) {
    yield put(project.projectUpdate.success(projectId, response));
    resolve();
  } else {
    yield put(project.projectUpdate.failure(projectId, errorDetails));
    reject(errorDetails);
  }
}

export function* watchChannelsCreateOrUpdate() {
  while (true) {
    const request = yield take(project.CHANNEL_CREATE_OR_UPDATE.REQUEST);
    yield fork(channelsCreateOrUpdate, request);
  }
}

export function* watchChannelDelete() {
  while (true) {
    const request = yield take(project.CHANNEL_DELETE.REQUEST);
    yield fork(channelDelete, request);
  }
}

export function* watchCreateProject() {
  while (true) {
    const { id, name, clientSlug, roomId } = yield take(project.CREATE_PROJECT.INIT);
    yield createProject(id, name, clientSlug, roomId);
  }
}

export function* watchCreateLink() {
  while (true) {
    const { id, name, url } = yield take(project.CREATE_LINK.INIT);
    yield createLink(id, name, url);
  }
}

export function* watchGetSuggestedTimes() {
  while (true) {
    const request = yield take(project.GET_SUGGESTED_TIMES.REQUEST);
    yield fork(getSuggestedTimes, request);
  }
}

export function* watchGetBudgetHistory() {
  while (true) {
    const request = yield take(log.GET_BUDGET_HISTORY.REQUEST);
    yield fork(getBudgetHistory, request);
  }
}

export function* watchReadBudgetHistory() {
  while (true) {
    const request = yield take(log.READ_BUDGET_HISTORY.REQUEST);
    yield fork(readBudgetHistory, request);
  }
}

export function* watchInvitationAdd() {
  while (true) {
    const request = yield take(project.INVITATION_ADD.REQUEST);
    yield fork(invitationAdd, request);
  }
}

export function* watchInvitationsUpdate() {
  while (true) {
    const request = yield take(project.PROJECT_INVITATIONS_UPDATE.REQUEST);
    yield fork(invitationsUpdate, request);
  }
}

export function* watchLinksCreateOrUpdate() {
  while (true) {
    const request = yield take(project.LINKS_CREATE_OR_UPDATE.REQUEST);
    yield fork(linksCreateOrUpdate, request);
  }
}

export function* watchLinkDelete() {
  while (true) {
    const request = yield take(project.LINKS_DELETE.REQUEST);
    yield fork(linkDelete, request);
  }
}

export function* watchMembershipDelete() {
  while (true) {
    const request = yield take(project.PROJECT_MEMBERSHIPS_DELETE.REQUEST);
    yield fork(membershipDelete, request);
  }
}

export function* watchMembershipUpdate() {
  while (true) {
    const request = yield take(project.PROJECT_MEMBERSHIPS_UPDATE.REQUEST);
    yield fork(membershipUpdate, request);
  }
}

export function* watchProjectBudgetUpdate() {
  while (true) {
    const request = yield take(project.PROJECT_BUDGET_UPDATE.REQUEST);
    yield fork(projectBudgetUpdate, request);
  }
}

export function* watchProjectUpdate() {
  while (true) {
    const request = yield take(project.PROJECT_UPDATE.REQUEST);
    yield fork(projectUpdate, request);
  }
}
