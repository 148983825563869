import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "semantic-ui-react";

import { KeyCodes } from "utils/constants";

import styles from "./styles.module.scss";

class EditMemberModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayName: props.user.displayName,
    };
  }

  onChange = (event) => {
    this.setState({ displayName: event.target.value });
  };

  onKeyDown = (e) => {
    const { displayName } = this.state;
    const { onSave, user } = this.props;

    switch (e.key) {
      case KeyCodes.ENTER[0]: {
        onSave(user.email, displayName);
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { onClose, onSave, open, user } = this.props;
    const { displayName } = this.state;

    return (
      <Modal className={styles.editMemberModal} onClose={onClose} open={open} size="mini">
        <Modal.Content className={styles.modalContent}>
          <p className={styles.label}>Name</p>
          <Form.Input
            required
            fluid
            name="name"
            placeholder="Enter name"
            onChange={this.onChange}
            value={displayName}
            className={styles.input}
            onKeyDown={this.onKeyDown}
          />
          <div className={styles.formActions}>
            <Button basic content="Cancel" onClick={onClose} />
            <Button
              primary
              content="Save changes"
              onClick={() => onSave(user.email, displayName)}
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

EditMemberModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
};

EditMemberModal.defaultProps = {
  open: false,
};

export default EditMemberModal;
