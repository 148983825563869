import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "containers/Profile/styles.module.scss";

class WarningOnCloseModal extends React.PureComponent {
  render() {
    const { onClose, open, onConfirm } = this.props;
    return (
      <Modal onClose={onClose} open={open} className={styles.warningOnCloseModal} closeIcon>
        <Modal.Content className={styles.modalContent}>
          <p className={styles.content}>This will discard all the changes you&apos;ve made.</p>
          <p className={styles.content}>Would you like to proceed?</p>
          <div className={styles.actionsContainer}>
            <Button
              className={classnames(styles.action, styles.confirm)}
              floated="right"
              onClick={onConfirm}
            >
              Yes, discard changes.
            </Button>
            <Button
              className={classnames(styles.action, styles.cancel)}
              floated="right"
              onClick={onClose}
            >
              No, go back.
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

WarningOnCloseModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

WarningOnCloseModal.defaultProps = {
  open: false,
};

export default WarningOnCloseModal;
