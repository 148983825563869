import MobileDetect from "mobile-detect";

const md = new MobileDetect(window.navigator.userAgent);

export const isMobile = () => !!md.mobile();

export const isTabletWidth = () => window.innerWidth < 992;

export const isPopup = () => window.opener && window.opener !== window && !!window.name;

// Browser-detection functions
// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || !!document.documentMode;
