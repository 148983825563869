import classnames from "classnames";
import React, { Component, lazy } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, matchPath, withRouter } from "react-router-dom";
import { Sidebar, Responsive } from "semantic-ui-react";
import { connect } from "react-redux";

import { sidebar } from "actions/sidebar";
import config from "config";
import * as responsive from "utils/responsive";
import {
  requireAuthenticationSubscription as reqAuthSub,
  requireUnauthenticated as reqUnauth,
} from "utils";

import ConfirmEmail from "containers/ConfirmEmail";
import DirectMessage from "containers/DirectMessage";
import Favicon from "containers/Favicon";
import GlobalSidebar from "containers/Sidebar";
import Hamburger from "containers/Sidebar/Hamburger";
// import NavMenu from "containers/NavMenu";
import NewProject from "containers/NewProject";
import NewTeam from "containers/NewTeam";
import Notification from "containers/Notification";
import PasswordReset from "containers/PasswordReset";
import PasswordResetDone from "containers/PasswordReset/Done";
import PasswordResetConfirm from "containers/PasswordReset/Confirm";
import PasswordResetConfirmDone from "containers/PasswordReset/ConfirmDone";
import Reminders from "components/Reminders";
import SignUp from "containers/SignUp";
import Login from "containers/Login";
import LoginModal from "containers/Login/components/LoginModal";
import Subscription from "containers/Subscription";
import TeamInvitation from "containers/TeamInvitation";
import Topbar from "containers/Topbar";
import Unsubscribe from "containers/Unsubscribe";
import Workspace from "containers/Workspace";
import ReminderWorkLogModal from "containers/Profile/components/ReminderWorkLogModal";

const TABLET_AND_BELOW = { maxWidth: 991 };

const About = lazy(() => import("containers/About"));
const AccountSettings = lazy(() => import("containers/Profile/User/Settings"));
const HomeSearch = lazy(() => import("containers/HomeSearch"));
const House = lazy(() => import("containers/House"));
const Legal = lazy(() => import("containers/Legal"));
const Market = lazy(() => import("containers/Market"));
const NotFound = lazy(() => import("containers/NotFound"));
const ExpiredLink = lazy(() => import("containers/ExpiredLink"));
const Profile = lazy(() => import("containers/Profile/User"));
const ProfileCalendarEvent = lazy(() =>
  import("containers/Profile/User/Update/ProfileCalendarEvent")
);
const ProfileSkillsAdd = lazy(() => import("containers/Profile/User/Update/ProfileSkillsAdd"));
const ProfileSkillsUpdate = lazy(() =>
  import("containers/Profile/User/Update/ProfileSkillsUpdate")
);
const ProfileUpdate = lazy(() => import("containers/Profile/User/Update"));
const RequirementsSearch = lazy(() => import("containers/RequirementsSearch"));
const TeamMembersUpdate = lazy(() => import("containers/Profile/Team/TeamMembersUpdate"));
const Schedule = lazy(() => import("containers/Schedule"));
const TeamProfile = lazy(() => import("containers/Profile/Team/TeamProfile"));
const TeamProfileUpdate = lazy(() => import("containers/Profile/Team/TeamProfileUpdate"));
const TeamSkillsAdd = lazy(() => import("containers/Profile/Team/TeamSkillsAdd"));
const TeamSkillsUpdate = lazy(() => import("containers/Profile/Team/TeamSkillsUpdate"));
const TimeTracker = lazy(() => import("containers/TimeTracker"));

const SubscriptionAuth = reqAuthSub(Subscription);
const NotificationAuth = reqAuthSub(Notification);
const SettingsAuth = reqAuthSub(AccountSettings);
const ProfileUpdateAuth = reqAuthSub(ProfileUpdate);
const TeamProfileUpdateAuth = config.flags.profile.teams && reqAuthSub(TeamProfileUpdate);
const ProfileCalendarEventAuth =
  config.flags.profile.availability && reqAuthSub(ProfileCalendarEvent);
const ProfileSkillsAddAuth = config.flags.profile.skills && reqAuthSub(ProfileSkillsAdd);
const ProfileSkillsUpdateAuth = config.flags.profile.skills && reqAuthSub(ProfileSkillsUpdate);
const TeamSkillsAddAuth = config.flags.profile.teams && reqAuthSub(TeamSkillsAdd);
const TeamSkillsUpdateAuth = config.flags.profile.teams && reqAuthSub(TeamSkillsUpdate);
const TeamMembersUpdateAuth = config.flags.profile.teams && reqAuthSub(TeamMembersUpdate);
const TimeTrackerAuth = config.flags.timeTracker && reqAuthSub(TimeTracker);
const ScheduleAuth = config.flags.schedule && reqAuthSub(Schedule);
const HouseAuth = config.flags.house && reqAuthSub(House);
const PasswordResetUnauth = reqUnauth(PasswordReset);
const PasswordResetDoneUnauth = reqUnauth(PasswordResetDone);
const PasswordResetConfirmUnauth = reqUnauth(PasswordResetConfirm);
const PasswordResetConfirmDoneUnauth = reqUnauth(PasswordResetConfirmDone);
const { CronJob } = require("cron");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openReminder: false,
    };

    this.cronJob = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => this.props.sidebarClose(), 3000);
  }

  componentDidUpdate() {
    if (!this.cronJob.current && this.props.currentUser?.timezoneDisplay) {
      this.cronJob.current = new CronJob(
        "0 18 * * 1,3,5",
        async () => {
          this.setState({
            openReminder: true,
          });
        },
        null,
        true,
        this.props.currentUser.timezoneDisplay
      );
      this.cronJob.current.start();
    }
  }

  componentWillUnmount() {
    if (this.cronJob.current) {
      this.cronJob.current.stop();
    }
  }

  handleSidebarOpen = () => {
    const { sidebarVisible } = this.props;
    if (!sidebarVisible) {
      this.props.sidebarOpen();
    }
  };

  handleSidebarClose = () => {
    const { sidebarVisible } = this.props;
    if (sidebarVisible) {
      this.props.sidebarClose();
    }
  };

  render() {
    const isPopup = responsive.isPopup();
    const isSubscribed =
      !config.stripeApiKey || (this.props.currentUser && this.props.currentUser.isSubscribed);
    const isChat = matchPath(this.props.location.pathname, { path: "/chat" });
    const canViewWeeklyAssignments =
      this.props.currentUser && this.props.currentUser.weeklyAssignmentsAccessType;

    return (
      <>
        {!isPopup && this.props.currentUser && (
          <Responsive as={Route} {...Responsive.onlyComputer}>
            <Topbar />
          </Responsive>
        )}
        {!isPopup && this.props.currentUser && isSubscribed && (
          <ReminderWorkLogModal
            open={this.state.openReminder}
            onClose={() => this.setState({ openReminder: false })}
          />
        )}
        <LoginModal open={!isPopup && this.props.isLoginModalOpen} />
        <Sidebar.Pushable>
          <Reminders />
          <Favicon />
          {!isPopup && this.props.currentUser && <NewProject />}
          {!isPopup && this.props.currentUser && <NewTeam />}
          {!isPopup && this.props.currentUser && <DirectMessage />}

          {!isPopup && (
            <Sidebar animation="overlay" visible={this.props.sidebarVisible}>
              {this.props.currentUser && isChat && (
                <Responsive as={Route} {...TABLET_AND_BELOW}>
                  <GlobalSidebar />
                </Responsive>
              )}
            </Sidebar>
          )}

          <Sidebar.Pusher
            className={classnames("global", this.props.currentUser && isSubscribed && "loggedin")}
            dimmed={this.props.sidebarVisible}
            onClick={this.handleSidebarClose}
          >
            {!isPopup && this.props.currentUser && isSubscribed && isChat && (
              <Responsive as={Route} {...Responsive.onlyComputer}>
                <GlobalSidebar />
              </Responsive>
            )}
            {!isPopup && this.props.currentUser && isSubscribed && (
              <Switch>
                <Route path="/chat" />
                <Responsive as={Route} {...TABLET_AND_BELOW}>
                  <Hamburger />
                </Responsive>
              </Switch>
            )}

            {/* {!isPopup && !(this.props.currentUser && isSubscribed) && <NavMenu />} */}

            <Switch>
              <Redirect exact from="/" to="/chat" />

              <Route path="/legal">
                <Legal />
              </Route>

              {config.flags.marketplace && (
                <Route exact path="/person-search">
                  <HomeSearch />
                </Route>
              )}

              {config.flags.marketplace && (
                <Route exact path="/requirements-search">
                  <RequirementsSearch />
                </Route>
              )}

              <Route path="/chat">
                <Workspace />
              </Route>

              <Route exact path="/about">
                <About />
              </Route>

              {config.flags.house && (
                <Route path="/house">
                  <HouseAuth />
                </Route>
              )}

              <Route exact path="/confirm-email/:key/">
                <ConfirmEmail />
              </Route>

              <Route exact path="/password/reset">
                <PasswordResetUnauth />
              </Route>

              <Route exact path="/password/reset/done">
                <PasswordResetDoneUnauth />
              </Route>

              <Route exact path="/password/reset/:uid-:token">
                <PasswordResetConfirmUnauth />
              </Route>

              <Route exact path="/password/reset/complete">
                <PasswordResetConfirmDoneUnauth />
              </Route>

              <Route exact path="/unsubscribe/:key/:token">
                <Unsubscribe />
              </Route>

              <Route exact path="/team-invitation/:key/:decision(accept|decline)/">
                <TeamInvitation />
              </Route>

              <Route path="/login">
                <Login />
              </Route>

              <Route path="/signup">
                <SignUp />
              </Route>

              <Route path="/expired-link">
                <ExpiredLink />
              </Route>

              <Route path="/subscription">
                <SubscriptionAuth />
              </Route>

              {config.flags.marketplace && (
                <Route exact path="/talents">
                  <Market />
                </Route>
              )}

              {config.flags.profile.availability ? (
                <Redirect exact from="/talents/:username" to="/talents/:username/calendar" />
              ) : (
                <Redirect exact from="/talents/:username" to="/talents/:username/bio" />
              )}

              {config.flags.profile.skills && (
                <Route path="/skills/add/">
                  <ProfileSkillsAddAuth />
                </Route>
              )}

              <Route exact path="/talents/:username/update">
                <ProfileUpdateAuth />
              </Route>

              {config.flags.profile.availability && (
                <Route exact path="/talents/:username/calendar/events/new">
                  <ProfileCalendarEventAuth />
                </Route>
              )}

              {config.flags.profile.availability && (
                <Route exact path="/talents/:username/calendar/events/:eventId/edit">
                  <ProfileCalendarEventAuth />
                </Route>
              )}

              {config.flags.profile.skills && (
                <Route exact path="/talents/:username/skills/update">
                  <ProfileSkillsUpdateAuth />
                </Route>
              )}

              <Route path="/talents/:username">
                <Profile />
              </Route>

              {config.flags.notifications && (
                <Route path="/notifications/">
                  <NotificationAuth />
                </Route>
              )}

              <Route exact path="/settings/unsubscribe/:key">
                <SettingsAuth />
              </Route>

              <Route path="/settings/">
                <SettingsAuth />
              </Route>

              {config.flags.profile.teams && (
                <Redirect exact from="/teams/:slug" to="/teams/:slug/basic-info" />
              )}

              {config.flags.profile.teams && (
                <Route exact path="/teams/:slug/update">
                  <TeamProfileUpdateAuth />
                </Route>
              )}

              {config.flags.profile.teams && (
                <Route exact path="/teams/:slug/skills/add">
                  <TeamSkillsAddAuth />
                </Route>
              )}

              {config.flags.profile.teams && (
                <Route exact path="/teams/:slug/skills/update">
                  <TeamSkillsUpdateAuth />
                </Route>
              )}

              {config.flags.profile.teams && (
                <Route exact path="/teams/:slug/members/update">
                  <TeamMembersUpdateAuth />
                </Route>
              )}

              {config.flags.profile.teams && (
                <Route path="/teams/:slug">
                  <TeamProfile />
                </Route>
              )}

              <Route path="/timetracker/">
                <TimeTrackerAuth />
              </Route>

              {config.flags.schedule && canViewWeeklyAssignments && (
                <Route path="/schedule/">
                  <ScheduleAuth />
                </Route>
              )}

              <Route>{this.props.location.pathname === "/" ? <div /> : <NotFound />}</Route>
            </Switch>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </>
    );
  }
}

App.propTypes = {
  currentUser: PropTypes.shape(),
  sidebarOpen: PropTypes.func.isRequired,
  sidebarClose: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.bool.isRequired,
  location: PropTypes.shape(),
  isLoginModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { initialLoad, user: currentUser, isLoginModalOpen },
    sidebar: { open: sidebarVisible },
  } = state;

  return {
    initialLoad,
    sidebarVisible,
    currentUser,
    isLoginModalOpen,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    sidebarOpen: sidebar.open,
    sidebarClose: sidebar.close,
  })(App)
);
