import classnames from "classnames";
import { isEqual, range } from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { Button, Icon } from "semantic-ui-react";

import styles from "./styles.module.scss";

const DAYLIMIT = 13;

const DayHeader = React.forwardRef(({ active, date, index, onNavigate }, ref) => {
  const displayDate = moment(date);
  const displayDateDay = displayDate.format("ddd");
  const displayDateFormat = displayDate.format("M/D");
  const itemClasses = [styles.dayHeaderDisplay];
  if (active) {
    itemClasses.push(styles.active);
  }
  return (
    <div
      className={classnames(...itemClasses)}
      onClick={() => onNavigate(index)}
      ref={ref}
      role="presentation"
    >
      <div className={styles.dayHeaderDay}>{displayDateDay}</div>
      <span className={styles.dayHeaderDate}>{displayDateFormat}</span>
    </div>
  );
});

DayHeader.propTypes = {
  active: PropTypes.bool.isRequired,
  date: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default class TimezoneViewerHeaderDays extends React.PureComponent {
  constructor(props) {
    super(props);

    this.daysRef = {};
    this.state = {
      activeIndex: 0,
      days: this.getDays(props.currentDate),
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.currentDate, this.props.currentDate)) {
      const { currentDate } = this.props;
      const firstDayInRange = this.state.days[0].clone().startOf("day");
      const lastDayInRange = firstDayInRange.clone().add(DAYLIMIT, "days");
      const inCurrentRange = currentDate.isBetween(firstDayInRange, lastDayInRange, "day", "[)");
      // If current date is in range, set index accordingly, otherwise, set index to 0 and update days
      if (inCurrentRange) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          activeIndex: currentDate.diff(firstDayInRange, "days"),
        });
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          activeIndex: 0,
          days: this.getDays(currentDate),
        });
      }
    }
  }

  getDays = (currentDate) => range(0, DAYLIMIT).map((ind) => moment(currentDate).add(ind, "days"));

  getDayHeaders = () => {
    const { activeIndex, days } = this.state;
    const dayHeaders = days.map((date, index) => (
      <DayHeader
        active={activeIndex === index}
        date={date}
        key={index}
        index={index}
        onNavigate={this.handleGoToDate}
        ref={(el) => {
          this.daysRef[index] = el;
        }}
      />
    ));
    return dayHeaders;
  };

  handleGoToDate = (index) => {
    const { days } = this.state;
    const date = days[index];
    this.setState({ activeIndex: index });
    this.props.onNavigate(date);
    const el = this.daysRef[index];
    if (el) el.scrollIntoView({ block: "nearest", inline: "end" });
  };

  handleGoToNext = () => {
    const { onNavigate } = this.props;
    const { activeIndex, days } = this.state;
    const nextDate = moment(days[activeIndex]).add(1, "days");
    let newIndex = activeIndex + 1;
    if (activeIndex + 1 < DAYLIMIT) {
      this.setState({ activeIndex: activeIndex + 1 });
    } else {
      newIndex = 0;
      this.setState({
        activeIndex: 0,
        days: this.getDays(nextDate),
      });
    }
    onNavigate(nextDate);
    const el = this.daysRef[newIndex];
    if (el) el.scrollIntoView({ block: "nearest", inline: "end" });
  };

  handleGoToPrev = () => {
    const { onNavigate } = this.props;
    const { activeIndex, days } = this.state;
    const nextDate = moment(days[activeIndex]).subtract(1, "days");
    let newIndex = activeIndex - 1;
    if (activeIndex - 1 >= 0) {
      this.setState({ activeIndex: activeIndex - 1 });
    } else {
      newIndex = DAYLIMIT - 1;
      const newStart = moment(nextDate).subtract(DAYLIMIT - 1, "days");
      this.setState({
        activeIndex: DAYLIMIT - 1,
        days: this.getDays(newStart),
      });
    }
    onNavigate(nextDate);
    const el = this.daysRef[newIndex];
    if (el) el.scrollIntoView({ block: "nearest", inline: "end" });
  };

  render() {
    return [
      <Button
        basic
        className={styles.noBorder}
        key="prev"
        icon
        onClick={this.handleGoToPrev}
        type="button"
      >
        <Icon name="chevron left" />
      </Button>,
      <div className={styles.dayHeaderContainer} key="dayHeaders">
        {this.getDayHeaders()}
      </div>,
      <Button
        basic
        className={styles.noBorder}
        key="next"
        icon
        onClick={this.handleGoToNext}
        type="button"
      >
        <Icon name="chevron right" />
      </Button>,
    ];
  }
}

TimezoneViewerHeaderDays.propTypes = {
  currentDate: PropTypes.shape().isRequired,
  onNavigate: PropTypes.func.isRequired,
};
