/* eslint-disable consistent-return */

const dateRegex = /^[0-9]{4}-[0-3][0-9]-[0-3][0-9]$/;
const dateTimeRegex = /^[0-9]{4}-[0-3][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9]\+[0-9]{4}$/;

export function dateString(props, propName, componentName) {
  if (props[propName] && !dateRegex.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected string with format YYYY-MM-DD`
    );
  }
}

export function dateStringRequired(props, propName, componentName) {
  if (!dateRegex.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected string with format YYYY-MM-DD`
    );
  }
}

// 2018-07-04T12:08:31+0000

export function dateTimeString(props, propName, componentName) {
  if (props[propName] && !dateTimeRegex.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected string with format YYYY-MM-DDThh:mm:ss+ZZZZ`
    );
  }
}

export function dateTimeStringRequired(props, propName, componentName) {
  if (!dateTimeRegex.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected string with format YYYY-MM-DDThh:mm:ss+ZZZZ`
    );
  }
}
