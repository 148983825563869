import React from "react";
import { Button, Icon } from "semantic-ui-react";
import config from "config";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/linkedin_oauth2/login/`;

const LinkedInButton = (props) => (
  <Button color="linkedin" fluid onClick={() => (window.location.href = LOGIN_URL)} {...props}>
    <Icon name="linkedin" />
    LinkedIn
  </Button>
);

export default LinkedInButton;
