export const ONLINE = "ONLINE";

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const REMOVAL = "REMOVAL";
const INIT = "INIT";

export const UPDATE_ROUTER_STATE = "UPDATE_ROUTER_STATE";
export const NAVIGATE = "NAVIGATE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";

export function createRequestTypes(base, csv = false) {
  const res = {};
  [INIT, REQUEST, SUCCESS, FAILURE, REMOVAL].forEach((type) => (res[type] = `${base}_${type}`));
  return res;
}

export function action(type, payload = {}) {
  return { type, ...payload };
}

export const updateRouterState = (state) => action(UPDATE_ROUTER_STATE, { state });
export const navigate = (pathname, replace = false) => action(NAVIGATE, { pathname, replace });
export const setLanguage = (code) => action(SET_LANGUAGE, { code });
export const resetErrorMessage = () => action(RESET_ERROR_MESSAGE);
