import EmailValidator from "email-validator";
import { isString, orderBy } from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button, Dropdown, Form, Input, Header } from "semantic-ui-react";

import config from "config";
import FuzzySearch from "components/FuzzySearch";
import UserAvatar from "components/UserAvatar";
import UserPill from "components/UserPill";
import Contact from "components/Contact";
import Member from "components/Member";

import * as constants from "./constants";
import * as styles from "./styles.module.scss";

export default class People extends React.Component {
  constructor(props) {
    super(props);
    this.projectInput = React.createRef();
    this.state = {
      sortBy: constants.ALPHABETICAL,
    };
  }

  componentDidMount() {
    this.projectInput.current.focus();
  }

  matchRenderer = (match, onClick, selected) => (
    <div
      className={classnames(styles.sectionMemberMatch, selected && styles.selected)}
      key={match.item.slug}
      onClick={() => onClick(match.item)}
      onKeyPress={() => onClick(match.item)}
      role="menuitem"
      tabIndex="-1"
      title={`${match.item.displayName}${match.item.email ? ` (${match.item.email})` : ""}`}
    >
      <UserAvatar
        avatar={match.item.avatar}
        bgColor={match.item.avatarBgcolor}
        className={styles.sectionMemberMatchAvatar}
        displayName={match.item.displayName}
        fgColor={match.item.avatarColor}
        size="16"
      />
      <span>
        {match.item.displayName}
        {match.item.email ? ` (${match.item.email})` : ""}
      </span>
    </div>
  );

  valuesRenderer = (values, onClick) =>
    values.map((value, index) => {
      const avatar = isString(value) ? null : value.avatarOrDefault;
      const text = isString(value) ? value : value.displayName;

      return (
        <UserPill
          key={index}
          avatar={avatar}
          bgColor={value.avatarBgcolor}
          fgColor={value.avatarColor}
          onClick={() => onClick(index)}
          text={text}
        />
      );
    });

  valuesValidator = (value) => EmailValidator.validate(value);

  sortPeople = (value, sortBy) => {
    switch (sortBy) {
      case constants.ALPHABETICAL:
        return orderBy(value, [(user) => user.displayName.toLowerCase()], "asc");
      case constants.ROLE:
        return orderBy(
          value,
          [(user) => (!user.role ? user.displayName.toLowerCase() : user.role)],
          "asc"
        );
      default:
        return [];
    }
  };

  onSortUsers = (e, { value }) => {
    this.setState({ sortBy: value });
  };

  render() {
    const {
      currentUser,
      currentUserEmail,
      members,
      onMemberChange,
      onProjectNameChange,
      onGenerateName,
      onNext,
      project,
      users,
    } = this.props;

    const { sortBy } = this.state;
    const memberSlugs = members.filter((member) => !isString(member)).map((member) => member.slug);
    const usersFiltered = users.filter((user) => !memberSlugs.includes(user.slug));

    return (
      <>
        <Form onSubmit={onNext}>
          <Header as="h4" content={`Name ${config.flags.profile.teams ? "Project" : "Team"}`} />
          <Form.Field>
            <Input
              ref={this.projectInput}
              fluid
              placeholder={`Enter ${config.flags.profile.teams ? "project" : "team"}`}
              name="project"
              value={project}
              onChange={onProjectNameChange}
              maxLength="200"
              action={{
                basic: true,
                onClick: onGenerateName,
                content: "Generate name",
                icon: "random",
                type: "button",
              }}
            />
          </Form.Field>
          <small className={styles.helper}>{project.length} / 200</small>

          <Header as="h4" content="Add members" />
          <p>Member List</p>
          <div className={styles.row}>
            <div className={styles.searchColumn}>
              <FuzzySearch
                allowAdditions
                keys={["displayName", "email"]}
                matchRenderer={this.matchRenderer}
                multiple
                onValuesChange={onMemberChange}
                options={usersFiltered}
                placeholder="Search a name or type an email address"
                values={members}
                valuesValidator={this.valuesValidator}
              />
            </div>
            <div className={styles.column}>
              <div className={styles.filterContainer}>
                <span className={styles.sortByText}>Sort By</span>
                <Dropdown
                  className={classnames(styles.viewDropdown, styles.viewDropdownFullWidth)}
                  defaultValue={constants.ALPHABETICAL}
                  options={constants.SORT_OPTIONS}
                  selection
                  onChange={this.onSortUsers}
                />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <Contact
                users={users}
                members={members}
                onValuesChange={onMemberChange}
                sortContact={this.sortPeople}
                sortBy={sortBy}
              />
            </div>
            <div className={styles.memberColumn}>
              <Member
                currentUser={currentUser}
                currentUserEmail={currentUserEmail}
                members={members}
                onValuesChange={onMemberChange}
                sortMember={this.sortPeople}
                sortBy={sortBy}
              />
            </div>
          </div>
          <div className={classnames(styles.prevNext, styles.peoplePage)}>
            <Button
              primary
              type="submit"
              floated="right"
              content="Continue"
              disabled={members.length === 1 || project.length === 0}
            />
          </div>
        </Form>
      </>
    );
  }
}

People.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  currentUserEmail: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])).isRequired,
  onMemberChange: PropTypes.func.isRequired,
  onProjectNameChange: PropTypes.func.isRequired,
  onGenerateName: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  project: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape()),
};

People.defaultProps = {
  onNext: () => {},
};
