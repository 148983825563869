import { createRequestTypes, action } from "./index";

export const SUBSCRIPTIONS = createRequestTypes("SUBSCRIPTIONS");
export const SUBSCRIBE = createRequestTypes("SUBSCRIBE");
export const RESUBSCRIBE = createRequestTypes("RESUBSCRIBE");
export const UNSUBSCRIBE = createRequestTypes("UNSUBSCRIBE");
export const CUSTOMER = createRequestTypes("CUSTOMER");
export const PLANS = createRequestTypes("PLANS");

export const subscriptions = {
  init: () => action(SUBSCRIPTIONS.INIT),
  request: () => action(SUBSCRIPTIONS.REQUEST),
  success: (response) => action(SUBSCRIPTIONS.SUCCESS, { response }),
  failure: (error) => action(SUBSCRIPTIONS.FAILURE, { error }),
};

export const subscribe = {
  init: (planId, cardToken, coupon) => action(SUBSCRIBE.INIT, { planId, cardToken, coupon }),
  request: () => action(SUBSCRIBE.REQUEST),
  success: (response) => action(SUBSCRIBE.SUCCESS, { response }),
  failure: (error) => action(SUBSCRIBE.FAILURE, { error }),
};

export const resubscribe = {
  init: (subscriptionId, cardToken, coupon) =>
    action(RESUBSCRIBE.INIT, { subscriptionId, cardToken, coupon }),
  request: () => action(RESUBSCRIBE.REQUEST),
  success: (response) => action(RESUBSCRIBE.SUCCESS, { response }),
  failure: (error) => action(RESUBSCRIBE.FAILURE, { error }),
};

export const unsubscribe = {
  init: (subscriptionId, undo) => action(UNSUBSCRIBE.INIT, { subscriptionId, undo }),
  request: () => action(UNSUBSCRIBE.REQUEST),
  success: (response) => action(UNSUBSCRIBE.SUCCESS, { response }),
  failure: (error) => action(UNSUBSCRIBE.FAILURE, { error }),
};

export const customer = {
  init: () => action(CUSTOMER.INIT),
  request: () => action(CUSTOMER.REQUEST),
  success: (response) => action(CUSTOMER.SUCCESS, { response }),
  failure: (error) => action(CUSTOMER.FAILURE, { error }),
};

export const plans = {
  init: () => action(PLANS.INIT),
  request: () => action(PLANS.REQUEST),
  success: (response) => action(PLANS.SUCCESS, { response }),
  failure: (error) => action(PLANS.FAILURE, { error }),
};
