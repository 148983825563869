import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { createRoom } from "actions/chat";
import Loading from "containers/Loading";
import { sentinelSlug } from "utils/constants";

class SlugRoom extends Component {
  static propTypes = {
    createRoom: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        teamSlug: PropTypes.string,
        userSlug: PropTypes.string,
      }).isRequired,
    }).isRequired,
    token: PropTypes.string,
  };

  componentDidMount() {
    const { token } = this.props;
    const { teamSlug, userSlug } = this.props.match.params;

    if (teamSlug) {
      this.props.createRoom({ token, teamSlug });
    } else if (userSlug && userSlug !== sentinelSlug) {
      this.props.createRoom({ token, userSlugs: [userSlug] });
    }
  }

  render() {
    return <Loading />;
  }
}

function mapStateToProps(state) {
  const {
    auth: { token },
  } = state;

  return {
    token,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createRoom: createRoom.request,
  })(SlugRoom)
);
