import { decamelizeKeys } from 'humps';
import * as schema from 'services/schema';
import { callApiWithToken } from './base';

export const fetchSkills = (token, minimal = false) => {
  const minimalQuery = minimal ? '1' : '0';

  return callApiWithToken(`skills/?minimal=${minimalQuery}`, token, schema.skillSchemaArray);
};

export const fetchHierarchy = (token) =>
  callApiWithToken('skills/hierarchy/', token, schema.hierarchySchemaArray);

export const createSkill = (token, _, data) =>
  callApiWithToken(
    'skills/',
    token,
    schema.skillSchema,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const createTalentSkill = (token, _, data) =>
  callApiWithToken(
    'talent_skills/',
    token,
    schema.talentSkillSchema,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const updateTalentSkill = (token, id, data) =>
  callApiWithToken(
    `talent_skills/${id}/`,
    token,
    schema.talentSkillSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
