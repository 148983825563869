import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "semantic-ui-react";

import styles from "./styles.module.scss";

class RecurrenceModal extends React.PureComponent {
  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const { onCancel, onConfirm, open, event, choice, onChange, source, loading } = this.props;
    const { isOutOfOffice, isWorkLog } = event;

    let heading = "Remove meeting from calendar";

    if (isWorkLog) {
      heading = "Remove task from calendar";
    } else if (isOutOfOffice) {
      heading = "Remove unavailable for work log";
    }

    if (source === "edit") heading = "Save edit changes";

    return (
      <Modal onClose={onCancel} open={open} className={styles.recurrenceModal} closeIcon>
        <Modal.Content className={styles.modalContent}>
          <p className={styles.heading}>{heading}</p>
          <Form.Group className={styles.recurrenceRadioGroup}>
            <Form.Radio
              className={styles.recurrenceRadio}
              label={`For this ${isOutOfOffice ? "log" : "meeting"} only`}
              value="thisOnly"
              name="recurrenceChoice"
              checked={choice === "thisOnly"}
              onChange={onChange}
            />
            <Form.Radio
              className={styles.recurrenceRadio}
              label={`For this ${isOutOfOffice ? "log" : "meeting"} and all future ${
                isOutOfOffice ? "log" : "meeting"
              }s`}
              value="future"
              name="recurrenceChoice"
              checked={choice === "future"}
              onChange={onChange}
            />
          </Form.Group>
          <div className={styles.actionsContainer}>
            <Button
              className={classnames(styles.action, { [styles.delete]: source === "delete" })}
              floated="right"
              onClick={() => onConfirm(event.id)}
              primary={source === "edit"}
              disabled={loading}
              loading={loading}
            >
              Select option
            </Button>
            <Button
              className={classnames(styles.action, styles.cancel)}
              floated="right"
              onClick={onCancel}
              disabled={loading}
              loading={loading}
            >
              Cancel
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

RecurrenceModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  event: PropTypes.shape().isRequired,
  choice: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

RecurrenceModal.defaultProps = {
  open: false,
  loading: false,
};

export default RecurrenceModal;
