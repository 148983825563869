import {
  GET_WORK_LOGS,
  CREATE_WORK_LOG,
  UPDATE_WORK_LOG,
  DELETE_WORK_LOG,
  RESET_WORK_LOGS,
  EXPORT_WORK_LOGS,
} from "actions/timetracker";

const initialState = {
  initialLoad: false,
  loading: false,
  error: false,
  errorDetails: null,
  success: false,
  count: 0,
  totalHours: 0,

  modalLoading: false,
  modalError: false,
  modalErrorDetails: null,
  modalSuccess: false,

  exportLoading: false,
  exportError: false,
  exportErrorDetails: null,
  exportSuccess: false,
};

export default function workLog(state = initialState, action) {
  switch (action.type) {
    case RESET_WORK_LOGS:
    case GET_WORK_LOGS.REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        errorDetails: null,
      };
    case GET_WORK_LOGS.SUCCESS:
      return {
        ...state,
        initialLoad: true,
        loading: false,
        success: true,
        count: action.response.count,
        totalHours: action.response.totalHours,
        error: false,
        errorDetails: null,
      };
    case GET_WORK_LOGS.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errorDetails: action.errorDetails,
      };
    case CREATE_WORK_LOG.REQUEST:
    case UPDATE_WORK_LOG.REQUEST:
    case DELETE_WORK_LOG.REQUEST:
      return {
        ...state,
        modalLoading: true,
        modalSuccess: false,
        modalError: false,
        modalErrorDetails: null,
      };
    case CREATE_WORK_LOG.SUCCESS:
    case UPDATE_WORK_LOG.SUCCESS:
    case DELETE_WORK_LOG.SUCCESS:
      return {
        ...state,
        modalLoading: false,
        modalSuccess: true,
        modalError: false,
        modalErrorDetails: null,
      };
    case CREATE_WORK_LOG.FAILURE:
    case UPDATE_WORK_LOG.FAILURE:
    case DELETE_WORK_LOG.FAILURE:
      return {
        ...state,
        modalLoading: false,
        modalSuccess: false,
        modalError: true,
        modalErrorDetails: action.errorDetails,
      };
    case EXPORT_WORK_LOGS.REQUEST:
      return {
        ...state,
        exportLoading: true,
        exportSuccess: false,
        exportError: false,
        exportErrorDetails: null,
      };
    case EXPORT_WORK_LOGS.SUCCESS:
      return {
        ...state,
        exportLoading: false,
        exportSuccess: true,
        exportError: false,
        exportErrorDetails: null,
      };
    case EXPORT_WORK_LOGS.FAILURE:
      return {
        ...state,
        exportLoading: false,
        exportSuccess: false,
        exportError: true,
        exportErrorDetails: action.errorDetails,
      };
    default:
      return state;
  }
}
