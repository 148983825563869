import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { NavLink as Link } from "react-router-dom";

import UserAvatar from "components/UserAvatar";

import styles from "./styles.module.scss";

const User = ({ actionRenderer = null, className, isAdmin, user }) => (
  <div className={classnames(styles.user, className)}>
    <Link to={`/talents/${user.slug}`} target="_blank" title="View profile">
      <UserAvatar
        avatar={user.avatarOrDefault}
        bgColor={user.avatarBgcolor}
        displayName={user.displayName}
        fgColor={user.avatarColor}
        size={25}
        star={isAdmin}
      />
    </Link>

    <div className={styles.userName}>{user.displayName}</div>

    {actionRenderer && actionRenderer(user)}
  </div>
);

User.propTypes = {
  actionRenderer: PropTypes.func,
  className: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  user: PropTypes.shape().isRequired,
};

export default User;
