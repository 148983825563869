import React from "react";
import { Button } from "semantic-ui-react";
import config from "config";
import styles from "./styles.module.scss";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/twitter/login/`;

const TwitterButton = (props) => (
  <Button
    className={styles.button}
    onClick={() => (window.location.href = LOGIN_URL)}
    title="Twitter"
    {...props}
  >
    <img src="/twitter-icon.svg" alt="twitter" />
  </Button>
);

export default TwitterButton;
