import { api, token as tokenService } from "services";
import { call, takeLatest } from "redux-saga/effects";
import * as skill from "actions/skill";
import { fetchEntity, pushEntity } from "./base";

export const fetchSkills = fetchEntity.bind(null, skill.skill, api.fetchSkills);
export const fetchHierarchy = fetchEntity.bind(null, skill.hierarchy, api.fetchHierarchy);
export const pushSkill = pushEntity.bind(null, skill.skill, api.createSkill);
export const pushNewTalentSkill = pushEntity.bind(null, skill.talentSkill, api.createTalentSkill);
export const pushTalentSkill = pushEntity.bind(null, skill.talentSkill, api.updateTalentSkill);

export function* loadSkills(request) {
  const { minimal } = request;
  const token = yield tokenService.getAuthToken();
  yield call(fetchSkills, true, token, minimal);
}

export function* loadHierarchy() {
  const token = yield tokenService.getAuthToken();
  yield call(fetchHierarchy, true, token, null);
}

export function* createSkill({ data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushSkill, true, token, null, null, data);
}

export function* createTalentSkill({ data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushNewTalentSkill, true, token, null, null, data);
}

export function* updateTalentSkill({ id, data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushTalentSkill, true, token, id, null, data);
}

export function* watchSkills() {
  yield takeLatest(skill.SKILL.INIT, loadSkills);
  yield takeLatest(skill.HIERARCHY.INIT, loadHierarchy);
  yield takeLatest(skill.CREATE_SKILL, createSkill);
  yield takeLatest(skill.CREATE_TALENT_SKILL, createTalentSkill);
  yield takeLatest(skill.UPDATE_TALENT_SKILL, updateTalentSkill);
}
