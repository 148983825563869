import { AUTH_LOGOUT, AUTH_LOGIN_INIT } from "actions/account";
import {
  ADD_MEMBERS,
  CREATE_ROOM,
  DELETE_MESSAGE,
  DISCONNECT_CALL,
  EDIT_MESSAGE,
  GET_ATTACHMENTS,
  GET_MESSAGES,
  GET_PINNED_MESSAGES,
  GET_ROOM,
  GET_ROOMS,
  GET_SEARCH,
  GET_THREAD,
  INVITATION_DELETE,
  INVITATION_RESEND,
  LAST_ROOM_KEY,
  MESSAGE_BOX_CACHE,
  NOTIFICATION_ADD,
  NOTIFICATION_CLEAR,
  POLL_CREATE,
  POLL_DELEGATE,
  POLL_DELETE,
  POLL_DETAIL,
  POLL_LIST,
  POLL_MULTIPLE_VOTE,
  POLL_SEARCH_SKILLS,
  POLL_UPDATE,
  PROJECT_HOME_ROOM_MAPPING_KEY,
  PROJECT_HOME_ROOM_SET_MAPPING,
  PUSHER_CALL_STARTED,
  PUSHER_RECEIVE_MESSAGE,
  PUSHER_SEND_MESSAGE,
  PUSHER_USER_KICKED,
  PUSHER_USER_TYPING,
  REACTIONS,
  READ_MESSAGES,
  UPDATE_READ_MESSAGES,
  RENAME_ROOM,
  RESET_JUMP,
  ROOM_CHANGE,
  ROOM_HIDE,
  ROOM_MEETING_DETAIL,
  ROOM_MEETING_JOIN,
  ROOM_MEMBERSHIP_DETAIL,
  ROOM_MEMBERSHIP_UPDATE,
  ROOM_SET_LAST,
  ROOM_UPDATE,
  ROOM_VISIBLE,
  SELECTED_CHANNEL_TAB,
  SLASH_COMMAND,
  START_CALL,
} from "actions/chat";
import {
  CHANNEL_CREATE_OR_UPDATE,
  CHANNEL_DELETE,
  INVITATION_ADD,
  PROJECT_DELETE,
  PROJECT_UPDATE,
} from "actions/project";
import { PANELS, SIDE_PANEL } from "actions/sidePanel";

import { SOCKET } from "actions/socket";
import { USER_DETAIL } from "actions/user";
import { availableSlashCommands } from "containers/Workspace/autoComplete";
import Humanize from "humanize-plus";
import localforage from "localforage";
import {
  assign,
  filter,
  find,
  isArray,
  isString,
  keys,
  mapKeys,
  mapValues,
  merge,
  mergeWith,
  omit,
  omitBy,
  orderBy,
  pick,
  pickBy,
  union,
  update,
} from "lodash";
import moment from "moment-timezone";

import { createNotification, createOwnMessage } from "utils/chat";
import { v4 as uuidv4 } from "uuid";

const pollsDefaultState = {
  modalPollErrors: {},
  modalPollLoading: false,
  isSearchingSkills: false,
  items: {},
  searchedSkills: [],
};

export function polls(state = pollsDefaultState, action) {
  switch (action.type) {
    case POLL_CREATE.INIT:
    case POLL_UPDATE.INIT:
      return { ...state, modalPollErrors: {}, modalPollLoading: false, searchedSkills: [] };

    case POLL_CREATE.REQUEST:
    case POLL_UPDATE.REQUEST:
      return { ...state, modalPollLoading: true };

    case POLL_CREATE.FAILURE:
    case POLL_UPDATE.FAILURE:
      return { ...state, modalPollErrors: action.errorDetails, modalPollLoading: false };

    case POLL_CREATE.SUCCESS:
    case POLL_UPDATE.SUCCESS: {
      const { response } = action;

      const itemsCurrent = { ...state.items[response.room] };
      const itemCurrent = { ...itemsCurrent[response.id] };
      const itemUpdated = { ...itemCurrent, ...response };
      const itemsUpdated = { ...itemsCurrent, [response.id]: itemUpdated };
      const items = { ...state.items, [response.room]: itemsUpdated };

      return { ...state, items, modalPollErrors: {}, modalPollLoading: false };
    }

    case POLL_DELETE.SUCCESS: {
      const { poll } = action;

      const items = { ...state.items };
      const item = items[poll.room];
      if (item) {
        delete item[poll.id];
      }

      return { ...state, items };
    }

    case POLL_DETAIL.SUCCESS: {
      const { response } = action;

      const roomId = response.room;
      const itemsCurrent = { ...state.items[roomId] };
      const itemCurrent = itemsCurrent[response.id] || {};
      const itemUpdated = { ...itemCurrent, ...response };
      const itemsUpdated = { ...itemsCurrent, [response.id]: itemUpdated };
      const items = { ...state.items, [roomId]: itemsUpdated };

      return { ...state, items };
    }

    case POLL_LIST.SUCCESS: {
      const { response, roomId } = action;

      const itemsCurrent = { ...state.items[roomId] };
      const itemsResponse = mapKeys(response.results, "id");
      const itemsUpdated = { ...itemsCurrent, ...itemsResponse };
      const items = { ...state.items, [roomId]: itemsUpdated };

      return { ...state, items };
    }

    case POLL_MULTIPLE_VOTE.REQUEST:
    case POLL_DELEGATE.REQUEST: {
      const { poll } = action;

      const itemsCurrent = { ...state.items[poll.room] };
      const itemCurrent = itemsCurrent[poll.id] || {};
      const itemUpdated = { ...itemCurrent, loading: true };
      const itemsUpdated = { ...itemsCurrent, [poll.id]: itemUpdated };
      const items = { ...state.items, [poll.room]: itemsUpdated };

      return { ...state, items };
    }

    case POLL_MULTIPLE_VOTE.SUCCESS:
    case POLL_DELEGATE.SUCCESS: {
      const { poll, response } = action;

      const itemsCurrent = { ...state.items[poll.room] };
      const itemCurrent = itemsCurrent[poll.id] || {};
      const itemUpdated = { ...itemCurrent, ...response, loading: false };
      const itemsUpdated = { ...itemsCurrent, [poll.id]: itemUpdated };
      const items = { ...state.items, [poll.room]: itemsUpdated };

      return { ...state, items };
    }

    case POLL_MULTIPLE_VOTE.FAILURE:
    case POLL_DELEGATE.FAILURE: {
      const { poll } = action;

      const itemsCurrent = { ...state.items[poll.room] };
      const itemCurrent = itemsCurrent[poll.id] || {};
      const itemUpdated = { ...itemCurrent, loading: false };
      const itemsUpdated = { ...itemsCurrent, [poll.id]: itemUpdated };
      const items = { ...state.items, [poll.room]: itemsUpdated };

      return { ...state, items };
    }

    case POLL_SEARCH_SKILLS.INIT: {
      return { ...state, isSearchingSkills: true };
    }

    case POLL_SEARCH_SKILLS.FAILURE: {
      return { ...state, isSearchingSkills: false };
    }

    case POLL_SEARCH_SKILLS.SUCCESS: {
      const { response } = action;

      return { ...state, isSearchingSkills: false, searchedSkills: response.skills };
    }

    case USER_DETAIL.SUCCESS: {
      const { response } = action;

      if (response && response.isDeleted) {
        const { slug, sentinel } = response;

        const items = Object.keys(state.items).reduce((acc, itemId) => {
          const item = Object.keys(state.items[itemId]).reduce((subAcc, subItemId) => {
            const subItem = { ...state.items[itemId][subItemId] };
            if (subItem.author === slug) {
              subItem.author = sentinel.slug;
              subItem.authorName = sentinel.displayName;
            }
            return { ...subAcc, [subItem.id]: subItem };
          }, {});
          return { ...acc, [itemId]: item };
        }, {});

        return { ...state, items };
      }
      return state;
    }

    default:
      return state;
  }
}

export function cachedMessages(state = {}, action) {
  const { cacheId, message, type } = action;

  switch (type) {
    case MESSAGE_BOX_CACHE:
      return { ...state, [cacheId]: message };
    default:
      return state;
  }
}

const roomsDefaultState = {
  _currentRoomId: null,
  addMembersRoomId: null,
  addMembersTimestamp: null,
  isRoomListLoaded: false,
  items: {},
  lastRoom: null,
  loadingMembers: {},
  mapping: {},
  notifications: {},
  roomSending: {},
  settingsErrorDetails: null,
  lastRoomTab: "FEED",

  // Conference call states
  callCode: null,
  callDisconnecting: false,
  callError: null,
  callerId: null,
  callId: null,
  callStarted: false,
  callToken: null,
};

function getRoomInitialState() {
  return {
    activeCallers: [],
    displayName: "",
    files: {},
    isActive: true,
    isComplete: false,
    invitations: [],
    invitation_users: [],
    name: "",
    memberships: [],
    typing: [],
    unreadMessagesCount: 0, // Moved to messages reducer!
    users: [],
  };
}

function addNotificationInfo(notifications, roomId, message) {
  const roomNotificationsCurrent = notifications[roomId] || [];
  const roomNotificationsUpdated = [...roomNotificationsCurrent, createNotification(message)];
  return { ...notifications, [roomId]: roomNotificationsUpdated };
}

function addNotificationError(notifications, roomId, errors) {
  if (!errors) return notifications;
  const errorKeys = Object.keys(errors).sort();

  let notificationsUpdated = notifications;
  errorKeys.forEach((key) => {
    let errorDetails = errors[key];
    if (!errorDetails) return;
    if (!isArray(errorDetails)) errorDetails = [errorDetails];
    if (!isString(errorDetails[0])) return;

    if (["detail", "non_field_errors"].includes(key)) {
      errorDetails.forEach((detail) => {
        notificationsUpdated = addNotificationInfo(notificationsUpdated, roomId, detail);
      });
    } else {
      const header = Humanize.titleCase(key);

      errorDetails.forEach((detail) => {
        notificationsUpdated = addNotificationInfo(
          notificationsUpdated,
          roomId,
          `${header} - ${detail}`
        );
      });
    }
  });

  return notificationsUpdated;
}

function roomCustomizer(objValue, srcValue, key) {
  switch (key) {
    case "invitations": {
      const invitations = {};

      [...objValue, ...srcValue].forEach((invitation) => {
        const invitationLatest = invitations[invitation.id];

        if (!invitationLatest || invitation.modified > invitationLatest.modified) {
          invitations[invitation.id] = invitation;
        }
      });

      return Object.values(invitations);
    }

    case "activeCallers": {
      return srcValue;
    }

    default:
      return undefined;
  }
}

export function rooms(state = roomsDefaultState, action) {
  switch (action.type) {
    case SELECTED_CHANNEL_TAB: {
      const { tabId } = action;

      return {
        ...state,
        lastRoomTab: tabId,
      };
    }
    case ADD_MEMBERS.SUCCESS: {
      const { response } = action;

      const itemCurrent = { ...state.items[response.id] };
      const itemUpdated = { ...getRoomInitialState(), ...itemCurrent, ...response };
      const items = { ...state.items, [response.id]: itemUpdated };
      const notifications = addNotificationInfo(
        state.notifications,
        response.id,
        "Member invitation sent"
      );

      return {
        ...state,
        items,
        notifications,
        addMembersRoomId: response.id,
        addMembersTimestamp: new Date().getTime(),
      };
    }

    case ADD_MEMBERS.FAILURE: {
      const { errorDetails, roomId } = action;
      const notifications = addNotificationError(state.notifications, roomId, errorDetails);

      return {
        ...state,
        notifications,
      };
    }

    case AUTH_LOGOUT.SUCCESS:
      return { ...roomsDefaultState };

    case CHANNEL_CREATE_OR_UPDATE.SUCCESS: {
      const { response } = action;

      const responseProcessed = response.map((room) => ({
        ...getRoomInitialState(),
        ...(state.items[room.id] || {}),
        ...room,
      }));
      const itemsUpdated = mapKeys(responseProcessed, "id");
      const items = { ...state.items, ...itemsUpdated };

      return { ...state, items };
    }

    case CHANNEL_DELETE.SUCCESS: {
      const { channelId } = action;

      const items = { ...state.items };
      delete items[channelId];

      return { ...state, items };
    }

    case CREATE_ROOM.SUCCESS: {
      const { response } = action;

      const item = { ...getRoomInitialState(), ...response };
      const items = { ...state.items, [response.id]: item };

      return { ...state, items };
    }

    case DISCONNECT_CALL.REQUEST:
      return { ...state, callDisconnecting: true };

    case DISCONNECT_CALL.SUCCESS:
      return {
        ...state,
        callCode: null,
        callId: null,
        callToken: null,
        callDisconnecting: false,
        callStarted: false,
        callerId: null,
      };

    case DISCONNECT_CALL.FAILURE:
      return { ...state, callDisconnecting: false };

    // case GET_ROOM.REQUEST: {
    //   const { id, room } = action;
    //   const items = {
    //     ...state.items,
    //     [id]: {
    //       ...getRoomInitialState(),
    //       ...state.items[id],
    //       ...room,
    //     },
    //   };
    //
    //   return { ...state, items };
    // }

    case GET_ROOM.SUCCESS: {
      const { response } = action;

      const itemCurrent = { ...getRoomInitialState(), ...state.items[response.id] };
      const itemUpdated = mergeWith(itemCurrent, response, roomCustomizer);
      const items = { ...state.items, [itemUpdated.id]: itemUpdated };

      return { ...state, items };
    }

    case GET_ROOMS.SUCCESS: {
      const { response } = action;
      const items = mapKeys(
        response.results.map((room) => ({
          ...getRoomInitialState(),
          ...state.items[room.id],
          ...room,
          isComplete: state.items[room.id] ? state.items[room.id].isComplete : room.isComplete,
        })),
        "id"
      );
      return { ...state, items, isRoomListLoaded: true };
    }

    case INVITATION_RESEND.REQUEST: {
      const { invitation, roomId } = action;

      const itemCurrent = { ...state.items[roomId] };
      const invitations = Object.assign([], itemCurrent.invitations);
      const invitationUpdated = invitations.find((i) => i.id === invitation.id) || {};
      invitationUpdated.loading = true;
      const itemUpdated = { ...itemCurrent, invitations };
      const items = { ...state.items, [roomId]: itemUpdated };

      return { ...state, items };
    }

    case INVITATION_RESEND.SUCCESS: {
      const { invitation, roomId } = action;

      const itemCurrent = { ...state.items[roomId] };
      const invitations = Object.assign([], itemCurrent.invitations);
      const invitationUpdated = invitations.find((i) => i.id === invitation.id) || {};
      invitationUpdated.loading = false;
      const itemUpdated = { ...itemCurrent, invitations };
      const items = { ...state.items, [roomId]: itemUpdated };

      return { ...state, items };
    }

    case INVITATION_RESEND.FAILURE: {
      const { errorDetails, invitation, roomId } = action;
      const notifications = addNotificationError(state.notifications, roomId, errorDetails);

      const itemCurrent = { ...state.items[roomId] };
      const invitations = Object.assign([], itemCurrent.invitations);
      const invitationUpdated = invitations.find((i) => i.id === invitation.id) || {};
      invitationUpdated.loading = false;
      const itemUpdated = { ...itemCurrent, invitations };
      const items = { ...state.items, [roomId]: itemUpdated };

      return { ...state, items, notifications };
    }

    case INVITATION_ADD.SUCCESS: {
      const { response } = action;

      const itemCurrent = { ...state.items[response.roomId] };
      const invitations = [...itemCurrent.invitations, response];
      const itemUpdated = { ...itemCurrent, invitations };
      const items = { ...state.items, [response.roomId]: itemUpdated };
      return { ...state, items };
    }

    case INVITATION_DELETE.SUCCESS: {
      const { invitation } = action;

      const itemCurrent = { ...state.items[invitation.roomId] };
      itemCurrent.invitations = itemCurrent.invitations.filter((item) => item.id !== invitation.id);
      const items = { ...state.items, [invitation.roomId]: itemCurrent };

      return { ...state, items };
    }

    case NOTIFICATION_ADD: {
      const { message, roomId } = action;
      const notifications = addNotificationInfo(state.notifications, roomId, message);

      return { ...state, notifications };
    }

    case NOTIFICATION_CLEAR: {
      const { roomId } = action;
      const roomNotificationsCurrent = state.notifications[roomId] || [];
      const roomNotificationsUpdated = [...roomNotificationsCurrent];
      roomNotificationsUpdated.splice(0, 1);
      const notifications = { ...state.notifications, [roomId]: roomNotificationsUpdated };

      return { ...state, notifications };
    }

    case PROJECT_UPDATE.SUCCESS: {
      const { response } = action;
      const projectId = response.result;
      const { roomIds } = response.entities.projects[projectId];
      const { isActive } = response.entities.projects[projectId];
      const newItems = { ...state.items };
      roomIds.forEach((key) => {
        newItems[key] = { ...state.items[key], isActive };
      });
      return { ...state, items: newItems };
    }

    case ROOM_CHANGE: {
      const { projectId, roomId } = action;
      const item = state.items[roomId];

      if (item) {
        const mapping = { ...state.mapping };
        localforage.setItem(LAST_ROOM_KEY, roomId);

        if (projectId) {
          localforage.setItem(PROJECT_HOME_ROOM_MAPPING_KEY, mapping);
          mapping[parseInt(projectId, 10)] = parseInt(roomId, 10);
        }

        return { ...state, _currentRoomId: roomId, lastRoom: roomId, mapping };
      }

      return state;
    }

    case ROOM_SET_LAST: {
      const { roomId } = action;
      return { ...state, lastRoom: roomId };
    }

    case PROJECT_HOME_ROOM_SET_MAPPING: {
      const { mapping } = action;
      return {
        ...state,
        mapping: mapping || {},
      };
    }

    case ROOM_HIDE.REQUEST: {
      const { id } = action;
      const items = { ...state.items };
      items[id] = { ...items[id], isHidden: true };
      return { ...state, items };
    }

    case PUSHER_CALL_STARTED.REQUEST: {
      const { callCode } = action;
      const items = { ...state.items };
      const item = find(items, (r) => r.callCode === callCode) || {};
      item.hasCall = true;
      return { ...state, items };
    }

    case PUSHER_SEND_MESSAGE.REQUEST: {
      const { id } = action;

      const item = { ...state.items[id] };
      item.files = {};
      const items = { ...state.items, [id]: item };

      return { ...state, items };
    }

    case GET_MESSAGES.SUCCESS: {
      const { id, jump } = action;
      const itemCurrent = { ...getRoomInitialState(), ...state.items[id] };
      const itemUpdated = {
        ...itemCurrent,
        id,
        jump: Number(jump) || null,
      };
      const items = { ...state.items, [id]: itemUpdated };
      return { ...state, items };
    }

    case RESET_JUMP: {
      const { id } = action;
      const itemCurrent = { ...getRoomInitialState(), ...state.items[id] };
      const itemUpdated = {
        ...itemCurrent,
        id,
        jump: null,
      };
      const items = { ...state.items, [id]: itemUpdated };
      return { ...state, items };
    }

    case PUSHER_RECEIVE_MESSAGE.SUCCESS: {
      const { id, response } = action;

      const roomData = omit(response, [
        "actions",
        "author",
        "authorName",
        "content",
        "created",
        "data",
        "files",
        "hasReactions",
        "hasRead",
        "id",
        "isDeleted",
        "isEdited",
        "isEmailMessage",
        "messageLength",
        "pinnedCount",
        "pinnedBy",
        "pinnedOn",
        "poll",
        "replies",
        "root",
        "sendId",
        "type",
        "event",
      ]);
      const itemCurrent = { ...getRoomInitialState(), ...state.items[id] };

      const itemUpdated = {
        ...itemCurrent,
        ...roomData,
        id,
      };
      const items = { ...state.items, [id]: itemUpdated };
      return { ...state, items };
    }

    case PUSHER_USER_KICKED: {
      const { currentUser, roomId, userSlugs } = action;

      const items = { ...state.items };

      if (userSlugs.includes(currentUser.slug)) {
        const item = { ...state.items[roomId] };
        item.userSlugs = userSlugs || [];
        item.users = filter(item.users, (user) => userSlugs.includes(user.slug));

        items[roomId] = item;
      } else {
        delete items[roomId];
      }

      return { ...state, items };
    }

    case PUSHER_USER_TYPING.START: {
      const { id, author } = action;
      const item = state.items[id];
      if (item) {
        item.typing = union(item.typing, [author]);
        const items = { ...state.items, [id]: item };
        return { ...state, items };
      }
      return state;
    }

    case PUSHER_USER_TYPING.END: {
      const { id, author } = action;
      const item = state.items[id];
      if (item) {
        item.typing = item.typing.filter((slug) => slug !== author);
        const items = { ...state.items, [id]: item };
        return { ...state, items };
      }
      return state;
    }

    // reset roomSending state on reload
    case AUTH_LOGIN_INIT: {
      const roomSending = {};
      return { ...state, roomSending };
    }

    case SLASH_COMMAND.REQUEST: {
      const { command, id } = action;

      const commands = availableSlashCommands
        .filter((item) => item.shouldCallAPI)
        .map((item) => item.alias || item.command);
      const isSending = commands.includes(command);
      const roomSending = { ...state.roomSending, [id]: isSending };

      return { ...state, roomSending };
    }

    case SLASH_COMMAND.FAILURE:
    case SLASH_COMMAND.SUCCESS: {
      const { id } = action;

      const roomSending = { ...state.roomSending };
      delete roomSending[id];

      return { ...state, roomSending };
    }

    case START_CALL.REQUEST: {
      const { callCode } = action;
      return {
        ...state,
        callCode,
        callError: null,
        callStarted: true,
        callDisconnecting: false,
      };
    }

    case START_CALL.SUCCESS: {
      const { callCode, response } = action;
      const { callId, callToken, callerId } = response;

      return { ...state, callError: null, callCode, callId, callToken, callerId };
    }

    case START_CALL.FAILURE: {
      const { callCode, error } = action;
      return { ...state, callCode, callError: error };
    }

    case RENAME_ROOM.REQUEST: {
      const { id, name } = action;
      const room = { ...state.items[id] };
      room.renaming = true;
      room.formerName = room.name;
      room.name = name;
      return {
        ...state,
        items: {
          ...state.items,
          [id]: room,
        },
      };
    }

    case RENAME_ROOM.SUCCESS: {
      const { id, response } = action;
      const room = { ...state.items[id] };
      room.renaming = false;
      room.name = response.name;
      room.formerName = room.name;
      return {
        ...state,
        items: {
          ...state.items,
          [id]: room,
        },
      };
    }

    case RENAME_ROOM.FAILURE: {
      const { id } = action;
      const room = { ...state.items[id] };
      room.renaming = false;
      room.name = room.formerName;
      room.formerName = null;
      return {
        ...state,
        items: {
          ...state.items,
          [id]: room,
        },
      };
    }

    case ROOM_UPDATE.SUCCESS: {
      const { response } = action;

      const itemCurrent = { ...getRoomInitialState(), ...state.items[response.id] };
      const itemUpdated = mergeWith(itemCurrent, response, roomCustomizer);
      const items = { ...state.items, [response.id]: itemUpdated };

      return { ...state, items };
    }

    case ROOM_VISIBLE: {
      const { id } = action;

      const items = {
        // Reset all rooms to be invisible.
        ...Object.keys(state.items).reduce(
          (acc, val) => ({
            ...acc,
            [val]: {
              ...state.items[val],
              visible: false,
            },
          }),
          {}
        ),
      };

      if (items[id]) {
        // Set selected room as visible. This should be only room with visible: true.
        items[id] = { ...items[id], visible: true };
      }

      return {
        ...state,
        items,
      };
    }

    case ROOM_MEMBERSHIP_DETAIL.SUCCESS: {
      const { membershipId, response } = action;
      const { roomId } = response;

      const roomCurrent = { ...getRoomInitialState(), ...state.items[roomId] };

      if (response.isDeleted) {
        const memberships = roomCurrent.memberships.filter((m) => m.id !== membershipId);
        const roomUpdated = { ...roomCurrent, memberships };
        const roomsUpdated = { ...state.items, [roomId]: roomUpdated };

        return { ...state, items: roomsUpdated };
      }

      const index = roomCurrent.memberships.findIndex((m) => m.id === membershipId);
      if (index >= 0) {
        roomCurrent.memberships[index] = { ...roomCurrent.memberships[index], ...response };
      } else {
        roomCurrent.memberships.push({ ...response });
      }

      const roomsUpdated = { ...state.items, [roomId]: roomCurrent };

      return { ...state, items: roomsUpdated };
    }

    case ROOM_MEMBERSHIP_UPDATE.SUCCESS: {
      const { id, roomId, response } = action;

      if (response && response.entities) {
        const responseItem = response.entities.roomMembership[id];
        const item = state.items[roomId];
        const settings = assign(
          {},
          item.notificationSettings,
          pick(responseItem, keys(item.notificationSettings))
        );

        const items = {
          ...state.items,
          [roomId]: {
            ...state.items[roomId],
            notificationSettings: settings,
          },
        };
        return {
          ...state,
          items,
        };
      }
      return state;
    }

    case PROJECT_DELETE.SUCCESS: {
      const {
        response: { id },
      } = action;

      const items = { ...state.items };
      Object.keys(items).forEach((key) => {
        const item = items[key];
        if (item.project === id) {
          delete items[key];
        }
      });

      return { ...state, items };
    }

    case USER_DETAIL.SUCCESS: {
      const { response } = action;

      if (response && response.isDeleted) {
        const { slug, sentinel } = response;

        const items = Object.keys(state.items).reduce((acc, itemId) => {
          const item = {
            memberships: [],
            userSlugs: [],
            users: [],
            invitationUsers: [],
            invitations: [],
            ...state.items[itemId],
          };
          // if user is the author of the room, change the author, authorName and name
          if (item.author === slug) {
            item.author = sentinel.slug;
            item.authorName = sentinel.displayName;

            if (!item.project) {
              item.name = sentinel.displayName;
            }
          }
          item.memberships = item.memberships.filter((membership) => membership.slug !== slug);
          item.userSlugs = item.userSlugs.filter((userSlug) => userSlug !== slug);
          item.users = item.users.filter((user) => user.slug !== slug);
          item.invitationUsers = item.invitationUsers.filter(
            (invitationUser) => invitationUser.slug !== slug
          );
          item.invitations = item.invitations.filter((invitation) => invitation.user !== slug);

          return { ...acc, [item.id]: item };
        }, {});

        return { ...state, items };
      }
      return state;
    }

    case SIDE_PANEL.TOGGLE: {
      const { panelId, meta } = action;
      if (meta && meta.roomId) {
        const room = state.items[meta.roomId];
        if (room.selected && room.selected.panelId === panelId) {
          room.selected = {
            ...room.selected,
            panelId: null,
          };
        } else {
          room.selected = {
            ...room.selected,
            panelId,
          };
        }
        return {
          ...state,
          items: {
            ...state.items,
            [meta.roomId]: room,
          },
        };
      }
      return state;
    }

    case SIDE_PANEL.OPEN: {
      const { panelId, meta } = action;
      if (meta) {
        const roomId = meta.id || meta.roomId;
        if (roomId) {
          const room = state.items[roomId];
          if (panelId === PANELS.THREAD) {
            room.selected = {
              ...room.selected,
              panelId: null,
            };
          } else {
            room.selected = {
              ...room.selected,
              panelId,
            };
          }
          return {
            ...state,
            items: {
              ...state.items,
              [roomId]: room,
            },
          };
        }
      }
      return state;
    }

    case SIDE_PANEL.CLOSE: {
      const { panelId, meta } = action;
      let items = { ...state.items };
      if (panelId === PANELS.THREAD) {
        // Since the thread carries over to any channel
        // closing it closes all the other side panels
        items = Object.keys(state.items).reduce((acc, itemId) => {
          const item = {
            ...state.items[itemId],
            selected: {
              ...state.items[itemId].selected,
              panelId: null,
            },
          };
          return { ...acc, [itemId]: item };
        }, {});
      } else if (meta && meta.roomId) {
        const room = state.items[meta.roomId];
        if (room.selected && room.selected.panelId === panelId) {
          room.selected = {
            ...room.selected,
            panelId: null,
          };
        }
        items = {
          ...state.items,
          [meta.roomId]: room,
        };
      }
      return { ...state, items };
    }

    default:
      return state;
  }
}

const messagesDefaultState = {
  attachments: {},
  deletedAttachments: {},
  nextItemsUrls: {},
  hasReactions: false,
  initialLoad: {},
  initialSearch: {},
  items: {},
  ordering: {},
  searchOrdering: {},
  lastTimeStamp: {},
  loading: {},
  loadingSearch: {},
  unreadMessagesLoaded: false,
  unreadMessagesCount: 0,
  unreadMessagesCountByRoom: {},
  unreadMessagesLastTimestampByRoom: {},
  _currentThread: {
    room: null,
    message: null,
  },
};

function makeOrdering(items, lastTimeStamp, createdOrder = "asc") {
  if (!lastTimeStamp) {
    return [];
  }
  return orderBy(
    pickBy(items, ({ created }) => moment(created).valueOf() >= lastTimeStamp),
    ["created", "id"],
    [createdOrder, "asc"]
  ).map(({ id }) => id);
}

export function messages(state = { ...messagesDefaultState }, action) {
  const { type, id, response } = action;
  switch (type) {
    case AUTH_LOGOUT.SUCCESS:
      return messagesDefaultState;

    case SOCKET.ONLINE:
      return state;

    case GET_ROOMS.SUCCESS: {
      const unreadMessagesCountByRoomResponse = Object.assign(
        {},
        ...response.results.map((room) => ({ [room.id]: room.unreadMessagesCount }))
      );
      const unreadMessagesCountByRoom = {
        ...state.unreadMessagesCountByRoom,
        ...unreadMessagesCountByRoomResponse,
      };
      const unreadMessagesCount = Object.keys(unreadMessagesCountByRoom).reduce(
        (acc, roomId) => acc + unreadMessagesCountByRoom[roomId],
        0
      );

      const unreadMessagesLastTimestampByRoom = Object.assign(
        {},
        ...response.results.map((room) => ({ [room.id]: room.newestMessageDate }))
      );
      return {
        ...state,
        unreadMessagesCountByRoom,
        unreadMessagesCount,
        unreadMessagesLastTimestampByRoom,
        unreadMessagesLoaded: true,
      };
    }

    case GET_ROOM.SUCCESS: {
      const unreadMessagesCountByRoom = {
        ...state.unreadMessagesCountByRoom,
        [response.id]: response.unreadMessagesCount,
      };
      const unreadMessagesCount = Object.keys(unreadMessagesCountByRoom).reduce(
        (acc, roomId) => acc + unreadMessagesCountByRoom[roomId],
        0
      );
      return { ...state, unreadMessagesCountByRoom, unreadMessagesCount };
    }

    case UPDATE_READ_MESSAGES.REQUEST:
    case READ_MESSAGES.REQUEST: {
      if (!state.items[id]) {
        return state;
      }

      const updatedItems = Object.keys(state.items[id]).reduce(
        (acc, messageId) => ({
          ...acc,
          [messageId]: {
            ...state.items[id][messageId],
            hasRead: true,
          },
        }),
        {}
      );

      const unreadMessagesCountByRoom = {
        ...state.unreadMessagesCountByRoom,
        [id]: 0,
      };
      const unreadMessagesCount = Object.keys(unreadMessagesCountByRoom).reduce(
        (acc, roomId) => acc + unreadMessagesCountByRoom[roomId],
        0
      );

      return {
        ...state,
        items: {
          ...state.items,
          [id]: updatedItems,
        },
        unreadMessagesCount,
        unreadMessagesCountByRoom,
      };
    }

    case EDIT_MESSAGE.REQUEST: {
      const { messageId, content } = action;
      const room = state.items[id];
      const message = { ...room[messageId] };
      message.previousContent = message.content;
      message.content = content;
      message.isEdited = true;

      const items = { ...state.items, [id]: { ...room, [messageId]: message } };
      return { ...state, items };
    }

    case EDIT_MESSAGE.SUCCESS: {
      const { messageId } = action;
      const room = state.items[id];
      const message = { ...room[messageId] };
      message.previousContent = message.content;

      const items = { ...state.items, [id]: { ...room, [messageId]: message } };
      return { ...state, items };
    }

    case DELETE_MESSAGE.REQUEST: {
      const { messageId } = action;
      const room = state.items[id];
      const message = { ...room[messageId] };
      message.loading = true;

      const items = { ...state.items, [id]: { ...room, [messageId]: message } };

      // Optimistically remove the message from list of messages shown
      const ordering = {
        ...state.ordering,
        [id]: state.ordering[id].filter((mid) => mid !== messageId),
      };

      // move the attachments to be deleted to `deletedAttachments`
      const messagesList = [message];
      if (message.repliesIds) {
        message.repliesIds.forEach((replyId) => {
          messagesList.push(room[replyId]);
        });
      }
      const roomDeletedAttachments = { ...state.deletedAttachments[id] };
      const roomAttachments = { ...state.attachments[id] };

      messagesList.forEach((messageItem) => {
        if (messageItem.files && messageItem.files.length > 0) {
          messageItem.files
            .map((file) => file.id)
            .forEach((fileId) => {
              roomDeletedAttachments[fileId] = {
                ...roomAttachments[fileId],
                attachedTo: messageId,
              };
              delete roomAttachments[fileId];
            });
        }
      });

      const attachments = {
        ...state.attachments,
        [id]: roomAttachments,
      };
      const deletedAttachments = {
        ...state.deletedAttachments,
        [id]: roomDeletedAttachments,
      };

      return { ...state, attachments, deletedAttachments, items, ordering };
    }

    case DELETE_MESSAGE.SUCCESS: {
      const { messageId } = action;

      const deletedAttachments = { ...state.deletedAttachments[id] };

      // get all the attachments attached to message deleted
      Object.values(deletedAttachments)
        .filter((fileItem) => fileItem.attachedTo === messageId)
        .map((fileItem) => fileItem.id)
        .forEach((fileId) => delete deletedAttachments[fileId]);
      return {
        ...state,
        deletedAttachments: {
          ...state.deletedAttachments,
          [id]: deletedAttachments,
        },
      };
    }

    case DELETE_MESSAGE.FAILURE: {
      const { messageId } = action;
      const room = state.items[id];
      const message = { ...room[messageId] };
      message.loading = false;

      const items = { ...state.items, [id]: { ...room, [messageId]: message } };

      // Put the not-deleted message back to the list of messages shown
      const ordering = { ...state.ordering };
      ordering[id] = makeOrdering(items[id], state.lastTimeStamp[id]);

      // put back the attachments from `deletedAttachments`
      const roomDeletedAttachments = { ...state.deletedAttachments[id] };
      const roomAttachments = { ...state.attachments[id] };
      Object.values(roomDeletedAttachments)
        .filter((fileItem) => fileItem.attachedTo === messageId)
        .map((fileItem) => fileItem.id)
        .forEach((fileId) => {
          const attachmentItem = { ...roomDeletedAttachments[fileId] };
          delete attachmentItem.attachedTo;
          roomAttachments[fileId] = attachmentItem;

          delete roomDeletedAttachments[fileId];
        });

      const attachments = {
        ...state.attachments,
        [id]: roomAttachments,
      };
      const deletedAttachments = {
        ...state.deletedAttachments,
        [id]: roomDeletedAttachments,
      };

      return { ...state, attachments, deletedAttachments, items, ordering };
    }

    case POLL_CREATE.SUCCESS: {
      const { roomId } = action;
      const message = {
        author: response.author,
        authorName: response.authorName,
        content: "",
        id: response.message,
        hasRead: true,
        poll: { ...response },
      };

      const itemsCurrent = { ...state.items[roomId] };
      const itemsUpdated = merge({}, itemsCurrent, { [message.id]: message });
      const items = { ...state.items, [roomId]: itemsUpdated };

      const ordering = {
        ...state.ordering,
        [roomId]: makeOrdering(itemsUpdated, state.lastTimeStamp[roomId]),
      };

      return { ...state, items, ordering };
    }

    case POLL_DELETE.REQUEST: {
      const { poll } = action;
      const roomMessages = state.items[poll.room] || {};
      const message = roomMessages[poll.message] || {};
      message.loading = true;

      const items = { ...state.items };
      const item = items[poll.room];
      if (item && message.id) {
        item[message.id] = message;
      }

      return { ...state, items };
    }

    case POLL_DELETE.SUCCESS: {
      const { poll } = action;
      const roomMessages = state.items[poll.room] || {};
      const message = roomMessages[poll.message] || {};

      const items = { ...state.items };
      const ordering = { ...state.ordering };
      const item = items[poll.room];
      const itemOrdering = ordering[poll.room];

      if (item && itemOrdering && message.id) {
        delete item[message.id];
        ordering[poll.room] = itemOrdering.filter((mid) => mid !== message.id);
      }

      return { ...state, items, ordering };
    }

    case POLL_DELETE.FAILURE: {
      const { poll } = action;
      const roomMessages = state.items[poll.room] || {};
      const message = roomMessages[poll.message] || {};
      message.loading = false;

      const items = { ...state.items };
      const item = items[poll.room];
      if (item && message.id) {
        item[message.id] = message;
      }

      return { ...state };
    }

    case POLL_DETAIL.SUCCESS: {
      const { fromCurrentUser } = action;

      const roomId = response.room;
      const itemsCurrent = { ...state.items[roomId] };
      const itemCurrent = { ...itemsCurrent[response.message] };
      const itemUpdated = {
        content: "",
        id: response.message,
        ...itemCurrent,
        author: response.author,
        authorName: response.authorName,
        created: moment(response.messageCreated).valueOf(),
        hasRead: fromCurrentUser,
        poll: response,
      };
      const itemsUpdated = merge({}, itemsCurrent, { [response.message]: itemUpdated });
      itemsUpdated[response.message].poll = response;
      const items = { ...state.items, [roomId]: itemsUpdated };

      const ordering = {
        ...state.ordering,
        [roomId]: makeOrdering(itemsUpdated, state.lastTimeStamp[roomId]),
      };

      return { ...state, items, ordering };
    }

    case POLL_MULTIPLE_VOTE.REQUEST:
    case POLL_DELEGATE.REQUEST: {
      const { poll } = action;

      const roomId = poll.room;
      const itemsCurrent = { ...state.items[roomId] };
      const itemCurrent = { ...itemsCurrent[poll.message] };
      itemCurrent.poll = { ...poll, loading: true };
      const itemsUpdated = merge({}, itemsCurrent, { [poll.message]: itemCurrent });
      const items = { ...state.items, [roomId]: itemsUpdated };

      return { ...state, items };
    }

    case POLL_MULTIPLE_VOTE.SUCCESS:
    case POLL_DELEGATE.SUCCESS: {
      const { poll } = action;

      const roomId = poll.room;
      const itemsCurrent = { ...state.items[roomId] };
      const itemCurrent = { ...itemsCurrent[poll.message] };
      itemCurrent.poll = { ...poll, ...response, loading: false };
      const itemsUpdated = merge({}, itemsCurrent, { [poll.message]: itemCurrent });
      const items = { ...state.items, [roomId]: itemsUpdated };

      return { ...state, items };
    }

    case POLL_MULTIPLE_VOTE.FAILURE:
    case POLL_DELEGATE.FAILURE: {
      const { poll } = action;
      const room = state.items[poll.room];
      const message = room[poll.message];
      message.poll = { ...poll, ...response, loading: false };

      const items = { ...state.items };
      return { ...state, items };
    }

    case POLL_UPDATE.SUCCESS: {
      const roomId = response.room;
      const itemsCurrent = { ...state.items[roomId] };
      const itemCurrent = { ...itemsCurrent[response.message] };
      const itemUpdated = {
        content: "",
        id: response.message,
        hasRead: true,
        ...itemCurrent,
        author: response.author,
        authorName: response.authorName,
        created: moment(response.messageCreated).valueOf(),
        poll: { ...response, loading: false },
      };
      const itemsUpdated = merge({}, itemsCurrent, { [response.message]: itemUpdated });
      itemsUpdated[response.message].poll = { ...response, loading: false };
      const items = { ...state.items, [roomId]: itemsUpdated };
      const ordering = {
        ...state.ordering,
        [roomId]: makeOrdering(itemsUpdated, state.lastTimeStamp[roomId]),
      };

      return { ...state, items, ordering };
    }

    case PUSHER_SEND_MESSAGE.REQUEST: {
      const { content, files, sendId, user, root } = action;
      const lastTimeStampCurrent = moment().valueOf();

      const message = createOwnMessage(
        sendId,
        content,
        lastTimeStampCurrent,
        user,
        null,
        null,
        Object.keys(files).map((key) => files[key]),
        root
      );

      const itemsCurrent = { ...state.items[id] };
      const itemsUpdated = merge({}, itemsCurrent, { [message.id]: message });
      const items = { ...state.items, [id]: itemsUpdated };

      const ordering = {
        ...state.ordering,
        [id]: makeOrdering(itemsUpdated, state.lastTimeStamp[id]),
      };

      return { ...state, items, ordering };
    }

    case PUSHER_SEND_MESSAGE.FAILURE: {
      const { sendId, error } = action;
      const item = { ...state.items[id] };
      const message = { ...item[sendId] };
      message.error = error;
      message.loading = false;
      item[sendId] = message;
      const items = { ...state.items, [id]: item };
      return { ...state, items };
    }

    case GET_MESSAGES.REQUEST: {
      const loading = { ...state.loading, [id]: true };
      const nextState = { ...state, loading };

      // Reset the ordering when jumping.
      if (action.jump) {
        nextState.ordering = { ...state.ordering, [id]: [] };
      }

      return nextState;
    }

    case GET_THREAD.REQUEST: {
      const { messageId } = action;
      return { ...state, _currentThread: { room: id, message: messageId } };
    }

    case GET_SEARCH.REQUEST: {
      const loadingSearch = { ...state.loadingSearch };
      loadingSearch[id] = true;
      return { ...state, loadingSearch };
    }

    case RESET_JUMP: {
      const ordering = { ...state.ordering };
      ordering[id] = makeOrdering(state.items[id], state.lastTimeStamp[id]);
      return { ...state, ordering };
    }

    case GET_MESSAGES.SUCCESS:
    case GET_PINNED_MESSAGES.SUCCESS:
    case GET_THREAD.SUCCESS:
    case GET_SEARCH.SUCCESS: {
      const { jump } = action;
      const itemsCurrent = { ...state.items[id] };

      const itemsResponse = mapKeys(response.results, "id");
      const itemsResponseProcessed = mapValues(itemsResponse, (item) =>
        update(item, "created", (created) => moment(created).valueOf())
      );
      const itemsUpdated = { ...itemsCurrent };
      Object.keys(itemsResponseProcessed).forEach((mid) => {
        itemsUpdated[mid] = itemsResponseProcessed[mid];
      });
      // const itemsUpdated = merge({}, itemsCurrent, itemsResponseProcessed);
      const items = { ...state.items, [id]: itemsUpdated };
      const initialLoad = { ...state.initialLoad };
      const initialSearch = { ...state.initialSearch };
      const nextItemsUrls = { ...state.nextItemsUrls };
      const lastTimeStamp = { ...state.lastTimeStamp };
      const loading = { ...state.loading };
      const loadingSearch = { ...state.loadingSearch };
      const ordering = { ...state.ordering };
      const searchOrdering = { ...state.searchOrdering };

      const now = moment().valueOf();
      lastTimeStamp[id] = lastTimeStamp[id] || now;
      if (action.type === GET_MESSAGES.SUCCESS) {
        loading[id] = false;

        if (jump) {
          ordering[id] = response.results.map((result) => result.id).reverse();
        } else {
          nextItemsUrls[id] = response.next;
          initialLoad[id] = true;
          if (response.oldest) {
            lastTimeStamp[id] =
              moment(response.oldest.created).valueOf() || lastTimeStamp[id] || now;
          }
          ordering[id] = makeOrdering(itemsUpdated, lastTimeStamp[id]);
        }
      } else if (action.type === GET_SEARCH.SUCCESS) {
        const { sort } = action;
        const searchSort = sort || "desc";

        initialSearch[id] = true;
        loadingSearch[id] = false;
        searchOrdering[id] = response.oldest
          ? makeOrdering(
              itemsResponseProcessed,
              moment(response.oldest.created).valueOf(),
              searchSort
            )
          : [];
      }

      return {
        ...state,
        nextItemsUrls,
        initialLoad,
        initialSearch,
        items,
        ordering,
        searchOrdering,
        lastTimeStamp,
        loading,
        loadingSearch,
      };
    }

    case GET_MESSAGES.FAILURE: {
      const loading = { ...state.loading, [id]: false };

      return { ...state, loading };
    }

    case GET_ATTACHMENTS.SUCCESS: {
      const attachmentsCurrent = { ...state.attachments[id] };

      const attachmentsResponse = mapKeys(response.results, "id");
      const attachmentsResponseProcessed = mapValues(attachmentsResponse, (item) =>
        update(item, "created", (created) => moment(created).valueOf())
      );
      const attachmentsUpdated = { ...attachmentsCurrent };
      Object.keys(attachmentsResponseProcessed).forEach((mid) => {
        attachmentsUpdated[mid] = attachmentsResponseProcessed[mid];
      });
      const attachments = { ...state.attachments, [id]: attachmentsUpdated };
      return {
        ...state,
        attachments,
      };
    }

    case PUSHER_RECEIVE_MESSAGE.SUCCESS: {
      let item = { ...response, created: moment(response.created).valueOf() };

      item = pick(item, [
        "actions",
        "author",
        "authorName",
        "content",
        "created",
        "data",
        "files",
        "hasReactions",
        "hasRead",
        "id",
        "isDeleted",
        "isEdited",
        "isEmailMessage",
        "pinnedCount",
        "pinnedBy",
        "pinnedOn",
        "poll",
        "replies",
        "root",
        "sendId",
        "repliesIds",
      ]);

      const itemsCurrent = { ...state.items[id] };

      const itemTemporary = itemsCurrent[item.sendId];
      if (itemTemporary) {
        item.created = itemTemporary.created;
      }

      let itemsUpdated = merge({}, { [item.id]: { hasRead: false } }, itemsCurrent, {
        [item.id]: item,
      });
      itemsUpdated = omit(itemsUpdated, item.sendId);

      if (item.isDeleted) {
        // Remove deleted items from messages
        itemsUpdated = omitBy(itemsUpdated, (m) => m.id === item.id || m.root === item.id);
      } else if (itemsUpdated[item.id].poll && item.poll) {
        // Fix poll state
        itemsUpdated[item.id].poll = item.poll;
      }

      // Remove deleted items from search results
      const searchOrdering = {
        ...state.searchOrdering,
        [id]: (state.searchOrdering[id] || []).filter((mid) => !item.isDeleted || mid !== item.id),
      };

      const unreadMessagesCountByRoom = {
        ...state.unreadMessagesCountByRoom,
      };
      const unreadMessagesLastTimestampByRoom = {
        ...state.unreadMessagesLastTimestampByRoom,
        [id]: itemsUpdated[item.id] ? new Date(itemsUpdated[item.id].created) : new Date(),
      };
      if (!itemsCurrent[item.id] && itemsUpdated[item.id] && !itemsUpdated[item.id].hasRead) {
        unreadMessagesCountByRoom[id] = (unreadMessagesCountByRoom[id] || 0) + 1;
      } else if (itemsCurrent[item.id] && !itemsUpdated[item.id]) {
        unreadMessagesCountByRoom[id] = (unreadMessagesCountByRoom[id] || 1) - 1;
      }
      const unreadMessagesCount = Object.keys(unreadMessagesCountByRoom).reduce(
        (acc, roomId) => acc + unreadMessagesCountByRoom[roomId],
        0
      );

      const items = { ...state.items, [id]: itemsUpdated };
      const ordering = {
        ...state.ordering,
        [id]: makeOrdering(itemsUpdated, state.lastTimeStamp[id]),
      };

      let attachments = { ...state.attachments };
      if (item.files && item.files.length > 0) {
        const attachmentsCurrent = { ...state.attachments[id] };

        const attachmentsResponse = mapKeys(item.files, "id");
        const attachmentsResponseProcessed = mapValues(attachmentsResponse, (fileItem) =>
          update(fileItem, "created", () => moment(item.created).valueOf())
        );
        const attachmentsUpdated = { ...attachmentsCurrent };
        Object.keys(attachmentsResponseProcessed).forEach((mid) => {
          attachmentsUpdated[mid] = attachmentsResponseProcessed[mid];
        });

        attachments = { ...state.attachments, [id]: attachmentsUpdated };
      }

      return {
        ...state,
        attachments,
        items,
        ordering,
        searchOrdering,
        unreadMessagesCount,
        unreadMessagesCountByRoom,
        unreadMessagesLastTimestampByRoom,
      };
    }

    case ROOM_MEETING_DETAIL.SUCCESS: {
      const { fromCurrentUser, oid } = action;
      if (response && response.entities) {
        const eventResponse = response.entities.events[oid];
        const roomId = eventResponse.room;
        const itemsCurrent = { ...state.items[roomId] };
        const itemCurrent = { ...itemsCurrent[eventResponse.message] };
        const itemUpdated = {
          content: "",
          id: eventResponse.message,
          ...itemCurrent,
          author: eventResponse.author,
          authorName: eventResponse.authorName,
          created: moment(eventResponse.messageCreated).valueOf(),
          hasRead: fromCurrentUser,
          event: eventResponse,
        };
        const itemsUpdated = merge({}, itemsCurrent, { [eventResponse.message]: itemUpdated });
        itemsUpdated[eventResponse.message].event = eventResponse;
        const items = { ...state.items, [roomId]: itemsUpdated };

        const ordering = {
          ...state.ordering,
          [roomId]: makeOrdering(itemsUpdated, state.lastTimeStamp[roomId]),
        };

        return { ...state, items, ordering };
      }
      return state;
    }

    case ROOM_MEETING_JOIN.REQUEST: {
      const { event } = action;

      const roomId = event.room;
      const itemsCurrent = { ...state.items[roomId] };
      const itemCurrent = { ...itemsCurrent[event.message] };
      itemCurrent.event = { ...event, loading: true };
      const itemsUpdated = merge({}, itemsCurrent, { [event.message]: itemCurrent });
      const items = { ...state.items, [roomId]: itemsUpdated };

      return { ...state, items };
    }

    case ROOM_MEETING_JOIN.SUCCESS: {
      const { event } = action;

      if (response && response.entities) {
        const roomId = event.room;
        const eventResponse = response.entities.events[event.id];
        const itemsCurrent = { ...state.items[roomId] };
        const itemCurrent = { ...itemsCurrent[event.message] };
        const itemUpdated = {
          ...itemCurrent,
          event: {
            ...event,
            ...eventResponse,
            loading: false,
          },
        };
        const itemsUpdated = { ...itemsCurrent, [event.message]: itemUpdated };
        const items = { ...state.items, [roomId]: itemsUpdated };

        return { ...state, items };
      }
      return state;
    }

    case ROOM_MEETING_JOIN.FAILURE: {
      const { event } = action;

      const roomId = event.room;
      const itemsCurrent = { ...state.items[roomId] };
      const itemCurrent = { ...itemsCurrent[event.message] };
      itemCurrent.event = { ...event, loading: false };
      const itemsUpdated = merge({}, itemsCurrent, { [event.message]: itemCurrent });
      const items = { ...state.items, [roomId]: itemsUpdated };

      return { ...state, items };
    }

    case REACTIONS.SUCCESS: {
      const { messageId } = action;

      const items = { ...state.items };
      const room = items[id];
      if (room) {
        const message = room[messageId];
        message.loadedReactions = true;
      }

      return { ...state, items };
    }

    case SLASH_COMMAND.SUCCESS: {
      if (response && response.message) {
        const message = createOwnMessage(
          uuidv4(),
          `(only visible to you): ${response.message}`,
          moment().valueOf()
        );

        const itemsCurrent = { ...state.items[id] };
        const itemsUpdated = merge({}, itemsCurrent, { [message.id]: message });
        const items = { ...state.items, [id]: itemsUpdated };

        const ordering = {
          ...state.ordering,
          [id]: makeOrdering(itemsUpdated, state.lastTimeStamp[id]),
        };

        return { ...state, items, ordering };
      }

      return state;
    }

    case SLASH_COMMAND.FAILURE: {
      const { error, errorDetails } = action;

      const errorString = errorDetails
        ? Object.values(errorDetails).reduce((a, b) => `${a}, ${b}`)
        : error;

      const message = createOwnMessage(
        uuidv4(),
        `ERROR (only visible to you): ${errorString}`,
        moment().valueOf()
      );

      const itemsCurrent = { ...state.items[id] };
      const itemsUpdated = merge({}, itemsCurrent, { [message.id]: message });
      const items = { ...state.items, [id]: itemsUpdated };

      const ordering = {
        ...state.ordering,
        [id]: makeOrdering(itemsUpdated, state.lastTimeStamp[id]),
      };

      return { ...state, items, ordering };
    }

    case USER_DETAIL.SUCCESS: {
      if (response && response.isDeleted) {
        const { slug, sentinel } = response;

        const items = Object.keys(state.items).reduce((acc, itemId) => {
          const item = Object.keys(state.items[itemId]).reduce((subAcc, subItemId) => {
            const subItem = { ...state.items[itemId][subItemId] };
            if (subItem.author === slug) {
              subItem.author = sentinel.slug;
              subItem.authorName = sentinel.displayName;
            }
            if (subItem.event) {
              const event = {
                invitations: [],
                ...subItem.event,
              };
              if (event.author === slug) {
                event.author = sentinel.slug;
                event.authorName = sentinel.displayName;
              }
              event.invitations = event.invitations.filter(
                (invitation) => invitation.user !== slug
              );
              subItem.event = event;
            }
            return { ...subAcc, [subItem.id]: subItem };
          }, {});
          return { ...acc, [itemId]: item };
        }, {});

        return { ...state, items };
      }
      return state;
    }

    default:
      return state;
  }
}

const messageAttachmentsDefaultState = {
  nextItemsUrls: {},
  loading: {},
};

export function messageAttachments(state = { ...messageAttachmentsDefaultState }, action) {
  const { type, id, response } = action;
  switch (type) {
    case AUTH_LOGOUT.SUCCESS:
      return messageAttachmentsDefaultState;

    case SOCKET.ONLINE:
      return messageAttachmentsDefaultState;

    case GET_ATTACHMENTS.REQUEST: {
      const loading = { ...state.loading, [id]: true };
      const nextState = { ...state, loading };

      return nextState;
    }

    case GET_ATTACHMENTS.SUCCESS: {
      const nextItemsUrls = { ...state.nextItemsUrls };
      const loading = { ...state.loading };

      if (action.type === GET_ATTACHMENTS.SUCCESS) {
        loading[id] = false;
        nextItemsUrls[id] = response.next;
      }

      return {
        ...state,
        nextItemsUrls,
        loading,
      };
    }

    case GET_ATTACHMENTS.FAILURE: {
      const loading = { ...state.loading, [id]: false };

      return { ...state, loading };
    }

    default:
      return state;
  }
}
