import classnames from "classnames";
import { range } from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { Button, Divider, Header, Icon } from "semantic-ui-react";
import ProjectBudgetItem from "./ProjectBudgetItem";
import styles from "./styles.module.scss";
import { getErrorMessages } from "../../utils";

const MAX_MONTH = 12;

export default class ProjectBudget extends React.PureComponent {
  constructor(props) {
    super();

    const { timezone } = props;

    this.state = {
      selectedYear: moment().tz(timezone).year(),
      loading: false,
      budgetErrors: {},
      budgetUpdated: {},
    };
  }

  getTotalBudget = () => {
    const { budget } = this.props;
    const { budgetUpdated } = this.state;
    const storedTotalBudget = budget.reduce((total, item) => total + parseFloat(item.amount), 0);
    const netUpdateAmount = Object.entries(budgetUpdated).reduce(
      (total, [updateId, updateItem]) => {
        const newAmount = parseFloat(updateItem.amount) || 0;
        if (newAmount < 0) {
          return total;
        }
        const oldItemIndex = budget.findIndex((item) => item.id === updateId);
        const oldAmount = oldItemIndex !== -1 ? parseFloat(budget[oldItemIndex].amount) || 0 : 0;
        return total + newAmount - oldAmount;
      },
      0
    );
    return (storedTotalBudget + netUpdateAmount).toLocaleString("en", {
      style: "currency",
      currency: "USD",
    });
  };

  handleNextYear = () => {
    this.setState((prev) => ({
      selectedYear: moment(prev.selectedYear, "YYYY").add(1, "years").year(),
    }));
  };

  handlePreviousYear = () => {
    this.setState((prev) => ({
      selectedYear: moment(prev.selectedYear, "YYYY").subtract(1, "years").year(),
    }));
  };

  handleBudgetCancel = (budgetId) => {
    this.setState((prevState) => {
      const budgetUpdated = { ...prevState.budgetUpdated };
      delete budgetUpdated[budgetId];

      return { budgetUpdated };
    });
  };

  handleBudgetChange = (budgetId, budget) => {
    this.setState(
      (prevState) => {
        const budgetUpdated = {
          ...prevState.budgetUpdated,
          [budgetId]: budget,
        };
        return { budgetUpdated };
      },
      () => this.props.onUpdate(this.state.budgetUpdated)
    );
  };

  handleErrorHide = (budgetId, index) => {
    this.setState((prevState) => {
      const errorsCurrent = prevState.budgetErrors[budgetId];
      const errorsUpdated = [...errorsCurrent.slice(0, index), ...errorsCurrent.slice(index + 1)];

      const errors = {
        ...prevState.budgetErrors,
        [budgetId]: errorsUpdated,
      };

      return { budgetErrors: errors };
    });
  };

  handleSave = async () => {
    const { onSave, projectId } = this.props;
    const { budgetUpdated } = this.state;
    const projectBudget = Object.values(budgetUpdated).map((item) => {
      const updatedItem = {
        ...item,
        amount: item.amount || 0,
      };
      if (updatedItem.isNew) {
        delete updatedItem.id;
        delete updatedItem.isNew;
        updatedItem.project = projectId;
      }
      return updatedItem;
    });
    this.setState({ loading: true, budgetErrors: {} });
    try {
      await onSave(projectBudget);
      this.setState({ budgetUpdated: {} });
    } catch (errorsUnprocessed) {
      const errorList = Object.values(errorsUnprocessed);
      if (errorList.length > 0) {
        const budgetList = Object.values(budgetUpdated);
        const budgetErrors = {};
        errorList.forEach((errorItem, index) => {
          const budgetItem = budgetList[index];
          budgetErrors[budgetItem.id] = getErrorMessages(errorItem);
        });
        this.setState({ budgetErrors });
      }
    }
    this.setState({ loading: false });
  };

  renderBudgetList = (start, end) => {
    const { budget, disabled, isAdmin } = this.props;
    const { selectedYear, budgetErrors, budgetUpdated, loading } = this.state;
    let sliceStart = 0;
    let sliceEnd = 12;

    if (start) sliceStart = start;
    if (end) sliceEnd = end;

    const filteredBudget = budget
      .filter((item) => item.year === selectedYear)
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.month]: item,
        }),
        {}
      );

    const yearlyBudget = range(1, MAX_MONTH + 1).map((month) => {
      const monthlyBudget = filteredBudget[month] || {
        amount: "",
        id: `${month}-${selectedYear}`,
        month,
        year: selectedYear,
        isNew: true,
      };
      return {
        ...monthlyBudget,
        ...budgetUpdated[monthlyBudget.id],
      };
    });

    return yearlyBudget
      .slice(sliceStart, sliceEnd)
      .map((item) => (
        <ProjectBudgetItem
          key={item.id}
          budget={item}
          disabled={disabled}
          isAdmin={isAdmin}
          isDirty={item.id in budgetUpdated}
          errors={budgetErrors[item.id]}
          loading={loading}
          onCancel={this.handleBudgetCancel}
          onChange={this.handleBudgetChange}
          onErrorHide={this.handleErrorHide}
        />
      ));
  };

  render() {
    const { disabled, hasTotal, isAdmin, onSave, projectId } = this.props;
    const { selectedYear, budgetUpdated, loading } = this.state;

    const isBudgetDirty = Object.values(budgetUpdated).length > 0;
    const totalBudget = this.getTotalBudget();

    return (
      <>
        <div className={styles.budgetYear}>
          <Button
            className={classnames(styles.sectionArrow, styles.solid)}
            icon
            onClick={this.handlePreviousYear}
          >
            <Icon name="chevron left" />
          </Button>
          <span>{selectedYear}</span>
          <Button
            className={classnames(styles.sectionArrow, styles.solid)}
            icon
            onClick={this.handleNextYear}
          >
            <Icon name="chevron right" />
          </Button>
        </div>
        <div className={styles.sectionBudget}>
          <div>{this.renderBudgetList(0, 6)}</div>
          <div>{this.renderBudgetList(6, 12)}</div>
        </div>
        {hasTotal && (
          <>
            <Divider />
            <Header as="h4">
              Overall Budget: <strong>{totalBudget}</strong>
            </Header>
          </>
        )}
        {isAdmin && !disabled && projectId && onSave && (
          <div className={styles.sectionButtons}>
            <Button
              className={styles.sectionButtonsPrimary}
              content="Save"
              disabled={loading || disabled || !isBudgetDirty}
              loading={loading}
              onClick={this.handleSave}
            />
          </div>
        )}
      </>
    );
  }
}

ProjectBudget.propTypes = {
  budget: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  hasTotal: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  projectId: PropTypes.number,
  timezone: PropTypes.string,
  onSave: PropTypes.func,
  onUpdate: PropTypes.func,
};

ProjectBudget.defaultProps = {
  budget: [],
  onSave: () => {},
  onUpdate: () => {},
  timezone: "UTC",
};
