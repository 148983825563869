import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ReactNotification from "react-web-notification";
import NotificationSound from "utils/notificationSound";
import { Icon, Transition } from "semantic-ui-react";
import styles from "../styles.module.scss";

export default class MeetingCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isNotifIgnored: true,
    };
  }

  handleNotSupported = () => {
    this.setState({
      isNotifIgnored: true,
    });
  };

  handlePermissionDenied = () => {
    this.setState({
      isNotifIgnored: true,
    });
  };

  handlePermissionGranted = () => {
    this.setState({
      isNotifIgnored: false,
    });
  };

  componentDidMount() {
    if ("Notification" in window) {
      if (Notification.permission === "default" || Notification.permission === "denied") {
        this.setState({
          isNotifIgnored: true,
        });
      } else {
        NotificationSound.playBeep();
        this.setState({
          isNotifIgnored: false,
        });
      }
    }
  }

  render() {
    const { currentUser, data, duration, visible } = this.props;
    const { isNotifIgnored } = this.state;
    const { context } = data;
    const { start, end, title } = context.event;
    const userMeetingStart = moment(start).tz(currentUser.timezoneDisplay);
    const userMeetingEnd = moment(end).tz(currentUser.timezoneDisplay);
    const formattedStart = userMeetingStart.format("hh:mm A");
    const formattedEnd = userMeetingEnd.format("hh:mm A");
    const formattedTitle = `Upcoming Meeting: ${title}`;
    const options = {
      body: `${formattedStart} - ${formattedEnd} (${currentUser.timezoneDisplay})`,
      icon: "/favicon.ico",
    };

    return (
      <>
        {!isNotifIgnored && visible && (
          <ReactNotification
            askAgain={false}
            ignore={isNotifIgnored}
            notSupported={this.handleNotSupported}
            onPermissionDenied={this.handlePermissionDenied}
            onPermissionGranted={this.handlePermissionGranted}
            options={options}
            timeout={duration}
            title={formattedTitle}
          />
        )}
        <Transition visible={visible} animation="fade" duration={500}>
          <div className={styles.reminder}>
            <div className={styles.content}>
              <span className={styles.icon}>
                <Icon name="calendar" />
              </span>
              <p>
                <span className={styles.title}>{formattedTitle}</span>
                <span className={styles.time}>
                  {formattedStart} - {formattedEnd}
                </span>
              </p>
            </div>
          </div>
        </Transition>
      </>
    );
  }
}

MeetingCard.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  duration: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
};
