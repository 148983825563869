import PropTypes from "prop-types";
import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Button, Input, Message } from "semantic-ui-react";

import styles from "./styles.module.scss";

class StripeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cardholderName: "",
      coupon: "",
      error: null,
    };
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = async () => {
    const { stripe, onSubmit } = this.props;
    if (stripe) {
      const { cardholderName, coupon } = this.state;
      const { token } = await stripe.createToken({ name: cardholderName });
      if (token && cardholderName) {
        this.setState({ error: null });
        onSubmit(token, coupon);
      } else {
        this.setState({ error: "Card details are required" });
      }
    }
  };

  render() {
    const { cardholderName, coupon, error } = this.state;
    return (
      <React.Fragment>
        {error && <Message error header="Error" content={error} />}
        <Input
          fluid
          placeholder="Name on card"
          name="cardholderName"
          value={cardholderName}
          onChange={this.handleInputChange}
          maxLength="200"
          className={styles.textInput}
        />
        <CardElement className={styles.cardElement} />
        <Input
          fluid
          placeholder="Coupon code (optional)"
          name="coupon"
          value={coupon}
          onChange={this.handleInputChange}
          maxLength="200"
          className={styles.textInput}
        />
        <Button content="Submit" onClick={this.handleSubmit} primary fluid />
      </React.Fragment>
    );
  }
}

StripeForm.propTypes = {
  stripe: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
};

StripeForm.defaultProps = {
  stripe: null,
};

export default injectStripe(StripeForm);
