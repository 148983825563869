import { SKILL, TALENT_SKILL, HIERARCHY } from "../actions/skill";

import { createReducerType } from "./base";

const initialState = {
  loading: false,
  error: false,
  errorDetails: null,
};

export default function skill(state = initialState, action) {
  if (action.type === SKILL.REQUEST || action.type === TALENT_SKILL.REQUEST) {
    return {
      ...state,
      loading: true,
      errorDetails: null,
    };
  }

  if (action.type === SKILL.SUCCESS || action.type === TALENT_SKILL.SUCCESS) {
    return {
      ...state,
      loading: false,
      error: false,
      errorDetails: null,
    };
  }

  if (action.type === SKILL.FAILURE || action.type === TALENT_SKILL.FAILURE) {
    return {
      ...state,
      loading: false,
      error: true,
      errorDetails: action.errorDetails,
    };
  }

  return state;
}

export const hierarchy = createReducerType(HIERARCHY);
