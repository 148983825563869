import { call, fork, put, take } from "redux-saga/effects";

import * as timetracker from "../actions/timetracker";
import { api, token as tokenService } from "../services";

function* fetchWorkLogs(request) {
  const { params } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.fetchWorkLogs, token, params);

  if (response) {
    yield put(timetracker.getWorkLogs.success(response));
  } else {
    yield put(timetracker.getWorkLogs.failure(error));
  }
}

function* exportWorkLogs({ resolve, reject, params }) {
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(api.exportWorkLogs, token, params);

  if (response) {
    yield put(timetracker.exportWorkLogs.success(response));
    if (resolve) resolve(response);
  } else {
    yield put(timetracker.exportWorkLogs.failure({ error, errorDetails }));
    if (reject) reject(errorDetails);
  }
}

export function* watchFetchWorkLogs() {
  while (true) {
    const request = yield take(timetracker.GET_WORK_LOGS.REQUEST);
    yield fork(fetchWorkLogs, request);
  }
}

export function* watchExportWorkLogs() {
  while (true) {
    const request = yield take(timetracker.EXPORT_WORK_LOGS.REQUEST);
    yield fork(exportWorkLogs, request);
  }
}
