import {
  USER,
  UPDATE_USER,
  DELETE_USER,
  BULK_PHONE_NUMBERS_UPDATE,
  BULK_SKILLS_UPDATE,
  CLOSE_NOTIFICATION,
} from "../actions/user";
import { PROJECT_DELETE } from "../actions/project";

const initialState = {
  loading: false,
  error: false,
  errorDetails: null,
  success: false,
  updated: false,
  updateError: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  skillsUpdateLoading: false,
  skillsUpdateSuccess: false,

  phoneNumbersUpdateError: false,
};

export default function user(state = initialState, action) {
  if (action.type === USER.REQUEST) {
    return {
      ...state,
      loading: true,
      error: false,
      errorDetails: null,
      success: false,
      updated: false,
      updateError: false,
      skillsUpdateLoading: false,
      skillsUpdateSuccess: false,
    };
  }

  if (action.type === USER.SUCCESS) {
    return {
      ...state,
      loading: false,
      error: false,
      errorDetails: null,
      success: true,
      updated: false,
      updateError: false,
    };
  }

  if (action.type === USER.FAILURE) {
    return {
      ...state,
      loading: false,
      error: true,
      errorDetails: action.errorDetails,
      success: false,
      updateError: false,
    };
  }

  if (action.type === UPDATE_USER.INIT || action.type === BULK_PHONE_NUMBERS_UPDATE.INIT) {
    return {
      ...state,
      loading: true,
      updated: false,
      error: false,
      updateError: false,
      phoneNumbersUpdateError: false,
    };
  }

  if (action.type === UPDATE_USER.SUCCESS || action.type === BULK_PHONE_NUMBERS_UPDATE.SUCCESS) {
    return {
      ...state,
      loading: false,
      updated: true,
      updateError: false,
      error: false,
      errorDetails: {},
      phoneNumbersUpdateError: false,
    };
  }

  if (action.type === UPDATE_USER.FAILURE) {
    return {
      ...state,
      updated: false,
      updateError: true,
      loading: false,
      error: true,
      errorDetails: action.errorDetails,
    };
  }

  if (action.type === DELETE_USER.INIT || action.type === PROJECT_DELETE.REQUEST) {
    return {
      ...state,
      deleteLoading: true,
      deleteSuccess: false,
      deleteError: false,
    };
  }

  if (action.type === DELETE_USER.SUCCESS || action.type === PROJECT_DELETE.SUCCESS) {
    return {
      ...state,
      deleteLoading: false,
      deleteSuccess: true,
      deleteError: false,
    };
  }

  if (action.type === DELETE_USER.FAILURE) {
    return {
      ...state,
      deleteLoading: false,
      deleteSuccess: false,
      deleteError: true,
    };
  }

  if (action.type === BULK_SKILLS_UPDATE.INIT) {
    return {
      ...state,
      skillsUpdateLoading: true,
      skillsUpdateSuccess: false,
    };
  }

  if (action.type === BULK_SKILLS_UPDATE.SUCCESS) {
    return {
      ...state,
      skillsUpdateLoading: false,
      skillsUpdateSuccess: true,
    };
  }

  if (action.type === CLOSE_NOTIFICATION) {
    return initialState;
  }

  if (action.type === BULK_PHONE_NUMBERS_UPDATE.FAILURE) {
    // bulkPhoneNumbersUpdate returns an array for errorDetails
    // but component expects an object
    const errorDetails = {
      phone_numbers: action.errorDetails,
    };

    return {
      ...state,
      updated: false,
      updateError: true,
      phoneNumbersUpdateError: true,
      loading: false,
      error: true,
      errorDetails,
    };
  }

  return state;
}
