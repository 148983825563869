import { createRequestTypes, action } from ".";

export const CALENDAR = createRequestTypes("CALENDAR");
export const EVENT = createRequestTypes("EVENT");
export const EVENT_DETAIL = createRequestTypes("EVENT_DETAIL");
export const EVENT_OCCURRENCE_DETAIL = createRequestTypes("EVENT_OCCURRENCE_DETAIL");
export const EVENT_DELETE = createRequestTypes("EVENT_DELETE");
export const CREATE_EVENT = "CREATE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const CLEAN_EVENT_OCCURRENCES = "CLEAN_EVENT_OCCURRENCES";
export const ADD_GOOGLE_CALENDAR = "ADD_GOOGLE_CALENDAR";
export const ADD_OUTLOOK_CALENDAR = "ADD_OUTLOOK_CALENDAR";
export const CLEAN_GOOGLE_EVENT_OCCURRENCES = "CLEAN_GOOGLE_EVENT_OCCURRENCES";
export const CLEAN_OUTLOOK_EVENT_OCCURRENCES = "CLEAN_OUTLOOK_EVENT_OCCURRENCES";
export const RESET_EVENT_OCCURRENCES = "RESET_EVENT_OCCURRENCES";
export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";

export const calendar = {
  request: (refresh) => action(CALENDAR.REQUEST, { refresh }),
  success: (response) => action(CALENDAR.SUCCESS, { response }),
  failure: (error, errorDetails) => action(CALENDAR.FAILURE, { error, errorDetails }),
};
export const event = {
  request: (refresh) => action(EVENT.REQUEST, { refresh }),
  success: (response) => action(EVENT.SUCCESS, { response }),
  failure: (error, errorDetails) => action(EVENT.FAILURE, { error, errorDetails }),
};

export const eventDetail = {
  request: (id) => action(EVENT_DETAIL.REQUEST, { id }),
  success: (response) => action(EVENT_DETAIL.SUCCESS, { response }),
  failure: (error, errorDetails) => action(EVENT_DETAIL.FAILURE, { error, errorDetails }),
};

export const eventOccurrenceDetail = {
  request: (occurrenceId, params = { projectId: 0 }, resolve = null, reject = null) =>
    action(EVENT_OCCURRENCE_DETAIL.REQUEST, { occurrenceId, params, resolve, reject }),
  success: (response, occurrenceId) =>
    action(EVENT_OCCURRENCE_DETAIL.SUCCESS, { response, occurrenceId }),
  failure: (error, errorDetails) =>
    action(EVENT_OCCURRENCE_DETAIL.FAILURE, { error, errorDetails }),
};

export const eventDelete = {
  request: (id, slug, params = {}, resolve = null, reject = null, isWorkLog = false) =>
    action(EVENT_DELETE.REQUEST, { id, slug, params, resolve, reject, isWorkLog }),
  success: (response) => action(EVENT_DELETE.SUCCESS, { response }),
  failure: (error, errorDetails) => action(EVENT_DELETE.FAILURE, { error, errorDetails }),
};

export const loadUserCalendar = (slug, refresh, params = {}) =>
  action(CALENDAR.INIT, { slug, params, refresh });
export const createEvent = (slug, data, resolve, reject, params, isWorkLog = false) =>
  action(CREATE_EVENT, { slug, data, resolve, reject, params, isWorkLog });
export const updateEvent = (id, data, slug, resolve, reject, params, isWorkLog = false) =>
  action(UPDATE_EVENT, { id, data, slug, resolve, reject, params, isWorkLog });
export const cleanEventOccurrences = (slug, ids, deleted = false) =>
  action(CLEAN_EVENT_OCCURRENCES, { slug, ids, deleted });
export const resetEventOccurrences = (slug) => action(RESET_EVENT_OCCURRENCES, { slug });

export const addGoogleCalendar = (ids) => action(ADD_GOOGLE_CALENDAR, { ids });
export const addOutlookCalendar = (ids) => action(ADD_OUTLOOK_CALENDAR, { ids });
export const cleanGoogleEventOccurrences = (slug, ids, deleted) =>
  action(CLEAN_GOOGLE_EVENT_OCCURRENCES, { slug, ids, deleted });
export const cleanOutlookEventOccurrences = (slug, ids, deleted) =>
  action(CLEAN_OUTLOOK_EVENT_OCCURRENCES, { slug, ids, deleted });

export const setSelectedEvent = (eventData) => action(SET_SELECTED_EVENT, { eventData });
