import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink as Link, withRouter } from "react-router-dom";
import { Grid, Header, Form, Button, Message } from "semantic-ui-react";

import { resetErrorMessage } from "actions";
import { passwordResetCheck, passwordResetConfirm } from "actions/account";
import PasswordStrengthMeter from "components/PasswordStrengthMeter";

import * as styles from "./styles.module.scss";

class Confirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      password2: "",
    };
  }

  componentDidMount() {
    const { uid, token } = this.props.match.params;
    this.props.passwordResetCheckInit(uid, token);
  }

  isPasswordSame = () => {
    const { password, password2 } = this.state;
    return password && password2 && password === password2;
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    const { uid, token } = this.props.match.params;
    const { password, password2 } = this.state;
    this.props.resetErrorMessage();
    this.props.passwordResetConfirmInit(uid, token, password, password2);
  };

  renderForm() {
    const { errorMessage, loading } = this.props;
    const { password, password2 } = this.state;
    return (
      <Grid.Row>
        <Grid.Column computer={8} mobile={16}>
          <p>Enter your new password below.</p>

          {errorMessage && <Message error header="Error" content={this.props.errorMessage} />}

          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Input
              name="password"
              label="Password"
              value={password}
              type="password"
              onChange={this.handleChange}
            />
            <PasswordStrengthMeter password={password} />
            <Form.Input
              name="password2"
              label="Password (once more)"
              value={password2}
              type="password"
              onChange={this.handleChange}
            />
            <Button type="submit" disabled={!this.isPasswordSame()}>
              Update Password
            </Button>
          </Form>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderCheck() {
    const { errorMessage, loading } = this.props;
    return (
      <Grid.Row>
        <Grid.Column computer={8} mobile={16}>
          {loading && <p>Please wait&hellip;</p>}
          {errorMessage && (
            <div>
              <Message error header="Error" content="Your password reset link has expired." />
              <p>
                <Link to="/">Go back</Link>
              </p>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    const { validLink } = this.props;

    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2" content="Reset your password" className={styles.header} />
          </Grid.Column>
        </Grid.Row>
        {!validLink && this.renderCheck()}
        {validLink && this.renderForm()}
      </Grid>
    );
  }
}

Confirm.propTypes = {
  passwordResetConfirmInit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  validLink: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    errorMessage,
    passwordResetConfirm: { loading, validLink },
  } = state;
  return {
    errorMessage,
    loading,
    validLink,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    passwordResetCheckInit: passwordResetCheck.init,
    passwordResetConfirmInit: passwordResetConfirm.init,
    resetErrorMessage,
  })(Confirm)
);
