import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import config from '../../config';

function offlineResponse() {
  return { offline: 'You are offline' };
}

export function getFullUrl(endpoint) {
  if (endpoint.indexOf('https://') >= 0 || endpoint.indexOf('http://') >= 0) {
    return endpoint;
  }
  return endpoint.indexOf(config.apiBaseUrl) === -1 ? `${config.apiBaseUrl}/${endpoint}` : endpoint;
}

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export function callApi(endpoint, entitySchema, method = 'GET', headers = {}, body = '') {
  const fullUrl = getFullUrl(endpoint);
  const params = {
    method,
    credentials: 'include',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      ...headers,
    },
  };

  if (method === 'PATCH' || method === 'POST' || method === 'PUT') {
    params.body = body;
  }

  return fetch(fullUrl, params)
    .then((r) =>
      Promise.resolve(r)
        .then((response) => {
          // If the API did not respond, or responded with a 500+ error,
          // assume that the API is offline.
          if (response.status < 100 || response.status >= 500) {
            return Promise.reject(offlineResponse());
          }
          return response;
        })
        .then(
          (response) => {
            if (response.status === 204) {
              return Promise.resolve({ json: {}, response });
            }
            if (response.headers.get('Content-Type') !== 'application/json') {
              return response.blob().then((blob) => ({ blob, response }));
            }
            return response.json().then((json) => ({ json, response }));
          },
          ({ offline }) => Promise.reject({ offline })
        )
        .then(
          ({ blob, json, response }) => {
            if (!response.ok) {
              return Promise.reject(json);
            }
            if (blob) {
              return blob;
            }

            const camelizedJson = camelizeKeys(json);
            const { count, next, previous, results, ...otherProps } = camelizedJson;
            const normalizedJson = entitySchema
              ? normalize(camelizedJson.results || camelizedJson, entitySchema)
              : camelizedJson;

            if (camelizedJson.next === undefined || camelizedJson.count === undefined) {
              return normalizedJson;
            }

            return Object.assign({}, normalizedJson, {
              nextPage: camelizedJson.next,
              previousPage: camelizedJson.previous,
              count: camelizedJson.count,
              ...otherProps,
            });
          },
          ({ offline }) => Promise.reject({ offline })
        )
        .then(
          (response) => ({ response }),
          ({ offline, ...error }) => {
            if (offline) {
              return { error: offline };
            }
            if (error.errno === 'EAI_AGAIN' || Object.keys(error) === 0) {
              return { error: offlineResponse() };
            }
            return {
              error: error.message || error.detail || 'Something bad happened.',
              errorDetails: error,
            };
          }
        )
    )
    .catch(() => ({ error: offlineResponse() }));
}

export function callApiWithToken(
  endpoint,
  token,
  entitySchema,
  method = 'GET',
  headers = {},
  body = ''
) {
  const tokenHeaders = { ...headers };
  if (token) {
    tokenHeaders.Authorization = `Token ${token}`;
  }
  return callApi(endpoint, entitySchema, method, tokenHeaders, body);
}
