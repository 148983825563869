import React from "react";
import PropTypes from "prop-types";
import { Button, Divider, Form, Header, Modal } from "semantic-ui-react";

import styles from "./styles.module.scss";

export default class ModalRename extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      header: props.roomName,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.header && nextProps.roomName) {
      return {
        header: nextProps.roomName,
      };
    }
    return null;
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleMount = () => {
    this.setState({ header: this.props.roomName });
  };

  handleSubmit = (e) => {
    const { header } = this.state;
    e.preventDefault();

    if (header.length > 0) {
      this.props.onRename(header);
      this.props.onClose();
    }
  };

  render() {
    const { onClose, onRename, open, trigger, roomName, ...props } = this.props;
    const { header } = this.state;

    return (
      <Modal
        basic
        centered={false}
        className={styles.modalRename}
        closeIcon="close"
        dimmer="inverted"
        open={open}
        onClose={onClose}
        onMount={this.handleMount}
        trigger={trigger}
        {...props}
      >
        <Header
          className={styles.modalRenameHeader}
          icon="text cursor"
          content={
            <span>
              Rename <em>{roomName || "Channel"}</em>
            </span>
          }
        />
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <p>What do you want to rename this channel into?</p>
            <Divider hidden />
            <Form.Input
              placeholder="Display name"
              error={header.length <= 0}
              name="header"
              value={header}
              onChange={this.handleChange}
              maxLength={255}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="green"
            type="submit"
            disabled={header.length <= 0}
            onClick={this.handleSubmit}
            icon="checkmark"
            content="Rename"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalRename.propTypes = {
  trigger: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  roomName: PropTypes.string,
};

ModalRename.defaultProps = {
  roomName: "",
};
