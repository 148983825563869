import moment from "moment-timezone";
import React, { useCallback } from "react";
import { batch, connect } from "react-redux";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import { attachment } from "actions/attachments";

const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: #dddddd;
  display: flex;
  font-size: 32px;
  left: 0;
  line-height: 1.75;
  padding: 2.5em 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 99;
`;

const OverlayBody = styled.div`
  flex: 1;
`;

const Attachment = ({ attachmentInit, cacheId, children, className, files, generic, style }) => {
  const handleDrop = (acceptedFiles) => {
    batch(() =>
      acceptedFiles.forEach((file) => attachmentInit(cacheId, { name: file.name, data: file }))
    );
  };

  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    noKeyboard: true,
    noClick: true,
  });

  const handlePaste = useCallback(
    (event) => {
      const { items } = event.clipboardData || { items: {} };

      // find pasted image among pasted items
      let blob = null;
      Object.keys(items).forEach((key) => {
        if (items[key].type.indexOf("image") === 0) {
          blob = items[key].getAsFile();
        }
      });
      // load image if there is a pasted image
      if (blob !== null) {
        attachmentInit(
          cacheId,
          {
            name: `Image Pasted at ${moment().format("YYYY MM DD HH-MM")}.png`,
            data: blob,
          },
          generic
        );
      }
    },
    [attachmentInit, cacheId, generic]
  );

  return (
    <div className={className} style={style || { position: "relative" }} {...getRootProps()}>
      {isDragActive && (
        <Overlay>
          <OverlayBody>
            <Icon name="attach" size="huge" circular />
            <br />
            Drop files
          </OverlayBody>
        </Overlay>
      )}

      <input {...getInputProps()} />
      {children({
        attachmentInit,
        cacheId,
        dropzoneOnPaste: handlePaste,
        dropzoneOpen: open,
        files,
      })}
    </div>
  );
};

Attachment.propTypes = {
  attachmentInit: PropTypes.func.isRequired,
  cacheId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  generic: PropTypes.bool,
  style: PropTypes.shape(),
  files: PropTypes.objectOf(PropTypes.shape()),
};

function mapStateToProps(state, props) {
  const files = state.attachments[props.cacheId] || {};

  return {
    files,
  };
}

export default connect(mapStateToProps, { attachmentInit: attachment.init })(Attachment);
