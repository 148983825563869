import { decamelizeKeys } from 'humps';
import { callApiWithToken } from './base';

export const fetchSubscriptions = (token) => callApiWithToken('subscription/', token);
export const subscribe = (token, data = {}) =>
  callApiWithToken(
    'subscription/subscribe/',
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const unsubscribe = (token, data = {}, undo = false) =>
  callApiWithToken(
    undo ? 'subscription/unsubscribe/?undo=undo' : 'subscription/unsubscribe/',
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const fetchCustomer = (token) => callApiWithToken('subscription/customer/', token);
export const fetchPlans = (token) => callApiWithToken('subscription/plans/', token);
