import { keys, pick, assign } from "lodash";
import {
  AUTH_LOAD_TOKEN,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REGISTER,
  AUTH_PASSWORD_RESET,
  AUTH_PASSWORD_RESET_CHECK,
  AUTH_PASSWORD_RESET_CONFIRM,
  AUTH_PASSWORD_CHANGE,
  TEAM_INVITATION,
  PASSWORDLESS_GET_TOKEN,
  PASSWORDLESS_LOGIN,
  LOGIN_MODAL_OPEN,
  LOGIN_MODAL_CLOSE,
  LOGIN_MODAL_SET_EXPIRATION,
} from "../actions/account";
import { SUBSCRIPTIONS, SUBSCRIBE, RESUBSCRIBE, UNSUBSCRIBE } from "../actions/subscription";
import { USER, UPDATE_USER } from "../actions/user";
import { NAVIGATE } from "../actions/index";

export function auth(
  state = {
    initialLoad: false,
    loading: false,
    token: null,
    user: null,
    errors: null,
    isLoginModalOpen: false,
  },
  action
) {
  const { type, response, error, errorDetails } = action;

  switch (type) {
    case AUTH_LOAD_TOKEN:
      return { ...state, loading: false, initialLoad: true };

    case AUTH_LOGIN.REQUEST:
    case PASSWORDLESS_GET_TOKEN.REQUEST:
    case PASSWORDLESS_LOGIN.REQUEST:
    case AUTH_LOGOUT.REQUEST:
    case AUTH_REGISTER.REQUEST:
      return { ...state, loading: true, errors: null };

    case AUTH_LOGIN.SUCCESS: {
      const { token, key, user } = response;
      return { ...state, loading: false, token: key || token, user };
    }
    case PASSWORDLESS_LOGIN.SUCCESS: {
      const { token, key, user, doSignout } = response;
      if (doSignout) {
        return { ...state, loading: false };
      }
      return { ...state, loading: false, token: key || token, user };
    }
    case PASSWORDLESS_GET_TOKEN.SUCCESS:
      return { ...state, loading: false, errors: null };

    case AUTH_LOGIN.FAILURE:
    case PASSWORDLESS_GET_TOKEN.FAILURE:
    case PASSWORDLESS_LOGIN.FAILURE:
      return { ...state, loading: false, token: null, user: null, errors: errorDetails };

    case AUTH_LOGOUT.SUCCESS:
      return { ...state, loading: false, token: null, user: null };

    case AUTH_REGISTER.SUCCESS:
    case NAVIGATE:
      return { ...state, loading: false, errors: null };

    case AUTH_REGISTER.FAILURE:
      return { ...state, loading: false, errors: errorDetails };

    case USER.SUCCESS:
    case UPDATE_USER.SUCCESS:
      if (action.response && action.response.entities && state.user) {
        const { slug } = state.user;
        const updatedUser = action.response.entities.users[slug];
        const updated = assign({}, state.user, pick(updatedUser, keys(state.user)));
        return { ...state, user: updated };
      }
      return state;

    // update user subscription status
    case SUBSCRIPTIONS.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isSubscribed:
            !!action.response &&
            (action.response.exempt ||
              (!!action.response.active &&
                !!action.response.active.length &&
                (action.response.active[0].status === "trialing" ||
                  action.response.active[0].status === "active" ||
                  action.response.active[0].latestInvoice.status === "paid"))),
        },
      };
    case SUBSCRIBE.SUCCESS:
    case RESUBSCRIBE.SUCCESS:
    case UNSUBSCRIBE.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isSubscribed:
            !!action.response &&
            (action.response.status === "trialing" ||
              action.response.status === "active" ||
              action.response.latestInvoice.status === "paid"),
        },
      };

    case LOGIN_MODAL_OPEN:
      return { ...state, isLoginModalOpen: true };
    case LOGIN_MODAL_CLOSE:
    case LOGIN_MODAL_SET_EXPIRATION.SUCCESS:
      return { ...state, isLoginModalOpen: false };

    default:
      if (error === "Subscription required") {
        return {
          ...state,
          user: {
            ...state.user,
            isSubscribed: false,
          },
        };
      }
      return state;
  }
}

export function passwordReset(
  state = {
    loading: false,
  },
  action
) {
  const { type } = action;

  switch (type) {
    case AUTH_PASSWORD_RESET.REQUEST:
      return { ...state, loading: true };
    case AUTH_PASSWORD_RESET.SUCCESS:
    case AUTH_PASSWORD_RESET.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export function passwordResetConfirm(
  state = {
    loading: false,
    validLink: false,
  },
  action
) {
  const { type } = action;

  switch (type) {
    case AUTH_PASSWORD_RESET_CHECK.REQUEST:
      return { ...state, loading: true, validLink: false };
    case AUTH_PASSWORD_RESET_CHECK.SUCCESS:
      return { ...state, loading: false, validLink: true };
    case AUTH_PASSWORD_RESET_CHECK.FAILURE:
      return { ...state, loading: false, validLink: false };

    case AUTH_PASSWORD_RESET_CONFIRM.REQUEST:
      return { ...state, loading: true };
    case AUTH_PASSWORD_RESET_CONFIRM.SUCCESS:
    case AUTH_PASSWORD_RESET_CONFIRM.FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export function isAuthenticating(state = false, action) {
  const { type } = action;

  switch (type) {
    case AUTH_LOGIN.REQUEST:
    case PASSWORDLESS_LOGIN.REQUEST:
      return true;

    case AUTH_LOGIN.SUCCESS:
    case AUTH_LOGIN.FAILURE:
    case PASSWORDLESS_LOGIN.SUCCESS:
    case PASSWORDLESS_LOGIN.FAILURE:
      return false;

    default:
      return state;
  }
}

export function isAuthenticated(state = false, action) {
  const { type } = action;

  switch (type) {
    case AUTH_LOGIN.SUCCESS:
    case AUTH_REGISTER.SUCCESS:
      return true;

    case PASSWORDLESS_LOGIN.SUCCESS: {
      const { response } = action;
      const { doSignout } = response;
      if (doSignout) {
        return false;
      }
      return true;
    }

    case AUTH_LOGIN.FAILURE:
    case PASSWORDLESS_LOGIN.FAILURE:
    case AUTH_LOGOUT.SUCCESS:
      return false;

    default:
      return state;
  }
}

export function passwordChange(
  state = {
    formLoading: false,
    success: false,
    errorDetails: {},
    message: "",
  },
  action
) {
  const { type, response, errorDetails } = action;

  switch (type) {
    case AUTH_PASSWORD_CHANGE.REQUEST:
      return { ...state, formLoading: true, success: false };
    case AUTH_PASSWORD_CHANGE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        success: true,
        errorDetails: {},
        message: response.detail,
      };
    case AUTH_PASSWORD_CHANGE.FAILURE:
      return { ...state, errorDetails, formLoading: false, message: "", success: false };
    default:
      return state;
  }
}

export function teamInvitation(
  state = {
    loading: false,
    message: "",
  },
  action
) {
  const { type, response, error } = action;

  switch (type) {
    case TEAM_INVITATION.REQUEST:
      return { ...state, loading: true };
    case TEAM_INVITATION.SUCCESS:
      return { ...state, loading: false, message: response.detail };
    case TEAM_INVITATION.FAILURE:
      return { ...state, loading: false, message: error };
    default:
      return state;
  }
}
