import { action, createRequestTypes } from ".";

export const ADD_REMINDERS = "ADD_REMINDERS";
export const REMOVE_REMINDER = "REMOVE_REMINDER";
export const UPDATE_REMINDER = createRequestTypes("UPDATE_REMINDER");

export const addReminders = (data) => action(ADD_REMINDERS, { data });
export const removeReminder = (id) => action(REMOVE_REMINDER, { id });

export const updateReminder = {
  init: (id, data) => action(UPDATE_REMINDER.INIT, { id, data }),
  request: () => action(UPDATE_REMINDER.REQUEST),
  success: (response) => action(UPDATE_REMINDER.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(UPDATE_REMINDER.FAILURE, { error, errorDetails }),
};
