/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import classnames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Form, Input } from "semantic-ui-react";

import DateTimePicker from "components/DateTimePicker";
import styles from "./styles.module.scss";

export default class RecurrenceCustomEnd extends React.PureComponent {
  onRadioSelect = (e, value, end, count) => {
    const { onChange } = this.props;
    const endDate = end.endOf("day").toISOString();
    switch (value) {
      case 0: {
        onChange(e, { endRecurringPeriod: null, count: "" });
        break;
      }
      case 1: {
        onChange(e, { endRecurringPeriod: endDate, count: "" });
        break;
      }
      default: {
        onChange(e, { endRecurringPeriod: null, count: count.toString() });
        break;
      }
    }
  };

  onChange = (e, { name, value }) => {
    const { onChange, startDate } = this.props;
    let newValue = value;
    if (name === "count") {
      if (value < 1) {
        newValue = 1;
      }
      newValue = newValue.toString();
    } else if (name === "endRecurringPeriod") {
      if (value.isBefore(startDate)) {
        newValue = moment(startDate).add(1, "days");
      }
      newValue = newValue.endOf("day").toISOString();
    }
    onChange(e, { [name]: newValue });
  };

  render() {
    const { count, endRecurringPeriod, startDate, disabled } = this.props;
    const endRecurringPeriodDate = endRecurringPeriod
      ? moment(endRecurringPeriod)
      : moment(startDate).add(3, "months");
    const intCount = count ? parseInt(count, 10) : 10;

    return (
      <div className={classnames(styles.customModalSection, styles.customModalEnd)}>
        <div>
          <FormattedMessage defaultMessage="Ends" id="RecurrenceInput.modalId.end" />
        </div>
        <div>
          <Form.Radio
            checked={!count && !endRecurringPeriod}
            label={
              <label>
                <FormattedMessage defaultMessage="Never" id="RecurrenceInput.modalId.endnever" />
              </label>
            }
            name="end"
            onChange={(e) => this.onRadioSelect(e, 0, endRecurringPeriodDate, intCount)}
            disabled={disabled}
          />
        </div>
        <div>
          <div className={styles.radioFloat}>
            <Form.Radio
              checked={!!endRecurringPeriod}
              label={
                <label>
                  <FormattedMessage defaultMessage="On" id="RecurrenceInput.modalId.endon" />
                </label>
              }
              name="end"
              onChange={(e) => this.onRadioSelect(e, 1, endRecurringPeriodDate, intCount)}
              disabled={disabled}
            />
          </div>
          <div className={styles.radioFloat}>
            <DateTimePicker
              date={endRecurringPeriodDate.format()}
              disabled={!endRecurringPeriod || disabled}
              hideTime
              minDate={startDate}
              name="endRecurringPeriod"
              required
              small
              onChange={this.onChange}
            />
          </div>
        </div>
        <div>
          <div className={styles.radioFloat}>
            <Form.Radio
              checked={!!count}
              label={
                <label>
                  <FormattedMessage defaultMessage="After" id="RecurrenceInput.modalId.endafter" />
                </label>
              }
              name="end"
              onChange={(e) => this.onRadioSelect(e, 2, endRecurringPeriodDate, intCount)}
              disabled={disabled}
            />
          </div>
          <div className={styles.radioFloat}>
            <Input
              disabled={!count || disabled}
              label={{ basic: true, content: "occurrences" }}
              labelPosition="right"
              min="1"
              name="count"
              onChange={this.onChange}
              type="number"
              value={intCount || 15}
            />
          </div>
        </div>
      </div>
    );
  }
}

RecurrenceCustomEnd.propTypes = {
  count: PropTypes.string,
  endRecurringPeriod: PropTypes.string,
  startDate: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
};
