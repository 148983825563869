import { isEqual } from "lodash";
import { SIDE_PANEL } from "actions/sidePanel";

const defaultState = {
  panelId: null,
  visible: false,
  meta: null,
};

const sidePanel = (state = defaultState, action) => {
  const { panelId, meta } = action;
  switch (action.type) {
    case SIDE_PANEL.CLOSE:
      return { visible: false, meta: null };

    case SIDE_PANEL.OPEN:
      return { panelId, visible: true, meta };

    case SIDE_PANEL.TOGGLE:
      if (panelId === state.panelId && isEqual(meta, state.meta)) {
        return { panelId: null, visible: false, meta: null };
      }
      return { panelId, visible: true, meta };

    default:
      return state;
  }
};

export default sidePanel;
