import React from "react";
import PropTypes from "prop-types";
import { Step, Responsive } from "semantic-ui-react";
import STEPS from "./steps.json";

const Steps = ({ step, setStep }) => (
  <Responsive as={Step.Group} fluid ordered {...Responsive.onlyComputer}>
    {STEPS.map((stepObject, i) => (
      <Step key={i} active={step === i} completed={step > i}>
        <Step.Content>
          <Step.Title>
            {i >= step && stepObject.title}
            {i < step && (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setStep(i);
                }}
              >
                {stepObject.title}
              </a>
            )}
          </Step.Title>
        </Step.Content>
      </Step>
    ))}
  </Responsive>
);

Steps.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default Steps;
