import { call, put } from "redux-saga/effects";

export function* fetchEntity(entity, apiFn, refresh, token, id, url) {
  yield put(entity.request(refresh));
  const { response, error, errorDetails } = yield call(apiFn, token, url || id || null);
  if (response) {
    yield put(entity.success(response));
  } else {
    yield put(entity.failure({ error, errorDetails }));
  }
  return response;
}

export function* fetchEntityQS(entity, apiFn, refresh, token, id, url, qs) {
  yield put(entity.request(refresh));
  const { response, error, errorDetails } = yield call(apiFn, token, url || id || null, qs);
  if (response) {
    yield put(entity.success(response));
  } else {
    yield put(entity.failure({ error, errorDetails }));
  }
  return response;
}

export function* pushEntity(entity, apiFn, refresh, token, id, url, data) {
  yield put(entity.request(refresh));
  const { response, error, errorDetails } = yield call(apiFn, token, url || id || null, data);
  if (response) {
    yield put(entity.success(response));
  } else {
    yield put(entity.failure({ error, errorDetails }));
  }
  return response;
}
