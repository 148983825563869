import React from "react";
import PropTypes from "prop-types";
import ImageLoader from "components/ImageLoader";
import { getFullUrl } from "services/api/base";

export default class ImageRenderer extends React.PureComponent {
  transformSrc = () => {
    const { src } = this.props;
    if (
      src.indexOf("https://") < 0 &&
      src.indexOf("http://") < 0 &&
      src.indexOf("/api/v1/") === 0
    ) {
      return getFullUrl(src.substring("/api/v1/".length));
    }
    return src;
  };

  render() {
    const { src, node, ...props } = this.props;
    const transformedSrc = this.transformSrc();
    return <ImageLoader inline src={transformedSrc} {...props} />;
  }
}

ImageRenderer.propTypes = {
  node: PropTypes.shape(),
  src: PropTypes.string.isRequired,
};
