import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import { pollDelete } from "actions/chat";
import { pollType } from "types/poll";

import styles from "./styles.module.scss";

class ModalPollDelete extends React.PureComponent {
  handleDelete = () => {
    const { onClose, poll } = this.props;
    onClose();
    this.props.pollDelete(poll);
  };

  render() {
    const { onClose, onOpen, open } = this.props;

    return (
      <Modal onClose={onClose} onOpen={onOpen} open={open} className={styles.deleteModal} closeIcon>
        <Modal.Content className={styles.modalContent}>
          <p className={styles.heading}>Delete poll</p>
          <p className={styles.content}>You are about to permanently delete this poll.</p>
          <div className={styles.actionsContainer}>
            <Button
              className={classnames(styles.action, styles.delete)}
              onClick={this.handleDelete}
            >
              Yes, delete poll
            </Button>
            <Button className={classnames(styles.action, styles.cancel)} onClick={onClose}>
              Cancel, do not delete
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

ModalPollDelete.propTypes = {
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  poll: pollType.isRequired,
  open: PropTypes.bool,

  pollDelete: PropTypes.func.isRequired,
};

ModalPollDelete.defaultProps = {
  onClose: () => {},
  onOpen: () => {},
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {
  pollDelete: pollDelete.request,
})(ModalPollDelete);
