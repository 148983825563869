import React from "react";
import { Button, Icon } from "semantic-ui-react";
import config from "config";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/twitter/login/`;

const TwitterButton = (props) => (
  <Button color="twitter" fluid onClick={() => (window.location.href = LOGIN_URL)} {...props}>
    <Icon name="twitter" />
    Twitter
  </Button>
);

export default TwitterButton;
