import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Shortcuts, ShortcutManager } from "react-shortcuts";
import scrollIntoView from "scroll-into-view-if-needed";

import { newMessageModal, newProjectModal, newTeamModal, shortcutsHelpModal } from "actions/modals";
import { sidebar } from "actions/sidebar";
import config from "config";
import {
  getNextRoom,
  getPreviousRoom,
  getNextUnreadRoom,
  getPreviousUnreadRoom,
} from "reducers/selectors";
import keyMap from "utils/keyMap";

const shortcutManager = new ShortcutManager(keyMap);

interface Props {
  isSubscribed: boolean;
  nextRoom: Pending | null;
  nextUnreadRoom: Pending | null;
  previousRoom: Pending | null;
  previousUnreadRoom: Pending | null;

  newMessageModalShow: () => void;
  newProjectModalShow: () => void;
  newTeamModalShow: () => void;
  shortcutsHelpModalToggle: () => void;
  sidebarOpen: () => void;
  sidebarClose: () => void;
}

class GlobalShortcuts extends React.Component<Props> {
  getChildContext() {
    return { shortcuts: shortcutManager };
  }

  componentDidMount() {
    // Undo the tabindex inserted by react-shortcuts.
    // The tabindex prevents scrollable containers from being scrolled.
    document.body.removeAttribute("tabindex");
    const shortcuts = document.querySelector("#root > shortcuts");
    if (shortcuts) {
      shortcuts.removeAttribute("tabindex");
    }
  }

  _getPreviousApp = () => {
    const activeApp = document.querySelector("#appSelector .active");
    if (!activeApp) {
      return null;
    }

    let previous = activeApp.previousSibling as Element;
    if (previous && previous.classList.contains("skip")) {
      previous = previous.previousSibling as Element;
    }
    if (previous) {
      return previous;
    }
    return document.getElementById("appSelector")!.firstChild!.lastChild;
  };

  _getNextApp = () => {
    const activeApp = document.querySelector("#appSelector .active");
    if (!activeApp) {
      return null;
    }

    let next = activeApp.nextSibling as Element;
    if (next && next.classList.contains("skip")) {
      next = next.nextSibling as Element;
    }
    if (next) {
      return next;
    }
    return document.getElementById("appSelector")!.firstChild!.firstChild;
  };

  getFollowingApp = (direction: number) => {
    const segment = window.location.href.split("/");
    let valid = false;
    segment.forEach((url) => {
      if (url === "chat") {
        valid = true;
      }
    });

    if (valid) {
      const id = ["appChat", "appPlanning", "appPoll", "appMeeting", "appFeedback", "appSettings"];
      const currentUrl = segment[segment.length - 1];
      let nextId = 0;

      switch (currentUrl) {
        case "planning":
          nextId = 1;
          break;
        case "polls":
          nextId = 2;
          break;
        case "meetings":
          nextId = 3;
          break;
        case "client-feedback":
          nextId = 4;
          break;
        case "settings":
          nextId = 5;
          break;
        default:
          nextId = 0;
      }

      while (true) {
        nextId += direction;

        if (nextId < 0) {
          nextId = 5;
        } else if (nextId > 5) {
          nextId = 0;
        }

        const page = document.getElementById(id[nextId]);
        if (page) {
          page.click();
          break;
        }
      }
    }
  };

  handleShortcuts = (action: string, event: KeyboardEvent) => {
    if (this.props.isSubscribed) {
      switch (action) {
        case "APP_CHAT": {
          if (!config.flags.chat) break;
          event.preventDefault();
          event.stopPropagation();
          const appChat = document.getElementById("appChat");
          if (appChat) appChat.click();
          break;
        }
        case "APP_PLANNING": {
          event.preventDefault();
          event.stopPropagation();
          const appPlanning = document.getElementById("appPlanning");
          if (appPlanning) appPlanning.click();
          break;
        }
        case "APP_CALL": {
          if (!config.flags.call) break;
          event.preventDefault();
          event.stopPropagation();
          const appCall = document.getElementById("appCall");
          if (appCall) appCall.click();
          break;
        }
        case "APP_MEETING": {
          if (!config.flags.meeting) break;
          event.preventDefault();
          event.stopPropagation();
          const appMeeting = document.getElementById("appMeeting");
          if (appMeeting) appMeeting.click();
          break;
        }
        case "APP_POLL": {
          if (!config.flags.poll) break;
          event.preventDefault();
          event.stopPropagation();
          const appPoll = document.getElementById("appPoll");
          if (appPoll) appPoll.click();
          break;
        }
        case "APP_FEEDBACK": {
          if (!config.flags.clientFeedback) break;
          event.preventDefault();
          event.stopPropagation();
          const appFeedback = document.getElementById("appFeedback");
          if (appFeedback) appFeedback.click();
          break;
        }
        case "APP_HEALTH": {
          if (!config.flags.health) break;
          event.preventDefault();
          event.stopPropagation();
          const appHealth = document.getElementById("appHealth");
          if (appHealth) appHealth.click();
          break;
        }
        case "APP_ABOUT": {
          event.preventDefault();
          event.stopPropagation();
          const appAbout = document.getElementById("appAbout");
          if (appAbout) appAbout.click();
          break;
        }
        case "APP_SETTINGS": {
          event.preventDefault();
          event.stopPropagation();
          const appSettings = document.getElementById("appSettings");
          if (appSettings) appSettings.click();
          break;
        }
        case "APP_PREVIOUS": {
          event.preventDefault();
          event.stopPropagation();
          this.getFollowingApp(-1);
          break;
        }
        case "APP_NEXT": {
          event.preventDefault();
          event.stopPropagation();
          this.getFollowingApp(1);
          break;
        }
        case "SEARCH_FOCUS": {
          event.preventDefault();
          event.stopPropagation();
          const sidebarSearch = document.querySelector<HTMLInputElement>("#sidebarSearch");
          if (sidebarSearch) {
            if (document.body.clientWidth <= 990) {
              this.props.sidebarOpen();
            }

            window.setTimeout(() => {
              sidebarSearch.focus();
              sidebarSearch.select();
            });
          }
          break;
        }
        case "CHAT_FOCUS": {
          event.preventDefault();
          event.stopPropagation();
          this.props.sidebarClose();
          const chatMessageBox = document.querySelector<HTMLTextAreaElement>("#chatMessageBox");
          if (chatMessageBox) chatMessageBox.focus();
          break;
        }
        case "NEXT_ROOM": {
          event.preventDefault();
          event.stopPropagation();
          const { nextRoom } = this.props;
          if (nextRoom) {
            const preRoomSelect = document.getElementById(`room${nextRoom.id}`);
            if (!preRoomSelect) {
              const currentProject = document.getElementById(
                `project${nextRoom.project}`
              ) as HTMLButtonElement;

              currentProject.click();
            }
            const roomSelected = document.getElementById(`room${nextRoom.id}`);
            if (roomSelected) {
              roomSelected.click();
              scrollIntoView(roomSelected, {
                block: "start",
                inline: "nearest",
                scrollMode: "if-needed",
              });
            }
          }
          break;
        }
        case "PREVIOUS_ROOM": {
          event.preventDefault();
          event.stopPropagation();
          const { previousRoom } = this.props;
          if (previousRoom) {
            const preRoomSelect = document.getElementById(`room${previousRoom.id}`);
            if (!preRoomSelect) {
              const currentProject = document.getElementById(
                `project${previousRoom.project}`
              ) as HTMLButtonElement;

              currentProject.click();
            }
            const roomSelected = document.getElementById(`room${previousRoom.id}`);
            if (roomSelected) {
              roomSelected.click();
              scrollIntoView(roomSelected, {
                block: "start",
                inline: "nearest",
                scrollMode: "if-needed",
              });
            }
          }
          break;
        }
        case "NEXT_UNREAD_ROOM": {
          event.preventDefault();
          event.stopPropagation();
          const { nextUnreadRoom } = this.props;
          if (nextUnreadRoom) {
            const roomSelected = document.getElementById(`room${nextUnreadRoom.id}`);
            if (roomSelected) {
              roomSelected.click();
              scrollIntoView(roomSelected, {
                block: "start",
                inline: "nearest",
                scrollMode: "if-needed",
              });
            }
          }
          break;
        }
        case "PREVIOUS_UNREAD_ROOM": {
          event.preventDefault();
          event.stopPropagation();
          const { previousUnreadRoom } = this.props;
          if (previousUnreadRoom) {
            const roomSelected = document.getElementById(`room${previousUnreadRoom.id}`);
            if (roomSelected) {
              roomSelected.click();
              scrollIntoView(roomSelected, {
                block: "start",
                inline: "nearest",
                scrollMode: "if-needed",
              });
            }
          }
          break;
        }
        case "NEW_MESSAGE": {
          event.preventDefault();
          event.stopPropagation();
          const { newMessageModalShow } = this.props;
          newMessageModalShow();
          break;
        }
        case "NEW_PROJECT": {
          event.preventDefault();
          event.stopPropagation();
          const { newProjectModalShow } = this.props;
          newProjectModalShow();
          break;
        }
        case "NEW_TEAM": {
          if (!config.flags.profile.teams) break;
          event.preventDefault();
          event.stopPropagation();
          const { newTeamModalShow } = this.props;
          newTeamModalShow();
          break;
        }
        case "SHORTCUTS_HELP": {
          event.preventDefault();
          event.stopPropagation();
          const { shortcutsHelpModalToggle } = this.props;
          shortcutsHelpModalToggle();
          break;
        }
        default:
          break;
      }
    }
  };

  render() {
    return (
      <Shortcuts
        className="shortcuts"
        alwaysFireHandler
        global
        handler={this.handleShortcuts}
        name="GLOBAL"
        targetNodeSelector="body"
        stopPropagation={false}
        preventDefault={false}
        tabIndex={null}
      >
        {this.props.children}
      </Shortcuts>
    );
  }
}

// @ts-expect-error TODO: Replace legacy context
GlobalShortcuts.childContextTypes = {
  shortcuts: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state: Pending) {
  const nextRoom = getNextRoom(state);
  const nextUnreadRoom = getNextUnreadRoom(state);
  const previousRoom = getPreviousRoom(state);
  const previousUnreadRoom = getPreviousUnreadRoom(state);
  const isSubscribed =
    !config.stripeApiKey || (state.auth && state.auth.user && state.auth.user.isSubscribed);

  return {
    nextRoom,
    nextUnreadRoom,
    previousRoom,
    previousUnreadRoom,
    isSubscribed,
  };
}

export default connect(mapStateToProps, {
  newMessageModalShow: newMessageModal.show,
  newProjectModalShow: newProjectModal.show,
  newTeamModalShow: newTeamModal.show,
  shortcutsHelpModalToggle: shortcutsHelpModal.toggle,
  sidebarOpen: sidebar.open,
  sidebarClose: sidebar.close,
})(GlobalShortcuts);
