const ROLES_ADMIN = "admin";
const ROLES_ADMIN_TEXT = "Admin";
const ROLES_MEMBER = "member";
const ROLES_MEMBER_TEXT = "Member";
const ROLES_CLIENT_ADMIN = "client_admin";
const ROLES_CLIENT_ADMIN_TEXT = "Client Admin";
const ROLES_CLIENT_MEMBER = "client_member";
const ROLES_CLIENT_MEMBER_TEXT = "Client Member";
const CLIENT = "client";

export const Roles = {
  admin: ROLES_ADMIN,
  member: ROLES_MEMBER,

  bldAiAdmin: ROLES_ADMIN,
  bldAiMember: ROLES_MEMBER,

  clientAdmin: ROLES_CLIENT_ADMIN,
  clientMember: ROLES_CLIENT_MEMBER,
  client: CLIENT,

  all: [ROLES_ADMIN, ROLES_MEMBER, ROLES_CLIENT_ADMIN, ROLES_CLIENT_MEMBER],
  adminRoles: [ROLES_ADMIN, ROLES_CLIENT_ADMIN],
  bldAiRoles: [ROLES_ADMIN, ROLES_MEMBER],
  clientRoles: [ROLES_CLIENT_ADMIN, ROLES_CLIENT_MEMBER],

  options: [
    { key: ROLES_ADMIN, text: ROLES_ADMIN_TEXT, value: ROLES_ADMIN },
    { key: ROLES_MEMBER, text: ROLES_MEMBER_TEXT, value: ROLES_MEMBER },
    { key: ROLES_CLIENT_ADMIN, text: ROLES_CLIENT_ADMIN_TEXT, value: ROLES_CLIENT_ADMIN },
    { key: ROLES_CLIENT_MEMBER, text: ROLES_CLIENT_MEMBER_TEXT, value: ROLES_CLIENT_MEMBER },
  ],
};
