import { action } from ".";

export const SIDE_PANEL = {
  CLOSE: "SIDE_PANEL_CLOSE",
  OPEN: "SIDE_PANEL_OPEN",
  TOGGLE: "SIDE_PANEL_TOGGLE",
};

export const PANELS = {
  ABOUT: "ABOUT",
  PINNED: "PINNED",
  SEARCH: "SEARCH",
  THREAD: "THREAD",
  CHANNEL_INFO: "CHANNEL_INFO",
  MEMBERS: "MEMBERS",
  ATTACHMENTS: "ATTACHMENTS",
};

export const sidePanel = {
  close: (panelId = null, meta = null) => action(SIDE_PANEL.CLOSE, { panelId, meta }),
  open: (panelId, meta = null) => action(SIDE_PANEL.OPEN, { panelId, meta }),
  toggle: (panelId, meta = null) => action(SIDE_PANEL.TOGGLE, { panelId, meta }),
};
