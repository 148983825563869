/* eslint-disable react/no-did-update-set-state */

import classnames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { Button, Form } from "semantic-ui-react";

import Alert from "components/Alert";

import styles from "./styles.module.scss";

export default class ProjectBudgetItem extends React.PureComponent {
  onCancel = () => {
    const { budget, onCancel } = this.props;
    onCancel(budget.id);
  };

  onChange = (e, data) => {
    const { budget, onChange } = this.props;
    onChange(budget.id, { ...budget, amount: data.value });
  };

  renderActions() {
    const { disabled, isDirty } = this.props;

    if (isDirty) {
      return (
        <Button
          className={styles.sectionCancel}
          disabled={disabled}
          icon="remove"
          onClick={this.onCancel}
          type="button"
        />
      );
    }

    return <span className={styles.sectionColumnAction} />;
  }

  render() {
    const { budget, disabled, errors, isAdmin, isDirty, loading, onErrorHide } = this.props;

    return (
      <div className={classnames(styles.sectionRow, styles.sectionBudgetRow)}>
        <div className={styles.sectionBudgetLabel}>
          {moment(`${budget.month} ${budget.year}`, "M YYYY").format("MMMM")}
        </div>
        <div>
          <div className={styles.sectionBudgetInputContainer}>
            <Form.Input
              type="number"
              className={classnames(
                styles.sectionInput,
                styles.sectionBudgetInput,
                isDirty && styles.dirty
              )}
              disabled={disabled || loading || !isAdmin}
              name="amount"
              placeholder="$0"
              onChange={this.onChange}
              value={budget.amount > 0 ? budget.amount : ""}
              min={0}
            />
            {this.renderActions()}
          </div>
          {errors.map((error, index) => (
            <Alert
              content={error}
              key={index}
              onClick={() => onErrorHide(budget.id, index)}
              type="danger"
            />
          ))}
        </div>
      </div>
    );
  }
}

ProjectBudgetItem.propTypes = {
  budget: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  isAdmin: PropTypes.bool,
  isDirty: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onErrorHide: PropTypes.func.isRequired,
};

ProjectBudgetItem.defaultProps = {
  disabled: false,
  errors: [],
  isAdmin: false,
  isDirty: false,
  loading: false,
};
