import Humanize from "humanize-plus";
import { has, isArray, isString, replace } from "lodash";
import seedrandom from "seedrandom";
import { bldAiDomain } from "./constants";

import config from "config";

export { requireAuthentication, requireUnauthenticated } from "./auth";
export { createOwnMessage, getRoomName, launchCall } from "./chat";
export { requireAuthenticationSubscription } from "./subscription";

export function emptyPromise(value = null) {
  return new Promise((resolve) => resolve(value));
}

export function genID() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function serialize(obj) {
  const str = [];
  Object.keys(obj).forEach((p) => {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  });
  return str.join("&");
}

export function getComponentName(Component) {
  return Component.displayName || Component.name || "Component";
}

export function getErrorMessages(errors) {
  if (!errors) return [];

  const errorKeys = Object.keys(errors).sort();
  const errorMessages = [];

  errorKeys.forEach((key) => {
    let errorDetails = errors[key];
    if (!errorDetails) return;
    if (!isArray(errorDetails)) errorDetails = [errorDetails];
    if (!isString(errorDetails[0])) return;

    const prefix = ["detail", "non_field_errors"].includes(key)
      ? ""
      : `${Humanize.titleCase(key)} - `;
    errorDetails.forEach((detail) => {
      errorMessages.push(`${prefix}${detail}`);
    });
  });

  return errorMessages;
}

export function getNotificationSettings(room, user) {
  let notificationSettings = {};
  if (user) {
    ({ notificationSettings } = user);
  }
  /*
  // currently, the app does not support room specific settings
  if (room && room.project) {
    notificationSettings = room.notificationSettings;
  }
  */
  return notificationSettings;
}

export function getNotificationSettingsItem(room, user, item) {
  const notificationSettings = getNotificationSettings(room, user);
  if (notificationSettings === undefined) {
    return true;
  }
  return has(notificationSettings, item) ? notificationSettings[item] : true;
}

export function trimString(string) {
  return string.replace(/^\s+|\s+$/g, "");
}

export function randomInt(maximum, seed = null) {
  const rng = seedrandom(seed);
  return Math.floor(rng() * Math.floor(maximum));
}

export function generateColor(isDark = true, seed = null) {
  const maximum = isDark ? 130 : 255;
  const seedR = seed ? `${seed}R` : null;
  const seedG = seed ? `${seed}G` : null;
  const seedB = seed ? `${seed}B` : null;

  return `rgba(${randomInt(maximum, seedR)}, ${randomInt(maximum, seedG)}, ${randomInt(
    maximum,
    seedB
  )}, 255)`;
}

export function getAbsoluteUri(relativeUri) {
  if (relativeUri.indexOf("https://") >= 0 || relativeUri.indexOf("http://") >= 0) {
    return relativeUri;
  }
  const { pathname } = new URL(config.apiBaseUrl);
  return `${config.apiBaseUrl}/${replace(relativeUri, `${pathname}/`, "")}`;
}

export function isUrlValid(urlString) {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function getEmailAddressDomain(email) {
  if (email) {
    if (email.includes("@")) {
      const domain = email.slice(email.indexOf("@") + 1);
      return domain;
    }
  }
  return "";
}

export function isBldAiDomain(email) {
  const domain = getEmailAddressDomain(email);
  if (domain) {
    return domain === bldAiDomain;
  }
  return false;
}

export function isRocketChatMember(user) {
  if (isBldAiDomain(user.email)) {
    return true;
  }
  return !!user.rocketChatUserId;
}
