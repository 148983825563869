import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button, Icon } from "semantic-ui-react";

import styles from "./styles.module.scss";

const types = ["danger", "info"];

const Alert = ({ children, className, content, disabled = false, onClick, type }) => (
  <div className={classnames(className, styles.alert, styles[type])}>
    <Icon name="warning circle" />

    <span className={styles.text}>{children || content}</span>

    {onClick && (
      <Button className={styles.removeButton} disabled={disabled} icon="remove" onClick={onClick} />
    )}
  </div>
);

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(types).isRequired,
  onClick: PropTypes.func,
};

export default Alert;
