import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink as Link, withRouter } from "react-router-dom";
import { Button, Container, Header } from "semantic-ui-react";

import { navigate } from "actions";
import { fetchConfirmEmail, verifyEmail } from "services/api";

import styles from "./styles.module.scss";

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      displayUser: "",
      verifying: false,
      verified: false,
    };
  }

  componentDidMount() {
    const { key } = this.props.match.params;
    fetchConfirmEmail(key).then(({ response }) => {
      if (response) {
        this.setState({
          email: response.email,
          displayUser: response.displayUser,
        });
      } else {
        this.props.navigate("/");
      }
    });
  }

  handleVerifyEmail = () => {
    const { key } = this.props.match.params;
    this.setState(
      {
        verifying: true,
      },
      () => {
        verifyEmail(key).then(() => {
          this.setState({
            verifying: false,
            verified: true,
          });
        });
      }
    );
  };

  render() {
    const { email, displayUser, verifying, verified } = this.state;
    return (
      email && (
        <Container>
          <Header as="h2" content="Confirm your Email Address" className={styles.header} />
          <p>Is this you?</p>
          <p>
            Please confirm that <strong>{email}</strong> is an email address for user{" "}
            <strong>{displayUser}</strong>.
          </p>

          {!verifying && !verified && (
            <p>
              <Button onClick={this.handleVerifyEmail}>Confirm</Button>
            </p>
          )}
          {!verifying && verified && (
            <p>
              <strong>
                Your account is confirmed. You may <Link to="/login">login</Link> now.
              </strong>
            </p>
          )}
          {verifying && <p>Verifying...</p>}
        </Container>
      )
    );
  }
}

ConfirmEmail.propTypes = {
  navigate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { isAuthenticated = false },
  } = state;
  return {
    isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps, { navigate })(ConfirmEmail));
