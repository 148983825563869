import { createRequestTypes, action } from "./index";

export const CLIENT_FEEDBACK = createRequestTypes("CLIENT_FEEDBACK");
export const ADD_CLIENT_FEEDBACK = "ADD_CLIENT_FEEDBACK";

export const clientFeedback = {
  init: (project) => action(CLIENT_FEEDBACK.INIT, { project }),
  request: (project) => action(CLIENT_FEEDBACK.REQUEST, { project }),
  success: (response) => action(CLIENT_FEEDBACK.SUCCESS, { response }),
  failure: (error) => action(CLIENT_FEEDBACK.FAILURE, { error }),
};

export const addClientFeedback = (data) => action(ADD_CLIENT_FEEDBACK, { data });
