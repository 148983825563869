import { sortBy, flatten } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { getCurrentUserProjectsActive } from "reducers/selectors/project";

class ProjectDropdown extends React.PureComponent {
  componentDidUpdate() {
    const { required, value } = this.props;
    if (required && !value) {
      document.querySelector("#project-dropdown input").required = true;
    }
  }

  handleChange = (e, { name, value }) => {
    document.querySelector("#project-dropdown input").required = false;
    this.props.onChange(e, { name, value });
  };

  render() {
    const {
      disabled,
      fluid,
      name,
      placeholder,
      projects,
      required,
      value: defaultValue,
      upward,
    } = this.props;

    return (
      <Dropdown
        id="project-dropdown"
        required={required}
        disabled={disabled}
        fluid={fluid}
        name={name}
        onChange={this.handleChange}
        options={projects}
        placeholder={placeholder}
        search
        selection
        value={defaultValue}
        upward={upward}
      />
    );
  }
}

ProjectDropdown.propTypes = {
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  required: PropTypes.bool,
  upward: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ProjectDropdown.defaultProps = {
  disabled: false,
  fluid: false,
  name: "project",
  onChange: () => {},
  placeholder: "Select project",
  required: false,
  upward: false,
  value: null,
};

function mapStateToProps(state) {
  const currentUserProjectsActive = getCurrentUserProjectsActive(state);
  const flattenedProjects = flatten(Object.values(currentUserProjectsActive)).map((p) => ({
    id: p.id,
    text: `${p.name}`,
    value: p.id,
  }));
  const projects = sortBy(flattenedProjects, [(channel) => channel.text]);

  return { projects };
}

export default connect(mapStateToProps, {})(ProjectDropdown);
