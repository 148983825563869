import moment from "moment-timezone";

export const LEGAL_AGE = 18;

export function age(birthday) {
  return moment().diff(birthday, "years");
}

export function isLegal(birthday) {
  return age(birthday) >= LEGAL_AGE;
}

const PROFILE_DEFAULT_FIELDS = {
  educationalAccomplishments: {
    fields: {
      talent: "",
      schoolName: "",
      degree: "",
      gradYear: undefined,
      schoolLocation: "",
      startYear: undefined,
      description: "",
    },
    talentField: "username",
  },
  employmentHistory: {
    fields: {
      talent: "",
      companyName: "",
      startYear: undefined,
      endYear: undefined,
      startMonth: undefined,
      endMonth: undefined,
      companyLocation: "",
      position: "",
      description: "",
    },
    talentField: "username",
  },
  publications: {
    fields: {
      talent: "",
      title: "",
      description: "",
      link: "",
    },
    talentField: "username",
  },
  linkedAccounts: {
    fields: {
      talent: "",
      category: null,
      accountUrl: "",
    },
    talentField: "username",
  },
  phoneNumbers: {
    fields: {
      talent: "",
      phoneNumber: "",
      isPublic: true,
      isPrimary: false,
    },
    talentField: "slug",
  },
  industries: {
    fields: {
      talent: "",
      industry: "",
    },
    talentField: "username",
  },
};

export function getDefaultFields(profileSection, user) {
  const defaults = PROFILE_DEFAULT_FIELDS[profileSection];
  const defaultFields = defaults.fields;
  defaultFields.talent = user[defaults.talentField];
  return defaultFields;
}

export function filterUsers(users, isObject = false) {
  if (isObject) {
    return Object.keys(users).reduce((acc, userId) => {
      const item = { ...users[userId] };
      if (item.isSentinel) {
        delete acc[userId];
        return acc;
      }
      return { ...acc, [userId]: item };
    }, {});
  }
  return users.filter((user) => !user.isSentinel);
}
