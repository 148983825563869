import qs from "querystring";
import React, { Component } from "react";
import {
  loginInit,
  passwordlessGetToken,
  passwordlessValidateToken,
  passwordlessLogin,
} from "actions/account";
import { resetErrorMessage } from "actions";
import PropTypes from "prop-types";
import { batch, connect } from "react-redux";
import { injectIntl } from "react-intl";
import { NavLink as Link, Redirect, withRouter } from "react-router-dom";
import { Button, Divider, Form, Header, Grid, Icon, Image, List, Message } from "semantic-ui-react";
import classNames from "classnames";
import { token as tokenService } from "services";
import { isArray, isString } from "lodash";
import LoginModal from "./components/LoginModal";
import { Email, Facebook, GitHub, Google, LinkedIn, Microsoft, Twitter } from "./buttons";

import WelcomeImage from "./images/welcome-back-no-bg.png";
import LogoName from "./images/logo-name.svg";
import { getErrorMessages } from "../../utils";

import styles from "./styles.module.scss";

export class LoginBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginEmail: "",
      loginPassword: "",
      isLoggingIn: false,
      isModalVisible: false,
      isEmailLogin: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLinkToEmailClick = this.handleLinkToEmailClick.bind(this);
    this.checkingModalVisibility = false;
  }

  componentDidMount() {
    const pageTokens = this.props.location.pathname.split("/").filter((s) => s);
    const hasToken = pageTokens.length > 1;
    if (hasToken && !this.checkingModalVisibility) {
      this.setModalVisibility(pageTokens);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.resetErrorMessage();
    }
    if (!prevProps.errorMessage && !!this.props.errorMessage) {
      this.setState({ loginEmail: "" });
    }
    const sentEmailField = document.getElementById("emailLink");
    if (sentEmailField) {
      sentEmailField.classList.remove(styles.loginLinkSent);
    }
  }

  setModalVisibility = async (pageTokens) => {
    if (!this.state.isModalVisible) {
      this.checkingModalVisibility = true;
      try {
        await this.validatePasswordlessToken(pageTokens[1]);
        this.setState({ isModalVisible: true });
      } catch (error) {
        console.log(this.props.errorMessage);
      } finally {
        this.checkingModalVisibility = false;
      }
    }
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleEmailLoginConfirm = () => {
    this.setState({ isModalVisible: true });
  };

  handleEmailLogin = ({ expiration, doSignout }) => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const next = params.get("next");

    const { loginEmail, loginPassword } = this.state;
    const credentials = {
      username: loginEmail,
      password: loginPassword,
    };
    const extraData = { expiration, doSignout };

    batch(() => {
      this.props.resetErrorMessage();
      this.props.loginInit(credentials, null, next, extraData);
    });
  };

  handleLinkToEmailClick = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const sentEmailField = document.getElementById("emailLink");
    this.props.resetErrorMessage();

    try {
      await this.getPasswordlessToken();
      sentEmailField.classList.add(styles.loginLinkSent);
    } catch (error) {
      console.log(this.props.errorMessage);
    }
  };

  getPasswordlessToken = () =>
    new Promise((resolve, reject) =>
      this.props.passwordlessGetToken({ email: this.state.loginEmail, resolve, reject })
    );

  validatePasswordlessToken = (invitationToken) =>
    new Promise((resolve, reject) =>
      this.props.passwordlessValidateToken({ invitationToken, resolve, reject })
    );

  handleModalLogin = ({ expiration, doSignout }) => {
    this.setState({ isLoggingIn: true });
    if (this.state.isEmailLogin) {
      this.handleEmailLogin({ expiration, doSignout });
    } else {
      this.handlePasswordlessLogin({ expiration, doSignout });
    }
  };

  handlePasswordlessLogin = async ({ expiration, doSignout }) => {
    const authToken = await tokenService.getAuthToken();
    const invitationToken = this.props.location.pathname.split("/")[2];
    this.props.passwordlessLogin({ authToken, invitationToken, expiration, doSignout });
  };

  handleModalClose = () => {
    this.setState({ isModalVisible: false });
  };

  handleEmailLoginToggle = () => {
    this.setState((prevState) => ({ isEmailLogin: !prevState.isEmailLogin }));
  };

  render() {
    const { token, loading, errorMessage } = this.props;
    const { isLoggingIn, isModalVisible, isEmailLogin } = this.state;
    let errorMessages = [];

    if (isString(errorMessage)) {
      if (errorMessage.startsWith("Expired")) {
        return <Redirect to="/expired-link" />;
      }
    } else {
      errorMessages = getErrorMessages(errorMessage);
      if (
        isArray(errorMessages) &&
        errorMessages.filter((s) => s.startsWith("Expired")).length > 0
      ) {
        return <Redirect to="/expired-link" />;
      }
    }

    if (token) {
      const { next } = qs.parse(this.props.location.search.substring(1));
      if (next) return <Redirect to={next} />;
      return <Redirect to="/" />;
    }

    return (
      <>
        <LoginModal
          onCancel={this.handleModalClose}
          isLoggingIn={isLoggingIn}
          onLogin={this.handleModalLogin}
          open={isModalVisible}
          errorMessage={errorMessage}
        />
        <Grid columns="equal" className={styles.content}>
          <Grid.Column className={styles.logo}>
            <div className={styles.centered}>
              <Image src={WelcomeImage} />
              <br />
              <Image src={LogoName} />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={classNames(styles.centered, styles.fullWidth)}>
              <Header as="h2" className={classNames(styles.header)}>
                <Icon
                  name="arrow left"
                  className={isEmailLogin ? styles.visibleIcon : ""}
                  onClick={this.handleEmailLoginToggle}
                />
                <Header.Content className={classNames(styles.headerText, styles.normalText)}>
                  <span>Login</span>
                </Header.Content>
              </Header>
              {!isEmailLogin ? (
                <>
                  <div className={styles.section}>
                    <Google />
                  </div>

                  <Divider horizontal>or</Divider>

                  <div id="emailLink" className={styles.section}>
                    <Form
                      data-testid="loginForm"
                      key="login2"
                      className={classNames(styles.form, styles.loginForm)}
                      onSubmit={this.handleLinkToEmailClick}
                      error={Boolean(this.props.errorMessage)}
                      loading={loading}
                    >
                      <Message error header="Error" content={errorMessages} />
                      <Form.Input
                        required
                        type="email"
                        placeholder="Enter your email address"
                        name="loginEmail"
                        value={this.state.loginEmail}
                        onChange={this.handleChange}
                        className={styles.inputShadow}
                      />
                      <Button data-testid="loginButton" type="submit" primary fluid>
                        <p>Email Login Link</p>
                      </Button>
                    </Form>
                    <div className={styles.success}>
                      We sent the link to <strong>{this.state.loginEmail}</strong>, if this address
                      exists. The link will log you in.
                      <br />
                      <Link to="/login">Back</Link>
                    </div>
                  </div>
                </>
              ) : (
                <div id="emailLink" className={styles.section}>
                  <Form
                    data-testid="loginForm"
                    key="login2"
                    className={classNames(styles.form, styles.loginForm)}
                    onSubmit={this.handleEmailLoginConfirm}
                    error={Boolean(this.props.errorMessage)}
                    loading={loading}
                  >
                    <Message
                      error
                      header="Error"
                      content={
                        isString(errorMessage) ? errorMessage : getErrorMessages(errorMessage)
                      }
                    />
                    <Form.Input
                      required
                      type="email"
                      placeholder="Enter your email address"
                      name="loginEmail"
                      value={this.state.loginEmail}
                      onChange={this.handleChange}
                      className={styles.inputShadow}
                    />
                    <Form.Input
                      required
                      type="password"
                      placeholder="Enter your password"
                      name="loginPassword"
                      onChange={this.handleChange}
                      className={styles.inputShadow}
                    />
                    <Button data-testid="loginButton" type="submit" primary fluid>
                      <p>Login</p>
                    </Button>
                  </Form>
                </div>
              )}
              <div className={classNames(styles.section, styles.icons, styles.otherLogin)}>
                <List horizontal>
                  <List.Item>Other ways to login:</List.Item>
                  <List.Item />
                  <List.Item className={styles.removeLeftMargin}>
                    <Facebook />
                  </List.Item>
                  <List.Item className={styles.removeLeftMargin}>
                    <Twitter />
                  </List.Item>
                  <List.Item className={styles.removeLeftMargin}>
                    <GitHub />
                  </List.Item>
                  <List.Item className={styles.removeLeftMargin}>
                    <LinkedIn />
                  </List.Item>
                  <List.Item className={styles.removeLeftMargin}>
                    <Microsoft />
                  </List.Item>
                  <List.Item className={styles.removeLeftMargin}>
                    <Email onClick={this.handleEmailLoginToggle} />
                  </List.Item>
                </List>
              </div>

              <div style={{ textAlign: "center", marginTop: "3rem" }}>
                <List horizontal>
                  <List.Item>
                    <Link to="/legal/privacy" target="_blank">
                      Privacy Policy
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link to="/legal/terms" target="_blank">
                      Terms of Use
                    </Link>
                  </List.Item>
                </List>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}

LoginBase.propTypes = {
  errorMessage: PropTypes.string,
  token: PropTypes.string,
  loading: PropTypes.bool,
  location: PropTypes.shape(),
  loginInit: PropTypes.func.isRequired,
  passwordlessGetToken: PropTypes.func.isRequired,
  passwordlessValidateToken: PropTypes.func.isRequired,
  passwordlessLogin: PropTypes.func.isRequired,
  resetErrorMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    errorMessage,
    auth: { token, loading },
  } = state;
  return {
    errorMessage,
    token,
    loading,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loginInit,
    resetErrorMessage,
    passwordlessGetToken: passwordlessGetToken.request,
    passwordlessValidateToken: passwordlessValidateToken.request,
    passwordlessLogin: passwordlessLogin.request,
  })(injectIntl(LoginBase))
);
