import Humanize from "humanize-plus";
import { groupBy, isEmpty } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Emoji } from "emoji-mart";
import { Popup, Button } from "semantic-ui-react";

import { reactions as reactionActions, reactionsAdd, reactionsDelete } from "actions/chat";
import { getReactions } from "reducers/selectors";

import ReactionPicker from "./ReactionPicker";
import * as styles from "./styles.module.scss";

class Reaction extends React.PureComponent {
  componentDidMount() {
    const { token, room, message } = this.props;
    if (message.hasReactions && !message.loadedReactions) {
      this.props.fetchReactions({ token, id: room.id, messageId: message.id });
    }
  }

  render() {
    const {
      alwaysVisible,
      currentUser,
      message,
      reactions,
      reactionsAddRequest,
      reactionsDeleteRequest,
      room,
      token,
      users,
    } = this.props;
    const { id } = room;
    const messageId = message.id;

    if (!alwaysVisible && isEmpty(reactions)) {
      if (message.hasReactions) {
        return <div style={{ height: 26 }} />;
      }
      return null;
    }

    return (
      <div className={styles.reactions}>
        {Object.keys(reactions).map((emoji) => {
          const emojiList = reactions[emoji];
          const hasOwn = emojiList.some((reaction) => reaction.user === currentUser.slug);
          const userNames = emojiList.map((reaction) => users[reaction.user].displayName);
          return (
            <Popup
              content={`${Humanize.oxford(userNames, 3)} reacted with :${emoji}:`}
              inverted
              key={emoji}
              size="mini"
              style={{ padding: 5 }}
              trigger={
                <Button
                  basic={!hasOwn}
                  content={
                    <span>
                      <Emoji emoji={emoji} native size={12} /> {emojiList.length}
                    </span>
                  }
                  onClick={
                    hasOwn
                      ? () =>
                          reactionsDeleteRequest({
                            token,
                            id,
                            messageId,
                            emoji,
                            user: currentUser.slug,
                          })
                      : () =>
                          reactionsAddRequest({
                            token,
                            id,
                            messageId,
                            emoji,
                            user: currentUser.slug,
                          })
                  }
                  type="button"
                  size="small"
                />
              }
            />
          );
        })}
        <div className={styles.addReaction}>
          <ReactionPicker room={room} message={message} />
        </div>
      </div>
    );
  }
}

Reaction.propTypes = {
  alwaysVisible: PropTypes.bool,
  currentUser: PropTypes.shape().isRequired,
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    hasReactions: PropTypes.bool,
    loadedReactions: PropTypes.bool,
  }).isRequired,
  fetchReactions: PropTypes.func.isRequired,
  reactions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        emoji: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  reactionsAddRequest: PropTypes.func.isRequired,
  reactionsDeleteRequest: PropTypes.func.isRequired,
  room: PropTypes.shape().isRequired,
  token: PropTypes.string.isRequired,
  users: PropTypes.objectOf(PropTypes.shape({ displayName: PropTypes.string.isRequired }))
    .isRequired,
};

function mapStateToProps(state, props) {
  const {
    auth: { user, token },
    entities: { users },
  } = state;
  const reactions = getReactions(state);
  const reactionsByEmoji = groupBy(reactions[props.message.id] || {}, "emoji");

  return {
    currentUser: user,
    reactions: reactionsByEmoji,
    token,
    users,
  };
}

export default connect(mapStateToProps, {
  fetchReactions: reactionActions.request,
  reactionsAddRequest: reactionsAdd.request,
  reactionsDeleteRequest: reactionsDelete.request,
})(Reaction);
