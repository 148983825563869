/* eslint-disable react/no-did-update-set-state */

import Fuse from "fuse.js";
import Humanize from "humanize-plus";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Button, Input, Modal } from "semantic-ui-react";

import User from "./User";
import styles from "./styles.module.scss";

const fuseOptions = {
  distance: 100,
  includeMatches: true,
  keys: ["displayName"],
  location: 0,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.6,
};

export default class UserListModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      searchValue: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.room && prevProps.room && this.props.room.id !== prevProps.room.id) {
      this.onClose();
    }
  }

  onChange = (e, { value }) => {
    this.setState({ searchValue: value });
  };

  onClose = () => {
    const { onClose } = this.props;

    this.setState({ open: false, searchValue: "" });
    if (onClose) onClose();
  };

  onOpen = () => {
    const { onOpen } = this.props;

    this.setState({ open: true, searchValue: "" });
    if (onOpen) onOpen();
  };

  focusInput = (input) => {
    if (input) input.focus();
  };

  search() {
    const { users } = this.props;
    const { searchValue } = this.state;

    const fuse = new Fuse(users, fuseOptions);
    const matches = fuse.search(searchValue);

    if (matches.length > 0) {
      return matches.map(({ item }) => item);
    }
    return users;
  }

  render() {
    const { actionRenderer, mountNode, room, trigger, userClassName, users } = this.props;
    const { open, searchValue } = this.state;
    let title = "";
    if (room) {
      title = `${users.length} ${Humanize.pluralize(users.length, "member")} in ${room.name}`;
    } else {
      title = `${users.length} ${Humanize.pluralize(users.length, "member")}`;
    }
    const usersFiltered = this.search();
    const usersSorted = orderBy(usersFiltered, "displayName", "asc");

    return (
      <Modal
        className={styles.modal}
        dimmer
        mountNode={mountNode}
        onClose={this.onClose}
        onOpen={this.onOpen}
        open={open}
        trigger={trigger}
      >
        <Modal.Header className={styles.header}>
          <h3 className={styles.headerTitle}>{title}</h3>
          <Button
            className={styles.closeButton}
            icon="close"
            onClick={this.onClose}
            size="mini"
            title="Close"
          />
        </Modal.Header>

        <Modal.Content className={styles.content} scrolling>
          <Input
            className={styles.userSearch}
            icon="search"
            iconPosition="left"
            onChange={this.onChange}
            placeholder="Search a member..."
            ref={this.focusInput}
            transparent
            value={searchValue}
          />

          {usersSorted.map((user) => (
            <User
              actionRenderer={actionRenderer}
              className={userClassName}
              key={user.slug}
              isAdmin={!!room && room.author === user.slug}
              user={user}
            />
          ))}
        </Modal.Content>
      </Modal>
    );
  }
}

UserListModal.propTypes = {
  actionRenderer: PropTypes.func,
  mountNode: PropTypes.instanceOf(Element),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  room: PropTypes.shape(),
  trigger: PropTypes.node.isRequired,
  userClassName: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

UserListModal.defaultProps = {
  actionRenderer: null,
  onClose: null,
  onOpen: null,
};
