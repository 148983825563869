import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getRooms } from "reducers/selectors";

class Favicon extends React.Component {
  componentDidMount() {
    if (window.__ElectronBridge) {
      window.__ElectronBridge.updateUnreadNotificationsCount(this.props.unreadMessagesCount);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unreadMessagesCount !== this.props.unreadMessagesCount) {
      if (window.__ElectronBridge) {
        window.__ElectronBridge.updateUnreadNotificationsCount(this.props.unreadMessagesCount);
      }
    }
  }

  render() {
    const { unreadMessagesCount } = this.props;

    if (unreadMessagesCount > 0) {
      return (
        <Helmet titleTemplate={`(${unreadMessagesCount}) %s`} defer={false}>
          <link rel="shortcut icon" href="/favicon_notif.ico" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon_notif-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon_notif-16x16.png" />
        </Helmet>
      );
    }
    return (
      <Helmet defer={false}>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      </Helmet>
    );
  }
}

Favicon.propTypes = {
  unreadMessagesCount: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const {
    messages: { unreadMessagesCountByRoom },
  } = state;

  const rooms = getRooms(state) || {};
  const unreadMessagesCount = Object.keys(rooms).reduce(
    (count, roomId) => count + unreadMessagesCountByRoom[roomId],
    0
  );

  return {
    unreadMessagesCount,
  };
}

export default connect(mapStateToProps, {})(Favicon);
