import classnames from "classnames";
import { isEqual, flatten } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router-dom";
import { Shortcuts } from "react-shortcuts";
import { Icon } from "semantic-ui-react";

import { logoutInit } from "actions/account";
import { roomHide, setChannelTab } from "actions/chat";
import { newMessageModal, newProjectModal, newTeamModal } from "actions/modals";
import { sidebar } from "actions/sidebar";
import ScrollableContent from "components/ScrollableContent";
import config from "config";
import { getCurrentProject, getCurrentRoom } from "reducers/selectors";
import {
  getCurrentUserMessageRooms,
  getCurrentUserProjectRoomsActive,
  getCurrentUserProjectRoomsArchived,
  getCurrentUserProjectsActive,
  getCurrentUserProjectsArchived,
  getCurrentUserTeamRooms,
} from "reducers/selectors/project";
import { projectType } from "types/project";
import { getPlatformName } from "utils/platform";

import MessageRoomList from "./MessageRoomList";
import ProjectList from "./ProjectList";
import UnreadAbove from "./UnreadAbove";
import UnreadBelow from "./UnreadBelow";
import UnreadList from "./UnreadList";
import styles from "./styles.module.scss";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      _selected: null,
    };

    this._currentFocusable = 0;
    this._isScrolling = null;
  }

  componentDidMount() {
    document.getElementById("sidebarSearch").addEventListener("focus", this._resetCurrentFocusable);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(
        new Set(this.props.currentUserMessageRooms.map((r) => r.id)),
        new Set(prevProps.currentUserMessageRooms.map((r) => r.id))
      ) ||
      !isEqual(
        new Set(flatten(Object.values(this.props.currentUserProjectRoomsActive)).map((r) => r.id)),
        new Set(flatten(Object.values(prevProps.currentUserProjectRoomsActive)).map((r) => r.id))
      ) ||
      !isEqual(
        new Set(
          flatten(Object.values(this.props.currentUserProjectRoomsArchived)).map((r) => r.id)
        ),
        new Set(flatten(Object.values(prevProps.currentUserProjectRoomsArchived)).map((r) => r.id))
      )
    ) {
      this._resetCurrentFocusable();
    }

    if (prevState._selected !== this.state._selected && this.state._selected) {
      document.getElementById(this.state._selected).scrollIntoView();
    }
  }

  componentWillUnmount() {
    this.props.sidebarClose();
    document
      .getElementById("sidebarSearch")
      .removeEventListener("focus", this._resetCurrentFocusable);
  }

  handleArchivedCollapse = () => {
    const { archivedCollapsed } = this.props;
    if (archivedCollapsed) {
      this.props.archivedExpand();
    } else {
      this.props.archivedCollapse();
    }
  };

  handleLogout = () => {
    this.props.logoutInit();
  };

  handleSearchChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  _setCurrentFocusableById = (id) => {
    const focusable = document.querySelectorAll(`[data-focusable]`);
    for (let i = 0; i < focusable.length; i += 1) {
      if (focusable[i].id === id) {
        this._currentFocusable = i;
        return;
      }
    }
  };

  _resetCurrentFocusable = () => {
    this._currentFocusable = 0;
  };

  _getNextFocusable = () => {
    const focusable = document.querySelectorAll(`[data-focusable]`);
    for (let i = this._currentFocusable + 1; i < focusable.length; i += 1) {
      if (focusable[i] && document.getElementById(focusable[i].id)) {
        return i;
      }
    }
    return 0;
  };

  _getLastFocusable = () => {
    const focusable = document.querySelectorAll(`[data-focusable]`);
    let lastFocusable = 0;
    for (let i = 0; i < focusable.length; i += 1) {
      if (focusable[i] && document.getElementById(focusable[i].id)) {
        lastFocusable = i;
      }
    }
    return lastFocusable;
  };

  _getPreviousFocusable = () => {
    const focusable = document.querySelectorAll(`[data-focusable]`);
    for (let i = this._currentFocusable - 1; i > 0; i -= 1) {
      if (focusable[i] && document.getElementById(focusable[i].id)) {
        return i;
      }
    }
    if (this._currentFocusable > 0) {
      return 0;
    }
    return this._getLastFocusable();
  };

  handleShortcuts = (action, event) => {
    const focusable = document.querySelectorAll(`[data-focusable]`);
    const chatMessageBox = document.querySelector("#chatMessageBox");

    switch (action) {
      case "PREVIOUS_ROOM":
        event.preventDefault();
        this._currentFocusable = this._getPreviousFocusable();
        focusable[this._currentFocusable].focus();
        break;
      case "NEXT_ROOM":
        event.preventDefault();
        this._currentFocusable = this._getNextFocusable();
        focusable[this._currentFocusable].focus();
        break;
      case "CANCEL":
        event.preventDefault();
        focusable[this._currentFocusable].blur();
        this.props.sidebarClose();
        if (chatMessageBox) chatMessageBox.focus();
        break;
      case "SELECT_ROOM":
        event.preventDefault();
        // Select either the first result or the user-selected room.
        // Instead of resetting _currentFocusable...
        // ...update it so that it points to "focused" using _setCurrentFocusableById
        if (this._currentFocusable === 0) {
          if (focusable[0] && focusable[1]) {
            const focusedId = focusable[1].id;
            const focused = document.getElementById(focusedId);
            focused.click();
            this._setCurrentFocusableById(focusedId);
            this.setState({ searchValue: "", _selected: focusedId });
          }
        } else {
          const focusedId = focusable[this._currentFocusable].id;
          const focused = document.getElementById(focusedId);
          focused.click();
          this._setCurrentFocusableById(focusedId);
          this.setState({ searchValue: "", _selected: focusedId });
        }
        break;
      default:
        break;
    }
  };

  render() {
    const {
      archivedCollapsed,
      currentRoom,
      currentUser,
      currentUserMessageRooms,
      currentUserProjectRoomsActive,
      currentUserProjectRoomsArchived,
      currentUserProjectsActive,
      currentUserProjectsArchived,
      currentUserTeamRooms,
      location,
      newMessageModalShow,
      newProjectModalShow,
      newTeamModalShow,
      projectHomeRoomMapping,
      roomHideRequest,
      unreadMessagesCountByRoom,
      unreadMessagesLastTimestampByRoom,
      users,
      lastRoomTab,
    } = this.props;
    const { searchValue } = this.state;

    const isChat = matchPath(location.pathname, { path: "/chat" });
    const searchPlaceholder = getPlatformName() === "osx" ? "Switch to / ⌘K" : "Switch to / Ctrl+K";
    const searchValueProcessed = searchValue.trim().toLowerCase();

    return (
      <Shortcuts
        name="SIDEBAR_SECTION"
        handler={this.handleShortcuts}
        className={classnames("shortcuts", styles.messageSidebarSection)}
      >
        <div className={classnames(styles.sidebar, !isChat && styles.collapsed)}>
          <div className={styles.sidebarSearch}>
            <input
              id="sidebarSearch"
              className="focusable"
              data-focusable
              onChange={this.handleSearchChange}
              placeholder={searchPlaceholder}
              type="search"
              value={searchValue}
            />
            <Icon name="search" className={styles.sidebarSearchIcon} />
          </div>
          <ScrollableContent idPrefix="sidebar" contentClass={styles.sidebarContent}>
            <UnreadAbove />
            <UnreadBelow />

            <UnreadList
              header="Unread"
              currentRoom={currentRoom}
              currentUser={currentUser}
              currentUserMessageRooms={currentUserMessageRooms}
              currentUserTeamRooms={currentUserTeamRooms}
              onNewClick={newMessageModalShow}
              roomHideRequest={roomHideRequest}
              projectHomeRoomMapping={projectHomeRoomMapping}
              projectRooms={currentUserProjectRoomsActive}
              projects={currentUserProjectsActive}
              searchValue={searchValueProcessed}
              unreadMessagesCountByRoom={unreadMessagesCountByRoom}
              unreadMessagesLastTimestampByRoom={unreadMessagesLastTimestampByRoom}
              users={users}
              lastRoomTab={lastRoomTab}
            />

            <ProjectList
              currentRoom={currentRoom}
              onNewClick={newProjectModalShow}
              projectHomeRoomMapping={projectHomeRoomMapping}
              projectRooms={currentUserProjectRoomsActive}
              projects={currentUserProjectsActive}
              searchValue={searchValueProcessed}
              title={config.flags.profile.teams ? "Projects" : "Teams"}
              unreadMessagesCountByRoom={unreadMessagesCountByRoom}
              setChannelTab={this.props.setChannelTab}
              lastRoomTab={lastRoomTab}
            />

            {config.flags.profile.teams && (
              <MessageRoomList
                currentRoom={currentRoom}
                currentUser={currentUser}
                header="Teams"
                messageRooms={currentUserTeamRooms}
                onNewClick={newTeamModalShow}
                roomHideRequest={roomHideRequest}
                searchValue={searchValueProcessed}
                unreadMessagesCountByRoom={unreadMessagesCountByRoom}
                users={users}
                lastRoomTab={lastRoomTab}
              />
            )}

            <MessageRoomList
              currentRoom={currentRoom}
              currentUser={currentUser}
              messageRooms={currentUserMessageRooms}
              onNewClick={newMessageModalShow}
              roomHideRequest={roomHideRequest}
              searchValue={searchValueProcessed}
              unreadMessagesCountByRoom={unreadMessagesCountByRoom}
              users={users}
              lastRoomTab={lastRoomTab}
            />

            {Object.keys(currentUserProjectRoomsArchived).length > 0 && (
              <ProjectList
                currentRoom={currentRoom}
                collapsed={archivedCollapsed}
                isArchived
                onToggleCollapse={this.handleArchivedCollapse}
                projectHomeRoomMapping={projectHomeRoomMapping}
                projectRooms={currentUserProjectRoomsArchived}
                projects={currentUserProjectsArchived}
                searchValue={searchValueProcessed}
                title={`Archived ${config.flags.profile.teams ? "Projects" : "Teams"}`}
                unreadMessagesCountByRoom={unreadMessagesCountByRoom}
                setChannelTab={this.props.setChannelTab}
                lastRoomTab={lastRoomTab}
              />
            )}
          </ScrollableContent>
        </div>
      </Shortcuts>
    );
  }
}

Sidebar.propTypes = {
  archivedCollapsed: PropTypes.bool.isRequired,
  currentRoom: PropTypes.shape(),
  currentUser: PropTypes.shape(),
  currentUserMessageRooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentUserProjectRoomsActive: PropTypes.shape().isRequired,
  currentUserProjectRoomsArchived: PropTypes.shape().isRequired,
  currentUserProjectsActive: PropTypes.arrayOf(projectType),
  currentUserProjectsArchived: PropTypes.arrayOf(projectType),
  currentUserTeamRooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  projectHomeRoomMapping: PropTypes.objectOf(PropTypes.number),
  unreadMessagesCountByRoom: PropTypes.objectOf(PropTypes.number).isRequired,
  unreadMessagesLastTimestampByRoom: PropTypes.shape(),
  users: PropTypes.shape(),

  archivedCollapse: PropTypes.func.isRequired,
  archivedExpand: PropTypes.func.isRequired,
  logoutInit: PropTypes.func.isRequired,
  sidebarClose: PropTypes.func.isRequired,
  newMessageModalShow: PropTypes.func.isRequired,
  newProjectModalShow: PropTypes.func.isRequired,
  newTeamModalShow: PropTypes.func.isRequired,
  roomHideRequest: PropTypes.func.isRequired,
  setChannelTab: PropTypes.func.isRequired,

  location: PropTypes.shape(),
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser },
    entities: { projects, users },
    messages: { unreadMessagesCountByRoom, unreadMessagesLastTimestampByRoom },
    rooms: { mapping: projectHomeRoomMapping, lastRoomTab },
    sidebar: { archivedCollapsed },
  } = state;

  const currentUserMessageRooms = getCurrentUserMessageRooms(state);
  const currentUserProjectRoomsActive = getCurrentUserProjectRoomsActive(state);
  const currentUserProjectRoomsArchived = getCurrentUserProjectRoomsArchived(state);
  const currentUserProjectsActive = getCurrentUserProjectsActive(state);
  const currentUserProjectsArchived = getCurrentUserProjectsArchived(state);
  const currentUserTeamRooms = getCurrentUserTeamRooms(state);

  const currentProject = getCurrentProject(state);
  const currentRoom = getCurrentRoom(state);

  return {
    archivedCollapsed,
    currentProject,
    currentRoom,
    currentUser,
    currentUserMessageRooms,
    currentUserProjectRoomsActive,
    currentUserProjectRoomsArchived,
    currentUserProjectsActive,
    currentUserProjectsArchived,
    currentUserTeamRooms,
    projectHomeRoomMapping,
    projects,
    unreadMessagesCountByRoom,
    unreadMessagesLastTimestampByRoom,
    users,
    lastRoomTab,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    archivedCollapse: sidebar.archivedCollapse,
    archivedExpand: sidebar.archivedExpand,
    logoutInit,
    sidebarClose: sidebar.close,
    newMessageModalShow: newMessageModal.show,
    newProjectModalShow: newProjectModal.show,
    newTeamModalShow: newTeamModal.show,
    roomHideRequest: roomHide.request,
    setChannelTab,
  })(Sidebar)
);
