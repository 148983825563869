import { mapKeys } from "lodash";
import moment from "moment-timezone";
import {
  trackedClasses,
  GET_WEEKLY_ASSIGNMENT_HISTORY,
  VERSION_COMMENT_ADD,
  VERSION_COMMENT_DELETE,
  VERSION_COMMENT_UPDATE,
} from "actions/log";
import {
  CREATE_WEEKLY_ASSIGNMENT,
  GET_WEEKLY_ASSIGNMENTS,
  UPDATE_WEEKLY_ASSIGNMENT,
  SET_ASSIGNMENT_ACCESS,
} from "actions/schedule";

export const weeklyAssignment = (
  state = {
    loading: false,
    error: false,
    success: false,
  },
  action
) => {
  const { type } = action;

  switch (type) {
    case GET_WEEKLY_ASSIGNMENTS.REQUEST:
    case CREATE_WEEKLY_ASSIGNMENT.REQUEST:
    case UPDATE_WEEKLY_ASSIGNMENT.REQUEST:
    case SET_ASSIGNMENT_ACCESS.REQUEST:
      return { ...state, loading: true };
    case GET_WEEKLY_ASSIGNMENTS.SUCCESS:
    case CREATE_WEEKLY_ASSIGNMENT.SUCCESS:
    case UPDATE_WEEKLY_ASSIGNMENT.SUCCESS:
    case SET_ASSIGNMENT_ACCESS.SUCCESS:
      return { ...state, loading: false, success: true };
    case GET_WEEKLY_ASSIGNMENTS.FAILURE:
    case CREATE_WEEKLY_ASSIGNMENT.FAILURE:
    case UPDATE_WEEKLY_ASSIGNMENT.FAILURE:
    case SET_ASSIGNMENT_ACCESS.FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export const weeklyAssignmentHistory = (
  state = {
    loading: false,
    success: true,
    failure: false,
    nextItemsUrls: {},
    items: {},
    lastEditTime: null,
  },
  action
) => {
  const { type } = action;
  switch (type) {
    case GET_WEEKLY_ASSIGNMENT_HISTORY.REQUEST:
      return { ...state, loading: true, failure: false };
    case GET_WEEKLY_ASSIGNMENT_HISTORY.SUCCESS: {
      const { weeklyAssignmentId, response } = action;
      let updatedEditTime = state.lastEditTime;
      if (response.count > 0) {
        const timestamp = response.results[0]?.date;
        if (timestamp) {
          const newEditTime = moment(timestamp);
          if (!updatedEditTime || newEditTime.isAfter(updatedEditTime)) {
            updatedEditTime = newEditTime;
          }
        }
      }
      const itemsResponse = mapKeys(response.results, "id");
      const weeklyAssignmentHistory = state.items[weeklyAssignmentId]
        ? { ...state.items[weeklyAssignmentId] }
        : {};
      return {
        ...state,
        loading: false,
        failure: false,
        nextItemsUrls: {
          ...state.nextItemsUrls,
          [weeklyAssignmentId]: response.nextPage,
        },
        items: {
          ...state.items,
          [weeklyAssignmentId]: {
            ...weeklyAssignmentHistory,
            ...itemsResponse,
          },
        },
        lastEditTime: updatedEditTime,
      };
    }
    case GET_WEEKLY_ASSIGNMENT_HISTORY.FAILURE:
      return { ...state, loading: false, failure: true };
    case VERSION_COMMENT_ADD.SUCCESS: {
      const { response, trackedClass } = action;
      if (response.objectId && trackedClass === trackedClasses.weeklyAssignment) {
        const weeklyAssignmentHistory = state.items[response.objectId]
          ? { ...state.items[response.objectId] }
          : {};
        const version = weeklyAssignmentHistory[response.version]
          ? { ...weeklyAssignmentHistory[response.version] }
          : {
              comments: [],
            };
        version.comments.push(response);
        return {
          ...state,
          items: {
            ...state.items,
            [response.objectId]: {
              ...weeklyAssignmentHistory,
              [response.version]: version,
            },
          },
        };
      }
      return state;
    }
    case VERSION_COMMENT_DELETE.SUCCESS: {
      const { response, versionCommentId, trackedClass } = action;
      if (response.objectId && trackedClass === trackedClasses.weeklyAssignment) {
        const weeklyAssignmentHistory = state.items[response.objectId]
          ? { ...state.items[response.objectId] }
          : {};
        const version = weeklyAssignmentHistory[response.version]
          ? { ...weeklyAssignmentHistory[response.version] }
          : {
              comments: [],
            };
        return {
          ...state,
          items: {
            ...state.items,
            [response.objectId]: {
              ...weeklyAssignmentHistory,
              [response.version]: {
                ...version,
                comments: version.comments.filter((comment) => comment.id !== versionCommentId),
              },
            },
          },
        };
      }
      return state;
    }
    case VERSION_COMMENT_UPDATE.SUCCESS: {
      const { response, trackedClass } = action;
      if (response.objectId && trackedClass === trackedClasses.weeklyAssignment) {
        const weeklyAssignmentHistory = state.items[response.objectId]
          ? { ...state.items[response.objectId] }
          : {};
        const version = weeklyAssignmentHistory[response.version]
          ? { ...weeklyAssignmentHistory[response.version] }
          : {
              comments: [],
            };
        const updatedComments = version.comments.map((comment) => {
          if (comment.id === response.id) {
            return response;
          }
          return comment;
        });
        return {
          ...state,
          items: {
            ...state.items,
            [response.objectId]: {
              ...weeklyAssignmentHistory,
              [response.version]: {
                ...version,
                comments: updatedComments,
              },
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};
