import classnames from "classnames";
import { isString, startCase, union, uniqBy } from "lodash";
import generate from "project-name-generator";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Icon, Modal, Segment } from "semantic-ui-react";
import localforage from "localforage";
import moment from "moment-timezone";

import config from "config";
import { isBldAiDomain } from "utils";
import { newProjectModal } from "actions/modals";
import { getSuggestedTimes, projectBudgetUpdate, projectUpdate } from "actions/project";
import { token as tokenService } from "services";
import { defaultMeetingsCreate } from "services/api/chat";
import { buildTeamProject } from "services/api/user";
import { filterUsers } from "utils/user";
import { Roles } from "utils/constants/project";

import { MEETING_TEMPLATE, MEETING_TYPES, MEETINGS, WELCOME_MESSAGE_KEY } from "./constants";
import People from "./People";
import Review from "./Review";
import Steps from "./Steps";
import Tools from "./Tools";
import Proposal from "./Proposal";

import STEPS from "./steps.json";

import * as styles from "./styles.module.scss";

const initialState = {
  step: 0,
  budgetUpdated: {},
  project: "",
  tools: ["chat", "call", "meetings", "poll", "feedback", "health", "gitlab"],
  members: [],
  message: "",
  messageEdited: false,
  creating: false,
  done: false,
  error: null,
  roomId: null,
  companyName: "",
  ceo: "",
  logoUrl: "",
};

class Team extends React.Component {
  constructor(props) {
    super(props);

    const meetings = {};
    MEETING_TYPES.forEach((meetingType) => {
      meetings[meetingType] = {
        checked: false,
        suggestedStart: MEETINGS[meetingType].suggestedStart,
      };
    });

    this.state = {
      ...initialState,
      meetings,
      members: [this.getCreatorInitialData()],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        members: [this.getCreatorInitialData()],
      });
    }
  }

  getCreatorInitialData = () => {
    const {
      currentUser: { displayName, email, hourlyRate, slug },
    } = this.props;
    return {
      displayName,
      email,
      hourlyRate,
      role: isBldAiDomain(email) ? Roles.bldAiAdmin : Roles.clientAdmin,
      slug,
    };
  };

  getMeetingData = (calendar, project) => {
    const { currentUser, timezone } = this.props;
    const { meetings, members } = this.state;
    const meetingData = [];
    const nextMonday = moment()
      .tz(timezone)
      .day(8)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const guests = members.map((member) => (isString(member) ? member : member.slug));
    guests.unshift(currentUser.slug);
    Object.keys(meetings).forEach((meetingType) => {
      if (meetings[meetingType].checked) {
        meetingData.push({
          ...MEETING_TEMPLATE,
          timezone,
          start: moment(nextMonday)
            .tz(timezone)
            .add(meetings[meetingType].suggestedStart, "hours")
            .toISOString(),
          end: moment(nextMonday)
            .tz(timezone)
            .add(meetings[meetingType].suggestedStart + 1, "hours")
            .toISOString(),
          title: MEETINGS[meetingType].name,
          ruleParams: MEETINGS[meetingType].ruleParams,
          project,
          calendar,
          guests,
        });
      }
    });
    return meetingData;
  };

  setStep = (step) => {
    this.setState({
      step: Math.max(Math.min(step, 5), 0),
    });
  };

  getLinkedBudget = (projectId) => {
    const { budgetUpdated } = this.state;
    const linkedBudget = Object.values(budgetUpdated).map((item) => {
      const budgetItem = {
        ...item,
        amount: item.amount || 0,
      };
      if (budgetItem.isNew) {
        delete budgetItem.isNew;
        delete budgetItem.id;
        budgetItem.project = projectId;
      }
      return budgetItem;
    });
    return linkedBudget;
  };

  handleBudgetChange = (budgetUpdated) => {
    this.setState({ budgetUpdated });
  };

  handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { newProjectModalHide } = this.props;
    newProjectModalHide();

    const { meetings } = this.state;
    MEETING_TYPES.forEach((meetingType) => {
      meetings[meetingType].checked = false;
    });
    this.setState({ ...initialState, meetings });
  };

  handleCheckboxChange = (e, { name, value }) => {
    if (name === "selectAll") {
      const { meetings } = this.state;
      MEETING_TYPES.forEach((meetingType) => {
        meetings[meetingType].checked = e.target.checked;
      });
      this.setState({ meetings });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        meetings: {
          ...prevState.meetings,
          [name]: {
            ...prevState.meetings[name],
            checked: value,
          },
        },
      }));
    }
  };

  handleChange = (e, { name, value, ...data }) => {
    if (data.type === "checkbox") {
      if (data.checked) {
        this.setState((prevState) => ({
          [name]: union(prevState[name], [value]),
        }));
      } else {
        this.setState((prevState) => ({
          [name]: prevState[name].filter((item) => item !== value),
        }));
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleRegisteredMember = (value) =>
    "role" in value
      ? value
      : {
          ...value,
          hourlyRate: 0,
          role: isBldAiDomain(value.email) ? Roles.bldAiAdmin : Roles.clientMember,
        };

  handleEmailOnlyMember = (value) => ({
    email: value,
    displayName: value,
    hourlyRate: 0,
    role: isBldAiDomain(value) ? Roles.bldAiAdmin : Roles.clientMember,
  });

  handleMemberChange = (values) => {
    const { currentUserEmail } = this.props;
    const newMembers = values.filter((user) => user !== currentUserEmail);

    this.setState({
      members: uniqBy(
        newMembers.map((member) =>
          isString(member)
            ? this.handleEmailOnlyMember(member)
            : this.handleRegisteredMember(member)
        ),
        "email"
      ),
    });
  };

  handleGenerateName = () => {
    const randomWord = startCase(generate({ words: 3 }).spaced);
    this.setState({
      project: randomWord,
    });
  };

  handleToolsInitialize = async () => {
    const { meetings, members } = this.state;
    const guests = members.map((member) => (isString(member) ? member : member.slug));
    const response = await this.handleGetSuggestedTimes(guests);
    const newMeetingState = {};
    Object.keys(response).forEach((meetingType) => {
      newMeetingState[meetingType] = {
        ...meetings[meetingType],
        suggestedStart: response[meetingType],
      };
    });
    this.setState({ meetings: newMeetingState });
  };

  handleGetSuggestedTimes = (guests) =>
    new Promise((resolve, reject) => this.props.getSuggestedTimes(guests, true, resolve, reject));

  handleProjectBudgetUpdate = (projectId, budget) =>
    new Promise((resolve, reject) =>
      this.props.projectBudgetUpdate(projectId, budget, resolve, reject)
    );

  handleNewProjectData = (projectId) =>
    new Promise((resolve, reject) => this.props.projectUpdate(projectId, {}, resolve, reject));

  handleFinish = () => {
    this.setState(
      {
        creating: true,
        step: 5,
      },
      () => {
        tokenService.getAuthToken().then((token) => {
          buildTeamProject(token, {
            projectName: this.state.project,
            members: this.state.members.map((member) => ({
              id: member.slug || member.email,
              rate: member.hourlyRate,
              role: member.role,
              displayName: member.displayName,
            })),
            tools: this.state.tools,
            company: {
              name: this.state.companyName.trim(),
              ceo: this.state.ceo.trim(),
              logo_url: this.state.logoUrl.trim(),
            },
            message: this.state.message,
          }).then(({ response, error }) => {
            if (response) {
              const { calendarId, projectId, roomId } = response;
              this.handleNewProjectData(projectId);
              const linkedBudget = this.getLinkedBudget(projectId);
              this.handleProjectBudgetUpdate(projectId, linkedBudget);
              const guests = this.state.members.map((member) =>
                isString(member) ? member : member.slug
              );
              guests.unshift(this.props.currentUser.slug);
              const meetingData = this.getMeetingData(calendarId, projectId);
              const objData = {
                events: meetingData,
                calendar: calendarId,
                emailNotif: true,
                guests,
              };
              defaultMeetingsCreate(token, roomId, objData).then(() => {
                this.setState({ creating: false, done: true, roomId }, () => this.handleDone());
                localforage.removeItem(WELCOME_MESSAGE_KEY);
              });
            } else if (error) {
              this.setState({
                creating: false,
                error,
              });
            }
          });
        });
      }
    );
  };

  handleSetMessage = (message) => {
    this.setState({ message });
  };

  handleNextStep = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { step } = this.state;
    if (step === 0) {
      this.handleToolsInitialize();
    }
    if (step < STEPS.length - 1) {
      this.setState({ step: step + 1 });
    }
  };

  handlePreviousStep = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { step } = this.state;
    if (step > 0) {
      this.setState({ step: step - 1 });
    }
  };

  handleDone = () => {
    const { history } = this.props;
    const { roomId } = this.state;
    this.handleClose();
    history.replace(`/chat/r/${roomId}`);
  };

  renderPeople() {
    const { currentUser, currentUserEmail, users } = this.props;
    const { members, project } = this.state;

    return (
      <People
        currentUser={currentUser}
        currentUserEmail={currentUserEmail}
        members={members}
        project={project}
        users={users}
        onProjectNameChange={this.handleChange}
        onMemberChange={this.handleMemberChange}
        onGenerateName={this.handleGenerateName}
        onNext={this.handleNextStep}
      />
    );
  }

  renderTools() {
    const { timezone } = this.props;
    const { meetings, step, tools } = this.state;

    return (
      <Tools
        tools={tools}
        step={step}
        meetings={meetings}
        onChange={this.handleChange}
        onCheckboxChange={this.handleCheckboxChange}
        onSubmit={this.handleNextStep}
        onPrevious={this.handlePreviousStep}
        onNext={this.handleNextStep}
        timezone={timezone}
      />
    );
  }

  renderProposal() {
    const { companyName, ceo, logoUrl } = this.state;

    return (
      <Proposal
        companyName={companyName}
        ceo={ceo}
        logoUrl={logoUrl}
        onChange={this.handleChange}
        onPrevious={this.handlePreviousStep}
        onNext={this.handleNextStep}
      />
    );
  }

  renderInvitation() {
    const { step, project, message, messageEdited } = this.state;
    const { timezone } = this.props;

    return (
      <Review
        project={project}
        message={message}
        messageEdited={messageEdited}
        onBudgetChange={this.handleBudgetChange}
        onChange={this.handleChange}
        onSubmit={this.handleFinish}
        onPrevious={this.handlePreviousStep}
        onNext={this.handleNextStep}
        onFinish={this.handleFinish}
        step={step}
        timezone={timezone}
      />
    );
  }

  renderCreating = () => (
    <Segment basic className={styles.creating}>
      <div>
        <Icon size="massive" name="spinner" loading />
      </div>
      <p>Your {config.flags.profile.teams ? "project" : "team"} is being created...</p>
    </Segment>
  );

  render() {
    const { open } = this.props;
    const { step, creating, done } = this.state;
    return (
      <Modal
        basic
        centered={false}
        id={styles.newProjectModal}
        className={classnames("center", styles.mainModal)}
        closeIcon="close"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        dimmer="inverted"
        onClose={this.handleClose}
        open={open}
      >
        <Modal.Header>
          {config.flags.profile.teams ? "Start a Project" : "Form New Team"}
        </Modal.Header>
        <Modal.Content>
          <Steps step={step} setStep={this.setStep} />

          {!creating && !done && step === 0 && this.renderPeople()}
          {!creating && !done && step === 1 && this.renderTools()}
          {!creating && !done && step === 2 && this.renderProposal()}
          {!creating && !done && step === 3 && this.renderInvitation()}

          {creating && !done && this.renderCreating()}
        </Modal.Content>
      </Modal>
    );
  }
}

Team.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  currentUserEmail: PropTypes.string,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
  open: PropTypes.bool,
  newProjectModalHide: PropTypes.func,
  timezone: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()),
  getSuggestedTimes: PropTypes.func.isRequired,
  projectBudgetUpdate: PropTypes.func.isRequired,
  projectUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser },
    modals: { showNewProjectModal: open },
  } = state;
  const users = Object.keys(state.entities.users)
    .map((slug) => state.entities.users[slug])
    .filter((user) => user.slug !== currentUser.slug);
  const timezone = currentUser.timezoneDisplay;
  const { email: currentUserEmail } = currentUser;
  return {
    currentUser,
    currentUserEmail,
    open,
    timezone,
    users: filterUsers(users),
  };
}

export default connect(mapStateToProps, {
  getSuggestedTimes: getSuggestedTimes.request,
  newProjectModalHide: newProjectModal.hide,
  projectBudgetUpdate: projectBudgetUpdate.request,
  projectUpdate: projectUpdate.request,
})(injectIntl(withRouter(Team)));
