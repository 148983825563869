const PollOptionTypeMultipleChoiceSingleAnswerText = "Multiple choice – single answer";
const PollOptionTypeMultipleChoiceSingleAnswerValue = "multiple_choice_single_answer";
const PollOptionTypeMultipleChoiceMultipleAnswerText = "Multiple choice – multiple answer";
const PollOptionTypeMultipleChoiceMultipleAnswerValue = "multiple_choice_multiple_answer";
const PollOptionTypePointAllocationText = "Point allocation";
const PollOptionTypePointAllocationValue = "point_allocation";
// const PollOptionTypeRankingText = 'Ranking';
// const PollOptionTypeRankingValue = 'ranking';

export const PollOptionType = {
  multipleChoiceSingleAnswer: PollOptionTypeMultipleChoiceSingleAnswerValue,
  multipleChoiceMultipleAnswer: PollOptionTypeMultipleChoiceMultipleAnswerValue,
  pointAllocation: PollOptionTypePointAllocationValue,
  // ranking: PollOptionTypeRankingValue,

  all: [
    PollOptionTypeMultipleChoiceSingleAnswerValue,
    PollOptionTypeMultipleChoiceMultipleAnswerValue,
    PollOptionTypePointAllocationValue,
    // PollOptionTypeRankingValue,
  ],
  options: [
    {
      key: PollOptionTypeMultipleChoiceSingleAnswerValue,
      text: PollOptionTypeMultipleChoiceSingleAnswerText,
      value: PollOptionTypeMultipleChoiceSingleAnswerValue,
    },
    {
      key: PollOptionTypeMultipleChoiceMultipleAnswerValue,
      text: PollOptionTypeMultipleChoiceMultipleAnswerText,
      value: PollOptionTypeMultipleChoiceMultipleAnswerValue,
    },
    {
      key: PollOptionTypePointAllocationValue,
      text: PollOptionTypePointAllocationText,
      value: PollOptionTypePointAllocationValue,
    },
    // {
    //   key: PollOptionTypeRankingValue,
    //   text: PollOptionTypeRankingText,
    //   value: PollOptionTypeRankingValue,
    // },
  ],
};

const PollRecurringTypeNeverText = "Does not repeat";
const PollRecurringTypeNeverValue = null;
const PollRecurringTypeDailyText = "Daily";
const PollRecurringTypeDailyValue = "daily";
const PollRecurringTypeWeeklyText = "Weekly";
const PollRecurringTypeWeeklyValue = "weekly";
const PollRecurringTypeMonthlyText = "Monthly";
const PollRecurringTypeMonthlyValue = "monthly";

export const PollRecurringType = {
  never: PollRecurringTypeNeverValue,
  daily: PollRecurringTypeDailyValue,
  weekly: PollRecurringTypeWeeklyValue,
  monthly: PollRecurringTypeMonthlyValue,

  all: [
    PollRecurringTypeNeverValue,
    PollRecurringTypeDailyValue,
    PollRecurringTypeWeeklyValue,
    PollRecurringTypeMonthlyValue,
  ],
  options: [
    {
      key: PollRecurringTypeNeverValue,
      value: PollRecurringTypeNeverValue,
      text: PollRecurringTypeNeverText,
    },
    {
      key: PollRecurringTypeDailyValue,
      value: PollRecurringTypeDailyValue,
      text: PollRecurringTypeDailyText,
    },
    {
      key: PollRecurringTypeWeeklyValue,
      value: PollRecurringTypeWeeklyValue,
      text: PollRecurringTypeWeeklyText,
    },
    {
      key: PollRecurringTypeMonthlyValue,
      value: PollRecurringTypeMonthlyValue,
      text: PollRecurringTypeMonthlyText,
    },
  ],
  labels: {
    [PollRecurringTypeNeverValue]: PollRecurringTypeNeverText,
    [PollRecurringTypeDailyValue]: PollRecurringTypeDailyText,
    [PollRecurringTypeWeeklyValue]: PollRecurringTypeWeeklyText,
    [PollRecurringTypeMonthlyValue]: PollRecurringTypeMonthlyText,
  },
};
