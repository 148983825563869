import { createRequestTypes, action } from ".";

export const GET_WEEKLY_ASSIGNMENTS = createRequestTypes("GET_WEEKLY_ASSIGNMENTS");
export const CREATE_WEEKLY_ASSIGNMENT = createRequestTypes("CREATE_WEEKLY_ASSIGNMENT");
export const UPDATE_WEEKLY_ASSIGNMENT = createRequestTypes("UPDATE_WEEKLY_ASSIGNMENT");
export const SET_ASSIGNMENT_ACCESS = createRequestTypes("SET_ASSIGNMENT_ACCESS");

export const getWeeklyAssignments = {
  request: () => action(GET_WEEKLY_ASSIGNMENTS.REQUEST),
  success: (response) => action(GET_WEEKLY_ASSIGNMENTS.SUCCESS, { response }),
  failure: (error) => action(GET_WEEKLY_ASSIGNMENTS.FAILURE, { error }),
};

export const createWeeklyAssignment = {
  request: (data) => action(CREATE_WEEKLY_ASSIGNMENT.REQUEST, { data }),
  success: (response) => action(CREATE_WEEKLY_ASSIGNMENT.SUCCESS, { response }),
  failure: (error) => action(CREATE_WEEKLY_ASSIGNMENT.FAILURE, { error }),
};

export const updateWeeklyAssignment = {
  request: (id, data) => action(UPDATE_WEEKLY_ASSIGNMENT.REQUEST, { id, data }),
  success: (response) => action(UPDATE_WEEKLY_ASSIGNMENT.SUCCESS, { response }),
  failure: (error) => action(UPDATE_WEEKLY_ASSIGNMENT.FAILURE, { error }),
};

export const setAssignmentAccess = {
  request: (data, resolve, reject) =>
    action(SET_ASSIGNMENT_ACCESS.REQUEST, { data, resolve, reject }),
  success: (response) => action(SET_ASSIGNMENT_ACCESS.SUCCESS, { response }),
  failure: (error) => action(SET_ASSIGNMENT_ACCESS.FAILURE, { error }),
};
