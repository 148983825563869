import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { getCurrentRoom } from "reducers/selectors";

import styles from "../styles.module.scss";

import SidePanelPinned from "./SidePanelPinned";
import SidePanelSearch from "./SidePanelSearch";
import SidePanelThread from "./SidePanelThread";
import SidePanelChannelInformation from "./SidePanelChannelInformation";
import SidePanelMembers from "./SidePanelMembers";
import SidePanelAttachments from "./SidePanelAttachments";

class SidePanel extends React.PureComponent {
  render() {
    const { children, currentRoom, onMessageOpenThread, visible } = this.props;

    return (
      <div className={classnames(styles.messageWithSidepanel, visible ? styles.visible : "")}>
        {children}

        <SidePanelPinned key={`PINNED-${currentRoom.id}`} onOpenThread={onMessageOpenThread} />
        <SidePanelThread key={`THREAD-${currentRoom.id}`} />
        <SidePanelSearch key={`SEARCH-${currentRoom.id}`} onOpenThread={onMessageOpenThread} />
        <SidePanelMembers key={`MEMBERS-${currentRoom.id}`} />
        <SidePanelChannelInformation key={`CHANNEL_INFO-${currentRoom.id}`} />
        <SidePanelAttachments key={`ATTACHMENTS-${currentRoom.id}`} />
      </div>
    );
  }
}

SidePanel.propTypes = {
  children: PropTypes.node.isRequired,
  currentRoom: PropTypes.shape(),
  onMessageOpenThread: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

SidePanel.defaultProps = {
  currentRoom: null,
};

function mapStateToProps(state) {
  const {
    sidePanel: { visible },
  } = state;

  const currentRoom = getCurrentRoom(state);

  return { currentRoom, visible };
}

export default connect(mapStateToProps)(SidePanel);
