import classnames from "classnames";
import { times } from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import {
  deleteMessage,
  performAction,
  pinMessage,
  pollDelegate,
  pollUpdate,
  pollMultipleVote,
  pusherSendMessage,
  roomMeetingJoin,
  unpinMessage,
} from "actions/chat";
import ContentLoader from "components/ContentLoader";
import deferComponentRender from "components/DeferRender";

import MessageListItem from "./MessageListItem";
import styles from "./styles.module.scss";

class MessageList extends React.PureComponent {
  componentDidMount() {
    if (this.props.scrollToBottom) {
      this.props.scrollToBottom();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.scrollToBottom && this.props.scrollToBottom) {
      this.props.scrollToBottom();
    }
  }

  _getChannelDisplay = () => {
    const { currentUser } = this.props;
    switch (currentUser.channelDisplay) {
      case "fixed_width_center":
        return classnames(styles.contentLoaderContainer, styles.centered);
      case "fixed_width_left":
        return styles.contentLoaderContainer;
      case "full_width_left":
        return classnames(styles.contentLoaderContainer, styles.fullWidth);
      default:
        return styles.contentLoaderContainer;
    }
  };

  onMeetingJoin = (join, event) => {
    const { room } = this.props;
    this.props.roomMeetingJoin({ join, rid: room.id, event });
  };

  onMessagePin = (message) => {
    const { room } = this.props;
    const roomId = room.id;

    this.props.pinMessage({
      id: roomId,
      messageId: message.id,
    });
  };

  onMessageResend = (message) => {
    const { currentUser, room, token } = this.props;
    const { content, files, id: sendId } = message;

    const roomId = room.id;

    this.props.pusherSendMessage({
      content,
      files,
      sendId,
      token,
      id: roomId,
      user: currentUser,
      root: message.root,
    });
  };

  onMessageUnpin = (message) => {
    const { room } = this.props;
    const roomId = room.id;

    this.props.unpinMessage({
      id: roomId,
      messageId: message.id,
    });
  };

  onPollDelegate = (poll, delegateSlug) => {
    this.props.pollDelegate(poll, delegateSlug);
  };

  onPollVote = (poll, votes) => {
    this.props.pollMultipleVote(poll, votes);
  };

  onPollArchive = (poll) => {
    const data = { id: poll.id, isArchived: !poll.isArchived };
    this.props.pollUpdate(data);
  };

  onPollPin = (poll) => {
    const data = { id: poll.id, isPinned: !poll.isPinned };
    this.props.pollUpdate(data);
  };

  renderLoading() {
    const { loading, messageOrdering, room, verticalAlign } = this.props;
    let hidden = false;
    let contentLoaders = 10;
    if (room) {
      hidden = room.isComplete && (!loading || messageOrdering.length > 0);
      // Do not render more than 10 content initially
      contentLoaders = Math.min(room.messagesCount, 10);
    }

    // if (room.isComplete && !loading) {
    //   return null;
    // }

    // if (room.isComplete && messageOrdering.length > 0) {
    //   return null;
    // }

    return (
      <div style={{ display: hidden ? "none" : "table-cell", verticalAlign }}>
        {times(contentLoaders).map((i) => (
          <div className={this._getChannelDisplay()} key={`contentLoader${i}`}>
            <ContentLoader />
          </div>
        ))}
      </div>
    );
  }

  renderMessages() {
    const {
      currentUser,
      deleteMessageRequest,
      isThread,
      isPinned,
      onOpenThread,
      messages,
      messageOrdering,
      performActionInit,
      room,
      roomUsers,
      unreadMessages,
      users,
      verticalAlign,
    } = this.props;

    if (messageOrdering.length === 0) {
      return null;
    }

    let hidden = true;
    if (room) {
      if (!room.isComplete) {
        return null;
      }
      hidden = messageOrdering.length === 0 || !room.isComplete;
    }

    const unreadMessageCount = unreadMessages.length;

    const messageList = [];
    let previousCreated = moment(0);
    let showHeader = true;
    let showRoot = false;
    let previousAuthor = null;
    let previousPoll = null;
    let previousEvent = null;

    messageOrdering.forEach((messageId, index) => {
      const message = messages[messageId];
      const prevMessage = messages[messageOrdering[index - 1]] || null;
      const created = moment(message.created);
      const createdDate = created.format("ddd, LL");
      const optionsDirection = index < 2 || index < messageOrdering.length - 6 ? "down" : "up";
      const previousDate = previousCreated.format("ddd, LL");

      if (previousAuthor === message.author && !previousPoll && !previousEvent) {
        showHeader = false;
      }

      if (message.pinnedBy) {
        showHeader = true;
      }

      if (
        message.root &&
        prevMessage &&
        message.root !== prevMessage.root &&
        message.root !== prevMessage.id
      ) {
        showHeader = true;
      } else if (message.root && !prevMessage) {
        showHeader = true;
      }

      if (isThread && index === 1) {
        showHeader = true;
        messageList.push(
          <Divider
            key={`divider-thread-${index}`}
            horizontal
            fitted
            className={styles.threadDivider}
          >
            <span>
              {messageOrdering.length - 1} {messageOrdering.length - 1 > 1 ? "replies" : "reply"}
            </span>
          </Divider>
        );
      }

      if (prevMessage && prevMessage.root && !message.root) {
        showHeader = true;
      }

      if (previousDate !== createdDate) {
        showHeader = true;
        showRoot = true;
        messageList.push(
          <Divider key={`divider-${createdDate}`} horizontal className={styles.dateDivider}>
            <span>{createdDate}</span>
          </Divider>
        );
      }

      if (!isThread && prevMessage) {
        if (message.root === prevMessage.root || message.root === prevMessage.id) {
          showRoot = false;
        } else {
          showRoot = true;
        }
      } else if (!isThread && !prevMessage) {
        showRoot = true;
      }

      if (previousCreated && created.diff(previousCreated) > 60 * 1000) {
        showHeader = true;
      }

      if (message.poll) {
        showHeader = true;
      }

      if (message.event) {
        showHeader = true;
      }

      if (unreadMessages.length > 0 && unreadMessages[0] === message.id) {
        showHeader = true;
        showRoot = true;
        const divider = (
          <Divider key="divider-unread-messages" horizontal className={styles.newMessageDivider}>
            <span>
              New Message
              {unreadMessageCount > 1 ? "s" : ""}
            </span>
          </Divider>
        );
        messageList.push(divider);
      }

      messageList.push(
        <MessageListItem
          key={message.id}
          currentUser={currentUser}
          deleteMessage={deleteMessageRequest}
          message={message}
          rootMessage={message.root ? messages[message.root] : message}
          onMeetingJoin={this.onMeetingJoin}
          onOpenThread={onOpenThread}
          onPin={this.onMessagePin}
          onPollArchive={this.onPollArchive}
          onPollPin={this.onPollPin}
          onPollDelegate={this.onPollDelegate}
          onPollVote={this.onPollVote}
          onResend={this.onMessageResend}
          onUnpin={this.onMessageUnpin}
          optionsDirection={optionsDirection}
          performActionInit={performActionInit}
          room={room}
          roomUsers={roomUsers}
          showHeader={showHeader}
          showRoot={showRoot}
          isThread={isThread}
          isPinned={isPinned}
          users={users}
        />
      );

      previousAuthor = message.author;
      previousCreated = created;
      previousPoll = message.poll;
      previousEvent = message.event;
      showHeader = true;
    });

    return (
      <div style={{ display: hidden ? "none" : "table-cell", verticalAlign }}>{messageList}</div>
    );
  }

  render() {
    const { verticalAlign } = this.props;

    return (
      <div className={classnames(styles.list, verticalAlign === "top" ? styles.top : null)}>
        {this.renderLoading()}
        {this.renderMessages()}
      </div>
    );
  }
}

MessageList.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  deleteMessageRequest: PropTypes.func.isRequired,
  isThread: PropTypes.bool,
  isPinned: PropTypes.bool,
  loading: PropTypes.bool,
  messages: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      author: PropTypes.string,
      created: PropTypes.number,
      event: PropTypes.shape(),
      message: PropTypes.string,
      pinnedBy: PropTypes.string,
      poll: PropTypes.shape(),
      root: PropTypes.number,
    })
  ),
  messageOrdering: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  performActionInit: PropTypes.func.isRequired,
  pinMessage: PropTypes.func.isRequired,
  pollDelegate: PropTypes.func.isRequired,
  pollUpdate: PropTypes.func.isRequired,
  pollMultipleVote: PropTypes.func.isRequired,
  pusherSendMessage: PropTypes.func.isRequired,
  room: PropTypes.shape(),
  roomMeetingJoin: PropTypes.func.isRequired,
  roomUsers: PropTypes.arrayOf(PropTypes.shape()),
  scrollToBottom: PropTypes.func,
  token: PropTypes.string,
  unpinMessage: PropTypes.func.isRequired,
  unreadMessages: PropTypes.arrayOf(PropTypes.number),
  users: PropTypes.shape().isRequired,
  verticalAlign: PropTypes.oneOf(["top", "bottom"]),

  onOpenThread: PropTypes.func,
};

MessageList.defaultProps = {
  isThread: false,
  loading: false,
  messages: [],
  roomUsers: [],
  unreadMessages: [],
  verticalAlign: "bottom",
};

function mapStateToProps(state) {
  const {
    auth: { token },
  } = state;
  return {
    token,
  };
}

export default connect(mapStateToProps, {
  deleteMessageRequest: deleteMessage.request,
  performActionInit: performAction.init,
  pinMessage: pinMessage.request,
  pollDelegate: pollDelegate.request,
  pollMultipleVote: pollMultipleVote.request,
  pollUpdate: pollUpdate.request,
  pusherSendMessage: pusherSendMessage.request,
  roomMeetingJoin: roomMeetingJoin.request,
  unpinMessage: unpinMessage.request,
})(deferComponentRender(MessageList));
