export const DEFAULT_EVENT = {
  id: null,
  title: "",
  isAvailable: false,
  isOutOfOffice: false,
  isWorkLog: true,
  callUrl: "",
  roomId: null,
  project: null,
  projectGeneralRoomId: null,
  invitedGuests: [],
  invitedOptionalGuests: [],
  activity: "",
  issueNumber: "",
  comment: "",
  hours: "",
  billable: true,
  organizerSlug: "",
  rootEventId: null,
  rootEvent: null,
  endRecurringPeriod: null,
  rule: {
    frequency: "",
    params: "",
  },
  originalStart: null,
  originalEnd: null,
};
