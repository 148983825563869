import { Dropdown as BaseDropdown } from "semantic-ui-react";

export default class Dropdown extends BaseDropdown {
  openOnSpace = (e) => {
    if (e.keyCode !== 32) return;
    if (typeof this.props.open !== "undefined") return;
    if (this.state.open) return;
    e.preventDefault();
    this.open(e);
  };
}
