import React from "react";

import styles from "./styles.module.scss";

const TimezoneViewerLegend = () => (
  <div className={styles.timezoneViewerLegend}>
    <div>
      <i className={styles.busy} />
      <span>Busy</span>
    </div>
    <div>
      <i className={styles.available} />
      <span>Available</span>
    </div>
    <div>
      <i className={styles.outsideWorkingHours} />
      <span>Outside working hours</span>
    </div>
    <div>
      <i className={styles.unavailableForWork} />
      <span>Unavailable for work</span>
    </div>
  </div>
);

export default TimezoneViewerLegend;
