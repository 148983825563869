import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";

import { dateString, dateTimeString } from "types";

const DateTime = ({
  className,
  dateTime,
  prefixExpired,
  prefixOngoing,
  suffixExpired,
  suffixOngoing,
}) => {
  const hasExpired = !dateTime || moment(dateTime).isSameOrBefore(moment());
  const prefix = hasExpired ? prefixExpired : prefixOngoing;
  const suffix = hasExpired ? suffixExpired : suffixOngoing;

  const dateTimeTitle = moment(dateTime).format("MMM D, YYYY h:mmA");
  const millisecondsLeft = moment(dateTime).diff(moment());

  let dateTimeFormatted;
  if (millisecondsLeft > 0 && millisecondsLeft <= 3600000) {
    const millisecondsLeftDuration = moment.duration(millisecondsLeft);
    const minutes = millisecondsLeftDuration.minutes();
    const seconds = `${millisecondsLeftDuration.seconds()}`.padStart(2, "0");

    dateTimeFormatted = `${minutes}:${seconds}`;
  } else {
    dateTimeFormatted = moment(dateTime).fromNow(true);
  }

  return (
    <span className={className}>
      {prefix}{" "}
      <time dateTime={dateTime} title={dateTimeTitle}>
        {dateTimeFormatted} {suffix}
      </time>
    </span>
  );
};

DateTime.propTypes = {
  className: PropTypes.string,
  dateTime: PropTypes.oneOfType([dateString, dateTimeString]),
  prefixExpired: PropTypes.string,
  prefixOngoing: PropTypes.string,
  suffixExpired: PropTypes.string,
  suffixOngoing: PropTypes.string,
};

export default DateTime;
