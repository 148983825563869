import { ROOM_CALENDAR, ROOM_MEMBER_CALENDAR, ROOM_MEETING_LIST } from "../actions/chat";
import {
  CALENDAR,
  EVENT,
  EVENT_DETAIL,
  EVENT_OCCURRENCE_DETAIL,
  SET_SELECTED_EVENT,
} from "../actions/calendar";

const initialState = {
  loading: false,
  error: false,
  errorDetails: null,
  selectedEvent: {
    id: null,
    title: "",
    start: "",
    end: "",
    callUrl: "",
    roomId: null,
    timezoneDisplay: "",
  },
};

export default function calendar(state = initialState, action) {
  if (
    action.type === CALENDAR.REQUEST ||
    action.type === EVENT.REQUEST ||
    action.type === EVENT_DETAIL ||
    action.type === ROOM_CALENDAR.REQUEST ||
    action.type === ROOM_MEMBER_CALENDAR.REQUEST ||
    action.type === ROOM_MEETING_LIST.REQUEST ||
    action.type === EVENT_OCCURRENCE_DETAIL.REQUEST
  ) {
    return {
      ...state,
      loading: true,
      errorDetails: null,
    };
  }

  if (
    action.type === CALENDAR.SUCCESS ||
    action.type === EVENT.SUCCESS ||
    action.type === EVENT_DETAIL.SUCCESS ||
    action.type === ROOM_CALENDAR.SUCCESS ||
    action.type === ROOM_MEMBER_CALENDAR.SUCCESS ||
    action.type === ROOM_MEETING_LIST.SUCCESS ||
    action.type === EVENT_OCCURRENCE_DETAIL.SUCCESS
  ) {
    return {
      ...state,
      loading: false,
      error: false,
      errorDetails: null,
    };
  }

  if (
    action.type === CALENDAR.FAILURE ||
    action.type === EVENT.FAILURE ||
    action.type === EVENT_DETAIL.FAILURE ||
    action.type === ROOM_CALENDAR.FAILURE ||
    action.type === ROOM_MEMBER_CALENDAR.FAILURE ||
    action.type === ROOM_MEETING_LIST.FAILURE ||
    action.type === EVENT_OCCURRENCE_DETAIL.FAILURE
  ) {
    return {
      ...state,
      loading: false,
      error: true,
      errorDetails: action.errorDetails,
    };
  }

  if (action.type === SET_SELECTED_EVENT) {
    return {
      ...state,
      selectedEvent: action.eventData,
    };
  }

  return state;
}
