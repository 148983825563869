export const ACTIVITIES = [
  { text: "Communication", value: "communication" },
  { text: "Design", value: "design" },
  { text: "Development", value: "development" },
  { text: "Estimation", value: "estimation" },
  { text: "Overhead", value: "overhead" },
  { text: "Product Management", value: "product_management" },
  { text: "Research", value: "research" },
  { text: "Support", value: "support" },
  { text: "Testing", value: "testing" },
  { text: "Training", value: "training" },
];

export const DATE_FILTERS = [
  { text: "Is", value: "is" },
  { text: ">=", value: "gte" },
  { text: "<=", value: "lte" },
  { text: "Between", value: "between" },
  { text: "Today", value: "today" },
  { text: "Yesterday", value: "yesterday" },
  { text: "This Week", value: "this_week" },
  { text: "Last Week", value: "last_week" },
  { text: "This Month", value: "this_month" },
  { text: "Last Month", value: "last_month" },
  { text: "This Year", value: "this_year" },
  { text: "Last Year", value: "last_year" },
];

export const ISSUE_TYPE_ANY = "";
export const ISSUE_TYPE_IS = "is";
export const ISSUE_TYPE_NONE = "none";

export const ISSUE_FILTERS = [
  { text: "Any", value: ISSUE_TYPE_ANY },
  { text: "Is", value: ISSUE_TYPE_IS },
  { text: "Not Provided", value: ISSUE_TYPE_NONE },
];

export const HOURS = [
  { text: "0.25", value: "0.25" },
  { text: "0.50", value: "0.50" },
  { text: "0.75", value: "0.75" },
  { text: "1", value: "1" },
];

export const OUT_OF_OFFICE_ACTIVITIES = [
  { text: "Vacation", value: "Vacation Leave" },
  { text: "Sick", value: "Sick Leave" },
];
