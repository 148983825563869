import { findIndex, groupBy, isEqual, mapKeys, omitBy, pickBy, sortBy } from "lodash";
import { createSelector, createSelectorCreator, defaultMemoize } from "reselect";
import { getRoomName } from "utils";

const getCurrentUser = (state) => state.auth && state.auth.user;
const getMessages = (state, roomId) => state.messages.items[roomId] || {};
const getProjectMemberships = (state) => state.entities.projectMemberships;
const getProjects = (state) => state.entities.projects;
const getUsers = (state) => state.entities.users;
const getArchivedCollapsed = (state) => state.sidebar.archivedCollapsed;

export const getRooms = (state) => {
  const retval = omitBy(state.rooms.items, (room) => room.isHidden);
  return retval;
};

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getTeams = createSelector(getUsers, (users) =>
  Object.keys(users)
    .filter((slug) => users[slug].isOfficial)
    .map((slug) => users[slug])
);

export const getSortedRooms = createDeepEqualSelector(
  getCurrentUser,
  getRooms,
  getProjects,
  getArchivedCollapsed,
  (user, rooms, projects, archivedCollapsed) =>
    sortBy(
      Object.keys(rooms)
        .map((roomId) => rooms[roomId])
        .filter(
          // If the archived projects section is collapsed,
          // do not include rooms whose projects were archived.
          (room) =>
            !archivedCollapsed ||
            !room.project ||
            (archivedCollapsed &&
              room.project &&
              projects[room.project] &&
              projects[room.project].isActive)
        ),
      [
        (room) => !room.isActive,
        (room) => !room.project,
        (room) => (room.projectName ? room.projectName.toLowerCase() : ""),
        (room) => !room.team,
        (room) => !room.isPrivate,
        (room) => !room.isGeneral,
        (room) => getRoomName(room, room.users, user).toLowerCase(),
      ]
    )
);

export const getDefaultRoom = createSelector(
  getRooms,
  getSortedRooms,
  (state) => state.rooms.lastRoom,
  (rooms, sortedRooms, lastRoom) => rooms[lastRoom] || sortedRooms[0] || null
);

export const getCurrentRoom = createSelector(
  getRooms,
  getDefaultRoom,
  (state) => state.rooms._currentRoomId,
  (rooms, defaultRoom, currentRoomId) => {
    if (Object.keys(rooms).length === 0) return null;
    if (rooms[currentRoomId]) {
      return rooms[currentRoomId];
    }
    return defaultRoom;
  }
);

export const getCurrentRoomId = createSelector(getCurrentRoom, (currentRoom) =>
  currentRoom ? currentRoom.id : null
);

export const getCurrentRoomPinnedCount = createSelector(getMessages, (messages) =>
  Object.values(messages).reduce((acc, message) => (message.pinnedBy ? acc + 1 : acc), 0)
);

export const getCurrentProject = createSelector(
  getCurrentRoom,
  getProjects,
  (currentRoom, projects) =>
    currentRoom && currentRoom.project ? projects[currentRoom.project] : null
);

export const getCurrentProjectMemberships = createSelector(
  getCurrentProject,
  getProjectMemberships,
  (currentProject, memberships) =>
    currentProject && currentProject.memberships
      ? mapKeys(
          currentProject.memberships.map((id) => memberships[id]),
          "userSlug"
        )
      : {}
);

export const getNextRoom = createSelector(getCurrentRoom, getSortedRooms, (currentRoom, rooms) => {
  if (!currentRoom) {
    return null;
  }
  const currentIndex = findIndex(rooms, (r) => r.id === currentRoom.id);
  const nextIndex = currentIndex < rooms.length - 1 ? currentIndex + 1 : 0;
  return rooms[nextIndex];
});

export const getPreviousRoom = createSelector(
  getCurrentRoom,
  getSortedRooms,
  (currentRoom, rooms) => {
    if (!currentRoom) {
      return null;
    }
    const currentIndex = findIndex(rooms, (r) => r.id === currentRoom.id);
    const nextIndex = currentIndex > 0 ? currentIndex - 1 : rooms.length - 1;
    return rooms[nextIndex];
  }
);

export const getNextUnreadRoom = (state) => {
  const currentRoom = getCurrentRoom(state);
  if (currentRoom) {
    const rooms = getSortedRooms(state);
    const currentIndex = findIndex(rooms, (r) => r.id === currentRoom.id);
    // Find rooms[> currentIndex] where unreadMessagesCount > 0
    for (let i = currentIndex + 1; i < rooms.length; i += 1) {
      if (state.messages.unreadMessagesCountByRoom[rooms[i].id] > 0) {
        return rooms[i];
      }
    }
    return currentRoom;
  }
  return null;
};

export const getPreviousUnreadRoom = (state) => {
  const currentRoom = getCurrentRoom(state);
  if (currentRoom) {
    const rooms = getSortedRooms(state);
    const currentIndex = findIndex(rooms, (r) => r.id === currentRoom.id);
    // Find rooms[< currentIndex] where unreadMessagesCount > 0
    for (let i = currentIndex - 1; i >= 0; i -= 1) {
      if (state.messages.unreadMessagesCountByRoom[rooms[i].id] > 0) {
        return rooms[i];
      }
    }
    return currentRoom;
  }
  return null;
};

const getNotifications = (state) => state.rooms.notifications;

export const getCurrentNotifications = createSelector(
  [getCurrentRoomId, getNotifications],
  (currentRoomId, notifications) => notifications[currentRoomId] || []
);

export const getProjectLinks = (state, project) =>
  (project.links || []).map((linkId) => state.entities.links[linkId]).filter((link) => !!link);

export const getCurrentThread = createSelector(
  [
    getRooms,
    (state) => state.messages.items,
    (state) => state.messages._currentThread.room,
    (state) => state.messages._currentThread.message,
  ],
  (rooms, messages, room, message) =>
    room &&
    message &&
    messages[room] &&
    messages[room][message] &&
    !messages[room][message].isDeleted
      ? {
          room: room ? rooms[room] : null,
          thread: pickBy(messages[room], (m) => m.id === message || m.root === message),
        }
      : { room: null, thread: {} }
);

export const getReactions = createSelector(
  (state) => state.entities.reactions,
  (reactions) => groupBy(Object.values(reactions), "message")
);

export const getReminders = (state) => state.entities.reminders;

export const getCurrentToken = (state) => state.auth && state.auth.token;
