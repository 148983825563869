import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as responsive from "utils/responsive";

import * as styles from "./styles.module.scss";

class NewVersionPrompt extends React.PureComponent {
  handleReload = () => {
    window.location.reload();
  };

  render() {
    const { hasNewVersion } = this.props;
    if (responsive.isPopup() || !hasNewVersion) {
      return null;
    }

    return (
      <div className={styles.newVersion}>
        A new version is available!{" "}
        <span
          className={styles.reload}
          onClick={this.handleReload}
          onKeyPress={this.handleReload}
          role="button"
          tabIndex="0"
        >
          Reload
        </span>
      </div>
    );
  }
}

NewVersionPrompt.propTypes = {
  hasNewVersion: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { hasNewVersion } = state;

  return {
    hasNewVersion,
  };
}

export default connect(mapStateToProps)(NewVersionPrompt);
