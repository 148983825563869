import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.checkboxRef = React.createRef();
  }

  componentDidMount() {
    this.setIndeterminate();
  }

  componentDidUpdate() {
    this.setIndeterminate();
  }

  setIndeterminate() {
    const { indeterminate } = this.props;
    this.checkboxRef.current.indeterminate = indeterminate;
  }

  render() {
    const { body, checked, children, className, identifier, onChange, tall } = this.props;

    return (
      <div className={classnames(styles.checkboxContainer, className)}>
        <label className={styles.checkboxInput} htmlFor={`checkbox-${identifier}`}>
          <input
            className={styles.originalCheckbox}
            id={`checkbox-${identifier}`}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            ref={this.checkboxRef}
          />
          <span className={classnames(styles.newCheckbox, { [styles.tall]: tall })} />
          <span className={styles.text}>{children}</span>
          {body}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  body: PropTypes.node,
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  indeterminate: PropTypes.bool,
  identifier: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tall: PropTypes.bool,
};

Checkbox.defaultProps = {
  indeterminate: false,
  tall: false,
};

export default Checkbox;
