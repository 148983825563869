import React from "react";
import { Button } from "semantic-ui-react";
import config from "config";

import styles from "./styles.module.scss";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/google/login/`;

const GoogleButton = (props) => (
  <Button
    className={styles.google}
    fluid
    onClick={() => {
      window.location.href = LOGIN_URL;
    }}
    title="Google"
    {...props}
  >
    <img src="/google-logo.svg" alt="google" />
    <p className={styles.googleButtonText}>Login with Google</p>
  </Button>
);

export default GoogleButton;
