import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "semantic-ui-react";

import { createRoom } from "actions/chat";
import { newMessageModal } from "actions/modals";
import FuzzySearch from "components/FuzzySearch";
import UserPill from "components/UserPill";
import { filterUsers } from "utils/user";

import MemberList from "./MemberList";

import * as styles from "./styles.module.scss";

class ModalCreateRoom extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedUsers: [],
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.inputRef.current.focus();
    }
  }

  valuesRenderer = (values, onClick) =>
    values.map((value, index) => {
      const avatar = value.avatarOrDefault;
      const text = value.displayName;

      return (
        <UserPill
          key={index}
          avatar={avatar}
          bgColor={value.avatarBgcolor}
          fgColor={value.avatarColor}
          onClick={() => onClick(index)}
          text={text}
        />
      );
    });

  matchesRenderer = (matches, onClick) => <MemberList matches={matches} onClick={onClick} />;

  handleSubmit = () => {
    const { createRoomRequest, showNewMessageModalHide, token } = this.props;
    const { selectedUsers } = this.state;
    createRoomRequest({
      name: "",
      userSlugs: selectedUsers.map((u) => u.slug),
      token,
    });
    this.setState({ selectedUsers: [] }, () => {
      showNewMessageModalHide();
    });
  };

  handleChange = (selectedUsers) => {
    this.setState({ selectedUsers });
  };

  handleClose = () => {
    this.setState({ selectedUsers: [] }, this.props.showNewMessageModalHide);
  };

  render() {
    const { open, users } = this.props;
    const { selectedUsers } = this.state;

    const selectedUserSlugs = selectedUsers.map((u) => u.slug);
    const usersFiltered = users.filter((user) => !selectedUserSlugs.includes(user.slug));

    return (
      <Modal
        as={Form}
        basic
        centered={false}
        className={classnames("center", styles.mainModal)}
        closeIcon
        closeOnDimmerClick={false}
        dimmer="inverted"
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
        open={open}
      >
        <Modal.Header>Direct Messages</Modal.Header>
        <Modal.Content>
          <FuzzySearch
            inputRef={this.inputRef}
            keys={["displayName", "email"]}
            multiple
            onValuesChange={this.handleChange}
            options={usersFiltered}
            placeholder="Search a name to begin a conversation"
            values={selectedUsers}
            valuesRenderer={this.valuesRenderer}
          >
            {({ matches, onClick }) => this.matchesRenderer(matches, onClick)}
          </FuzzySearch>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Begin" disabled={selectedUsers.length === 0} type="submit" primary />
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalCreateRoom.propTypes = {
  createRoomRequest: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  showNewMessageModalHide: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { token, user: currentUser },
    modals: { showNewMessageModal: open },
  } = state;
  const users = Object.keys(state.entities.users)
    .map((slug) => state.entities.users[slug])
    .filter((user) => user.slug !== currentUser.slug);

  return {
    open,
    users: filterUsers(users),
    token,
  };
}

export default connect(mapStateToProps, {
  showNewMessageModalHide: newMessageModal.hide,
  createRoomRequest: createRoom.request,
})(ModalCreateRoom);
