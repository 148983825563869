/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import classnames from "classnames";
import Humanize from "humanize-plus";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Form } from "semantic-ui-react";

import { MONTHLY, WEEKLY } from "./constants";
import styles from "./styles.module.scss";

const WEEKDAYS = {
  w7: { value: "SU", text: "Sun" },
  w1: { value: "MO", text: "Mon" },
  w2: { value: "TU", text: "Tue" },
  w3: { value: "WE", text: "Wed" },
  w4: { value: "TH", text: "Thu" },
  w5: { value: "FR", text: "Fri" },
  w6: { value: "SA", text: "Sat" },
};

const MONTHOPTIONS = {
  default: {
    defaultId: "RecurrenceInput.modalId.monthdefault",
    defaultMessage: "day {date}",
  },
  setpos: {
    defaultId: "RecurrenceInput.modalId.monthsetpos",
    defaultMessage: "the {counter} {day}",
  },
};

export default class RecurrenceCustomWeekday extends React.PureComponent {
  onChangeMonth = (e, setpos, weekday, basic) => {
    const { onChange } = this.props;
    if (!basic) {
      onChange(e, { byweekday: weekday, bysetpos: `${setpos}` });
    } else {
      onChange(e, { byweekday: weekday, bysetpos: "" });
    }
  };

  onChangeWeek = (e, weekdayList, active, value) => {
    const { onChange } = this.props;
    if (weekdayList.length === 1 && active) return;

    if (active) {
      weekdayList.splice(weekdayList.indexOf(value), 1);
    } else {
      weekdayList.push(value);
    }
    onChange(e, { byweekday: weekdayList.join(",") });
  };

  renderMonthOptions() {
    const { bysetpos, startDate, disabled } = this.props;
    const date = startDate.date();
    const day = startDate.format("dddd");
    const weekdayInput = WEEKDAYS[`w${startDate.isoWeekday()}`];

    const month = startDate.month();
    const year = startDate.year();
    let counter = 0;
    let current = moment(startDate);
    while (current.month() >= month && current.year() === year) {
      current = moment(current).subtract(1, "weeks");
      counter += 1;
    }
    const countLabel = Humanize.ordinal(counter);

    return (
      <div className={classnames(styles.customModalSection, styles.customModalWeekday)}>
        <div>
          <FormattedMessage defaultMessage="Repeat on" id="RecurrenceInput.modalId.weekday" />
        </div>
        <Form.Radio
          checked={!bysetpos}
          label={
            <label>
              <FormattedMessage
                defaultMessage={MONTHOPTIONS.default.defaultMessage}
                id={MONTHOPTIONS.default.defaultId}
                values={{ date }}
              />
            </label>
          }
          name="month"
          onChange={(e) => this.onChangeMonth(e, counter, weekdayInput.value, true)}
          value={0}
          disabled={disabled}
        />
        <Form.Radio
          checked={!!bysetpos}
          label={
            <label>
              <FormattedMessage
                defaultMessage={MONTHOPTIONS.setpos.defaultMessage}
                id={MONTHOPTIONS.setpos.defaultId}
                values={{ counter: countLabel, day }}
              />
            </label>
          }
          name="month"
          onChange={(e) => this.onChangeMonth(e, counter, weekdayInput.value, false)}
          value={1}
          disabled={disabled}
        />
      </div>
    );
  }

  renderWeekdays() {
    const { byweekday, startDate, disabled } = this.props;
    const weekdayInput = WEEKDAYS[`w${startDate.isoWeekday()}`];
    const weekdayList = byweekday ? byweekday.split(",") : [weekdayInput.value];

    return (
      <div className={classnames(styles.customModalSection, styles.customModalWeekday)}>
        <div>
          <FormattedMessage defaultMessage="Repeat on" id="RecurrenceInput.modalId.weekday" />
        </div>
        {Object.keys(WEEKDAYS).map((key) => {
          const active = weekdayList.indexOf(WEEKDAYS[key].value) >= 0;
          return (
            <Button
              key={key}
              circular
              compact
              size="mini"
              content={WEEKDAYS[key].text}
              className={active ? classnames(styles.dayBtn, styles.active) : styles.dayBtn}
              onClick={(e) => this.onChangeWeek(e, weekdayList, active, WEEKDAYS[key].value)}
              disabled={disabled}
            />
          );
        })}
      </div>
    );
  }

  render() {
    const { frequency } = this.props;
    if (frequency === WEEKLY) return this.renderWeekdays();
    if (frequency === MONTHLY) return this.renderMonthOptions();
    return null;
  }
}

RecurrenceCustomWeekday.propTypes = {
  bysetpos: PropTypes.string,
  byweekday: PropTypes.string,
  frequency: PropTypes.string.isRequired,
  startDate: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
};
