import { isEmpty, orderBy } from "lodash";
import classnames from "classnames";
import Humanize from "humanize-plus";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Input } from "semantic-ui-react";

import { project as projects } from "actions/project";
import { sidePanel, PANELS } from "actions/sidePanel";

import {
  getCurrentRoom,
  getCurrentProject,
  getCurrentProjectMemberships,
} from "reducers/selectors";
import { projectType } from "types/project";
import { Roles } from "utils/constants/project";

import ScrollableContent from "components/ScrollableContent";
import UserAvatar from "components/UserAvatar";
import ContactModal from "components/ContactModal";

import * as sidePanelStyles from "../../styles.module.scss";
import * as styles from "./styles.module.scss";

class SidePanelMembers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };
  }

  componentDidMount() {
    const { project } = this.props;
    if (project && !project.isComplete) {
      this.props.projectInit(project.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { project } = this.props;
    if (prevProps.project !== project && project && !project.isComplete) {
      this.props.projectInit(project.id);
    }
  }

  handleManageMembers = () => {
    const { history, project, sidePanelClose, currentRoom } = this.props;
    let settingsUrl = "";
    if (project) {
      settingsUrl = `/chat/r/${project.generalChannelId}/settings`;
    }
    sidePanelClose(PANELS.MEMBERS, { roomId: currentRoom.id });
    if (project) {
      history.push({
        pathname: settingsUrl,
        state: {
          scrollToMembers: true,
        },
      });
    }
  };

  handleSearch = (e, { value }) => {
    this.setState({ search: value });
  };

  render() {
    const {
      currentRoom,
      hasProjectUrls,
      invitations,
      isAdmin,
      members,
      panelId,
      project,
      sidePanelClose,
      visible,
    } = this.props;
    const { search } = this.state;
    const membersLength = members.length;

    if (!visible || panelId !== PANELS.MEMBERS) {
      return null;
    }

    const searchLowercase = search.toLowerCase();
    const membersFiltered = members.filter(
      (member) =>
        member.slug.toLowerCase().includes(searchLowercase) ||
        member.displayName.toLowerCase().includes(searchLowercase)
    );

    const invitationsFiltered = invitations.filter(
      (invite) =>
        (invite.user &&
          (invite.user.slug.toLowerCase().includes(searchLowercase) ||
            invite.user.displayName.toLowerCase().includes(searchLowercase))) ||
        (invite.email && invite.email.toLowerCase().includes(searchLowercase))
    );

    const hasLabel = !isEmpty(invitations);

    return (
      <ScrollableContent
        idPrefix="members-sidepanel"
        containerClass={sidePanelStyles.sidePanelBoxShadow}
      >
        <div className={classnames(sidePanelStyles.sidePanel, styles.members)}>
          <div className={sidePanelStyles.heading}>
            <p>
              {membersLength} {Humanize.pluralize(membersLength, "Member")}
            </p>
            <div>
              {project && isAdmin && (
                <Button
                  basic
                  content="Manage Members"
                  className={sidePanelStyles.actionButton}
                  onClick={this.handleManageMembers}
                />
              )}
              <Button
                basic
                content="Close"
                onClick={() => sidePanelClose(PANELS.MEMBERS, { roomId: currentRoom.id })}
                className={classnames(
                  sidePanelStyles.actionButton,
                  sidePanelStyles.close,
                  hasProjectUrls && sidePanelStyles.hasProjectUrlsClose
                )}
              />
            </div>
          </div>
          <div className={styles.content}>
            <Input
              fluid
              icon="search"
              iconPosition="left"
              name="search"
              placeholder="Search member"
              type="search"
              value={search}
              onChange={this.handleSearch}
              className={styles.search}
            />
            {hasLabel && <div className={styles.label}>Pending Invite (Non-Members)</div>}
            {invitationsFiltered.map((invite) => (
              <div className={styles.memberItem} key={invite.id}>
                {invite.user ? (
                  <UserAvatar
                    avatar={invite.user.avatarOrDefault}
                    size="32"
                    bgColor={invite.user.avatarBgcolor}
                    fgColor={invite.user.avatarColor}
                    displayName={invite.user.displayName}
                  />
                ) : (
                  <UserAvatar isAnonymous size="32" />
                )}
                <div className={styles.detail}>
                  {invite.user ? invite.user.displayName : invite.email}
                </div>
              </div>
            ))}
            {hasLabel && (
              <div className={classnames(styles.label, styles.memberLabel)}>Members</div>
            )}
            {membersFiltered.map((member) => (
              <div className={styles.memberItem} key={member.slug}>
                <UserAvatar
                  avatar={member.avatarOrDefault}
                  size="32"
                  bgColor={member.avatarBgcolor}
                  fgColor={member.avatarColor}
                  displayName={member.displayName}
                  online={member.online}
                />
                <div className={styles.detail}>
                  <ContactModal user={member} displayYou />
                </div>
              </div>
            ))}
          </div>
        </div>
      </ScrollableContent>
    );
  }
}

SidePanelMembers.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape(),
  }).isRequired,
  currentRoom: PropTypes.shape(),
  currentUser: PropTypes.shape(),
  hasProjectUrls: PropTypes.bool.isRequired,
  invitations: PropTypes.arrayOf(PropTypes.shape()),
  isAdmin: PropTypes.bool,
  project: projectType,
  panelId: PropTypes.string,
  visible: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape()).isRequired,

  projectInit: PropTypes.func.isRequired,
  sidePanelClose: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser },
    entities: { users },
    sidePanel: { panelId, visible },
  } = state;
  const currentRoom = getCurrentRoom(state) || { invitations: [], userSlugs: [] };
  const project = getCurrentProject(state);
  const projectMemberships = getCurrentProjectMemberships(state);
  const isAdmin =
    !!projectMemberships[currentUser.slug] &&
    projectMemberships[currentUser.slug].role === Roles.admin;
  const hasProjectUrls = Boolean(project && project.gitlabUrl);

  let membersRaw = [];
  if (project) {
    membersRaw = Object.keys(projectMemberships)
      .map((slug) => users[slug])
      .filter((member) => member);
  } else {
    membersRaw = currentRoom.userSlugs.map((slug) => users[slug]).filter((member) => member);
  }
  const members = orderBy(membersRaw, "displayName", "asc");

  let invitations = [];
  if (project && project.invitations) {
    invitations = project.invitations
      .filter((invitation) => invitation.status === "pending")
      .map((invitation) => {
        if (invitation.user) {
          return {
            ...invitation,
            user: users[invitation.user],
          };
        }
        return invitation;
      });
  }

  return {
    currentRoom,
    currentUser,
    hasProjectUrls,
    invitations,
    isAdmin,
    panelId,
    project,
    visible,
    members,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    projectInit: projects.init,
    sidePanelClose: sidePanel.close,
  })(SidePanelMembers)
);
