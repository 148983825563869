import qs from "querystring";
import EmailValidator from "email-validator";
import { startCase, isString } from "lodash";
import generate from "project-name-generator";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, NavLink as Link, Redirect, withRouter } from "react-router-dom";
import { Header, Grid, Form, Button, Input, List, Message } from "semantic-ui-react";
import { injectIntl, defineMessages } from "react-intl";
import { batch, connect } from "react-redux";

import { Helmet } from "react-helmet";
import Phone from "react-phone-number-input";
import classNames from "classnames";

import "react-phone-number-input/style.css";

import config from "config";
import { resetErrorMessage } from "actions";
import { loginInit, registerInit } from "actions/account";
import PasswordStrengthMeter from "components/PasswordStrengthMeter";
import FuzzySearch from "components/FuzzySearch";
import UserAvatar from "components/UserAvatar";
import UserPill from "components/UserPill";
import { countryOptions } from "utils/constants";

import { Facebook, GitHub, Google, LinkedIn, Microsoft, Twitter } from "./buttons";

import styles from "./styles.module.scss";
import { MESSAGES } from "./l10n";

const zeroPad = { padding: "0", paddingLeft: "7px", paddingRight: "7px", paddingBottom: "14px" };

export class SignUpBase extends Component {
  state = {
    loginEmail: "",
    loginPassword: "",
    signupFirstName: "",
    signupLastName: "",
    signupEmail: "",
    signupPassword: "",
    signupConfirmPassword: "",
    signupMobile: "",
    signupLocation: "",
    signupLegalAge: false,
    signupAsTeam: false,
    signupTeamName: "",
    signUpTeamMembers: [],
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleGenerateName = () => {
    const randomWord = startCase(generate({ words: 3 }).spaced);
    this.setState({
      signupTeamName: randomWord,
    });
  };

  matchRenderer = (match, onClick, selected) => (
    <div
      className={classNames(styles.sectionMemberMatch, selected && styles.selected)}
      key={match.item.slug}
      onClick={() => onClick(match.item)}
      onKeyPress={() => onClick(match.item)}
      role="menuitem"
      tabIndex="-1"
      title={`${match.item.displayName}${match.item.email ? ` (${match.item.email})` : ""}`}
    >
      <UserAvatar
        avatar={match.item.avatar}
        bgColor={match.item.avatarBgcolor}
        className={styles.sectionMemberMatchAvatar}
        displayName={match.item.displayName}
        fgColor={match.item.avatarColor}
        size="16"
      />
      <span>
        {match.item.displayName}
        {match.item.email ? ` (${match.item.email})` : ""}
      </span>
    </div>
  );

  valuesRenderer = (values, onClick) =>
    values.map((value, index) => {
      const avatar = isString(value) ? null : value.avatarOrDefault;
      const text = isString(value) ? value : value.displayName;

      return (
        <UserPill
          key={index}
          avatar={avatar}
          bgColor={value.avatarBgcolor}
          fgColor={value.avatarColor}
          onClick={() => onClick(index)}
          text={text}
        />
      );
    });

  valuesValidator = (value) => EmailValidator.validate(value);

  handleMemberChange = (values) => {
    this.setState({ signUpTeamMembers: values });
  };

  handleLogin = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const next = params.get("next");

    const { loginEmail, loginPassword } = this.state;
    const credentials = {
      username: loginEmail,
      password: loginPassword,
    };

    batch(() => {
      this.props.resetErrorMessage();
      this.props.loginInit(credentials, null, next, {});
    });
  };

  handleSignup = (e) => {
    if (e) {
      e.preventDefault();
    }

    const {
      signupEmail,
      signupFirstName,
      signupLastName,
      signupPassword,
      signupConfirmPassword,
      signupMobile,
      signupLocation,
      signupLegalAge,
      signupAsTeam,
      signupTeamName,
      signUpTeamMembers,
    } = this.state;
    this.props.resetErrorMessage();
    this.props.registerInit({
      username: signupEmail,
      first_name: signupFirstName,
      last_name: signupLastName,
      email: signupEmail,
      password1: signupPassword,
      password2: signupConfirmPassword,
      phone_number: signupMobile,
      country: signupLocation,
      legal_age: signupLegalAge,
      as_team: signupAsTeam,
      team_name: signupTeamName,
      members: signUpTeamMembers,
      invite_token: qs.parse(this.props.location.search.substring(1)).invite_token,
    });
  };

  render() {
    const { formatMessage } = this.props.intl;
    const _f = formatMessage;
    const msgs = defineMessages(MESSAGES);
    const { token, errors, loading } = this.props;
    const { signupAsTeam, signupTeamName, signUpTeamMembers } = this.state;
    const formErrors = {
      Errors: errors && errors.non_field_errors,
      Email: errors && (errors.username || errors.email),
      Mobile: errors && errors.phone_number,
      Password: errors && errors.password1,
      Location: errors && errors.country,
      "Team Name": errors && errors.team_name,
    };
    let offerPasswordReset = false;

    const options = countryOptions.map((c) => {
      if (c.key === "aa") {
        return {
          ...c,
          value: "",
          text: "",
        };
      }
      return c;
    });

    if (token) {
      const { next } = qs.parse(this.props.location.search.substring(1));
      if (next) return <Redirect to={next} />;
      return <Redirect to="/" />;
    }

    if (formErrors.Email && formErrors.Email[0]) {
      formErrors.Email[0] = formErrors.Email[0].replace(/username/i, "email");
      if (formErrors.Email[0].indexOf("already exists.") >= 0) {
        offerPasswordReset = true;
      }
    }

    return (
      <div className={styles.content}>
        <Route
          exact
          path="/signup"
          render={() => (
            <Header
              as="h2"
              content={formatMessage(msgs.header)}
              textAlign="center"
              className={classNames(styles.header, styles.headerText, styles.normalText)}
            />
          )}
        />
        <Route
          exact
          path="/login"
          render={() => (
            <Header
              as="h2"
              content={formatMessage(msgs.loginHeader)}
              textAlign="center"
              className={classNames(styles.header, styles.headerText, styles.normalText)}
            />
          )}
        />

        <Header
          as="h4"
          content={formatMessage(msgs.subHeader)}
          color="grey"
          textAlign="center"
          className={classNames(styles.subHeader, styles.subHeaderText, styles.normalText)}
        />

        <Grid stackable columns={config.flags.socialLogin ? 2 : 1} container>
          <Grid.Column>
            <Route
              exact
              path="/signup"
              render={() => [
                <Helmet key="signup1" defer={false}>
                  <title>{_f(msgs.signupTitle)}</title>
                </Helmet>,
                <Form
                  data-testid="signupForm"
                  key="signup2"
                  className={classNames(styles.form, styles.signupForm)}
                  onSubmit={this.handleSignup}
                  error={Boolean(this.props.errorMessage)}
                  loading={loading}
                >
                  {Object.keys(formErrors)
                    .filter((key) => formErrors[key])
                    .map((key, index) => (
                      <Message
                        key={`signupForm-errors-${index}`}
                        error
                        header={`${key}`}
                        content={formErrors[key].join()}
                      />
                    ))}
                  {Boolean(this.props.errorMessage) && offerPasswordReset && (
                    <Message info>
                      <Message.Header>Is the email address yours?</Message.Header>
                      <Link to={`/password/reset?email=${this.state.signupEmail}`}>
                        Reset your password
                      </Link>
                    </Message>
                  )}
                  <Form.Group widths={2}>
                    <Form.Input
                      required
                      label={_f(msgs.firstName)}
                      name="signupFirstName"
                      value={this.state.signupFirstName}
                      onChange={this.handleChange}
                      className={styles.inputShadow}
                    />
                    <Form.Input
                      required
                      label={_f(msgs.lastName)}
                      name="signupLastName"
                      value={this.state.signupLastName}
                      onChange={this.handleChange}
                      className={styles.inputShadow}
                    />
                  </Form.Group>
                  <Form.Group widths={2}>
                    <Form.Input
                      required
                      type="email"
                      label={_f(msgs.email)}
                      name="signupEmail"
                      value={this.state.signupEmail}
                      onChange={this.handleChange}
                      className={styles.inputShadow}
                    />
                    <Form.Field required>
                      <label>{_f(msgs.mobile)}</label>
                      <Phone
                        type="tel"
                        name="signupMobile"
                        value={this.state.signupMobile}
                        onChange={(phone) =>
                          this.handleChange(null, { name: "signupMobile", value: phone })
                        }
                        className={styles.inputShadow}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={2}>
                    <Form.Input
                      required
                      type="password"
                      label={_f(msgs.password)}
                      name="signupPassword"
                      value={this.state.signupPassword}
                      onChange={this.handleChange}
                      className={styles.inputShadow}
                    />
                    <Form.Input
                      required
                      type="password"
                      label={_f(msgs.confirmPassword)}
                      name="signupConfirmPassword"
                      value={this.state.signupConfirmPassword}
                      onChange={this.handleChange}
                      className={styles.inputShadow}
                    />
                  </Form.Group>
                  <PasswordStrengthMeter password={this.state.signupPassword} />
                  <Form.Dropdown
                    label={_f(msgs.location)}
                    name="signupLocation"
                    value={this.state.signupLocation}
                    onChange={this.handleChange}
                    size="mini"
                    search
                    selection
                    fluid
                    selectOnNavigation={false}
                    options={options}
                    className={styles.inputShadow}
                  />
                  {config.flags.marketplace && (
                    <>
                      <Form.Checkbox
                        label={_f(msgs.legalAge)}
                        className={styles.checkboxField}
                        name="signupLegalAge"
                        checked={this.state.signupLegalAge}
                        onChange={(e, { checked }) =>
                          this.handleChange(e, { name: "signupLegalAge", value: checked })
                        }
                      />
                      <Form.Checkbox
                        label={_f(msgs.signupAsTeam)}
                        className={styles.checkboxField}
                        name="signupAsTeam"
                        checked={signupAsTeam}
                        onChange={(e, { checked }) =>
                          this.handleChange(e, { name: "signupAsTeam", value: checked })
                        }
                      />
                      {signupAsTeam && (
                        <>
                          <p className={styles.teamNameLabel}>{_f(msgs.teamName)}</p>
                          <Form.Field className={styles.teamName}>
                            <Input
                              fluid
                              placeholder="Enter team name"
                              name="signupTeamName"
                              value={signupTeamName}
                              onChange={this.handleChange}
                              maxLength="200"
                              action={{
                                basic: true,
                                onClick: this.handleGenerateName,
                                content: "Generate name",
                                icon: "random",
                                type: "button",
                              }}
                            />
                          </Form.Field>
                          <small className={styles.helper}>{signupTeamName.length} / 200</small>

                          <p className={styles.teamNameLabel}>{_f(msgs.addMembers)}</p>
                          <FuzzySearch
                            allowAdditions
                            className={styles.teamMembers}
                            keys={["displayName", "email"]}
                            matchRenderer={this.matchRenderer}
                            multiple
                            onValuesChange={this.handleMemberChange}
                            placeholder="Type email addresses"
                            values={signUpTeamMembers}
                            valuesRenderer={this.valuesRenderer}
                            valuesValidator={this.valuesValidator}
                          />
                        </>
                      )}
                    </>
                  )}
                  <p>
                    By signing up with us, you agree to our{" "}
                    <Link to="/legal/terms" target="_blank">
                      Terms of Use
                    </Link>{" "}
                    and{" "}
                    <Link to="/legal/privacy" target="_blank">
                      Privacy Policy
                    </Link>
                    .
                  </p>
                  {/* <Form.Checkbox disabled label={_f(msgs.twofa)} /> */}
                  <Button
                    data-testid="signupButton"
                    type="submit"
                    content={_f(msgs.signup)}
                    primary
                    fluid
                  />
                  <div className={classNames(styles.createAccount, styles.regularText)}>
                    <span>{_f(msgs.hasAccount)} </span>
                    <Link to="/login" className="primary">
                      {_f(msgs.login)}
                    </Link>
                  </div>
                </Form>,
              ]}
            />

            <Route
              exact
              path="/login"
              render={() => [
                <Helmet key="login1" defer={false}>
                  <title>{_f(msgs.loginTitle)}</title>
                </Helmet>,
                <Form
                  data-testid="loginForm"
                  key="login2"
                  className={classNames(styles.form, styles.loginForm)}
                  onSubmit={this.handleLogin}
                  error={Boolean(this.props.errorMessage)}
                  loading={loading}
                >
                  <Message error header="Error" content={this.props.errorMessage} />
                  <Form.Input
                    required
                    type="email"
                    label={_f(msgs.email)}
                    name="loginEmail"
                    value={this.state.loginEmail}
                    onChange={this.handleChange}
                    className={styles.inputShadow}
                  />
                  <Form.Input
                    required
                    type="password"
                    label={_f(msgs.password)}
                    name="loginPassword"
                    value={this.state.loginPassword}
                    onChange={this.handleChange}
                    className={styles.inputShadow}
                  />
                  <Button data-testid="loginButton" type="submit" primary fluid>
                    {_f(msgs.login)}
                  </Button>
                  <div className={classNames(styles.createAccount, styles.regularText)}>
                    <span>{_f(msgs.createAccount)} </span>
                    <Link to="/signup" className="primary">
                      {_f(msgs.signup)}
                    </Link>
                  </div>
                  <div className={classNames(styles.passwordReset, styles.regularText)}>
                    <Link to="/password/reset" className="primary">
                      {_f(msgs.passwordReset)}
                    </Link>
                  </div>
                </Form>,
              ]}
            />
          </Grid.Column>

          {config.flags.socialLogin && (
            <Grid.Column>
              <div className={styles.socialContainer}>
                <Grid.Row>
                  <Route
                    exact
                    path="/signup"
                    render={() => (
                      <Header
                        as="h4"
                        content={formatMessage(msgs.socSignupHeader)}
                        className={classNames(
                          styles.socHeader,
                          styles.regularText,
                          styles.boldText
                        )}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/login"
                    render={() => (
                      <Header
                        as="h4"
                        content={formatMessage(msgs.socHeader)}
                        className={classNames(
                          styles.socHeader,
                          styles.regularText,
                          styles.boldText
                        )}
                      />
                    )}
                  />
                  <Grid columns={3} doubling padded>
                    <Grid.Column style={zeroPad}>
                      <Facebook />
                    </Grid.Column>
                    <Grid.Column style={zeroPad}>
                      <Twitter />
                    </Grid.Column>
                    <Grid.Column style={zeroPad}>
                      <LinkedIn />
                    </Grid.Column>
                    <Grid.Column style={zeroPad}>
                      <Google />
                    </Grid.Column>
                    <Grid.Column style={zeroPad}>
                      <GitHub />
                    </Grid.Column>
                    <Grid.Column style={zeroPad}>
                      <Microsoft />
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
              </div>
            </Grid.Column>
          )}
        </Grid>

        <div style={{ textAlign: "center", marginTop: "3rem" }}>
          <List horizontal>
            <List.Item>
              <Link to="/legal/terms">Terms of Use</Link>
            </List.Item>
            <List.Item>
              <Link to="/legal/privacy">Privacy Policy</Link>
            </List.Item>
          </List>
        </div>
      </div>
    );
  }
}

SignUpBase.propTypes = {
  intl: PropTypes.shape().isRequired,
  errorMessage: PropTypes.string,
  token: PropTypes.string,
  errors: PropTypes.shape(),
  location: PropTypes.shape(),
  loading: PropTypes.bool,
  loginInit: PropTypes.func.isRequired,
  registerInit: PropTypes.func.isRequired,
  resetErrorMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    errorMessage,
    auth: { token, errors, loading },
  } = state;
  return {
    errorMessage,
    token,
    errors,
    loading,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loginInit,
    registerInit,
    resetErrorMessage,
  })(injectIntl(SignUpBase))
);
