const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || "development"];

const throwMissingEnvError = (envString, { isRequired = false } = {}) => {
  // pass in option isRequired: true to require variable in production
  if (isRequired && !process.env[envString]) {
    throw new Error(`Please set ${envString} in .env.local`);
  }
  // If environment is not production and envString is not defined throw Error
  if (environment && !environment.isProduction && !process.env[envString]) {
    throw new Error(`Please set ${envString} in .env.local`);
  }
  return false;
};

const getBaseDomain = () => "work.bld.ai";

const getAppName = () => {
  if (process.env.REACT_APP_NAME) {
    return process.env.REACT_APP_NAME;
  }
  return "work.bld.ai";
};

const getLoginUrl = () => {
  if (process.env.REACT_APP_LOGIN_URL) {
    return process.env.REACT_APP_LOGIN_URL;
  }
  return "/login";
};

const getEnvPrefix = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return "dev.";
    case "beta":
      return "beta.";
    case "local":
      return "local.";
    default:
      return "";
  }
};

const getPortDisplay = () => {
  if (process.env.REACT_APP_API_PORT !== 443) {
    return `:${process.env.REACT_APP_API_PORT}`;
  }
  return "";
};

const getApiBaseUrl = () => {
  if (process.env.REACT_APP_API_URL || throwMissingEnvError("REACT_APP_API_URL")) {
    return process.env.REACT_APP_API_URL;
  }

  if (environment && !environment.isProduction) {
    return "/api/v1";
  }

  const envPrefix = getEnvPrefix();
  const port = getPortDisplay();
  return `https://api.${envPrefix}${getBaseDomain()}${port}/api/v1`;
};

const getWebsocketUrl = () => {
  if (process.env.REACT_APP_WS_URL || throwMissingEnvError("REACT_APP_WS_URL")) {
    return process.env.REACT_APP_WS_URL;
  }

  if (environment && !environment.isProduction) {
    return "ws://localhost:8000/ws";
  }

  const protocol = environment && environment.isProduction ? "wss" : "ws";

  const envPrefix = getEnvPrefix();
  const port = getPortDisplay();
  return `${protocol}://api.${envPrefix}${getBaseDomain()}${port}/ws`;
};

const appName = getAppName();
const loginUrl = getLoginUrl();
const apiBaseUrl = getApiBaseUrl();
const websocketUrl = getWebsocketUrl();

const flags = {
  chat: !process.env.REACT_APP_DISABLE_CHAT,
  discussion: !process.env.REACT_APP_DISABLE_DISCUSSION,
  call: !process.env.REACT_APP_DISABLE_CALL,
  video: !process.env.REACT_APP_DISABLE_VIDEO,
  meeting: !process.env.REACT_APP_DISABLE_MEETING,
  poll: !process.env.REACT_APP_DISABLE_POLL,
  feedback: !process.env.REACT_APP_DISABLE_FEEDBACK,
  clientFeedback: !process.env.REACT_APP_DISABLE_CLIENT_FEEDBACK,
  health: !process.env.REACT_APP_DISABLE_HEALTH,
  marketplace: !process.env.REACT_APP_DISABLE_MARKETPLACE,
  house: !process.env.REACT_APP_DISABLE_HOUSE,
  socialLogin: !process.env.REACT_APP_DISABLE_SOCIAL_LOGIN,
  integrations: !process.env.REACT_APP_DISABLE_INTEGRATIONS,
  notifications: !process.env.REACT_APP_DISABLE_NOTIFICATIONS,
  timeTracker: !process.env.REACT_APP_DISABLE_TIME_TRACKER,
  schedule: !process.env.REACT_APP_DISABLE_SCHEDULE,
  planning: !process.env.REACT_APP_DISABLE_PLANNING,
  profile: {
    availability: !process.env.REACT_APP_DISABLE_PROFILE_AVAILABILITY,
    calendarSync: !process.env.REACT_APP_DISABLE_PROFILE_CALENDAR_SYNC,
    skills: !process.env.REACT_APP_DISABLE_PROFILE_SKILLS,
    teams: !process.env.REACT_APP_DISABLE_PROFILE_TEAMS,
    basicInfo: !process.env.REACT_APP_DISABLE_PROFILE_BASIC_INFO,
    about: !process.env.REACT_APP_DISABLE_PROFILE_ABOUT,
    projects: !process.env.REACT_APP_DISABLE_PROFILE_PROJECTS,
  },
  brand: {
    logo: process.env.REACT_APP_ENABLE_BRAND_LOGO,
    sidebarLogo: process.env.REACT_APP_ENABLE_BRAND_SIDEBAR_LOGO,
  },
  gitlabIntegrationAlwaysOn: process.env.REACT_APP_ENABLE_GITLAB_INTEGRATION_ALWAYS_ON,
};

export default {
  baseDomain: getBaseDomain(),
  appName,
  loginUrl,
  apiBaseUrl,
  websocketUrl,
  ravenEnv: process.env.REACT_APP_RAVEN_ENV || "local", // 'local', 'dev', 'beta', 'production'
  ravenApiKey: process.env.REACT_APP_RAVEN_API_KEY || "",
  ravenAppId: process.env.REACT_APP_RAVEN_APP_ID || "",
  host: process.env.HOST || "localhost",
  port: process.env.PORT || 3000,
  app: {
    title: appName,
    description: appName,
    head: {
      titleTemplate: appName,
      meta: [
        { name: "description", content: appName },
        {
          name: "viewport",
          content: "width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;",
        },
        { name: "viewport", content: "width=device-width" },
        { charset: "utf-8" },
        {
          "http-equiv": "X-UA-Compatible",
          content: "IE=edge",
        },
        { property: "og:site_name", content: appName },
        { property: "og:image", content: "" },
        { property: "og:locale", content: "en_US" },
        { property: "og:title", content: appName },
        { property: "og:description", content: appName },
      ],
    },
  },

  flags,

  algoliaAppId: flags.marketplace ? process.env.REACT_APP_ALGOLIA_APP_ID : null,
  algoliaApiKey: flags.marketplace ? process.env.REACT_APP_ALGOLIA_API_KEY : null,
  elasticsearchHostURL: flags.marketplace ? process.env.REACT_APP_ELASTICSEARCH_HOST_URL : null,
  elasticsearchHostUsername: flags.marketplace
    ? process.env.REACT_APP_ELASTICSEARCH_HOST_USERNAME
    : null,
  elasticsearchHostPassword: flags.marketplace
    ? process.env.REACT_APP_ELASTICSEARCH_HOST_PASSWORD
    : null,
  googleMapsApiKey: flags.house
    ? process.env.REACT_APP_GMAPS_API_KEY || "AIzaSyAjeQqGZBtr410btzfnEhtSSYuP8Jqa2v4"
    : null,
  chromeExtensionId: flags.marketplace
    ? process.env.REACT_APP_CHROME_EXTENSION_ID || "ppcgkpkkolaagoignnmndohblhccdcgp"
    : null,
  maxAttachmentSize: process.env.REACT_APP_MAX_ATTACHMENT_SIZE || 1000 * 1024 * 1024, // 1GB default
  brandLogo: flags.brand.logo ? process.env.REACT_APP_BRAND_LOGO : "",
  brandSidebarLogo: flags.brand.sidebarLogo ? process.env.REACT_APP_BRAND_SIDEBAR_LOGO : "",
  webrtcApiKey: process.env.REACT_APP_WEBRTC_API_KEY,
  webrtcSocketUrl: process.env.REACT_APP_WEBRTC_SOCKET_URL,
  webrtcSocketPath: process.env.REACT_APP_WEBRTC_SOCKET_PATH,

  stripeApiKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
  ...environment,
};
