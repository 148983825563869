import { call, fork, put, take } from "redux-saga/effects";

import { api, token as tokenService } from "services";
import * as log from "actions/log";

function* versionCommentAdd(request) {
  const { versionComment, reject, resolve, trackedClass } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(
    api.versionCommentAdd,
    token,
    versionComment
  );
  if (response) {
    yield put(log.versionCommentAdd.success(response, trackedClass));
    resolve();
  } else if (error) {
    yield put(log.versionCommentAdd.failure(errorDetails));
    reject(errorDetails);
  }
}

function* versionCommentUpdate(request) {
  const { versionCommentId, versionComment, reject, resolve, trackedClass } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(
    api.versionCommentUpdate,
    token,
    versionCommentId,
    versionComment
  );
  if (response) {
    yield put(log.versionCommentUpdate.success(versionCommentId, response, trackedClass));
    resolve();
  } else if (error) {
    yield put(log.versionCommentUpdate.failure(versionCommentId, errorDetails));
    reject(errorDetails);
  }
}

function* versionCommentDelete(request) {
  const { versionCommentId, reject, resolve, trackedClass } = request;
  const token = yield tokenService.getAuthToken();
  const { response, error, errorDetails } = yield call(
    api.versionCommentDelete,
    token,
    versionCommentId
  );
  if (response) {
    yield put(log.versionCommentDelete.success(versionCommentId, response, trackedClass));
    resolve();
  } else if (error) {
    yield put(log.versionCommentDelete.failure(versionCommentId, errorDetails));
    reject(errorDetails);
  }
}

export function* watchVersionCommentAdd() {
  while (true) {
    const request = yield take(log.VERSION_COMMENT_ADD.REQUEST);
    yield fork(versionCommentAdd, request);
  }
}

export function* watchVersionCommentUpdate() {
  while (true) {
    const request = yield take(log.VERSION_COMMENT_UPDATE.REQUEST);
    yield fork(versionCommentUpdate, request);
  }
}

export function* watchVersionCommentDelete() {
  while (true) {
    const request = yield take(log.VERSION_COMMENT_DELETE.REQUEST);
    yield fork(versionCommentDelete, request);
  }
}
