import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Header, Modal, Table } from "semantic-ui-react";

import { downloadLinkModal } from "actions/modals";

import * as styles from "./styles.module.scss";

class DownloadLink extends React.Component {
  render() {
    const windowsUrl =
      "https://www.dropbox.com/s/1fztscltibnuv0i/work.bld.ai%20Setup%201.0.0.exe?dl=0";
    const linuxUrl = "https://www.dropbox.com/s/kwt6gclsv2bmtav/work.bld.ai-1.0.0.AppImage?dl=0";
    const macOsUrl = "https://www.dropbox.com/s/7vyeq8qmpkxdqfm/work.bld.ai-1.0.0.dmg?dl=0";

    const { showDownloadLinkModal, downloadLinkModalHide, downloadLinkModalShow } = this.props;

    return (
      <Modal
        open={showDownloadLinkModal}
        onOpen={downloadLinkModalShow}
        onClose={downloadLinkModalHide}
        className={styles.modal}
      >
        <Modal.Header>Download Links</Modal.Header>
        <Modal.Content>
          <Header as="h2" sub>
            Operating Systems
          </Header>
          <Table fixed compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Windows</Table.Cell>
                <Table.Cell>
                  <a
                    className={styles.downloadLink}
                    href={windowsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Linux</Table.Cell>
                <Table.Cell>
                  <a
                    className={styles.downloadLink}
                    href={linuxUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>MacOS</Table.Cell>
                <Table.Cell>
                  <a
                    className={styles.downloadLink}
                    href={macOsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }
}

DownloadLink.propTypes = {
  showDownloadLinkModal: PropTypes.bool.isRequired,
  downloadLinkModalShow: PropTypes.func.isRequired,
  downloadLinkModalHide: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    modals: { showDownloadLinkModal },
  } = state;
  return {
    showDownloadLinkModal,
  };
}

export default connect(mapStateToProps, {
  downloadLinkModalHide: downloadLinkModal.hide,
  downloadLinkModalShow: downloadLinkModal.show,
})(DownloadLink);
