import classnames from "classnames";
import { sumBy } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { CHANNEL_TABS } from "actions/chat";
import { projectType } from "types/project";
import { getRoomName } from "utils";
import { getActiveTabRoomUrl } from "utils/apps";

import ProjectRoom from "./ProjectRoom";
import styles from "./styles.module.scss";

class Project extends React.Component {
  handleSettingsRedirect = () => {
    const { project } = this.props;
    if (project.generalChannelId) {
      this.props.setChannelTab(project.generalChannelId, CHANNEL_TABS.SETTINGS);
    }
  };

  roomSorting = (roomA, roomB) => {
    if (roomA.isPrivate) return -1;
    if (roomB.isPrivate) return 1;
    if (roomA.isGeneral) return -1;
    if (roomB.isGeneral) return 1;
    if (roomA.name?.toLowerCase() < roomB.name?.toLowerCase()) return -1;
    if (roomA.name?.toLowerCase() > roomB.name?.toLowerCase()) return 1;
    return 0;
  };

  renderTitle(homeLink) {
    const { isCollapsed, project, projectRooms, unreadMessagesCountByRoom } = this.props;
    const isUnread = sumBy(projectRooms, (room) => unreadMessagesCountByRoom[room.id]) > 0;

    return (
      <Button
        as={Link}
        to={homeLink}
        className={classnames("focusable", styles.sidebarProject, isUnread && styles.unread)}
        data-focusable
        id={`project${project.id}`}
        tabIndex="0"
        title={project.name}
      >
        <span
          className={classnames(
            styles.sidebarProjectContent,
            project.generalChannelId && styles.closeable
          )}
        >
          <Icon name={isCollapsed ? "chevron down" : "chevron right"} />
          <span>{project.name}</span>
          <div className={styles.unreadProjectNotif} />
        </span>
        {project.generalChannelId && (
          <Button
            as={Link}
            to={`/chat/r/${project.generalChannelId}/settings`}
            icon="setting"
            className={classnames(styles.settingsButton, isCollapsed && styles.active)}
            onClick={this.handleSettingsRedirect}
          />
        )}
      </Button>
    );
  }

  render() {
    const {
      currentRoom,
      currentUser,
      isCollapsed,
      project,
      projectHomeRoomId,
      projectRooms,
      searchValue,
      unreadMessagesCountByRoom,
    } = this.props;

    if (!project) return null;

    if (projectRooms.length === 0) return null;
    const roomSearchValue = searchValue.split("#")[1] || "";
    const projectRoomsFiltered = roomSearchValue
      ? projectRooms.filter(
          (projectRoom) =>
            getRoomName(projectRoom, projectRoom.users, currentUser)
              .toLowerCase()
              .indexOf(roomSearchValue) >= 0
        )
      : projectRooms;

    if (roomSearchValue.length > 0 && projectRoomsFiltered.length === 0) {
      return null;
    }

    const homeRoom = roomSearchValue
      ? projectRoomsFiltered[0] ||
        projectRooms.find((room) => unreadMessagesCountByRoom[room.id] > 0) ||
        projectRooms.find((room) => room.isPrivate) ||
        projectRooms[0]
      : projectRooms.find((room) => unreadMessagesCountByRoom[room.id] > 0) ||
        projectRooms.find((room) => room.id === projectHomeRoomId) ||
        projectRooms.find((room) => room.isPrivate) ||
        projectRooms[0];

    const homeLink = getActiveTabRoomUrl(homeRoom, this.props.lastRoomTab) || "..";
    const projectRoomsSorted = projectRoomsFiltered.sort(this.roomSorting);

    return (
      <div className={isCollapsed ? styles.sidebarProjectCollapsed : null}>
        {this.renderTitle(homeLink)}

        {(isCollapsed || (roomSearchValue && projectRoomsSorted.length > 0)) && (
          <div className={styles.sidebarProjectRoomList} key={project.id}>
            {projectRoomsSorted.map((room) => {
              if (!room) return null;
              return (
                <ProjectRoom
                  isCurrent={room === currentRoom}
                  key={room.id}
                  room={room}
                  unreadMessagesCount={unreadMessagesCountByRoom[room.id] || 0}
                  lastRoomTab={this.props.lastRoomTab}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

Project.propTypes = {
  currentRoom: PropTypes.shape(),
  currentUser: PropTypes.shape(),
  isCollapsed: PropTypes.bool.isRequired,
  project: projectType.isRequired,
  projectHomeRoomId: PropTypes.number,
  projectRooms: PropTypes.arrayOf(PropTypes.shape()),
  searchValue: PropTypes.string,
  unreadMessagesCountByRoom: PropTypes.objectOf(PropTypes.number).isRequired,
  setChannelTab: PropTypes.func.isRequired,
  lastRoomTab: PropTypes.string,
};

Project.defaultProps = {
  projectRooms: [],
};

export default Project;
