import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import config from "config";

import { requireAuthentication } from "./auth";

export const requireAuthenticationSubscription = (WrappedComponent) =>
  requireAuthentication(
    connect(
      (state) => ({ isSubscribed: state.auth && state.auth.user && state.auth.user.isSubscribed }),
      {}
    )(({ isSubscribed, ...props }) => {
      const { pathname, search } = useLocation();

      if (config.stripeApiKey && !isSubscribed && pathname !== "/subscription")
        return <Redirect to={`/subscription?next=${pathname}`} />;
      if ((!config.stripeApiKey || isSubscribed) && pathname === "/subscription")
        return <Redirect to={qs.parse(search, { ignoreQueryPrefix: true }).next || "/"} />;
      return <WrappedComponent {...props} />;
    })
  );
