import { sortBy } from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { Dropdown } from "semantic-ui-react";

export default class TimezoneDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timezoneOptions = this.getTimezones();
  }

  getTimezones = () => {
    const { displayOffset } = this.props;
    const timezones = moment.tz.names();
    const offsetTmz = timezones.map((timezone) => {
      const tz = moment.tz(timezone).format("Z").replace(":00", "").replace(":30", ".5");
      const x = tz === 0 ? 0 : parseInt(tz, 10).toFixed(2);
      const offsetLabel = displayOffset ? `(GMT${moment.tz(timezone).format("Z")}) ` : "";
      return {
        text: `${offsetLabel}${timezone}`,
        value: `${timezone}`,
        time: `${x}`,
      };
    });
    return sortBy(offsetTmz, [(tz) => -tz.time]);
  };

  render() {
    const {
      className,
      disabled,
      fluid,
      name,
      onChange,
      placeholder,
      selectOnBlur,
      selectOnNavigation,
      value: defaultValue,
    } = this.props;

    return (
      <Dropdown
        disabled={disabled}
        fluid={fluid}
        name={name}
        onChange={onChange}
        options={this.timezoneOptions}
        placeholder={placeholder}
        search
        selection
        selectOnBlur={selectOnBlur}
        selectOnNavigation={selectOnNavigation}
        value={defaultValue}
        className={className}
      />
    );
  }
}

TimezoneDropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayOffset: PropTypes.bool,
  fluid: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  selectOnBlur: PropTypes.bool,
  selectOnNavigation: PropTypes.bool,
  value: PropTypes.string,
};

TimezoneDropdown.defaultProps = {
  disabled: false,
  displayOffset: false,
  fluid: false,
  name: "timezone",
  onChange: () => {},
  placeholder: "Select time zone",
  selectOnBlur: true,
  selectOnNavigation: true,
  value: "",
};
