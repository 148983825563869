const call = new Audio("/call.mp3");
const beep = new Audio("/beep.mp3");
const join = new Audio("/calljoin.mp3");
const hangup = new Audio("/hangup.mp3");
const start = new Audio("/callstart.mp3");

start.addEventListener("ended", () => {
  start.currentTime = 0;
  start.play();
});

call.addEventListener("ended", () => {
  call.currentTime = 0;
  call.play();
});

class NotificationSound {
  static playStart() {
    start.currentTime = 0;
    start.play().catch(() => {
      console.log("Waiting for participants");
    });
  }

  static playCall() {
    call.currentTime = 0;
    call.play().catch(() => {
      console.log("Someone is calling");
    });
  }

  static playBeep() {
    beep.currentTime = 0;
    beep.play().catch(() => {
      console.log("You received a message");
    });
  }

  static playJoin() {
    join.currentTime = 0;
    join.play().catch(() => {
      console.log("Someone joined a call");
    });
  }

  static playHangup() {
    hangup.currentTime = 0;
    hangup.play().catch(() => {
      console.log("Someone left a call");
    });
  }

  static pauseCall() {
    call.pause();
  }

  static pauseStart() {
    start.pause();
  }
}

export default NotificationSound;
