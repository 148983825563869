import {
  WORKMATES,
  FEEDBACK,
  WORKMATE_FEEDBACK,
  TALENT_FEEDBACK,
  SKILL_FEEDBACK,
  FEEDBACK_REQUEST,
  FEEDBACK_WIZARD,
  GROUP_FEEDBACK,
  CONJOINT_PAIR,
  CONJOINT_FEEDBACK,
  UNDO_CONJOINT_FEEDBACK,
  RATINGS_HISTORY,
  TEAM_RATINGS_HISTORY,
  RELATIONS,
  COMMON_SKILL,
} from "actions/feedback";

import { createReducerType, createReducerTypeTrackLast } from "./base";

export function createRatingsHistoryReducer(entity) {
  return (
    state = {
      loading: false,
      success: true,
      failure: false,
      errorDetails: null,
      data: [],
    },
    action
  ) => {
    const { type, response } = action;

    switch (type) {
      case entity.REQUEST:
        return { ...state, loading: true, success: false, failure: false, errorDetails: null };
      case entity.SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          failure: false,
          errorDetails: null,
          data: Object.assign([], response.entities.ratingsHistory.ratings),
        };
      case entity.FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          failure: true,
          errorDetails: { ...action.errorDetails },
        };
      default:
        return state;
    }
  };
}

export function createRelationsReducer(entity) {
  return (
    state = {
      loading: false,
      success: true,
      failure: false,
      errorDetails: null,
      data: { nodes: [], links: [] },
    },
    action
  ) => {
    const { type, response } = action;

    switch (type) {
      case entity.REQUEST:
        return { ...state, loading: true, success: false, failure: false, errorDetails: null };
      case entity.SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          failure: false,
          errorDetails: null,
          data: Object.assign({}, response.entities.relations.relations),
        };
      case entity.FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          failure: true,
          errorDetails: { ...action.errorDetails },
        };
      default:
        return state;
    }
  };
}

export function createCommonSkillReducer(entity) {
  return (
    state = {
      loading: false,
      success: true,
      failure: false,
      errorDetails: null,
      commonSkill: [],
    },
    action
  ) => {
    const { type, response } = action;

    switch (type) {
      case entity.REQUEST:
        return {
          ...state,
          loading: true,
          success: false,
          failure: false,
          errorDetails: null,
          commonSkill: [],
        };
      case entity.SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          failure: false,
          errorDetails: null,
          commonSkill: Object.assign([], response.entities.commonSkill.commonSkill),
        };
      case entity.FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          failure: true,
          errorDetails: { ...action.errorDetails },
          commonSkill: [],
        };
      default:
        return state;
    }
  };
}

export const workmates = createReducerType(WORKMATES);
export const feedback = createReducerType(FEEDBACK);
export const workmateFeedback = createReducerType(WORKMATE_FEEDBACK);
export const talentFeedback = createReducerType(TALENT_FEEDBACK);
export const skillFeedback = createReducerType(SKILL_FEEDBACK);
export const feedbackRequest = createReducerType(FEEDBACK_REQUEST);
export const feedbackWizard = createReducerType(FEEDBACK_WIZARD);
export const groupFeedback = createReducerType(GROUP_FEEDBACK);
export const conjointPair = createReducerType(CONJOINT_PAIR);
export const conjointFeedback = createReducerTypeTrackLast(CONJOINT_FEEDBACK);
export const undoConjointFeedback = createReducerType(UNDO_CONJOINT_FEEDBACK);
export const ratingsHistory = createRatingsHistoryReducer(RATINGS_HISTORY);
export const teamRatingsHistory = createRatingsHistoryReducer(TEAM_RATINGS_HISTORY);
export const relations = createRelationsReducer(RELATIONS);
export const commonSkill = createCommonSkillReducer(COMMON_SKILL);
