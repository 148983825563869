export const MESSAGES = {
  header: {
    id: "Notification.header",
    defaultMessage: "Notifications",
  },
  markRead: {
    id: "Notification.markRead",
    defaultMessage: "Mark all as read",
  },
  filterHeader: {
    id: "Notification.filterHeader",
    defaultMessage: "Show:",
  },
};
