import config from "config";

export const MESSAGES = {
  signupTitle: {
    id: "SignUp.title",
    defaultMessage: `${config.appName} - Signup`,
  },
  loginTitle: {
    id: "Login.title",
    defaultMessage: `${config.appName} - Login`,
  },
  header: {
    id: "SignUp.header",
    defaultMessage: `Create Account`,
  },
  loginHeader: {
    id: "Signup.loginHeader",
    defaultMessage: `Account Login`,
  },
  subHeader: {
    id: "Signup.subHeader",
    defaultMessage: `Welcome to ${config.appName}`,
  },
  socHeader: {
    id: "SignUp.social.header",
    defaultMessage: "Login using your social account",
  },
  socSignupHeader: {
    id: "SignUp.social.signuoHeader",
    defaultMessage: "Signup using your social account",
  },
  adHeader: {
    id: "SignUp.ad.header",
    defaultMessage: "Enterprise customer? We support SSO",
  },
  adSubHeader: {
    id: "SignUp.ad.subHeader",
    defaultMessage: "Email us at active_directory@talented.app",
  },
  firstName: {
    id: "SignUp.form.firstName",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "SignUp.form.lastName",
    defaultMessage: "Last Name",
  },
  email: {
    id: "SignUp.form.email",
    defaultMessage: "Email",
  },
  password: {
    id: "SignUp.form.password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "SignUp.form.confirmPassword",
    defaultMessage: "Confirm Password",
  },
  mobile: {
    id: "SignUp.form.mobile",
    defaultMessage: "Mobile",
  },
  twofa: {
    id: "SignUp.form.twofa",
    defaultMessage: "Setup two factor authentication",
  },
  location: {
    id: "SignUp.form.location",
    defaultMessage: "Country of Residence",
  },
  signupAsTeam: {
    id: "Signup.form.signupAsTeam",
    defaultMessage: "Signup as a Team",
  },
  teamName: {
    id: "Signup.form.teamName",
    defaultMessage: "Team Name",
  },
  addMembers: {
    id: "Signup.form.addMembers",
    defaultMessage: "Add Members",
  },
  signup: {
    id: "SignUp.form.signup",
    defaultMessage: "Create account",
  },
  login: {
    id: "SignUp.form.login",
    defaultMessage: "Login",
  },
  passwordReset: {
    id: "SignUp.passwordReset",
    defaultMessage: "Forgot Password",
  },
  beginAddingPeople: {
    id: "SignUp.beginAddingPeople",
    defaultMessage: "Add people",
  },
  legalAge: {
    id: "Signup.legalAge",
    defaultMessage:
      "I understand that I must be over 18 years old to offer my services on work.bld.ai",
  },
  createAccount: {
    id: "SignUp.createAccount",
    defaultMessage: "No account yet?",
  },
  hasAccount: {
    id: "SignUp.hasAccount",
    defaultMessage: "Already have an account?",
  },
};
