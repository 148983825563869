import {
  cleanEventOccurrences,
  cleanGoogleEventOccurrences,
  cleanOutlookEventOccurrences,
  loadUserCalendar,
} from "actions/calendar";
import {
  getRoom,
  pollDelete,
  pollDetail,
  pusherCallStarted,
  pusherReceiveMessage,
  pusherSendMessage,
  pusherUserKicked,
  pusherUserTyping,
  updateReadMessages,
  reactionsAdd,
  reactionsDelete,
  roomMeetingDetail,
  roomMembershipDetail,
} from "actions/chat";
import { addClientFeedback } from "actions/clientFeedback";
import { notification as notificationAction } from "actions/notification";
import { projectDetail, projectInvitationsDetail, projectMembershipDetail } from "actions/project";
import { addReminders } from "actions/reminders";
import { socket } from "actions/socket";
import {
  setUserPresence,
  teamDetail,
  teamInvitationDetail,
  teamMembershipDetail,
  userDetail,
} from "actions/user";

import config from "config";
import Ringer from "containers/Ringer";
import { camelizeKeys } from "humps";
import { find } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { getCurrentRoom } from "reducers/selectors";
import { pollType } from "types/poll";
import { getNotificationSettingsItem, launchCall } from "utils";
import NotificationSound from "utils/notificationSound";
import * as responsive from "utils/responsive";

import * as events from "./events";

class Pusher extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      callCode: null,
      caller: null,
      callerName: null,
      callModal: false,
    };
  }

  componentDidMount() {
    if (this.props.currentUser && this.props.token) {
      this.connect();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser && !prevProps.token && this.props.token) {
      this.connect();
    } else if (
      (prevProps.currentUser && !this.props.currentUser) ||
      (prevProps.token && !this.props.token)
    ) {
      this.disconnect();
    }
  }

  componentWillUnmount() {
    this.disconnect();
  }

  onModalCallStartedJoin = (callCode) => {
    this.startCall(callCode);
    this.setState({
      callModal: false,
    });
  };

  onModalCallStartedIgnore = () => {
    this.setState({
      callCode: null,
      caller: null,
      callerName: null,
      callModal: false,
    });
  };

  connect = () => {
    const { token, currentUser } = this.props;
    const channelName = `private-user-${currentUser.slug}`;
    this.callbacks = {};

    this.websocket = new ReconnectingWebSocket(
      `${config.websocketUrl}/chat/${channelName}/?token=${token.substring(0, 8)}`
    );

    this.websocket.addEventListener("open", this.eventConnected);
    this.websocket.addEventListener("close", this.eventDisconnected);
    this.websocket.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);

      if (message.event && this.callbacks[message.event]) {
        this.callbacks[message.event](message);
      }
    });

    this.bindEvents();
  };

  disconnect = () => {
    if (this.websocket) {
      this.websocket.close();
    }
  };

  bind = (event, callback) => {
    this.callbacks[event] = callback;
  };

  unbind = (event) => {
    delete this.callbacks[event];
  };

  bindEvents = () => {
    this.callbacks = {};

    this.bind(events.CALL_DISCONNECTED, this.eventCallDisconnected);
    this.bind(events.CALL_JOINED, this.eventCallJoined);

    if (responsive.isPopup()) {
      return;
    }

    this.bind(events.CALL_STARTED, this.eventCallStarted);
    this.bind(events.ROOM_CREATED, this.eventRoomCreated);
    this.bind(events.ROOM_SWITCH, this.eventRoomSwitch);
    this.bind(events.USER_JOINED, this.eventUserJoined);
    this.bind(events.NEW_USER, this.eventNewUser);
    this.bind(events.TEAM_MEMBERSHIP, this.eventTeamMembership);
    this.bind(events.TEAM, this.eventTeam);
    this.bind(events.USER, this.eventUser);
    this.bind(events.MESSAGE_SENT, this.eventMessageSent);
    this.bind(events.MESSAGE_READ, this.eventMessageRead);
    this.bind(events.POLL, this.eventPoll);
    this.bind(events.PROJECT, this.eventProject);
    this.bind(events.PROJECT_INVITATION, this.eventProjectInvitation);
    this.bind(events.PROJECT_MEMBERSHIP, this.eventProjectMembership);
    this.bind(events.ROOM_MEMBERSHIP, this.eventRoomMembership);
    this.bind(events.USER_KICKED, this.eventUserKicked);
    this.bind(events.USER_PRESENCE, this.eventUserPresence);
    this.bind(events.USER_TYPING, this.eventUserTyping);
    this.bind(events.NEW_NOTIFICATION, this.eventNewNotification);
    this.bind(events.REACTION_ADDED, this.eventReactionAdded);
    this.bind(events.REACTION_DELETED, this.eventReactionDeleted);
    this.bind(events.USER_GOOGLE_AVAILABILITY, this.eventUserGoogleAvailability);
    this.bind(events.USER_OUTLOOK_AVAILABILITY, this.eventUserOutlookAvailability);
    this.bind(events.USER_AVAILABILITY, this.eventUserAvailability);
    this.bind(events.ROOM_MEETING, this.eventRoomMeeting);
    this.bind(events.TEAM_INVITATION, this.eventTeamInvitation);
    this.bind(events.EVENT_NEW_REMINDER, this.eventNewReminder);
    this.bind(events.EVENT_NEW_CLIENT_FEEDBACK, this.eventNewClientFeedback);
  };

  eventConnected = () => {
    this.props.online();
  };

  eventDisconnected = () => {
    this.props.offline();
  };

  eventCallDisconnected = (data) => {
    const { callCode, token, currentUser, rooms } = this.props;
    const room = camelizeKeys(data);
    const { author, roomId } = room;
    room.id = roomId;

    const callSound = getNotificationSettingsItem(
      rooms[roomId],
      currentUser,
      "notificationCallSound"
    );
    NotificationSound.pauseCall();
    NotificationSound.pauseStart();
    if (callSound && (callCode || author === currentUser.slug)) {
      NotificationSound.playHangup();
    }

    this.setState({
      callCode: null,
      caller: null,
      callerName: null,
      callModal: false,
    });
    this.props.getRoom({ id: roomId, token, room });
  };

  eventCallJoined = (data) => {
    const { token } = this.props;
    const room = camelizeKeys(data);
    const { roomId } = room;
    room.id = roomId;

    this.props.getRoom({ id: roomId, token, room });
  };

  eventCallStarted = (data) => {
    const { currentUser, rooms, users } = this.props;
    const { author, callCode, displayName } = camelizeKeys(data);

    if (currentUser.slug !== author) {
      const room = find(rooms, (r) => r.callCode === callCode);
      if (room) {
        const callSound = getNotificationSettingsItem(room, currentUser, "notificationCallSound");
        if (callSound) {
          NotificationSound.playCall();
        }
      }

      if (window.__ElectronBridge) {
        window.__ElectronBridge.showToast({
          displayName,
          message: `${displayName} is calling`,
        });
      }

      this.setState({
        callCode,
        caller: users[author] || null,
        callerName: displayName,
        callModal: true,
      });
    }

    this.props.pusherCallStarted({ callCode });
  };

  playNotificationSound = (room) => {
    NotificationSound.playBeep();
    if (window.__ElectronBridge) {
      window.__ElectronBridge.showToast(room);
    }
  };

  eventMessageSent = (data) => {
    const { currentUser, messagesByRooms, rooms, token } = this.props;
    const room = camelizeKeys(data);
    const { slug } = currentUser || {};
    const { author, roomId } = room;
    const messageSound = getNotificationSettingsItem(
      rooms[roomId],
      currentUser,
      "notificationMessageSound"
    );

    const systemMessageSound = getNotificationSettingsItem(
      rooms[roomId],
      currentUser,
      "notificationSystemMessageSound"
    );

    if (!messagesByRooms[roomId] || !messagesByRooms[roomId][room.messageId]) {
      if (!this.isRoomVisible(roomId) && slug !== author) {
        if (!author && systemMessageSound) {
          this.playNotificationSound(room);
        } else if (author && messageSound) {
          this.playNotificationSound(room);
        }
      }
    }

    if (rooms && !rooms[roomId]) {
      this.props.getRoom({ id: roomId, token, room });
    }

    this.props.pusherReceiveMessage({ data, user: currentUser });
  };

    eventMessageRead = (data) => {
    this.props.updateReadMessages({ token: this.props.token, id: data.chat_pk });
  };

  eventNewReminder = (data) => {
    this.props.addReminders(camelizeKeys(data.data));
  };

  eventNewClientFeedback = (data) => {
    this.props.addClientFeedback(camelizeKeys(data.data));
  };

  eventPoll = (data) => {
    const { currentUser, pollsByRooms } = this.props;
    const { author, isDeleted, pollId, roomId } = camelizeKeys(data);

    if (isDeleted) {
      const polls = pollsByRooms[roomId] || {};
      const poll = polls[pollId] || {};

      this.props.pollDelete(poll, {});
    } else {
      const fromCurrentUser = currentUser.slug === author;

      this.props.pollDetail(pollId, fromCurrentUser);
    }
  };

  eventProject = (data) => {
    const { project } = camelizeKeys(data);

    this.props.projectDetail(project);
  };

  eventProjectInvitation = (data) => {
    const { projectInvitation } = camelizeKeys(data);

    this.props.projectInvitationsDetail(projectInvitation);
  };

  eventProjectMembership = (data) => {
    const { projectMembership } = camelizeKeys(data);

    this.props.projectMembershipDetail(projectMembership);
  };

  eventRoomCreated = (data) => {
    const { token } = this.props;
    const room = camelizeKeys(data);
    const { roomId } = room;
    room.id = roomId;

    this.props.getRoom({ id: roomId, token, room });
  };

  eventRoomSwitch = (data) => {
    const { history } = this.props;
    const room = camelizeKeys(data);
    const { roomId } = room;

    history.push(`/chat/r/${roomId}`);
  };

  eventRoomMembership = (data) => {
    const { roomMembership } = camelizeKeys(data);

    this.props.roomMembershipDetail(roomMembership.id, roomMembership);
  };

  eventTeamMembership = (data) => {
    const { teamMembership } = camelizeKeys(data);

    this.props.teamMembershipDetail(teamMembership);
  };

  eventTeamInvitation = (data) => {
    const { invitation } = camelizeKeys(data);

    this.props.teamInvitationDetail(invitation);
  };

  eventTeam = (data) => {
    const { team } = camelizeKeys(data);

    this.props.teamDetail(team);
  };

  eventUser = (data) => {
    const { user } = camelizeKeys(data);

    this.props.userDetail(user);
  };

  eventUserJoined = (data) => {
    const { token } = this.props;
    const room = camelizeKeys(data);
    const { roomId } = room;

    this.props.getRoom({ id: roomId, token, room });
  };

  eventUserKicked = (data) => {
    const { currentUser } = this.props;
    const room = camelizeKeys(data);
    const { roomId, userSlugs } = room;

    this.props.pusherUserKicked({ currentUser, roomId, userSlugs });
  };

  eventUserPresence = (data) => {
    this.props.setUserPresence(data.author, data.online);
  };

  eventUserTyping = (data) => {
    const { currentUser } = this.props;
    const { roomId, author } = camelizeKeys(data);
    if (currentUser.slug !== author) {
      this.props.pusherUserTypingStart({ id: roomId, author });
    }
  };

  eventNewNotification = () => {
    this.props.loadNotifications();
  };

  eventNewUser = (data) => {
    const { user } = camelizeKeys(data);

    this.props.userDetail(user);
  };

  eventReactionAdded = (data) => {
    const { author, reactionId, roomId, messageId, emoji } = camelizeKeys(data);
    this.props.reactionsAddSuccess({
      entities: {
        reactions: {
          [reactionId]: {
            emoji,
            id: reactionId,
            message: messageId,
            room: roomId,
            user: author,
          },
        },
      },
    });
  };

  eventReactionDeleted = (data) => {
    const { reactionId } = camelizeKeys(data);
    this.props.reactionsDeleteSuccess({ id: reactionId });
  };

  eventUserGoogleAvailability = (data) => {
    const { currentUser } = this.props;
    const { deactivatedCalIds, deleted } = camelizeKeys(data);
    if (deactivatedCalIds && deactivatedCalIds.length > 0) {
      this.props.cleanGoogleEventOccurrences(currentUser.slug, deactivatedCalIds, deleted);
    }
    this.props.loadUserCalendar(currentUser.slug, true, {});
  };

  eventUserOutlookAvailability = (data) => {
    const { currentUser } = this.props;
    const { deactivatedCalIds, deleted } = camelizeKeys(data);
    if (deactivatedCalIds && deactivatedCalIds.length > 0) {
      this.props.cleanOutlookEventOccurrences(currentUser.slug, deactivatedCalIds, deleted);
    }
    this.props.loadUserCalendar(currentUser.slug, true, {});
  };

  eventUserAvailability = (data) => {
    const { author, eventId, isDeleted } = camelizeKeys(data);
    this.props.cleanEventOccurrences(author, [eventId], isDeleted);
  };

  eventRoomMeeting = (data) => {
    const { currentUser } = this.props;
    const { author, roomId, eventId, isCreated, isDeleted, isUpdated } = camelizeKeys(data);
    const fromCurrentUser = currentUser.slug === author || !isCreated;
    if (isUpdated || isDeleted) {
      this.props.cleanEventOccurrences(roomId, [eventId], isDeleted);
    }
    if (!isDeleted) {
      this.props.roomMeetingDetail({ fromCurrentUser, rid: roomId, oid: eventId });
    }
  };

  isRoomVisible = (roomId) => {
    if (!document.hasFocus()) {
      return false;
    }
    const { currentRoom } = this.props;
    if (!currentRoom || currentRoom.id !== roomId) {
      return false;
    }
    return true;
  };

  startCall = (callCode) => {
    NotificationSound.pauseCall();
    launchCall(callCode);
  };

  render() {
    if (!this.state.callModal) {
      return null;
    }

    if (responsive.isPopup()) {
      return null;
    }

    return (
      <Ringer
        callCode={this.state.callCode}
        caller={this.state.caller}
        callerName={this.state.callerName}
        onIgnoreClick={this.onModalCallStartedIgnore}
        onJoinClick={this.onModalCallStartedJoin}
      />
    );
  }
}

Pusher.propTypes = {
  updateReadMessages: PropTypes.func.isRequired,
  addReminders: PropTypes.func.isRequired,
  addClientFeedback: PropTypes.func.isRequired,
  callCode: PropTypes.string,
  messagesByRooms: PropTypes.objectOf(PropTypes.shape()),
  pollsByRooms: PropTypes.objectOf(PropTypes.objectOf(pollType)),
  rooms: PropTypes.shape(),
  token: PropTypes.string,
  currentRoom: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  currentUser: PropTypes.shape(),
  users: PropTypes.objectOf(PropTypes.shape()).isRequired,
  cleanEventOccurrences: PropTypes.func.isRequired,
  cleanGoogleEventOccurrences: PropTypes.func.isRequired,
  cleanOutlookEventOccurrences: PropTypes.func.isRequired,
  getRoom: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loadNotifications: PropTypes.func.isRequired,
  loadUserCalendar: PropTypes.func.isRequired,
  offline: PropTypes.func.isRequired,
  online: PropTypes.func.isRequired,
  pollDelete: PropTypes.func.isRequired,
  pollDetail: PropTypes.func.isRequired,
  projectDetail: PropTypes.func.isRequired,
  projectInvitationsDetail: PropTypes.func.isRequired,
  projectMembershipDetail: PropTypes.func.isRequired,
  pusherCallStarted: PropTypes.func.isRequired,
  pusherReceiveMessage: PropTypes.func.isRequired,
  pusherUserKicked: PropTypes.func.isRequired,
  pusherUserTypingStart: PropTypes.func.isRequired,
  reactionsAddSuccess: PropTypes.func.isRequired,
  reactionsDeleteSuccess: PropTypes.func.isRequired,
  roomMeetingDetail: PropTypes.func.isRequired,
  roomMembershipDetail: PropTypes.func.isRequired,
  setUserPresence: PropTypes.func.isRequired,
  teamMembershipDetail: PropTypes.func.isRequired,
  teamInvitationDetail: PropTypes.func.isRequired,
  userDetail: PropTypes.func.isRequired,
  teamDetail: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { token, user },
    entities: { users },
    messages: { items: messagesByRooms },
    polls: { items: pollsByRooms },
    rooms: { callId, callCode, callToken, items: rooms },
  } = state;
  const currentRoom = getCurrentRoom(state, user);

  let notificationSettings = {};
  if (user) {
    ({ notificationSettings } = user);
  }
  if (currentRoom && currentRoom.project) {
    ({ notificationSettings } = currentRoom);
  }

  return {
    currentRoom,
    currentUser: user,
    token,
    callCode,
    callId,
    callToken,
    messagesByRooms,
    pollsByRooms,
    rooms,
    users,
    notificationSettings,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateReadMessages: updateReadMessages.request,
    addReminders,
    addClientFeedback,
    cleanEventOccurrences,
    cleanGoogleEventOccurrences,
    cleanOutlookEventOccurrences,
    loadUserCalendar,
    pusherUserKicked,
    setUserPresence,
    getRoom: getRoom.request,
    loadNotifications: notificationAction.init,
    offline: socket.offline,
    online: socket.online,
    pollDelete: pollDelete.success,
    pollDetail: pollDetail.request,
    projectDetail: projectDetail.success,
    projectInvitationsDetail: projectInvitationsDetail.success,
    projectMembershipDetail: projectMembershipDetail.success,
    pusherCallStarted: pusherCallStarted.request,
    pusherReceiveMessage: pusherReceiveMessage.request,
    pusherSendMessage: pusherSendMessage.request,
    pusherUserTypingStart: pusherUserTyping.start,
    reactionsAddSuccess: reactionsAdd.success,
    reactionsDeleteSuccess: reactionsDelete.success,
    roomMeetingDetail: roomMeetingDetail.request,
    roomMembershipDetail: roomMembershipDetail.success,
    teamMembershipDetail: teamMembershipDetail.success,
    teamInvitationDetail: teamInvitationDetail.success,
    userDetail: userDetail.success,
    teamDetail: teamDetail.success,
  })(Pusher)
);
