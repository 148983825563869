import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";

import { downloadLinkModal } from "actions/modals";

class Sidebar extends React.Component {
  render() {
    const { className, text } = this.props;

    return (
      <Button className={className} onClick={this.props.downloadLinkModalShow}>
        {text}
      </Button>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  downloadLinkModalShow: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  text: "Download App",
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {
  downloadLinkModalShow: downloadLinkModal.show,
})(Sidebar);
