import PropTypes from "prop-types";
import React from "react";
import { Button } from "semantic-ui-react";

import TimezoneDropdown from "components/TimezoneDropdown";
import styles from "./styles.module.scss";
import TimezoneViewerHeaderDays from "./TimezoneViewerHeaderDays";

export default class TimezoneViewerHeader extends React.PureComponent {
  render() {
    const { currentDate, isSelected, onNavigate, onScheduleMeeting, onTimezoneAdd } = this.props;

    return (
      <div className={styles.timezoneViewerHeader}>
        <div key="add" className={styles.timezoneViewerGroup}>
          <TimezoneDropdown
            displayOffset
            fluid
            onChange={onTimezoneAdd}
            placeholder="Add a time zone"
            selectOnBlur={false}
            selectOnNavigation={false}
          />
        </div>
        <div key="nav" className={styles.timezoneViewerTimeline}>
          {isSelected && onScheduleMeeting ? (
            <Button primary content="Schedule Meeting" onClick={onScheduleMeeting} type="button" />
          ) : (
            <TimezoneViewerHeaderDays currentDate={currentDate} onNavigate={onNavigate} />
          )}
        </div>
      </div>
    );
  }
}

TimezoneViewerHeader.propTypes = {
  currentDate: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool,
  onNavigate: PropTypes.func.isRequired,
  onTimezoneAdd: PropTypes.func.isRequired,
  onScheduleMeeting: PropTypes.func,
};
