import classnames from "classnames";
import { sortBy, take, takeRight } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Popup } from "semantic-ui-react";

import UserAvatar from "components/UserAvatar";
import UserListModal from "components/UserListModal";
import { sentinelSlug } from "utils/constants";

import styles from "./styles.module.scss";

const AvatarList = ({
  anonymous = false,
  anonymousCount = 0,
  className,
  maxShown = 4,
  size = 25,
  users = [],
}) => {
  const maxShownActual = anonymous ? 0 : maxShown;
  const usersSorted = sortBy(users, "displayName");
  const usersShown = take(usersSorted, maxShownActual).reverse();
  const usersHidden = takeRight(usersSorted, usersSorted.length - usersShown.length);

  const showMoreStyle = {
    height: `${size}px`,
    lineHeight: `${size}px`,
    width: `${size}px`,
  };
  const showMore = (
    <div
      className={classnames(styles.avatarListItem, anonymous && styles.anonymous)}
      style={showMoreStyle}
    >
      +{usersHidden.length}
    </div>
  );
  const anonymousCountInfo = (
    <div
      className={classnames(styles.avatarListItem, anonymous && styles.anonymous)}
      style={showMoreStyle}
    >
      +{anonymousCount}
    </div>
  );

  const showMorePreview = (
    <div>
      {usersHidden.slice(0, 5).map((user) => (
        <React.Fragment key={user.slug}>
          {user.displayName}
          <br />
        </React.Fragment>
      ))}
      {usersHidden.length >= 5 && <span>more&hellip;</span>}
    </div>
  );

  return (
    <div className={classnames(styles.avatarList, className)}>
      {usersShown.map((user) => {
        const avatarElement = (
          <UserAvatar
            avatar={user.avatarOrDefault}
            bgColor={user.avatarBgcolor}
            displayName={user.displayName}
            fgColor={user.avatarColor}
            round
            size={size}
          />
        );
        if (sentinelSlug !== user.slug) {
          return (
            <Link
              className={styles.avatarListItem}
              key={user.slug}
              target="_blank"
              title={user.displayName}
              to={`/chat/u/${user.slug}`}
            >
              {avatarElement}
            </Link>
          );
        }
        return (
          <span className={styles.avatarListItem} key={user.slug}>
            {avatarElement}
          </span>
        );
      })}

      {usersHidden.length > 0 && anonymous && showMore}
      {usersHidden.length > 0 && !anonymous && (
        <Popup
          content={showMorePreview}
          inverted
          size="mini"
          style={{ padding: 5 }}
          trigger={<UserListModal users={usersSorted} trigger={showMore} />}
        />
      )}
      {anonymousCount > 0 && anonymousCountInfo}
    </div>
  );
};

AvatarList.propTypes = {
  anonymous: PropTypes.bool,
  anonymousCount: PropTypes.number,
  className: PropTypes.string,
  maxShown: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  users: PropTypes.arrayOf(PropTypes.shape()),
};

export default AvatarList;
