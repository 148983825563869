import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { removeReminder, updateReminder } from "actions/reminders";
import { getReminders } from "reducers/selectors";
import Reminder from "./Reminder";
import styles from "./styles.module.scss";

const Reminders = ({
  currentUser,
  reminders,
  removeReminder: onRemove,
  updateReminder: onUpdate,
}) => {
  return (
    <div className={styles.reminders}>
      {Object.keys(reminders).map((r) => (
        <Reminder
          key={`reminder-${r}`}
          currentUser={currentUser}
          data={reminders[r]}
          onExpire={onRemove}
          update={onUpdate}
        />
      ))}
    </div>
  );
};

Reminders.propTypes = {
  reminders: PropTypes.shape(),
  removeReminder: PropTypes.func.isRequired,
  updateReminder: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { user },
  } = state;
  const reminders = getReminders(state);
  return {
    currentUser: user,
    reminders,
  };
}

export default connect(mapStateToProps, {
  removeReminder,
  updateReminder: updateReminder.init,
})(Reminders);
