import React from "react";
import PropTypes from "prop-types";
import { Dimmer, Icon, Loader } from "semantic-ui-react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { previewAttachment } from "services/api/chat";
import { isObject, isString } from "lodash";
import styles from "./styles.module.scss";

export default class TextViewer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codeString: null,
      error: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    previewAttachment(this.props.filePath)
      .then(({ response, error }) => {
        if (response) {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({
              codeString: reader.result,
              error: false,
              errorMessage: "",
            });
          };
          reader.readAsText(response);
        } else if (isString(error)) {
          this.setState({
            codeString: null,
            error: true,
            errorMessage: error,
          });
        } else if (isObject(error) && error.offline) {
          this.setState({
            codeString: null,
            error: true,
            errorMessage: error.offline,
          });
        }
      })
      .catch(() => {
        // Catch if the FileReader throws an error
        this.setState({
          codeString: null,
          error: true,
          errorMessage: "There was a problem with loading the file preview.",
        });
      });
  }

  render() {
    const { codeString, error, errorMessage } = this.state;

    if (error && errorMessage) {
      return (
        <div className={styles.error}>
          <Icon name="warning circle" />
          {errorMessage}
        </div>
      );
    }

    if (codeString === null) {
      return (
        <div>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </div>
      );
    }

    return (
      <SyntaxHighlighter
        language="plaintext"
        style={vs}
        showLineNumbers
        customStyle={{
          maxHeight: 480,
        }}
      >
        {codeString}
      </SyntaxHighlighter>
    );
  }
}

TextViewer.propTypes = {
  filePath: PropTypes.string.isRequired,
};
