import React from "react";
import { Button, Icon } from "semantic-ui-react";
import config from "config";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/windowslive/login/`;

const MicrosoftButton = (props) => (
  <Button color="grey" fluid onClick={() => (window.location.href = LOGIN_URL)} {...props}>
    <Icon name="windows" />
    Microsoft
  </Button>
);

export default MicrosoftButton;
