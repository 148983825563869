export const budgetVisualizationOptions = {
  day: 0,
  month: 1,
  year: 2,
};

export const countryOptions = [
  { key: "aa", value: undefined, text: "Any Country" },
  { key: "af", value: "af", flag: "af", text: "Afghanistan" },
  { key: "ax", value: "ax", flag: "ax", text: "Aland Islands" },
  { key: "al", value: "al", flag: "al", text: "Albania" },
  { key: "dz", value: "dz", flag: "dz", text: "Algeria" },
  { key: "as", value: "as", flag: "as", text: "American Samoa" },
  { key: "ad", value: "ad", flag: "ad", text: "Andorra" },
  { key: "ao", value: "ao", flag: "ao", text: "Angola" },
  { key: "ai", value: "ai", flag: "ai", text: "Anguilla" },
  { key: "ag", value: "ag", flag: "ag", text: "Antigua" },
  { key: "ar", value: "ar", flag: "ar", text: "Argentina" },
  { key: "am", value: "am", flag: "am", text: "Armenia" },
  { key: "aw", value: "aw", flag: "aw", text: "Aruba" },
  { key: "au", value: "au", flag: "au", text: "Australia" },
  { key: "at", value: "at", flag: "at", text: "Austria" },
  { key: "az", value: "az", flag: "az", text: "Azerbaijan" },
  { key: "bs", value: "bs", flag: "bs", text: "Bahamas" },
  { key: "bh", value: "bh", flag: "bh", text: "Bahrain" },
  { key: "bd", value: "bd", flag: "bd", text: "Bangladesh" },
  { key: "bb", value: "bb", flag: "bb", text: "Barbados" },
  { key: "by", value: "by", flag: "by", text: "Belarus" },
  { key: "be", value: "be", flag: "be", text: "Belgium" },
  { key: "bz", value: "bz", flag: "bz", text: "Belize" },
  { key: "bj", value: "bj", flag: "bj", text: "Benin" },
  { key: "bm", value: "bm", flag: "bm", text: "Bermuda" },
  { key: "bt", value: "bt", flag: "bt", text: "Bhutan" },
  { key: "bo", value: "bo", flag: "bo", text: "Bolivia" },
  { key: "ba", value: "ba", flag: "ba", text: "Bosnia", alias: "Bosnia and Herzegovina" },
  { key: "bw", value: "bw", flag: "bw", text: "Botswana" },
  { key: "bv", value: "bv", flag: "bv", text: "Bouvet Island" },
  { key: "br", value: "br", flag: "br", text: "Brazil" },
  { key: "vg", value: "vg", flag: "vg", text: "British Virgin Islands" },
  { key: "bn", value: "bn", flag: "bn", text: "Brunei" },
  { key: "bg", value: "bg", flag: "bg", text: "Bulgaria" },
  { key: "bf", value: "bf", flag: "bf", text: "Burkina Faso" },
  { key: "bi", value: "bi", flag: "bi", text: "Burundi" },
  { key: "tc", value: "tc", flag: "tc", text: "Caicos Islands" },
  { key: "kh", value: "kh", flag: "kh", text: "Cambodia" },
  { key: "cm", value: "cm", flag: "cm", text: "Cameroon" },
  { key: "ca", value: "ca", flag: "ca", text: "Canada" },
  { key: "cv", value: "cv", flag: "cv", text: "Cape Verde" },
  { key: "ky", value: "ky", flag: "ky", text: "Cayman Islands" },
  { key: "cf", value: "cf", flag: "cf", text: "Central African Republic" },
  { key: "td", value: "td", flag: "td", text: "Chad" },
  { key: "cl", value: "cl", flag: "cl", text: "Chile" },
  { key: "cn", value: "cn", flag: "cn", text: "China" },
  { key: "cx", value: "cx", flag: "cx", text: "Christmas Island" },
  { key: "cc", value: "cc", flag: "cc", text: "Cocos Islands" },
  { key: "co", value: "co", flag: "co", text: "Colombia" },
  { key: "km", value: "km", flag: "km", text: "Comoros" },
  { key: "cg", value: "cg", flag: "cg", text: "Congo Brazzaville" },
  { key: "cd", value: "cd", flag: "cd", text: "Congo" },
  { key: "ck", value: "ck", flag: "ck", text: "Cook Islands" },
  { key: "cr", value: "cr", flag: "cr", text: "Costa Rica" },
  { key: "ci", value: "ci", flag: "ci", text: "Cote Divoire" },
  { key: "hr", value: "hr", flag: "hr", text: "Croatia" },
  { key: "cu", value: "cu", flag: "cu", text: "Cuba" },
  { key: "cy", value: "cy", flag: "cy", text: "Cyprus" },
  { key: "cz", value: "cz", flag: "cz", text: "Czech Republic" },
  { key: "dk", value: "dk", flag: "dk", text: "Denmark" },
  { key: "dj", value: "dj", flag: "dj", text: "Djibouti" },
  { key: "dm", value: "dm", flag: "dm", text: "Dominica" },
  { key: "do", value: "do", flag: "do", text: "Dominican Republic" },
  { key: "ec", value: "ec", flag: "ec", text: "Ecuador" },
  { key: "eg", value: "eg", flag: "eg", text: "Egypt" },
  { key: "sv", value: "sv", flag: "sv", text: "El Salvador" },
  { key: "gb", value: "gb", flag: "gb", text: "England" },
  { key: "gq", value: "gq", flag: "gq", text: "Equatorial Guinea" },
  { key: "er", value: "er", flag: "er", text: "Eritrea" },
  { key: "ee", value: "ee", flag: "ee", text: "Estonia" },
  { key: "et", value: "et", flag: "et", text: "Ethiopia" },
  { key: "eu", value: "eu", flag: "eu", text: "European Union" },
  { key: "fk", value: "fk", flag: "fk", text: "Falkland Islands" },
  { key: "fo", value: "fo", flag: "fo", text: "Faroe Islands" },
  { key: "fj", value: "fj", flag: "fj", text: "Fiji" },
  { key: "fi", value: "fi", flag: "fi", text: "Finland" },
  { key: "fr", value: "fr", flag: "fr", text: "France" },
  { key: "gf", value: "gf", flag: "gf", text: "French Guiana" },
  { key: "pf", value: "pf", flag: "pf", text: "French Polynesia" },
  { key: "tf", value: "tf", flag: "tf", text: "French Territories" },
  { key: "ga", value: "ga", flag: "ga", text: "Gabon" },
  { key: "gm", value: "gm", flag: "gm", text: "Gambia" },
  { key: "ge", value: "ge", flag: "ge", text: "Georgia" },
  { key: "de", value: "de", flag: "de", text: "Germany" },
  { key: "gh", value: "gh", flag: "gh", text: "Ghana" },
  { key: "gi", value: "gi", flag: "gi", text: "Gibraltar" },
  { key: "gr", value: "gr", flag: "gr", text: "Greece" },
  { key: "gl", value: "gl", flag: "gl", text: "Greenland" },
  { key: "gd", value: "gd", flag: "gd", text: "Grenada" },
  { key: "gp", value: "gp", flag: "gp", text: "Guadeloupe" },
  { key: "gu", value: "gu", flag: "gu", text: "Guam" },
  { key: "gt", value: "gt", flag: "gt", text: "Guatemala" },
  { key: "gw", value: "gw", flag: "gw", text: "Guinea-Bissau" },
  { key: "gn", value: "gn", flag: "gn", text: "Guinea" },
  { key: "gy", value: "gy", flag: "gy", text: "Guyana" },
  { key: "ht", value: "ht", flag: "ht", text: "Haiti" },
  { key: "hm", value: "hm", flag: "hm", text: "Heard Island" },
  { key: "hn", value: "hn", flag: "hn", text: "Honduras" },
  { key: "hk", value: "hk", flag: "hk", text: "Hong Kong" },
  { key: "hu", value: "hu", flag: "hu", text: "Hungary" },
  { key: "is", value: "is", flag: "is", text: "Iceland" },
  { key: "in", value: "in", flag: "in", text: "India" },
  { key: "io", value: "io", flag: "io", text: "Indian Ocean Territory" },
  { key: "id", value: "id", flag: "id", text: "Indonesia" },
  { key: "ir", value: "ir", flag: "ir", text: "Iran" },
  { key: "iq", value: "iq", flag: "iq", text: "Iraq" },
  { key: "ie", value: "ie", flag: "ie", text: "Ireland" },
  { key: "il", value: "il", flag: "il", text: "Israel" },
  { key: "it", value: "it", flag: "it", text: "Italy" },
  { key: "jm", value: "jm", flag: "jm", text: "Jamaica" },
  { key: "jp", value: "jp", flag: "jp", text: "Japan" },
  { key: "jo", value: "jo", flag: "jo", text: "Jordan" },
  { key: "kz", value: "kz", flag: "kz", text: "Kazakhstan" },
  { key: "ke", value: "ke", flag: "ke", text: "Kenya" },
  { key: "ki", value: "ki", flag: "ki", text: "Kiribati" },
  { key: "kw", value: "kw", flag: "kw", text: "Kuwait" },
  { key: "kg", value: "kg", flag: "kg", text: "Kyrgyzstan" },
  { key: "la", value: "la", flag: "la", text: "Laos" },
  { key: "lv", value: "lv", flag: "lv", text: "Latvia" },
  { key: "lb", value: "lb", flag: "lb", text: "Lebanon" },
  { key: "ls", value: "ls", flag: "ls", text: "Lesotho" },
  { key: "lr", value: "lr", flag: "lr", text: "Liberia" },
  { key: "ly", value: "ly", flag: "ly", text: "Libya" },
  { key: "li", value: "li", flag: "li", text: "Liechtenstein" },
  { key: "lt", value: "lt", flag: "lt", text: "Lithuania" },
  { key: "lu", value: "lu", flag: "lu", text: "Luxembourg" },
  { key: "mo", value: "mo", flag: "mo", text: "Macau" },
  { key: "mk", value: "mk", flag: "mk", text: "Macedonia" },
  { key: "mg", value: "mg", flag: "mg", text: "Madagascar" },
  { key: "mw", value: "mw", flag: "mw", text: "Malawi" },
  { key: "my", value: "my", flag: "my", text: "Malaysia" },
  { key: "mv", value: "mv", flag: "mv", text: "Maldives" },
  { key: "ml", value: "ml", flag: "ml", text: "Mali" },
  { key: "mt", value: "mt", flag: "mt", text: "Malta" },
  { key: "mh", value: "mh", flag: "mh", text: "Marshall Islands" },
  { key: "mq", value: "mq", flag: "mq", text: "Martinique" },
  { key: "mr", value: "mr", flag: "mr", text: "Mauritania" },
  { key: "mu", value: "mu", flag: "mu", text: "Mauritius" },
  { key: "yt", value: "yt", flag: "yt", text: "Mayotte" },
  { key: "mx", value: "mx", flag: "mx", text: "Mexico" },
  { key: "fm", value: "fm", flag: "fm", text: "Micronesia" },
  { key: "md", value: "md", flag: "md", text: "Moldova" },
  { key: "mc", value: "mc", flag: "mc", text: "Monaco" },
  { key: "mn", value: "mn", flag: "mn", text: "Mongolia" },
  { key: "me", value: "me", flag: "me", text: "Montenegro" },
  { key: "ms", value: "ms", flag: "ms", text: "Montserrat" },
  { key: "ma", value: "ma", flag: "ma", text: "Morocco" },
  { key: "mz", value: "mz", flag: "mz", text: "Mozambique" },
  { key: "na", value: "na", flag: "na", text: "Namibia" },
  { key: "nr", value: "nr", flag: "nr", text: "Nauru" },
  { key: "np", value: "np", flag: "np", text: "Nepal" },
  { key: "an", value: "an", flag: "an", text: "Netherlands Antilles" },
  { key: "nl", value: "nl", flag: "nl", text: "Netherlands" },
  { key: "nc", value: "nc", flag: "nc", text: "New Caledonia" },
  { key: "pg", value: "pg", flag: "pg", text: "New Guinea" },
  { key: "nz", value: "nz", flag: "nz", text: "New Zealand" },
  { key: "ni", value: "ni", flag: "ni", text: "Nicaragua" },
  { key: "ne", value: "ne", flag: "ne", text: "Niger" },
  { key: "ng", value: "ng", flag: "ng", text: "Nigeria" },
  { key: "nu", value: "nu", flag: "nu", text: "Niue" },
  { key: "nf", value: "nf", flag: "nf", text: "Norfolk Island" },
  { key: "kp", value: "kp", flag: "kp", text: "North Korea" },
  { key: "mp", value: "mp", flag: "mp", text: "Northern Mariana Islands" },
  { key: "no", value: "no", flag: "no", text: "Norway" },
  { key: "om", value: "om", flag: "om", text: "Oman" },
  { key: "pk", value: "pk", flag: "pk", text: "Pakistan" },
  { key: "pw", value: "pw", flag: "pw", text: "Palau" },
  { key: "ps", value: "ps", flag: "ps", text: "Palestine" },
  { key: "pa", value: "pa", flag: "pa", text: "Panama" },
  { key: "py", value: "py", flag: "py", text: "Paraguay" },
  { key: "pe", value: "pe", flag: "pe", text: "Peru" },
  { key: "ph", value: "ph", flag: "ph", text: "Philippines" },
  { key: "pn", value: "pn", flag: "pn", text: "Pitcairn Islands" },
  { key: "pl", value: "pl", flag: "pl", text: "Poland" },
  { key: "pt", value: "pt", flag: "pt", text: "Portugal" },
  { key: "pr", value: "pr", flag: "pr", text: "Puerto Rico" },
  { key: "qa", value: "qa", flag: "qa", text: "Qatar" },
  { key: "re", value: "re", flag: "re", text: "Reunion" },
  { key: "ro", value: "ro", flag: "ro", text: "Romania" },
  { key: "ru", value: "ru", flag: "ru", text: "Russia" },
  { key: "rw", value: "rw", flag: "rw", text: "Rwanda" },
  { key: "sh", value: "sh", flag: "sh", text: "Saint Helena" },
  { key: "kn", value: "kn", flag: "kn", text: "Saint Kitts and Nevis" },
  { key: "lc", value: "lc", flag: "lc", text: "Saint Lucia" },
  { key: "pm", value: "pm", flag: "pm", text: "Saint Pierre" },
  { key: "vc", value: "vc", flag: "vc", text: "Saint Vincent" },
  { key: "ws", value: "ws", flag: "ws", text: "Samoa" },
  { key: "sm", value: "sm", flag: "sm", text: "San Marino" },
  { key: "gs", value: "gs", flag: "gs", text: "Sandwich Islands" },
  { key: "st", value: "st", flag: "st", text: "Sao Tome" },
  { key: "sa", value: "sa", flag: "sa", text: "Saudi Arabia" },
  { key: "sn", value: "sn", flag: "sn", text: "Senegal" },
  { key: "cs", value: "cs", flag: "cs", text: "Serbia" },
  { key: "rs", value: "rs", flag: "rs", text: "Serbia" },
  { key: "sc", value: "sc", flag: "sc", text: "Seychelles" },
  { key: "sl", value: "sl", flag: "sl", text: "Sierra Leone" },
  { key: "sg", value: "sg", flag: "sg", text: "Singapore" },
  { key: "sk", value: "sk", flag: "sk", text: "Slovakia" },
  { key: "si", value: "si", flag: "si", text: "Slovenia" },
  { key: "sb", value: "sb", flag: "sb", text: "Solomon Islands" },
  { key: "so", value: "so", flag: "so", text: "Somalia" },
  { key: "za", value: "za", flag: "za", text: "South Africa" },
  { key: "kr", value: "kr", flag: "kr", text: "South Korea" },
  { key: "es", value: "es", flag: "es", text: "Spain" },
  { key: "lk", value: "lk", flag: "lk", text: "Sri Lanka" },
  { key: "sd", value: "sd", flag: "sd", text: "Sudan" },
  { key: "sr", value: "sr", flag: "sr", text: "Suriname" },
  { key: "sj", value: "sj", flag: "sj", text: "Svalbard" },
  { key: "sz", value: "sz", flag: "sz", text: "Swaziland" },
  { key: "se", value: "se", flag: "se", text: "Sweden" },
  { key: "ch", value: "ch", flag: "ch", text: "Switzerland" },
  { key: "sy", value: "sy", flag: "sy", text: "Syria" },
  { key: "tw", value: "tw", flag: "tw", text: "Taiwan" },
  { key: "tj", value: "tj", flag: "tj", text: "Tajikistan" },
  { key: "tz", value: "tz", flag: "tz", text: "Tanzania" },
  { key: "th", value: "th", flag: "th", text: "Thailand" },
  { key: "tl", value: "tl", flag: "tl", text: "Timorleste" },
  { key: "tg", value: "tg", flag: "tg", text: "Togo" },
  { key: "tk", value: "tk", flag: "tk", text: "Tokelau" },
  { key: "to", value: "to", flag: "to", text: "Tonga" },
  { key: "tt", value: "tt", flag: "tt", text: "Trinidad" },
  { key: "tn", value: "tn", flag: "tn", text: "Tunisia" },
  { key: "tr", value: "tr", flag: "tr", text: "Turkey" },
  { key: "tm", value: "tm", flag: "tm", text: "Turkmenistan" },
  { key: "tv", value: "tv", flag: "tv", text: "Tuvalu" },
  { key: "ug", value: "ug", flag: "ug", text: "Uganda" },
  { key: "ua", value: "ua", flag: "ua", text: "Ukraine" },
  { key: "ae", value: "ae", flag: "ae", text: "United Arab Emirates" },
  { key: "us", value: "us", flag: "us", text: "United States", alias: "United States of America" },
  { key: "uy", value: "uy", flag: "uy", text: "Uruguay" },
  { key: "um", value: "um", flag: "um", text: "Us Minor Islands" },
  { key: "vi", value: "vi", flag: "vi", text: "Us Virgin Islands" },
  { key: "uz", value: "uz", flag: "uz", text: "Uzbekistan" },
  { key: "vu", value: "vu", flag: "vu", text: "Vanuatu" },
  { key: "va", value: "va", flag: "va", text: "Vatican City" },
  { key: "ve", value: "ve", flag: "ve", text: "Venezuela" },
  { key: "vn", value: "vn", flag: "vn", text: "Vietnam" },
  { key: "wf", value: "wf", flag: "wf", text: "Wallis and Futuna" },
  { key: "eh", value: "eh", flag: "eh", text: "Western Sahara" },
  { key: "ye", value: "ye", flag: "ye", text: "Yemen" },
  { key: "zm", value: "zm", flag: "zm", text: "Zambia" },
  { key: "zw", value: "zw", flag: "zw", text: "Zimbabwe" },
];

export const KeyCodes = {
  BACKSPACE: ["Backspace", 8],
  TAB: ["Tab", 9],
  ENTER: ["Enter", 13],
  SHIFT: ["Shift", 16],
  CTRL: ["Control", 17],
  ALT: ["Alt", 18],
  CAPS_LOCK: ["CapsLock", 20],
  ESCAPE: ["Escape", 27],
  SPACE: [" ", 32],
  PAGE_UP: ["PageUp", 33],
  PAGE_DOWN: ["PageDown", 34],
  END: ["End", 35],
  HOME: ["Home", 36],
  LEFT: ["ArrowLeft", 37],
  UP: ["ArrowUp", 38],
  RIGHT: ["ArrowRight", 39],
  DOWN: ["ArrowDown", 40],
  INSERT: ["Insert", 45],
  DELETE: ["Delete", 46],
  ZERO: ["0", 48],
  ONE: ["1", 49],
  TWO: ["2", 50],
  THREE: ["3", 51],
  FOUR: ["4", 52],
  FIVE: ["5", 53],
  SIX: ["6", 54],
  SEVEN: ["7", 55],
  EIGHT: ["8", 56],
  NINE: ["9", 57],
  A: ["a", 65],
  B: ["b", 66],
  C: ["c", 67],
  D: ["d", 68],
  E: ["e", 69],
  F: ["f", 70],
  G: ["g", 71],
  H: ["h", 72],
  I: ["i", 73],
  J: ["j", 74],
  K: ["k", 75],
  L: ["l", 76],
  M: ["m", 77],
  N: ["n", 78],
  O: ["o", 79],
  P: ["p", 80],
  Q: ["q", 81],
  R: ["r", 82],
  S: ["s", 83],
  T: ["t", 84],
  U: ["u", 85],
  V: ["v", 86],
  W: ["w", 87],
  X: ["x", 88],
  Y: ["y", 89],
  Z: ["z", 90],
  CMD: ["Meta", 91],
  MENU: ["ContextMenu", 93],
  NUMPAD_0: ["0", 96],
  NUMPAD_1: ["1", 97],
  NUMPAD_2: ["2", 98],
  NUMPAD_3: ["3", 99],
  NUMPAD_4: ["4", 100],
  NUMPAD_5: ["5", 101],
  NUMPAD_6: ["6", 102],
  NUMPAD_7: ["7", 103],
  NUMPAD_8: ["8", 104],
  NUMPAD_9: ["9", 105],
  MULTIPLY: ["*", 106],
  ADD: ["+", 107],
  SUBTRACT: ["-", 109],
  DECIMAL: [".", 110],
  DIVIDE: ["/", 111],
  F1: ["F1", 112],
  F2: ["F2", 113],
  F3: ["F3", 114],
  F4: ["F4", 115],
  F5: ["F5", 116],
  F6: ["F6", 117],
  F7: ["F7", 118],
  F8: ["F8", 119],
  F9: ["F9", 120],
  F10: ["F10", 121],
  F11: ["F11", 122],
  F12: ["F12", 123],
  NUM_LOCK: ["NumLock", 144],
  SEMICOLON: [";", 186],
  EQUAL: ["=", 187],
  COMMA: [",", 188],
  DASH: ["-", 189],
  PERIOD: [".", 190],
  FORWARD_SLASH: ["/", 191],
  TILDE: ["~", 192],
  OPEN_BRACKET: ["[", 219],
  BACK_SLASH: ["\\", 220],
  CLOSE_BRACKET: ["]", 221],
};

export const monthOptions = [
  { key: 0, value: null, text: "Month" },
  { key: 1, value: 1, text: "January" },
  { key: 2, value: 2, text: "February" },
  { key: 3, value: 3, text: "March" },
  { key: 4, value: 4, text: "April" },
  { key: 5, value: 5, text: "May" },
  { key: 6, value: 6, text: "June" },
  { key: 7, value: 7, text: "July" },
  { key: 8, value: 8, text: "August" },
  { key: 9, value: 9, text: "September" },
  { key: 10, value: 10, text: "October" },
  { key: 11, value: 11, text: "November" },
  { key: 12, value: 12, text: "December" },
];

export const genderOptions = [
  { key: "", value: "", text: "(Undisclosed)" },
  { key: "M", value: "M", icon: "male", text: "Male" },
  { key: "F", value: "F", icon: "female", text: "Female" },
];

export const privacyOptions = [
  { key: "private", value: true, icon: "lock", text: "Private" },
  { key: "public", value: false, icon: "unlock", text: "Public" },
];

export const industryOptions = [
  { key: 0, value: null, text: "Industry" },
  {
    key: "Information Technology and Services",
    value: "Information Technology and Services",
    text: "Information Technology and Services",
  },
  {
    key: "Hospital & Health Care",
    value: "Hospital & Health Care",
    text: "Hospital & Health Care",
  },
  { key: "Construction", value: "Construction", text: "Construction" },
  { key: "Education Management", value: "Education Management", text: "Education Management" },
  { key: "Retail", value: "Retail", text: "Retail" },
  { key: "Financial Services", value: "Financial Services", text: "Financial Services" },
  { key: "Accounting", value: "Accounting", text: "Accounting" },
  { key: "Computer Software", value: "Computer Software", text: "Computer Software" },
  { key: "Automotive", value: "Automotive", text: "Automotive" },
  { key: "Higher Education", value: "Higher Education", text: "Higher Education" },
  {
    key: "Marketing & Advertising",
    value: "Marketing & Advertising",
    text: "Marketing & Advertising",
  },
  {
    key: "Government Administration",
    value: "Government Administration",
    text: "Government Administration",
  },
  { key: "Banking", value: "Banking", text: "Banking" },
  {
    key: "Health, Welness & Fitness",
    value: "Health, Welness & Fitness",
    text: "Health, Welness & Fitness",
  },
  { key: "Real Estate", value: "Real Estate", text: "Real Estate" },
  { key: "Telecommunications", value: "Telecommunications", text: "Telecommunications" },
  { key: "Oil & Energy", value: "Oil & Energy", text: "Oil & Energy" },
  { key: "Food & Beverages", value: "Food & Beverages", text: "Food & Beverages" },
  { key: "Hospitality", value: "Hospitality", text: "Hospitality" },
  {
    key: "Mechanical or Industrial Engineering",
    value: "Mechanical or Industrial Engineering",
    text: "Mechanical or Industrial Engineering",
  },
  {
    key: "Electrical & Electronic Manufacturing",
    value: "Electrical & Electronic Manufacturing",
    text: "Electrical & Electronic Manufacturing",
  },
  {
    key: "Primary/Secondary Education",
    value: "Primary/Secondary Education",
    text: "Primary/Secondary Education",
  },
  { key: "Insurance", value: "Insurance", text: "Insurance" },
  { key: "Internet", value: "Internet", text: "Internet" },
  { key: "Human Resources", value: "Human Resources", text: "Human Resources" },
  { key: "Medical Practice", value: "Medical Practice", text: "Medical Practice" },
  {
    key: "Transportation/Trucking/Railroad",
    value: "Transportation/Trucking/Railroad",
    text: "Transportation/Trucking/Railroad",
  },
  { key: "Consumer Services", value: "Consumer Services", text: "Consumer Services" },
  { key: "Management Consulting", value: "Management Consulting", text: "Management Consulting" },
  { key: "Pharmaceuticals", value: "Pharmaceuticals", text: "Pharmaceuticals" },
  { key: "Civil Engineering", value: "Civil Engineering", text: "Civil Engineering" },
  { key: "Design", value: "Design", text: "Design" },
  { key: "Research", value: "Research", text: "Research" },
  { key: "Restaurants", value: "Restaurants", text: "Restaurants" },
  {
    key: "Logistics & Supply Chain",
    value: "Logistics & Supply Chain",
    text: "Logistics & Supply Chain",
  },
  {
    key: "Architecture & Planning",
    value: "Architecture & Planning",
    text: "Architecture & Planning",
  },
  { key: "Law Practice", value: "Law Practice", text: "Law Practice" },
  { key: "Apparel & Fashion", value: "Apparel & Fashion", text: "Apparel & Fashion" },
  { key: "Consumer Goods", value: "Consumer Goods", text: "Consumer Goods" },
  { key: "Facilities Services", value: "Facilities Services", text: "Facilities Services" },
  { key: "Food Production", value: "Food Production", text: "Food Production" },
  {
    key: "Non-profit Organization Management",
    value: "Non-profit Organization Management",
    text: "Non-profit Organization Management",
  },
  { key: "Entertainment", value: "Entertainment", text: "Entertainment" },
  { key: "Machinery", value: "Machinery", text: "Machinery" },
  { key: "Chemicals", value: "Chemicals", text: "Chemicals" },
  { key: "Arts & Crafts", value: "Arts & Crafts", text: "Arts & Crafts" },
  { key: "Wholesale", value: "Wholesale", text: "Wholesale" },
  { key: "Utilities", value: "Utilities", text: "Utilities" },
  { key: "Legal Services", value: "Legal Services", text: "Legal Services" },
  { key: "Farming", value: "Farming", text: "Farming" },
  { key: "Mining & Metals", value: "Mining & Metals", text: "Mining & Metals" },
  { key: "Airlines/Aviation", value: "Airlines/Aviation", text: "Airlines/Aviation" },
  {
    key: "Leisure, Travel & Turism",
    value: "Leisure, Travel & Turism",
    text: "Leisure, Travel & Turism",
  },
  { key: "Sporting Goods", value: "Sporting Goods", text: "Sporting Goods" },
  { key: "Building Materials", value: "Building Materials", text: "Building Materials" },
  { key: "Music", value: "Music", text: "Music" },
  { key: "Enviromental Services", value: "Enviromental Services", text: "Enviromental Services" },
  {
    key: "Professional Training & Coaching",
    value: "Professional Training & Coaching",
    text: "Professional Training & Coaching",
  },
  { key: "Medical Device", value: "Medical Device", text: "Medical Device" },
  {
    key: "Individual & Family Services",
    value: "Individual & Family Services",
    text: "Individual & Family Services",
  },
  { key: "Cosmetics", value: "Cosmetics", text: "Cosmetics" },
  { key: "Mental Health Care", value: "Mental Health Care", text: "Mental Health Care" },
  {
    key: "Aviation and Aerospace",
    value: "Aviation and Aerospace",
    text: "Aviation and Aerospace",
  },
  { key: "Staffing & Recruiting", value: "Staffing & Recruiting", text: "Staffing & Recruiting" },
  { key: "Industrial Automation", value: "Industrial Automation", text: "Industrial Automation" },
  { key: "Graphic Design", value: "Graphic Design", text: "Graphic Design" },
  {
    key: "Security & Investigations",
    value: "Security & Investigations",
    text: "Security & Investigations",
  },
  { key: "Import and Export", value: "Import and Export", text: "Import and Export" },
  {
    key: "Public Relations and Communications",
    value: "Public Relations and Communications",
    text: "Public Relations and Communications",
  },
  { key: "Textiles", value: "Textiles", text: "Textiles" },
  { key: "Military", value: "Military", text: "Military" },
  { key: "Broadcast Media", value: "Broadcast Media", text: "Broadcast Media" },
  { key: "Biotechnology", value: "Biotechnology", text: "Biotechnology" },
  { key: "Media Production", value: "Media Production", text: "Media Production" },
  {
    key: "Business Supplies & Equipment",
    value: "Business Supplies & Equipment",
    text: "Business Supplies & Equipment",
  },
  { key: "Computer Networking", value: "Computer Networking", text: "Computer Networking" },
  { key: "Writing & Editing", value: "Writing & Editing", text: "Writing & Editing" },
  { key: "Consumer Elecronics", value: "Consumer Elecronics", text: "Consumer Elecronics" },
  {
    key: "International Trade and Development",
    value: "International Trade and Development",
    text: "International Trade and Development",
  },
  { key: "Events Services", value: "Events Services", text: "Events Services" },
  { key: "Photography", value: "Photography", text: "Photography" },
  {
    key: "Renewables & Envirnoment",
    value: "Renewables & Envirnoment",
    text: "Renewables & Envirnoment",
  },
  { key: "Computer Hardware", value: "Computer Hardware", text: "Computer Hardware" },
  {
    key: "Civic and Social Organization",
    value: "Civic and Social Organization",
    text: "Civic and Social Organization",
  },
  { key: "Furniture", value: "Furniture", text: "Furniture" },
  { key: "Defense & Space", value: "Defense & Space", text: "Defense & Space" },
  {
    key: "Computer & Network Security",
    value: "Computer & Network Security",
    text: "Computer & Network Security",
  },
  { key: "Printing", value: "Printing", text: "Printing" },
  { key: "Fine Art", value: "Fine Art", text: "Fine Art" },
  {
    key: "Religious Institutions",
    value: "Religious Institutions",
    text: "Religious Institutions",
  },
  { key: "Investment Management", value: "Investment Management", text: "Investment Management" },
  { key: "Law Enforcement", value: "Law Enforcement", text: "Law Enforcement" },
  { key: "Publishing", value: "Publishing", text: "Publishing" },
  { key: "Information Services", value: "Information Services", text: "Information Services" },
  { key: "Maritime", value: "Maritime", text: "Maritime" },
  {
    key: "Outsourcing/Offshoring",
    value: "Outsourcing/Offshoring",
    text: "Outsourcing/Offshoring",
  },
  { key: "Warehousing", value: "Warehousing", text: "Warehousing" },
  { key: "E-learning", value: "E-learning", text: "E-learning" },
  { key: "Executive Office", value: "Executive Office", text: "Executive Office" },
  { key: "Government Relations", value: "Government Relations", text: "Government Relations" },
  { key: "Animation", value: "Animation", text: "Animation" },
  { key: "Semiconducs", value: "Semiconducs", text: "Semiconducs" },
  { key: "Supermarkets", value: "Supermarkets", text: "Supermarkets" },
  { key: "Program Development", value: "Program Development", text: "Program Development" },
  { key: "Public Safety", value: "Public Safety", text: "Public Safety" },
  { key: "Plastics", value: "Plastics", text: "Plastics" },
  { key: "Alternative Medicine", value: "Alternative Medicine", text: "Alternative Medicine" },
  { key: "Performing Arts", value: "Performing Arts", text: "Performing Arts" },
  { key: "Online Media", value: "Online Media", text: "Online Media" },
  {
    key: "Motion Pictures & Film",
    value: "Motion Pictures & Film",
    text: "Motion Pictures & Film",
  },
  {
    key: "Commercial Real Estate",
    value: "Commercial Real Estate",
    text: "Commercial Real Estate",
  },
  { key: "Judiciary", value: "Judiciary", text: "Judiciary" },
  {
    key: "Packaging and Containers",
    value: "Packaging and Containers",
    text: "Packaging and Containers",
  },
  {
    key: "Luxury Goods & Jewelry",
    value: "Luxury Goods & Jewelry",
    text: "Luxury Goods & Jewelry",
  },
  { key: "Veterinary", value: "Veterinary", text: "Veterinary" },
  { key: "Computer Games", value: "Computer Games", text: "Computer Games" },
  { key: "Investment Banking", value: "Investment Banking", text: "Investment Banking" },
  { key: "Market Research", value: "Market Research", text: "Market Research" },
  { key: "International Affairs", value: "International Affairs", text: "International Affairs" },
  { key: "Wine & Spirits", value: "Wine & Spirits", text: "Wine & Spirits" },
  { key: "Newspapers", value: "Newspapers", text: "Newspapers" },
  {
    key: "Translation & Localisation",
    value: "Translation & Localisation",
    text: "Translation & Localisation",
  },
  {
    key: "Recreational Facilities & Services",
    value: "Recreational Facilities & Services",
    text: "Recreational Facilities & Services",
  },
  {
    key: "Paper & Forest Products",
    value: "Paper & Forest Products",
    text: "Paper & Forest Products",
  },
  { key: "Capital Markets", value: "Capital Markets", text: "Capital Markets" },
  { key: "Public Policy", value: "Public Policy", text: "Public Policy" },
  {
    key: "Package/Freight Delivery",
    value: "Package/Freight Delivery",
    text: "Package/Freight Delivery",
  },
  { key: "Libraries", value: "Libraries", text: "Libraries" },
  { key: "Wireless", value: "Wireless", text: "Wireless" },
  { key: "Gambling & Casinos", value: "Gambling & Casinos", text: "Gambling & Casinos" },
  {
    key: "Venture Capital & Private Equity",
    value: "Venture Capital & Private Equity",
    text: "Venture Capital & Private Equity",
  },
  {
    key: "Glass, Ceramics & Concrete",
    value: "Glass, Ceramics & Concrete",
    text: "Glass, Ceramics & Concrete",
  },
  { key: "Philanthropy", value: "Philanthropy", text: "Philanthropy" },
  { key: "Ranching", value: "Ranching", text: "Ranching" },
  { key: "Dairy", value: "Dairy", text: "Dairy" },
  {
    key: "Museums and Institutions",
    value: "Museums and Institutions",
    text: "Museums and Institutions",
  },
  { key: "Shipbuilding", value: "Shipbuilding", text: "Shipbuilding" },
  { key: "Think Thanks", value: "Think Thanks", text: "Think Thanks" },
  {
    key: "Political Organization",
    value: "Political Organization",
    text: "Political Organization",
  },
  { key: "Fishery", value: "Fishery", text: "Fishery" },
  { key: "Fund-Raising", value: "Fund-Raising", text: "Fund-Raising" },
  { key: "Tobacco", value: "Tobacco", text: "Tobacco" },
  { key: "Railroad Manufacture", value: "Railroad Manufacture", text: "Railroad Manufacture" },
  {
    key: "Alternative Dispute Resolution",
    value: "Alternative Dispute Resolution",
    text: "Alternative Dispute Resolution",
  },
  { key: "Nanotechnology", value: "Nanotechnology", text: "Nanotechnology" },
  { key: "Legislative Office", value: "Legislative Office", text: "Legislative Office" },
  { key: "", value: "", text: "" },
];

export const degreeOptions = [
  { key: 0, value: null, text: "Degree" },
  { key: 1, value: "Bachelor's", text: "Bachelor's" },
  { key: 2, value: "Master's", text: "Master's" },
  { key: 3, value: "Doctoral", text: "Doctoral" },
  { key: 4, value: "Professional", text: "Professional" },
  { key: 5, value: "Associate's", text: "Associate's" },
  { key: 6, value: "Other", text: "Other" },
];

export const status = {
  pending: "pending",
  accepted: "accepted",
  declined: "declined",
};

export const sentinelSlug = "sentinel";

export const bldAiDomain = "bld.ai";
