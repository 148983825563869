import { action } from "./index";

const createModalVisibilityOptions = (label) => ({
  SHOW: `${label}_SHOW`,
  HIDE: `${label}_HIDE`,
  TOGGLE: `${label}_TOGGLE`,
});

const createModalVisibilityActions = (options) => ({
  show: () => action(options.SHOW),
  hide: () => action(options.HIDE),
  toggle: () => action(options.TOGGLE),
});

export const NEW_MESSAGE_MODAL = createModalVisibilityOptions("NEW_MESSAGE_MODAL");
export const NEW_PROJECT_MODAL = createModalVisibilityOptions("NEW_PROJECT_MODAL");
export const NEW_TEAM_MODAL = createModalVisibilityOptions("NEW_TEAM_MODAL");
export const SHORTCUTS_HELP_MODAL = createModalVisibilityOptions("SHORTCUTS_HELP_MODAL");
export const DOWNLOAD_LINK_MODAL = createModalVisibilityOptions("DOWNLOAD_LINK_MODAL");

export const newMessageModal = createModalVisibilityActions(NEW_MESSAGE_MODAL);
export const newProjectModal = createModalVisibilityActions(NEW_PROJECT_MODAL);
export const shortcutsHelpModal = createModalVisibilityActions(SHORTCUTS_HELP_MODAL);
export const newTeamModal = createModalVisibilityActions(NEW_TEAM_MODAL);
export const downloadLinkModal = createModalVisibilityActions(DOWNLOAD_LINK_MODAL);
