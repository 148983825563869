import classnames from "classnames";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { Container, Header, Button, Checkbox, Icon } from "semantic-ui-react";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";

import InlinePopup from "components/InlinePopup";
import config from "config";
import { serialize } from "utils";
import {
  notification as notificationAction,
  paginatedNotification,
  markReadNotification,
  submitMarkRead,
} from "actions/notification";

import styles from "./styles.module.scss";
import { MESSAGES } from "./l10n";
import NotificationList from "./NotificationList";

const LINK_FOR_NOTIF = {
  "requested feedback from": (obj) => {
    if (obj.actor) return `/feedback/give/feedback/${obj.actor.slug}`;
    return "";
  },
  "created a poll": (obj) => {
    if (obj.actionObject) return `/chat/r/${obj.actionObject.room}/polls/${obj.actionObject.id}`;
    return "";
  },
  "invited you in a project": (obj) => {
    if (obj.actionObject) return `/chat/r/${obj.actionObject.id}`;
    return "";
  },
  "invited you to form a team": (obj) => {
    if (obj.actionObject) return `/chat/r/${obj.actionObject.roomId}`;
    return "";
  },
  "invited you to a meeting": (obj) => {
    if (obj.actionObject && obj.target) {
      return `/chat/r/${obj.actionObject.id}/meetings/${obj.target.id}`;
    }
    return "";
  },
  // 'level up congratulations': (obj) => `/talents/${obj.target.slug}/skills`
};

const DISPLAY_FOR_LINK = {
  "requested feedback from": "Give feedback",
  "created a poll": "Submit vote",
  "invited you in a project": `Go to ${config.flags.profile.teams ? "project" : "team"}`,
  "level up congratulations": "View profile",
  "invited you to form a team": "Go to team",
  "invited you to a meeting": "Go to meeting",
};

const FILTER_OPTIONS = [];
FILTER_OPTIONS.push({
  key: "level up congratulations",
  label: "Changes in skill mastery",
});
if (config.flags.feedback) {
  FILTER_OPTIONS.push({
    key: "requested feedback from",
    label: "Feedback requests",
  });
}
if (config.flags.poll) {
  FILTER_OPTIONS.push({
    key: "created a poll",
    label: "Poll",
  });
}
FILTER_OPTIONS.push({
  key: "invited you in a project",
  label: `${config.flags.profile.teams ? "Project" : "Team"} invitation`,
});
if (config.flags.profile.teams) {
  FILTER_OPTIONS.push({
    key: "invited you to form a team",
    label: "Team invitation",
  });
}
if (config.flags.meeting) {
  FILTER_OPTIONS.push({
    key: "invited you to a meeting",
    label: "Meeting invitation",
  });
}

const getLinkForNotif = (obj) => {
  const linkFunc = LINK_FOR_NOTIF[obj.verb];
  if (linkFunc) {
    return linkFunc(obj);
  }
  return "";
};

const getDisplayLink = (obj) => DISPLAY_FOR_LINK[obj.verb] || "";

class Notification extends Component {
  constructor(props) {
    super(props);

    const filters = { all: true };
    FILTER_OPTIONS.forEach((option) => {
      filters[option.key] = true;
    });
    this.state = {
      filters,
      currentPage: 0,
    };
  }

  componentDidMount() {
    const qs = `?${serialize({ verb__inlist: this.generateFilterQuery() })}`;
    this.props.loadNotification(qs);
  }

  componentWillReceiveProps(newProps) {
    const { loadedPages } = newProps;
    let currentPage = 0;
    if (newProps.hasNextPage !== undefined) {
      currentPage = newProps.nextPageNumber - 1;
    } else if (loadedPages.length > 0) {
      currentPage = loadedPages[loadedPages.length - 1];
    }

    this.setState({
      currentPage,
    });
  }

  componentWillUnmount() {
    if (!this.props.forDropdown) {
      this.props.postMarkReadNotification();
    }
  }

  handleAll = () => {
    if (this.state.filters.all) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          all: false,
        },
      }));
    } else {
      const filters = { all: true };
      FILTER_OPTIONS.forEach((option) => {
        filters[option.key] = true;
      });

      this.setState(
        () => ({
          filters,
        }),
        () => {
          const qs = `?${serialize({ verb__inlist: this.generateFilterQuery() })}`;
          this.props.loadNotification(qs);
        }
      );
    }
  };

  handleItemClick = (obj) => {
    const { id, recipient, itemLink } = obj;
    if (itemLink) {
      if (obj.unread) {
        this.props.postSubmitMarkRead(id, recipient);
      }
      this.props.history.push(itemLink);
    }
  };

  generateFilterQuery = () => {
    // based on state.filters, generate the filter query
    const activeFilters = [];
    Object.keys(this.state.filters).forEach((key) => {
      if (this.state.filters[key]) {
        activeFilters.push(key);
      }
    });

    if (activeFilters.length > 0) {
      return activeFilters.toString();
    }
    return "null";
  };

  handleNext = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const nextPageState = this.state.currentPage + 1;
    // check if the next page has already been loaded
    // if yes, simply set the currentPage to the next page
    // otherwise, load the necessary page

    if (this.props.loadedPages.indexOf(nextPageState) > -1) {
      this.setState({
        currentPage: nextPageState,
      });
    } else {
      const { nextPageNumber } = this.props;
      let qs = "";
      if (nextPageNumber) {
        qs = `?${serialize({
          page: nextPageNumber,
          verb__inlist: this.generateFilterQuery(),
        })}`;
      }
      this.props.loadPaginatedNotification(qs);
    }
  };

  handlePrevious = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  handleMarkRead = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.postMarkReadNotification();
  };

  handleFilter = (e, filterValue) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          all: false,
          [filterValue]: !prevState.filters[filterValue],
        },
      }),
      () => {
        const qs = `?${serialize({ verb__inlist: this.generateFilterQuery() })}`;
        this.props.loadNotification(qs);
      }
    );
  };

  createFilters = () =>
    FILTER_OPTIONS.map((obj) => (
      <Checkbox
        key={obj.key}
        label={obj.label}
        className={classnames("inverted", styles.filterOption)}
        onChange={(e) => this.handleFilter(e, obj.key)}
        checked={this.state.filters[obj.key]}
      />
    ));

  render() {
    const { formatMessage } = this.props.intl;
    const _f = formatMessage;
    const msgs = defineMessages(MESSAGES);
    const { hasNextPage } = this.props;
    const hasPrevious = this.state.currentPage > 1;
    const hasNext = hasNextPage || this.state.currentPage < this.props.loadedPages.length;

    const notifications = this.props.notifications.filter(
      (notif) => notif.page === this.state.currentPage
    );

    return (
      <div className={styles.container}>
        <div className={styles.heroBanner}>
          <Container>
            <Header as="h1" content={_f(msgs.header)} />
          </Container>
        </div>
        <div className={styles.notifContainer}>
          <InlinePopup
            basic
            on="click"
            position="bottom left"
            trigger={
              <button className={styles.filterTrigger} type="button">
                {_f(msgs.filterHeader)}
                {this.state.filters.all ? " All" : ""}
                <Icon name="caret down" />
              </button>
            }
            style={{ left: "auto", top: "auto" }}
          >
            <InlinePopup.Content>
              <Checkbox
                key="all"
                label="All"
                className={classnames("inverted", styles.filterOption)}
                onChange={this.handleAll}
                checked={this.state.filters.all}
              />
              {this.createFilters()}
            </InlinePopup.Content>
          </InlinePopup>
          <div className={styles.markRead}>
            <button className={styles.markReadButton} onClick={this.handleMarkRead} type="button">
              {formatMessage(msgs.markRead)}
            </button>
          </div>
          <NotificationList
            notifications={notifications}
            handleItemClick={this.handleItemClick}
            userDisplayName={this.props.userDisplayName}
            loading={this.props.loading}
          />
          <div className={styles.pagination}>
            {hasPrevious && (
              <Button
                circular
                icon="chevron left"
                onClick={this.handlePrevious}
                className={styles.pageButton}
              />
            )}
            {hasNext && (
              <Button
                circular
                icon="chevron right"
                onClick={this.handleNext}
                className={styles.pageButton}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Notification.propTypes = {
  intl: PropTypes.shape().isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape()),
  userDisplayName: PropTypes.string.isRequired,
  loadNotification: PropTypes.func.isRequired,
  loadPaginatedNotification: PropTypes.func.isRequired,
  postMarkReadNotification: PropTypes.func.isRequired,
  postSubmitMarkRead: PropTypes.func.isRequired,
  hasNextPage: PropTypes.string,
  nextPageNumber: PropTypes.number,
  forDropdown: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  loadedPages: PropTypes.arrayOf(PropTypes.number),
};

Notification.defaultProps = {
  forDropdown: false,
};

function mapStateToProps(state) {
  const { notification } = state.entities;
  const { user } = state.auth;
  const { loading, nextPageNumber, count: notifCount } = state.notification;
  let { nextPage } = state.notification;

  const notifications = Object.keys(notification)
    .map((key) => ({
      ...notification[key],
      timestamp: moment(notification[key].timestamp),
      itemLink: getLinkForNotif(notification[key]),
      displayLink: getDisplayLink(notification[key]),
    }))
    .sort((a, b) => b.timestamp - a.timestamp);
  const unread = notifications.filter((obj) => obj.unread === true);
  const read = notifications.filter((obj) => obj.unread === false);
  let userDisplayName = "";
  if (user) {
    userDisplayName = user.displayName;
  }
  if (notifications.length === notifCount) {
    nextPage = undefined;
  }

  const pages = new Set(notifications.map((obj) => obj.page));
  const loadedPages = [...pages];

  return {
    notifications,
    unread,
    read,
    userDisplayName,
    hasNextPage: nextPage,
    nextPageNumber,
    loading,
    loadedPages,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadNotification: notificationAction.init,
    loadPaginatedNotification: paginatedNotification.init,
    postMarkReadNotification: markReadNotification.init,
    postSubmitMarkRead: submitMarkRead,
  })(injectIntl(Notification))
);
