import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import localForage from "localforage";
import { extendPrototype } from "localforage-observable";
import "moment-timezone";
import Observable from "zen-observable";

import "./semantic/dist/semantic.min.css";
import "emoji-mart/css/emoji-mart.css";
import { resetErrorMessage } from "./actions";
import * as account from "./actions/account";
import Root from "./containers/Root";
import rootSaga from "./sagas";
import { history, token } from "./services";
import configureStore from "./store/configureStore";
import Hubspot from "./utils/hubspot";

window.Observable = Observable;

export const { store, persistor } = configureStore({}); // eslint-disable-line
store.runSaga(rootSaga);

// Listen for changes to the current location.
history.listen((location) => {
  Hubspot.push(["setPath", location.pathname]);
  Hubspot.push(["trackPageView"]);
  store.dispatch(resetErrorMessage());
});

if (window.__ElectronBridge) {
  window.__ElectronBridge.switchRoom = (roomId) => {
    history.push(`/chat/r/${roomId}`);
  };
}

// Detect login and logout based on changes to authToken.
const localforage = extendPrototype(localForage);
localforage.ready(() => {
  localforage.configObservables({
    crossTabChangeDetection: true,
    crossTabNotification: true,
  });
  const observable = localforage.newObservable({
    crossTabNotification: true,
    changeDetection: false,
    key: token.AUTH_TOKEN_KEY,
    removeItem: true,
    setItem: true,
  });
  observable.subscribe({
    next: (args) => {
      if (args.valueChange && args.crossTabNotification && args.success) {
        if (args.methodName === "removeItem") {
          store.dispatch(account.logoutInit());
        } else if (args.methodName === "setItem") {
          window.location.reload();
        }
      }
    },
  });
});

const App = () => <Root store={store} persistor={persistor} history={history} />;

export default App;
