import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { NavLink as Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Message,
  Segment,
} from "semantic-ui-react";
import { Elements, StripeProvider } from "react-stripe-elements";

import * as subscriptionActions from "actions/subscription";

import config from "../../config";
import StripeForm from "./StripeForm";
import styles from "./styles.module.scss";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlanId: null,
      stripe: null,
      error: null,
    };
  }

  componentDidMount() {
    const { customerInit, plansInit, subscriptionsInit } = this.props;
    subscriptionsInit();
    customerInit();
    plansInit();
    if (window.Stripe) {
      // the stripe script is loaded.
      this.setState({ stripe: window.Stripe(config.stripeApiKey) });
    } else {
      // the stripe script isn't loaded yet. lets wait for it to load.
      document.querySelector("#stripe-js").addEventListener("load", () => {
        this.setState({ stripe: window.Stripe(config.stripeApiKey) });
      });
    }
  }

  componentDidUpdate() {
    const {
      plans,
      plansInitialized,
      plansLoading,
      subscriptions,
      subscriptionsInitialized,
      subscriptionsLoading,
      subscriptionsInit,
    } = this.props;
    const { stripe, selectedPlanId } = this.state;
    if (plansInitialized && !plansLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      if (!plans.length && selectedPlanId) this.setState({ selectedPlanId: null });
      if (plans.length && !plans.some((plan) => plan.id === selectedPlanId)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ selectedPlanId: plans[0].id });
      }
    }
    if (stripe && subscriptionsInitialized && !subscriptionsLoading) {
      const subscription = subscriptions[0];
      if (subscription && subscription.latestInvoice.paymentIntent.status === "requires_action") {
        // 3D secure time!
        stripe
          .handleCardPayment(subscription.latestInvoice.paymentIntent.clientSecret)
          .then((result) => {
            if (result.error) {
              this.setState({ error: result.error.message });
            } else {
              this.setState({ error: null });
              subscriptionsInit();
            }
          });
      }
    }
  }

  handleSubmit = (token = {}, coupon) => {
    const { subscriptions, subscribeInit, resubscribeInit } = this.props;
    const subscription = subscriptions[0];
    if (subscription) {
      resubscribeInit(subscription.id, token.id, coupon);
    } else {
      const { selectedPlanId } = this.state;
      subscribeInit(selectedPlanId, token.id, coupon);
    }
  };

  handleContinue = () => {
    this.handleSubmit();
  };

  render() {
    const {
      canceledSubscriptions,
      customer,
      customerError,
      customerInitialized,
      customerLoading,
      plans,
      plansError,
      plansInitialized,
      plansLoading,
      subscriptions,
      subscriptionsError,
      subscriptionsInitialized,
      subscriptionsLoading,
    } = this.props;
    const { selectedPlanId, stripe, error } = this.state;

    const loading =
      !stripe ||
      !customerInitialized ||
      customerLoading ||
      !plansInitialized ||
      plansLoading ||
      !subscriptionsInitialized ||
      subscriptionsLoading;
    const errors = error || subscriptionsError || plansError || customerError;
    const subscription = subscriptions[0];
    const selectedPlan = subscription
      ? subscription.plan
      : plans.find((plan) => plan.id === selectedPlanId);
    const fullDiscount =
      !subscription &&
      selectedPlan &&
      customer &&
      customer.discount &&
      customer.discount.coupon.duration === "forever" &&
      (customer.discount.coupon.percentOff >= 100 ||
        customer.discount.coupon.amountOff >= selectedPlan.amount);
    const paymentFailed =
      !!subscription &&
      subscription.latestInvoice.paymentIntent.status === "requires_payment_method";
    const paymentActionRequired =
      !!subscription && subscription.latestInvoice.paymentIntent.status === "requires_action";

    return (
      <>
        <Container className={styles.main}>
          <div className={styles.headerContainer}>
            <Header
              as="h2"
              className={styles.header}
              content={`Welcome to ${config.appName}!`}
              textAlign="center"
            />
            <Header
              as="h4"
              className={styles.subHeader}
              color="grey"
              content={
                // eslint-disable-next-line no-nested-ternary
                fullDiscount
                  ? `Congratulations, you have been granted access to ${config.appName}.`
                  : canceledSubscriptions && canceledSubscriptions.length
                  ? "Your subscription has ended. To continue, an active subscription is required."
                  : "Subscribe to continue."
              }
              textAlign="center"
            />
            {fullDiscount && (
              <Button
                content={`Continue to ${config.appName}`}
                onClick={this.handleContinue}
                primary
                className={styles.continueButton}
                size="big"
                loading={loading}
                disabled={loading}
              />
            )}
          </div>
          {!fullDiscount && (
            <Segment className={styles.secureSegment} padded loading={loading}>
              <Label attached="top right">
                <Icon name="lock" />
                Secure Server
              </Label>
              {errors && (
                <Message error header="Error" content={errors} className={styles.errorMessage} />
              )}
              {!errors && paymentFailed && (
                <Message
                  error
                  header="Payment Error"
                  content="Your last payment failed to go through. Please enter another pament source."
                  className={styles.errorMessage}
                />
              )}
              {!errors && !paymentFailed && paymentActionRequired && (
                <Message
                  error
                  header="Payment Requires Action"
                  content="Your payment requires further action to continue."
                  className={styles.errorMessage}
                />
              )}
              <Grid stackable columns={2} divided>
                <Grid.Column>
                  <Grid.Row>
                    {selectedPlan && (
                      <Segment className={styles.planSegment} padded>
                        <Header as="h4" className={styles.planName}>
                          {selectedPlan.metadata.name}
                        </Header>
                        <div className={styles.planAmount}>
                          <span className={styles.amountUnits}>
                            {selectedPlan.currency.toUpperCase()}
                          </span>
                          <span className={styles.amountValue}>
                            {(selectedPlan.amount / 100.0).toFixed(2)}
                          </span>
                          <span className={styles.amountInterval}>/{selectedPlan.interval}</span>
                        </div>
                        {selectedPlan.trialPeriodDays && (
                          <div className={styles.planTrial}>
                            <span className={styles.trialText}>
                              {selectedPlan.trialPeriodDays} days free trial
                            </span>
                          </div>
                        )}
                      </Segment>
                    )}
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row>
                    <StripeProvider stripe={stripe}>
                      <Elements>
                        <StripeForm onSubmit={this.handleSubmit} />
                      </Elements>
                    </StripeProvider>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </Segment>
          )}

          <div style={{ "text-align": "center", "margin-top": "3rem" }}>
            <List horizontal>
              <List.Item>
                <Link to="/legal/terms">Terms of Use</Link>
              </List.Item>
              <List.Item>
                <Link to="/legal/privacy">Privacy Policy</Link>
              </List.Item>
            </List>
          </div>
        </Container>
      </>
    );
  }
}

Subscription.propTypes = {
  canceledSubscriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  customer: PropTypes.shape().isRequired,
  customerError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  customerInitialized: PropTypes.bool.isRequired,
  customerLoading: PropTypes.bool.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  plansError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  plansInitialized: PropTypes.bool.isRequired,
  plansLoading: PropTypes.bool.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subscriptionsError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  subscriptionsInitialized: PropTypes.bool.isRequired,
  subscriptionsLoading: PropTypes.bool.isRequired,
  subscriptionsInit: PropTypes.func.isRequired,
  subscribeInit: PropTypes.func.isRequired,
  resubscribeInit: PropTypes.func.isRequired,
  customerInit: PropTypes.func.isRequired,
  plansInit: PropTypes.func.isRequired,
};

export default connect(
  ({ subscription: { subscriptions, customer, plans } }) => ({
    canceledSubscriptions: subscriptions.canceled,
    customer: customer.data,
    customerError: customer.error,
    customerInitialized: customer.initialized,
    customerLoading: customer.loading,
    plans: plans.data,
    plansError: plans.error,
    plansInitialized: plans.initialized,
    plansLoading: plans.loading,
    subscriptions: subscriptions.active,
    subscriptionsError: subscriptions.error,
    subscriptionsInitialized: subscriptions.initialized,
    subscriptionsLoading: subscriptions.loading,
  }),
  {
    subscriptionsInit: subscriptionActions.subscriptions.init,
    subscribeInit: subscriptionActions.subscribe.init,
    resubscribeInit: subscriptionActions.resubscribe.init,
    customerInit: subscriptionActions.customer.init,
    plansInit: subscriptionActions.plans.init,
  }
)(Subscription);
