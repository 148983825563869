import PropTypes from "prop-types";
import React from "react";
import { Icon, Form, Dropdown } from "semantic-ui-react";
import { NavLink as Link } from "react-router-dom";
import classnames from "classnames";

import { isBldAiDomain } from "utils";
import { bldAiDomain } from "utils/constants";
import { Roles } from "utils/constants/project";
import UserAvatar from "components/UserAvatar";
import EditMemberModal from "./EditMemberModal";

import * as styles from "./styles.module.scss";

export default class MemberItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editModalVisible: false,
    };
  }

  onHourlyRateChange = (id, value) => {
    const { updateHourlyRate } = this.props;
    updateHourlyRate(id, value);
  };

  onRoleChange = (id, value) => {
    const { updateRole } = this.props;
    updateRole(id, value);
  };

  showEditMemberModal = () =>
    this.setState({
      editModalVisible: true,
    });

  closeEditMemberModal = () =>
    this.setState({
      editModalVisible: false,
    });

  handleSaveDisplayName = (email, value) => {
    const { updateDisplayName } = this.props;
    updateDisplayName(email, value);
    this.closeEditMemberModal();
  };

  render() {
    const { currentUser, user, removeMember, isCurrentUserBldAiMember } = this.props;

    const { editModalVisible } = this.state;
    const text = !user.slug ? user.email : user.displayName;
    const currentUserText = !currentUser.slug ? currentUser.email : currentUser.displayName;
    const isCurrentUser = text === currentUserText;

    return (
      <div key={user.slug || user.email} className={styles.memberContainer}>
        <div className={styles.sectionColumnAction}>
          {text !== currentUserText && (
            <Icon name="remove" className={styles.removeIcon} onClick={() => removeMember(user)} />
          )}
        </div>
        <div className={styles.sectionColumnAvatar}>
          <UserAvatar
            avatar={user.avatar}
            isAnonymous={!user.slug}
            bgColor={user.avatarBgcolor}
            className={styles.sectionMemberMatchAvatar}
            displayName={text}
            fgColor={user.avatarColor}
            size="32"
          />
        </div>
        <div className={styles.sectionNameColumn}>
          <div
            className={
              isBldAiDomain(user.email)
                ? classnames(styles.memberName, styles.isbldaiParentNode)
                : styles.memberName
            }
          >
            {!user.slug ? (
              <span>{text}</span>
            ) : (
              <>
                <Link to={`/talents/${user.slug}`} target="_blank" title="View profile">
                  {text}
                  {isCurrentUser && " (You)"}
                </Link>
                <br />
                <span className={styles.isbldai}>
                  {isBldAiDomain(user.email) ? bldAiDomain : ""}
                </span>
              </>
            )}
          </div>
        </div>
        <div className={styles.sectionColumnAction}>
          {isBldAiDomain(user.email) || user.slug ? (
            <div className={styles.spacer} />
          ) : (
            <div className={styles.edit} onClick={this.showEditMemberModal} />
          )}
        </div>
        <div className={styles.sectionColumnInput}>
          <Form.Input
            type="number"
            className={styles.sectionMemberRate}
            name="amount"
            placeholder="$0"
            onChange={(e) => this.onHourlyRateChange(user.slug || user.email, e.target.value)}
            value={user.hourlyRate > 0 ? user.hourlyRate : ""}
            min={0}
          />
        </div>
        <div className={styles.sectionColumn}>
          <Dropdown
            className={styles.sectionMemberType}
            onChange={(param, data) => this.onRoleChange(user.slug || user.email, data.value)}
            options={Roles.options.map((option) => ({
              ...option,
              disabled: isBldAiDomain(user.email)
                ? option.value.includes(Roles.client)
                : !option.value.includes(Roles.client),
            }))}
            selection
            defaultValue={user.role}
            disabled={isCurrentUser || (isBldAiDomain(user.email) && !isCurrentUserBldAiMember)}
          />
        </div>
        <EditMemberModal
          onClose={this.closeEditMemberModal}
          onSave={this.handleSaveDisplayName}
          open={editModalVisible}
          user={user}
        />
      </div>
    );
  }
}

MemberItem.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  isCurrentUserBldAiMember: PropTypes.bool,
  removeMember: PropTypes.func,
  updateHourlyRate: PropTypes.func,
  updateRole: PropTypes.func,
  updateDisplayName: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
};

MemberItem.defaultProps = {
  isCurrentUserBldAiMember: false,
  removeMember: null,
  updateHourlyRate: null,
  updateRole: null,
  updateDisplayName: null,
  user: null,
};
