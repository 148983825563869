import { call, takeLatest, put } from "redux-saga/effects";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { api, token as tokenService } from "services";
import * as feedback from "actions/feedback";

import { fetchEntity, fetchEntityQS, pushEntity } from "./base";

const fetchWorkmates = fetchEntity.bind(null, feedback.workmates, api.fetchWorkmates);
const fetchFeedback = fetchEntity.bind(null, feedback.feedback, api.fetchFeedback);
const fetchWorkmateFeedback = fetchEntity.bind(
  null,
  feedback.workmateFeedback,
  api.fetchWorkmateFeedback
);
const fetchConjointPair = fetchEntity.bind(null, feedback.conjointPair, api.fetchConjointPair);
const pushTalentFeedback = pushEntity.bind(null, feedback.talentFeedback, api.postTalentFeedback);
const pushSkillFeedback = pushEntity.bind(null, feedback.skillFeedback, api.postSkillFeedback);
const pushFeedbackRequest = pushEntity.bind(
  null,
  feedback.feedbackRequest,
  api.postFeedbackRequest
);
const pushFeedback = pushEntity.bind(null, feedback.feedbackWizard, api.postFeedbackWizard);
const pushGroupFeedback = pushEntity.bind(null, feedback.groupFeedback, api.postGroupFeedback);
const pushConjointFeedback = pushEntity.bind(
  null,
  feedback.conjointFeedback,
  api.postConjointFeedback
);
const pushUndoConjointFeedback = pushEntity.bind(
  null,
  feedback.undoConjointFeedback,
  api.postUndoConjointFeedback
);
const fetchRatingsHistory = fetchEntityQS.bind(
  null,
  feedback.ratingsHistory,
  api.fetchRatingsHistory
);
const fetchTeamRatingsHistory = fetchEntityQS.bind(
  null,
  feedback.teamRatingsHistory,
  api.fetchTeamRatingsHistory
);
const fetchRelations = fetchEntityQS.bind(null, feedback.relations, api.fetchRelations);
const fetchCommonSoftSkill = fetchEntityQS.bind(
  null,
  feedback.commonSkill,
  api.fetchCommonSoftSkill
);
const fetchCommonHardSkill = fetchEntityQS.bind(
  null,
  feedback.commonSkill,
  api.fetchCommonHardSkill
);

export function* loadWorkmates({ username }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchWorkmates, true, token, username);
}

export function* loadFeedback({ username }) {
  const token = yield tokenService.getAuthToken();
  yield put(showLoading());
  yield call(fetchFeedback, true, token, username);
  yield put(hideLoading());
}

export function* loadWorkmateFeedback({ username }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchWorkmateFeedback, true, token, username);
}

export function* loadConjointPair() {
  const token = yield tokenService.getAuthToken();
  yield put(showLoading());
  yield call(fetchConjointPair, true, token);
  yield put(hideLoading());
}

export function* createTalentFeedback({ data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushTalentFeedback, true, token, null, null, data);
}

export function* createSkillFeedback({ data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushSkillFeedback, true, token, null, null, data);
}

export function* createFeedbackRequest({ data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushFeedbackRequest, true, token, null, null, data);
}

export function* createFeedback({ data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushFeedback, true, token, null, null, data);
}

export function* createGroupFeedback({ data }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushGroupFeedback, true, token, null, null, data);
}

export function* createConjointFeedback({ data }) {
  const token = yield tokenService.getAuthToken();
  const response = yield call(pushConjointFeedback, true, token, null, null, data);
  if (response) {
    // get next pair
    yield put(feedback.conjointPair.init());
  }
}

export function* undoConjointFeedback({ id }) {
  const token = yield tokenService.getAuthToken();
  yield call(pushUndoConjointFeedback, true, token, id, null);
}

export function* loadRatingsHistory({ username, query }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchRatingsHistory, true, token, username, null, query);
}

export function* loadTeamRatingsHistory({ username, query }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchTeamRatingsHistory, true, token, username, null, query);
}

export function* loadRelations({ username, query }) {
  const token = yield tokenService.getAuthToken();
  yield call(fetchRelations, true, token, username, null, query);
}

export function* loadCommonSkill({ username, skillType, workmate }) {
  const token = yield tokenService.getAuthToken();
  if (skillType === "soft") {
    yield call(fetchCommonSoftSkill, true, token, username, null, workmate);
  } else if (skillType === "hard") {
    yield call(fetchCommonHardSkill, true, token, username, null, workmate);
  }
}

export function* watchFeedback() {
  yield takeLatest(feedback.WORKMATES.INIT, loadWorkmates);
  yield takeLatest(feedback.FEEDBACK.INIT, loadFeedback);
  yield takeLatest(feedback.WORKMATE_FEEDBACK.INIT, loadWorkmateFeedback);
  yield takeLatest(feedback.CONJOINT_PAIR.INIT, loadConjointPair);
  yield takeLatest(feedback.TALENT_FEEDBACK.INIT, createTalentFeedback);
  yield takeLatest(feedback.SKILL_FEEDBACK.INIT, createSkillFeedback);
  yield takeLatest(feedback.FEEDBACK_WIZARD.INIT, createFeedback);
  yield takeLatest(feedback.GROUP_FEEDBACK.INIT, createGroupFeedback);
  yield takeLatest(feedback.FEEDBACK_REQUEST.INIT, createFeedbackRequest);
  yield takeLatest(feedback.CONJOINT_FEEDBACK.INIT, createConjointFeedback);
  yield takeLatest(feedback.UNDO_CONJOINT_FEEDBACK.INIT, undoConjointFeedback);
  yield takeLatest(feedback.RATINGS_HISTORY.INIT, loadRatingsHistory);
  yield takeLatest(feedback.TEAM_RATINGS_HISTORY.INIT, loadTeamRatingsHistory);
  yield takeLatest(feedback.RELATIONS.INIT, loadRelations);
  yield takeLatest(feedback.COMMON_SKILL.INIT, loadCommonSkill);
}
