import config from "config";
import { Emoji } from "emoji-mart";
import emojiData from "emoji-mart/data/all.json";
import moment from "moment-timezone";
import React from "react";

// api commands
const FEEDBACK = "feedback";
const LEAVE = "leave";
const ME = "me";
const POLL = "poll";
const REMOVE = "remove";
const THANK_YOU = "thank_you";
const INVITE = "invite";
const LOG = "log";
const REMIND = "remind";
const CALENDAR = "calendar";

// adds targetUsers to the data
const MULTI_USER_ARG = {
  argLabel: "[user1,user2,...]",
  transformData: (data) => {
    const targetUsers = data.args.length > 0 ? data.args[0] : "";
    return {
      ...data,
      targetUsers: targetUsers.split(","),
      args: data.args.slice(1),
      message: data.message.slice(targetUsers.length + 1),
    };
  },
};

// autocompletes users in the room
const ROOM_MULTI_USER_ARG = {
  ...MULTI_USER_ARG,
  autoComplete: ({ message, user, room, callback } = {}) => {
    if (message.split(/\s/gi).length !== 1) return callback([]);
    const selectedSlugs = message.split(",");
    const lastWord = selectedSlugs[selectedSlugs.length - 1];
    const excludeSlugs = selectedSlugs.slice(0, -1);
    return callback(
      room.users
        .filter(
          (roomUser) =>
            roomUser.slug !== user.slug &&
            !excludeSlugs.includes(roomUser.slug) &&
            (roomUser.slug.toLowerCase().includes(lastWord) ||
              roomUser.displayName.toLowerCase().includes(lastWord))
        )
        .map((roomUser) => ({
          value: roomUser.slug,
          text: roomUser.slug,
          description:
            roomUser.displayName !== roomUser.slug
              ? `${roomUser.displayName} (${roomUser.email})`
              : "",
        }))
    );
  },
};

// autocompletes users outside the room
const OTHER_MULTI_USER_ARG = {
  ...MULTI_USER_ARG,
  autoComplete: ({ message, users, room, callback } = {}) => {
    if (message.split(/\s/gi).length !== 1) return callback([]);
    const selectedSlugs = message.split(",");
    const lastWord = selectedSlugs[selectedSlugs.length - 1];
    const excludeSlugs = room.userSlugs.concat(selectedSlugs.slice(0, -1));
    return callback(
      Object.values(users)
        .filter(
          (user) =>
            !excludeSlugs.includes(user.slug) &&
            (user.slug.toLowerCase().includes(lastWord) ||
              user.displayName.toLowerCase().includes(lastWord))
        )
        .map((user) => ({
          value: user.slug,
          text: user.slug,
          description: user.displayName !== user.slug ? `${user.displayName} (${user.email})` : "",
        }))
    );
  },
};

const appendToMessage = (data, value) => ({ ...data, message: `${data.message} ${value}` });

// define slash commands
export const SLASH_FEEDBACK = {
  argLabel: "[user] [soft skill] [1-3] [comment]",
  autoComplete: ({ message, user, room, callback } = {}) => {
    const words = message.split(/\s/gi);
    if (words.length === 1)
      return callback(
        room.users
          .filter(
            (roomUser) =>
              roomUser.slug !== user.slug &&
              (roomUser.slug.toLowerCase().includes(words[0]) ||
                roomUser.displayName.toLowerCase().includes(words[0]))
          )
          .map((roomUser) => ({
            value: roomUser.slug,
            text: roomUser.slug,
            description:
              roomUser.displayName !== roomUser.slug
                ? `${roomUser.displayName} (${roomUser.email})`
                : "",
          }))
      );
    if (words.length === 2)
      return callback(
        [
          {
            value: "athlete",
            text: "Athlete",
            description: "Live healthy (sleep, nutrition, exercise, and relaxation)",
          },
          {
            value: "pioneer",
            text: "Pioneer",
            description: "Take responsibility and act with courage",
          },
          {
            value: "teacher",
            text: "Teacher",
            description: "Selflessly help and mentor others",
          },
          {
            value: "student",
            text: "Student",
            description: "Embrace feedback with a growth mindset",
          },
          {
            value: "scientist",
            text: "Scientist",
            description: "Conduct unbiased experiments and learn from data",
          },
          {
            value: "artist",
            text: "Artist",
            description: "Apply creative design thinking",
          },
          {
            value: "engineer",
            text: "Engineer",
            description: "Be 80-20 and provide fast turnarounds",
          },
          {
            value: "owner",
            text: "Owner",
            description: "Be accountable for quality end products and speak up",
          },
          {
            value: "human",
            text: "Human",
            description: "Let your fun, quirky, and authentic self shine through",
          },
        ].filter(({ value }) => value.toLowerCase().startsWith(words[1].toLowerCase()))
      );
    if (words.length === 3)
      return callback(
        [
          {
            value: "3",
            text: "3",
            description: "A role model",
          },
          {
            value: "2",
            text: "2",
            description: "Like the rest of us",
          },
          {
            value: "1",
            text: "1",
            description: "Has room for improvement",
          },
        ].filter(({ value }) => value.toLowerCase().startsWith(words[2].toLowerCase()))
      );
    return callback([]);
  },
  command: FEEDBACK,
  description: "Give feedback",
  shouldCallAPI: true,
  transformData: (data) => {
    const {
      args: [targetUser = "", skill = "", rating = "", ...args],
      message,
    } = data;
    return {
      ...data,
      args,
      rating,
      skill,
      targetUser,
      message: message.slice(rating.length + skill.length + targetUser.length + 3),
    };
  },
};
export const SLASH_INVITE = {
  ...OTHER_MULTI_USER_ARG,
  argLabel: "[user1,user2,user@email.com,...]",
  command: INVITE,
  description: "Invite users to the room",
  shouldCallAPI: true,
};
export const SLASH_REMOVE = {
  ...ROOM_MULTI_USER_ARG,
  command: REMOVE,
  description: "Remove users from the room",
  shouldCallAPI: true,
};
export const SLASH_LEAVE = {
  command: LEAVE,
  description: "Leave the chat room",
  shouldCallAPI: true,
};
export const SLASH_ME = {
  argLabel: "[message]",
  command: ME,
  description: "Do an action",
  shouldCallAPI: true,
};
export const SLASH_POLL = {
  argLabel: "[question]",
  command: POLL,
  description: "Start a poll",
  shouldCallAPI: true,
};
export const SLASH_SHRUG = {
  argLabel: "[message]",
  command: "shrug",
  description: "Append ¯\\_(ツ)_/¯ to your message",
  shouldSendMessage: true,
  transformData: (data) => appendToMessage(data, "¯\\\\\\_(ツ)\\_/¯"),
};
export const SLASH_REMIND = {
  argLabel: "[days] [message]",
  command: REMIND,
  description: "Post your message specified days later",
  shouldCallAPI: true,
  transformData: (data) => ({
    ...data,
    days: data.args.length > 0 ? data.args[0] : null,
    message: data.args.length > 1 ? data.args.slice().splice(1).join(" ") : "",
  }),
};
export const SLASH_CALENDAR = {
  ...ROOM_MULTI_USER_ARG,
  argLabel: "[user1,user2,...] [yyyy-mm-dd] [hh:mm] [GMT] [duration in hours]",
  command: CALENDAR,
  description: "Created a new calendar event for specified users",
  transformData: (data) => {
    // if no hours for duration assume 1 hour
    const hours = data.args.length > 4 && !Number.isNaN(data.args[4]) ? data.args[4] : 1;
    // if no gmt is given assume users local timezone
    const gmt =
      data.args.length > 3 && !Number.isNaN(data.args[3].replace("GMT", ""))
        ? data.args[3].replace("GMT", "")
        : -new Date().getTimezoneOffset() / 60;
    const dateParts = data.args.length > 1 ? data.args[1].split(/[-/\\]/) : [];
    // if no year given assume current
    if (dateParts.length === 2) dateParts.splice(0, 0, new Date().getFullYear());
    const startTime =
      data.args.length > 2
        ? data.args[2].split(/[:-]/)
        : [new Date().getHours(), new Date().getMinutes()];

    if (startTime.length === 1) {
      startTime.push("00");
      startTime[0] = startTime[0].replace("AM", "");
      if (startTime[0].indexOf("PM") !== -1) {
        startTime[0] = startTime[0].replace("PM", "");
        startTime[0] = Number(startTime[0]) + 12;
      }
      if (startTime[0].length < 2) startTime[0] = `0${startTime[0]}`;
    }
    const date =
      data.args.length > 2
        ? moment
            .utc(`${dateParts.join("-")} ${startTime.join(":")}`)
            .add(-gmt, "h")
            .format()
        : moment().format();
    return {
      ...data,
      args: [],
      message: "",
      targetUsers: data.args.length ? data.args[0].split(",") : [],
      date,
      gmt,
      hours,
    };
  },
  shouldCallAPI: true,
};
export const SLASH_THANK_YOU = {
  ...ROOM_MULTI_USER_ARG,
  argLabel: "[user1,user2,...] [message]",
  command: THANK_YOU,
  description: "Thank the user",
  shouldCallAPI: true,
};
export const SLASH_THANKS = {
  ...SLASH_THANK_YOU,
  alias: "thanks",
};
export const SLASH_LOG = {
  argLabel: "[hours] [comment]",
  command: LOG,
  description: "Log work hours",
  shouldCallAPI: true,
  transformData: (data) => {
    const hours = data.args.length > 0 ? data.args[0] : null;
    const timezone = moment.tz.guess();
    let start = null;
    let end = null;

    if (hours) {
      start = moment().hour(8).minute(0).second(0).millisecond(0);
      end = start.clone().add(hours, "hours");
    }

    return {
      ...data,
      comment: data.args.length > 1 ? data.args.slice().splice(1).join(" ") : "",
      start: moment(start).tz(timezone, true).toISOString(),
      end: moment(end).tz(timezone, true).toISOString(),
      hours,
      timezone,
    };
  },
};

export const slashCommandRun = ({
  command,
  alias,
  prefix = "/",
  transformData = (data) => data,
  shouldCallAPI,
  shouldSendMessage,
} = {}) => ({ callAPI, sendMessage, message, canInviteMember }) => {
  if (!canInviteMember) return false;

  const value = `${prefix}${alias || command}`;
  const args = message.split(/\s/gi);
  if (args.length > 0 && args[0] === value) {
    const data = transformData({
      args: args.slice(1),
      message: message.slice(value.length + 1),
    });
    if (shouldCallAPI) callAPI(command, data);
    if (shouldSendMessage) sendMessage(data.message);
    return true;
  }
  return false;
};

export const slashCommandAutoComplete = ({
  command,
  alias,
  prefix = "/",
  label,
  argLabel,
  description,
  autoComplete = ({ callback }) => callback([]),
  hidden,
} = {}) => ({ message, user, users, room, canInviteMember, callback } = {}) => {
  const value = `${prefix}${alias || command}`;
  if (hidden) return callback([]);
  if (!canInviteMember && command === INVITE) return callback([]);
  if (message.length > 0 && value.toLowerCase().startsWith(message.toLowerCase())) {
    return callback([
      {
        value,
        text: label || (argLabel ? `${value} ${argLabel}` : value),
        description,
      },
    ]);
  }
  if (message.split(/\s/gi, 1)[0] === value) {
    return autoComplete({
      message: message.slice(value.length + 1),
      user,
      users,
      room,
      callback,
    });
  }
  return callback([]);
};

export const atMentionAutoComplete = ({
  user,
  users,
  message,
  room,
  callback,
  prefix = "@",
  addSpecial = true,
  includeSelf = false,
} = {}) => {
  const pattern = new RegExp(`^[\\s\\S]*${prefix}([A-Za-z0-9]*)$`);
  const match = pattern.exec(message);
  if (match) {
    const value = match[match.length - 1];
    if (message.length > 0 && message.endsWith(`${prefix}${value}`)) {
      // Handle special @all and @here handles.
      const slugs = [...room.userSlugs.sort()];
      const data = { ...users };

      if (addSpecial) {
        slugs.splice(0, 0, "here");
        slugs.splice(0, 0, "all");
        data.all = { displayName: "Everyone in channel" };
        data.here = { displayName: "Everyone currently online in channel" };
      }

      const options = slugs
        .filter((slug) => includeSelf || slug !== user.slug)
        .filter(
          (slug) =>
            slug.toLowerCase().startsWith(value.toLowerCase()) ||
            data[slug].displayName.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
        .map((slug) => [
          {
            value: `${prefix}${slug}`,
            text: slug,
            description: data[slug].email
              ? `${data[slug].displayName} (${data[slug].email})`
              : data[slug].displayName,
          },
        ]);

      return callback(options);
    }
  }
  return callback([]);
};

const emojis = emojiData.categories
  .map((category) => category.emojis)
  .reduce((acc, emojiList) => [...acc, ...emojiList], []);

export const emojiAutoComplete = ({ message, callback, prefix = ":" } = {}) => {
  const pattern = new RegExp(`^[\\s\\S]*${prefix}([a-zA-Z0-9-_+]{2,}):?$`);
  const match = pattern.exec(message);
  if (match) {
    const value = match[match.length - 1];
    const options = emojis
      .filter((emoji) => emoji.toLowerCase().startsWith(value))
      .sort()
      .map((emoji) => [
        {
          value: `${emoji}:`,
          content: (
            <span>
              <Emoji native emoji={emoji} size={14} /> :{emoji}:
            </span>
          ),
        },
      ])
      .slice(0, 11);
    return callback(options);
  }
  return callback([]);
};

export const autoCompleteSelector = ({
  user,
  users,
  message,
  room,
  atMentionCallback,
  emojiCallback,
  emptyCallback,
} = {}) => {
  const atMentionPattern = new RegExp(`^[\\s\\S]*@([A-Za-z0-9]*)$`);
  const emojiPattern = new RegExp(`^[\\s\\S]*:([a-zA-Z0-9-_+]{2,}):?$`);

  if (atMentionPattern.exec(message)) {
    return atMentionAutoComplete({ user, users, message, room, callback: atMentionCallback });
  }
  if (emojiPattern.exec(message) && !message.endsWith(":")) {
    return emojiAutoComplete({ message, callback: emojiCallback });
  }
  return emptyCallback();
};

export const availableSlashCommands = [
  SLASH_INVITE,
  SLASH_LEAVE,
  SLASH_ME,
  SLASH_POLL,
  SLASH_REMOVE,
  SLASH_SHRUG,
  SLASH_THANK_YOU,
  SLASH_THANKS,
  SLASH_REMIND,
  SLASH_CALENDAR,
];
if (config.flags.feedback) {
  availableSlashCommands.push(SLASH_FEEDBACK);
}
if (config.flags.timeTracker) {
  availableSlashCommands.push(SLASH_LOG);
}

export const autoCompletes = availableSlashCommands.map((sc) => slashCommandAutoComplete(sc));
export const slashCommands = availableSlashCommands.map((sc) => slashCommandRun(sc));
