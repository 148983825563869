import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

import { channelCreateOrUpdate } from "actions/project";
import { sidePanel, PANELS } from "actions/sidePanel";
import {
  getCurrentRoom,
  getCurrentProject,
  getCurrentProjectMemberships,
} from "reducers/selectors";
import { projectType } from "types/project";
import Alert from "components/Alert";
import { LabeledInput } from "components/input";
import { Roles } from "utils/constants/project";

import * as sidePanelStyles from "../../styles.module.scss";
import * as styles from "./styles.module.scss";

class SidePanelChannelInformation extends React.Component {
  constructor(props) {
    super(props);

    const { channel } = props;
    const email = channel ? channel.email : "";
    const name = channel ? channel.name : "";
    const description = channel ? channel.description : "";

    this.state = {
      email: {
        value: email,
        isDirty: false,
      },
      name: {
        value: name,
        isDirty: false,
      },
      description: {
        value: description,
        isDirty: false,
      },
      edit: false,
      errors: {},
      loading: false,
    };
  }

  componentWillReceiveProps(props) {
    const { channel } = props;
    if (channel.name !== this.props.channel.name) {
      const name = channel ? channel.name : "";
      this.setState({ name: { value: name } });
    }
  }

  handleCancel = () => {
    const { channel } = this.props;
    const email = channel ? channel.email : "";
    const name = channel ? channel.name : "";
    const description = channel ? channel.name : "";

    this.setState({
      email: {
        value: email,
        isDirty: false,
      },
      name: {
        value: name,
        isDirty: false,
      },
      description: {
        value: description,
        isDirty: false,
      },
    });
  };

  handleChange = (e, data) => {
    const { channel } = this.props;
    const originalValue = (channel && channel[data.name]) || "";

    const isDirty = data.value !== originalValue;
    this.setState({
      [data.name]: {
        value: data.value,
        isDirty,
      },
    });
  };

  handleErrorHide = (field, index) => {
    const { errors } = this.state;
    if (errors[field]) {
      const currentErrors = [...errors[field]];
      const updatedErrors = [...currentErrors.slice(0, index), ...currentErrors.slice(index + 1)];

      this.setState({
        errors: {
          ...errors,
          [field]: updatedErrors,
        },
      });
    }
  };

  handleSubmit = async () => {
    const { channelsCreateOrUpdate, project, channel } = this.props;
    const { email, name, description } = this.state;
    const hasChanges = email.isDirty || name.isDirty || description.isDirty;

    if (hasChanges) {
      const channels = [
        {
          email: email.value,
          name: name.value,
          description: description.value,
          id: channel.id,
        },
      ];
      try {
        this.setState({ loading: true });
        await new Promise((resolve, reject) =>
          channelsCreateOrUpdate(project.id, channels, resolve, reject)
        );
        this.setState({
          email: {
            ...email,
            isDirty: false,
          },
          name: {
            ...name,
            isDirty: false,
          },
          description: {
            ...description,
            isDirty: false,
          },
          edit: false,
          errors: {},
          loading: false,
        });
      } catch (errors) {
        this.setState({
          errors: errors[0],
          loading: false,
        });
      }
    } else {
      this.toggleEdit();
    }
  };

  toggleEdit = () => {
    this.setState((prevState) => ({
      edit: !prevState.edit,
    }));
  };

  render() {
    const { channel, hasProjectUrls, visible, panelId, sidePanelClose, isAdmin } = this.props;
    const { email, name, description, edit, errors, loading } = this.state;

    if (!visible || panelId !== PANELS.CHANNEL_INFO) {
      return null;
    }

    return (
      <div className={classnames(sidePanelStyles.sidePanel, styles.channelInfo)}>
        <div className={sidePanelStyles.heading}>
          <p>Information</p>
          <div>
            {isAdmin &&
              channel.isActive &&
              (edit ? (
                <Button
                  loading={loading}
                  primary
                  content="Save"
                  className={styles.channelButton}
                  onClick={this.handleSubmit}
                />
              ) : (
                <Button
                  basic
                  content="Edit"
                  className={sidePanelStyles.actionButton}
                  onClick={this.toggleEdit}
                />
              ))}
            <Button
              basic
              content="Close"
              onClick={() => sidePanelClose(PANELS.CHANNEL_INFO, { roomId: channel.id })}
              className={classnames(
                sidePanelStyles.actionButton,
                sidePanelStyles.close,
                hasProjectUrls && sidePanelStyles.hasProjectUrlsClose
              )}
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.inputContainer}>
            <LabeledInput
              fluid
              disabled={!edit}
              id="email"
              label="Reply via email"
              name="email"
              onChange={this.handleChange}
              value={email.value}
              componentClassName={classnames(styles.inputField, email.isDirty && styles.isDirty)}
            />

            {errors.email &&
              errors.email.map((error, index) => (
                <Alert
                  className={styles.alertError}
                  content={error}
                  key={`email-${index}`}
                  onClick={() => this.handleErrorHide("email", index)}
                  type="danger"
                />
              ))}
          </div>
          <div className={styles.inputContainer}>
            <LabeledInput
              fluid
              disabled={!edit}
              id="name"
              label="Channel name"
              name="name"
              onChange={this.handleChange}
              value={name.value}
              componentClassName={classnames(styles.inputField, name.isDirty && styles.isDirty)}
            />
            {errors.name &&
              errors.name.map((error, index) => (
                <Alert
                  className={styles.alertError}
                  content={error}
                  key={`name-${index}`}
                  onClick={() => this.handleErrorHide("name", index)}
                  type="danger"
                />
              ))}
          </div>
          <div className={styles.inputContainer}>
            <LabeledInput
              fluid
              disabled={!edit}
              id="description"
              label="Description"
              name="description"
              onChange={this.handleChange}
              value={description.value}
              componentClassName={classnames(
                styles.inputField,
                description.isDirty && styles.isDirty
              )}
            />
            {errors.description &&
              errors.description.map((error, index) => (
                <Alert
                  className={styles.alertError}
                  content={error}
                  key={`description-${index}`}
                  onClick={() => this.handleErrorHide("description", index)}
                  type="danger"
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

SidePanelChannelInformation.propTypes = {
  channel: PropTypes.shape().isRequired,
  hasProjectUrls: PropTypes.bool.isRequired,
  project: projectType,
  panelId: PropTypes.string,
  visible: PropTypes.bool,
  channelsCreateOrUpdate: PropTypes.func,
  sidePanelClose: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    auth: { user },
    sidePanel: { panelId, visible },
    entities: { teams, teamMemberships, users },
  } = state;
  const currentRoom = getCurrentRoom(state);
  const project = getCurrentProject(state);
  const hasProjectUrls = Boolean(project && project.gitlabUrl);

  let isAdmin = false;
  if (currentRoom && currentRoom.project) {
    const projectMemberships = getCurrentProjectMemberships(state);
    isAdmin =
      user && !!projectMemberships[user.slug] && projectMemberships[user.slug].role === Roles.admin;
  } else if (currentRoom && currentRoom.teamSlug) {
    const team = Object.keys(teams)
      .map((key) => teams[key])
      .filter((obj) => obj.slug === currentRoom.teamSlug)[0];
    const teamMembers = Object.keys(teamMemberships)
      .map((id) => teamMemberships[id])
      .filter((obj) => team.talentSlugs.includes(obj.talent) && obj.team === team.id)
      .map((obj) => ({
        ...obj,
        ...users[obj.talent],
      }));
    const userMembership = teamMembers.find((obj) => user && obj.talent === user.slug);
    isAdmin = userMembership && userMembership.role === Roles.admin;
  }

  return {
    channel: currentRoom,
    hasProjectUrls,
    project,
    panelId,
    visible,
    isAdmin,
  };
}

export default connect(mapStateToProps, {
  channelsCreateOrUpdate: channelCreateOrUpdate.request,
  sidePanelClose: sidePanel.close,
})(SidePanelChannelInformation);
