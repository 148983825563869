import React from "react";
import { Button } from "semantic-ui-react";
import config from "config";
import styles from "./styles.module.scss";

const LOGIN_URL = `${config.apiBaseUrl}/auth/accounts/github/login/`;

const GitHubButton = (props) => (
  <Button
    className={styles.button}
    onClick={() => {
      window.location.href = LOGIN_URL;
    }}
    title="GitHub"
    {...props}
  >
    <img src="/github-icon.svg" alt="/github" />
  </Button>
);

export default GitHubButton;
