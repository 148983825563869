import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";

import { teamInvitation } from "actions/account";

import styles from "./styles.module.scss";

class TeamInvitation extends React.Component {
  componentDidMount() {
    const { token } = this.props;
    const { key, decision } = this.props.match.params;
    this.props.teamInvitationInit(token, key, decision);
  }

  render() {
    const { loading, message } = this.props;

    return (
      <Container>
        <Header as="h2" content="Team Invitation" className={styles.header} />
        {loading && <p>Verifying token...</p>}
        {!loading && message && <p>{message}</p>}
      </Container>
    );
  }
}

TeamInvitation.propTypes = {
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape(),
  message: PropTypes.string,
  teamInvitationInit: PropTypes.func.isRequired,
  token: PropTypes.string,
};

function mapStateToProps(state) {
  const {
    auth: { token },
    teamInvitation: { loading, message },
  } = state;
  return {
    loading,
    message,
    token,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    teamInvitationInit: teamInvitation.init,
  })(TeamInvitation)
);
