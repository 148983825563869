import { createRequestTypes, action } from ".";

export const ADD_TEAM_MEMBERS = createRequestTypes("ADD_TEAM_MEMBERS");
export const BULK_PHONE_NUMBERS_UPDATE = createRequestTypes("BULK_PHONE_NUMBERS_UPDATE");
export const BULK_SKILLS_UPDATE = createRequestTypes("BULK_SKILLS_UPDATE");
export const BULK_TEAM_SKILLS_UPDATE = createRequestTypes("BULK_TEAM_SKILLS_UPDATE");
export const DELETE_TEAM = createRequestTypes("DELETE_TEAM");
export const DELETE_TEAM_MEMBERSHIP = createRequestTypes("DELETE_TEAM_MEMBERSHIP");
export const DELETE_USER = createRequestTypes("DELETE_USER");
export const REMOVE_INVITE = createRequestTypes("REMOVE_INVITE");
export const RESEND_INVITE = createRequestTypes("RESEND_INVITE");
export const SET_USER_PRESENCE = "SET_USER_PRESENCE";
export const TEAM = createRequestTypes("TEAM");
export const TEAM_DETAIL = createRequestTypes("TEAM_DETAIL");
export const TEAM_CALENDAR = createRequestTypes("TEAM_CALENDAR");
export const TEAM_INVITATION_DETAIL = createRequestTypes("TEAM_INVITATION_DETAIL");
export const TEAM_MEMBERSHIPS_DETAIL = createRequestTypes("TEAM_MEMBERSHIPS_DETAIL");
export const TEAM_TALENT_INVITES = createRequestTypes("TEAM_TALENTS");
export const TEAM_TALENTS = createRequestTypes("TEAM_TALENTS");
export const UPDATE_MEMBERSHIPS = createRequestTypes("UPDATE_MEMBERSHIPS");
export const UPDATE_TEAM = createRequestTypes("UPDATE_TEAM");
export const UPDATE_USER = createRequestTypes("UPDATE_USER");
export const USER = createRequestTypes("USER");
export const USER_DETAIL = createRequestTypes("USER_DETAIL");
export const USER_PRESENCE = createRequestTypes("USER_PRESENCE");
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const ADD_TO_ROCKET_CHAT = createRequestTypes("ADD_TO_ROCKET_CHAT");

export const user = {
  request: (refresh) => action(USER.REQUEST, { refresh }),
  success: (response) => action(USER.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(USER.FAILURE, { error, errorDetails }),
};

export const loadUser = (slug, refresh) => action(USER.INIT, { slug, refresh });

export const updateUser = {
  init: (slug, data) => action(UPDATE_USER.INIT, { slug, data }),
  success: (response) => action(UPDATE_USER.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(UPDATE_USER.FAILURE, { error, errorDetails }),
};

export const deleteUser = {
  init: (slug) => action(DELETE_USER.INIT, { slug }),
  success: (response) => action(DELETE_USER.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(DELETE_USER.FAILURE, { error, errorDetails }),
};

export const userPresence = {
  init: () => action(USER_PRESENCE.INIT),
  request: () => action(USER_PRESENCE.REQUEST),
  success: (response) => action(USER_PRESENCE.SUCCESS, { response }),
  failure: (error) => action(USER_PRESENCE.FAILURE, { error }),
};

export const setUserPresence = (slug, online) => action(SET_USER_PRESENCE, { slug, online });

export const team = {
  init: () => action(TEAM.INIT),
  request: () => action(TEAM.REQUEST),
  success: (response) => action(TEAM.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(TEAM.FAILURE, { error, errorDetails }),
};

export const loadTalentTeam = (slug, refresh) => action(TEAM.INIT, { slug, refresh });

export const teamTalents = {
  init: (slug) => action(TEAM_TALENTS.INIT, { slug }),
  request: (slug) => action(TEAM_TALENTS.REQUEST, { slug }),
  success: (response) => action(TEAM_TALENTS.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(TEAM_TALENTS.FAILURE, { error, errorDetails }),
};

export const teamTalentInvites = {
  init: (slug) => action(TEAM_TALENT_INVITES.INIT, { slug }),
  request: (slug) => action(TEAM_TALENT_INVITES.REQUEST, { slug }),
  success: (response) => action(TEAM_TALENT_INVITES.SUCCESS, { response }),
  failure: ({ error, errorDetails }) =>
    action(TEAM_TALENT_INVITES.FAILURE, { error, errorDetails }),
};

export const updateTeam = {
  init: (slug, id, data) => action(UPDATE_TEAM.INIT, { slug, id, data }),
  success: (response) => action(UPDATE_TEAM.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(UPDATE_TEAM.FAILURE, { error, errorDetails }),
};

export const deleteTeam = {
  init: (slug, name, resolve, reject) => action(DELETE_TEAM.INIT, { slug, name, resolve, reject }),
  success: (response) => action(DELETE_TEAM.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(DELETE_TEAM.FAILURE, { error, errorDetails }),
};

export const addMembers = {
  init: (slug, data) => action(ADD_TEAM_MEMBERS.INIT, { slug, data }),
  success: (response) => action(ADD_TEAM_MEMBERS.SUCCESS, { response }),
};

export const updateMemberships = {
  init: (slug, data, resolve, reject) =>
    action(UPDATE_MEMBERSHIPS.INIT, { slug, data, resolve, reject }),
  success: (response) => action(UPDATE_MEMBERSHIPS.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(UPDATE_MEMBERSHIPS.FAILURE, { error, errorDetails }),
};

export const resendTeamInvite = {
  init: (id) => action(RESEND_INVITE.INIT, { id }),
  success: (response) => action(RESEND_INVITE.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(RESEND_INVITE.FAILURE, { error, errorDetails }),
};

export const removeTeamInvite = {
  init: (id) => action(REMOVE_INVITE.INIT, { id }),
  success: (response) => action(REMOVE_INVITE.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(REMOVE_INVITE.FAILURE, { error, errorDetails }),
};

export const teamCalendar = {
  init: (slug, params = {}) => action(TEAM_CALENDAR.INIT, { slug, params }),
  request: (slug) => action(TEAM_CALENDAR.REQUEST, { slug }),
  success: (response) => action(TEAM_CALENDAR.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(TEAM_CALENDAR.FAILURE, { error, errorDetails }),
};

export const bulkSkillsUpdate = {
  init: (slug, data) => action(BULK_SKILLS_UPDATE.INIT, { slug, data }),
  success: (response) => action(BULK_SKILLS_UPDATE.SUCCESS, { response }),
};

export const bulkTeamSkillsUpdate = {
  init: (slug, data) => action(BULK_TEAM_SKILLS_UPDATE.INIT, { slug, data }),
  success: (response) => action(BULK_TEAM_SKILLS_UPDATE.SUCCESS, { response }),
};

export const userDetail = {
  success: (response) => action(USER_DETAIL.SUCCESS, { response }),
};

export const deleteTeamMembership = {
  init: (slug, id, resolve, reject) =>
    action(DELETE_TEAM_MEMBERSHIP.INIT, { slug, id, resolve, reject }),
  success: (response) => action(DELETE_TEAM_MEMBERSHIP.SUCCESS, { response }),
  failure: ({ error, errorDetails }) =>
    action(DELETE_TEAM_MEMBERSHIP.FAILURE, { error, errorDetails }),
};

export const teamMembershipDetail = {
  success: (response) => action(TEAM_MEMBERSHIPS_DETAIL.SUCCESS, { response }),
};

export const teamInvitationDetail = {
  success: (response) => action(TEAM_INVITATION_DETAIL.SUCCESS, { response }),
};

export const teamDetail = {
  success: (response) => action(TEAM_DETAIL.SUCCESS, { response }),
};

export const closeNotification = () => action(CLOSE_NOTIFICATION);

export const bulkPhoneNumbersUpdate = {
  init: (slug, data) => action(BULK_PHONE_NUMBERS_UPDATE.INIT, { slug, data }),
  success: (slug, response) => action(BULK_PHONE_NUMBERS_UPDATE.SUCCESS, { slug, response }),
  failure: ({ error, errorDetails }) =>
    action(BULK_PHONE_NUMBERS_UPDATE.FAILURE, { error, errorDetails }),
};

export const addToRocketChat = {
  init: (slug) => action(ADD_TO_ROCKET_CHAT.INIT, { slug }),
  success: (response) => action(ADD_TO_ROCKET_CHAT.SUCCESS, { response }),
  failure: ({ error, errorDetails }) => action(ADD_TO_ROCKET_CHAT.FAILURE, { error, errorDetails }),
};
