export const DEFAULT_TIME_HEIGHT = 70;

export const DEFAULT_TIME_WIDTH = 50;

export const COUNT_ARRAY = Array.from(Array(24).keys());

export const UNAVAILABLE_HOUR = {
  firstHalf: "hoursOutsideWork",
  secondHalf: "hoursOutsideWork",
};

export const DAY_ARRAY_UNAVAILABLE = Array(24).fill(UNAVAILABLE_HOUR);

export const TIMELINE_COLORS = {
  hoursAvailable: "#FFFFFF",
  hoursOutsideWork: "rgba(196, 196, 196, 0.3)",
  hoursBusy: "rgba(105, 90, 206, 0.3)",
  hoursUnavailable: "rgba(235, 87, 87, 0.3)",
};
