import React from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

import config from "config";
import styles from "./NotFound.module.scss";

export default function NotFound() {
  return (
    <div data-testid="notFound" className={styles.container}>
      <Helmet defer={false}>
        <FormattedMessage id="NotFound.title" defaultMessage={`${config.appName} - Not Found`} />
      </Helmet>
      <Grid stretched>
        <Grid.Column stretched textAlign="center">
          <Grid.Row textAlign="center">
            <h1>
              <FormattedMessage id="NotFound.header" defaultMessage="This page isn't available." />
            </h1>
            <p>&nbsp;</p>
            <p>
              <Link to="/">
                <FormattedMessage id="NotFound.return" defaultMessage="Return Home" />
              </Link>
            </p>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </div>
  );
}
