import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import TextAreaAutosize from "react-textarea-autosize";
import { Button, Icon } from "semantic-ui-react";
import * as styles from "./styles.module.scss";

class TextArea extends React.Component {
  constructor(props) {
    super(props);

    this.input = props.forwardedRef || React.createRef();

    this.state = {
      addedFiles: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.files !== prevProps.files) {
      this.processFiles();
    }
  }

  handleAttach = () => {
    this.props.dropzoneOpen();
  };

  handleRetry = () => {
    Object.keys(this.props.files)
      .filter((fileId) => this.props.files[fileId].error)
      .forEach((fileId) => {
        this.props.attachmentInit(this.props.cacheId, this.props.files[fileId]);
      });
  };

  addFile = (fileId) =>
    new Promise((resolve) => {
      const { files, name, onChange, value } = this.props;
      const file = files[fileId];

      const inFocus = document.activeElement === this.input.current.ref;
      const selectionStart =
        this.input.current && inFocus ? this.input.current.ref.selectionStart : value.length;
      const placeholder = `{{file:${fileId}}}`;

      this.setState(
        (prevState) => ({
          addedFiles: {
            ...prevState.addedFiles,
            [fileId]: {
              done: !file.uploading,
              placeholder,
            },
          },
        }),
        () => {
          const leftValue = value.substring(0, selectionStart);
          const rightValue = value.substring(selectionStart);
          let newValue = "";
          if (leftValue.length > 0 && rightValue.length > 0) {
            newValue = `${leftValue} ${placeholder} ${rightValue}`;
          } else if (leftValue.length > 0 && rightValue.length === 0) {
            newValue = `${leftValue} ${placeholder}`;
          } else if (leftValue.length === 0 && rightValue.length > 0) {
            newValue = `${placeholder} ${rightValue}`;
          } else if (leftValue.length === 0 && rightValue.length === 0) {
            newValue = placeholder;
          }
          onChange(null, { name, value: newValue });
          resolve(fileId);
        }
      );
    });

  processFile = (fileId) => {
    const { files, name, onChange, value } = this.props;
    const { addedFiles } = this.state;
    const file = files[fileId];
    const fileStatus = addedFiles[fileId];

    if (!fileStatus.done) {
      if (file.file && !file.error && !file.uploading) {
        let fileText = "";
        if (
          file.type === "jpeg" ||
          file.type === "gif" ||
          file.type === "png" ||
          file.type === "webp"
        ) {
          fileText = `![image](${file.file})`;
        } else {
          fileText = `[file](${file.file})`;
        }
        const newValue = value.replace(fileStatus.placeholder, fileText);
        onChange(null, { name, value: newValue });
        this.setState((prevState) => ({
          addedFiles: {
            ...prevState.addedFiles,
            [fileId]: { ...prevState.addedFiles[fileId], done: true },
          },
        }));
      }
    }
  };

  processFiles = () => {
    const { files } = this.props;
    const { addedFiles } = this.state;

    Object.keys(files).forEach((fileId) => {
      if (!addedFiles[fileId]) {
        this.addFile(fileId).then(this.processFile);
      } else {
        this.processFile(fileId);
      }
    });
  };

  isUploadingSomeFiles = () =>
    Object.keys(this.props.files).some((fileId) => this.props.files[fileId].uploading);

  hasUploadError = () =>
    Object.keys(this.props.files).some((fileId) => this.props.files[fileId].error);

  getUploadAverage = () => {
    const { files } = this.props;
    return (
      Math.floor(
        Object.keys(files).reduce((acc, fileId) => acc + files[fileId].progress, 0) /
          Object.keys(files).length
      ) || 0
    );
  };

  handleTextAreaChange = (e) => {
    const { onChange } = this.props;
    const { name, value } = e.target;

    if (onChange) onChange(e, { name, value });
  };

  render() {
    const {
      attachmentInit,
      cacheId,
      className,
      disabled,
      dropzoneOpen,
      dropzoneOnPaste,
      forwardedRef,
      hintless,
      onChange,
      value,
      error,
      ...textAreaProps
    } = this.props;

    const hasUploadError = this.hasUploadError();
    const isUploadingSomeFiles = this.isUploadingSomeFiles();
    const uploadAverage = this.getUploadAverage();
    let textAreaClass = classnames(styles.textarea, className);
    if (error) {
      textAreaClass = classnames(styles.textarea, styles.error, className);
    }

    return (
      <React.Fragment>
        <div className={styles.textareaContainer}>
          <TextAreaAutosize
            className={textAreaClass}
            disabled={disabled}
            onChange={this.handleTextAreaChange}
            onPaste={dropzoneOnPaste}
            value={value}
            {...textAreaProps}
            ref={forwardedRef}
          />

          <div className={styles.textareaButtons}>
            {!hasUploadError && !isUploadingSomeFiles && !disabled && (
              <Button
                icon="image"
                onClick={this.handleAttach}
                tabIndex="-1"
                title="Attach file"
                type="button"
              />
            )}
            {hasUploadError && !isUploadingSomeFiles && !disabled && (
              <Button
                icon="warning"
                negative
                onClick={this.handleRetry}
                tabIndex="-1"
                title="Retry"
                type="button"
              />
            )}
          </div>
        </div>

        {!hintless && (
          <p style={{ float: "right", marginTop: 5 }}>
            <small>Markdown is supported.</small>
          </p>
        )}

        <div style={{ float: "left", marginTop: 5 }}>
          {isUploadingSomeFiles && (
            <span>
              Attaching files ({uploadAverage}
              %) &hellip; <Icon name="spinner" loading />
            </span>
          )}
        </div>
      </React.Fragment>
    );
  }
}

TextArea.propTypes = {
  attachmentInit: PropTypes.func.isRequired,
  cacheId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dropzoneOpen: PropTypes.func,
  dropzoneOnPaste: PropTypes.func,
  files: PropTypes.objectOf(PropTypes.shape()),
  hintless: PropTypes.bool,
  onChange: PropTypes.func,
};

export default React.forwardRef((props, ref) => <TextArea {...props} forwardedRef={ref} />);
