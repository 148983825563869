import { isEqual } from "lodash";
import React, { Component, Suspense, lazy } from "react";
import { batch, connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { getRoom, getThread, roomChange } from "actions/chat";
import { sidePanel, PANELS } from "actions/sidePanel";
import config from "config";
import { getRooms, getCurrentRoom, getCurrentProjectMemberships } from "reducers/selectors";
import Chat from "containers/Chat";
import Loading from "containers/Loading";
import SidePanel from "containers/Workspace/SidePanel";
import { getActiveTabRoomUrl } from "utils/apps";
import { projectMembershipType } from "types/project";
import { Roles } from "utils/constants/project";

const Health = lazy(() => import("containers/Health"));
const Meeting = lazy(() => import("containers/Meeting"));
const Planning = lazy(() => import("containers/Planning"));
const Poll = lazy(() => import("containers/Poll"));
const Feedback = lazy(() => import("containers/Feedback"));
const ClientFeedback = lazy(() => import("containers/ClientFeedback"));

const ScreenSettings = lazy(() => import("./ScreenSettings"));

class Channel extends Component {
  static getDerivedStateFromProps(props, prevState) {
    if (props.match.params.roomId !== prevState.roomId) {
      return {
        updating: true,
        roomId: props.match.params.roomId,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    this.state = {
      updating: false, // When `true, prevent channel from rendering while room is being changed.
      roomId: null,
      autoScroll: false,
    };
    this.membersRef = React.createRef();
  }

  componentDidMount() {
    this.setCurrentRoom();
  }

  componentDidUpdate(prevProps) {
    if (this.props.room && (!prevProps.room || prevProps.room.id !== this.props.room.id)) {
      this.updateCurrentRoom();
      this.handleSidePanelToggle();
    }
    const { location } = this.props;
    const { location: oldLocation } = prevProps;

    if (
      !isEqual(location, oldLocation) &&
      location.state &&
      location.state.scrollToMembers &&
      !this.state.autoScroll
    ) {
      this.setData({ autoScroll: true });
    }
  }

  getUserProjectRole() {
    const { user, projectMemberships } = this.props;
    if (user) {
      const userMembership = projectMemberships[user.slug];
      if (userMembership) {
        return userMembership.role;
      }
    }
  }

  onMessageOpenThread = (id, messageId) => {
    batch(() => {
      this.props.sidePanelOpen(PANELS.THREAD, { id, messageId });
      this.props.getThread({ id, messageId });
    });
    return false;
  };

  setCurrentRoom() {
    const { room, token, lastRoomTab } = this.props;
    const { roomId } = this.props.match.params;

    if (room) {
      if (!room.isComplete) {
        this.props.getRoom({ id: room.id, token, room });
      }

      const { project } = room;
      if (!roomId) {
        const url = getActiveTabRoomUrl({ id: room.id, project }, lastRoomTab);
        this.props.history.replace(url);
      } else {
        this.props.roomChange(project, room.id);
        this.setState({ updating: false });
      }
    }
  }

  setData = (data) => {
    this.setState(data);
  };

  handleAutoScroll = () => {
    this.setState({ autoScroll: false });
  };

  handleSidePanelToggle = () => {
    const {
      room: { id, selected },
      sidePanelId,
      sidePanelClose,
      sidePanelOpen,
    } = this.props;

    // Since thread carries over to any channel,
    // there is no need to toggle the other side panels
    if (sidePanelId !== PANELS.THREAD) {
      if (selected && selected.panelId) {
        sidePanelOpen(selected.panelId, { roomId: id });
      } else {
        sidePanelClose();
      }
    }
  };

  updateCurrentRoom() {
    this.setCurrentRoom();
  }

  render() {
    const { room } = this.props;
    const { updating } = this.state;

    if (!room) {
      return <Loading isLoading />;
    }

    if (updating) {
      return null;
    }

    const roomId = room.id;
    const userProjectRole = this.getUserProjectRole();
    const isProjectAdmin = Roles.adminRoles.includes(userProjectRole);

    return (
      <Suspense fallback={<Loading isLoading />}>
        <SidePanel onMessageOpenThread={this.onMessageOpenThread}>
          <Switch>
            {config.flags.planning && isProjectAdmin && (
              <Route key={`planning-${roomId}`} path="/chat/r/:roomId/planning">
                <Planning />
              </Route>
            )}

            {config.flags.meeting && (
              <Route key={`meeting-${roomId}`} path="/chat/r/:roomId/meetings">
                <Meeting />
              </Route>
            )}

            {config.flags.poll && (
              <Route key={`poll-${roomId}`} path="/chat/r/:roomId/polls">
                <Poll />
              </Route>
            )}

            {config.flags.health && (
              <Route key={`health-${roomId}`} path="/chat/r/:roomId/health">
                <Health />
              </Route>
            )}

            {config.flags.feedback && (
              <Route key={`feedback-${roomId}`} path="/chat/r/:roomId/feedback">
                <Feedback />
              </Route>
            )}

            <Route key={`client-feedback-${roomId}`} path="/chat/r/:roomId/client-feedback">
              <ClientFeedback />
            </Route>

            <Route key={`settings-${roomId}`} path="/chat/r/:roomId/settings">
              <ScreenSettings
                autoScroll={this.state.autoScroll}
                membersRef={this.membersRef}
                onAutoScroll={this.handleAutoScroll}
              />
            </Route>

            <Route key={roomId} path="/chat/r/:roomId/:jump?">
              <Chat onMessageOpenThread={this.onMessageOpenThread} />
            </Route>

            <Route key="chat" exact path="/chat/r">
              <Chat onMessageOpenThread={this.onMessageOpenThread} />
            </Route>
          </Switch>
        </SidePanel>
      </Suspense>
    );
  }
}

function mapStateToProps(state, props) {
  const { roomId } = props.match.params;
  const {
    auth: { token, user },
    messages: { initialLoad: messagesInitialLoad, loading: messagesLoading },
    rooms: { isRoomListLoaded, lastRoomTab },
    sidePanel: { panelId },
  } = state;

  if (!isRoomListLoaded) {
    return {
      hasLoadedMessages: false,
      isLoadingMessages: true,
      room: null,
      lastRoomTab,
    };
  }

  const rooms = getRooms(state);
  const isRoomValid = Number(roomId) && !!rooms[roomId];

  if (!isRoomValid) {
    const room = getCurrentRoom(state);
    if (room) {
      props.history.replace(`/chat/r/${room.id}`);
    }
    return {
      hasLoadedMessages: true,
      isLoadingMessages: false,
      room: null,
    };
  }

  // if (addMembersRoomId && String(addMembersRoomId) !== roomId) {
  //   const room = rooms[addMembersRoomId];
  //   if (room) {
  //     props.history.replace(`/chat/r/${room.id}`);
  //     return {
  //       hasLoadedMessages: true,
  //       isLoadingMessages: false,
  //       room: null,
  //     };
  //   }
  // }

  const room = rooms[roomId];
  const hasLoadedMessages = room ? !!messagesInitialLoad[room.id] : false;
  const isLoadingMessages = room ? !!messagesLoading[room.id] : false;
  const projectMemberships = getCurrentProjectMemberships(state);

  return {
    hasLoadedMessages,
    isLoadingMessages,
    projectMemberships,
    room,
    sidePanelId: panelId,
    token,
    user,
    lastRoomTab,
  };
}

Channel.propTypes = {
  getRoom: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape(),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      jump: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      roomId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
  }).isRequired,
  projectMemberships: PropTypes.objectOf(projectMembershipType).isRequired,
  room: PropTypes.shape(),
  roomChange: PropTypes.func.isRequired,
  sidePanelId: PropTypes.string,
  token: PropTypes.string,
  user: PropTypes.shape(),

  getThread: PropTypes.func.isRequired,
  sidePanelClose: PropTypes.func.isRequired,
  sidePanelOpen: PropTypes.func.isRequired,
  lastRoomTab: PropTypes.string,
};

export default withRouter(
  connect(mapStateToProps, {
    getRoom: getRoom.request,
    roomChange,
    getThread: getThread.request,
    sidePanelOpen: sidePanel.open,
    sidePanelClose: sidePanel.close,
  })(Channel)
);
