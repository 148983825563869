import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import ReactMarkdown from "react-markdown";
import { Button, Checkbox, Form, Icon, Input } from "semantic-ui-react";

import MarkdownTextArea from "components/MarkdownTextArea";
import { plugins, renderers } from "components/Markdown";
import { pollOptionType } from "types/poll";
import { PollOptionType } from "utils/constants/poll";

import styles from "./styles.module.scss";

const PollOptionHandle = SortableHandle(() => (
  <Icon className={classnames(styles.pollOptionEditSortHandle, "equals")} />
));

export default class PollOption extends React.Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
  }

  componentDidMount() {
    if (this.props.editMode && (!this.props.option || !this.props.option.name)) {
      this.input.current.focus();
    }
  }

  handleChange = (e, { checked, value }) => {
    const { onChange, option, type } = this.props;

    if (type === PollOptionType.pointAllocation) {
      if (onChange) onChange(option, Number(value));
    } else if (onChange) {
      onChange(option, Number(checked));
    }
  };

  handleKeyPress = (event) => {
    const { onKeyPress, option } = this.props;
    if (onKeyPress) onKeyPress(event, option);
  };

  handleRemoveClick = (event) => {
    const { onRemoveClick, option } = this.props;
    if (onRemoveClick) onRemoveClick(event, option);
  };

  render() {
    const {
      disabled,
      editMode,
      error,
      isLast,
      onChange,
      onPaste,
      option,
      type,
      value,
    } = this.props;

    if (editMode) {
      return (
        <div className={styles.pollOptionEdit}>
          <PollOptionHandle />

          <Form.Field className={styles.pollOptionEditName} error={error}>
            <MarkdownTextArea
              cacheId={`poll-option-${option.id}`}
              disabled={disabled}
              fluid
              hintless
              name={option.id}
              onChange={onChange}
              onKeyDown={this.handleKeyPress}
              onPaste={onPaste}
              ref={this.input}
              placeholder="Option"
              previewless
              rows={1}
              value={option.name}
            />
          </Form.Field>

          <Button
            className={styles.removeButton}
            disabled={disabled}
            icon="remove"
            onClick={this.handleRemoveClick}
            size="medium"
            tabIndex="-1"
            title="Remove"
            type="button"
          />
        </div>
      );
    }

    if (type === PollOptionType.pointAllocation) {
      return (
        <Form.Field className={styles.pollOptionPointAllocation} key={option.id}>
          <Input
            className={classnames(styles.pollOption, isLast && styles.last)}
            min={0}
            onChange={this.handleChange}
            type="number"
            value={value}
          />

          <ReactMarkdown
            renderers={renderers}
            plugins={plugins}
            source={option.name}
            unwrapDisallowed
          />
        </Form.Field>
      );
    }

    return (
      <Form.Field key={option.id}>
        <Checkbox
          checked={value > 0}
          className={classnames(styles.pollOption, isLast && styles.last)}
          disabled={disabled}
          label={{
            children: (
              <ReactMarkdown
                renderers={renderers}
                plugins={plugins}
                source={option.name}
                unwrapDisallowed
              />
            ),
          }}
          onChange={this.handleChange}
          radio={type === PollOptionType.multipleChoiceSingleAnswer}
          value={option.id}
        />
      </Form.Field>
    );
  }
}

PollOption.propTypes = {
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
  error: PropTypes.bool,
  option: pollOptionType.isRequired,
  type: PropTypes.oneOf(PollOptionType.all),
  value: PropTypes.number,
  isLast: PropTypes.bool,

  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  onKeyPress: PropTypes.func,
  onRemoveClick: PropTypes.func,
};

PollOption.defaultProps = {
  disabled: false,
  editMode: false,
  error: false,
  value: 0,
  isLast: false,
};
