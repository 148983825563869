import { createRequestTypes, action } from "./index";

export const NOTIFICATION = createRequestTypes("NOTIFICATION");
export const PAGINATED_NOTIFICATION = createRequestTypes("PAGINATED_NOTIFICATION");
export const MARK_READ_NOTIFICATION = createRequestTypes("MARK_READ_NOTIFICATION");
export const UPDATE_NOTIFICATION = createRequestTypes("UPDATE_NOTIFICATION");

export const notification = {
  init: (query = "") => action(NOTIFICATION.INIT, { query }),
  request: () => action(NOTIFICATION.REQUEST),
  success: (response) => action(NOTIFICATION.SUCCESS, { response }),
  failure: (error) => action(NOTIFICATION.FAILURE, { error }),
};

export const paginatedNotification = {
  init: (query = "") => action(PAGINATED_NOTIFICATION.INIT, { query }),
  request: () => action(PAGINATED_NOTIFICATION.REQUEST),
  success: (response) => action(PAGINATED_NOTIFICATION.SUCCESS, { response }),
  failure: (error) => action(PAGINATED_NOTIFICATION.FAILURE, { error }),
};

export const markReadNotification = {
  init: () => action(MARK_READ_NOTIFICATION.INIT),
  request: () => action(MARK_READ_NOTIFICATION.REQUEST),
  success: (response) => action(MARK_READ_NOTIFICATION.SUCCESS, { response }),
  failure: (error) => action(MARK_READ_NOTIFICATION.FAILURE, { error }),
};

export const updateNotification = {
  init: (id, data) => action(UPDATE_NOTIFICATION.INIT, { id, data }),
  request: () => action(UPDATE_NOTIFICATION.REQUEST),
  success: (response) => action(UPDATE_NOTIFICATION.SUCCESS, { response }),
  failure: (error) => action(UPDATE_NOTIFICATION.FAILURE, { error }),
};

export const submitMarkRead = (id, recipient) =>
  updateNotification.init(id, {
    recipient,
    unread: false,
  });
