import classnames from "classnames";
import Humanize from "humanize-plus";
import PropTypes from "prop-types";
import React from "react";
import { Icon, Label, Button, Modal } from "semantic-ui-react";

import ImageLoader from "components/ImageLoader";
import TextViewer from "components/TextViewer";
import { getAbsoluteUri } from "utils";

import * as styles from "./styles.module.scss";

function filterImagesOnly(fileList) {
  return fileList.filter(
    (file) =>
      file.type === "png" ||
      file.type === "jpeg" ||
      file.type === "gif" ||
      file.type === "svg" ||
      file.type === "webp"
  );
}

export default class MessageAttachmentListItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.isCollapsed,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCollapsed !== this.props.isCollapsed) {
      this.setData({
        collapsed: this.props.isCollapsed,
      });
    }
  }

  setData = (data) => {
    this.setState(data);
  };

  getFile = () => {
    const { fileId, files } = this.props;
    return files.find((file) => file.id === fileId || file.fileId === fileId);
  };

  handleRemove = (e) => {
    const { fileId, onRemove } = this.props;
    if (!onRemove) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onRemove(fileId);
  };

  handleRetry = () => {
    const { fileId, onRetry } = this.props;
    if (!onRetry) {
      return;
    }
    onRetry(fileId);
  };

  handleTogglePreview = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  _isImage = (fileType) => {
    switch (fileType) {
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
      case "webp":
        return true;
      default:
        return false;
    }
  };

  renderLink(preventHref) {
    const { loading, onRemove, onRetry, upload } = this.props;
    const { collapsed } = this.state;
    const file = this.getFile();
    const fileUrl = file.file ? getAbsoluteUri(file.file) : null;

    let linkDetail = {};
    if (!file.data) {
      linkDetail = {
        icon: "arrow down",
        title: "Download",
      };
    }

    let icon = null;
    if (loading) {
      icon = {
        name: "spinner",
        loading: true,
      };
    } else if (preventHref) {
      icon = {
        name: "attach",
      };
    } else if (file.error) {
      icon = {
        name: "warning circle",
      };
    } else if (upload) {
      icon = {
        name: "check",
      };
    } else {
      icon = {
        name: "download",
      };
    }

    if (upload) {
      return (
        <Label
          as="a"
          basic
          download={file.name}
          icon={icon}
          color={file.error ? "red" : null}
          onRemove={onRemove && !loading ? this.handleRemove : null}
          onClick={onRetry && file.error ? this.handleRetry : null}
          href={upload || preventHref || file.data ? null : `${fileUrl}?download=1`}
          content={
            (!file.error &&
              upload &&
              `${file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name}`) ||
            (file.error && `${file.name} (${file.error.detail})`)
          }
          target="_blank"
          detail={
            (upload && file.error && file.error.retry && "Click to retry") ||
            (upload &&
              file.progress &&
              !file.error &&
              `${Humanize.fileSize(file.fileSize || file.data.size)} ${Math.floor(file.progress)}%`)
          }
          className={classnames(styles.messageBoxAttachment, loading && styles.loading)}
        />
      );
    }

    return (
      <div className={classnames(styles.attachment, collapsed && styles.collapsed)}>
        <div className={styles.attachmentDetail}>
          <p className={styles.heading}>
            {this._isImage(file.type) && (
              <Icon
                name={collapsed ? "expand" : "compress"}
                title={collapsed ? "Expand preview" : "Collapse preview"}
                onClick={this.handleTogglePreview}
              />
            )}
            {upload || file.data ? (
              file.name
            ) : (
              <a href={`${fileUrl}?download=1`} title={file.name}>
                {file.name}
              </a>
            )}
          </p>
          <p className={styles.subheading}>{Humanize.fileSize(file.fileSize || file.data.size)}</p>
          {file.error && <p className={styles.subheading}>{`(${file.error.detail})`}</p>}
        </div>
        <div className={styles.attachmentButton}>
          {linkDetail && (
            <Button
              as="a"
              basic
              icon={linkDetail.icon}
              type="button"
              href={upload || file.data ? null : `${fileUrl}?download=1`}
              title={linkDetail.title}
            />
          )}
        </div>
      </div>
    );
  }

  renderAttachmentIcon = (fileType) => {
    let icon = "";
    switch (fileType) {
      case "text":
      case "docx":
        icon = "file alternate";
        break;
      case "mp3":
      case "x-m4a":
        icon = "file audio";
        break;
      case "webm":
      case "mp4":
      case "octet-stream":
        icon = "file video";
        break;
      case "xlsx":
        icon = "table";
        break;
      default:
        icon = "paperclip";
        break;
    }
    return (
      <div className={styles.attachmentIcon}>
        <Icon fitted size="large" name={icon} />
      </div>
    );
  };

  renderFileViewer() {
    const { files } = this.props;
    const { collapsed } = this.state;

    const file = this.getFile();
    const fileUrl = file.file ? getAbsoluteUri(file.file) : null;

    switch (file.type) {
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
      case "webp":
        return (
          <React.Fragment>
            {!collapsed && (
              <ImageLoader imageList={filterImagesOnly(files)} src={fileUrl} alt="Attachment" />
            )}
            {this.renderLink()}
          </React.Fragment>
        );
      case "text":
        return (
          <React.Fragment>
            <Modal
              basic
              closeIcon
              inverted
              className={styles.modal}
              trigger={this.renderAttachmentIcon(file.type)}
            >
              <Modal.Content>
                <TextViewer filePath={fileUrl} />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="a"
                  compact
                  content="Download"
                  href={file.data ? null : `${fileUrl}?download=1`}
                  icon="download"
                  rel="noopener noreferrer"
                  secondary
                  size="small"
                  target="_blank"
                />
              </Modal.Actions>
            </Modal>
            {this.renderLink(true)}
          </React.Fragment>
        );
      case "mp3":
      case "x-m4a":
        return (
          <React.Fragment>
            <Modal
              basic
              closeIcon
              inverted
              className={styles.modal}
              size="mini"
              trigger={this.renderAttachmentIcon(file.type)}
            >
              <Modal.Content>
                {/* eslint-disable jsx-a11y/media-has-caption */}
                <audio
                  autoPlay={false}
                  src={fileUrl}
                  controls
                  controlsList="nodownload"
                  style={{ maxWidth: "100%" }}
                />
                {/* eslint-enable jsx-a11y/media-has-caption */}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="a"
                  compact
                  content="Download"
                  href={file.data ? null : `${fileUrl}?download=1`}
                  icon="download"
                  rel="noopener noreferrer"
                  secondary
                  size="small"
                  target="_blank"
                />
              </Modal.Actions>
            </Modal>
            {this.renderLink()}
          </React.Fragment>
        );
      case "webm":
      case "mp4":
      case "octet-stream":
        return (
          <React.Fragment>
            <Modal
              basic
              closeIcon
              inverted
              className={styles.modal}
              size="small"
              trigger={this.renderAttachmentIcon(file.type)}
            >
              <Modal.Content>
                <video
                  muted
                  src={fileUrl}
                  controls
                  controlsList="nodownload"
                  disablePictureInPicture
                  style={{ maxWidth: "100%", height: 320 }}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="a"
                  compact
                  content="Download"
                  href={file.data ? null : `${fileUrl}?download=1`}
                  icon="download"
                  rel="noopener noreferrer"
                  secondary
                  size="small"
                  target="_blank"
                />
              </Modal.Actions>
            </Modal>
            {this.renderLink()}
          </React.Fragment>
        );
      case "docx":
      case "xlsx":
        return (
          <React.Fragment>
            {this.renderAttachmentIcon(file.type)}
            {this.renderLink()}
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            {this.renderAttachmentIcon(file.type)}
            {this.renderLink()}
          </React.Fragment>
        );
    }
  }

  render() {
    const { upload, loading } = this.props;
    const file = this.getFile();

    if (upload || loading || file.data) {
      return this.renderLink();
    }

    return (
      <div
        className={classnames(
          styles.attachmentContainer,
          this._isImage(file.type) && styles.imageAttachment
        )}
      >
        {this.renderFileViewer()}
      </div>
    );
  }
}

MessageAttachmentListItem.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      fileSize: PropTypes.number,
      data: PropTypes.shape({
        preview: PropTypes.string,
        size: PropTypes.number.isRequired,
        type: PropTypes.string,
      }),

      // Extra attributes
      progress: PropTypes.number,
      error: PropTypes.shape({
        detail: PropTypes.string,
        retry: PropTypes.bool,
      }),
    })
  ).isRequired,
  fileId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loading: PropTypes.bool,
  onRemove: PropTypes.func,
  onRetry: PropTypes.func,
  upload: PropTypes.bool,
  isCollapsed: PropTypes.bool,
};

MessageAttachmentListItem.defaultProps = {
  loading: false,
  onRemove: undefined,
  onRetry: undefined,
  upload: false,
  isCollapsed: false,
};
