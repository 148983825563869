/* eslint-disable react/no-did-update-set-state */

import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { SingleDatePicker } from "react-dates";
import { Button, Dropdown, Form, Modal } from "semantic-ui-react";

import styles from "./styles.module.scss";
import TimeDropdown from "../../TimeDropdown";

export default class DateTimeDropdown extends React.Component {
  constructor(props) {
    super(props);

    let value;
    if (props.value && !props.value.startsWith("duration")) {
      value = props.value;
    }

    this.state = {
      cachedValue: value,
      customDate: null,
      customTime: null,
      customValue: value,
      isCustomDateFocused: false,
      isModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (prevProps.value !== value && value && !value.startsWith("duration")) {
      this.setState({ cachedValue: value, customValue: value });
    }
  }

  getDropdownCustomOption() {
    const { customValue } = this.state;
    const defaultOptionText = "Custom";

    return {
      key: customValue || "custom",
      text: defaultOptionText,
      value: customValue || "custom",
    };
  }

  getDropdownOptions() {
    const { options } = this.props;

    return [...options, this.getDropdownCustomOption()];
  }

  getDropdownText() {
    const { value } = this.props;
    const option = this.getDropdownOptions().find((o) => o.value === value) || {};

    if (option.value === this.getDropdownCustomOption().value) {
      return moment(option.value).format("MMM D, YYYY | h:mma");
    }

    return option.text;
  }

  handleCustomDateChange = (date) => {
    this.setState({ customDate: date });
  };

  handleCustomDateFocused = ({ focused }) => {
    this.setState({ isCustomDateFocused: focused });
  };

  handleCustomTimeChange = (event, { value }) => {
    this.setState({ customTime: value });
  };

  handleInputChange = (event, data) => {
    if (data.value === this.getDropdownCustomOption().value) {
      this.modalOpen();
    } else {
      this.props.onChange(event, data);
    }
  };

  handleModalClose = () => {
    this.modalClose();
  };

  handleModalOpen = () => {
    this.modalOpen();
  };

  handleModalSet = (event) => {
    this.generateCustomDateTime(event);
    this.modalClose();
  };

  generateCustomDateTime = (event) => {
    const { name } = this.props;
    const { customDate, customTime } = this.state;

    if (customDate) {
      const customTimeMoment = moment(customTime || "00:00", "HH:mm");

      customDate.set({
        hour: customTimeMoment.get("hour"),
        minute: customTimeMoment.get("minute"),
      });

      const value = customDate.format();

      this.props.onChange(event, { name, value });
      this.setState({ cachedValue: value, customValue: value });
    }
  };

  modalClose = () => {
    this.setState({ isModalOpen: false });
  };

  modalOpen = () => {
    const { cachedValue } = this.state;

    let customDate;
    let customTime;
    if (cachedValue && !cachedValue.startsWith("duration")) {
      customDate = moment(cachedValue);
      customTime = customDate.format("HH:mm");
    }

    this.setState({ customDate, customTime, isModalOpen: true });
  };

  render() {
    const {
      className,
      disabled,
      modalTitle,
      mountNode,
      name,
      placeholder,
      value,
      ...passThroughProps
    } = this.props;
    const { customDate, customTime, isCustomDateFocused, isModalOpen } = this.state;

    return (
      <React.Fragment>
        <Modal
          className={styles.modal}
          dimmer
          mountNode={mountNode}
          onClose={this.handleModalClose}
          onOpen={this.handleModalOpen}
          open={isModalOpen}
        >
          <Modal.Header className={styles.header}>
            <h3 className={styles.headerTitle}>{modalTitle}</h3>
            <Button
              className={styles.closeButton}
              icon="close"
              onClick={this.handleModalClose}
              size="mini"
              title="Close"
            />
          </Modal.Header>

          <Modal.Content className={styles.content}>
            <Form>
              <div className={styles.customInput}>
                <SingleDatePicker
                  date={customDate}
                  displayFormat="MMM D, YYYY"
                  focused={isCustomDateFocused}
                  hideKeyboardShortcutsPanel
                  id="date"
                  numberOfMonths={1}
                  onFocusChange={this.handleCustomDateFocused}
                  onDateChange={this.handleCustomDateChange}
                  placeholder="Date"
                />

                <TimeDropdown
                  className={styles.customInputTime}
                  name="time"
                  onChange={this.handleCustomTimeChange}
                  placeholder="Time"
                  required
                  value={customTime}
                />
              </div>

              <Button
                className={styles.setButton}
                content="Set"
                fluid
                onClick={this.handleModalSet}
              />
            </Form>
          </Modal.Content>
        </Modal>

        <Dropdown
          {...passThroughProps}
          className={className}
          disabled={disabled}
          fluid
          name={name}
          onChange={this.handleInputChange}
          options={this.getDropdownOptions()}
          placeholder={placeholder}
          selection
          text={this.getDropdownText()}
          value={value}
        />
      </React.Fragment>
    );
  }
}

DateTimeDropdown.propTypes = {
  ...Dropdown.propTypes,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  modalTitle: PropTypes.string,
  mountNode: PropTypes.instanceOf(Element),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

DateTimeDropdown.defaultProps = {
  ...Dropdown.defaultProps,
  modalTitle: "Custom date/time",
  onBlur: () => {},
  onChange: () => {},
};
