import { mapKeys } from "lodash";

import {
  trackedClasses,
  GET_BUDGET_HISTORY,
  VERSION_COMMENT_ADD,
  VERSION_COMMENT_DELETE,
  VERSION_COMMENT_UPDATE,
} from "actions/log";

export default function projectBudget(
  state = {
    loading: false,
    success: true,
    failure: false,
    nextItemsUrls: {},
    items: {},
  },
  action
) {
  const { type } = action;
  switch (type) {
    case GET_BUDGET_HISTORY.REQUEST:
      return { ...state, loading: true, failure: false };
    case GET_BUDGET_HISTORY.SUCCESS: {
      const { projectId, response } = action;
      const itemsResponse = mapKeys(response.results, "id");
      const projBudget = state.items[projectId] ? { ...state.items[projectId] } : {};
      return {
        ...state,
        loading: false,
        failure: false,
        nextItemsUrls: {
          ...state.nextItemsUrls,
          [projectId]: response.nextPage,
        },
        items: {
          ...state.items,
          [projectId]: {
            ...projBudget,
            ...itemsResponse,
          },
        },
      };
    }
    case GET_BUDGET_HISTORY.FAILURE:
      return { ...state, loading: false, failure: true };
    case VERSION_COMMENT_ADD.SUCCESS: {
      const { response, trackedClass } = action;
      if (response.objectId && trackedClass === trackedClasses.budget) {
        const projBudget = state.items[response.objectId]
          ? { ...state.items[response.objectId] }
          : {};
        const version = projBudget[response.version]
          ? { ...projBudget[response.version] }
          : {
              comments: [],
            };
        version.comments.push(response);
        return {
          ...state,
          items: {
            ...state.items,
            [response.objectId]: {
              ...projBudget,
              [response.version]: version,
            },
          },
        };
      }
      return state;
    }
    case VERSION_COMMENT_DELETE.SUCCESS: {
      const { response, versionCommentId, trackedClass } = action;
      if (response.objectId && trackedClass === trackedClasses.budget) {
        const projBudget = state.items[response.objectId]
          ? { ...state.items[response.objectId] }
          : {};
        const version = projBudget[response.version]
          ? { ...projBudget[response.version] }
          : {
              comments: [],
            };
        return {
          ...state,
          items: {
            ...state.items,
            [response.objectId]: {
              ...projBudget,
              [response.version]: {
                ...version,
                comments: version.comments.filter((comment) => comment.id !== versionCommentId),
              },
            },
          },
        };
      }
      return state;
    }
    case VERSION_COMMENT_UPDATE.SUCCESS: {
      const { response, trackedClass } = action;
      if (response.objectId && trackedClass === trackedClasses.budget) {
        const projBudget = state.items[response.objectId]
          ? { ...state.items[response.objectId] }
          : {};
        const version = projBudget[response.version]
          ? { ...projBudget[response.version] }
          : {
              comments: [],
            };
        const updatedComments = version.comments.map((comment) => {
          if (comment.id === response.id) {
            return response;
          }
          return comment;
        });
        return {
          ...state,
          items: {
            ...state.items,
            [response.objectId]: {
              ...projBudget,
              [response.version]: {
                ...version,
                comments: updatedComments,
              },
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}
