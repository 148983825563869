import { arrayOf, bool, number, oneOf, shape, string } from "prop-types";

import { Roles } from "utils/constants/project";

import { dateString, dateTimeStringRequired } from ".";

export const roleType = oneOf(Roles.all);

export const projectInvitationType = shape({
  email: string,
  id: number.isRequired,
  inviteToken: string,
  isGeneric: bool,
  modified: dateTimeStringRequired,
  role: roleType.isRequired,
  status: oneOf(["accepted", "declined", "pending"]).isRequired,
  user: string,
});

export const projectType = shape({
  balance: number,
  client: string,
  clientDisplayName: string,
  endDate: dateString,
  gitlabProjectId: number,
  id: number.isRequired,
  invitations: arrayOf(projectInvitationType),
  isActive: bool.isRequired,
  links: arrayOf(number),
  memberships: arrayOf(number),
  name: string.isRequired,
  roomIds: arrayOf(number).isRequired,
  startDate: dateString,
  url: string.isRequired,
  gitlabUrl: string,
});

export const projectLinkType = shape({
  id: number.isRequired,
  name: string.isRequired,
  project: number.isRequired,
  url: string.isRequired,
});

export const projectMembershipType = shape({
  created: dateTimeStringRequired,
  dailyRate: number,
  daysOfWork: number,
  hoursSpent: string,
  id: number.isRequired,
  projectId: number.isRequired,
  role: roleType.isRequired,
  skills: arrayOf(string),
  teamProject: number,
  userName: string.isRequired,
  userSlug: string.isRequired,
  workCost: number,
});
