import { decamelizeKeys } from "humps";
import * as schema from "services/schema";

import { callApiWithToken } from "./base";

export const channelCreateOrUpdate = (token, projectId, channels) =>
  callApiWithToken(
    `projects/${projectId}/channels/`,
    token,
    null,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(channels))
  );

export const channelDelete = (token, projectId, channelId) =>
  callApiWithToken(`projects/${projectId}/channels/${channelId}/`, token, null, "DELETE");

export const getBudgetHistory = (token, projectId, url = null) =>
  callApiWithToken(url || `projects/${projectId}/log/`, token, null, "GET");

export const getSuggestedTimes = (token, data) =>
  callApiWithToken(`suggested_times/`, token, null, "POST", { "Content-Type": "application/json" }, JSON.stringify(decamelizeKeys(data)));

export const readBudgetHistory = (token, projectId) =>
  callApiWithToken(`projects/${projectId}/log/read/`, token, null, 'PATCH', {
    'Content-Type': 'application/json',
  });

export const invitationAdd = (token, projectId) =>
  callApiWithToken(`projects/${projectId}/invite/`, token, null, "POST");

export const invitationUpdate = (token, projectId, invitations) =>
  callApiWithToken(
    `projects/${projectId}/invitations/`,
    token,
    schema.projectSchema,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(invitations))
  );

export const linkCreateOrUpdate = (token, projectId, links) =>
  callApiWithToken(
    `projects/${projectId}/links/`,
    token,
    schema.projectSchema,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(links))
  );

export const linkDelete = (token, projectId, linkId) =>
  callApiWithToken(`projects/${projectId}/links/${linkId}/`, token, null, "DELETE");

export const membershipUpdate = (token, projectId, memberships) =>
  callApiWithToken(
    `projects/${projectId}/memberships/`,
    token,
    schema.projectMembershipSchemaArray,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(memberships))
  );

export const membershipDelete = (token, projectId, membershipId) =>
  callApiWithToken(`projects/${projectId}/memberships/${membershipId}/`, token, null, "DELETE");

export const projectDelete = (token, projectId) =>
  callApiWithToken(`projects/${projectId}/`, token, null, "DELETE");

export const loadTalentProjects = (token, slug) =>
  callApiWithToken(`users/${slug}/projects/`, token, schema.projectSchemaArray, "GET");

export const projectBudgetUpdate = (token, projectId, budget) =>
  callApiWithToken(
    `projects/${projectId}/budget/`,
    token,
    schema.projectBudgetSchemaArray,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(budget))
  );

export const sendBudgetReport = (token, projectId, params, graph) => callApiWithToken(
    `projects/${projectId}/send-budget-report/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys({"params": params, "graph": graph,})),
  )