import config from "config";

export const MESSAGES = {
  title: {
    bio: {
      id: "Profile.title.bio",
      defaultMessage: `${config.appName} - {name} Bio`,
    },
    work: {
      id: "Profile.title.work",
      defaultMessage: `${config.appName} - {name} Work`,
    },
    skills: {
      id: "Profile.title.skills",
      defaultMessage: `${config.appName} - {name} Skills`,
    },
    availability: {
      id: "Profile.title.availability",
      defaultMessage: `${config.appName} - {name} Availability`,
    },
    teams: {
      id: "Profile.title.teams",
      defaultMessage: `${config.appName} - {name} Teams`,
    },
    accounts: {
      id: "Profile.title.accounts",
      defaultMessage: `${config.appName} - {name} Linked Accounts`,
    },
    email: {
      id: "Profile.title.email",
      defaultMessage: `${config.appName} - {name} Email`,
    },
    notifications: {
      id: "Profile.title.notifications",
      defaultMessage: `${config.appName} - {name} Notifications`,
    },
    banking: {
      id: "Profile.title.banking",
      defaultMessage: `${config.appName} - {name} Banking`,
    },
    security: {
      id: "Profile.title.security",
      defaultMessage: `${config.appName} - {name} Security`,
    },
    settings: {
      id: "Profile.title.settings",
      defaultMessage: `${config.appName} - {name} Account Settings`,
    },
  },
  headers: {
    bio: {
      id: "Profile.headers.bio",
      defaultMessage: "Basic Information",
    },
    rates: {
      id: "Profile.headers.rates",
      defaultMessage: "Rates",
    },
    work: {
      id: "Profile.headers.work",
      defaultMessage: "Projects",
    },
    skills: {
      id: "Profile.headers.skills",
      defaultMessage: "Skills",
    },
    availability: {
      id: "Profile.headers.availability",
      defaultMessage: "Availability",
    },
    teams: {
      id: "Profile.headers.teams",
      defaultMessage: "Teams",
    },
    accounts: {
      id: "Profile.headers.accounts",
      defaultMessage: "Linked Accounts",
    },
    email: {
      id: "Profile.headers.email",
      defaultMessage: "Email",
    },
    notificationSound: {
      id: "Profile.headers.notificationSound",
      defaultMessage: "Sound",
    },
    notifications: {
      id: "Profile.headers.notifications",
      defaultMessage: "Notifications",
    },
    banking: {
      id: "Profile.headers.banking",
      defaultMessage: "Banking",
    },
    security: {
      id: "Profile.headers.security",
      defaultMessage: "Security",
    },
    changePassword: {
      id: "Profile.headers.changePassword",
      defaultMessage: "Change Password",
    },
    settings: {
      id: "Profile.headers.settings",
      defaultMessage: "Account Settings",
    },
    avatar: {
      id: "Profile.headers.avatar",
      defaultMessage: "Update your avatar",
    },
    notificationEmail: {
      id: "Profile.headers.notificationEmail",
      defaultMessage: "Email",
    },
    notificationWeb: {
      id: "Profile.headers.notificationWeb",
      defaultMessage: "Web",
    },
    display: {
      id: "Profile.headers.display",
      defaultMessage: "Display",
    },
    members: {
      id: "Profile.headers.members",
      defaultMessage: "Members",
    },
    about: {
      id: "Profile.headers.about",
      defaultMessage: "About",
    },
    subscription: {
      id: "Profile.headers.subscription",
      defaultMessage: "Subscription",
    },
  },
  availability: {
    available: {
      id: "Profile.available",
      defaultMessage: "Availability",
    },
    meeting: {
      id: "Profile.meeting",
      defaultMessage: "Meeting",
    },
    event: {
      id: "Profile.event",
      defaultMessage: "Event",
    },
    outOfOffice: {
      id: "Profile.outOfOffice",
      defaultMessage: "Out of Office",
    },
    busy: {
      id: "Profile.busy",
      defaultMessage: "Busy",
    },
    isAvailable: {
      id: "Profile.isAvailable",
      defaultMessage: "Available",
    },
    projectChannel: {
      id: "Profile.projectChannel",
      defaultMessage: "Project",
    },
    videoConferenceUrl: {
      id: "Profile.availability.videoConferenceUrl",
      defaultMessage: "Video Conference URL",
    },
    calendar: {
      id: "Profile.availability.calendar",
      defaultMessage: "What days/hours are you available to work",
    },
    marketplace: {
      id: "Profile.availability.marketplace",
      defaultMessage: "Do you want to make your profile available on the marketplace",
    },
    workOptions: {
      id: "Profile.availability.workOptions",
      defaultMessage: "How do you want to work",
    },
    isFullTime: {
      id: "Profile.availability.isFullTime",
      defaultMessage: "Full time",
    },
    isPartTime: {
      id: "Profile.availability.isPartTime",
      defaultMessage: "Part time",
    },
    isContractual: {
      id: "Profile.availability.isContractual",
      defaultMessage: "Contractual",
    },
    calendarSettings: {
      id: "Profile.availability.calendarSettings",
      defaultMessage: "Calendar settings",
    },
    setDefaultDays: {
      id: "Profile.availability.setDefaultDays",
      defaultMessage: "Set your default working days",
    },
    setDefaultHours: {
      id: "Profile.availability.setDefaultHours",
      defaultMessage: "Set your default work hours",
    },
    setTimezone: {
      id: "Profile.availability.setTimezone",
      defaultMessage: "Set your timezone",
    },
    selectCalendar: {
      id: "Profile.availability.googleCalendar",
      defaultMessage: "Select Calendar",
    },
    googleCalendar: {
      id: "Profile.availability.googleCalendar",
      defaultMessage: "Google",
    },
    outlookCalendar: {
      id: "Profile.availability.outlookCalendar",
      defaultMessage: "Outlook",
    },
    syncCalendar: {
      id: "Profile.availability.syncCalendar",
      defaultMessage: "Connect to Google",
    },
    syncCalendarMessageContent: {
      id: "Profile.availability.syncCalendarMessageContent",
      defaultMessage: "Syncing",
    },
    addSyncCalendar: {
      id: "Profile.availability.addSyncCalendar",
      defaultMessage: "Add calendars",
    },
    connectAccount: {
      id: "Profile.availability.connectAccount",
      defaultMessage: "Accept and connect to Google",
    },
    disconnectAccount: {
      id: "Profile.availability.disconnectAccount",
      defaultMessage: "Disconnect",
    },
    syncOutlookCalendar: {
      id: "Profile.availability.syncOutlookCalendar",
      defaultMessage: "Connect to Outlook",
    },
    connectOutlookAccount: {
      id: "Profile.availability.connectOutlookAccount",
      defaultMessage: "Accept and connect to Outlook",
    },
    accounts: {
      id: "Profile.availability.accounts",
      defaultMessage: "Accounts",
    },
    types: {
      id: "Profile.availability.types",
      defaultMessage: "Availability Types",
    },
    calendarView: {
      id: "Profile.availability.calendarView",
      defaultMessage: "Calendar view",
    },
    setAvailability: {
      id: "Profile.availability.setAvailability",
      defaultMessage: "Set your availability",
    },
    title: {
      id: "Profile.availability.title",
      defaultMessage: "Title",
    },
    setDate: {
      id: "Profile.availability.setDate",
      defaultMessage: "Date",
    },
    setTime: {
      id: "Profile.availability.setTime",
      defaultMessage: "Date and Time",
    },
    recurrence: {
      id: "Profile.availability.recurrence",
      defaultMessage: "Recurrence",
    },
    description: {
      id: "Profile.availability.description",
      defaultMessage: "Description",
    },
    billable: {
      id: "Profile.availability.billable",
      defaultMessage: "Billable",
    },
    addGuests: {
      id: "Profile.availability.addGuests",
      defaultMessage: "Add Guests",
    },
    moreOptions: {
      id: "Profile.availability.moreOptions",
      defaultMessage: "More options",
    },
    cancelEvent: {
      id: "Profile.availability.cancelEvent",
      defaultMessage: "Cancel Event",
    },
    cancel: {
      id: "Profile.availability.cancel",
      defaultMessage: "Cancel",
    },
    remove: {
      id: "Profile.availability.remove",
      defaultMessage: "Remove",
    },
    allDay: {
      id: "Profile.availability.allDay",
      defaultMessage: "All day",
    },
    noRepeat: {
      id: "Profile.availability.noRepeat",
      defaultMessage: "Does not repeat",
    },
    daily: {
      id: "Profile.availability.daily",
      defaultMessage: "Daily",
    },
    weekly: {
      id: "Profile.availability.weekly",
      defaultMessage: "Weekly",
    },
    monthly: {
      id: "Profile.availability.monthly",
      defaultMessage: "Monthly",
    },
    setTeamVisibility: {
      id: "Profile.availability.setTeamVisibility",
      defaultMessage: "Visibility",
    },
    setTeamVisibilityDesc: {
      id: "Profile.availability.setTeamVisibilityDesc",
      defaultMessage: "Set the visibility of this schedule for specific teams and projects",
    },
    setTeamAll: {
      id: "Profile.availability.setTeamAll",
      defaultMessage: "All teams and projects",
    },
    setMarketplace: {
      id: "Profile.availability.setMarketplace",
      defaultMessage: "Marketplace",
    },
    setMarketplaceVisibility: {
      id: "Profile.availability.setMarketplaceVisibility",
      defaultMessage: "Make your profile searchable for work opportunities",
    },
    setInstantBook: {
      id: "Profile.availability.setInstantBook",
      defaultMessage: "Make yourself available for Instant Booking",
    },
    mobility: {
      id: "Profile.availability.mobility",
      defaultMessage: "Mobility",
    },
    onSiteRemoteOption: {
      id: "Profile.availability.onSiteRemoteOption",
      defaultMessage: "On-site or remote",
    },
    onSiteOption: {
      id: "Profile.availability.onSiteOption",
      defaultMessage: "On-site only",
    },
    remoteOption: {
      id: "Profile.availability.remoteOption",
      defaultMessage: "Remote only",
    },
    onSiteRemoteDisplay: {
      id: "Profile.availability.onSiteRemoteDisplay",
      defaultMessage: "On-site/Remote",
    },
    onSiteDisplay: {
      id: "Profile.availability.onSiteDisplay",
      defaultMessage: "On-site",
    },
    remoteDisplay: {
      id: "Profile.availability.remoteDisplay",
      defaultMessage: "Remote",
    },
    workLog: {
      id: "Profile.availability.workLog",
      defaultMessage: "Work Log",
    },
    workType: {
      id: "Profile.availability.workType",
      defaultMessage: "Work Type",
    },
    unavailableForWork: {
      id: "Profile.availability.unavailableForWork",
      defaultMessage: "Unavailable for work",
    },
    activity: {
      id: "Profile.availability.activity",
      defaultMessage: "Activity",
    },
    hours: {
      id: "Profile.availability.hours",
      defaultMessage: "Hours",
      totalHours: {
        id: "Profile.availability.hours.totalHours",
        defaultMessage: "Total hours",
      },
      meetingHours: {
        id: "Profile.availability.hours.meetingHours",
        defaultMessage: "Meeting hours",
      },
      vacationHours: {
        id: "Profile.availability.hours.vacationHours",
        defaultMessage: "Vacation hours",
      },
      taskHours: {
        id: "Profile.availability.hours.taskHours",
        defaultMessage: "Task hours",
      },
      trainingHours: {
        id: "Profile.availability.hours.trainingHours",
        defaultMessage: "Training hours",
      },
    },
    issueUrl: {
      id: "Profile.availability.issueUrl",
      defaultMessage: "Issue URL",
    },
    videoCall: {
      id: "Profile.availability.videoCall",
      defaultMessage: "Video Call",
    },
    record: {
      day: {
        id: "Profile.availability.record.day",
        defaultMessage: "Daily Hours Summary",
      },
      week: {
        id: "Profile.availability.record.week",
        defaultMessage: "Weekly Hours Summary",
      },
      month: {
        id: "Profile.availability.record.week",
        defaultMessage: "Monthly Hours Summary",
      },
    },
  },
  contactInfo: {
    id: "Profile.contactInfo",
    defaultMessage: "Contact Info",
  },
  education: {
    id: "Profile.education",
    defaultMessage: "Education",
  },
  employment: {
    id: "Profile.employment",
    defaultMessage: "Employment history",
  },
  otherInfo: {
    id: "Profile.otherInfo",
    defaultMessage: "Gender, birthday and more",
  },
  hideContactInfo: {
    id: "Profile.hideContactInfo",
    defaultMessage: "Make your contact information private",
  },
  number: {
    id: "Profile.number",
    defaultMessage: "Phone Number",
  },
  location: {
    id: "Profile.location",
    defaultMessage: "Location",
  },
  city: {
    id: "Profile.city",
    defaultMessage: "City",
  },
  country: {
    id: "Profile.country",
    defaultMessage: "Country",
  },
  email: {
    id: "Profile.email",
    defaultMessage: "Work Email",
  },
  update: {
    id: "Profile.update",
    defaultMessage: "Update Profile…",
  },
  updateAvatar: {
    id: "Profile.update.avatar",
    defaultMessage: "Save Avatar",
  },
  save: {
    id: "Profile.save",
    defaultMessage: "Save",
  },
  saveChanges: {
    id: "Profile.saveChanges",
    defaultMessage: "Save Changes",
  },
  cancel: {
    id: "Profile.cancel",
    defaultMessage: "Cancel",
  },
  add: {
    id: "Profile.add",
    defaultMessage: "Add",
  },
  addToCalendar: {
    id: "Profile.addToCalendar",
    defaultMessage: "Add to calendar",
  },
  addAndCreateAnother: {
    id: "Profile.addAndCreateAnother",
    defaultMessage: "Add and create another log",
  },
  delete: {
    id: "Profile.delete",
    defaultMessage: "Delete",
  },
  firstName: {
    id: "Profile.firstName",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "Profile.lastName",
    defaultMessage: "Last Name",
  },
  description: {
    id: "Profile.description",
    defaultMessage: "Description",
  },
  gender: {
    id: "Profile.gender",
    defaultMessage: "Gender",
  },
  birthday: {
    id: "Profile.birthday",
    defaultMessage: "Birth Date",
  },
  hours: {
    id: "Profile.hours",
    defaultMessage: "hours",
  },
  degree: {
    id: "Profile.educ.degree",
    defaultMessage: "Degree",
  },
  gradYear: {
    id: "Profile.educ.gradYear",
    defaultMessage: "To Year",
  },
  schoolName: {
    id: "Profile.educ.schoolName",
    defaultMessage: "School",
  },
  awards: {
    id: "Profile.educ.awards",
    defaultMessage: "Awards",
  },
  companyName: {
    id: "Profile.employment.companyName",
    defaultMessage: "Company Name",
  },
  startYear: {
    id: "Profile.employment.startYear",
    defaultMessage: "Start Year",
  },
  endYear: {
    id: "Profile.employment.endYear",
    defaultMessage: "End Year",
  },
  firstFifteenMinsRate: {
    id: "Profile.work.firstFifteenMinsRate",
    defaultMessage: "First Fifteen Minutes",
  },
  hourlyRate: {
    id: "Profile.work.hourlyRate",
    defaultMessage: "Hourly",
  },
  dailyRate: {
    id: "Profile.work.dailyRate",
    defaultMessage: "Daily",
  },
  suggestedRates: {
    id: "Profile.work.suggestedRates",
    defaultMessage: "Benchmark Rate",
  },
  askingRates: {
    id: "Profile.work.askingRates",
    defaultMessage: "Your Rate",
  },
  workedSkills: {
    id: "Profile.work.skills",
    defaultMessage: "Skills",
  },
  workedHours: {
    id: "Profile.work.workedHours",
    defaultMessage: "Worked hours",
  },
  workedWith: {
    id: "Profile.work.workedWith",
    defaultMessage: "Other people worked with",
  },
  sampleQuestions: {
    id: "Profile.skills.sampleQuestions",
    defaultMessage: "Sample questions created for this skill, or sample projects",
  },
  people: {
    id: "Profile.teams.people",
    defaultMessage: "People",
  },
  work: {
    id: "Profile.teams.work",
    defaultMessage: "Work",
  },
  topSkills: {
    id: "Profile.teams.topSkills",
    defaultMessage: "Top skills",
  },
  metrics: {
    id: "Profile.teams.metrics",
    defaultMessage: "Metrics",
  },
  categoryName: {
    id: "Profile.linkedAccounts.categoryName",
    defaultMessage: "Category name",
  },
  accountName: {
    id: "Profile.linkedAccounts.accountName",
    defaultMessage: "Account name",
  },
  accountUrl: {
    id: "Profile.linkedAccounts.accountUrl",
    defaultMessage: "Account url",
  },
  online: {
    id: "Profile.online",
    defaultMessage: "Online",
  },
  offline: {
    id: "Profile.offline",
    defaultMessage: "Offline",
  },
  addSkill: {
    id: "Profile.addSkill",
    defaultMessage: "Add Skill",
  },
  filterSkill: {
    id: "Profile.filterSkill",
    defaultMessage: "Filter skills",
  },
  selectSkill: {
    id: "Profile.selectSkill",
    defaultMessage: "Select a skill to add",
  },
  selectAvatar: {
    id: "Profile.selectAvatar",
    defaultMessage: "Click to change avatar",
  },
  editName: {
    id: "Profile.editName",
    defaultMessage: "Click to edit name",
  },
  notGiven: {
    id: "Profile.notGiven",
    defaultMessage: "Not given",
  },
  private: {
    id: "Profile.private",
    defaultMessage: "Only you can see this information.",
  },
  notSaved: {
    id: "Profile.notSaved",
    defaultMessage:
      "Your profile changes were not saved. Please review your details below, then try again.",
  },
  teamNotSaved: {
    id: "Profile.teamNotSaved",
    defaultMessage:
      "Your team profile changes were not saved. Please review your details below, then try again.",
  },
  notLegal: {
    id: "Profile.notLegal",
    defaultMessage:
      "For legal reasons, only those over the age of 18 years can offer their services on the Marketplace",
  },
  softSkills: {
    id: "Profile.softSkills",
    defaultMessage: "Soft Skills",
  },
  hardSkills: {
    id: "Profile.hardSkills",
    defaultMessage: "Hard Skills",
  },
  skills: {
    id: "Profile.skills",
    defaultMessage: "Skills",
  },
  members: {
    id: "Profile.members",
    defaultMessage: "Members",
  },
  addSkillWiz: {
    header: {
      id: "Profile.addSkillWiz.header",
      defaultMessage: "Add hard skills you want to be known for and improve on",
    },
    select: {
      id: "Profile.addSkillWiz.select",
      defaultMessage: "Select Skill",
    },
    rate: {
      id: "Profile.addSkillWiz.rate",
      defaultMessage: "Rate Skill",
    },
    review: {
      id: "Profile.addSkillWiz.review",
      defaultMessage: "Review",
    },
    quicklyAdd: {
      id: "Profile.addSkillWiz.quicklyAdd",
      defaultMessage: "Quickly add hard skills",
    },
    typeSkill: {
      id: "Profile.addSkillWiz.typeSkill",
      defaultMessage: "Type a skill...",
    },
    manualAdd: {
      id: "Profile.addSkillWiz.manualAdd",
      defaultMessage: "Or manually select from our curated list",
    },
    continue: {
      id: "Profile.addSkillWiz.continue",
      defaultMessage: "Continue",
    },
    done: {
      id: "Profile.addSkillWiz.done",
      defaultMessage: "Done",
    },
    back: {
      id: "Profile.addSkillWiz.back",
      defaultMessage: "Back to select skill",
    },
    selfAssess: {
      id: "Profile.addSkillWiz.selfAssess",
      defaultMessage: "Please assess your skill level on:",
    },
    skillSelected: {
      id: "Profile.addSkillWiz.skillSelected",
      defaultMessage: "Skills selected",
    },
  },
  defaultAvatar: {
    id: "Profile.defaultAvatar",
    defaultMessage: "Default Avatar",
  },
  fontColor: {
    id: "Profile.fontColor",
    defaultMessage: "Font Color",
  },
  backgroundColor: {
    id: "Profile.backgroundColor",
    defaultMessage: "Background Color",
  },
  setAvatarImage: {
    id: "Profile.setAvatarImage",
    defaultMessage: "Set picture",
  },
  setAvatarColors: {
    id: "Profile.setAvatarColors",
    defaultMessage: "Set colors",
  },
  changePassword: {
    oldPassword: {
      id: "Profile.changePassword.oldPassword",
      defaultMessage: "Old Password",
    },
    newPassword1: {
      id: "Profile.changePassword.newPassword1",
      defaultMessage: "New Password",
    },
    newPassword2: {
      id: "Profile.changePassword.newPassword2",
      defaultMessage: "Confirm New Password",
    },
    updatePassword: {
      id: "Profile.changePassword.updatePassword",
      defaultMessage: "Update Password",
    },
  },
  basicInformation: {
    personalInfo: {
      id: "Profile.basicInformation.personalInfo",
      defaultMessage: "Personal Information",
    },
    contactInfo: {
      id: "Profile.basicInformation.contactInfo",
      defaultMessage: "Contact Information",
    },
    others: {
      id: "Profile.basicInformation.others",
      defaultMessage: "Others",
    },
    workExp: {
      id: "Profile.basicInformation.workExp",
      defaultMessage: "Work Experience",
    },
    education: {
      id: "Profile.basicInformation.education",
      defaultMessage: "Education",
    },
    dateJoined: {
      id: "Profile.basicInformation.dateJoined",
      defaultMessage: "Date Joined",
    },
    rates: {
      id: "Profile.basicInformation.rates",
      defaultMessage: "Rates",
    },
    publications: {
      id: "Profile.basicInformation.publications",
      defaultMessage: "Publications",
    },
    industries: {
      id: "Profile.basicInformation.industries",
      defaultMessage: "Industries",
    },
  },
  profileUpdate: {
    header: {
      id: "Profile.profileUpdate.header",
      defaultMessage: "Edit Profile",
    },
    close: {
      id: "Profile.profileUpdate.close",
      defaultMessage: "Close",
    },
    personalInfo: {
      id: "Profile.profileUpdate.personalInfo",
      defaultMessage: "Personal Information",
    },
    basicInfo: {
      id: "Profile.profileUpdate.basicInfo",
      defaultMessage: "Basic information",
    },
    education: {
      id: "Profile.profileUpdate.education",
      defaultMessage: "Education",
    },
    workExp: {
      id: "Profile.profileUpdate.workExp",
      defaultMessage: "Work Experience",
    },
    miscellaneous: {
      id: "Profile.profileUpdate.miscellaneous",
      defaultMessage: "Miscellaneous",
    },
    publications: {
      id: "Profile.profileUpdate.publications",
      defaultMessage: "Publications",
    },
    links: {
      id: "Profile.profileUpdate.links",
      defaultMessage: "Links",
    },
    schoolLocation: {
      id: "Profile.profileUpdate.schoolLocation",
      defaultMessage: "School Location",
    },
    schoolFieldOfStudy: {
      id: "Profile.profileUpdate.schoolFieldOfStudy",
      defaultMessage: "Field of study",
    },
    month: {
      id: "Profile.profileUpdate.month",
      defaultMessage: "Month",
    },
    year: {
      id: "Profile.profileUpdate.year",
      defaultMessage: "Year",
    },
    startInfo: {
      id: "Profile.profileUpdate.startInfo",
      defaultMessage: "From Year/Month",
    },
    startYear: {
      id: "Profile.profileUpdate.startYear",
      defaultMessage: "From Year",
    },
    endInfo: {
      id: "Profile.profileUpdate.endInfo",
      defaultMessage: "To Year/Month",
    },
    endYear: {
      id: "Profile.profileUpdate.endYear",
      defaultMessage: "To Year",
    },
    description: {
      id: "Profile.profileUpdate.description",
      defaultMessage: "Description",
    },
    position: {
      id: "Profile.profileUpdate.position",
      defaultMessage: "Position",
    },
    role: {
      id: "Profile.profileUpdate.role",
      defaultMessage: "Role",
    },
    company: {
      id: "Profile.profileUpdate.company",
      defaultMessage: "Company",
    },
    companyName: {
      id: "Profile.profileUpdate.companyName",
      defaultMessage: "Company Name",
    },
    companyLocation: {
      id: "Profile.profileUpdate.companyLocation",
      defaultMessage: "Company Location",
    },
    location: {
      id: "Profile.profileUpdate.location",
      defaultMessage: "City",
    },
    country: {
      id: "Profile.profileUpdate.country",
      defaultMessage: "Country",
    },
    updateSuccess: {
      id: "Profile.profileUpdate.updateSuccess",
      defaultMessage: "Profile has been updated.",
    },
    aboutUpdate: {
      id: "Profile.profileUpdate.aboutUpdate",
      defaultMessage: "About page has been updated.",
    },
    marketplaceApplicationSuccess: {
      id: "Profile.profileUpdate.marketplaceApplicationSuccess",
      defaultMessage:
        "Profile has been updated. Your application for the marketplace is being reviewed. Please allow us 24 hours to approve.",
    },
    marketplace: {
      id: "Profile.profileUpdate.marketplace",
      defaultMessage: "Marketplace",
    },
    visibility: {
      id: "Profile.profileUpdate.visibility",
      defaultMessage: "Visibility",
    },
    hideProfile: {
      id: "Profile.profileUpdate.hideProfile",
      defaultMessage: "Make my profile available on the Marketplace",
    },
    about: {
      id: "Profile.profileUpdate.about",
      defaultMessage:
        "Use this section to describe yourself and showcase things - featured work, publications, etc. - that you want people to know at a glance...",
    },
    ratesPrivacy: {
      id: "Profile.profileUpdate.ratesPrivacy",
      defaultMessage: "Keep my rates private",
    },
    ratesPrivacyInfo: {
      id: "Profile.profileUpdate.ratesPrivacyInfo",
      defaultMessage: "People and teams who are upfront about their rates attract more job offers.",
    },
    title: {
      id: "Profile.profileUpdate.title",
      defaultMessage: "Title",
    },
    link: {
      id: "Profile.profileUpdate.link",
      defaultMessage: "Link",
    },
    updateError: {
      id: "Profile.profileUpdate.updateError",
      defaultMessage: "Changes to the account were not saved.",
    },
    shortBio: {
      id: "Profile.profileUpdate.shortBio",
      defaultMessage: "About me",
    },
    profileError: {
      id: "Profile.profileUpdate.profileError",
      defaultMessage: "Changes to the profile were not saved.",
    },
    linkedAccountCategory: {
      id: "Profile.profileUpdate.linkedAccountCategory",
      defaultMessage: "Category",
    },
    linkedAccountUrl: {
      id: "Profile.profileUpdate.linkedAccountUrl",
      defaultMessage: "Account URL",
    },
    linkedAccounts: {
      id: "Profile.profileUpdate.linkedAccounts",
      defaultMessage: "Links",
    },
    hourlyRateInfo: {
      id: "Profile.profileUpdate.hourlyRateInfo",
      defaultMessage:
        "Set a range of typical hourly rates for an individual member of your team, eg. $50-$150",
    },
    dailyRateInfo: {
      id: "Profile.profileUpdate.dailyRateInfo",
      defaultMessage:
        "Set a range of typical daily rates for an individual member of your team, eg. $250-$750",
    },
    requiredFields: {
      id: "Profile.profileUpdate.requiredFields",
      defaultMessage: "Please provide required fields.",
    },
    addDescription: {
      id: "Profile.profileUpdate.addDescription",
      defaultMessage: "Add description...",
    },
    archiveProjectButtonTitle: {
      id: "Profile.profileUpdate.archiveProjectButtonTitle",
      defaultMessage: "Archive Project",
    },
    archiveProjectPlaceholder: {
      id: "Profile.profileUpdate.archiveProjectPlaceholder",
      defaultMessage: "Project name",
    },
    industry: {
      id: "Profile.profileUpdate.industry",
      defaultMessage: "Industry",
    },
    industries: {
      id: "Profile.profileUpdate.industries",
      defaultMessage: "Industries",
    },
  },
  teamProfile: {
    header: {
      id: "Profile.teamProfile.header",
      defaultMessage: "Edit Team Profile",
    },
    basicInformation: {
      id: "Profile.teamProfile.basicInformation",
      defaultMessage: "Basic Information",
    },
    name: {
      id: "Profile.teamProfile.name",
      defaultMessage: "Name",
    },
    description: {
      id: "Profile.teamProfile.description",
      defaultMessage: "Description",
    },
    organizationName: {
      id: "Profile.teamProfile.organizationName",
      defaultMessage: "Organization Name",
    },
    fifteenMinRate: {
      id: "Profile.teamProfile.fifteenMinRate",
      defaultMessage: "Fifteen-minute Rate",
    },
    hourlyRate: {
      id: "Profile.teamProfile.hourlyRate",
      defaultMessage: "Hourly Rate",
    },
    dailyRate: {
      id: "Profile.teamProfile.dailyRate",
      defaultMessage: "Daily Rate",
    },
    invitationSent: {
      id: "Profile.teamProfile.invitationSent",
      defaultMessage: "Invitation(s) sent.",
    },
    members: {
      id: "Profile.teamProfile.members",
      defaultMessage: "Members",
    },
    invitee: {
      id: "Profile.teamProfile.invitee",
      defaultMessage: "Pending Invite",
    },
    invitees: {
      id: "Profile.teamProfile.invitees",
      defaultMessage: "Pending Invites",
    },
    marketplace: {
      id: "Profile.teamProfile.marketplace",
      defaultMessage: "Marketplace",
    },
    rates: {
      id: "Profile.teamProfile.rates",
      defaultMessage: "Rates",
    },
    visibility: {
      id: "Profile.teamProfile.visibility",
      defaultMessage: "Visibility",
    },
    hideProfile: {
      id: "Profile.teamProfile.hideProfile",
      defaultMessage: "Make this team available on the Marketplace",
    },
    editMembersHeader: {
      id: "Profile.teamProfile.editMembersHeader",
      defaultMessage: "Edit Members",
    },
    updateMembersSuccess: {
      id: "Profile.teamProfile.updateMembersSuccess",
      defaultMessage: "Members have been updated.",
    },
    featuredSkills: {
      id: "Profile.teamProfile.featuredSkills",
      defaultMessage: "Featured Skills",
    },
    hourlyRateMin: {
      id: "Profile.teamProfile.hourlyRateMin",
      defaultMessage: "Minimum hourly rate",
    },
    hourlyRateMax: {
      id: "Profile.teamProfile.hourlyRateMax",
      defaultMessage: "Maximum hourly rate",
    },
    dailyRateMin: {
      id: "Profile.teamProfile.dailyRateMin",
      defaultMessage: "Minimum daily rate",
    },
    dailyRateMax: {
      id: "Profile.teamProfile.dailyRateMax",
      defaultMessage: "Maximum daily rate",
    },
    hourlyRateInfo: {
      id: "Profile.teamProfile.hourlyRateInfo",
      defaultMessage:
        "This is a range of typical hourly rates for an individual member of this team.",
    },
    dailyRateInfo: {
      id: "Profile.teamProfile.dailyRateInfo",
      defaultMessage:
        "This is a range of typical daily rates for an individual member of this team.",
    },
    addMembers: {
      id: "Profile.teamProfile.addMembers",
      defaultMessage: "Add member",
    },
    sendInvite: {
      id: "Profile.teamProfile.sendInvite",
      defaultMessage: "Send Invite",
    },
  },
  skillsUpdate: {
    header: {
      id: "Profile.skillsUpdate.header",
      defaultMessage: "Edit Skills",
    },
    editBtn: {
      id: "Profile.skillsUpdate.editBtn",
      defaultMessage: "Edit skills",
    },
    updateSuccess: {
      id: "Profile.skillsUpdate.updateSuccess",
      defaultMessage: "Skills have been updated.",
    },
  },
  userProfile: {
    marketPreview: {
      id: "Profile.userProfile.marketPreview",
      defaultMessage: "This is your marketplace profile.",
    },
    profilePreview: {
      id: "Profile.userProfile.profilePreview",
      defaultMessage: "This is your profile.",
    },
    teamPreview: {
      id: "Profile.userProfile.teamPreview",
      defaultMessage: "This is your team profile.",
    },
    viewMarketProfile: {
      id: "Profile.userProfile.viewMarketProfile",
      defaultMessage: "View marketplace profile",
    },
    viewProfile: {
      id: "Profile.userProfile.viewProfile",
      defaultMessage: "View profile",
    },
    viewTeamProfile: {
      id: "Profile.userProfile.viewTeamProfile",
      defaultMessage: "View team profile",
    },
    availability: {
      id: "Profile.userProfile.availability",
      defaultMessage: "Availability",
    },
    mobility: {
      id: "Profile.userProfile.mobility",
      defaultMessage: "Mobility",
    },
    dailyRate: {
      id: "Profile.userProfile.dailyRate",
      defaultMessage: "Daily Rate",
    },
  },
  messages: {
    errorLoadingUser: {
      id: "AccountSettings.warnings.errorLoadingUser",
      defaultMessage: "Unable to load User at this time. Please try again later.",
    },
    unsubscribeHealthReminder: {
      id: "AccountSettings.warnings.unsubscribeHealthReminder",
      defaultMessage: "You have successfully unsubscribed from daily health reminder emails.",
    },
  },
  accountSettings: {
    notifications: {
      id: "Profile.accountSettings.notifications",
      defaultMessage: "Display & Notifications",
    },
    accountInfo: {
      id: "Profile.accountSettings.accountInfo",
      defaultMessage: "Account Information",
    },
    password: {
      id: "Profile.accountSettings.password",
      defaultMessage: "Password",
    },
    updateSuccess: {
      id: "Profile.accountSettings.updateSuccess",
      defaultMessage: "Account has been updated.",
    },
    featuredSkills: {
      id: "Profile.accountSettings.featuredSkills",
      defaultMessage: "Featured Skills",
    },
    phoneNumbers: {
      id: "Profile.accountSettings.phoneNumbers",
      defaultMessage: "Phone Numbers",
    },
    updatePhoneNumbers: {
      id: "Profile.accountSettings.updatePhoneNumbers",
      defaultMessage: "Phone numbers were updated.",
    },
    primary: {
      id: "Profile.accountSettings.primary",
      defaultMessage: "Primary?",
    },
    subscription: {
      id: "Profile.accountSettings.subscription",
      defaultMessage: "Subscription",
    },
  },
  fieldError: {
    featuredSkillsMax: {
      id: "Profile.fieldError.featuredSkillsMax",
      defaultMessage: "You can only select up to four (4) skills.",
    },
    phoneNumber: {
      id: "Profile.fieldError.phoneNumber",
      defaultMessage: "Something went wrong. Failed to update phone numbers.",
    },
  },
  deleteObject: {
    teamButtonTitle: {
      id: "Profile.deleteObject.teamButtonTitle",
      defaultMessage: "Delete this team",
    },
    teamHeader: {
      id: "Profile.deleteObject.teamHeader",
      defaultMessage: "Delete team",
    },
    teamPlaceholder: {
      id: "Profile.deleteObject.teamPlaceholder",
      defaultMessage: "Team name",
    },
    delete: {
      id: "Profile.deleteObject.delete",
      defaultMessage: "Delete",
    },
    accountButtonTitle: {
      id: "Profile.deleteObject.accountButtonTitle",
      defaultMessage: "Delete this account",
    },
    accountHeader: {
      id: "Profile.deleteObject.accountHeader",
      defaultMessage: "Delete account",
    },
    accountPlaceholder: {
      id: "Profile.deleteObject.accountPlaceholder",
      defaultMessage: "Account name",
    },
    accountDeleting: {
      id: "Profile.deleteObject.accountDeleting",
      defaultMessage: "Account is being deleted...",
    },
    accountError: {
      id: "Profile.deleteObject.accountError",
      defaultMessage: "Something went wrong. Failed to delete the account.",
    },
  },
  search: {
    filterSkills: {
      id: "Profile.search.filterSkills",
      defaultMessage: "Search or filter skills",
    },
    filterMembers: {
      id: "Profle.search.filterMembers",
      defaultMessage: "Search or filter members",
    },
    addMembers: {
      id: "Profile.search.addMembers",
      defaultMessage: "Search a name or add via email",
    },
    filterTeams: {
      id: "Profile.search.filterTeams",
      defaultMessage: "Search or filter teams",
    },
    filterProjects: {
      id: "Profile.search.filterProjects",
      defaultMessage: "Search or filter projects",
    },
  },
  notifications: {
    messageSound: {
      id: "Profile.notifications.messageSound",
      defaultMessage: "Play sound when new user messages arrive",
    },
    systemMessageSound: {
      id: "Profile.notifications.systemMessageSound",
      defaultMessage: "Play sound when new system messages arrive",
    },
    callSound: {
      id: "Profile.notifications.callSound",
      defaultMessage: "Play sound when someone calls",
    },
    messagesEmailNotif: {
      id: "Profile.notifications.messagesEmailNotif",
      defaultMessage: "Always get email notifications for messages",
    },
    messagesEmailNotifInfo: {
      id: "Profile.notifications.messagesEmailNotifInfo",
      defaultMessage:
        "If you turn this off, you will still get email notifications for unread messages.",
    },
    unreadMessages: {
      id: "Profile.notifications.unreadMessages",
      defaultMessage: "Get email notifications for unread messages",
    },
    dailyHealthEmail: {
      id: "Profile.notifications.dailyHealthEmail",
      defaultMessage: "Enable daily health reminder email",
    },
    callStartedEmail: {
      id: "Profile.notifications.callStartedEmail",
      defaultMessage: "Enable call started emails",
    },
    projectInviteEmail: {
      id: "Profile.notifications.projectInviteEmail",
      defaultMessage: "Enable project invitation emails",
    },
    teamInviteEmail: {
      id: "Profile.notifications.teamInviteEmail",
      defaultMessage: "Enable team invitation emails",
    },
    dailyHealthAppReminder: {
      id: "Profile.notifications.dailyHealthAppReminder",
      defaultMessage: "Enable daily health reminder within app",
    },
  },
  display: {
    channelDisplay: {
      id: "Profile.display.channelDisplay",
      defaultMessage: "Channel Display",
    },
  },
  industry: {
    id: "Profile.ind.industry",
    defaultMessage: "Industry",
  },
};
