import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Icon, Header, Modal, Table } from "semantic-ui-react";

import { shortcutsHelpModal } from "actions/modals";
import config from "config";
import { getPlatformName } from "utils/platform";
import keyMap from "utils/keyMap";

import * as styles from "./styles.module.scss";

function renderKey(key) {
  switch (key) {
    case "up":
      return <span>&uarr;</span>;
    case "down":
      return <span>&darr;</span>;
    case "left":
      return <span>&larr;</span>;
    case "right":
      return <span>&rarr;</span>;
    case "command":
      return <span>&#8984;</span>;
    case "alt":
      if (getPlatformName() === "osx") {
        return <span>&#8997;</span>;
      }
      return key;
    case "pageup":
      return "PgUp";
    case "pagedown":
      return "PgDn";
    default:
      return key;
  }
}

function renderKeyMap(keyCombo) {
  if (typeof keyCombo === "string") {
    const combo = keyCombo.split("+");
    return combo.map((key) => <kbd key={key}>{renderKey(key)}</kbd>);
  }
  if (Array.isArray(keyCombo)) {
    return keyCombo.map((subCombo, i) => (
      <span key={i}>
        {renderKeyMap(subCombo)}
        {i < keyCombo.length - 1 ? " or " : ""}
      </span>
    ));
  }
  const platform = getPlatformName();
  return renderKeyMap(keyCombo[platform || "other"]);
}

class ShortcutsHelp extends React.Component {
  render() {
    const { showShortcutsHelpModal, shortcutsHelpModalHide, shortcutsHelpModalShow } = this.props;
    return (
      <Modal
        open={showShortcutsHelpModal}
        onOpen={shortcutsHelpModalShow}
        onClose={shortcutsHelpModalHide}
        className={styles.modal}
      >
        <Modal.Header>Keyboard Shortcuts {renderKeyMap(keyMap.GLOBAL.SHORTCUTS_HELP)}</Modal.Header>
        <Modal.Content>
          <Header as="h2" sub>
            Navigation
          </Header>
          <Table fixed compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Search Channel</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.SEARCH_FOCUS)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Navigate channels (while searching)</Table.Cell>
                <Table.Cell>
                  {renderKeyMap(keyMap.SIDEBAR_SECTION.PREVIOUS_ROOM)} or{" "}
                  {renderKeyMap(keyMap.SIDEBAR_SECTION.NEXT_ROOM)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Cancel Search (while searching)</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.SIDEBAR_SECTION.CANCEL)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Previous Channel</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.PREVIOUS_ROOM)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Next Channel</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.NEXT_ROOM)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Previous Unread Channel</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.PREVIOUS_UNREAD_ROOM)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Next Unread Channel</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.NEXT_UNREAD_ROOM)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Header as="h2" sub>
            App Navigation
          </Header>
          <Table fixed compact>
            <Table.Body>
              {config.flags.chat && (
                <Table.Row>
                  <Table.Cell>
                    <Icon name="chat" flipped="horizontally" /> Chat
                  </Table.Cell>
                  <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_CHAT)}</Table.Cell>
                </Table.Row>
              )}
              {config.flags.call && (
                <Table.Row>
                  <Table.Cell>
                    <Icon name="call" flipped="horizontally" /> Call
                  </Table.Cell>
                  <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_CALL)}</Table.Cell>
                </Table.Row>
              )}
              {config.flags.poll && (
                <Table.Row>
                  <Table.Cell>
                    <Icon name="chart bar" /> Poller
                  </Table.Cell>
                  <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_POLL)}</Table.Cell>
                </Table.Row>
              )}
              {config.flags.meeting && (
                <Table.Row>
                  <Table.Cell>
                    <Icon name="calendar alternate" /> Meetings
                  </Table.Cell>
                  <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_MEETING)}</Table.Cell>
                </Table.Row>
              )}
              {config.flags.clientFeedback && (
                <Table.Row>
                  <Table.Cell>
                    <Icon name="thumbs up" /> Feedback
                  </Table.Cell>
                  <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_FEEDBACK)}</Table.Cell>
                </Table.Row>
              )}
              {config.flags.health && (
                <Table.Row>
                  <Table.Cell>
                    <Icon name="heartbeat" /> Health
                  </Table.Cell>
                  <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_HEALTH)}</Table.Cell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.Cell>
                  <Icon name="cog" /> Settings
                </Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_SETTINGS)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Icon name="list alternate outline" /> Planning
                </Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_PLANNING)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Previous App</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_PREVIOUS)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Next App</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.APP_NEXT)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Header as="h2" sub>
            Messages
          </Header>
          <Table fixed compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Upload file</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.MESSAGE_BOX.UPLOAD)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Edit Last Message (when the input field is empty)</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.MESSAGE_BOX.EDIT_LAST)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Reply to Last Message (when the input field is empty)</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.MESSAGE_BOX.REPLY_LAST)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Start typing commands (when the input field is empty)</Table.Cell>
                <Table.Cell>{renderKeyMap("/")}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Header as="h2" sub>
            Wizards
          </Header>
          <Table fixed compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>New Project</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.NEW_PROJECT)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>New Direct Message</Table.Cell>
                <Table.Cell>{renderKeyMap(keyMap.GLOBAL.NEW_MESSAGE)}</Table.Cell>
              </Table.Row>
              {config.flags.profile.teams && (
                <Table.Row>
                  <Table.Cell>New Team</Table.Cell>
                  <Table.Cell>{renderKeyMap(keyMap.GLOBAL.NEW_TEAM)}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }
}

ShortcutsHelp.propTypes = {
  showShortcutsHelpModal: PropTypes.bool.isRequired,
  shortcutsHelpModalShow: PropTypes.func.isRequired,
  shortcutsHelpModalHide: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    modals: { showShortcutsHelpModal },
  } = state;
  return {
    showShortcutsHelpModal,
  };
}

export default connect(mapStateToProps, {
  shortcutsHelpModalHide: shortcutsHelpModal.hide,
  shortcutsHelpModalShow: shortcutsHelpModal.show,
})(ShortcutsHelp);
