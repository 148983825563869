import React from "react";
import { Progress } from "semantic-ui-react";
import PropTypes from "prop-types";
import zxcvbn from "zxcvbn";

import styles from "./styles.module.scss";

class PasswordStrengthMeter extends React.Component {
  static propTypes = {
    password: PropTypes.string.isRequired,
  };

  strength = ["Risky", "Weak", "Fair", "Good", "Strong"];
  color = ["red", "orange", "yellow", "olive", "green"];

  render() {
    const { password } = this.props;
    const { score } = zxcvbn(password);

    if (password) {
      return (
        <Progress
          className={styles.passwordMeter}
          size="small"
          value={score}
          total={4}
          color={this.color[score]}
          label={this.strength[score]}
        />
      );
    }
    return null;
  }
}

export default PasswordStrengthMeter;
