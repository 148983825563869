import { camelizeKeys } from "humps";
import { buffers, eventChannel, END } from "redux-saga";
import { call, fork, put, take } from "redux-saga/effects";
import Humanize from "humanize-plus";

import * as attachments from "actions/attachments";
import config from "config";
import { api, token as tokenService } from "../services";

// Based on: https://decembersoft.com/posts/file-upload-progress-with-redux-saga/
function createUploadFileChannel(token, id, file, generic) {
  return eventChannel((emitter) => {
    const xhr = api.uploadAttachment(token, generic);

    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100.0;
        emitter({ progress });
      }
    };
    const onFailure = () => {
      emitter({
        error: {
          detail: "Cannot upload file.",
          retry: true,
        },
      });
      emitter(END);
    };
    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr;
      if (readyState === 4) {
        if (status === 201) {
          emitter(
            camelizeKeys({
              // IE11 compat: IE11 does not support responseType=json
              response: typeof response === "string" ? JSON.parse(response) : response,
            })
          );
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };

    const formData = new FormData();
    formData.append("file_data", file.data, file.name);

    xhr.send(formData);

    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}

function* uploadAttachment(request) {
  const { id, file, generic } = request;
  yield put(attachments.attachment.request(id, file));

  if (file.data && file.data.size > config.maxAttachmentSize) {
    yield put(
      attachments.attachment.failure(id, file, {
        detail: `File above ${Humanize.fileSize(config.maxAttachmentSize)}`,
        retry: false,
      })
    );
    return;
  }

  const token = yield call(tokenService.getAuthToken);
  const channel = yield call(createUploadFileChannel, token, id, file, generic);

  while (true) {
    const { progress = 0, response, error } = yield take(channel);

    if (response) {
      yield put(attachments.attachment.success(id, file, response));
      return;
    }
    if (error) {
      yield put(attachments.attachment.failure(id, file, error));
      return;
    }
    yield put(attachments.attachment.progress(id, file, progress));
  }
}

function* watchUploadAttachment() {
  while (true) {
    const request = yield take(attachments.ATTACHMENT.INIT);
    yield fork(uploadAttachment, request);
  }
}

export function* rootAttachments() {
  yield fork(watchUploadAttachment);
}
