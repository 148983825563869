/* eslint-disable react/no-did-update-set-state */

import classnames from "classnames";
import Humanize from "humanize-plus";
import { clamp, find, isEqual, mapValues, pickBy } from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Button, Dropdown, Form, Message, Icon } from "semantic-ui-react";

import { pollUpdate } from "actions/chat";
import Countdown from "components/Countdown";
import Errors from "components/Errors";
import { LabeledDropdown } from "components/input";
import { renderers, plugins } from "components/Markdown";
import { pollType } from "types/poll";
import { PollOptionType } from "utils/constants/poll";

import PollOption from "containers/Workspace/PollOption";
import stylesChat from "containers/Workspace/styles.module.scss";
import MessageListItemPollResultList from "./MessageListItemPollResultList";
import ModalPollDelete from "./ModalPollDelete";
import styles from "./styles.module.scss";

export const RESULTS_BY_WEIGHTED = "RESULTS_BY_WEIGHTED";
export const RESULTS_BY_SIMPLE = "RESULTS_BY_SIMPLE";

const STEP_VOTE = "STEP_VOTE";
const STEP_VOTING = "STEP_VOTING";
const STEP_JUST_ARCHIVED = "STEP_JUST_ARCHIVED";
const STEP_JUST_EXPIRED = "STEP_JUST_EXPIRED";
const STEP_JUST_VOTED = "STEP_JUST_VOTED";
const STEP_RESULTS = "STEP_RESULTS";
const MIN_DISPLAYED_OPTIONS = 5;

class MessageListItemPoll extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentUser, poll } = nextProps;
    const { step } = prevState;
    if (!poll) return null;
    if (step && step !== STEP_VOTE) return null;

    const derivedState = {};

    if (step === STEP_VOTE && poll.isArchived) {
      derivedState.step = STEP_JUST_ARCHIVED;
    } else if (poll.isArchived || poll.isExpired) {
      derivedState.step = STEP_RESULTS;
    } else if (step === null && (poll.hasVoted || !!poll.voteDelegate)) {
      derivedState.step = STEP_RESULTS;
    } else {
      derivedState.step = STEP_VOTE;
    }

    if (!step) {
      const infoDateEnd = [poll.archivedAt, poll.expireAt].sort()[0];
      derivedState.isExpiredOnInit = moment(infoDateEnd).isSameOrBefore(moment());

      const valueCurrent = {};
      const valueInitial = {};

      poll.options.forEach((option) => {
        const voteCount = option.votesCount[currentUser.slug] || 0;

        valueCurrent[option.id] = voteCount;
        valueInitial[option.id] = voteCount;
      });

      derivedState.valueCurrent = valueCurrent;
      derivedState.valueInitial = valueInitial;
    }

    return derivedState;
  }

  constructor(props) {
    super(props);

    this.state = {
      delegateSlug: props.poll && props.poll.voteDelegate && props.poll.voteDelegate.slug,
      errors: {},
      isDelegating: props.poll && props.poll.voteDelegate,
      isExpiredOnInit: false,
      loading: false,
      step: null,
      valueCurrent: {},
      valueInitial: {},
      moreOptions: {
        hasMore: (props.poll && props.poll.options.length > MIN_DISPLAYED_OPTIONS) || false,
        show: false,
      },
      deleteModal: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentUser } = this.props;
    const prevPoll = prevProps.poll;
    const currentPoll = this.props.poll;

    if (!prevPoll || !currentPoll) return;

    if (prevState.step !== this.state.step) {
      if ([STEP_JUST_ARCHIVED, STEP_JUST_VOTED].includes(this.state.step)) {
        this.timer = setTimeout(() => this.setState({ step: STEP_RESULTS }), 2000);
      } else {
        clearTimeout(this.timer);
      }
    }

    if (prevPoll.loading && !currentPoll.loading && this.state.step === STEP_VOTING) {
      this.setState({ step: STEP_JUST_VOTED });
    }

    if (
      currentPoll.optionType !== prevPoll.optionType &&
      currentPoll.optionType === PollOptionType.multipleChoiceSingleAnswer
    ) {
      const valueCurrent = {};
      const valueInitial = {};

      let optionWithVoteFound = false;
      currentPoll.options.forEach((option) => {
        if (optionWithVoteFound) {
          valueCurrent[option.id] = 0;
          valueInitial[option.id] = 0;
        } else {
          const voteCount = option.votesCount[currentUser.slug] || 0;

          valueCurrent[option.id] = voteCount;
          valueInitial[option.id] = voteCount;

          optionWithVoteFound = voteCount > 0;
        }
      });

      this.setState({ valueCurrent, valueInitial });
    }

    if (prevPoll.isArchived && !currentPoll.isArchived && !currentPoll.hasVoted) {
      this.setState({ step: STEP_VOTE });
    }

    if (this.props.step && prevProps.step !== this.props.step) {
      this.setState({ step: this.props.step });
    }
  }

  _getTotalVotes = (weighted = false) => {
    const { poll } = this.props;
    let totalVotes = 0;
    poll.options.forEach((option) => {
      if (weighted) {
        totalVotes += option.totalVotesWeighted;
      } else {
        totalVotes += option.totalVotesSimple;
      }
    });
    return totalVotes;
  };

  _toggleMoreOptions = () => {
    this.setState(({ moreOptions }) => ({
      moreOptions: {
        ...moreOptions,
        show: !moreOptions.show,
      },
    }));
  };

  handleArchive = async () => {
    const { poll } = this.props;
    const data = { id: poll.id, isArchived: !poll.isArchived };

    try {
      this.setState({ errors: {}, loading: true });

      await this.pollUpdate(data);
    } catch (errors) {
      this.setState({ errors, loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChange = (option, value) => {
    const { poll } = this.props;

    if (poll.optionType === PollOptionType.multipleChoiceSingleAnswer) {
      const valueCurrent = { [option.id]: value };

      this.setState({ valueCurrent });
    } else {
      this.setState((prevState) => ({
        valueCurrent: {
          ...prevState.valueCurrent,
          [option.id]: value,
        },
      }));
    }
  };

  handleCountdownExpire = () => {
    const { isExpiredOnInit, step } = this.state;
    if (isExpiredOnInit) return;

    if (step === STEP_VOTE) {
      this.setState({ step: STEP_JUST_EXPIRED });
    } else {
      this.forceUpdate();
    }
  };

  handleCountdownScheduledAt = () => {
    this.forceUpdate();
  };

  handleDelegate = (event, { value }) => {
    this.setState({ delegateSlug: value });
  };

  handleDeleteShow = () => {
    this.setState({ deleteModal: true });
  };

  handleDeleteHide = () => {
    this.setState({ deleteModal: false });
  };

  handlePin = async () => {
    const { poll } = this.props;
    const data = { id: poll.id, isPinned: !poll.isPinned };

    try {
      this.setState({ errors: {}, loading: true });

      await this.pollUpdate(data);
    } catch (errors) {
      this.setState({ errors, loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSubmit = (e) => {
    const { onDelegate, onVote, onChangeStep, showPollActions, poll, step: propStep } = this.props;
    const { delegateSlug, isDelegating, valueCurrent, valueInitial } = this.state;

    if (isDelegating) {
      if (onDelegate && delegateSlug) {
        onDelegate(poll, delegateSlug);
      }
      this.setState({ step: STEP_VOTING, valueInitial: {}, valueCurrent: {} });
    } else {
      const step = isEqual(valueCurrent, valueInitial) ? STEP_JUST_VOTED : STEP_VOTING;

      let valueProcessed;
      if (
        poll.optionType === PollOptionType.multipleChoiceSingleAnswer ||
        poll.optionType === PollOptionType.multipleChoiceMultipleAnswer
      ) {
        valueProcessed = mapValues(valueCurrent, (vote) => clamp(vote, 0, 1));
      } else {
        valueProcessed = { ...valueCurrent };
      }

      if (onVote && step === STEP_VOTING) {
        onVote(poll, valueProcessed);
      }

      this.setState({ step, valueInitial: valueCurrent, delegateSlug: null });
    }

    // this is only used when other actions are not shown in the poll
    if (!showPollActions && propStep !== STEP_RESULTS) {
      onChangeStep({ next: STEP_RESULTS });
    }
  };

  handleToggleDelegating = () => {
    this.setState((prevState) => ({ isDelegating: !prevState.isDelegating }));
  };

  handleVote = () => {
    const { onChangeStep, poll, showPollActions } = this.props;
    this.setState({
      step: STEP_VOTE,
    });

    // this is only used when  other actions are not shown in the poll
    if (poll.hasVoted && !showPollActions) onChangeStep();
  };

  handleViewResults = () => {
    this.setState({ step: STEP_RESULTS });
  };

  pollUpdate = (data) =>
    new Promise((resolve, rejection) => this.props.pollUpdate(data, resolve, rejection));

  isVoteable() {
    const { poll, room } = this.props;

    const dateTime = [poll.archivedAt, poll.expireAt].sort()[0];
    return poll.isVoteable && room.isActive && (!dateTime || moment(dateTime).isAfter(moment()));
  }

  renderCountdown() {
    const { poll } = this.props;
    const infoDateEnd = [poll.archivedAt, poll.expireAt].sort()[0];

    if (moment(poll.scheduledAt).isAfter(moment())) {
      return (
        <Countdown
          className={styles.messageListItemPollFooterCountdown}
          dateTime={poll.scheduledAt}
          noDateTimeMessage="no schedule"
          onExpire={this.handleCountdownScheduledAt}
          prefixOngoing="opens in"
        />
      );
    }

    return (
      <Countdown
        className={styles.messageListItemPollFooterCountdown}
        dateTime={infoDateEnd}
        noDateTimeMessage="no expiry"
        onExpire={this.handleCountdownExpire}
      />
    );
  }

  renderFooter() {
    const { maximized, poll, previewing, room, showPollActions } = this.props;
    const { step, deleteModal } = this.state;
    if ([STEP_JUST_ARCHIVED, STEP_JUST_EXPIRED, STEP_JUST_VOTED].includes(step)) return null;

    return (
      <footer className={styles.messageListItemPollFooter}>
        {step !== STEP_RESULTS && poll.allowAnonymousVotes && (
          <span className={stylesChat.pollAnonymousTag}>Anonymous</span>
        )}

        {this.renderCountdown()}
        {showPollActions && !previewing && (
          <>
            <Dropdown
              icon={{
                name: "ellipsis horizontal",
                size: "small",
              }}
              button
              basic
              direction="left"
              className="icon"
            >
              <Dropdown.Menu className={styles.actionsMenu}>
                {!poll.isArchived && poll.isEditable && (
                  <Dropdown.Item onClick={this.handlePin}>
                    {poll.isPinned ? "Unpin from Channel" : "Pin to Channel"}
                  </Dropdown.Item>
                )}
                {step === STEP_RESULTS && this.isVoteable() && (
                  <Dropdown.Item onClick={this.handleVote}>
                    {poll.hasVoted ? "Change vote" : "Vote"}
                  </Dropdown.Item>
                )}
                {step === STEP_VOTE && (poll.isResultsViewablePresubmission || poll.hasVoted) && (
                  <Dropdown.Item onClick={() => this.setState({ step: STEP_RESULTS })}>
                    View results
                  </Dropdown.Item>
                )}
                {!maximized && (
                  <Dropdown.Item as={Link} to={`/chat/r/${room.id}/polls/${poll.id}`}>
                    View poll
                  </Dropdown.Item>
                )}
                {!poll.isArchived && poll.isEditable && (
                  <Dropdown.Item
                    as={Link}
                    to={`/chat/r/${room.id}/polls/${poll.id}/edit`}
                    className={styles.editOption}
                  >
                    Edit
                  </Dropdown.Item>
                )}
                {poll.isEditable && (
                  <Dropdown.Item onClick={this.handleArchive}>
                    {poll.isArchived ? "Unarchive" : "Archive"}
                  </Dropdown.Item>
                )}
                {poll.isEditable && (
                  <Dropdown.Item className={styles.deleteOption} onClick={this.handleDeleteShow}>
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            {poll.isEditable && (
              <ModalPollDelete
                poll={poll}
                open={deleteModal}
                onClose={this.handleDeleteHide}
                onOpen={this.handleDeleteShow}
              />
            )}
          </>
        )}
      </footer>
    );
  }

  renderDelegationStatus() {
    const { poll } = this.props;
    const otherPeople = Humanize.oxford(
      poll.voteDelegators.map((user) => user.displayName),
      3
    );

    if (!poll.voteDelegate && poll.voteDelegators.length > 0) {
      return (
        <Message
          className={styles.messageItemListPollDelegationStatus}
          color="blue"
          size="tiny"
          content={
            <span>
              <Icon name="warning circle" />
              You are voting on behalf of <strong>{otherPeople}</strong>.
            </span>
          }
        />
      );
    }
    if (poll.voteDelegate) {
      return (
        <Message
          className={styles.messageItemListPollDelegationStatus}
          color="blue"
          size="tiny"
          content={
            <span>
              <Icon name="warning circle" />
              You are allowing {poll.voteDelegate.displayName} to carry your vote
              {poll.voteDelegators.length > 0 && (
                <strong>
                  {" "}
                  and {poll.voteDelegators.length}{" "}
                  {Humanize.pluralize(poll.voteDelegators.length, "other")}
                  {poll.voteDelegators.length < 2 ? "'s" : "'"}
                </strong>
              )}
              .
            </span>
          }
        />
      );
    }
    return null;
  }

  renderForm() {
    const { poll, previewing, room, replies } = this.props;
    const { delegateSlug, isDelegating, loading, step, valueCurrent } = this.state;
    if (![STEP_VOTE, STEP_VOTING].includes(step)) return null;
    if (!this.isVoteable()) return null;

    let choiceSelected = false;
    for (const value of Object.values(valueCurrent)) {
      if (value > 0) {
        choiceSelected = true;
        break;
      }
    }

    const hasSelection = !!delegateSlug || choiceSelected;
    const totalVotes = this._getTotalVotes();
    const hasVotes = totalVotes > 0;
    const hasReplies = replies > 0;

    return (
      <Form onSubmit={this.handleSubmit}>
        {this.renderDelegationStatus()}
        <div className={styles.messagePollSubmit}>
          <div>
            {hasVotes && (
              <span>
                {totalVotes} {Humanize.pluralize(totalVotes, "vote")}
              </span>
            )}
            {hasVotes && hasReplies && <span className={styles.bullet}>&bull;</span>}
            {hasReplies && (
              <span>
                {replies} {replies > 1 ? "replies" : "reply"}
              </span>
            )}
          </div>
          <div>
            <Button
              className={styles.messageListItemPollDelegate}
              content="Delegate vote"
              disabled={previewing}
              onClick={isDelegating ? this.handleSubmit : this.handleToggleDelegating}
              basic
              type="button"
            />
            <Button
              className={styles.messageListItemPollSubmit}
              disabled={poll.loading || !room.isActive || !hasSelection || loading || previewing}
              content="Submit vote"
              loading={poll.loading || loading}
              onClick={isDelegating ? this.handleToggleDelegating : this.handleSubmit}
              type="button"
            />
          </div>
        </div>
      </Form>
    );
  }

  renderMessage() {
    const { step } = this.state;
    if (![STEP_JUST_ARCHIVED, STEP_JUST_EXPIRED, STEP_JUST_VOTED].includes(step)) return null;

    let message = "";
    if (step === STEP_JUST_VOTED) {
      message = "Vote submitted!";
    } else if (step === STEP_JUST_EXPIRED) {
      message = "Poll ended!";
    } else if (step === STEP_JUST_ARCHIVED) {
      message = "Poll archived!";
    }

    return (
      <div className={styles.messageListItemPollMessage}>
        {`${message} `}
        <button
          type="button"
          className={styles.messageListItemPollMessageLink}
          onClick={this.handleViewResults}
        >
          View results
        </button>
      </div>
    );
  }

  renderOptions() {
    const { currentUser, poll, room, roomUsers } = this.props;
    const { isDelegating, loading, step, valueCurrent } = this.state;
    if (![STEP_VOTE, STEP_VOTING].includes(step)) return null;

    if (isDelegating) {
      const delegates = roomUsers
        .filter(
          (user) =>
            user.slug !== currentUser.slug &&
            !find(poll.voteDelegators, (d) => d.slug === user.slug)
        )
        .map((user) => ({
          key: user.slug,
          selected: this.state.delegateSlug && this.state.delegateSlug === user.slug,
          text: `${user.displayName} @${user.slug}`,
          value: user.slug,
        }));

      return (
        <Form size="small">
          <LabeledDropdown
            className={styles.messageListItemPollOptions}
            name="delegateSlug"
            label="Delegate vote to"
            placeholder="Select delegate"
            fluid
            selection
            selectOnNavigation={false}
            search
            value={this.state.delegateSlug}
            options={delegates}
            onChange={this.handleDelegate}
            disabled={poll.loading || !room.isActive || loading}
          />
        </Form>
      );
    }

    const { moreOptions } = this.state;
    let { options } = poll;
    let linkLabel = null;
    if (moreOptions.hasMore) {
      if (!moreOptions.show) {
        options = options.slice(0, MIN_DISPLAYED_OPTIONS);
        linkLabel = `+${poll.options.length - MIN_DISPLAYED_OPTIONS} options more`;
      } else {
        linkLabel = `show less options`;
      }
    }

    return (
      <div className={styles.messageListItemPollOptions}>
        {options.map((option, index) => (
          <PollOption
            disabled={!this.isVoteable() || poll.loading || !room.isActive || loading}
            key={option.id}
            onChange={this.handleChange}
            option={option}
            type={poll.optionType}
            value={valueCurrent[option.id]}
            isLast={index === options.length - 1}
          />
        ))}
        {linkLabel && (
          <Button
            className={styles.morePollOptions}
            content={linkLabel}
            onClick={this._toggleMoreOptions}
          />
        )}
      </div>
    );
  }

  renderResults() {
    const { poll, users } = this.props;
    const { step } = this.state;
    if (step !== STEP_RESULTS) return null;

    const votesByOptions = {};
    poll.options.forEach((option) => {
      votesByOptions[option.id] = pickBy(option.votesCount, (vote) => vote > 0);
    });

    return (
      <div>
        <MessageListItemPollResultList poll={poll} users={users} votesByOptions={votesByOptions} />
        {this.renderDelegationStatus()}
      </div>
    );
  }

  renderTags() {
    const { poll } = this.props;
    const { step } = this.state;
    if ([STEP_JUST_ARCHIVED, STEP_JUST_EXPIRED, STEP_JUST_VOTED].includes(step)) return null;
    if (poll.skills.length === 0) return null;

    return (
      <div>
        {poll.skills.map((skill) => (
          <span className={stylesChat.pollTag} key={skill.id}>
            {skill.name}
          </span>
        ))}
      </div>
    );
  }

  render() {
    const { extraClass, maximized, poll, leftAligned } = this.props;
    const { errors, step } = this.state;
    if (!poll || !step) return null;

    return (
      <div
        id={`poll-${poll.id}`}
        className={classnames(
          styles.messageListItemPoll,
          maximized ? styles.maximized : null,
          leftAligned ? styles.leftAligned : null,
          extraClass
        )}
      >
        {this.renderFooter()}

        <Errors errors={errors} />

        <div className={styles.messageListItemPollHeader}>
          <ReactMarkdown renderers={renderers} plugins={plugins} source={poll.question} />
        </div>

        {poll.description && (
          <div className={styles.messageListItemPollDescription}>
            <ReactMarkdown renderers={renderers} plugins={plugins} source={poll.description} />
          </div>
        )}

        {this.renderOptions()}
        {this.renderResults()}
        {this.renderForm()}
        {this.renderMessage()}
        {this.renderTags()}
      </div>
    );
  }
}

MessageListItemPoll.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  extraClass: PropTypes.string,
  maximized: PropTypes.bool,
  step: PropTypes.string,
  onChangeStep: PropTypes.func,
  onDelegate: PropTypes.func,
  onVote: PropTypes.func,
  poll: pollType,
  previewing: PropTypes.bool,
  room: PropTypes.shape(),
  roomUsers: PropTypes.arrayOf(PropTypes.shape()),
  users: PropTypes.shape().isRequired,
  leftAligned: PropTypes.bool,
  replies: PropTypes.number,
  showPollActions: PropTypes.bool,

  pollUpdate: PropTypes.func.isRequired,
};

MessageListItemPoll.defaultProps = {
  extraClass: "",
  maximized: false,
  poll: null,
  previewing: false,
  roomUsers: [],
  leftAligned: false,
  showPollActions: true,
  replies: 0,
  step: "",

  onChangeStep: () => {},
  onDelegate: null,
  onVote: null,
};

function mapStateToProps(state, props) {
  if (props.poll) {
    const {
      messages: { items },
    } = state;
    const roomMessages = items.length > 0 ? items[props.poll.room] : null;
    const message = roomMessages ? roomMessages[props.poll.message] : null;
    return {
      replies: message ? message.replies : 0,
    };
  }
  return {};
}

export default connect(mapStateToProps, {
  pollUpdate: pollUpdate.request,
})(MessageListItemPoll);
