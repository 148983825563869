import classnames from "classnames";
import { find, isEmpty, omitBy, sortBy } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Shortcuts } from "react-shortcuts";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

import { deleteMessage } from "actions/chat";
import { sidePanel, PANELS } from "actions/sidePanel";
import Attachment from "containers/Attachment";
import MessageBox from "containers/Chat/MessageBox";
import MessageBoxEdit from "containers/Chat/MessageBoxEdit";
import MessageList from "containers/Chat/MessageList";
import { getCurrentThread } from "reducers/selectors";
import { getRoomName } from "utils/chat";

import * as styles from "../../styles.module.scss";

class SidePanelThread extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastMessageModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastMessageId !== this.props.lastMessageId) {
      this.handleHideLastMessageModal();
    }
  }

  handleShowLastMessageModal = () => {
    this.setState({ lastMessageModal: true });
  };

  handleHideLastMessageModal = () => {
    this.setState({ lastMessageModal: false });
  };

  handleShortcut = (action, event) => {
    const chatMessageBox = document.querySelector("#chatMessageBox");
    switch (action) {
      case "CLOSE":
        event.preventDefault();
        event.stopPropagation();
        this.props.sidePanelClose(PANELS.THREAD);
        if (chatMessageBox) chatMessageBox.focus();
        break;
      default:
        break;
    }
  };

  render() {
    const {
      currentRoom,
      currentUser,
      lastMessage,
      deleteMessageRequest,
      panelId,
      roomUsers,
      root,
      sidePanelClose,
      thread,
      threadOrdering,
      users,
      visible,
    } = this.props;

    if (isEmpty(threadOrdering) || !visible || panelId !== PANELS.THREAD) {
      return null;
    }

    return (
      <Attachment
        cacheId={`thread-${root.id}`}
        className={classnames("message-list", "sidePanel", styles.sidePanel)}
      >
        {({ cacheId, dropzoneOnPaste, dropzoneOpen }) => (
          <React.Fragment>
            <button
              type="button"
              className={styles.sidePanelClose}
              onClick={() => sidePanelClose(PANELS.THREAD)}
            >
              Close
            </button>

            <Header as="h3" content={getRoomName(currentRoom, currentUser, roomUsers)} />

            <MessageList
              currentUser={currentUser}
              isThread
              messages={thread}
              messageOrdering={threadOrdering}
              room={currentRoom}
              roomUsers={roomUsers}
              users={users}
              verticalAlign="top"
            />

            {lastMessage && (
              <MessageBoxEdit
                deleteMessage={deleteMessageRequest}
                editOpen={this.state.lastMessageModal}
                message={this.props.lastMessage}
                room={currentRoom}
                onEditModalClose={this.handleHideLastMessageModal}
              />
            )}

            <Shortcuts
              className="shortcuts"
              name="MESSAGE_BOX_THREAD"
              alwaysFireHandler
              handler={this.handleShortcut}
              stopPropagation={false}
              preventDefault={false}
            >
              <MessageBox
                cacheId={cacheId}
                dropzoneOpen={dropzoneOpen}
                dropzoneOnPaste={dropzoneOnPaste}
                key={root.id}
                height={1}
                id="chatMessageBox"
                room={currentRoom}
                root={root}
                onEditLastMessage={this.handleShowLastMessageModal}
                onReplyLastMessage={this.handleOpenThread}
              />
            </Shortcuts>
          </React.Fragment>
        )}
      </Attachment>
    );
  }
}

SidePanelThread.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  currentRoom: PropTypes.shape(),
  deleteMessageRequest: PropTypes.func.isRequired,
  lastMessage: PropTypes.shape(),
  lastMessageId: PropTypes.number,
  panelId: PropTypes.string,
  roomUsers: PropTypes.arrayOf(PropTypes.shape()),
  root: PropTypes.shape(),
  sidePanelClose: PropTypes.func.isRequired,
  thread: PropTypes.objectOf(PropTypes.shape()).isRequired,
  threadOrdering: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  users: PropTypes.shape().isRequired,
  visible: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser },
    entities: { users },
    sidePanel: { panelId, visible },
  } = state;
  const { room, thread } = getCurrentThread(state);
  const root = find(omitBy(thread, (m) => !!m.root));
  const threadOrdering = sortBy(thread, "created").map((message) => message.id);

  let roomUsers = [];
  if (room && room.users) {
    roomUsers = room.users;
  } else if (room && !room.users) {
    roomUsers = room.userSlugs.map((slug) => users[slug]);
  }

  const lastMessageId = threadOrdering
    .slice()
    .reverse()
    .find(
      (messageId) =>
        !thread[messageId].isDeleted &&
        !thread[messageId].poll &&
        thread[messageId].author === currentUser.slug
    );
  const lastMessage = lastMessageId ? thread[lastMessageId] : null;

  return {
    currentRoom: room,
    currentUser,
    lastMessage,
    lastMessageId,
    panelId,
    roomUsers,
    root,
    thread,
    threadOrdering,
    users,
    visible,
  };
}

export default connect(mapStateToProps, {
  deleteMessageRequest: deleteMessage.request,
  sidePanelClose: sidePanel.close,
})(SidePanelThread);
