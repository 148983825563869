import { decamelizeKeys } from 'humps';
import querystring from 'querystring';
import * as schema from 'services/schema';
import { callApi, callApiWithToken } from './base';

export const fetchUser = (token, slug) =>
  callApiWithToken(`users/${slug}/`, token, schema.userSchema);

export const updateUser = (token, slug, data) =>
  callApiWithToken(
    `users/${slug}/`,
    token,
    schema.userSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const deleteUser = (token, slug) =>
  callApiWithToken(
    `users/${slug}/`,
    token,
    null,
    'DELETE',
    { 'Content-Type': 'application/json' },
    ''
  );

export const fetchUserCalendar = (token, url) =>
  callApiWithToken(url, token, schema.calendarSchema);
export const fetchEvents = (token) =>
  callApiWithToken('events/', token, schema.eventSchemaArray);

export const createEvent = (token, _, data) =>
  callApiWithToken(
    'events/',
    token,
    schema.eventSchema,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const updateEvent = (token, id, data) =>
  callApiWithToken(
    `events/${id}/`,
    token,
    schema.eventSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const eventDetail = (token, id) =>
  callApiWithToken(`events/${id}/`, token, schema.eventSchema);
export const eventOccurrenceDetail = (token, id, params = {}) =>
  callApiWithToken(`event_occurrences/${id}/?${querystring.stringify(params)}`, token, null);
export const deleteEvent = (token, id) =>
  callApiWithToken(`events/${id}/`, token, null, 'DELETE', { 'Content-Type': 'application/json' });
export const addGoogleCalendar = (token, ids) =>
  callApiWithToken(
    `calendars/google/add_calendar/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(ids))
  );
export const addOutlookCalendar = (token, ids) =>
  callApiWithToken(
    `calendars/outlook/add_calendar/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(ids))
  );

export const userPresence = (token) =>
  callApiWithToken('presence/', token, schema.userSchemaArray, 'GET');

export const buildTeam = (token, data) =>
  callApiWithToken(
    'build_team/',
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(decamelizeKeys(data))
  );

export const buildTeamProject = (token, data) =>
  callApiWithToken(
    'build_team_project/',
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(decamelizeKeys(data))
  );

export const buildTeamProjectInviteAccept = (token, inviteToken) =>
  callApiWithToken(
    'build_team_project_invite_accept/',
    token,
    null,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      decamelizeKeys({
        inviteToken,
      })
    )
  );

export const fetchTeam = () =>
  callApi('users/official/', schema.userSchemaArray, 'GET', {
    'Content-Type': 'application/json',
  });

export const fetchTalentTeam = (token, slug) =>
  callApiWithToken(`talent_teams/${slug}/`, token, schema.talentTeamsSchema);

export const fetchTeamTalents = (token, slug) =>
  callApiWithToken(`talent_teams/${slug}/members/`, token, schema.userSchemaArray);

export const fetchTeamTalentInvites = (token, slug) =>
  callApiWithToken(`talent_teams/${slug}/invites/`, token, schema.userSchemaArray);

export const updateTeam = (token, slug, data) =>
  callApiWithToken(
    `talent_teams/${slug}/`,
    token,
    schema.talentTeamsSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const deleteTeam = (token, slug) =>
  callApiWithToken(
    `talent_teams/${slug}/`,
    token,
    null,
    'DELETE',
    { 'Content-Type': 'application/json' },
    ''
  );

export const addMembers = (token, slug, data) =>
  callApiWithToken(
    `talent_teams/${slug}/add_members/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const updateMemberships = (token, slug, data) =>
  callApiWithToken(
    `talent_teams/${slug}/memberships/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const resendTeamInvite = (token, id) =>
  callApiWithToken(
    `team_invite/${id}/resend/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    ''
  );

export const removeTeamInvite = (token, id) =>
  callApiWithToken(
    `team_invite/${id}/remove/`,
    token,
    null,
    'DELETE',
    { 'Content-Type': 'application/json' },
    ''
  );

export const fetchTeamCalendar = (token, url) =>
  callApiWithToken(url, token, schema.calendarSchemaArray);

export const bulkSkillsUpdate = (token, slug, data) =>
  callApiWithToken(
    `users/${slug}/bulk_skills_update/`,
    token,
    schema.userSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const bulkTeamSkillsUpdate = (token, slug, data) =>
  callApiWithToken(
    `talent_teams/${slug}/bulk_skills_update/`,
    token,
    schema.talentTeamsSchema,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const deleteTeamMembership = (token, slug, id) =>
  callApiWithToken(
    `talent_teams/${slug}/memberships/${id}/`,
    token,
    null,
    'DELETE',
    { 'Content-Type': 'application/json' },
    ''
  );

export const toggleSubscribe = (toggleSubscribeToken, key, value) =>
  callApi(
    'toggle-subscribe/',
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      decamelizeKeys({
        toggleSubscribeToken,
        key,
        value,
      })
    )
  );

export const bulkPhoneNumbersUpdate = (token, slug, data) =>
  callApiWithToken(
    `users/${slug}/bulk_phonenumbers_update/`,
    token,
    null,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const addToRocketChat = (token, slug) =>
  callApiWithToken(
    `users/${slug}/add_to_rocket_chat/`,
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
  );
