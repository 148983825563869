import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { api, token as tokenService } from "services";

import * as subscription from "actions/subscription";
import * as user from "actions/user";
import { fetchRooms } from "./chat";
import { loadProjects } from "./project";

function* loadSubscription() {
  yield put(subscription.subscriptions.request());
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.fetchSubscriptions, token);
  if (response) {
    yield put(subscription.subscriptions.success(response));
  } else if (error) {
    yield put(subscription.subscriptions.failure(error));
  }
}

function* subscribe(planId, cardToken, coupon) {
  yield put(subscription.subscribe.request());
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.subscribe, token, { planId, cardToken, coupon });
  if (response) {
    yield put(subscription.subscribe.success(response));
    // load stuff
    yield put(user.userPresence.init());
    yield call(loadProjects, {});
    yield call(fetchRooms, token);
  } else if (error) {
    yield put(subscription.subscribe.failure(error));
  }
}

function* watchSubscribe() {
  while (true) {
    const { planId, cardToken, coupon } = yield take(subscription.SUBSCRIBE.INIT);
    yield subscribe(planId, cardToken, coupon);
  }
}

function* resubscribe(subscriptionId, cardToken, coupon) {
  yield put(subscription.resubscribe.request());
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.subscribe, token, {
    subscriptionId,
    cardToken,
    coupon,
  });
  if (response) {
    yield put(subscription.resubscribe.success(response));
    // load stuff
    yield put(user.userPresence.init());
    yield call(loadProjects, {});
    yield call(fetchRooms, token);
  } else if (error) {
    yield put(subscription.resubscribe.failure(error));
  }
}

function* watchResubscribe() {
  while (true) {
    const { subscriptionId, cardToken, coupon } = yield take(subscription.RESUBSCRIBE.INIT);
    yield resubscribe(subscriptionId, cardToken, coupon);
  }
}

function* unsubscribe(subscriptionId, undo) {
  yield put(subscription.unsubscribe.request());
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.unsubscribe, token, { subscriptionId }, undo);
  if (response) {
    yield put(subscription.unsubscribe.success(response));
  } else if (error) {
    yield put(subscription.unsubscribe.failure(error));
  }
}

function* watchUnsubscribe() {
  while (true) {
    const { subscriptionId, undo } = yield take(subscription.UNSUBSCRIBE.INIT);
    yield unsubscribe(subscriptionId, undo);
  }
}

function* loadCustomer() {
  yield put(subscription.customer.request());
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.fetchCustomer, token);
  if (response) {
    yield put(subscription.customer.success(response));
  } else if (error) {
    yield put(subscription.customer.failure(error));
  }
}

function* loadPlans() {
  yield put(subscription.plans.request());
  const token = yield tokenService.getAuthToken();
  const { response, error } = yield call(api.fetchPlans, token);
  if (response) {
    yield put(subscription.plans.success(response));
  } else if (error) {
    yield put(subscription.plans.failure(error));
  }
}

export function* rootSubscription() {
  yield all([fork(watchSubscribe), fork(watchResubscribe), fork(watchUnsubscribe)]);
  yield takeLatest(subscription.SUBSCRIPTIONS.INIT, loadSubscription);
  yield takeLatest(subscription.CUSTOMER.INIT, loadCustomer);
  yield takeLatest(subscription.PLANS.INIT, loadPlans);
}
