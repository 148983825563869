import { decamelizeKeys } from 'humps';
import * as schema from 'services/schema';
import { callApiWithToken } from './base';

export const fetchWorkmates = (token, slug) =>
  callApiWithToken(`users/${slug}/workmates/`, token, schema.workmateSchemaArray);
export const fetchFeedback = (token, slug) =>
  callApiWithToken(`users/${slug}/feedback/`, token, schema.feedback);
export const fetchWorkmateFeedback = (token, slug) =>
  callApiWithToken(`users/${slug}/workmate_feedback/`, token, schema.workmateFeedbackArray);

export const postTalentFeedback = (token, _, data) =>
  callApiWithToken(
    'talent_feedback/',
    token,
    schema.talentFeedback,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const postSkillFeedback = (token, _, data) =>
  callApiWithToken(
    'talent_skill_feedback/',
    token,
    schema.talentSkillFeedback,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const postFeedbackRequest = (token, _, data) =>
  callApiWithToken(
    'feedback_requests/',
    token,
    schema.feedbackRequest,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const postFeedbackWizard = (token, _, data) =>
  callApiWithToken(
    'feedback/',
    token,
    null,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );
export const postGroupFeedback = (token, _, data) =>
  callApiWithToken(
    'talent_feedback/group_feedback/',
    token,
    schema.talentFeedbackArray,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const fetchConjointPair = (token) =>
  callApiWithToken('conjoint_feedback/get_random_selection/', token, schema.conjointPair);
export const postConjointFeedback = (token, _, data) =>
  callApiWithToken(
    'conjoint_feedback/',
    token,
    schema.conjointFeedback,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys(data))
  );

export const postUndoConjointFeedback = (token, id) =>
  callApiWithToken(`conjoint_feedback/${id}/undo/`, token, null, 'POST');

export const fetchRatingsHistory = (token, slug, qs) =>
  callApiWithToken(`users/${slug}/ratings_history/${qs}`, token, schema.ratingsHistory);
export const fetchTeamRatingsHistory = (token, slug, qs) =>
  callApiWithToken(`users/${slug}/team_ratings_history/${qs}`, token, schema.ratingsHistory);

export const fetchRelations = (token, slug, qs) =>
  callApiWithToken(`users/${slug}/relations/${qs}`, token, schema.relations);
export const fetchCommonSoftSkill = (token, slug, qs) =>
  callApiWithToken(`users/${slug}/random_common_soft_skill/${qs}`, token, schema.commonSkill);
export const fetchCommonHardSkill = (token, slug, qs) =>
  callApiWithToken(`users/${slug}/random_common_hard_skill/${qs}`, token, schema.commonSkill);
