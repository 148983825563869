import React from "react";
import PropTypes from "prop-types";

// https://github.com/rexxars/react-markdown/issues/65#issuecomment-288083389
export default class LinkRenderer extends React.PureComponent {
  render() {
    const { children, href } = this.props;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
}

LinkRenderer.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
