import classNames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ContactModal from "components/ContactModal";
import { NavLink as Link } from "react-router-dom";
import { Icon, Responsive } from "semantic-ui-react";

import { getCurrentRoomId } from "reducers/selectors";
import styles from "./styles.module.scss";

class MessageListItemHeader extends React.PureComponent {
  render() {
    const {
      author,
      authorName,
      children,
      currentRoomId,
      error,
      id,
      isEmailMessage,
      isThread,
      isPinned,
      showHeader,
      showLinkToProfile,
    } = this.props;
    const created = moment(this.props.created);

    return (
      <div
        className={classNames(styles.messageListItemSegment, error ? styles.error : "")}
        disabled={error}
      >
        <Responsive
          className={styles.author}
          maxWidth={showHeader || isThread || isPinned ? null : 480}
        >
          <span className={styles.headerContainer}>
            {author && showLinkToProfile ? (
              <strong>
                <ContactModal user={author} propStyle={styles.author} />
              </strong>
            ) : (
              <strong>{authorName}</strong>
            )}{" "}
            {isEmailMessage && <Icon name="envelope open" title="This message was sent by email" />}
            <Link to={`/chat/r/${currentRoomId}/${id}`} title={created.format("LLL")}>
              {created.format("LT")}
            </Link>
          </span>
        </Responsive>
        {children}
      </div>
    );
  }
}

MessageListItemHeader.propTypes = {
  showHeader: PropTypes.bool,
  author: PropTypes.string,
  authorName: PropTypes.string.isRequired,
  children: PropTypes.node,
  created: PropTypes.number.isRequired,
  currentRoomId: PropTypes.number.isRequired,
  error: PropTypes.shape(),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isEmailMessage: PropTypes.bool,
  isThread: PropTypes.bool,
  isPinned: PropTypes.bool,
  showLinkToProfile: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    currentRoomId: getCurrentRoomId(state),
  };
}

export default connect(mapStateToProps)(MessageListItemHeader);
