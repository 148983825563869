import union from "lodash/union";
import { VISUALIZATION } from "../actions/budget";

const initialState = {
  initialValue: 0,
  totalValue: 0,
  paymentBreakdown: [],
  spendBreakdown: [],
  loading: false,
  refreshing: false,
  error: false,
};

export default function buyers(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case VISUALIZATION.REQUEST: {
      const { refresh } = action;
      return {
        ...state,
        initialValue: refresh ? 0 : state.initialValue,
        totalValue: refresh ? 0 : state.totalValue,
        paymentBreakdown: refresh ? [] : state.paymentBreakdown,
        spendBreakdown: refresh ? [] : state.spendBreakdown,
        loading: !refresh,
        refreshing: !!refresh,
      };
    }

    case VISUALIZATION.SUCCESS: {
      if (action.response) {
        const { initial, total, paymentBreakdown, spendBreakdown } = action.response || {
          initial: 0,
          total: 0,
          paymentBreakdown: [],
          spendBreakdown: [],
        };

        return {
          ...state,
          initialValue: initial || 0,
          totalValue: total || 0,
          paymentBreakdown: union([], state.paymentBreakdown, paymentBreakdown),
          spendBreakdown: union([], state.spendBreakdown, spendBreakdown),
          loading: false,
          refreshing: false,
          error: false,
        };
      }
      return state;
    }

    case VISUALIZATION.FAILURE: {
      return {
        ...state,
        loading: false,
        refreshing: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
