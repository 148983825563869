import PropTypes from "prop-types";
import React from "react";
import { injectIntl } from "react-intl";

import HealthCard from "./cards/HealthCard";
import MeetingCard from "./cards/MeetingCard";

const autoClearDelay = 15000;

class Reminder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = { visible: true };
  }

  componentDidMount() {
    this.startTimer();
  }

  clearTimer = () => {
    clearTimeout(this.timer);
  };

  startTimer = () => {
    this.timer = setTimeout(this.stopTimer, autoClearDelay);
  };

  stopTimer = () => {
    this.timer = null;
    this.setState({ visible: false }, this.props.onExpire);
  };

  onFocus = () => {
    this.clearTimer();
    this.startTimer();
  };

  render() {
    const { currentUser, data, intl, update } = this.props;
    const { visible } = this.state;

    switch (data.key) {
      case "enable_daily_health_reminder_web": {
        return (
          <HealthCard
            data={data}
            onFocus={this.onFocus}
            visible={visible}
            intl={intl}
            update={update}
          />
        );
      }
      case "upcoming_meeting_reminder_web": {
        return (
          <MeetingCard
            currentUser={currentUser}
            data={data}
            duration={autoClearDelay}
            visible={visible}
          />
        );
      }
      default: {
        return null;
      }
    }
  }
}

Reminder.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    context: PropTypes.shape(),
  }).isRequired,
  intl: PropTypes.shape().isRequired,
  onExpire: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

export default injectIntl(Reminder);
