import {
  CREATE_PROJECT,
  CREATE_LINK,
  CREATE_LINK_RESET,
  INVITATION_ADD,
  LINKS_CREATE_OR_UPDATE,
  PROJECT,
} from "actions/project";
import { INVITATION_DELETE } from "actions/chat";

export default function project(
  state = {
    loading: false,
    success: true,
    failure: false,
    invitationLinksLoading: false,
    currentInvitationLink: null,
    linksLoading: {},
    linkLoading: false,
    linkSuccess: false,
    linkFailure: false,
    error: {},
  },
  action
) {
  const { type, error } = action;

  switch (type) {
    case CREATE_PROJECT.REQUEST:
      return { ...state, loading: true, success: false, failure: false, error: {} };
    case CREATE_PROJECT.SUCCESS:
      return { ...state, loading: false, success: true, failure: false, error: {} };
    case CREATE_PROJECT.FAILURE:
      return { ...state, loading: false, success: false, failure: true, error };

    case CREATE_LINK_RESET:
      return { ...state, linkLoading: false, linkSuccess: false, linkFailure: false, error: {} };
    case CREATE_LINK.REQUEST:
      return { ...state, linkLoading: true, linkSuccess: false, linkFailure: false, error: {} };
    case CREATE_LINK.SUCCESS:
      return { ...state, linkLoading: false, linkSuccess: true, linkFailure: false, error: {} };
    case CREATE_LINK.FAILURE:
      return { ...state, linkLoading: false, linkSuccess: false, linkFailure: true, error };

    case INVITATION_ADD.REQUEST:
      return { ...state, invitationLinksLoading: true };
    case INVITATION_ADD.SUCCESS:
      return { ...state, invitationLinksLoading: false };
    case INVITATION_ADD.FAILURE:
      return { ...state, invitationLinksLoading: false };

    case INVITATION_DELETE.REQUEST: {
      const { invitation } = action;
      return { ...state, invitationLinksLoading: true, currentInvitationLink: invitation.id };
    }
    case INVITATION_DELETE.SUCCESS:
      return { ...state, invitationLinksLoading: false, currentInvitationLink: null };
    case INVITATION_DELETE.FAILURE:
      return { ...state, invitationLinksLoading: false };

    case LINKS_CREATE_OR_UPDATE.REQUEST: {
      const { projectId } = action;
      const linksLoading = { ...state.linkLoading, [projectId]: true };

      return { ...state, linksLoading };
    }
    case LINKS_CREATE_OR_UPDATE.SUCCESS: {
      const { projectId } = action;
      const linksLoading = { ...state.linkLoading, [projectId]: false };

      return { ...state, linksLoading };
    }
    case LINKS_CREATE_OR_UPDATE.FAILURE: {
      const { projectId } = action;
      const linksLoading = { ...state.linkLoading, [projectId]: false };

      return { ...state, linksLoading };
    }

    case PROJECT.REQUEST:
      return { ...state, loading: true };
    case PROJECT.SUCCESS:
      return { ...state, loading: false };
    case PROJECT.FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}
