import { action } from "./index";

export const SIDEBAR = {
  OPEN: "SIDEBAR_OPEN",
  CLOSE: "SIDEBAR_CLOSE",
  ARCHIVED_COLLAPSE: "ARCHIVED_COLLAPSE",
  ARCHIVED_EXPAND: "ARCHIVED_EXPAND",
};

export const sidebar = {
  open: () => action(SIDEBAR.OPEN),
  close: () => action(SIDEBAR.CLOSE),
  archivedCollapse: () => action(SIDEBAR.ARCHIVED_COLLAPSE),
  archivedExpand: () => action(SIDEBAR.ARCHIVED_EXPAND),
};
