import React from "react";
import PropTypes from "prop-types";
import { Header, Button, Form, TextArea } from "semantic-ui-react";
import config from "config";

import * as styles from "./styles.module.scss";

/* eslint-disable */
const invitationMessage = (team, appliedForMarketplace) => {
  if (appliedForMarketplace) {
    return `\
Hi team! I've started a team called "${team}".

I requested for our team profile to be available in the ${config.appName} Marketplace. This will not automatically make your own profile searchable in the Marketplace.

Let's get started!
`;
  }
  return `\
Hi team! I've started a team called "${team}".

Let's get started!
`;
};
/* eslint-enable */

export default class Review extends React.Component {
  componentDidMount() {
    const { team, message, messageEdited, appliedForMarketplace } = this.props;
    const defaultMessage = invitationMessage(team, appliedForMarketplace);

    let messageDisplay = "";
    if (messageEdited) {
      messageDisplay = message;
    } else {
      messageDisplay = defaultMessage;
    }

    this.props.onChange(null, {
      name: "message",
      value: messageDisplay,
    });
  }

  handleChange = (e, { name, value }) => {
    this.props.onChange(null, { name, value });
    this.props.onMessageEdited();
  };

  render() {
    const { message, onPrevious, onFinish } = this.props;

    return (
      <React.Fragment>
        <Header as="h4" content="You are one step away from building your team" />

        <Form>
          <small className={styles.helper}>
            We&rsquo;ve already prepared a templated welcome message, but feel free to edit!
          </small>
          <TextArea
            name="message"
            value={message}
            onChange={this.handleChange}
            style={{ minHeight: 192 }}
          />
        </Form>

        <div className={styles.prevNext}>
          <Button type="button" basic floated="left" content="Back" onClick={onPrevious} />
          <Button
            primary
            type="button"
            floated="right"
            content="Continue"
            onClick={onFinish}
            disabled={message.length === 0}
          />
        </div>
      </React.Fragment>
    );
  }
}

Review.propTypes = {
  appliedForMarketplace: PropTypes.bool.isRequired,
  team: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  messageEdited: PropTypes.bool.isRequired,
  onMessageEdited: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onFinish: PropTypes.func,
};

Review.defaultProps = {
  onPrevious: () => {},
  onFinish: () => {},
};
