import PropTypes from "prop-types";
import React from "react";

import { KeyCodes } from "utils/constants";

import styles from "./styles.module.scss";

export default class Autocomplete extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: -1,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    const { disabled, matches } = this.props;
    if (disabled || matches.length === 0) return;

    let { selected } = this.state;
    switch (e.key) {
      case KeyCodes.ENTER[0]: {
        const { item } = { ...matches[selected === -1 ? 0 : selected] };
        const result = this.onSelect(item);
        if (result) e.preventDefault();
        return;
      }

      case KeyCodes.DOWN[0]:
        selected = this.state.selected + 1;
        e.preventDefault();
        break;

      case KeyCodes.UP[0]:
        selected = this.state.selected - 1;
        e.preventDefault();
        break;

      default:
        return;
    }

    if (selected >= matches.length) selected = -1;
    if (selected < -1) selected = matches.length - 1;
    this.setState({ selected });
  };

  onSelect = (item) => {
    const { onSelect, text, validator } = this.props;

    if (item) {
      onSelect(item);
      this.setState({ selected: -1 });
      return item;
    }

    if (!text) {
      return null;
    }

    if (!validator || validator(text)) {
      onSelect(text);
      this.setState({ selected: -1 });
    }

    return text;
  };

  render() {
    const { disabled, matches, matchRenderer } = this.props;
    const { selected } = this.state;
    if (disabled) return null;
    if (matches.length === 0) return null;

    return (
      <div className={styles.autocompleteContainer}>
        {matches.map((match, index) => matchRenderer(match, this.onSelect, selected === index))}
      </div>
    );
  }
}

Autocomplete.propTypes = {
  disabled: PropTypes.bool,
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape().isRequired,
      matches: PropTypes.arrayOf(
        PropTypes.shape({
          arrayIndex: PropTypes.number.isRequired,
          indices: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ),
  matchRenderer: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  validator: PropTypes.func,
};

Autocomplete.defaultProps = {
  disabled: false,
  matches: [],
  validator: null,
};
