import { decamelizeKeys } from "humps";

import { callApiWithToken } from "./base";

export const versionCommentAdd = (token, versionComment) =>
  callApiWithToken(
    `version_comments/`,
    token,
    null,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(versionComment))
  );

export const versionCommentUpdate = (token, versionCommentId, versionComment) =>
  callApiWithToken(
    `version_comments/${versionCommentId}/`,
    token,
    null,
    "PATCH",
    { "Content-Type": "application/json" },
    JSON.stringify(decamelizeKeys(versionComment))
  );

export const versionCommentDelete = (token, versionCommentId) =>
  callApiWithToken(
    `version_comments/${versionCommentId}/`,
    token,
    null,
    "DELETE",
    { "Content-Type": "application/json" },
  );
