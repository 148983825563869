import React from "react";
import PropTypes from "prop-types";
import { Button, Header, Input } from "semantic-ui-react";

import { isUrlValid } from "utils";

import * as styles from "./styles.module.scss";

export default class Proposal extends React.Component {
  constructor(props) {
    super(props);
    this.companyNameInput = React.createRef();
  }

  componentDidMount() {
    this.companyNameInput.current.focus();
  }

  render() {
    const { companyName, ceo, logoUrl, onChange, onPrevious, onNext } = this.props;

    const isEmpty = !companyName.trim() && !ceo.trim() && !logoUrl.trim() && !isUrlValid(logoUrl);

    return (
      <>
        <div className={styles.proposalContent}>
          <Header as="h4" content="Complete your proposal template for the client" />
          <p>
            Your team will also receive a proposal template for the client in the team’s Google
            Drive. To complete this template, we just need a few more information about the client’s
            company. You can always edit these details in the proposal template document later on.
          </p>
          <Header as="h5" content="Company name" />
          <Input
            ref={this.companyNameInput}
            fluid
            placeholder="Enter company name"
            name="companyName"
            maxLength="200"
            value={companyName}
            onChange={onChange}
          />
          <Header as="h5" content="Current CEO of the company" />
          <Input
            fluid
            placeholder="Enter current CEO of the company"
            name="ceo"
            maxLength="200"
            value={ceo}
            onChange={onChange}
          />
          <Header as="h5" content="URL link of the company logo" />
          <Input
            fluid
            placeholder="Enter current head of the company"
            type="url"
            name="logoUrl"
            maxLength="200"
            value={logoUrl}
            onChange={onChange}
          />
        </div>
        <div className={styles.prevNext}>
          <Button type="button" basic floated="left" content="Back" onClick={onPrevious} />
          <Button
            primary
            type="button"
            floated="right"
            content={isEmpty ? "Skip" : "Continue"}
            onClick={onNext}
          />
        </div>
      </>
    );
  }
}

Proposal.propTypes = {
  companyName: PropTypes.string,
  ceo: PropTypes.string,
  logoUrl: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
};

Proposal.defaultProps = {
  onPrevious: () => {},
  onNext: () => {},
};
