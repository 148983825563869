import classnames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { SingleDatePicker } from "react-dates";
import TimeDropdown from "components/TimeDropdown";
import { isMobile } from "utils/responsive";

import styles from "./styles.module.scss";

export default class DateTimePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    const date = props.date ? moment(props.date) : null;
    this.state = {
      date,
      inputFocused: false,
      time: date ? date.format("HH:mm") : "",
    };
    this.isMobile = isMobile();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.date && nextProps.date !== prevState.date.format()) {
      const date = moment(nextProps.date);
      return { date, time: date.format("HH:mm") };
    }
    return null;
  }

  isOutsideRange = (date) => {
    const { maxDate, minDate } = this.props;
    if (maxDate && minDate) {
      return !date.isBetween(minDate, maxDate, "[]");
    }
    if (maxDate) {
      return date.isAfter(maxDate);
    }
    if (minDate) {
      return date.isBefore(minDate);
    }
    return false;
  };

  handleDateChange = (date) => {
    const { time } = this.state;
    const { hideTime, name, onChange } = this.props;
    if (date && time) {
      const timeObj = moment(time, "HH:mm");
      date.set({
        hour: timeObj.get("hour"),
        minute: timeObj.get("minute"),
      });
      onChange(null, { name, value: date });
    } else if (date && hideTime) {
      onChange(null, { name, value: date });
    }
  };

  handleTimeChange = (event, data) => {
    const { date } = this.state;
    const { name, onChange } = this.props;
    const time = data.value || event.target.value || "";
    this.setState({ time });
    if (date && time) {
      const timeObj = moment(time, "HH:mm");
      date.set({
        hour: timeObj.get("hour"),
        minute: timeObj.get("minute"),
      });
      onChange(null, { name, value: date });
    }
  };

  handleFocus = () => this.setState({ inputFocused: true });

  render() {
    const { date, inputFocused, time } = this.state;
    const { className, disabled, hideTime, required, reversed, small, id } = this.props;
    const componentClasses = [className, styles.dateTimePicker];
    if (reversed) {
      componentClasses.push(styles.reversed);
    }
    if (hideTime) {
      componentClasses.push(styles.hideTime);
    }
    if (small) {
      componentClasses.push(styles.small);
    }
    const timeContainerWidth = { width: "40%", height: this.isMobile ? "24px" : null };
    if (small) {
      timeContainerWidth.width = "auto";
    }

    return (
      <div className={classnames(...componentClasses)}>
        {reversed && !hideTime && (
          <TimeDropdown
            className={styles.timeField}
            disabled={disabled}
            fluid
            name="time"
            value={time}
            required={required}
            onChange={this.handleTimeChange}
            containerStyle={timeContainerWidth}
            id={id}
          />
        )}
        <SingleDatePicker
          date={date}
          disabled={disabled}
          onDateChange={this.handleDateChange}
          focused={inputFocused}
          onFocusChange={({ focused }) => this.setState({ inputFocused: focused })}
          id="date"
          isOutsideRange={this.isOutsideRange}
          daySize={33}
          displayFormat="MMM D, YYYY"
          hideKeyboardShortcutsPanel
          noBorder
          numberOfMonths={1}
          small={small}
          transitionDuration={0}
          anchorDirection={reversed ? "right" : "left"}
        />
        {!reversed && !hideTime && (
          <TimeDropdown
            className={styles.timeField}
            disabled={disabled}
            fluid
            name="time"
            value={time}
            required={required}
            onChange={this.handleTimeChange}
            containerStyle={timeContainerWidth}
          />
        )}
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  disabled: PropTypes.bool,
  hideTime: PropTypes.bool,
  maxDate: PropTypes.shape(),
  minDate: PropTypes.shape(),
  name: PropTypes.string,
  required: PropTypes.bool,
  reversed: PropTypes.bool,
  small: PropTypes.bool,
  id: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

DateTimePicker.defaultProps = {
  date: "",
  disabled: false,
  hideTime: false,
  maxDate: null,
  minDate: null,
  name: "date",
  required: true,
  reversed: false,
  small: false,
  id: "dateTimePicker",
};
