import cx from "classnames";
import _ from "lodash";
import React, { cloneElement } from "react";

import { Popup, PopupContent, PopupHeader, Ref } from "semantic-ui-react";

import styles from "./styles.module.scss";

// This popup is a hack to make the Popup render inline as the next sibling of the trigger
// - Prevents unmounting on close
// - Scrolls with its trigger
// - Requires that the parent element has `display:relative`;
// - Only triggers on click, and does not close on scroll
// - Does not implement keepInViewport
class InlinePopup extends Popup {
  constructor(props) {
    super(props);
    this.state = {
      closed: true,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
    const { active } = this.props;
    if (active) this.triggerNode.click();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  computePopupStyle(positions) {
    const style = { position: "absolute" };
    const { horizontalOffset, verticalOffset } = this.props;

    if (_.includes(positions, "right")) {
      style.right = Math.round(this.parentCoords.right - this.coords.right);
      style.left = "auto";
    } else if (_.includes(positions, "left")) {
      style.left = Math.round(this.coords.left - this.parentCoords.left);
      style.right = "auto";
    } else {
      const xOffset = (this.coords.width - this.popupCoords.width) / 2;
      style.left = Math.round(this.coords.left - this.parentCoords.left + xOffset);
      style.right = "auto";
    }

    if (_.includes(positions, "top")) {
      style.bottom = Math.round(this.coords.height + this.parentCoords.bottom - this.coords.bottom);
      style.top = "auto";
    } else if (_.includes(positions, "bottom")) {
      style.top = Math.round(this.coords.height + this.coords.top - this.parentCoords.top);
      style.bottom = "auto";
    } else {
      const yOffset = (this.coords.height - this.popupCoords.height) / 2;
      style.top = Math.round(this.coords.top - this.parentCoords.top + yOffset);
      style.bottom = "auto";

      const xOffset = this.popupCoords.width + 8;
      if (_.includes(positions, "right")) {
        style.right -= xOffset;
      } else {
        style.left -= xOffset;
      }
    }

    if (horizontalOffset) {
      if (_.isNumber(style.right)) {
        style.right -= horizontalOffset;
      } else {
        style.left -= horizontalOffset;
      }
    }

    if (verticalOffset) {
      if (_.isNumber(style.top)) {
        style.top += verticalOffset;
      } else {
        style.bottom += verticalOffset;
      }
    }

    return style;
  }

  handleTriggerClick = (e, ...rest) => {
    const { trigger } = this.props;
    const { closed } = this.state;
    _.invoke(trigger, "props.onClick", e, ...rest);
    if (closed) {
      this.handleOpen(e);
      this.setPopupStyle();
    } else {
      this.handleClose(e);
    }
    this.setState({ closed: !closed });
  };

  handleDocumentClick = (e) => {
    const { closed } = this.state;
    if (closed || this.triggerNode.contains(e.target) || this.node.contains(e.target)) return;
    this.handleClose(e);
    this.setState({ closed: true });
  };

  handleOpen = (e) => {
    this.coords = e.currentTarget.getBoundingClientRect();
    this.parentCoords = e.currentTarget.parentElement.getBoundingClientRect();
    const { onOpen } = this.props;
    if (onOpen) onOpen(e, this.props);
  };

  handlePopupRef = (popupRef) => {
    this.node = popupRef;
    this.popupCoords = popupRef ? popupRef.getBoundingClientRect() : null;
  };

  handleTriggerRef = (triggerRef) => {
    this.triggerNode = triggerRef;
  };

  render() {
    const {
      basic,
      children,
      className,
      content,
      flowing,
      header,
      inverted,
      size,
      trigger,
      wide,
    } = this.props;

    const { position, closed } = this.state;
    const style = _.assign({}, this.state.style, this.props.style);
    const classes = cx(
      "ui",
      position,
      size,
      wide && (wide === true ? "wide" : `${wide} ${"wide"}`),
      basic && "basic",
      flowing && "flowing",
      inverted && "inverted",
      "popup transition",
      !closed && "visible",
      className,
      styles.inlinePopup
    );

    return [
      <Ref key={1} innerRef={this.handleTriggerRef}>
        {cloneElement(trigger, {
          onClick: this.handleTriggerClick,
        })}
      </Ref>,
      <div key={2} className={classes} style={style} ref={this.handlePopupRef}>
        {children}
        {PopupHeader.create(header)}
        {PopupContent.create(content)}
      </div>,
    ];
  }
}

export default InlinePopup;
