import React from "react";
import PropTypes from "prop-types";
import { NavLink as Link } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import ShortcutsHelpSidebar from "containers/ShortcutsHelp/Sidebar";
import DownloadLinkSidebar from "containers/DownloadLink/Sidebar";
import UserAvatar from "components/UserAvatar";
import { callApiWithToken } from "services/api/base";
import styles from "./styles.module.scss";

const AvatarMenu = ({ currentUser, logout, toHideInBigScreens }) => {
  const currentUserAt = `@${currentUser.slug}`;
  const token = useSelector((state) => state.auth.token);

  return (
    <Dropdown
      className={
        toHideInBigScreens
          ? classnames(styles.topbarDropdown, styles.toHideInBigScreens)
          : styles.topbarDropdown
      }
      trigger={
        <UserAvatar
          avatar={currentUser.avatarOrDefault}
          bgColor={currentUser.avatarBgcolor}
          displayName={currentUser.displayName}
          size={24}
          fgColor={currentUser.avatarColor}
        />
      }
    >
      <Dropdown.Menu className={styles.topbarDropdownMenu}>
        <header className={styles.topbarDropdownMenuHeader}>
          <Link to={`/talents/${currentUser.slug}`}>
            <UserAvatar
              avatar={currentUser.avatarOrDefault}
              bgColor={currentUser.avatarBgcolor}
              displayName={currentUser.displayName}
              size={35}
              fgColor={currentUser.avatarColor}
            />
          </Link>

          <div className={styles.topbarDropdownMenuHeaderText}>
            <h2
              className={styles.topbarDropdownMenuHeaderTextTitle}
              title={currentUser.displayName}
            >
              {currentUser.displayName}
            </h2>

            <h3 className={styles.topbarDropdownMenuHeaderTextSubtitle} title={currentUserAt}>
              {currentUserAt}
            </h3>
          </div>
        </header>
        <Link className={styles.topbarDropdownMenuItem} to={`/talents/${currentUser.slug}/bio`}>
          My Profile
        </Link>
        <Dropdown.Divider />
        <Link className={styles.topbarDropdownMenuItem} to="/legal/terms">
          Terms of Use
        </Link>
        <Link className={styles.topbarDropdownMenuItem} to="/legal/privacy">
          Privacy Policy
        </Link>
        <Dropdown.Divider />
        <ShortcutsHelpSidebar className={styles.topbarDropdownMenuItem} />
        <DownloadLinkSidebar className={styles.topbarDropdownMenuItem} />
        <Link className={styles.topbarDropdownMenuItem} to="/settings">
          Settings
        </Link>
        {currentUser.isStaff && (
          <Button
            download
            className={styles.topbarDropdownMenuItem}
            onClick={() => {
              callApiWithToken("users_data_export/", token).then(({ response: csvBlob }) => {
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "users_data.csv";
                a.click();
              });
            }}
          >
            Get all users data as csv
          </Button>
        )}
        <Button className={styles.topbarDropdownMenuItem} onClick={logout}>
          Sign out
        </Button>
      </Dropdown.Menu>
    </Dropdown>
  );
};

AvatarMenu.propTypes = {
  currentUser: PropTypes.shape(),
  logout: PropTypes.func.isRequired,
  toHideInBigScreens: PropTypes.bool,
};

AvatarMenu.defaultProps = {
  toHideInBigScreens: false,
};

export default AvatarMenu;
