import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Menu } from "semantic-ui-react";
import { renderers, plugins } from "components/Markdown";
import Attachment from "containers/Attachment";

import { withLabel } from "components/input";

import TextArea from "./TextArea";
import styles from "./styles.module.scss";

class BaseMarkdownTextArea extends React.Component {
  constructor(props) {
    super(props);

    this.input = props.forwardedRef || React.createRef();

    this.state = {
      preview: false,
    };
  }

  componentDidMount() {
    if (this.props.focus && this.input.current) {
      this.input.current.focus();
    }
  }

  handleClickWrite = () => {
    this.setState({ preview: false });
  };

  handleClickPreview = () => {
    this.setState({ preview: true });
  };

  render() {
    const {
      cacheId,
      fluid,
      focus,
      forwardedRef,
      hintless,
      loading,
      limited,
      previewless,
      value,
      style,
      ...textAreaProps
    } = this.props;
    const { preview } = this.state;

    return (
      <div className={classnames("markdown-textarea", styles.container, fluid && styles.fluid)}>
        {!previewless && (
          <Menu pointing secondary>
            <Menu.Item content="Write" onClick={this.handleClickWrite} active={!preview} />
            <Menu.Item content="Preview" onClick={this.handleClickPreview} active={preview} />
          </Menu>
        )}
        {preview && (
          <div className={styles.preview}>
            <ReactMarkdown renderers={renderers} plugins={plugins} source={value} />
          </div>
        )}
        {!preview && !limited && (
          <Attachment generic cacheId={cacheId}>
            {({ attachmentInit, dropzoneOnPaste, dropzoneOpen, files }) => (
              <TextArea
                attachmentInit={attachmentInit}
                cacheId={cacheId}
                dropzoneOpen={dropzoneOpen}
                dropzoneOnPaste={dropzoneOnPaste}
                files={files}
                hintless={hintless}
                ref={this.input}
                style={style}
                value={value}
                {...textAreaProps}
              />
            )}
          </Attachment>
        )}
        {!preview && limited && <TextArea value={value} {...textAreaProps} />}
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

BaseMarkdownTextArea.propTypes = {
  cacheId: PropTypes.string.isRequired,
  fluid: PropTypes.bool,
  focus: PropTypes.bool,
  hintless: PropTypes.bool,
  limited: PropTypes.bool,
  placeholder: PropTypes.string,
  previewless: PropTypes.bool,
  value: PropTypes.string,
};

BaseMarkdownTextArea.defaultProps = {
  fluid: false,
  focus: false,
  hintless: false,
  limited: false,
  placeholder: null,
  previewless: false,
};

const MarkdownTextArea = React.forwardRef((props, ref) => (
  <BaseMarkdownTextArea {...props} forwardedRef={ref} />
));

export default MarkdownTextArea;
export const LabeledMarkdownTextArea = withLabel(MarkdownTextArea);
