import { createRequestTypes, action } from "./index";

export const VERSION_COMMENT_ADD = createRequestTypes("VERSION_COMMENT_ADD");
export const VERSION_COMMENT_UPDATE = createRequestTypes("VERSION_COMMENT_UPDATE");
export const VERSION_COMMENT_DELETE = createRequestTypes("VERSION_COMMENT_DELETE");
export const GET_BUDGET_HISTORY = createRequestTypes("GET_BUDGET_HISTORY");
export const GET_WEEKLY_ASSIGNMENT_HISTORY = createRequestTypes("GET_WEEKLY_ASSIGNMENT_HISTORY");
export const READ_BUDGET_HISTORY = createRequestTypes("READ_BUDGET_HISTORY");

export const trackedClasses = {
  budget: "budget",
  weeklyAssignment: "weeklyAssignment",
};

export const versionCommentAdd = {
  request: (versionComment, resolve, reject, trackedClass = trackedClasses.budget) =>
    action(VERSION_COMMENT_ADD.REQUEST, { versionComment, resolve, reject, trackedClass }),
  success: (response, trackedClass = trackedClasses.budget) =>
    action(VERSION_COMMENT_ADD.SUCCESS, { response, trackedClass }),
  failure: (errorDetails) => action(VERSION_COMMENT_ADD.FAILURE, { errorDetails }),
};

export const versionCommentUpdate = {
  request: (
    versionCommentId,
    versionComment,
    resolve,
    reject,
    trackedClass = trackedClasses.budget
  ) =>
    action(VERSION_COMMENT_UPDATE.REQUEST, {
      versionCommentId,
      versionComment,
      resolve,
      reject,
      trackedClass,
    }),
  success: (versionCommentId, response, trackedClass = trackedClasses.budget) =>
    action(VERSION_COMMENT_UPDATE.SUCCESS, { versionCommentId, response, trackedClass }),
  failure: (versionCommentId, errorDetails) =>
    action(VERSION_COMMENT_UPDATE.FAILURE, { versionCommentId, errorDetails }),
};

export const versionCommentDelete = {
  request: (versionCommentId, resolve, reject, trackedClass = trackedClasses.budget) =>
    action(VERSION_COMMENT_DELETE.REQUEST, { versionCommentId, resolve, reject, trackedClass }),
  success: (versionCommentId, response, trackedClass = trackedClasses.budget) =>
    action(VERSION_COMMENT_DELETE.SUCCESS, { versionCommentId, response, trackedClass }),
  failure: (versionCommentId, errorDetails) =>
    action(VERSION_COMMENT_DELETE.FAILURE, { versionCommentId, errorDetails }),
};

export const getBudgetHistory = {
  request: (projectId, url = null) => action(GET_BUDGET_HISTORY.REQUEST, { projectId, url }),
  success: (projectId, response) => action(GET_BUDGET_HISTORY.SUCCESS, { projectId, response }),
  failure: (projectId, errorDetails) =>
    action(GET_BUDGET_HISTORY.FAILURE, { projectId, errorDetails }),
};

export const readBudgetHistory = {
  request: ({ id, token }) => action(READ_BUDGET_HISTORY.REQUEST, { id, token }),
  success: (id, unread) => action(READ_BUDGET_HISTORY.SUCCESS, { id, unread }),
  failure: (id, error) => action(READ_BUDGET_HISTORY.FAILURE, { id, error }),
};

export const getWeeklyAssignmentHistory = {
  request: (weeklyAssignmentId, url = null) =>
    action(GET_WEEKLY_ASSIGNMENT_HISTORY.REQUEST, { weeklyAssignmentId, url }),
  success: (weeklyAssignmentId, response) =>
    action(GET_WEEKLY_ASSIGNMENT_HISTORY.SUCCESS, { weeklyAssignmentId, response }),
  failure: (weeklyAssignmentId, errorDetails) =>
    action(GET_WEEKLY_ASSIGNMENT_HISTORY.FAILURE, { weeklyAssignmentId, errorDetails }),
};

// export const readWeeklyAssignmentHistory = {
//   request: ({ id, token }) => action(READ_BUDGET_HISTORY.REQUEST, { id, token }),
//   success: (id, unread) => action(READ_BUDGET_HISTORY.SUCCESS, { id, unread }),
//   failure: (id, error) => action(READ_BUDGET_HISTORY.FAILURE, { id, error }),
// };
