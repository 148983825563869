import classnames from "classnames";
import { isString, startCase, union } from "lodash";
import generate from "project-name-generator";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Icon, Modal, Segment } from "semantic-ui-react";

import config from "config";
import { newTeamModal } from "actions/modals";
import { token as tokenService } from "services";
import { buildTeam } from "services/api/user";
import { filterUsers } from "utils/user";

import People from "./People";
import Review from "./Review";
import Steps from "./Steps";
import STEPS from "./steps.json";
import * as styles from "./styles.module.scss";

const initialState = {
  step: 0,
  team: "",
  appliedForMarketplace: config.flags.marketplace,
  members: [],
  message: "",
  messageEdited: false,
  creating: false,
  done: false,
  error: null,
  teamSlug: null,
};

class Team extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  setStep = (step) => {
    this.setState({
      step: Math.max(Math.min(step, 4), 0),
    });
  };

  handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { newTeamModalHide } = this.props;
    newTeamModalHide();
    this.setState({ ...initialState });
  };

  handleChange = (e, { name, value, ...data }) => {
    if (data.type === "checkbox") {
      if (data.checked) {
        this.setState((prevState) => ({
          [name]: union(prevState[name], [value]),
        }));
      } else {
        this.setState((prevState) => ({
          [name]: prevState[name].filter((item) => item !== value),
        }));
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleMemberChange = (values) => {
    this.setState({ members: values });
  };

  handleMessageEdited = () => {
    this.setState({ messageEdited: true });
  };

  handleGenerateName = () => {
    const randomWord = startCase(generate({ words: 3 }).spaced);
    this.setState({
      team: randomWord,
    });
  };

  handleFinish = () => {
    this.setState(
      {
        creating: true,
        step: 3,
      },
      () => {
        tokenService.getAuthToken().then((token) => {
          buildTeam(token, {
            teamName: this.state.team,
            appliedForMarketplace: this.state.appliedForMarketplace,
            members: this.state.members.map((member) => (isString(member) ? member : member.slug)),
            message: this.state.message,
          }).then(({ response, error }) => {
            if (response) {
              this.setState(
                {
                  creating: false,
                  done: true,
                  roomId: response.roomId,
                  teamSlug: response.slug,
                },
                () => {
                  this.handleDone();
                }
              );
            } else if (error) {
              this.setState({
                creating: false,
                error,
              });
            }
          });
        });
      }
    );
  };

  handleSetMessage = (message) => {
    this.setState({ message });
  };

  handleNextStep = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { step } = this.state;
    if (step < STEPS.length - 1) {
      this.setState({ step: step + 1 });
    }
  };

  handlePreviousStep = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { step } = this.state;
    if (step > 0) {
      this.setState({ step: step - 1 });
    }
  };

  handleDone = () => {
    const { history } = this.props;
    const { roomId } = this.state;
    this.handleClose();
    history.replace(`/chat/r/${roomId}`);
  };

  renderPeople() {
    const { users } = this.props;
    const { members, team, appliedForMarketplace } = this.state;

    return (
      <People
        appliedForMarketplace={appliedForMarketplace}
        members={members}
        team={team}
        users={users}
        onTeamNameChange={this.handleChange}
        onMemberChange={this.handleMemberChange}
        onGenerateName={this.handleGenerateName}
        onNext={this.handleNextStep}
      />
    );
  }

  renderInvitation() {
    const { step, team, message, messageEdited, appliedForMarketplace } = this.state;

    return (
      <Review
        appliedForMarketplace={appliedForMarketplace}
        team={team}
        message={message}
        messageEdited={messageEdited}
        onMessageEdited={this.handleMessageEdited}
        step={step}
        onChange={this.handleChange}
        onSubmit={this.handleFinish}
        onPrevious={this.handlePreviousStep}
        onNext={this.handleNextStep}
        onFinish={this.handleFinish}
      />
    );
  }

  renderCreating = () => (
    <Segment basic className={styles.creating}>
      <div>
        <Icon size="massive" name="spinner" loading />
      </div>
      <p>Your team is being formed...</p>
    </Segment>
  );

  render() {
    const { open } = this.props;
    const { step, creating, done } = this.state;

    return (
      <Modal
        basic
        centered={false}
        className={classnames("center", styles.mainModal)}
        closeIcon="close"
        closeOnDimmerClick={false}
        dimmer="inverted"
        onClose={this.handleClose}
        open={open}
      >
        <Modal.Header>Form New Team</Modal.Header>
        <Modal.Content>
          <Steps step={step} setStep={this.setStep} />

          {!creating && !done && step === 0 && this.renderPeople()}
          {!creating && !done && step === 1 && this.renderInvitation()}

          {creating && !done && this.renderCreating()}
        </Modal.Content>
      </Modal>
    );
  }
}

Team.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
  open: PropTypes.bool,
  newTeamModalHide: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape()),
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser },
    modals: { showNewTeamModal: open },
  } = state;
  const users = Object.keys(state.entities.users)
    .map((slug) => state.entities.users[slug])
    .filter((user) => user.slug !== currentUser.slug);

  return {
    open,
    users: filterUsers(users),
  };
}

export default connect(mapStateToProps, {
  newTeamModalHide: newTeamModal.hide,
})(injectIntl(withRouter(Team)));
