import React from "react";
import PropTypes from "prop-types";
import { camelize } from "humps";
import { Container, Divider, Grid, Header, Loader, Message } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { isObject, isString } from "lodash";
import { withRouter } from "react-router-dom";
import { toggleSubscribe } from "services/api";
import styles from "./styles.module.scss";

const UNSUBSCRIBE_LABELS = {
  enableDailyHealthReminderEmail: (
    <FormattedMessage
      id="Unsubscribe.label.enableDailyHealthReminderEmail"
      defaultMessage="daily health reminder emails"
    />
  ),
  receiveUnreadChatAsEmail: (
    <FormattedMessage
      id="Unsubscribe.label.receiveUnreadChatAsEmail"
      defaultMessage="unread chats"
    />
  ),
};

const UNSUBSCRIBE_MESSAGES = {
  enableDailyHealthReminderEmail: (
    <FormattedMessage
      id="Unsubscribe.unsubscribe.success.enableDailyHealthReminderEmail"
      defaultMessage="You have been <a>unsubscribed</a> from receiving daily health reminders."
      values={{ a: (msg) => <strong>{msg}</strong> }}
    />
  ),
  receiveUnreadChatAsEmail: (
    <FormattedMessage
      id="Unsubscribe.unsubscribe.success.receiveUnreadChatAsEmail"
      defaultMessage="You have been <a>unsubscribed</a> from receiving unread chats."
      values={{ a: (msg) => <strong>{msg}</strong> }}
    />
  ),
};

const RESUBSCRIBE_MESSAGES = {
  enableDailyHealthReminderEmail: (
    <FormattedMessage
      id="Unsubscribe.resubscribe.success.enableDailyHealthReminderEmail"
      defaultMessage="You have been <a>subscribed</a> to daily health reminders."
      values={{ a: (msg) => <strong>{msg}</strong> }}
    />
  ),
  receiveUnreadChatAsEmail: (
    <FormattedMessage
      id="Unsubscribe.resubscribe.success.receiveUnreadChatAsEmail"
      defaultMessage="You have been <a>subscribed</a> to unread chats."
      values={{ a: (msg) => <strong>{msg}</strong> }}
    />
  ),
};

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: true,
      loading: false,
      loaded: false,
      subscribe: false,
      success: false,
      detail: "",
      email: "",
    };
  }

  componentDidMount() {
    this.unsubscribe();
  }

  _getLabel = () => {
    const { key } = this.props.match.params;
    return UNSUBSCRIBE_LABELS[camelize(key)] || "";
  };

  _getSuccessMessage = () => {
    const { key } = this.props.match.params;
    const { subscribe } = this.state;
    if (subscribe) {
      return RESUBSCRIBE_MESSAGES[camelize(key)] || "";
    }
    return UNSUBSCRIBE_MESSAGES[camelize(key)] || "";
  };

  _toggleSubscribe = (value) => {
    this.setState({ loading: true, loaded: false, subscribe: value }, () => {
      const { key, token: toggleSubscribeToken } = this.props.match.params;
      toggleSubscribe(toggleSubscribeToken, key, value).then(
        ({ response, error, errorDetails }) => {
          let success = false;
          let email = "";
          let detail;
          if (response) {
            success = true;
            detail = response.detail;
            email = (response || {}).email || "";
          } else if (isString(error)) {
            detail = error;
            email = (errorDetails || {}).email;
          } else if (isObject(error) && error.offline) {
            detail = error.offline;
            email = (errorDetails || {}).email;
          }
          this.setState({ initial: false, loading: false, loaded: true, success, detail, email });
        }
      );
    });
  };

  resubscribe = () => this._toggleSubscribe(true);

  unsubscribe = () => this._toggleSubscribe(false);

  _renderHeader = () => {
    const { email, subscribe } = this.state;
    if (!subscribe) {
      return (
        <FormattedMessage
          id="Unsubscribe.unsubscribe.header"
          defaultMessage="We're sorry to see you go, {email}"
          values={{ email: <strong>{email}</strong> }}
        />
      );
    }
    return (
      <FormattedMessage
        id="Unsubscribe.resubscribe.header"
        defaultMessage="Resubscribing {email}"
        values={{ email: <strong>{email}</strong> }}
      />
    );
  };

  _renderLoadingMessage = () => {
    const { subscribe, loading } = this.state;
    if (!loading) {
      return null;
    }
    if (!subscribe) {
      return (
        <Message>
          <p>
            <FormattedMessage
              id="Unsubscribe.unsubscribe.loading"
              defaultMessage="Unsubscribing from {value}..."
              values={{ value: this._getLabel() }}
            />
          </p>
        </Message>
      );
    }
    return (
      <Message>
        <p>
          <FormattedMessage
            id="Unsubscribe.resubscribe.loading"
            defaultMessage="Resubscribing to {value}..."
            values={{ value: this._getLabel() }}
          />
        </p>
      </Message>
    );
  };

  _renderSuccessMessage = () => {
    const { loaded, success } = this.state;
    if (!(loaded && success)) {
      return null;
    }
    return (
      <Message positive>
        <Message.Header>Success!</Message.Header>
        <p>{this._getSuccessMessage()}</p>
      </Message>
    );
  };

  _renderFailureMessage = () => {
    const { detail, loaded, subscribe, success } = this.state;
    if (!(loaded && !success)) {
      return null;
    }
    if (!subscribe) {
      return (
        <Message error>
          <Message.Header>
            <FormattedMessage
              id="Unsubscribe.unsubscribe.failure"
              defaultMessage="Failed unsubscribing from {value}..."
              values={{ value: this._getLabel() }}
            />
          </Message.Header>
          <p>{detail}</p>
        </Message>
      );
    }
    return (
      <Message error>
        <Message.Header>
          <FormattedMessage
            id="Unsubscribe.resubscribe.failure"
            defaultMessage="Failed resubscribing to {value}..."
            values={{ value: this._getLabel() }}
          />
        </Message.Header>
        <p>{detail}</p>
      </Message>
    );
  };

  _renderToggleSubscribeMessage = () => {
    const { subscribe } = this.state;
    if (!subscribe) {
      return (
        <button
          className={styles.resubscribeLink}
          onClick={this.resubscribe}
          onKeyDown={() => {}}
          tabIndex={0}
          type="button"
        >
          <FormattedMessage
            id="Unsubscribe.resubscribe"
            defaultMessage="click here to <a>resubscribe.</a>"
            values={{ a: (msg) => <strong>{msg}</strong> }}
          />
        </button>
      );
    }
    return (
      <button
        className={styles.resubscribeLink}
        onClick={this.unsubscribe}
        onKeyDown={() => {}}
        tabIndex={0}
        type="button"
      >
        <FormattedMessage
          id="Unsubscribe.unsubscribe"
          defaultMessage="click here to <a>unsubscribe.</a>"
          values={{ a: (msg) => <strong>{msg}</strong> }}
        />
      </button>
    );
  };

  render() {
    const { initial } = this.state;
    return (
      <Container className={styles.container}>
        <Grid celled>
          <Grid.Row>
            <Grid.Column>
              <Loader active={initial} inline="centered" />
              {!initial && (
                <React.Fragment>
                  <Header as="h3" className={styles.header}>
                    {this._renderHeader()}
                  </Header>
                  <Divider />
                  {this._renderLoadingMessage()}
                  {this._renderSuccessMessage()}
                  {this._renderFailureMessage()}
                  <p>
                    <FormattedMessage
                      id="Unsubscribe.description"
                      defaultMessage="If this was done in error, you can"
                    />
                    &nbsp;
                    {this._renderToggleSubscribeMessage()}
                  </p>
                </React.Fragment>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

Unsubscribe.propTypes = {
  match: PropTypes.shape.isRequired,
};

export default withRouter(Unsubscribe);
