import React from "react";
import PropTypes from "prop-types";
import { Header, Button, List, Icon, Checkbox } from "semantic-ui-react";

import config from "config";

import Meetings from "./Meetings";
import * as styles from "./styles.module.scss";
import APPS from "./apps.json";
import TOOLS from "./tools.json";

const Tools = ({ timezone, tools, meetings, onCheckboxChange, onChange, onPrevious, onNext }) => (
  <>
    <Header as="h4" content="Your team instantly gets the following:" />
    <List className={styles.defaultTools}>
      {Object.keys(APPS.apps)
        .filter((key) => config.flags[key])
        .map((key) => (
          <List.Item key={key}>
            <List.Icon
              circular
              color="violet"
              inverted
              verticalAlign="top"
              {...APPS.apps[key].icon}
            />
            <List.Content>
              <List.Header>{APPS.apps[key].name}</List.Header>
              <List.Description>{APPS.apps[key].description}</List.Description>
            </List.Content>
          </List.Item>
        ))}
    </List>

    <Meetings meetings={meetings} onCheckboxChange={onCheckboxChange} timezone={timezone} />

    {config.flags.integrations && <Header as="h4" content="Third-party integrations:" />}

    {config.flags.integrations && (
      <List className={styles.optionalTools}>
        {Object.keys(TOOLS.tools).map((key) => (
          <List.Item key={key}>
            <List.Content>
              <span>
                {TOOLS.tools[key].icon && <Icon name={TOOLS.tools[key].icon} color="orange" />}{" "}
                {TOOLS.tools[key].name}
              </span>
              <Checkbox
                toggle
                onChange={onChange}
                name="tools"
                value={key}
                checked={tools.indexOf(key) >= 0}
                readOnly={config.flags.gitlabIntegrationAlwaysOn}
              />
            </List.Content>
          </List.Item>
        ))}
      </List>
    )}

    <div className={styles.prevNext}>
      <Button type="button" basic floated="left" content="Back" onClick={onPrevious} />
      <Button primary type="button" floated="right" content="Continue" onClick={onNext} />
    </div>
  </>
);

Tools.propTypes = {
  timezone: PropTypes.string.isRequired,
  tools: PropTypes.arrayOf(PropTypes.string).isRequired,
  meetings: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
};

Tools.defaultProps = {
  onPrevious: () => {},
  onNext: () => {},
};

export default Tools;
