// Priority: hoursOutsideWork > hoursUnavailable > hoursBusy > hoursAvailable
const updateStyle = (oldValue, newValue) => {
  if (!oldValue) return newValue;
  if (oldValue === "hoursOutsideWork") return "hoursOutsideWork";
  if (oldValue === "hoursUnavailable") return "hoursUnavailable";
  if (oldValue === "hoursBusy") return "hoursBusy";
  return newValue;
};

export const getNewStyle = (oldClasses, classToAdd, curHour, eventData) => {
  const { firstHalf: firstHalfOld, secondHalf: secondHalfOld } = oldClasses;
  let firstHalf = firstHalfOld;
  let secondHalf = secondHalfOld;
  const { startHour, startMinute, endHour, endMinute } = eventData;
  const isStart = curHour === startHour;
  const isEnd = curHour === endHour;
  const isFirstHalf = startMinute >= 0 && startMinute < 30;
  const isSecondHalf = endMinute > 30 && endMinute < 60;
  if (isStart && isEnd) {
    // startHour == endHour == curHour
    if (startMinute !== endMinute) {
      firstHalf = isFirstHalf ? classToAdd : firstHalfOld;
      secondHalf = isSecondHalf ? classToAdd : secondHalfOld;
    }
  } else if (isStart) {
    // startHour where startHour != endHour
    firstHalf = isFirstHalf ? classToAdd : firstHalfOld;
    secondHalf = classToAdd;
  } else if (isEnd) {
    // endHour where startHour != endHour
    firstHalf = classToAdd;
    secondHalf = isSecondHalf ? classToAdd : secondHalfOld;
  } else {
    // curHour between startHour and endHour where startHour != endHour
    firstHalf = classToAdd;
    secondHalf = classToAdd;
  }
  // Update styling based on priority
  const newFirstHalf = updateStyle(firstHalf, firstHalfOld);
  const newSecondHalf = updateStyle(secondHalf, secondHalfOld);
  return { firstHalf: newFirstHalf, secondHalf: newSecondHalf };
};
