import React from "react";
import PropTypes from "prop-types";
import NotFound from "containers/NotFound";
import styles from "./styles.module.scss";

const Loading = ({ isLoading, error, isNotFound }) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <div style={{ flex: 1, backgroundColor: "white" }}>
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className={styles.spinner} />
        </div>
      </div>
    );
  }
  // Handle the error state
  if (error) {
    if (isNotFound) {
      return <NotFound />;
    }
    return <div>Sorry, there was a problem loading the page.</div>;
  }
  return null;
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(), PropTypes.bool]),
  isNotFound: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: true,
  error: null,
  isNotFound: false,
};

export default Loading;
