import findAndReplace from "mdast-util-find-and-replace";

const regex = /@[a-zA-Z0-9-_]+/g;

export function mentionParser(options) {
  const settings = {
    validate: () => true,
    getDisplay: (slug) => slug,
    getURL: (slug) => `/talents/${slug}`,
    ...options,
  };

  function toLink(value) {
    const username = value.substring(1);
    if (settings.validate(username)) {
      return {
        type: "link",
        title: username,
        url: settings.getURL(username),
        children: [{ type: "text", value }],
      };
    }
    return value;
  }

  function transformer(tree) {
    findAndReplace(tree, [[regex, toLink]]);
  }

  return transformer;
}
