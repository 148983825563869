import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink as Link, matchPath, withRouter } from "react-router-dom";

import config from "config";
import { logoutInit } from "actions/account";
import AvatarMenu from "components/AvatarMenu";
import TopBarDropdownMenu from "components/TopBarDropdownMenu";
import NotificationBell from "containers/Notification/NotificationBell";
import { getRooms } from "reducers/selectors";
import styles from "./styles.module.scss";

class Hamburger extends React.Component {
  handleLogout = () => {
    this.props.logoutInit();
  };

  render() {
    const { currentUser, location, hasUnreadMessages } = this.props;

    const isCalendar = matchPath(location.pathname, { path: "/talents/:username/calendar" });
    const isTimeTracker = matchPath(location.pathname, { path: "/timetracker" });
    const isHealth = matchPath(location.pathname, { path: "/talents/:username/health" });
    const isSchedule = matchPath(location.pathname, { path: "/schedule" });
    const isNotifications = matchPath(location.pathname, { path: "/notifications" });

    return (
      <div className={styles.hamburger}>
        <Link to="/" className={styles.logo} title="Home">
          {config.flags.brand.sidebarLogo ? (
            <img src={config.brandSidebarLogo} width="auto" alt={config.appName} />
          ) : (
            <img src="/bld-logo.png" alt={config.appName} />
          )}
        </Link>
        <TopBarDropdownMenu
          currentUser={currentUser}
          hasUnreadMessages={hasUnreadMessages}
          isCalendar={!!isCalendar}
          isHealth={isHealth}
          isSchedule={isSchedule}
          isTimeTracker={isTimeTracker}
        />
        {config.flags.notifications && <NotificationBell active={isNotifications} />}
        <AvatarMenu currentUser={currentUser} logout={this.handleLogout} />
      </div>
    );
  }
}

Hamburger.propTypes = {
  currentUser: PropTypes.shape(),
  location: PropTypes.shape(),
  logoutInit: PropTypes.func.isRequired,
  hasUnreadMessages: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    auth: { user: currentUser },
    messages: { unreadMessagesCountByRoom },
  } = state;

  const rooms = getRooms(state) || {};
  const unreadMessagesCount = Object.keys(rooms).reduce(
    (count, roomId) => count + unreadMessagesCountByRoom[roomId],
    0
  );
  const hasUnreadMessages = !Number.isNaN(unreadMessagesCount) && unreadMessagesCount > 0;

  return { currentUser, hasUnreadMessages };
}

export default withRouter(
  connect(mapStateToProps, {
    logoutInit,
  })(Hamburger)
);
