import classnames from "classnames";
import { isEmpty, without } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { NavLink as Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { getRoomName, getLabelRoomName } from "utils/chat";
import { getActiveTabRoomUrl } from "utils/apps";

import styles from "./styles.module.scss";

class MessageRoom extends React.PureComponent {
  handleRoomHide = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.roomHideRequest(this.props.room.id);
  };

  render() {
    const { currentUser, isCurrent, room, unreadMessagesCount, users, lastRoomTab } = this.props;
    if (!room) return null;

    const roomName = getRoomName(room, room.users, currentUser);
    const userSlugsOthers = without(room.userSlugs, currentUser.slug);
    const roomUsers = userSlugsOthers.map((slug) => users[slug]);
    const labelRoomName = getLabelRoomName(room, roomUsers, currentUser);

    const online =
      !isEmpty(room.userSlugs) &&
      room.userSlugs.find(
        (slug) => userSlugsOthers.indexOf(slug) >= 0 && users[slug] && users[slug].online
      );

    const url = getActiveTabRoomUrl(room, lastRoomTab);

    return (
      <Button
        as={Link}
        className={classnames(
          "focusable",
          styles.sidebarMessageRoom,
          isCurrent && styles.active,
          online && styles.online,
          unreadMessagesCount > 0 && styles.unread
        )}
        data-focusable
        id={`room${room.id}`}
        tabIndex="0"
        title={labelRoomName}
        to={url}
      >
        <span
          className={classnames(styles.sidebarMessageRoomContent, !room.team && styles.closeable)}
        >
          <Icon name={room.icon || (room.team ? "chevron right" : "circle")} />
          <span>{roomName}</span>
          <div className={styles.unreadMessageRoomNotif} />
          {room.hasCall && <Icon name="call" />}
        </span>
        {!room.team && !room.isInUnreadList && (
          <Button
            basic
            onClick={this.handleRoomHide}
            icon="close"
            className={styles.closeButton}
            size="small"
            title="Close"
          />
        )}
      </Button>
    );
  }
}

MessageRoom.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  isCurrent: PropTypes.bool,
  room: PropTypes.shape().isRequired,
  roomHideRequest: PropTypes.func.isRequired,
  unreadMessagesCount: PropTypes.number.isRequired,
  users: PropTypes.shape().isRequired,
  lastRoomTab: PropTypes.string,
};

MessageRoom.defaultProps = {
  isCurrent: false,
};

function mapStateToProps(state) {
  const {
    sidePanel: { panelId, visible },
  } = state;

  return {
    sidePanelId: panelId,
    sidePanelVisible: visible,
  };
}

export default connect(mapStateToProps)(MessageRoom);
